import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";

import Share from "../../../../../../components/FacebookLogin/share";
import * as friendActions from "../../../../actions";
import * as groupActions from "../../../FriendGroups/actions";

class FriendSuggestions extends Share {
  handleFriendAddPress = (friendId, isMember) => {
    const { groups, activeListIndex } = this.props;
    const groupId = groups[activeListIndex].id;
    let filteredFriends;

    if (isMember) {
      filteredFriends = this.props.friends.filter(
        (itemId) => itemId !== friendId
      );
    } else {
      filteredFriends = this.props.friends.slice();
      filteredFriends.push(friendId);
    }

    groups[activeListIndex].members = filteredFriends;
    this.props.groupActions.patchGroup(
      {
        id: groupId,
        members: filteredFriends,
      },
      this.props.user
    );
    this.props.friendActions.getFriendsById(
      filteredFriends,
      this.props.allFriends
    );
    this.props.groupActions.receiveGroups(groups);
  };

  renderNoFriends() {
    return (
      <div>
        <p className="text-center p-md">
          <FormattedMessage id="feed.nofriends-sidebar" />
        </p>
        <p className="text-center">
          <button
            onClick={() => this.shareNoFriends()}
            className="button button--facebook"
          >
            <FormattedMessage id="feed.share.button.tellAbout" />
          </button>
          <br />
          <br />
        </p>
      </div>
    );
  }

  renderSuggestions() {
    const { users, allFriends, friends } = this.props;
    return (
      <ul className="friend-list__contacts contacts contacts--multicolumn contacts--selectable list--selectable">
        {allFriends.map((friendId) => (
          <li
            key={friendId}
            onClick={() =>
              this.handleFriendAddPress(friendId, friends.includes(friendId))
            }
            className={`contacts__item list__item ${
              this.props.friends.includes(friendId) && "selected"
            }`}
          >
            <UserImage
              className="contacts__user-avatar"
              user={users[friendId]}
            />

            <span className="contacts__user-name">
              {users[friendId].first_name}{" "}
              <strong>{users[friendId].last_name}</strong>
            </span>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    if (this.props.activeListIndex < 0) {
      return null;
    }
    return (
      <div
        className={`friend-list__contacts-container ${
          !this.props.friends.length && "friend-list--visible"
        }`}
      >
        <h3 className="text-center">
          <FormattedMessage id="friends.suggestions" />
        </h3>
        {this.props.allFriends.length
          ? this.renderSuggestions()
          : this.renderNoFriends()}
      </div>
    );
  }
}

FriendSuggestions.propTypes = {
  activeListIndex: PropTypes.number.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      gender: PropTypes.string,
      image: PropTypes.string,
    }),
  }).isRequired,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  allFriends: PropTypes.arrayOf(PropTypes.number).isRequired,
  groupActions: PropTypes.shape({
    patchGroup: PropTypes.func,
  }).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  friends: state.friends.friends.activeList,
  activeListIndex: state.friends.groups.index,
  users: state.users.all,
  groups: state.friends.groups.all,
  allFriends: state.friends.friends.all,
  user: state.user,
});

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    friendActions: bindActionCreators(friendActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FriendSuggestions);
