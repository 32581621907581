import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "./actions";

class MultiStep extends Component {
  componentDidMount() {
    this.props.actions.addMultistep(this.props.id, this.props.default);
  }

  componentWillUnmount() {
    this.props.actions.removeMultistep(this.props.id);
  }

  render() {
    if (!this.props.step) {
      return null;
    }

    if (this.props.steps[this.props.step]) {
      return <div>{this.props.steps[this.props.step]}</div>;
    }
    throw `Step ${this.props.step} in multistep ${this.props.id} not exist.`; // eslint-disable-line
  }
}

MultiStep.propTypes = {
  id: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    step: state.multiStep[ownProps.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiStep);
