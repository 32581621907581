import React from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import ProductImage from "components/ProductImage";
import FormattedHTMLMessage from "services/localization/flex";

class MyWish extends React.Component {
  getPercentage() {
    const need = this.props.wish.amount_needed;
    const gathered = this.props.wish.amount_gathered;
    if (gathered >= need) {
      return "100%";
    }
    return `${(gathered * 100) / need}%`;
  }

  getDateDiff(currentDate, date) {
    const t2 = currentDate.getTime();
    const t1 = date.getTime();
    const days = (t2 - t1) / (24 * 3600 * 1000);
    const weeks = (t2 - t1) / (24 * 3600 * 1000 * 7);
    const d1Y = date.getYear();
    const d2Y = currentDate.getYear();
    const d1M = date.getMonth();
    const d2M = currentDate.getMonth();
    const months = d2M + 12 * d2Y - (d1M + 12 * d1Y);
    const DateDiff = {
      inDays: Math.abs(Math.floor(days)),
      inWeeks: Math.abs(Math.floor(weeks)),
      inMonths: Math.abs(Math.floor(months)),
    };
    return DateDiff;
  }

  getDaysRemaining() {
    const actualDate = new Date();
    const dateOfExpiration = new Date(this.props.wish.date_of_expiration);

    const remaining = Math.floor((dateOfExpiration - actualDate) / 86400000);

    if (remaining <= 0) {
      return 0;
    }

    return remaining;
  }

  showDeadline() {
    let message;

    const currentDate = new Date();
    const dateExpired = new Date(this.props.wish.date_of_expiration);
    const wishState = this.props.wish.wish_state;
    if (false && this.props.wish.isDeleted) {
      // TODO propagovat isDeleted do wish
      message = <FormattedMessage id="wish.detail.deleted" />;
    } else if (wishState === "closed" || wishState === "tranfered") {
      message = <FormattedMessage id="wish.detail.terminated" />;
    } else if (this.props.wish.date_of_expiration === null) {
      return;
    } else if (currentDate > dateExpired) {
      message = <FormattedHTMLMessage id="wish.detail.expired" />;
    } else if (this.props.wish.date_completed) {
      message = <FormattedHTMLMessage id="wish.detail.completed" />;
    } else {
      // const diff = this.getDateDiff(dateExpired, currentDate);
      const days = this.getDaysRemaining();
      if (days === 0) {
        message = <FormattedHTMLMessage id="wish.detail.todayEnds" />;
      } else {
        message = (
          <FormattedHTMLMessage
            id="wish.detail.remaining"
            values={{
              days: this.getDaysRemaining(),
            }}
          />
        );
      }
    }
    if (message === null) {
      return null;
    }
    return (
      <p>
        <i className="icon icon--time" />
        <span>{message}</span>
      </p>
    );
  }

  render() {
    return (
      <div className="wishes-item wishes-item--small">
        <Link
          to={`/wish/detail/${this.props.wish.id}`}
          className="wishes-item__info-wrapper"
        >
          <div className="wishes-item__info">
            <h2 className="wishes-item__title">{this.props.wish.title}</h2>
            <div className="wishes-item__progress">
              <p className="wishes-item__progress-description">
                <FormattedHTMLMessage
                  id="wish.detail.collected.mine"
                  values={{
                    count: this.props.wish.donators_count,
                    gathered: this.props.intl.formatNumber(
                      this.props.wish.amount_gathered
                    ),
                    currency: this.props.wish.currency.toUpperCase(),
                  }}
                />
              </p>
              <span
                className={
                  "wishes-item__progress-bar" +
                  (this.props.wish.amount_gathered &&
                  this.props.wish.amount_needed
                    ? ""
                    : " invisible")
                }
              >
                <span
                  className="wishes-item__progress-bar-inner"
                  style={{ width: this.getPercentage() }}
                />
              </span>
              {this.showDeadline()}
            </div>
          </div>
          <figure className="wishes-item__image">
            <ProductImage wish={this.props.wish} />
          </figure>
        </Link>
      </div>
    );
  }
}

MyWish.propTypes = {
  wish: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    amount_needed: PropTypes.number,
    currency: PropTypes.string,
    amount_gathered: PropTypes.number,
    donators_count: PropTypes.number,
    date_created: PropTypes.instanceOf(Date),
    date_of_expiration: PropTypes.instanceOf(Date),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
  }).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(MyWish);
