import React from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import UpcomingBirthdaysComponent from "scenes/Friends/components/UpcomingBirthdaysComponent";
import SettingsSidebar from "scenes/Settings/components/SettingsSidebar";

class UpcomingBirthdays extends React.Component {
  render() {
    if (this.props.user.loggedIn) {
      return (
        <DocumentTitle
          title={this.props.intl.formatMessage({
            id: "upcomingBirthdays.title",
          })}
        >
          <section className="app-section">
            <div className="container container--narrow">
              <article className="settings">
                <SettingsSidebar />
                <div className="divider divider--vertical no-vertical-mobile fill">
                  <div className="row align-center">
                    <h2>
                      <FormattedMessage id="upcomingBirthdays.title" />
                    </h2>
                  </div>
                  <UpcomingBirthdaysComponent
                    friends={this.props.friends}
                    page={!false}
                  />
                </div>
              </article>
            </div>
          </section>
        </DocumentTitle>
      );
    }
    return (
      <DocumentTitle
        title={this.props.intl.formatMessage({ id: "upcomingBirthdays.title" })}
      />
    );
  }
}

UpcomingBirthdays.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }).isRequired,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  friends: state.friends.friends.all,
});
export default connect(mapStateToProps, null)(injectIntl(UpcomingBirthdays));
