export const STATUS_DEFAULT = 0;
export const STATUS_LOADING = 1;
export const STATUS_LOADING_FAILED = 2;
export const STATUS_LOADED = 3;
export const STATUS_UPLOADING = 4;
export const STATUS_UPLOADING_FAILED = 5;
export const STATUS_UPLOADED = 6;
export const STATUS_UNAUTHORIZED = 7;

export const IMAGE_URL = "https://api.elateme.com/";
