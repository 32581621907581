import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import NotificationsList from "components/NotificationsList";
import SettingsSidebar from "scenes/Settings/components/SettingsSidebar";

import * as notificationActions from "./actions";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  handleLoadMore() {
    if (this.props.next !== null) {
      this.props.notificationActions.getNextPage(
        this.props.user,
        this.props.next
      );
    }
  }

  render() {
    if (this.props.user.loggedIn) {
      return (
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "menu.notifications" })}
        >
          <div className="app-section">
            <div className="container container--narrow">
              <article className="settings">
                <SettingsSidebar />
                <div className="notifications divider divider--vertical">
                  <h2>
                    <FormattedMessage id="notifications.title" />
                  </h2>
                  <div className="content">
                    <NotificationsList
                      notificationsActions={this.props.notificationActions}
                      isDropdown={false}
                    />
                    {this.props.next ? (
                      <a
                        className="more-link"
                        onClick={() => this.handleLoadMore()}
                      >
                        <FormattedMessage id="notifications.loadMore" />
                      </a>
                    ) : null}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </DocumentTitle>
      );
    }
    return (
      <DocumentTitle
        title={this.props.intl.formatMessage({ id: "menu.notifications" })}
      />
    );
  }
}
Notifications.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
    accessToken: PropTypes.string,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  notificationActions: PropTypes.shape({
    getNotifications: PropTypes.func,
    getNextPage: PropTypes.func,
  }).isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      date_created: PropTypes.date,
      notification_type: PropTypes.string,
      wish: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        ammount_needed: PropTypes.number,
        ammount_gathered: PropTypes.number,
        donators_count: PropTypes.number,
        date_created: PropTypes.date,
        date_of_expiration: PropTypes.date,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ),
      }),
      donation: PropTypes.shape({
        id: PropTypes.number,
        donator: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
        }),
        wish: PropTypes.number,
        amount: PropTypes.number,
        date: PropTypes.date,
      }),
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  notifications: state.notifications.notifications,
  next: state.notifications.next,
});
function mapDispatchToProps(dispatch) {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Notifications));
