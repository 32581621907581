import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { push } from "react-router-redux";
import query_parser from "query-string";

import { authFetchApi } from "services/api/";

import { fetchApi } from "../../services/api";

import * as types from "./actionTypes";
import * as constants from "./constants";

export const setStatus = (item) => ({
  type: types.SET_STATUS,
  item,
});

export const setWish = (item) => ({
  type: types.SET_WISH,
  item,
});

export const setSurpriseWish = (item) => ({
  type: types.SET_SURPRISE_WISH,
  item,
});

export const setTitle = (item) => ({
  type: types.SET_TITLE,
  item,
});

export const setNewImage = (item) => ({
  type: types.SET_NEW_IMAGE,
  item,
});

export const setImages = (item) => ({
  type: types.SET_IMAGES,
  item,
});

export const setDescription = (item) => ({
  type: types.SET_DESCRIPTION,
  item,
});

export const setAmountNeeded = (item) => ({
  type: types.SET_AMOUNT_NEEDED,
  item,
});

export const setDateOfExpiration = (item) => ({
  type: types.SET_DATE_OF_EXPIRATION,
  item,
});

export const setVisibility = (item) => ({
  type: types.SET_VISIBILITY,
  item,
});

export const setProductUrl = (item) => ({
  type: types.SET_PRODUCT_URL,
  item,
});

export const setWishState = (item) => ({
  type: types.SET_WISH_STATE,
  item,
});

export const setCurrency = (item) => ({
  type: types.SET_CURRENCY,
  item,
});

export const setUserMoneyReceiver = (item) => ({
  type: types.SET_USER_MONEY_RECEIVER,
  item,
});

export const addAllowedUsers = (item) => ({
  type: types.ADD_ALLOWED_USERS,
  item,
});

export const removeAllowedUsers = (item) => ({
  type: types.REMOVE_ALLOWED_USERS,
  item,
});

export const reset = () => ({
  type: types.RESET,
  item: null,
});

export const setRecipient = (item) => ({
  type: types.SET_RECIPIENT,
  item,
});

export const setFlatDonation = (item) => ({
  type: types.SET_FLAT_DONATION,
  item,
});

export const setCustomUserName = (item) => ({
  type: types.SET_CUSTOM_USER_NAME,
  item,
});

export const setAllowedUsers = (item) => ({
  type: types.SET_ALLOWED_USERS,
  item,
});

export const setEshopId = (item) => ({
  type: types.SET_ESHOP_ID,
  item,
});

export const setEshopProductId = (item) => ({
  type: types.SET_ESHOP_PRODUCT_ID,
  item,
});

export const setCreationSource = (item) => ({
  type: types.SET_CREATION_SOURCE,
  item,
});

function unwrapWish(wish) {
  const newWish = Object.assign({}, wish);
  const values = query_parser.parse(window.location.search);
  // convert surprise_wish to recipient number
  if (wish.surprise_wish) {
    newWish.surprise_wish.recipient = wish.surprise_wish.recipient.id;
  }

  if (!wish.surprise_wish) {
    newWish.surprise_wish = {};
  }

  if (values.description != null) {
    newWish.description = values.description;
  }

  if (wish.user_money_receiver) {
    newWish.user_money_receiver = wish.user_money_receiver.id;
  }

  // convert allowed_users array to object
  const allowedUsers = {};

  wish.allowed_users.forEach((userId) => {
    allowedUsers[userId] = true;
  });

  newWish.allowed_users = allowedUsers;

  return newWish;
}
/*
function wrapWish(wish) {
  const newWish = Object.assign({}, wish);

  // convert recipient number to surprise_wish
  newWish.surprise_wish = {
    recipient: wish.recipient,
  };

  delete newWish.recipient;

  // convert allowed_users object to array
  newWish.allowed_users = Object.keys(wish.allowed_users);
  return newWish;
}
*/

export function getWishById(id, user) {
  return (dispatch) => {
    dispatch(setStatus(constants.STATUS_LOADING));
    authFetchApi(`/wishes/${id}/`, user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        if (user.loggedIn === false) {
          dispatch(setStatus(constants.STATUS_UNAUTHORIZED));
        } else {
          dispatch(setWish(unwrapWish(data)));
          dispatch(setStatus(constants.STATUS_LOADED));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setStatus(constants.STATUS_LOADING_FAILED));
      });
  };
}

export function uploadImage(user, image, callback) {
  return (dispatch) => {
    const data = new FormData();
    data.append("image", image);
    authFetchApi("/wishes/upload/image/", user.accessToken, {
      method: "POST",
      "Content-Type": "multipart/form-data",
      body: data,
    })
      .then((imageUrl) => {
        if (callback) {
          callback(imageUrl);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setStatus(constants.STATUS_UPLOADING_FAILED));
      });
  };
}

function _createWish(dispatch, wish, user) {
  wish.amount_needed = wish.amount_needed || 0;
  wish.user_money_receiver = wish.user_money_receiver || user.id;
  if (wish.images && wish.images.length) {
    const image = wish.images[0].image;
    if (image && !image.startsWith("http://localhost")) {
      const match = image.match(/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g); // eslint-disable-line
      if (!match) {
        wish.images = [];
      }
    }
  }

  authFetchApi("/wishes/", user.accessToken, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(wish),
  })
    .then((data) => {
      dispatch(setStatus(constants.STATUS_UPLOADED));
      ReactGA.event({
        category: "Wish",
        action: "create",
      });
      ReactGA4.event({
        category: "Wish",
        action: "create",
      });

      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.track("InitiateCheckout");
        });


      dispatch(
        push({
          pathname: `/wish/detail/${data.id}`,
          state: { created: true, isOpenWishCreatedModal: true },
        })
      );
    })
    .catch((error) => {
      console.log(error);
      dispatch(setStatus(constants.STATUS_UPLOADING_FAILED));
    });
}

export function createWish(user, wish, newImage) {
  return (dispatch) => {
    dispatch(setStatus(constants.STATUS_UPLOADING));

    if (newImage) {
      dispatch(
        uploadImage(user, newImage, (img) => {
          if (img.url) {
            wish.images = [{ image: img.url }];
          }
          // wish.images = [{ image: img }];
          _createWish(dispatch, wish, user);
        })
      );
    } else {
      _createWish(dispatch, wish, user);
    }
  };
}

function _updateWish(dispatch, user, wish) {
  authFetchApi(`/wishes/${wish.id}/`, user.accessToken, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(wish),
  })
    .then((data) => {
      dispatch(setStatus(constants.STATUS_UPLOADED));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setStatus(constants.STATUS_UPLOADING_FAILED));
    });
}

export function loadDataFromEshop(user, productURL) {
  return (dispatch) => {
    const headers = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ page_url: productURL }),
    };
    let response = null;
    if (user.loggedIn) {
      response = authFetchApi("/wishes/webpage/", user.accessToken, headers);
    } else {
      response = fetchApi("/wishes/webpage/", headers);
    }

    function autosize(event) {
      const el = event.srcElement || event;
      setTimeout(() => {
        el.style.cssText = "height:auto; padding:0";
        // for box-sizing other than "content-box" use:
        el.style.cssText = "-moz-box-sizing:content-box";
        el.style.cssText = `height:${el.scrollHeight + 5}px`;
      }, 0);
    }

    response
      .then((data) => {
        dispatch(setTitle(data[0].title));
        const textarea = document.querySelector("textarea");
        autosize(textarea);

        dispatch(setDescription(data[0].description));
        dispatch(setImages([{ image: data[0].image }]));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateWish(user, wish, newImage) {
  return (dispatch) => {
    dispatch(setStatus(constants.STATUS_UPLOADING));

    if (newImage) {
      dispatch(
        uploadImage(user, newImage, (img) => {
          if (img.url) {
            wish.images = [{ image: img.url }];
          }
          _updateWish(dispatch, user, wish);
        })
      );
    } else {
      _updateWish(dispatch, user, wish);
    }
  };
}
