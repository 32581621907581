import * as types from "./actionTypes";
import * as constants from "./constants";

export const initialState = {
  status: constants.LOADING,
  response: [],
  error: null,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.RECEIVE_RESPONSE:
      return Object.assign({}, state, {
        status: constants.LOADED,
        response: payload.response,
      });
    case types.API_ERROR:
      return Object.assign({}, state, {
        status: constants.ERROR,
        error: payload.error,
      });
    case types.RESET:
      return Object.assign({}, initialState);
    case types.PATCH_REQUEST:
      const data = state.response.map((req) => {
        if (req.id === payload.id) {
          return {
            ...req,
            ...payload.patch,
          };
        }
        return req;
      });
      return Object.assign({}, state, { response: data });
    default:
      return state;
  }
};
