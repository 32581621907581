import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import CommentForm from "./components/CommentForm";
import CommentList from "./components/CommentList";
import * as actions from "./actions";

class CommentBox extends React.Component {
  constructor(props) {
    super(props);

    this.loadNextPage = this.loadNextPage.bind(this);
  }

  componentDidMount() {
    this.props.actions.createCommentBox(this.props.id);
  }

  componentWillUnmount() {
    this.props.actions.removeCommentBox(this.props.id);
  }

  loadNextPage() {
    this.props.actions.loadCommentPage(
      this.props.user,
      this.props.id,
      this.props.commentBox.page + 1,
      this.props.pageSize
    );
  }

  getNextCount() {
    return Math.min(
      this.props.pageSize,
      this.props.commentBox.count - this.props.commentBox.visible
    );
  }

  showNextButton() {
    if (this.props.commentBox.visible === this.props.commentBox.count) {
      return null;
    }

    return (
      <a className="wishes-item__load-comments" onClick={this.loadNextPage}>
        <FormattedMessage id="comments.loadNext" />
        <span> ({this.getNextCount()}) </span>
      </a>
    );
  }

  render() {
    if (!this.props.commentBox) {
      return null;
    }

    return (
      <div
        className={
          this.props.detail
            ? "comments wish-detail__comments"
            : "comments wishes-item__comments"
        }
      >
        {this.props.user.loggedIn && this.props.comments_allowed && (
          <CommentForm id={this.props.id} detail={this.props.detail} />
        )}
        <CommentList
          id={this.props.id}
          last_three_comments={this.props.last_three_comments}
          isFeed={this.props.isFeed}
        />
        {/*this.showNextButton()*/}
      </div>
    );
  }
}

CommentBox.propTypes = {
  id: PropTypes.number.isRequired,

  actions: PropTypes.shape({
    createCommentBox: PropTypes.func,
    removeCommentBox: PropTypes.func,
  }).isRequired,

  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    commentBox: state.comments.comments[ownProps.id],
    pageSize: state.comments.pageSize,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CommentBox));
