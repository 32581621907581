import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import moment from "moment";

import "moment/locale/cs";
import "moment/locale/en-gb";

const currentYear = new Date().getFullYear();

function getFromMonth(deadline) {
  return deadline !== undefined
    ? new Date(currentYear, new Date().getMonth())
    : new Date(currentYear - 60, 0);
}

function getToMonth(deadline) {
  return deadline !== undefined
    ? new Date(currentYear + 60, 0)
    : new Date(currentYear, new Date().getMonth());
}

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  const date = props.month;
  const months = moment().locale("cs").localeData().months();
  const fromMonth = getFromMonth(props.deadline);
  const toMonth = getToMonth(props.deadline);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    e.stopPropagation();
    const { year, month } = e.target.form;
    props.onChange(new Date(year.value, month.value));
  };
  return (
    <div {...props} className="form__field">
      <form>
        <select
          className="date-input"
          name="month"
          onChange={handleChange}
          value={date.getMonth()}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          className="date-input"
          name="year"
          onChange={handleChange}
          value={date.getFullYear()}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
      <div>{children}</div>
    </div>
  );
}

class DayPickerForm extends React.Component {
  render() {
    const { date_of_birth, deadline, value, placeholder, month, locale } =
      this.props;

    return (
      <DayPickerInput
        value={value ? formatDate(value, "LL", locale) : null}
        onDayChange={this.props.onDayChange}
        placeholder={placeholder}
        formatDate={formatDate}
        parseDate={parseDate}
        format={"DD. MM. YYYY"}
        dayPickerProps={{
          selectedDays: date_of_birth || deadline,
          locale,
          localeUtils: MomentLocaleUtils,
          fromMonth: getFromMonth(deadline),
          toMonth: getToMonth(deadline),
          month,
          modifiers: {
            disabled: [
              {
                after: deadline === undefined ? new Date() : null,
                before: deadline === undefined ? null : new Date(),
              },
            ],
          },
        }}
        overlayComponent={(props) => (
          <CustomOverlay
            {...props}
            onChange={this.props.onChange}
            month={month}
            deadline={deadline}
          />
        )}
        keepFocus={false}
      />
    );
  }
}

export default DayPickerForm;
