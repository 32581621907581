/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { fetchBitGoApi } from 'services/api/index';
import * as actions from '../../actions';

class Paying extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moreInfo: false,
    }

    this.handleClickBack = this.handleClickBack.bind(this);
    this.handlePayingSelect = this.handlePayingSelect.bind(this);
  }


  handlePayingSelect(selection) {
    switch(selection) {
      case 1 :
        this.props.actions.callCardPayment(this.props.user,
          this.props.wish.id,
          this.props.value,
          window);
        this.props.actions.setStep(6);
        break;
      case 2 :
        this.props.actions.setStep(3);
        break;
      case 3 :
        // show QR payment
        this.props.actions.setStep(4);
        break;
      default :
        this.props.action.setStep(6);
    }
  }

  getCardCosts() {
    return Math.ceil((this.props.flat_donation || this.props.value) * 0.0118);
  }


  handleClickBack() {
    this.props.actions.stepBack();
  }

  getDonateInfoMobile() {
    if (this.state.moreInfo) {
      return (
        <p className="hidden-desktop hidden-tablet">
          <FormattedMessage id="wish.detail.donate-money-info.desktop"/>
          <br />{" "}
          <a
            onClick={() => this.handleLoadInfo()}
          >
            <FormattedMessage id="common.less" />
          </a>
        </p>
      )
    }
    return (
      <p className="hidden-desktop hidden-tablet">
        <FormattedMessage id="wish.detail.donate-money-info.mobile"/>
        <br />{" "}
        <a
          onClick={() => this.handleLoadInfo()}
        >
          <FormattedMessage id="common.more" />
        </a>
      </p>
    )
  }

  handleLoadInfo() {
    this.setState({
      moreInfo: !this.state.moreInfo,
    })
  }

  getTitle() {
    let donation;

    if(this.props.flat_donation) {
      donation = this.props.flat_donation;
    } else {
      donation = this.props.value;
    }

    return (
      <FormattedMessage
        id="donate.title.flat"
        values={{
          donation: donation,
          currency: this.props.currency.toUpperCase(),
        }}
      />
    );
  }


  render() {
    return (
    <div className="popup donate-popup donate-payment-method">
      <h3 className="popup__heading">{this.getTitle()}</h3>

      <h4 className="popup__title"><FormattedMessage id="donate.choosePaymentMethod" /></h4>

      <ul className="donate-popup__payment-methods list list--selectable">
        {this.props.wish.currency === "czk" && false ?
        <button onClick={()=> this.handlePayingSelect(1)} className={this.props.active === 1 ? "no-style list__item selected" : "no-style list__item"} >
          <img src="/img/payment-credit-card.svg" alt="" className="list__item-image list__item-image--small" />
          <span className="fill"><FormattedMessage id="donate.card" /> (+{this.getCardCosts()} Kč)</span>
        </button>
          : null}
        {/*
        <button onClick={()=> this.handlePayingSelect(2)} className={this.props.active === 2 ? "no-style list__item selected" : "no-style list__item"} >
          <img src="/img/payment-bitcoin.svg" alt="" className="list__item-image list__item-image--small" />
          <span className="fill"><FormattedMessage id="donate.bitcoin" /></span>
        </button>
        */}
        <button onClick={()=> this.handlePayingSelect(3)} className={this.props.active === 3 ? "no-style list__item selected" : "no-style list__item"} >
          <img src="/img/qr-code.svg" alt="" className="list__item-image list__item-image--small" />
          <span className="fill"><FormattedMessage id="donate.QRCode" /></span>
        </button>
      </ul>
      <p className="hidden-mobile">
        <FormattedMessage id="wish.detail.donate-money-info.desktop" />
      </p>
      {this.getDonateInfoMobile()}
      <div className="popup__footer">
        {!this.props.isFlat &&
        <button className="button fill" onClick={this.handleClickBack}><FormattedMessage id="common.back" /></button>
        }
      </div>

    </div>
    );
  }
}

Paying.propTypes = {
  setAddress: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};


const mapStateToProps = state => ({
  active : state.donation.payingOption,
  user: state.user,
  wish: state.wishDetail.restrictedWish || state.wishDetail.wish,
  url: state.donation.urlCardPayment,
  isFlat: state.donation.flat_donation,
  value: state.donation.donationValue,
});

function mapDispatchToProps(dispatch) {
  return {
    actions : bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Paying);
