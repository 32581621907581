import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import deepEqual from "deep-equal";

import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { getNotifications } from "scenes/Notifications/actions";
import { authFetchApi, fetchApi } from "services/api/";

import * as constants from "../../../../components/WishControllers/constants";

import * as types from "./actionTypes";

export const setWish = (item) => ({
  type: types.SET_WISH,
  item,
});
export const setRestrictedWish = (item) => ({
  type: types.SET_RESTRICTED_WISH,
  item,
});
export const setFriend = (item) => ({
  type: types.SET_FRIEND,
  item,
});
export const setAccessRequests = (item) => ({
  type: types.SET_ACCESS_REQUESTS,
  item,
});
export const setRestriction = (item) => ({
  type: types.SET_RESTRICTION,
  item,
});
export const setStatus = (item) => ({
  type: types.SET_STATUS,
  item,
});
export const setLoading = (id) => ({
  type: types.SET_LOADING,
  id,
});
export const reset = () => ({
  type: types.RESET,
  item: null,
});
export const wishDeleted = (wishId) => ({
  type: types.WISH_DELETED,
  item: wishId,
});

export const setWaiting = () => ({
  type: types.IS_WAITING,
  item: null,
});

export function getRestrictedWishById(id, user) {
  return (dispatch) => {
    dispatch(setStatus(types.STATUS_LOADING));
    const address = `/wishes/restricted/${id}/`;
    const options = { method: "GET" };
    let request;
    if (user && user.loggedIn) {
      request = authFetchApi(address, user.accessToken, options);
    } else {
      request = fetchApi(address, options);
    }
    return request
      .then((data) => {
        dispatch(setFriend(data.author));
        dispatch(setRestrictedWish(data));
      })
      .catch((error) => {
        dispatch(setStatus(types.STATUS_ERROR_RESTRICTED));
      });
  };
}

export function checkRestrictedWishStatus(id, user, wish) {
  return (dispatch) => {
    const address = `/wishes/restricted/${id}/`;
    const options = { method: "GET" };
    let request;
    if (user && user.loggedIn) {
      request = authFetchApi(address, user.accessToken, options);
    } else {
      request = fetchApi(address, options);
    }
    return request
      .then((data) => {
        if (Object.toJSON(data) !== Object.toJSON(wish)) {
          dispatch(setRestrictedWish(data));
        }
      })
      .catch((e) => {
        dispatch(setStatus(types.STATUS_ERROR_RESTRICTED));
      });
  };
}

export function checkWishStatus(id, user, wish, location) {
  return (dispatch) => {
    const isNonRestricted = wish && wish.title !== undefined;
    const address = isNonRestricted
      ? `/wishes/${id}`
      : `/wishes/restricted/${id}`;
    const options = { method: "GET" };
    let request;
    if (user && user.loggedIn) {
      request = authFetchApi(address, user.accessToken, options);
    } else {
      request = fetchApi(address, options);
    }
    return request
      .then((data) => {
        if (!deepEqual(data, wish)) {
          dispatch(setWish(data));
        }
      })
      .catch((e) => {
        if (e.errorCode === 401 || e.errorCode === 403) {
          return checkRestrictedWishStatus(id, user, wish)(dispatch);
        }
        if (e.errorCode === 404) {
          dispatch(setStatus(types.STATUS_NOT_FOUND));
        } else {
          dispatch(setStatus(types.STATUS_ERROR_FULL));
        }
      });
  };
}

export function getWish(id, user) {
  return (dispatch) => {
    dispatch(setLoading(id));
    const address = `/wishes/${id}/`;
    const options = { method: "GET" };
    let request;
    if (user && user.loggedIn) {
      request = authFetchApi(address, user.accessToken, options);
    } else {
      request = fetchApi(address, options);
    }
    return request
      .then((data) => {
        dispatch(setFriend(data.author));
        dispatch(setWish(data));
      })
      .catch((error) => {
        if (error.errorCode === 401 || error.errorCode === 403) {
          dispatch(setRestriction(true));
          return getRestrictedWishById(id, user)(dispatch);
        }
        if (error.errorCode === 404) {
          dispatch(setStatus(types.STATUS_NOT_FOUND));
        } else {
          dispatch(setStatus(types.STATUS_ERROR_FULL));
        }
      });
  };
}

export function getAuthorById(id, callback) {
  return (dispatch) => {
    fetchApi(`/account/restricted/${id}/`, {
      method: "GET",
    }).then((data) => {
      dispatch(setFriend(data));
      callback();
    });
  };
}

export function deleteWish(id, token, continueTo) {
  return (dispatch) => {
    authFetchApi(`/wishes/${id}/`, token, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(wishDeleted(id));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function closeWish(id, token) {
  return (dispatch) => {
    const form = { wish_state: "closed" };
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    return authFetchApi(`/wishes/${id}/`, token, options)
      .then((json) => {
        const eventData = {
          category: "Wish",
          action: "close",
          label: "id",
          value: id, // TODO tady bych mohl nastavit amount_gathered
        };
        ReactGA.event(eventData);
        ReactGA4.event(eventData);
        import("react-facebook-pixel")
          .then((x) => x.default)
          .then((ReactPixel) => {
            ReactPixel.track("Purchase");
          });

        dispatch(setWish(json));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function refundWish(id, token) {
  return (dispatch) => {
    const form = { wish_state: "for-refundation" };
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    return authFetchApi(`/wishes/${id}/`, token, options)
      .then((json) => {
        dispatch(setWish(json));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function requestForWishReveal(user, wish) {
  return (dispatch) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ wish: wish.id }),
    };
    return authFetchApi("/wishes/access/request/", user.accessToken, options)
      .then(() => {
        dispatch(setWaiting());
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function setAccessRequest(user, requestId, response) {
  return (dispatch) => {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: response
        ? JSON.stringify({ status: "granted" })
        : JSON.stringify({ status: "rejected" }),
    };
    console.log("Options body:", options.body);
    return authFetchApi(
      `/wishes/access/${requestId}`,
      user.accessToken,
      options
    )
      .then(() => {
        dispatch(getNotifications(user));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function setMoneyReceiver(user, wish, money_receiver) {
  return (dispatch) => {
    authFetchApi(`/wishes/${wish.id}/`, user.accessToken, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_money_receiver: money_receiver }),
    })
      .then(() => {
        getWish(wish.id, user);
      })
      .catch((error) => {
        console.log(error);
        dispatch(setStatus(constants.STATUS_UPLOADING_FAILED));
      });
  };
}
