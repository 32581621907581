import React from "react";
import PropTypes from "prop-types";

const DEFAULT_IMAGE = "/img/placeholder-gift.svg";

const ProductImage = ({ className, wish }) => {
  let productImage = DEFAULT_IMAGE;
  if (
    wish.images &&
    Array.isArray(wish.images) &&
    wish.images.length > 0 &&
    wish.images[0].image
  ) {
    productImage = wish.images[0].image;
  }
  return (
    <img
      src={productImage}
      onError={(e) => {
        e.target.src = DEFAULT_IMAGE;
      }}
      alt={wish.title}
      className={className}
    />
  );
};

ProductImage.propTypes = {
  wish: PropTypes.shape({
    title: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
  }).isRequired,
  className: PropTypes.string,
};

ProductImage.defaultProps = {
  className: "",
};
export default ProductImage;
