import React, { Component } from "react";

import Friend from "./Friend";

class FriendsList extends Component {
  render() {
    const friendsList = this.props.friends.map((friend, i) => {
      return <Friend friend={friend} key={i} index={i} />;
    });

    return (
      <div className="search__item">
        <h1 className="search__item-heading">Friends</h1>
        {friendsList}
      </div>
    );
  }
}

export default FriendsList;
