/* eslint-disable react/jsx-filename-extension */

import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { addLocaleData } from "react-intl";
import csLocaleData from "react-intl/locale-data/cs";
import enLocaleData from "react-intl/locale-data/en";
import svLocaleData from "react-intl/locale-data/sv";
import { Provider } from "react-intl-redux";
import { Router } from "react-router";
import { renderRoutes } from "react-router-config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./services/prerendering/parseData";

import routes from "./components/Router/routes";
import store, { history } from "./store";
addLocaleData([...enLocaleData, ...csLocaleData, ...svLocaleData]);

Sentry.init({
  dsn: "https://143855b4ce7c4a948affad2b4bcecbca@o459852.ingest.sentry.io/5469679",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const tagManagerArgs = {
  gtmId: "GTM-MTNNH8R",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>{renderRoutes(routes)}</Router>
  </Provider>,
  document.getElementById("root")
);
/* eslint-enable react/jsx-filename-extension */
