import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import AnalyticsHandler from "./components/AnalyticsHandler";
import GDPRHandlers from "./components/GDPRHandlers";
import GDPRModal from "./components/GDPRModal";
import * as gdprActions from "./actions";
import * as constants from "./constants";
import { hasNotAnsweredPermissions } from "./helpers";

class GDPRContainer extends Component {
  render() {
    const { actions, gdpr, location } = this.props;
    const hasSomethingToShow =
      gdpr.status !== constants.initial ||
      gdpr.hasChange ||
      hasNotAnsweredPermissions(gdpr.gdpr);

    const isOpen = gdpr.isOpen && hasSomethingToShow;
    const filterFunc =
      gdpr.status === constants.initial && !gdpr.hasChange
        ? (arr) => arr[1] === null
        : () => true;

    return (
      <Fragment>
        <GDPRHandlers gdpr={gdpr.handlersData}>
          <AnalyticsHandler
            gdprKey="web-analytics"
            location={location.pathname}
          />
        </GDPRHandlers>
        <GDPRModal
          actions={actions}
          gdpr={gdpr}
          isOpen={isOpen}
          filterFunc={filterFunc}
        />
      </Fragment>
    );
  }
}
GDPRContainer.propTypes = {
  actions: PropTypes.shape({
    closeModal: PropTypes.func,
    setField: PropTypes.func,
    save: PropTypes.func,
  }).isRequired,
  gdpr: PropTypes.shape({
    isOpen: PropTypes.bool,
    status: PropTypes.string,
    saveStatus: PropTypes.string,
    gdpr: PropTypes.object,
    handlersData: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  gdpr: state.gdpr,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(gdprActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GDPRContainer)
);
