import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";
import UnauthorizedMenu from "components/App/components/Menu/components/UnauthorizedMenu/index";
import ConfirmModal from "components/ConfirmModal";
import * as types from "components/ConfirmModal/actionTypes.js";

import Wishes from "./components/Wishes";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  closeModal() {
    this.setState({
      isOpen: false,
    });
  }

  renderHomepage() {
    return (
      <div className="homepage">
        <UnauthorizedMenu {...this.props} />

        <div className="app-section">
          <section className="section claim">
            <div className="main">
              <div className="claim__column claim__cta" />
              <header className="claim__column hp-header">
                <h2 className="hp-header__title">
                  <FormattedHTMLMessage id="hp.header.title" />
                </h2>
                <h3 className="hp-header__subtitle">
                  <FormattedHTMLMessage id="hp.header.subtitle" />
                </h3>
                <Link to="/create" className="hp-header__button">
                  <FormattedMessage id="hp.header.link" />
                </Link>
              </header>
            </div>
          </section>

          <section className="describer">
            <div className="main">
              <div className="describer-header">
                <div className="describer-header__image">
                  <img
                    src="/img/bubble-gift.svg"
                    className="img-fluid describer-header__bubble"
                    alt=""
                  />
                </div>
                <div className="describer-header__title">
                  <h2>
                    <span className="describer-header__title--bigger">
                      <FormattedMessage id="hp.section.h2.1" />
                    </span>
                    &nbsp;
                    <FormattedMessage id="hp.section.h2.2" />
                    &nbsp;
                    <FormattedMessage id="hp.section.h2.3" />
                  </h2>
                </div>
              </div>

              <div className="describer-body">
                <div className="describer-body__item">
                  <img
                    src="/img/bubble-1.svg"
                    className="img-fluid describer-body__title"
                    alt=""
                  />
                  <p className="describer-body__desc">
                    <FormattedMessage id="hp.bubble.1" />
                  </p>
                </div>
                <div className="describer-body__item">
                  <img
                    src="/img/bubble-2.svg"
                    className="img-fluid describer-body__title"
                    alt=""
                  />
                  <p className="describer-body__desc">
                    <FormattedMessage id="hp.bubble.2" />
                  </p>
                </div>
                <div className="describer-body__item">
                  <img
                    src="/img/bubble-3.svg"
                    className="img-fluid describer-body__title"
                    alt=""
                  />
                  <p className="describer-body__desc">
                    <FormattedMessage id="hp.bubble.3" />
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="what-is main">
              <div className="what-is__image">
                <div className="embed-object embed-object__16by9 ">
                  <iframe
                    title="tutorial"
                    className="embed-object-in"
                    src="https://www.youtube.com/embed/TnkOblHuCIc"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
              <header className="what-is__header">
                <h2 className="what-is__title">
                  <FormattedMessage id="hp.what-it-is.title" />
                </h2>
                <p className="what-is__subtitle">
                  <FormattedMessage id="hp.what-it-is.subtitle" />
                </p>
                <a
                  className="hp-header__button hidden-desktop"
                  onClick={() => this.setState({ isOpen: true })}
                  role="button"
                >
                  <FormattedMessage id="hp.what-it-is.link" />
                </a>
              </header>
            </div>

            <div className="main what-is-container hidden-device">
              <div className="what-is-container__item">
                <div className="what-is-container__header">
                  <img
                    src="/img/bubble-how-work.svg"
                    className="what-is-container__image"
                    alt=""
                  />
                  <h3 className="what-is-container__subtitle">
                    <FormattedMessage id="home.how-it-works.title" />
                  </h3>
                </div>
                <p>
                  <FormattedMessage id="home.how-it-works" />
                </p>
              </div>
              <div className="what-is-container__item">
                <div className="what-is-container__header">
                  <img
                    src="/img/bubble-how-create.svg"
                    className="what-is-container__image"
                    alt=""
                  />
                  <h3 className="what-is-container__subtitle">
                    <FormattedMessage id="home.how-to-create-wish.title" />
                  </h3>
                </div>

                <p>
                  <FormattedMessage id="home.how-to-create-wish" />
                </p>
              </div>
              <div className="what-is-container__item">
                <div className="what-is-container__header">
                  <img
                    src="/img/bubble-cashback.svg"
                    className="what-is-container__image"
                    alt=""
                  />
                  <h3 className="what-is-container__subtitle">
                    <FormattedMessage id="home.cashback.title" />
                  </h3>
                </div>
                <p>
                  <FormattedMessage id="home.cashback" />
                </p>
                <p>
                  <FormattedMessage id="home.cashback.description" />
                </p>
                <br />
                <p>
                  Máš otázky? <Link to="/faq">Přečti si ty nejčastější</Link>.
                </p>
              </div>
            </div>

            {/* PRESS */}
            <div className="what-is main">
              <header className="what-is__header">
                <h2 className="what-is__title">
                  <FormattedMessage id="hp.press.title" />
                </h2>
              </header>
            </div>

            <div className="main press-container">
              <div className="press-container__item">
                <div className="what-is-container__header">
                  <h3 className="what-is-container__subtitle">
                    <a
                      target="_blank"
                      href="https://fit.cvut.cz/cs/zivot-na-fit/aktualne/success-stories/11064-aplikace-wowee-plni-prani-i-pomaha-v-nouzi"
                      rel="noreferrer"
                    >
                      ČVUT FIT: Aplikace Wowee plní přání i pomáhá v nouzi
                    </a>
                  </h3>
                </div>
                <p>
                  Příběh aplikace Wowee je ukázkou toho, jak radost obdarovat
                  nejbližsí lze přeměnit v radost pomáhat potřebným. Wowee je
                  webová aplikace, která umožňuje finančně se složit na dárek
                  přátelům nebo rodině. Aplikace, na níž se podíleli studenti
                  FIT, však pomáhá i v době koronaviru.
                </p>
                <br />
              </div>
              <div className="press-container__item">
                <div className="what-is-container__header">
                  <h3 className="what-is-container__subtitle">
                    <a
                      target="_blank"
                      href="https://www.cysnews.cz/lifestyle/splnte-prani-lidem-v-nouzi-pomoci-aplikace-wowee/"
                      rel="noreferrer"
                    >
                      Cysnews.cz: Splňte přání lidem v nouzi pomocí aplikace
                      Wowee
                    </a>
                  </h3>
                </div>
                <p>
                  Webová aplikace Wowee otevírá prostor pro lidskou solidaritu a
                  umožňuje finančně pomáhat těm, kteří se v době koronavirové
                  nákazy ocitli v nelehké situaci. Aplikace funguje na principu
                  finanční sbírky, čímž se stává bezkontaktní pomocí potřebným.
                  Projekt vznikl ve spolupráci se studenty Fakulty informačních
                  technologií ČVUT v Praze (FIT ČVUT) a to původně za účelem
                  plnění přání mezi přáteli a rodinou.
                </p>
                <br />
              </div>
            </div>

            <div className="main press-container">
              <div className="press-container__item">
                <div className="what-is-container__header">
                  <h3 className="what-is-container__subtitle">
                    <a
                      target="_blank"
                      href="https://aktualne.cvut.cz/stalo-se/20191106-aplikace-wowee-pomaha-s-porizenim-velkych-darku"
                      rel="noreferrer"
                    >
                      ČVUT: Aplikace Wowee pomáhá s pořízením velkých dárků
                    </a>
                  </h3>
                </div>
                <p>
                  Ze studentského nápadu a po třech letech vývoje a testování
                  vznikla společně se studenty pražského ČVUT unikátní webová
                  aplikace Wowee. Ta využívá princip crowdfundingu mezi přáteli,
                  rodinou nebo třeba kolegy, kteří se přes jednoduchou on-line
                  aplikaci mohou skládat na smysluplné dárky.
                </p>
                <br />
              </div>
              <div className="press-container__item">
                <div className="what-is-container__header">
                  <h3 className="what-is-container__subtitle">
                    <a
                      target="_blank"
                      href="https://starty.cz/clanky/startupy/nemate-penize-na-vanocni-darek-diky-studentum-z-cvut-se-na-nej-muzete-slozit-s-dalsimi-lidmi/"
                      rel="noreferrer"
                    >
                      Starty.cz: Nemáte peníze na vánoční dárek?
                    </a>
                  </h3>
                </div>
                <p>
                  Technické obory Českého vysokého učení technického dávají
                  studentům možnost se reálně zapojit nejen do vývoje
                  nejrůznějších aplikací, ale i do tvorby skutečného startupu.
                  Právě tak vznikl nový startup Wowee. Pokud chcete pod vánočním
                  stromečkem najít vysněný dárek, po jehož otevření si řeknete
                  wow, stačí své přání rozeslat svým blízkým, kteří už se o to
                  nějak postarají. Co přesně tím máme na mysli?
                </p>
                <br />
              </div>
            </div>

            <div className="main press-container">
              <div className="press-container__item">
                <div className="what-is-container__header">
                  <h3 className="what-is-container__subtitle">
                    <a
                      target="_blank"
                      href="https://www.e15.cz/byznys/startupy/start-up-wowee-dovoli-spolecne-porizovat-darky-za-vyvojem-stoji-studenti-cvut-1353161"
                      rel="noreferrer"
                    >
                      E15.cz: Start-up Wowee dovolí společně pořizovat dárky. Za
                      vývojem stojí studenti ČVUT.
                    </a>
                  </h3>
                </div>
                <p>
                  Ze studentského nápadu a po třech letech vývoje a testování
                  vznikla společně se studenty pražského ČVUT unikátní webová
                  aplikace Wowee. Ta využívá princip crowdfundingu mezi přáteli,
                  rodinou nebo třeba kolegy, kteří se přes jednoduchou on-line
                  aplikaci mohou skládat na smysluplné dárky.
                </p>
                <br />
              </div>
              <div className="press-container__item">
                <div className="what-is-container__header">
                  <h3 className="what-is-container__subtitle">
                    <a
                      target="_blank"
                      href="https://magazin.cz/spolecnost/12400-wowee-socialni-sit-ktera-ti-pomuze-splnit-prani"
                      rel="noreferrer"
                    >
                      Magazin.cz: Wowee! Sociální síť, která ti pomůže splnit
                      přání
                    </a>
                  </h3>
                </div>
                <p>
                  Wowee propojuje všechny, kteří chtějí plnit přání svých
                  blízkých. Stačí se zdarma registrovat založit přání pro sebe
                  nebo někoho blízkého a je hotovo. Přání můžeš sdílet na další
                  sítě pomocí odkazu a to i diskrétně.
                </p>
              </div>
            </div>
          </section>

          <ConfirmModal
            type={types.ABOUT_WOWEE}
            isOpen={this.state.isOpen}
            closeModal={() => this.closeModal()}
            confirmAction={() => {}}
          />
          <Footer {...this.props} />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.user.loggedIn) {
      return this.renderHomepage();
    }
    return <Wishes {...this.props} />;
  }
}

Home.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(injectIntl(Home));
