import React from "react";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import PropTypes from "prop-types";

import * as constants from "components/App/constants";

class AnalyticsHandler extends React.Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
  };
  componentDidMount() {
    const { location } = this.props;
    ReactGA.pageview(location);
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location !== location) {
      ReactGA.pageview(location);
      ReactGA4.send({ hitType: "pageview", page: location})
      if (window && window.FB && window.FB.AppEvents) {
        window.FB.AppEvents.logPageView(location);
      }
    }
  }
  render() {
    return null;
  }
}

export default AnalyticsHandler;
