/**
 * Created by levinrom on 4/6/17.
 */

import * as groupTypes from "./components/FriendGroups/actionTypes";
import * as types from "./actionTypes";

function parseDate(friend) {
  const date = new Date();
  const currentDate = new Date();
  const symbols = friend.date_of_birth.split("-");
  date.setFullYear(currentDate.getFullYear());
  date.setMonth(symbols[1] - 1);
  date.setDate(symbols[2]);
  if (
    date.getMonth() < currentDate.getMonth() ||
    (date.getMonth() === currentDate.getMonth() &&
      date.getDate() < currentDate.getDate())
  ) {
    date.setFullYear(date.getFullYear() + 1);
  }
  return date;
}

function sortByBirthday(friends) {
  friends.sort((first, second) => {
    if (!first.date_of_birth) {
      return false;
    }
    if (!second.date_of_birth) {
      return true;
    }
    const date1 = parseDate(first);
    const date2 = parseDate(second);
    return date1 > date2;
  });
}

export const initState = {
  all: [],
  activeList: [],
  outsideNetwork: [],
  friend: null,
  filter: {
    active: false,
    input: "",
  },
  apiError: false,
  friendRequests: [],
  friendshipsRequested: [],
  lastFiveUsers: [],
};

function initArray(friends) {
  const array = [];
  for (let i = 0; i < friends.length; i += 1) {
    array.push(friends[i].id);
  }
  return array;
}

export default (state = initState, payload) => {
  switch (payload.type) {
    case types.FETCH_FRIENDS:
      sortByBirthday(payload.item);
      const friendsIndx = initArray(payload.item);
      return Object.assign({}, state, {
        all: friendsIndx,
        activeList: friendsIndx,
      });
    case types.SET_FRIEND_INFO: {
      const list = state.all.slice();
      const index = payload.item.i;
      list[index] = payload.item.friend;
      return Object.assign({}, state, {
        all: list,
      });
    }
    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true });
    case types.CLEAR_LIST:
      return Object.assign({}, state, { all: [] });
    case types.GET_FRIENDS:
      return Object.assign({}, state, { activeList: payload.item });
    case groupTypes.PATCH_GROUP:
      return Object.assign({}, state, { activeList: payload.item.members });
    case types.GET_FRIEND_BY_ID:
      return Object.assign({}, state, { friend: payload.item });
    case types.CHANGE_FILTER_MOD:
      return Object.assign({}, state, {
        filter: {
          active: payload.item,
          input: state.filter.input,
        },
      });
    case types.SET_FILTER_INPUT:
      return Object.assign({}, state, {
        filter: {
          active: state.filter.active,
          input: payload.item,
        },
      });
    case types.GET_REQUESTS:
      return Object.assign({}, state, { friendRequests: payload.item });
    case types.GET_USER_REQUESTS:
      return Object.assign({}, state, { friendshipsRequested: payload.item });
    case types.GET_LAST_FIVE_USERS:
      return Object.assign({}, state, { lastFiveUsers: payload.item });
    default:
      return state;
  }
};
