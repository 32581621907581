import React from "react";
import { FormattedMessage } from "react-intl";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

import Spinner from "components/Spinner";

import * as constants from "../constants";
import { hasNotAnsweredPermissions } from "../helpers";

import PermissionsList from "./PermissionsList";

const GDPRModal = ({ actions, gdpr, filterFunc, isOpen }) => {
  const btnDisabled =
    !(gdpr.hasChange && gdpr.saveStatus !== constants.pending) ||
    gdpr.status === constants.pending ||
    gdpr.status === constants.error ||
    hasNotAnsweredPermissions(gdpr.gdpr);

  let btnMsg = "common.saved";
  let content = (
    <PermissionsList
      list={gdpr.gdpr}
      setField={actions.setField}
      filter={filterFunc}
    />
  );

  if (gdpr.status === constants.pending) {
    btnMsg = "common.loading";
    content = <Spinner />;
  } else if (gdpr.status === constants.error) {
    btnMsg = "common.error";
    content = <FormattedMessage id="common.error" />;
  } else if (gdpr.saveStatus === constants.error) {
    btnMsg = "common.error";
  } else if (gdpr.hasChange && gdpr.saveStatus !== constants.pending) {
    btnMsg = "common.save";
  } else if (gdpr.saveStatus === constants.pending) {
    btnMsg = "common.saving";
  }
  // const closeDisabled = gdpr.status === constants.initial;
  const closeDisabled = false;
  const closeModal = () => {
    if (!closeDisabled) {
      actions.closeModal();
    }
  };

  return (
    <ReactModal
      contentLabel="GDPR"
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal modal--main"
      overlayClassName="overlay overlay--main"
    >
      <div className="popup">
        <h3 className="popup__heading gdpr">
          <FormattedMessage id="gdpr.popup.title" />
        </h3>
        <h4 className="popup__title gdpr">
          <FormattedMessage id="gdpr.popup.description" />
        </h4>
        {content}
        <div className="popup__footer">
          <button
            className="button"
            onClick={closeModal}
            disabled={closeDisabled}
          >
            <FormattedMessage id="common.close" />
          </button>
          <button
            className="button button--next fill"
            disabled={btnDisabled}
            onClick={actions.save}
          >
            <FormattedMessage id={btnMsg} />
          </button>
        </div>
      </div>
      <button
        className="fancybox-close-small"
        onClick={closeModal}
        disabled={closeDisabled}
      />
    </ReactModal>
  );
};

GDPRModal.propTypes = {
  actions: PropTypes.shape({
    closeModal: PropTypes.func,
    setField: PropTypes.func,
    save: PropTypes.func,
  }).isRequired,
  gdpr: PropTypes.shape({
    isOpen: PropTypes.bool,
    status: PropTypes.string,
    saveStatus: PropTypes.string,
    gdpr: PropTypes.object,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  filterFunc: PropTypes.func.isRequired,
};

export default GDPRModal;
