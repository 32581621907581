import * as types from "./actionTypes";

export const initState = {
  notifications: [],
  isDropdownOpen: false,
  next: "",
  loaded: false,
  count: 0,
  popups: [],
  closingPopup: false,
  revealRequests: [],
};

function editNotifications(item, notifications) {
  notifications.forEach((notification) => {
    if (notification.id === item.id) {
      notification.user_influencer.friendship_request.me = {
        state: item.state,
      };
    }
  });
  return notifications;
}

export default (state = initState, payload) => {
  switch (payload.type) {
    case types.RESET_NOTIFICATIONS:
      return initState;
    case types.FETCH_NOTIFICATIONS:
      return Object.assign({}, state, {
        notifications: payload.item.results,
        next: payload.item.next,
        loaded: true,
      });
    case types.CLOSING_POPUP:
      return Object.assign({}, state, {
        closingPopup: payload.item,
      });
    case types.FETCH_NOTIFICATIONS_COUNT:
      return Object.assign({}, state, { count: payload.count });
    case types.SET_DROPDOWN:
      return Object.assign({}, state, { isDropdownOpen: payload.value });
    case types.POPUP_SUCCESS_COLLECTION:
      return Object.assign({}, state, { popups: payload.popups });
    case types.POPUP_CLOSE:
      return Object.assign({}, state, {
        popups: state.popups.filter((popup) => popup.id !== payload.popupId),
      });
    case types.APPEND_NOTIFICATIONS:
      return Object.assign({}, state, {
        notifications: state.notifications.concat(payload.item.results),
        next: payload.item.next,
        loaded: true,
      });
    case types.NOTIFICATION_DELETED:
      return Object.assign({}, state, {
        notifications: state.notifications.filter(
          (item) => item.id !== payload.id
        ),
      });
    case types.SET_REQUESTS:
      return Object.assign({}, state, { revealRequests: payload.data });
    case types.EDIT_FRIEND_REQUEST:
      return Object.assign({}, state, {
        notifications: editNotifications(payload.data, state.notifications),
      });
    default:
      return state;
  }
};
