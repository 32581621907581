import React from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as usersActions from "data/users/actions";
import { userById } from "data/users/helper";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ConfirmModal from "components/ConfirmModal";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import ErrorBox from "components/ErrorBox";
import InfiniteLoader from "components/react-infinite-loader";
import Spinner from "components/Spinner";
import UserImage from "components/UserImage";
import * as wishControllActions from "components/WishControllers/actions";
import WishList from "components/WishList";
import * as friendActions from "scenes/Friends/actions";
import NotFound from "scenes/NotFound";
import CustomWishButton from "scenes/Wish/scenes/Create/components/Selection/components/CustomWishButton";
import ProductList from "scenes/Wish/scenes/Create/components/Selection/components/ProductList";
import SearchBox from "scenes/Wish/scenes/Create/components/Selection/components/SearchBox";
import FormattedHTMLMessage from "services/localization/flex";

import Share from "../../../components/FacebookLogin/share";
import * as constants from "../MyWishes/constants";

import * as actions from "./actions";

class User extends Share {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      modalTitle: "",
      modalType: modalTypes.DEFAULT,
      friend: null,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({
      isOpenModal: false,
    });
  }

  isFriend(idU) {
    if (this.props.user.loggedIn) {
      return this.props.friends.includes(parseInt(idU, 10));
    }
    return false;
  }

  componentDidMount() {
    this.getSelectedUser();
  }

  componentWillMount() {
    const userId = parseInt(this.props.match.params.userId, 10);

    this.getUserWishes();
    this.props.actions.getUserDetail(
      this.props.user,
      this.isFriend(userId),
      userId
    );

    if (this.props.user.loggedIn) {
      this.props.friendActions.getRequestedFriendships(this.props.user);
      this.props.friendActions.getFriendshipRequests(this.props.user);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.userId !== nextProps.match.params.userId) {
      this.props.actions.reset();
      this.loadNewPage(nextProps.match.params.userId);
    }
  }

  componentWillUnmount() {
    this.props.actions.reset();
  }

  getUserWishes = () => {
    this.props.actions.reset();
    this.props.actions.setWishesLoading(true);
    this.props.actions.fetchUserWishesPage(
      this.props.user,
      this.props.match.params.userId,
      1,
      this.props.wishState
    );
  };

  renderUserId() {
    const url = window.location.href;
    const urlList = url.split("/");
    return urlList[urlList.length - 1];
  }

  getSelectedUser() {
    this.props.wishActions.setRecipient(this.props.users[this.renderUserId()]);
  }

  loadNewPage(userId) {
    if (this.props.hasMore) {
      this.props.actions.setWishesLoading(true);
      const page = this.props.lastDownloadedPage + 1;
      this.props.actions.fetchUserWishesPage(
        this.props.user,
        userId,
        page,
        this.props.wishState
      );
    }
  }

  changeWishView(wishState) {
    this.props.actions.reset();
    this.props.actions.setWishesLoading(true);
    this.props.actions.setWishState(wishState);
    this.props.actions.fetchUserWishesPage(
      this.props.user,
      this.props.match.params.userId,
      1,
      wishState
    );
  }

  getWishAuthorInfo(idU) {
    const id = parseInt(idU, 10);
    if (id === this.props.user.id) {
      return this.props.user;
    }
    this.props.usersActions.updateUsers(
      this.props.user,
      this.props.users,
      id,
      this.props.defaultUser
    );
    return userById(id, this.props.users, this.props.defaultUser);
  }

  renderPages() {
    return this.props.feed.map((page, index) => (
      <WishList
        key={index}
        user={this.props.user}
        list={page}
        friends={this.props.friends}
        feed
      />
    ));
  }

  renderUserWishes(user) {
    if (this.props.error) {
      return <ErrorBox mainContent onClick={this.getUserWishes} />;
    }
    const hasWishes = this.props.feed.length;

    if (this.props.isFetching) {
      return (
        <div className="main-section-feed flex-column justify-center">
          {this.renderPages()}
          <InfiniteLoader
            onVisited={() => this.loadNewPage(this.props.match.params.userId)}
          />
          <Spinner />
        </div>
      );
    }

    if (!hasWishes) {
      return (
        <div className="main-section-feed">
          <div className="sharebox">
            <FormattedHTMLMessage id="user.noWishes" values={{ name: user }} />
          </div>
          {this.props.friends.includes(this.props.match.params.userId) ? (
            <div>
              <SearchBox user_wish={user} />
              <CustomWishButton />
              <ProductList />
            </div>
          ) : (
            <div>
              <FormattedHTMLMessage id="user.no_friend" />
            </div>
          )}
        </div>
      );
    }
    const message = "user.profile.non-logged.hasWishes";
    return (
      <div className="main-section-feed">
        {!this.props.user.loggedIn ? (
          <div className="main-section-feed">
            <div className="sharebox">
              <FormattedHTMLMessage id="user.profile.non-logged" />
              {hasWishes ? (
                <FormattedHTMLMessage id={message} values={{ name: user }} />
              ) : (
                <FormattedHTMLMessage
                  id="user.noWishes"
                  values={{ name: user }}
                />
              )}
            </div>
          </div>
        ) : null}

        {this.renderPages()}
        <InfiniteLoader
          onVisited={() => this.loadNewPage(this.props.match.params.userId)}
        />
      </div>
    );
  }

  sentRequest(user) {
    for (let i = 0; i < this.props.friendshipsRequested.length; i++) {
      if (user.id === this.props.friendshipsRequested[i].friend) {
        return true;
      }
    }

    return false;
  }

  renderCreateShareProfile(user) {
    return (
      <a
        className="button button--facebook fill"
        onClick={() => {
          this.openShareProfile(user, this.props.user);
        }}
      >
        <FormattedMessage id="common.share" />
      </a>
    );
  }

  renderCreateWishButton(user) {
    if (!this.props.user.loggedIn || user === null) {
      return null;
    }
    if (this.props.friends.includes(user.id)) {
      return (
        <div>
          <Link
            to={`/wish/create/${user.id}`}
            className="button button--primary"
          >
            <FormattedMessage id="menu.createWish" />
          </Link>
          <br />
          <br />
        </div>
      );
    }
    return null;
  }

  declineFriendshipRequest(user) {
    const self = this;
    this.props.friendshipsRequested.forEach((request) => {
      if (request.friend === user.id) {
        self.props.friendActions.declineFriendshipRequest(
          self.props.user,
          request.id
        );
      }
    });
  }

  checkRequests(user) {
    const requests = this.props.friendRequests;
    for (let i = 0; requests && i < requests.length; i++) {
      if (requests[i].user.id === user.id) {
        return requests[i];
      }
    }
    return null;
  }

  renderManageFriend(user) {
    let className = "button button--next";
    let id = "user.add_user";
    let requestSent = false;

    if (!this.props.user.loggedIn || user === null) {
      return null;
    }

    const request = this.checkRequests(user);
    if (request) {
      return (
        <div>
          <h4>
            <FormattedMessage id="user.friendship_request.title" />
          </h4>
          <div>
            <button
              onClick={() =>
                this.props.friendActions.handleRequest(
                  request.id,
                  "accepted",
                  this.props.user
                )
              }
              className="button button--next"
            >
              <FormattedMessage id="friends.acceptRequest" />
            </button>
            <button
              onClick={() =>
                this.props.friendActions.handleRequest(
                  request.id,
                  "deny",
                  this.props.user
                )
              }
              className="button button--delete"
            >
              <FormattedMessage id="friends.declineRequest" />
            </button>
          </div>
        </div>
      );
    }

    if (this.props.friends.includes(user.id)) {
      return (
        <button
          onClick={() => this.removeFriend(user)}
          className="button button--delete"
        >
          <FormattedMessage id="user.remove_user" />
        </button>
      );
    }
    if (this.sentRequest(user)) {
      className = "button";
      id = "user.request_sent";
      requestSent = true;
    }

    return (
      <button
        className={className}
        onClick={
          requestSent
            ? () => this.declineFriendshipRequest(user)
            : () => this.addFriend(user)
        }
      >
        <FormattedMessage id={id} />
      </button>
    );
  }

  addFriend(user) {
    this.props.friendActions
      .sendFriendshipRequest(this.props.user, user)
      .then(() => {});
  }

  removeFriend(user) {
    this.props.friendActions
      .removeFromFriends(this.props.user, user.friendship_id)
      .then(() => {
        this.setState({ friend: user });
        this.setState({ modalTitle: "Friend removed" });
        this.setState({ isOpenModal: true });
        this.setState({ modalType: modalTypes.REMOVE_FRIEND });
      });
  }

  render() {
    const user = this.getWishAuthorInfo(this.props.match.params.userId);
    if (typeof user.fetchSuccess === "boolean" && !user.fetchSuccess) {
      return <NotFound />;
    }
    const msg =
      user !== null ? `${user.first_name} ${user.last_name} |  Wowee` : "";

    return (
      <DocumentTitle title={msg}>
        <div className="app-section">
          {this.renderUserWishes(user)}
          <aside className="sidebar hidden-mobile">
            <div className="settings stick">
              <div className="settings-sidebar">
                <UserImage className="settings-sidebar__avatar" user={user} />
                <h4 className="settings-sidebar__name">
                  <FormattedHTMLMessage
                    id="user.name"
                    values={{
                      user,
                    }}
                  />
                </h4>
                <h3 className="settings-sidebar__meta">
                  <FormattedHTMLMessage
                    id="user.profile"
                    values={{ name: user }}
                  />
                </h3>
                {this.renderCreateWishButton(user)}
                {this.renderCreateShareProfile(user)}
                <ul className="list stats-list">
                  <li
                    className="list__item stats-list__item"
                    onClick={() => this.changeWishView(constants.STATE_OPEN)}
                  >
                    <span className="list__title">
                      <FormattedMessage id="settings.activeGifts" />
                    </span>
                    <span className="list__item-description">
                      {this.props.userDetail
                        ? this.props.userDetail.stat_gifts_opened
                        : null}
                    </span>
                  </li>
                  <li
                    className="list__item stats-list__item"
                    onClick={() =>
                      this.changeWishView(constants.STATE_I_CREATED)
                    }
                  >
                    <span className="list__title">
                      <FormattedMessage id="settings.createdGifts" />
                    </span>
                    <span className="list__item-description">
                      {this.props.userDetail
                        ? this.props.userDetail.stat_gifts_created
                        : null}
                    </span>
                  </li>
                  <li
                    className="list__item stats-list__item"
                    onClick={() =>
                      this.changeWishView(constants.STATE_I_CREATED_SURPRISE)
                    }
                  >
                    <span className="list__title">
                      <FormattedMessage id="settings.createdSuprises" />
                    </span>
                    <span className="list__item-description">
                      {this.props.userDetail
                        ? this.props.userDetail.stat_surprises_created
                        : null}
                    </span>
                  </li>
                  <li
                    className="list__item stats-list__item"
                    onClick={() =>
                      this.changeWishView(constants.STATE_FINISHED)
                    }
                  >
                    <span className="list__title">
                      <FormattedMessage id="settings.finishedWishes" />
                    </span>
                    <span className="list__item-description">
                      {this.props.userDetail
                        ? this.props.userDetail.stat_gifts_finished
                        : null}
                    </span>
                  </li>
                </ul>
                {this.renderManageFriend(user)}
              </div>
            </div>
            <ConfirmModal
              title={this.state.modalTitle}
              isOpen={this.state.isOpenModal}
              type={this.state.modalType}
              closeModal={this.closeModal}
              friend={this.state.friend}
            />
          </aside>
        </div>
      </DocumentTitle>
    );
  }
}
User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    loggedIn: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    fetchUserWishesPage: PropTypes.func,
    reset: PropTypes.func,
    setWishesLoading: PropTypes.func,
  }).isRequired,
  usersActions: PropTypes.shape({
    updateUsers: PropTypes.func,
  }).isRequired,
  defaultUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
  }).isRequired,
  feed: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        author: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
          gender: PropTypes.string,
          image: PropTypes.string,
        }),
        date_completed: PropTypes.string,
        surprise_wish: PropTypes.shape({}),
        ammount_gathered: PropTypes.number,
        description: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ).isRequired,
        amount_needed: PropTypes.number,
      })
    )
  ),
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  lastDownloadedPage: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

User.defaultProps = {
  feed: [],
};

const mapStateToProps = (state, ownProps) => ({
  feed: state.userView.wishPages,
  hasMore: state.userView.hasMore,
  isFetching: state.userView.loading,
  error: state.userView.apiError,
  lastDownloadedPage: state.userView.lastDownloadedPage,
  userDetail: state.userView.userDetail,
  user: state.user,
  users: state.users.all,
  friends: state.friends.friends.all,
  defaultUser: {
    first_name: state.intl.messages["mock.user.first_name"],
    last_name: state.intl.messages["mock.user.last_name"],
  },
  friendshipsRequested: state.friends.friends.friendshipsRequested,
  friendRequests: state.friends.friends.friendRequests,
  wishState: state.userView.wishState,
});

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    wishActions: bindActionCreators(wishControllActions, dispatch),
    friendActions: bindActionCreators(friendActions, dispatch),
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(User));
