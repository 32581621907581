import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as appActions from "components/App/actions";
import * as modalActions from "components/ConfirmModal/actions";
import * as types from "components/ConfirmModal/actionTypes";

class SetupPartnersPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordAgain: "",
      passwordFlag: 2,
      areTheSame: 2,
      email: null,
      used_hash: false,
      error: false,
    };
  }

  getHashID() {
    return window.location.pathname.split("/")[2];
  }

  componentWillMount() {
    this.props.actions
      .getEmailByHash(this.getHashID())
      .then((data) => {
        this.setState({
          email: data.email,
          used_hash: data.used,
        });
      })
      .catch((e) => {
        if (e.errorCode === 404) {
          this.setState({
            error: true,
          });
        }
      });
  }

  componentDidMount() {
    if (this.props.user.loggedIn) {
      this.props.modalActions.open({
        title: "",
        type: types.YOU_ARE_LOGGED,
      });
    }
  }

  componentWillUnmount() {}

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
      passwordFlag: e.target.value.length > 5 ? 1 : 0,
    });
  };

  onChangePasswordAgain = (e) => {
    this.setState({
      passwordAgain: e.target.value,
      areTheSame: e.target.value === this.state.password ? 1 : 0,
    });
  };

  getStateMessage(flag) {
    if (flag === 0) {
      return (
        <p className="form__description form__description--danger">
          <FormattedMessage id="login.password.invalid" />
        </p>
      );
    }

    if (flag === 1) {
      return (
        <p className="form__description form__description--success">
          <FormattedMessage id="login.password.valid" />
        </p>
      );
    }

    return <p></p>;
  }

  areTheSame() {
    if (this.state.areTheSame === 1) {
      return (
        <p className="form__description form__description--success">
          <FormattedMessage id="login.password.match" />
        </p>
      );
    }

    if (this.state.areTheSame === 0) {
      return (
        <p className="form__description form__description--danger">
          <FormattedMessage id="login.password.dont_match" />
        </p>
      );
    }

    return <p></p>;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (
      this.state.areTheSame &&
      this.state.passwordFlag &&
      this.state.password.length > 0
    ) {
      this.props.appActions.addTransition("/");
      this.props.actions.setNewPassword(
        this.state.password,
        this.state.passwordAgain,
        this.getHashID()
      );
    }
  };

  renderUsedHash() {
    return (
      <div className="login-text">
        <p>
          <FormattedMessage id="login.new_password.used_hash" />
        </p>
        <p className="login-text__link">
          <Link to={"/login"}>
            <FormattedMessage id="login.new_password.login" />
          </Link>
        </p>
      </div>
    );
  }

  renderError() {
    return (
      <div className="login-text">
        <p>
          <FormattedMessage id="login.new_password.error" />
        </p>
        <p className="login-text__link">
          <Link to={"/"}>
            <FormattedMessage id="login.new_password.error.login" />
          </Link>
        </p>
      </div>
    );
  }

  renderSetNewPassword() {
    return (
      <div className="login-form">
        <h1 className="login-form__title">
          <FormattedMessage id="general.set-up-new-password-for" />
        </h1>
        {/*
            <h2>pro účet <span>{this.state.email}</span></h2>
          */}
        <h2>{this.state.email}</h2>
        <form className="form">
          <div className="form__row">
            <label className="form__label">
              <FormattedMessage id="common.password" />
            </label>
            <input
              type="password"
              value={this.state.password}
              placeholder=""
              className="form__field"
              onChange={this.onChangePassword}
            />
            {this.getStateMessage(this.state.passwordFlag)}
          </div>

          <div className="form__row">
            <label className="form__label">
              <FormattedMessage id="login.password.again" />
            </label>
            <input
              type="password"
              value={this.state.passwordAgain}
              placeholder=""
              className="form__field"
              onChange={this.onChangePasswordAgain}
            />
            {this.areTheSame()}
          </div>

          <div className="form__row">
            <input
              type="submit"
              value={this.props.intl.formatMessage({ id: "common.submit" })}
              className="button login-button"
              onClick={this.handleSubmit}
            />
          </div>
        </form>
        <div className="login-footer">
          <p>
            <FormattedMessage id="login.new_password.footer.1" />
            <Link to={`/login`}>
              <FormattedMessage id="login.new_password.footer.2" />
            </Link>
            .
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section className="login main">
        <div className="login-promo">
          <img src="/img/celebrating.png" className="login-promo__img" alt="" />
        </div>
        {this.state.error
          ? this.renderError()
          : this.state.used_hash
          ? this.renderUsedHash()
          : this.renderSetNewPassword()}
      </section>
    );
  }
}

SetupPartnersPassword.propTypes = {
  actions: PropTypes.shape({
    //    createCommentBox: PropTypes.func,
    //    removeCommentBox: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SetupPartnersPassword));
