import React from "react";
import PropTypes from "prop-types";

const DEFAULT_IMAGE = "/img/placeholder-gift.svg";

const EshopProductImage = ({ className, product }) => {
  const productImage = product.image || DEFAULT_IMAGE;
  return (
    <img
      src={productImage}
      onError={(e) => {
        e.target.src = DEFAULT_IMAGE;
      }}
      alt={product.title}
      className={className}
    />
  );
};

EshopProductImage.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

EshopProductImage.defaultProps = {
  className: "",
};
export default EshopProductImage;
