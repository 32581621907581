import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class GDPRHandlers extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    gdpr: PropTypes.object.isRequired, //eslint-disable-line
  };
  render() {
    const { children, gdpr } = this.props;
    const childrenArr = React.Children.toArray(children);
    const handlers = childrenArr.filter(
      (child) => !!child.props.gdprKey && gdpr[child.props.gdprKey]
    );
    return <Fragment>{handlers}</Fragment>;
  }
}

export default GDPRHandlers;
