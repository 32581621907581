import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import ProductImage from "components/ProductImage";

class WishesList extends Component {
  handleLinkToItem(url) {
    this.props.history.push(url);
  }

  render() {
    const { list } = this.props;

    const Rows = list.map((item, i) => {
      return (
        <div
          onMouseDown={() => this.handleLinkToItem(`/wish/detail/${item.id}`)}
          className="search-helper__item"
          key={i}
        >
          <div className="search-helper__item-img">
            <ProductImage wish={item} />
          </div>
          <h1 className="search-helper__item-name">{item.title}</h1>
          <h1 className="search-helper__item-name search-helper__item-name--secondary">
            pro
          </h1>
          <h1 className="search-helper__item-name search-helper__item-name--secondary">
            {item.user_money_receiver.first_name}
          </h1>
          <h1 className="search-helper__item-name search-helper__item-name--secondary">
            {item.user_money_receiver.last_name}
          </h1>
        </div>
      );
    });

    return <React.Fragment>{Rows}</React.Fragment>;
  }
}

export default withRouter(WishesList);
