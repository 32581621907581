import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import * as modalTypes from "components/ConfirmModal/actionTypes";
import { setStep } from "components/Containers/MultiStep/actions";
import Share from "components/FacebookLogin/share";
import * as feedActions from "scenes/Home/components/Wishes/components/Feed/actions";
import * as userViewActions from "scenes/User/UserView/actions";
import * as wishActions from "scenes/Wish/scenes/Detail/actions";
import * as donationActions from "scenes/Wish/scenes/Detail/components/Donation/actions";
import FormattedHTMLMessage from "services/localization/flex";

class WishDropdownMenu extends Share {
  handleHideWish() {
    const id = this.props.wishToModify.id;
    if (this.props.user.id === this.props.wishToModify.author.id) {
      // alert('Can not hide your own wish');
      return;
    }
    this.props.feedActions.hideWishFromFeed(id, this.props.user);
    this.props.userViewActions.receiveWishes(
      this.hideWishFromArray(this.props.userView.wishes, id)
    );
    this.props.feedActions.receiveFeed(
      this.hideWishFromArray(this.props.feed, id)
    );
  }
  hideWishFromArray(arr, id) {
    const feed = arr.slice();
    const tmp = [];
    for (let i = 0; i < feed.length; i += 1) {
      if (feed[i].id !== id) {
        tmp.push(feed[i]);
      }
    }
    return tmp;
  }
  handleHideUser() {
    const id = this.props.wishToModify.author.id;
    if (this.props.user.id === id) {
      // alert('Can not hide from yourself');
      return;
    }
    this.props.feedActions.hideUserFromFeed(id, this.props.user);
    const feed = this.props.feed.slice();
    const tmp = [];
    for (let i = 0; i < feed.length; i += 1) {
      if (feed[i].author !== id) {
        tmp.push(feed[i]);
      }
    }
    this.props.feedActions.receiveFeed(tmp);
  }
  handleCopyWish() {
    const {
      amount_needed,
      description,
      images,
      product_url,
      title,
      surprise_wish,
    } = this.props.wishToModify;

    const img =
      images.length !== 0 && images[0].image !== null ? images[0].image : "";

    const params = {
      title,
      amount: amount_needed,
      image: img,
      productURL: product_url,
      description,
      surprise_wish:
        surprise_wish && surprise_wish.recipient != undefined
          ? surprise_wish.recipient.id
          : "",
    };

    const stringified = queryString.stringify(params);
    this.props.history.push(`/wish/create/settings?${stringified}`);

    this.props.setStep("wish_create", "settings");
  }

  handleDelete() {
    const wishId = this.props.wishToModify.id;
    const token = this.props.user.accessToken;
    const routeTo = `/user/${this.props.user.id}/`;
    this.props.wishActions.deleteWish(wishId, token, routeTo);
    if (this.props.userView.wishes) {
      // TODO wishes je pri smazani prazdny - toto je jenom hotfix
      this.props.userViewActions.receiveWishes(
        this.hideWishFromArray(this.props.userView.wishes, wishId)
      );
      this.props.feedActions.receiveFeed(
        this.hideWishFromArray(this.props.feed, wishId)
      );
    }

    if (window.location.pathname === "/") {
      this.props.feedActions.reset();
      this.props.feedActions.fetchFeedPage(this.props.user, 1);
      this.props.feedActions.setFeedLoading(true);
    }

    this.props.closeConfirmModal();

    setTimeout(() => {
      this.props.openConfirmModal(
        () => {},
        this.props.intl.formatMessage({
          id: "wish.dropdownSuccesfullyDeletedWish.description",
        }),
        modalTypes.INFORMATION
      );
    }, 1000);
  }
  handleRefund() {
    const wishId = this.props.wishToModify.id;
    const token = this.props.user.accessToken;
    this.props.wishActions.refundWish(wishId, token).then(() => {
      this.props.closeConfirmModal();
    });
  }

  /*
  showDonators = (evt) => {
    this.props.donationActions.getWishDonators(this.props.user, this.props.wishToModify.id).then(() => {
      this.props.openConfirmModal(() => {}, '', modalTypes.SHOW_DONATORS);
    });
    evt.preventDefault();
  }
  */

  setMoneyReceiver() {
    this.props.openConfirmModal(() => {}, "", modalTypes.USER_MONEY_RECEIVER);
  }

  ownerOption() {
    const out = (
      <ul>
        {this.props.wishToModify.wish_state === "open" ? (
          <li className="dropdown__menu-item">
            <a
              onClick={() =>
                this.props.history.push(
                  `/wish/edit/${this.props.wishToModify.id}`
                )
              }
              className="dropdown__menu-link"
            >
              <FormattedMessage id="wish.dropdownEdit.title" />
              <span className="description">
                <FormattedHTMLMessage id="wish.dropdownEdit.description" />
              </span>
            </a>
          </li>
        ) : null}
        {(this.props.wishToModify.amount_gathered === 0 &&
          this.props.wishToModify.wish_state === "open") ||
        this.props.wishToModify.wish_state === "transfered" ? (
          <li className="dropdown__menu-item">
            <a
              onClick={() =>
                this.props.openConfirmModal(
                  this.handleDelete.bind(this),
                  "Delete",
                  modalTypes.DELETE
                )
              }
              href="##"
              className="dropdown__menu-link"
            >
              <FormattedMessage id="wish.dropdownDelete.title" />
              <span className="description">
                <FormattedHTMLMessage id="wish.dropdownDelete.description" />
              </span>
            </a>
          </li>
        ) : null}
        {/*}
        {this.props.wishToModify.amount_gathered > 0 && this.props.wishToModify.wish_state === 'open' ?
            (
              <li className="dropdown__menu-item">
                <a
                  onClick={() => this.props.openConfirmModal(this.handleClose.bind(this), 'Do you really want to terminate this wish?', modalTypes.CLOSE)}
                  href="##"
                  className="dropdown__menu-link"
                >
                  <FormattedMessage id="wish.dropdownTerminate.title" />
                  <span className="description">
                    <FormattedHTMLMessage id="wish.dropdownTerminate.description" />
                  </span>
                </a>
              </li>
            ) : null
        }
        */}
        {/*
        {this.props.wishToModify.amount_gathered > 0 && this.props.wishToModify.wish_state === 'open' ?
          (
            <li className="dropdown__menu-item">

              <a
                onClick={() => this.props.openConfirmModal(this.handleRefund.bind(this), 'Do you really want to refund this wish?', modalTypes.REFUND)}
                href="##"
                className="dropdown__menu-link"
              >
                <FormattedMessage id="wish.dropdownRefund.title" />
                <span className="description">
                  <FormattedHTMLMessage id="wish.dropdownRefund.description" />
                </span>
              </a>
            </li>
          ) : null
        }
            */}
        <li className="dropdown__menu-item">
          <a
            onClick={() => this.handleCopyWish()}
            className="dropdown__menu-link"
          >
            <FormattedMessage id="wish.dropdownCopy.title" />
            <span className="description">
              <FormattedMessage id="wish.dropdownCopy.description" />
            </span>
          </a>
        </li>
        {/*
        <li className="dropdown__menu-item">
          <a onClick={this.showDonators} href="#" className="dropdown__menu-link">
            <FormattedMessage id="wish.dropdownShowDonators.title" />
            <span className="description">
              <FormattedMessage id="wish.dropdownShowDonators.description" />
            </span>
          </a>
        </li>
        */}
        {/*this.props.wishToModify.wish_state === 'open' &&
          <li className="dropdown__menu-item">
            <a onClick={() => this.setMoneyReceiver()} href="#" className="dropdown__menu-link">
              <FormattedMessage id="wish.dropdownSetMoneyReceiver.title"/>
              <span className="description">
                <FormattedMessage id="wish.dropdownSetMoneyReceiver.description"/>
              </span>
            </a>
          </li>
        */}
        {this.props.wishToModify.wish_state === "open" && (
          <li className="dropdown__menu-item">
            <a
              onClick={() =>
                this.shareWish(this.props.wishToModify, null, this.props.user)
              }
              href="#"
              className="dropdown__menu-link"
            >
              <FormattedMessage id="wish.dropdownShare.title" />
              <span className="description">
                <FormattedMessage id="wish.dropdownShare.description" />
              </span>
            </a>
          </li>
        )}
      </ul>
    );
    return out;
  }

  normalOption() {
    return (
      <ul>
        {/*
        <li className="dropdown__menu-item" hidden={!this.props.isFeed}>
          <a
            onClick={() => this.handleHideWish()}
            href="##"
            className="dropdown__menu-link"
          >
            <FormattedMessage id="wish.dropdownHideWish.title" />
            <span className="description">
              <FormattedHTMLMessage id="wish.dropdownHideWish.description" />
            </span>
          </a>
        </li>
        <li className="dropdown__menu-item" hidden={!this.props.isFeed}>
          <a
            onClick={() => this.handleHideUser()}
            href="##"
            className="dropdown__menu-link"
          >
            <FormattedMessage id="wish.dropdownHideUser.title" />
            <span className="description">
              <FormattedHTMLMessage
                id="wish.dropdownHideUser.description"
                values={{ name: this.props.author.first_name }}
              />
            </span>
          </a>
        </li>
        */}
        <li className="dropdown__menu-item">
          <a
            onClick={() => this.handleCopyWish()}
            href="##"
            className="dropdown__menu-link"
          >
            <FormattedMessage id="wish.dropdownCopy.title" />
            <span className="description">
              <FormattedMessage id="wish.dropdownCopy.description" />
            </span>
          </a>
        </li>
        {/* <li className="dropdown__menu-item">
          <a onClick={this.showDonators} href="#" className="dropdown__menu-link">
            <FormattedMessage id="wish.dropdownShowDonators.title" />
            <span className="description">
              <FormattedMessage id="wish.dropdownShowDonators.description" />
            </span>
          </a>
        </li> */}
        {this.props.wishToModify.wish_state === "open" && (
          <li className="dropdown__menu-item">
            <a
              onClick={() =>
                this.shareWish(
                  this.props.wishToModify,
                  this.props.wishToModify.author,
                  this.props.user
                )
              }
              href="#"
              className="dropdown__menu-link"
            >
              <FormattedMessage id="wish.dropdownShare.title" />
              <span className="description">
                <FormattedMessage id="wish.dropdownShare.description" />
              </span>
            </a>
          </li>
        )}
      </ul>
    );
  }

  getOpenedContainerClass() {
    if (this.props.isFeed) {
      return "dropdown dropdown--open wishes-item__menu";
    }
    return "dropdown dropdown--open context-menu";
  }

  renderOpen() {
    const isOwner = this.props.user.id === this.props.wishToModify.author.id;
    return (
      <div
        onMouseLeave={this.props.close}
        className="context-menu dropdown-open"
      >
        <div className={this.getOpenedContainerClass()}>
          <a
            href="##"
            className="dropdown__button"
            onClick={() => this.props.close()}
          >
            <img src="/img/dropdown.svg" alt="" />
          </a>
        </div>
        <div
          className={`context-menu dropdown__menu ${
            this.props.isFeed ? "" : "fix-Hoffman-mistakes"
          }`}
        >
          {isOwner ? this.ownerOption() : this.normalOption()}
        </div>
      </div>
    );
  }

  renderClosed() {
    if (this.props.isFeed) {
      return (
        <div className="dropdown context-menu">
          <a className="dropdown__button">
            <button className="no-style" onClick={() => this.props.open()}>
              <img src="/img/dropdown.svg" alt="" />
            </button>
          </a>
        </div>
      );
    }
    return (
      <div className="dropdown context-menu">
        <a className="dropdown__button">
          <button className="no-style" onClick={() => this.props.open()}>
            <img src="/img/dropdown.svg" alt="" />
          </button>
        </a>
      </div>
    );
  }

  render() {
    if (this.props.isOpen) {
      return this.renderOpen();
    }
    return this.renderClosed();
  }
}
WishDropdownMenu.propTypes = {
  author: PropTypes.shape({
    first_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  feedActions: PropTypes.shape({
    hideUserFromFeed: PropTypes.func,
    hideWishFromFeed: PropTypes.func,
    receiveFeed: PropTypes.func,
    setDropDownOpen: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,
  feed: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        author: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          gender: PropTypes.string,
          image: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
        }),
        description: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ).isRequired,
        amount_needed: PropTypes.number,
      })
    )
  ).isRequired,
  wishToModify: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string,
      image: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
    description: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ).isRequired,
    amount_needed: PropTypes.number,
    amount_gathered: PropTypes.number,
    wish_state: PropTypes.string,
  }).isRequired,
  userView: PropTypes.shape({
    wishes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
  }).isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  closeConfirmModal: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isFeed: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  wishActions: PropTypes.shape({
    deleteWish: PropTypes.func,
    closeWish: PropTypes.func,
    refundWish: PropTypes.func,
  }).isRequired,
  userViewActions: PropTypes.shape({
    getWishes: PropTypes.func,
    receiveWishes: PropTypes.func,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    feedActions: bindActionCreators(feedActions, dispatch),
    wishActions: bindActionCreators(wishActions, dispatch),
    userViewActions: bindActionCreators(userViewActions, dispatch),
    donationActions: bindActionCreators(donationActions, dispatch),
    setStep: bindActionCreators(setStep, dispatch),
  };
}

const mapStateToProps = (state) => ({
  feed: state.feed.feed_pages,
  user: state.user,
  userView: state.userView,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(WishDropdownMenu))
);
