/**
 * Created by rovnarud
 */
import React from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as userActions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ConfirmModal from "components/ConfirmModal";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import * as gdprActions from "components/GDPR/actions";
import Spinner from "components/Spinner";
import UserImage from "components/UserImage";
import * as myWishActions from "scenes/User/MyWishes/actions";
import * as constants from "scenes/User/MyWishes/constants";
import FormattedHTMLMessage from "services/localization/flex";

import { Share } from "../../../../components/FacebookLogin/share";
import * as settingsActions from "../../actions";

class SettingsSidebar extends Share {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      modalTitle: "",
      modalType: modalTypes.DEFAULT,
      modalCallback: () => {},
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleChangePicture = this.handleChangePicture.bind(this);
  }

  componentDidMount() {
    this.props.actions.getWishes(this.props.user);
  }

  handleClick() {
    this.setState({ modalTitle: "Delete" });
    this.setState({ isOpenModal: true });
    this.setState({ modalType: modalTypes.DELETE_ACCOUNT });
    this.setState({ modalCallback: this.deleteAccount.bind(this) });
  }

  closeModal() {
    this.setState({
      isOpenModal: false,
    });
  }

  deleteAccount() {
    this.props.actions.deleteAccount(this.props.user).then(() => {
      this.props.userActions.logout();
      this.closeModal();
    });
  }

  handleChangePicture = () => {
    this.setState({ isOpenModal: true });
    this.setState({ modalType: modalTypes.CHANGE_PICTURE });
  };

  changeWishView(wishState) {
    this.props.myWishActions.reset();
    this.props.myWishActions.setMyWishesLoading(true);
    this.props.myWishActions.setWishState(wishState);
    this.props.myWishActions.fetchWishesPage(this.props.user, 1, wishState);
  }

  render() {
    if (!this.props.loading) {
      return (
        <aside className="settings-sidebar">
          <div>
            <UserImage
              className="settings-sidebar__avatar"
              user={this.props.user}
              onClick={
                this.props.user.FBaccessToken ? null : this.handleChangePicture
              }
            />
          </div>
          <div>
            <h3 className="settings-sidebar__meta">
              <FormattedMessage id="settings.loggedIn" />
            </h3>
            <h4 className="settings-sidebar__name">
              {this.props.user.first_name}&nbsp;
              {this.props.user.last_name}
            </h4>
            {this.props.showAccessRequests && (
              <Link className="settings-sidebar__access" to="/access-requests">
                <FormattedMessage id="settings.access_requests" />
              </Link>
            )}
            <p>
              <br />
              <a
                className="button button-fb fill"
                onClick={() => {
                  this.openShareProfile(this.props.user, this.props.user);
                }}
              >
                <FormattedMessage id="common.share" />
              </a>
              <br />
            </p>

            <ul className="list stats-list">
              <li
                className="list__item stats-list__item"
                onClick={() => this.changeWishView(constants.STATE_OPEN)}
              >
                <span className="list__title">
                  <FormattedMessage id="settings.activeGifts" />
                </span>
                <span className="list__item-description">
                  {this.props.user.stat_gifts_opened}
                </span>
              </li>
              <li
                className="list__item stats-list__item"
                onClick={() => this.changeWishView(constants.STATE_I_CREATED)}
              >
                <span className="list__title">
                  <FormattedMessage id="settings.createdGifts" />
                </span>
                <span className="list__item-description">
                  {this.props.user.stat_gifts_created}
                </span>
              </li>
              <li
                className="list__item stats-list__item"
                onClick={() =>
                  this.changeWishView(constants.STATE_I_CREATED_SURPRISE)
                }
              >
                <span className="list__title">
                  <FormattedMessage id="settings.createdSuprises" />
                </span>
                <span className="list__item-description">
                  {this.props.user.stat_surprises_created}
                </span>
              </li>
              <li
                className="list__item stats-list__item"
                onClick={() => this.changeWishView(constants.STATE_FINISHED)}
              >
                <span className="list__title">
                  <FormattedMessage id="settings.finishedWishes" />
                </span>
                <span className="list__item-description">
                  {this.props.user.stat_gifts_finished}
                </span>
              </li>
              <li
                className="list__item stats-list__item"
                onClick={() => this.changeWishView(constants.STATE_I_DONATED)}
              >
                <span className="list__title">
                  <FormattedHTMLMessage
                    id="settings.totalDonated"
                    values={{
                      currency: this.props.user.currency.toUpperCase(),
                      user: this.props.user,
                    }}
                  />
                </span>
                <span className="list__item-description">
                  {this.props.user.stat_total_donated}
                </span>
              </li>
              <li
                className="list__item stats-list__item"
                onClick={() =>
                  this.changeWishView(constants.STATE_DONATED_TO_ME)
                }
              >
                <span className="list__title">
                  <FormattedHTMLMessage
                    id="settings.totalGathered"
                    values={{
                      currency: this.props.user.currency.toUpperCase(),
                      user: this.props.user,
                    }}
                  />
                </span>
                <span className="list__item-description">
                  {this.props.user.stat_total_gathered}
                </span>
              </li>
            </ul>
            <br />
          </div>

          {this.props.showDelete && (
            <a onClick={() => this.handleClick()} className="hidden-mobile">
              <FormattedMessage id="settings.delete_account" />
            </a>
          )}
          {this.props.showAccessRequests && (
            <Link to="/access-requests" class="hidden-mobile">
              <FormattedMessage id="settings.access_requests" />
            </Link>
          )}
          {this.props.showGdpr && (
            <div>
              <a
                onClick={() =>
                  this.props.gdprActions.fetchGdpr(this.props.user)
                }
              >
                <FormattedMessage id="gdpr.open" />
              </a>
            </div>
          )}

          <ConfirmModal
            title={this.state.modalTitle}
            isOpen={this.state.isOpenModal}
            type={this.state.modalType}
            closeModal={this.closeModal}
            confirmAction={this.state.modalCallback}
          />
        </aside>
      );
    }
    return (
      <section className="main-section feed">
        <Spinner />
      </section>
    );
  }
}

SettingsSidebar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    access_token: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
    hidden_wishes: PropTypes.array,
  }).isRequired,
  gifts: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        author: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          gender: PropTypes.string,
          image: PropTypes.string,
        }),
        surprise_wish: PropTypes.shape({
          recipient: PropTypes.shape({
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            gender: PropTypes.string,
            image: PropTypes.string,
            causes_first_name: PropTypes.shape({}),
            causes_last_name: PropTypes.shape({}),
          }),
        }),
        date_completed: PropTypes.date,
        amount_needed: PropTypes.number,
        amount_gathered: PropTypes.number,
      })
    ),
  }).isRequired,
  actions: PropTypes.shape({
    getWishes: PropTypes.func,
  }).isRequired,
  gdprActions: PropTypes.shape({
    fetchGdpr: PropTypes.func,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  showDelete: PropTypes.bool,
  showAccessRequests: PropTypes.bool,
  showGdpr: PropTypes.bool,
  intl: intlShape.isRequired,
};

SettingsSidebar.defaultProps = {
  showDelete: false,
  showAccessRequests: false,
  showGdpr: false,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    gifts: state.settings.gifts,
    loading: state.settings.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingsActions, dispatch),
    gdprActions: bindActionCreators(gdprActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    myWishActions: bindActionCreators(myWishActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SettingsSidebar));
