import React from "react";
import Props from "prop-types";

const CommentAttachment = ({ data }) => (
  <div>
    <a
      href={data.url}
      target="_blank"
      className="comment__attachment attachment"
      rel="noreferrer"
    >
      <img className="attachment__image" src={data.small_image} alt="" />
      <div className="attachment__content">
        <h5 className="attachment__title">{data.title}</h5>
        {!!data.price && (
          <span className="attachment__price">
            {data.price} {data.currency}
          </span>
        )}
      </div>
    </a>
  </div>
);

CommentAttachment.propTypes = {
  data: Props.shape({
    title: Props.string,
  }).isRequired,
};

export default CommentAttachment;
