export const SET_WISH = "wishDetailReducer/setWish";
export const SET_RESTRICTED_WISH = "wishDetailReducer/setRestrictedWish";
export const SET_FRIEND = "wishDetailReducer/setFriend";
export const SET_RESTRICTION = "wishDetailReducer/setRestriction";
export const SET_ACCESS_REQUESTS = "wishDetailReducer/setAccessRequests";
export const SET_STATUS = "wishDetailReducer/setStatus";
export const SET_LOADING = "wishDetailReducer/setLoading";
export const STATUS_EMPTY = "wishDetailReducer/status_empty";
export const STATUS_LOADING = "wishDetailReducer/status_loading";
export const STATUS_ERROR_RESTRICTED =
  "wishDetailReducer/status_error_restricted";
export const STATUS_ERROR_FULL = "wishDetailReducer/status_error_full";
export const STATUS_NOT_FOUND = "wishDetailReducer/status_not_found";
export const STATUS_LOADED_RESTRICTED =
  "wishDetailReducer/status_loaded_restricted";
export const STATUS_LOADED_FULL = "wishDetailReducer/status_loaded_full";
export const RESET = "wishDetailReducer/reset";
export const WISH_DELETED = "wishDetailReducer/delete";
export const API_ERROR = "wishDetailReducer/api_error";
export const WISH_REDUCER = "wishDetailReducer";
export const IS_WAITING = "wishDetail/restrictedWish/isWaiting";
