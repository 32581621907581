export const WISHES_PAGE_SIZE = 10;
export const TOGGLE_ACTIVE = "active";
export const TOGGLE_ENDED = "ended";
export const TOGGLE_DELETED = "deleted";
export const STATE_OPEN = "open";
export const STATE_I_DONATED = "iDonated";
export const STATE_DONATED_TO_ME = "donatedToMe";
export const STATE_I_CREATED = "iCreated";
export const STATE_I_CREATED_SURPRISE = "iCreatedSurprise";
export const STATE_FINISHED = "finished";
