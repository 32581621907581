import React, { Component } from "react";

import Stranger from "./Stranger";

class StrangersList extends Component {
  render() {
    const StrangersList = this.props.strangers.map((stranger, i) => {
      return <Stranger stranger={stranger} key={i} index={i} />;
    });

    return (
      <div className="search__item">
        <h1 className="search__item-heading">Others</h1>
        {StrangersList}
      </div>
    );
  }
}

export default StrangersList;
