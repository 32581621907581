import React, { Component, Fragment } from "react";
import ReactGA4 from "react-ga4";
import CookieBot from "react-cookiebot";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { renderRoutes } from "react-router-config";
import * as userActions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";

import * as constants from 'components/App/constants'; // eslint-disable-line
import ConfirmModal from "components/ConfirmModal";
import * as modalActions from "components/ConfirmModal/actions";
import GDPRContainer from "components/GDPR";
import PopupContainer from "components/PopupContainer";
import * as wishActions from "components/WishControllers/actions";
import * as settingsActions from "scenes/Settings/actions";

import Menu from "./components/Menu";
import * as appActions from "./actions";

import "styles/sass/App.css";
const domainGroupId = "e94bf816-2f8d-40f8-8d60-232bf5731c67";

ReactGA.initialize(constants.GA_ID);
ReactGA4.initialize(constants.GA4_ID);
const advancedMatching = { em: "poradna@wowee.cz" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class App extends Component {
  componentDidMount() {
    const url = window.location.pathname + window.location.search;
    ReactGA.pageview(url);
    ReactGA4.send({ hitType: "pageview", page: url})
      import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('370015367622464', advancedMatching, options);
        ReactPixel.pageView();
      });

    if (localStorage) {
      const cookies = new Cookies();
      const api = cookies.get("apiToken") || localStorage.getItem("apiToken");

      const fb = localStorage.getItem("fbToken");
      const secs = localStorage.getItem("expiresIn");
      const lang = localStorage.getItem("language");

      if (lang) {
        this.props.settingsActions.switchLanguage(lang);
      }
      if (api && fb) {
        // if (api && fb && secs) {
        /*
        const t = new Date();
        if (t.getTime() / 1000 >= parseInt(secs, 10)) {
          this.props.actions.autoload();
          return;
        }
         */
        this.props.actions.autoload();
        this.props.actions.loading();
        this.props.userActions.afterLogin(
          fb,
          api,
          this.props.app.addedTransition,
          this.props.app.transitionTo
        );
      }
      if (!fb && api) {
        this.props.userActions.afterEmailLogin(
          api,
          this.props.app.addedTransition,
          this.props.app.transitionTo
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.user.loggedIn !== nextProps.user.loggedIn &&
      nextProps.user.loggedIn === true
    ) {
      if (nextProps.app.addedTransition) {
        if (nextProps.app.transitionTo.startsWith("http")) {
          window.location.href = nextProps.app.transitionTo;
        } else {
          this.props.history.push(nextProps.app.transitionTo);
        }
      }
    }
  }

  render() {
    if (this.props.app.loading) {
      return <div className="loading-circle" />;
    }
    let menu;
    if (
      (this.props.location && this.props.location.pathname !== "/") ||
      this.props.user.loggedIn
    ) {
      menu = <Menu {...this.props} />;
    }

    return (
      <Fragment>
        <CookieBot domainGroupId={domainGroupId} />
        <ConfirmModal
          isOpen={this.props.modal.isOpen}
          title={this.props.modal.title}
          type={this.props.modal.type}
          closeModal={this.props.modalActions.close}
          confirmAction={this.props.modalActions.close}
          className="modal modal--main"
          overlayClassName="overlay overlay--main"
        />
        {menu}
        <PopupContainer />
        <GDPRContainer />
        {this.props.route && renderRoutes(this.props.route.routes)}
      </Fragment>
    );
  }
}

App.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
    FBaccessToken: PropTypes.string,
  }).isRequired,
  app: PropTypes.shape({
    loading: PropTypes.bool,
    transitionTo: PropTypes.string,
    addedTransition: PropTypes.bool,
  }).isRequired,
  modal: PropTypes.shape({
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    addTransition: PropTypes.func,
    autoload: PropTypes.func,
    loading: PropTypes.func,
  }).isRequired,
  userActions: PropTypes.shape({
    afterLogin: PropTypes.func,
    afterEmailLogin: PropTypes.func,
  }).isRequired,
  settingsActions: PropTypes.shape({
    switchLanguage: PropTypes.func,
  }).isRequired,
  modalActions: PropTypes.shape({
    close: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app,
    modal: state.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    actions: bindActionCreators(appActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    wishActions: bindActionCreators(wishActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
