import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import * as actions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as appActions from "components/App/actions";
import * as modalActions from "components/ConfirmModal/actions";
import * as types from "components/ConfirmModal/actionTypes";

import FormattedHTMLMessage from "../../services/localization/flex";
import FacebookLoginLite from "../FacebookLoginLite";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: undefined,
      password: undefined,
    };
  }

  componentDidMount() {
    if (this.props.user.loggedIn) {
      this.props.push("/");
    }
  }

  componentWillUnmount() {}

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  onChangPassWord = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  getErrorMessage = () => {
    if (this.props.user.wrongCredentials) {
      return (
        <p className="form__description form__description--danger">
          <FormattedHTMLMessage id="login.error.credentials" />
        </p>
      );
    }
    return <p className="form__description form__description--danger"></p>;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.password && this.state.email) {
      this.props.appActions.addTransition("/");
      this.props.actions.loginUsingEmail(this.state.email, this.state.password);
    }
  };

  render() {
    return (
      <section className="login main">
        <div className="login-promo">
          <img src="img/celebrating.png" className="login-promo__img" alt="" />
        </div>

        <div className="login-form">
          <h1 className="login-form__title">
            <FormattedMessage id="general.login" />
            ...
          </h1>
          <FacebookLoginLite {...this.props} />
          <h2 className="title-line">
            <span>
              <FormattedMessage id="common.or" />
            </span>
          </h2>
          <form className="form">
            <div className="form__row">
              <label className="form__label">
                <FormattedMessage id="common.email" />
              </label>
              <input
                type="email"
                placeholder={this.props.intl.formatMessage({
                  id: "login.email.placeholder",
                })}
                className="form__field"
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
              <p className="form__description form__description--success"></p>
            </div>

            <div className="form__row">
              <label className="form__label">
                <FormattedMessage id="common.password" />
              </label>
              <input
                type="password"
                placeholder={this.props.intl.formatMessage({
                  id: "login.password.placeholder",
                })}
                className="form__field"
                value={this.state.password}
                onChange={this.onChangPassWord}
              />
              {this.getErrorMessage()}
              <p className="form__description">
                <FormattedMessage id="login.password.forget" />{" "}
                <Link to="/forgotten-password">
                  <FormattedMessage id="login.password.forgotten.reset_it" />
                </Link>
              </p>
            </div>

            <div className="form__row">
              <input
                type="submit"
                value={this.props.intl.formatMessage({ id: "common.submit" })}
                className="button login-button"
                onClick={this.handleSubmit}
              />
            </div>
          </form>
          <div className="login-footer">
            <p>
              <FormattedMessage id="login.no_account.1" />
              <Link to={`/sign-up`}>
                <FormattedMessage id="login.no_account.2" />
              </Link>
              .
            </p>
          </div>
        </div>
      </section>
    );
  }
}

LoginForm.propTypes = {
  return_url: PropTypes.string,

  actions: PropTypes.shape({
    //    createCommentBox: PropTypes.func,
    //    removeCommentBox: PropTypes.func,
  }).isRequired,

  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    push: bindActionCreators(push, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoginForm));
