import * as constatnts from "../MyWishes/constants";

import * as types from "./actionTypes";

export const initialState = {
  wishPages: [],
  loading: false,
  apiError: false,
  hasMore: true,
  lastDownloadedPage: 0,
  userDetail: null,
  wishState: constatnts.STATE_OPEN,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_WISHES:
      const pages = state.wishPages.slice();
      pages.push(payload.item);
      return Object.assign({}, state, { wishPages: pages, loading: false });
    case types.SET_WISHES_LOADING:
      return Object.assign({}, state, { loading: payload.item });
    case types.SET_WISHES_HAS_MORE:
      return Object.assign({}, state, { hasMore: payload.item });
    case types.SET_WISHES_LAST_PAGE:
      return Object.assign({}, state, { lastDownloadedPage: payload.item });
    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true });
    case types.RESET:
      return Object.assign(
        {},
        {
          wishPages: [],
          loading: false,
          apiError: false,
          hasMore: true,
          lastDownloadedPage: 0,
          userDetail: state.userDetail,
          wishState: constatnts.STATE_OPEN,
        }
      );
    case types.SET_USER_DETAIL:
      return Object.assign({}, state, { userDetail: payload.user });
    case types.SET_WISH_STATE:
      return Object.assign({}, state, { wishState: payload.item });
    default:
      return state;
  }
};
