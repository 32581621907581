import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import ProductImage from "components/ProductImage";
import UserImage from "components/UserImage";
import ProgressItem from "components/WishList/components/ProgressItem";
import FormattedHTMLMessage from "services/localization/flex";

import * as constants from "../../constants";

class WishComponent extends React.Component {
  renderButton(type) {
    // 'accept' or 'decline'
    const isAccept = type === "accept";
    const {
      data: { status, changeStatus, id, isAccepting },
      acceptRequest,
      declineRequest,
    } = this.props;
    const isActive = isAccepting === isAccept;
    const alreadyDone = isAccept
      ? status === constants.GRANTED
      : status === constants.REJECTED;

    const disabled = alreadyDone || changeStatus === constants.LOADING;
    const callback = isAccept
      ? () => acceptRequest(id)
      : () => declineRequest(id);
    let buttonClassname = isAccept ? "button--next" : "button--delete";
    let msgId = `access_requests.${type}`;
    if (isActive && changeStatus === constants.ERROR) {
      msgId += ".error";
      buttonClassname = "button--delete";
    } else if (isActive && changeStatus === constants.LOADING) {
      msgId = "common.loading";
    } else if (alreadyDone) {
      msgId += ".done";
      buttonClassname = "";
    }
    return (
      <button
        className={`button ${buttonClassname} access-request__button`}
        onClick={callback}
        disabled={disabled}
      >
        <FormattedMessage id={msgId} />
      </button>
    );
  }
  render() {
    const {
      data: { wish, petitioner },
      user,
    } = this.props;
    return (
      <article className="wishes-item">
        <div className="wishes-item__container">
          <header className="wishes-item__info-wrapper">
            <div className="wishes-item__info">
              <UserImage
                className="wishes-item__user-avatar"
                user={petitioner}
              />
              <h2 className="wishes-item__title">
                <span className="small">
                  <FormattedHTMLMessage
                    id="access_requests.user_asked"
                    values={{ name: petitioner }}
                  />
                </span>
                <span className="wishes-item__wish-name"> {wish.title} </span>
              </h2>
              <ProgressItem
                progress={wish}
                mineWish={user.id === wish.author}
              />
            </div>
            <figure className="wishes-item__image">
              <ProductImage wish={wish} />
            </figure>
            <Link to={`/wish/detail/${wish.id}`} className="xlink" />
          </header>
          <div className="access-request__controls">
            {this.renderButton("accept")}
            {this.renderButton("decline")}
          </div>
        </div>
      </article>
    );
  }
}

WishComponent.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    changeStatus: PropTypes.string,
    isAccepting: PropTypes.bool,
    petitioner: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      gender: PropTypes.string,
      image: PropTypes.string,
    }),
    wish: PropTypes.shape({
      title: PropTypes.string,
      author: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        gender: PropTypes.string,
        image: PropTypes.string,
      }),
      description: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
        })
      ),
      amount_needed: PropTypes.number,
    }),
  }).isRequired,
  acceptRequest: PropTypes.func.isRequired,
  declineRequest: PropTypes.func.isRequired,
};

export default WishComponent;
