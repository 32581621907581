import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import DayPickerForm from "components/DayPickerForm";
import * as langConstants from "services/localization/constants";

import * as settingsActions from "../../actions";
import SetNewPassword from "../SetNewPassword";

class BankAccountNr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changedLanguage: false,
      changedAccount: false,
      country_id: this.props.country_id,
      gender: this.props.gender,
      date_of_birth: this.props.date_of_birth,
      month: new Date(this.props.date_of_birth),
      first_name: this.props.first_name,
      last_name: this.props.last_name,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.handleChangeBirthday = this.handleChangeBirthday.bind(this);
  }

  componentWillMount() {
    this.props.actions.setLanguage(this.props.user.language);
    this.props.actions.setCurrency(this.props.user.currency);
    this.checkInput(this.props.user.bank_account);
  }

  validateInput(input) {
    if (!input) return true;
    const len = input.length;
    const re = /^(\d{1,8}-)?\d{3,10}\/\d{4}$/;
    if (len > 5) {
      return re.test(input);
    }
    return false;
  }

  checkInput(text) {
    this.checkNumber(text);
  }

  checkNumber(text) {
    this.props.actions.setNewBankNr(text);
    this.props.actions.setInputOk(this.validateInput(text));
  }

  handleOnChange(text) {
    this.setState({ changedAccount: true });
    this.checkNumber(text);
    this.props.actions.setSuccessUpdate(false);
  }

  displayNumber() {
    if (this.props.user.bank_account === null) {
      return "Not set";
    }
    return this.props.user.bank_account;
  }

  selectLanguage(event) {
    const locale = event.target.value;
    this.props.actions.setLanguage(locale);
    this.props.actions.setSuccessUpdate(false);
    this.setState({ changedLanguage: true });
  }

  selectCurrency(event) {
    const currency = event.target.value;
    this.props.actions.setCurrency(currency);
    this.props.actions.setSuccessUpdate(false);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.bankNumber.inputOk && !this.props.informationsChanged) {
      const bankAccount = this.props.bankNumber.text;
      this.props.actions.submitForm(
        bankAccount,
        this.props.user,
        this.props.language,
        this.props.currency
      );
    } else if (
      this.state.changedLanguage &&
      (this.props.bankNumber.text === "" || this.props.bankNumber.text === null)
    ) {
      this.props.actions.submitLanguage(this.props.user, this.props.language);
    } else if (
      this.props.bankNumber.text != null &&
      !this.props.bankNumber.inputOk
    ) {
      this.props.modalActions.open({
        title: "",
        type: modalTypes.INVALID_ADDRESS,
      });
    }

    if (this.props.informationsChanged) {
      const form = {};
      if (this.state.first_name !== this.props.first_name) {
        form.first_name = this.state.first_name;
      }
      if (this.state.first_name !== this.props.last_name) {
        form.last_name = this.state.last_name;
      }
      if (this.state.gender !== this.props.gender) {
        form.gender = this.state.gender;
      }
      if (this.state.country_id !== this.props.country_id) {
        form.country_id = this.state.country_id;
      }
      if (this.state.date_of_birth !== this.props.date_of_birth) {
        const date = this.state.date_of_birth;
        const dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        form.date_of_birth = dateString;
      }
      this.props.actions.changeInformations(this.props.user, form);
    }
  };

  handleChangeGender = (e) => {
    this.setState({
      gender: e.target.value,
    });
    this.anythingNew(
      this.state.date_of_birth,
      e.target.value,
      this.state.country_id,
      this.state.first_name,
      this.state.last_name
    );
  };

  handleChangeBirthday = (date) => {
    if (date) {
      this.setState({
        date_of_birth: date,
      });
      this.anythingNew(
        date,
        this.state.gender,
        this.state.country_id,
        this.state.first_name,
        this.state.last_name
      );
    }
  };

  selectCountry = (e) => {
    this.setState({
      country_id: e.target.value,
    });
    this.anythingNew(
      this.state.date_of_birth,
      this.state.gender,
      e.target.value,
      this.state.first_name,
      this.state.last_name
    );
  };

  onMonthChange(month) {
    this.setState({
      month,
    });
  }

  anythingNew(birthday, gender, country, first_name, last_name) {
    if (
      birthday === this.props.date_of_birth &&
      gender === this.props.gender &&
      country === this.props.country_id &&
      first_name === this.props.first_name &&
      last_name === this.props.last_name
    ) {
      this.props.actions.informationsChanged(false);
    } else if (!this.props.informationsChanged) {
      this.props.actions.informationsChanged(true);
    }
  }

  handleChangeFirstName = (name) => {
    this.setState({
      first_name: name,
    });
    this.anythingNew(
      this.state.date_of_birth,
      this.state.gender,
      this.state.country_id,
      name,
      this.state.last_name
    );
  };

  handleChangeLastName = (name) => {
    this.setState({
      last_name: name,
    });
    this.anythingNew(
      this.state.date_of_birth,
      this.state.gender,
      this.state.country_id,
      this.state.first_name,
      name
    );
  };

  render() {
    let message = "common.save";
    if (this.props.disabled) {
      message = "common.saving";
    } else {
      message =
        this.props.success_update && !this.props.informationsChanged
          ? "common.saved"
          : "common.save";
    }

    const className =
      message !== "common.save" ? "button" : "button button--next";

    return (
      <div className="content settings-content divider divider--vertical">
        <form className="form" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="form__row">
            <label htmlFor="first_name" className="form__label">
              <FormattedMessage id="login.sign-up.name" />
            </label>
            <input
              type="text"
              placeholder={this.props.intl.formatMessage({ id: "login.name" })}
              className="form__field"
              onChange={(e) => this.handleChangeFirstName(e.target.value)}
              value={this.state.first_name}
            />
          </div>
          <div className="form__row">
            <label htmlFor="first_name" className="form__label">
              <FormattedMessage id="login.sign-up.surname" />
            </label>
            <input
              type="text"
              placeholder={this.props.intl.formatMessage({
                id: "login.surname",
              })}
              className="form__field"
              onChange={(e) => this.handleChangeLastName(e.target.value)}
              value={this.state.last_name}
            />
          </div>
          <div className="form__row">
            <label className="form__label" htmlFor="bank_account">
              <FormattedMessage id="settings.bankAccount.title" />
            </label>
            <input
              type="text"
              placeholder={this.props.intl.formatMessage({
                id: "settings.bankAccount.example",
              })}
              className="form__field"
              onChange={(e) => this.handleOnChange(e.target.value)}
              value={this.props.bankNumber.text}
            />
            <p className="form__description">
              <FormattedMessage id="settings.bankAccount.howTo" />
            </p>
          </div>
          <div className="form__row">
            <label className="form__label" htmlFor="language">
              <FormattedMessage id="settings.selectLanguage" />
            </label>
            <select
              name="language"
              value={this.props.language}
              className="form__field"
              onChange={(e) => this.selectLanguage(e)}
            >
              <option value={langConstants.ENGLISH}>
                {this.props.intl.formatMessage({
                  id: "settings.language.english",
                })}
              </option>
              <option value={langConstants.CZECH}>
                {this.props.intl.formatMessage({
                  id: "settings.language.czech",
                })}
              </option>
              <option value={langConstants.SLOVAK}>
                {this.props.intl.formatMessage({
                  id: "settings.language.slovak",
                })}
              </option>
            </select>
          </div>
          <div className="form__row">
            <label className="form__label" htmlFor="currency">
              <FormattedMessage id="settings.selectCurrency" />
            </label>
            <select
              name="currency"
              value={this.props.currency}
              className="form__field"
              onChange={(e) => this.selectCurrency(e)}
            >
              <option value="czk">
                {this.props.intl.formatMessage({ id: "settings.currency.czk" })}
              </option>
              <option value="eur">
                {this.props.intl.formatMessage({ id: "settings.currency.eur" })}
              </option>
              {/*
              <option value="usd">{this.props.intl.formatMessage({ id: 'settings.currency.usd' })}</option>
              */}
            </select>
          </div>
          <div className="form__row">
            <label htmlFor="birthday" className="form__label">
              <FormattedMessage id="login.birthday" />
            </label>
            <DayPickerForm
              date_of_birth={this.state.date_of_birth}
              value={this.state.date_of_birth}
              onDayChange={this.handleChangeBirthday}
              placeholder={this.props.intl.formatMessage({
                id: "login.birthday",
              })}
              locale={this.props.locale}
              month={this.state.month}
              onChange={this.onMonthChange}
            />
          </div>
          <div className="form__row">
            <label htmlFor="birthday" className="form__label">
              <FormattedMessage id="login.sign-up.gender" />
            </label>
            <div className="form__radio">
              <div className="radio-option">
                <input
                  type="radio"
                  name="gender"
                  value="M"
                  id="muz"
                  onChange={this.handleChangeGender}
                  checked={this.state.gender === "M"}
                />
                <label htmlFor="muz">
                  <FormattedMessage id="login.sign-up.gender.male" />
                </label>
              </div>
              <div className="radio-option">
                <input
                  type="radio"
                  name="gender"
                  value="F"
                  id="zena"
                  onChange={this.handleChangeGender}
                  checked={this.state.gender === "F"}
                />
                <label htmlFor="zena">
                  <FormattedMessage id="login.sign-up.gender.female" />
                </label>
              </div>
            </div>
          </div>
          <div className="form__row">
            <label htmlFor="country" className="form__label">
              <FormattedMessage id="settings.country.label" />
            </label>
            <select
              name="country"
              value={this.state.country_id}
              className="form__field"
              onChange={(e) => this.selectCountry(e)}
            >
              <option value={langConstants.CZECH_REPUBLIC_ID}>
                {this.props.intl.formatMessage({ id: "settings.country.cz" })}
              </option>
              <option value={langConstants.SLOVAKIA_ID}>
                {this.props.intl.formatMessage({ id: "settings.country.svk" })}
              </option>
            </select>
          </div>
          <div className="form__row text-right">
            <input
              type="submit"
              disabled={this.props.disabled && !this.props.informationsChanged}
              value={this.props.intl.formatMessage({ id: message })}
              className={className}
            />
          </div>
        </form>
        {!this.props.user.FBaccessToken && <SetNewPassword />}
      </div>
    );
  }
}
BankAccountNr.propTypes = {
  actions: PropTypes.shape({
    setNewBankNr: PropTypes.func,
    setInputOk: PropTypes.func,
    setLanguage: PropTypes.func,
    setCurrency: PropTypes.func,
    submitForm: PropTypes.func,
    switchLanguage: PropTypes.func,
    setSuccessUpdate: PropTypes.func,
  }).isRequired,
  modalActions: PropTypes.shape({
    open: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    bank_account: PropTypes.string,
    accessToken: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  bankNumber: PropTypes.shape({
    text: PropTypes.string,
    inputOk: PropTypes.bool,
  }).isRequired,
  language: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  success_update: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locate: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    bankNumber: state.settings.bankNumber,
    currency: state.settings.currency,
    language: state.settings.language,
    disabled: state.settings.apiSubmitting,
    intl: state.intl,
    locale: state.intl.locale,
    success_update: state.settings.success_update,
    country_id: state.user.country_id,
    informationsChanged: state.settings.informationsChanged,
    gender: state.user.gender,
    date_of_birth: state.user.date_of_birth,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingsActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BankAccountNr));
