import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { push } from "react-router-redux";
import query_parser from "query-string";
import Cookies from "universal-cookie";

import * as appActions from "components/App/actions";
import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import * as gdprActions from "components/GDPR/actions";
import * as gdprConstants from "components/GDPR/constants";
import * as friendsActions from "scenes/Friends/actions";
import * as settingsActions from "scenes/Settings/actions";
import { authFetchApi, fetchApi } from "services/api/";
import * as langConstants from "services/localization/constants";

import { COOKIE_DOMAIN } from "../../components/App/constants";

import * as types from "./actionTypes";
import * as constants from "./constants";

const secure = COOKIE_DOMAIN === ".wowee.cz";

export const setSubmitting = (item) => ({
  type: types.SUBMITTING,
  item,
});

function clearStorage() {
  const cookies = new Cookies();
  cookies.remove("apiToken", {
    domain: COOKIE_DOMAIN,
    secure,
    path: "/",
    sameSite: "none",
  });
  console.log(`COOKIE apiToken deleted: ${cookies.get("apiToken")}`);

  if (localStorage) {
    localStorage.removeItem("apiToken");
    localStorage.removeItem("fbToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("unregisteredWish");
  }
  return {
    type: types.LOGOUT,
  };
}

function createCookie(name, value, days) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else expires = "";
  document.cookie = `${name}=${value}${expires}; path=/`;
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}

function setStorage(token, FbToken, expiresIn) {
  console.log("setStorage", COOKIE_DOMAIN, secure, token, FbToken, expiresIn);
  const cookies = new Cookies();
  cookies.set("apiToken", token, {
    domain: COOKIE_DOMAIN,
    secure,
    path: "/",
    sameSite: "none",
  });

  if (localStorage) {
    const t = new Date();
    localStorage.setItem("apiToken", token);
    if (FbToken) {
      localStorage.setItem("fbToken", FbToken);
      localStorage.setItem("expiresIn", t.getTime() / 1000 + expiresIn * 100);
    }
  }

  eraseCookie("csrftoken");
}

function loginFail(dispatch, type = modalTypes.ERROR) {
  console.log("LOGIN FAIL");
  dispatch(clearStorage());
  dispatch(appActions.loaded());
  dispatch(modalActions.open({ title: "", type }));
}

export function logout(accessToken) {
  console.log("LOGOUT");

  return (dispatch) => {
    dispatch(declineApiTokenOnServer(accessToken));
    dispatch(clearStorage());
  };
}

export function receiveLogin(item) {
  return {
    type: types.LOGIN,
    item,
  };
}

export const fetchUser = (item) => ({
  type: types.FETCH_USER,
  item,
});

export function declineApiTokenOnServer(token) {
  return (dispatch) =>
    authFetchApi("/account/auth/logout/", token, {
      method: "POST",
    }).then(() => {
      dispatch(clearStorage());
    });
}

export function updateUserImage(token, newImage) {
  return (dispatch) => {
    const form = new FormData();
    form.append("image", newImage);
    return authFetchApi("/account/", token, {
      method: "PATCH",
      "Content-Type": "multipart/form-data",
      body: form,
    })
      .then((data) => {
        const result = Object.assign({}, data, {
          accessToken: token,
          loggedIn: true,
        });
        dispatch(receiveLogin(result));
      })
      .catch(() => {
        dispatch({ type: types.FRIENDS_API_ERROR });
      });
  };
}

export function loginUsingEmail(email, password) {
  return (dispatch) => {
    const form = new FormData();
    form.append("email", email);
    form.append("password", password);
    return fetchApi("/account/auth/email/login/", {
      method: "POST",
      body: form,
    })
      .then((json) => {
        const values = query_parser.parse(window.location.search);
        ReactGA.event({
          category: "SignIn",
          action: "email",
          label: json.isNew ? "first" : "repeated",
          value: json.id,
        });
        ReactGA4.event({
          category: "SignIn",
          action: "email",
          label: json.isNew ? "first" : "repeated",
          value: json.id,
        });
        setStorage(json.token, null, null);
        if (window.navigator.language && json.isNew) {
          const lang =
            window.navigator.language === langConstants.ENGLISH_ID
              ? langConstants.ENGLISH
              : langConstants.CZECH;
          settingsActions
            .submitForm(
              undefined,
              { accessToken: json.token },
              lang
            )(dispatch)
            .then(() => {
              if (values.return_url) {
                window.location.href = values.return_url;
                return;
              }
              afterEmailLogin(json.token)(dispatch);
            });
        } else {
        }
        if (values.return_url) {
          window.location.href = values.return_url;
          return;
        }
        afterEmailLogin(json.token)(dispatch);
      })
      .catch((e) => {
        if (e.errorCode === 406) {
          dispatch(
            modalActions.open({
              title: "",
              type: modalTypes.NOT_ACTIVATED,
              email,
            })
          );
        } else if (e.errorCode >= 400 && e.errorCode < 500) {
          dispatch({ type: types.WRONG_CREDENTIALS });
        } else {
          loginFail(dispatch);
        }
      });
  };
}

export function afterEmailLogin(token, setTransition, path) {
  console.log("afterEmailLogin starts");
  return (dispatch) => {
    console.log("AFTERLOGIN, calling /account", token);
    return authFetchApi("/account/", token, { method: "GET" })
      .then((accountJson) => {
        const result = Object.assign({}, accountJson, {
          accessToken: token,
          loggedIn: true,
        });
        dispatch(receiveLogin(result));
        dispatch(settingsActions.switchLanguage(accountJson.language));
        dispatch(settingsActions.setCurrency(accountJson.currency));

        if (setTransition) {
          const values = query_parser.parse(window.location.search);
          if (values.return_url) {
            window.location.href = values.return_url;
          } else {
            dispatch(push(path));
          }
        }
        handleGdpr(token, dispatch);

        friendsActions
          .getUserFriends({ accessToken: token })(dispatch)
          .then(() => {
            console.log("AFTERLOGIN: friends loaded");
            dispatch(appActions.loaded());
          })
          .catch(() => {
            loginFail(dispatch);
            dispatch({ type: types.FRIENDS_API_ERROR });
          });
      })
      .catch(() => {
        dispatch(clearStorage());
        dispatch(appActions.loaded());
        // loginFail(dispatch);
      });
  };
}

function handleGdpr(token, dispatch) {
  gdprActions
    .fetchGdpr({ accessToken: token })(dispatch)
    .then(() => {
      dispatch(gdprActions.setStatus(gdprConstants.initial));
    });
}

export function afterLogin(fbToken, token, setTransition, path) {
  return (dispatch) => {
    const form = new FormData();
    form.append("provider", "facebook");
    form.append("token", fbToken);
    console.log("AFTERLOGIN, calling /account", token);
    return authFetchApi("/account/", token, { method: "GET" })
      .then((accountJson) => {
        handleGdpr(token, dispatch);
        // TODO - toto volat jen kdyz se uzivatel prihlasil pres FB
        console.log(
          "AFTERLOGIN: going to call /account/friends/syncronize",
          token
        );
        authFetchApi("/account/friends/synchronize/", token, {
          method: "POST",
          body: form,
        }).catch((e) => {
          dispatch({ type: types.FRIENDS_API_ERROR });
        });
        // clearStorage();
        const result = Object.assign({}, accountJson, {
          accessToken: token,
          loggedIn: true,
          FBaccessToken: fbToken,
        });
        dispatch(receiveLogin(result));
        dispatch(settingsActions.switchLanguage(accountJson.language));
        dispatch(settingsActions.setCurrency(accountJson.currency));
        if (setTransition) {
          const values = query_parser.parse(window.location.search);
          if (values.return_url) {
            window.location.href = values.return_url;
          } else {
            dispatch(push(path));
          }
        }
        friendsActions
          .getUserFriends({ accessToken: token })(dispatch)
          .then(() => {
            console.log("AFTERLOGIN: friends loaded");
            dispatch(appActions.loaded());
          })
          .catch(() => {
            console.log("AFTERLOGIN: get friends failed");
            loginFail(dispatch);
            dispatch({ type: types.FRIENDS_API_ERROR });
          });
      })
      .catch((e) => {
        console.log("AFTERLOGIN: /account failed");
        console.error(e);
        dispatch(appActions.loaded());
        // loginFail(dispatch);
      });
  };
}

function doLoginRequest(item, setTransition, path, dispatch, anonymous) {
  const anonym = anonymous || false;
  const form = new FormData();
  form.append("provider", "facebook");
  form.append("token", item.FBaccessToken);
  return fetchApi("/account/auth/login/", {
    method: "POST",
    body: form,
    anonymous: anonym,
  })
    .then((json) => {
      ReactGA.event({
        category: "SignIn",
        action: "facebook",
        label: json.isNew ? "first" : "repeated",
        value: json.id,
      });
      ReactGA4.event({
        category: "SignIn",
        action: "facebook",
        label: json.isNew ? "first" : "repeated",
        value: json.id,
      });

      if (json.isNew) {
        ReactGA.event({
          category: "SignUp",
          action: "finish",
          label: "facebook",
        });
        ReactGA4.event({
          category: "SignUp",
          action: "finish",
          label: "facebook",
        });
      }

      if (window.navigator.language && json.isNew) {
        const lang =
          window.navigator.language === langConstants.ENGLISH_ID
            ? langConstants.ENGLISH
            : langConstants.CZECH;
        settingsActions
          .submitForm(
            undefined,
            { accessToken: json.token },
            lang
          )(dispatch)
          .then(() => {
            setStorage(json.token, item.FBaccessToken, item.expiresIn);
            // afterLogin(item.FBaccessToken, json.token, setTransition, path)(dispatch);
          });
      } else {
        setStorage(json.token, item.FBaccessToken, item.expiresIn);
        // afterLogin(item.FBaccessToken, json.token, setTransition, path)(dispatch);
      }
      const values = query_parser.parse(window.location.search);
      if (values.return_url) {
        window.location.href = values.return_url;
        return;
      }
      afterLogin(item.FBaccessToken, json.token, setTransition, path)(dispatch);
    })
    .catch((e) => {
      console.log("DOLOGINREQUEST failed", e);
      console.exception(e);
      dispatch(clearStorage());
      dispatch(appActions.loaded());
    });
}

export function createRegistrationHash(email, password) {
  return (dispatch) => {
    const form = new FormData();
    form.append("email", email);
    form.append("password", password);
    return fetchApi("/account/signup/email/", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        if (response.status === 202) {
          dispatch({ type: types.ALREADY_SENT });
        } else {
          dispatch({ type: types.REGISTRATION_SENT });
        }
      })
      .catch((e) => {
        if (e.errorCode === 400) {
          dispatch(
            modalActions.open({
              title: "Uživatel s tímto emailem už existuje.",
              type: modalTypes.ERROR,
            })
          );
        }
      });
  };
}

export function resendRegistrationEmail(email) {
  return (dispatch) => {
    const form = new FormData();
    form.append("email", email);
    return fetchApi("/account/signup/email/resend/", {
      method: "POST",
      body: form,
    })
      .then(() => {
        dispatch({ type: types.REGISTRATION_SENT });
      })
      .catch((e) => {
        dispatch(
          modalActions.open({
            title: "Chyba při znovuzaslání registračního emailu",
            type: modalTypes.ERROR,
          })
        );
      });
  };
}

export function createForgottenPasswordHash(email) {
  return (dispatch) => {
    const form = new FormData();
    form.append("email", email);
    return fetchApi("/account/forgotten-password/", {
      method: "POST",
      body: form,
    })
      .then(() => {
        dispatch({ type: types.NEW_PASSWORD_SENT });
      })
      .catch((e) => {
        if (e.errorCode === 400) {
          dispatch(
            modalActions.open({
              title: "Uživatel s tímto emailem neexistuje.",
              type: modalTypes.ERROR,
            })
          );
        } else if (e.errorCode === 404) {
          dispatch({ type: types.NEW_PASSWORD_SENT });
        }
      });
  };
}

export function finishRegistration(hash_id, form) {
  return (dispatch) => {
    dispatch(setSubmitting(constants.STATUS_LOADING));
    fetchApi(`/account/signup/email/${hash_id}/`, {
      method: "GET",
    })
      .then(() => {
        fetchApi(`/account/signup/${hash_id}/`, {
          method: "POST",
          body: form,
        })
          .then((json) => {
            ReactGA.event({
              category: "SignUp",
              action: "finish",
              label: "email",
            });
            ReactGA4.event({
              category: "SignUp",
              action: "finish",
              label: "email",
            });
            import("react-facebook-pixel")
              .then((x) => x.default)
              .then((ReactPixel) => {
                ReactPixel.track("CompleteRegistration");
              });

            const lang =
              window.navigator.language === langConstants.ENGLISH_ID
                ? langConstants.ENGLISH
                : langConstants.CZECH;
            settingsActions
              .submitForm(
                undefined,
                { accessToken: json.token },
                lang
              )(dispatch)
              .then(() => {
                setStorage(json.token, null, null);
                dispatch(setSubmitting(constants.STATUS_LOADED));
                afterEmailLogin(json.token)(dispatch);
              });
          })
          .catch((e) => {
            dispatch(
              modalActions.open({
                title: "Chyba pri registraci",
                type: modalTypes.ERROR,
              })
            );
          });
      })
      .catch((e) => {
        if (e.errorCode === 404) {
          dispatch(
            modalActions.open({
              title: "Zaznam s registraci nenalezen",
              type: modalTypes.ERROR,
            })
          );
        }
      });
  };
}

export function getEmailByHash(hash) {
  return () =>
    fetchApi(`/account/forgotten-password/${hash}/`, {
      method: "GET",
    });
}

export function setNewPassword(password, confirm_password, hash) {
  return (dispatch) => {
    const form = new FormData();
    form.append("password", password);
    form.append("confirm-password", confirm_password);
    return fetchApi(`/account/forgotten-password/${hash}/`, {
      method: "PATCH",
      body: form,
    })
      .then((data) => {
        afterEmailLogin(data.token, false, "/")(dispatch);
      })
      .catch((e) => {
        dispatch(
          modalActions.open({
            title: "Chyba pri zmenene hesla",
            type: modalTypes.ERROR,
          })
        );
      });
  };
}

export function login(item, setTransition, path) {
  return (dispatch) => {
    const request = doLoginRequest(item, setTransition, path, dispatch);
    /*
    const form = new FormData();
    form.append('provider', 'facebook');
    form.append('token', item.FBaccessToken);
    return fetchApi('/account/auth/login/', {
      method: 'POST',
      body: form,
    }).then((json) => {
      setStorage(json.token, item.FBaccessToken, item.expiresIn);
      if (window.navigator.language && json.isNew) {
        const lang = window.navigator.language === langConstants.ENGLISH_ID ?
          langConstants.ENGLISH :
          langConstants.CZECH;
        settingsActions.submitForm(undefined, { accessToken: json.token }, lang)(dispatch)
          .then(() => {
            afterLogin(item.FBaccessToken, json.token, setTransition, path)(dispatch);
          });
      } else {
        afterLogin(item.FBaccessToken, json.token, setTransition, path)(dispatch);
      }
    })
    */
    request.catch((e) => {
      if (e.errorCode === 403) {
        clearStorage();
        doLoginRequest(item, setTransition, path, dispatch, true).catch(
          (e2) => {
            // mozna smazat cookies a pak zobrazit dialogove okno a po tlacitku refresh strankz
            loginFail(
              dispatch,
              e2.errorCode === 400 ? modalTypes.LOGIN_ERROR : modalTypes.ERROR
            );
          }
        );
      } else {
        loginFail(
          dispatch,
          e.errorCode === 400 ? modalTypes.LOGIN_ERROR : modalTypes.ERROR
        );
      }
    });
  };
}
