import * as types from "./actionTypes";

export const addMultistep = (id, step) => ({
  type: types.ADD_MULTISTEP,
  item: { id: id, step: step },
});

export const removeMultistep = (item) => ({
  type: types.REMOVE_MULTISTEP,
  item: item,
});

export const setStep = (id, step) => ({
  type: types.SET_STEP,
  item: { id: id, step: step },
});

export const reset = () => ({
  type: types.RESET,
  item: null,
});
