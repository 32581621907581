import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "components/WishControllers/actions";

class Images extends Component {
  getImage() {
    if (this.props.newImage.url !== "") {
      return this.props.newImage.url;
    }

    if (this.props.images.length !== 0) {
      return this.props.images[0].image;
    }

    return "/img/placeholder-gift.svg";
  }

  handleImageChange(e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      const newImage = {
        url: reader.result,
        file,
      };

      this.props.actions.setNewImage(newImage);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  render() {
    return [
      <img
        key="image"
        alt="Product"
        src={this.getImage()}
        onError={(e) => {
          e.target.src = "/img/placeholder-gift.svg";
        }}
      />,
      <label key="file" htmlFor="file">
        <input
          type="file"
          onChange={(e) => this.handleImageChange(e)}
          id="file"
          accept="image/*"
        />
      </label>,
    ];
  }
}

Images.propTypes = {
  actions: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  images: state.wishContainer.wish.images,
  newImage: state.wishContainer.newImage,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Images);
