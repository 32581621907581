import * as types from "./actionTypes";
import * as constants from "./constants";

export const initialState = {
  status: constants.STATUS_DEFAULT,
  newImage: {
    file: null,
    url: "",
  },
  wish: {
    id: -1,
    author: -1,
    images: [],
    surprise_wish: {},
    amount_gathered: 0,
    donators_count: 0,
    title: "",
    description: "",
    amount_needed: 0,
    flat_donation: null,
    date_created: null,
    date_of_expiration: null,
    date_completed: null,
    visibility: "public",
    product_url: "",
    wish_state: null,
    currency: null,
    custom_user_name: null,
    user_money_receiver: -1,
    allowed_users: {},
    allowed_groups: {},
    eshop: null,
    eshop_product_key: null,
    creation_source: "wowee-product-suggest",
  },
  recipient: null,
};

function addAllowedUsers(users, state) {
  const allowedUsers = Object.assign({}, state.wish.allowed_users);

  users.forEach((id) => {
    allowedUsers[id] = true;
  });

  return allowedUsers;
}

function setAllowedUsers(users) {
  const allowedUsers = {};

  users.forEach((id) => {
    allowedUsers[id] = true;
  });

  return allowedUsers;
}

function removeAllowedUsers(users, state) {
  const allowedUsers = Object.assign({}, state.wish.allowed_users);

  users.forEach((id) => {
    delete allowedUsers[id];
  });

  return allowedUsers;
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_STATUS:
      return Object.assign({}, state, { status: payload.item });
    case types.SET_WISH:
      return Object.assign({}, state, { wish: payload.item });
    case types.SET_SURPRISE_WISH:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          surprise_wish: payload.item,
        },
      });
    case types.SET_TITLE:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          title: payload.item,
        },
      });
    case types.SET_NEW_IMAGE:
      return Object.assign({}, state, { newImage: payload.item });
    case types.SET_IMAGES:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          images: payload.item,
        },
      });
    case types.SET_DESCRIPTION:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          description: payload.item,
        },
      });
    case types.SET_AMOUNT_NEEDED:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          amount_needed: payload.item,
        },
      });
    case types.SET_DATE_OF_EXPIRATION:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          date_of_expiration: payload.item,
        },
      });
    case types.SET_VISIBILITY:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          visibility: payload.item,
        },
      });
    case types.SET_PRODUCT_URL:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          product_url: payload.item,
        },
      });
    case types.SET_WISH_STATE:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          wish_state: payload.item,
        },
      });
    case types.SET_CURRENCY:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          currency: payload.item,
        },
      });
    case types.SET_USER_MONEY_RECEIVER:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          user_money_receiver: payload.item,
        },
      });
    case types.ADD_ALLOWED_USERS:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          allowed_users: addAllowedUsers(payload.item, state),
        },
      });
    case types.REMOVE_ALLOWED_USERS:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          allowed_users: removeAllowedUsers(payload.item, state),
        },
      });
    case types.SET_FLAT_DONATION:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          flat_donation: payload.item,
        },
      });
    case types.SET_CUSTOM_USER_NAME:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          custom_user_name: payload.item,
        },
      });
    case types.SET_ALLOWED_USERS:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          allowed_users: setAllowedUsers(payload.item),
        },
      });
    case types.SET_ESHOP_ID:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          eshop: payload.item,
        },
      });
    case types.SET_ESHOP_PRODUCT_ID:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          eshop_product_key: payload.item,
        },
      });
    case types.SET_CREATION_SOURCE:
      return Object.assign({}, state, {
        wish: {
          ...state.wish,
          creation_source: payload.item,
        },
      });
    case types.RESET:
      return Object.assign({}, initialState);
    case types.SET_RECIPIENT:
      return Object.assign({}, state, { recipient: payload.item });
    default:
      return Object.assign({}, state);
  }
};
