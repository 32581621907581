export const SET_STATUS = "wishContainerReducer/set_status";
export const SET_WISH = "wishContainerReducer/set_wish";
export const SET_SURPRISE_WISH = "wishContainerReducer/ser_surprise_wish";
export const SET_TITLE = "wishContainerReducer/set_title";
export const SET_NEW_IMAGE = "wishContainerReducer/set_new_image";
export const SET_IMAGES = "wishContainerReducer/set_images";
export const SET_DESCRIPTION = "wishContainerReducer/set_description";
export const SET_AMOUNT_NEEDED = "wishContainerReducer/set_amount_needed";
export const SET_AMOUNT_CURRENCY = "wishContainerReducer/set_amount_currency";
export const SET_DATE_OF_EXPIRATION =
  "wishContainerReducer/set_date_of_expiration";
export const SET_VISIBILITY = "wishContainerReducer/visibility";
export const SET_PRODUCT_URL = "wishContainerReducer/set_product_url";
export const SET_WISH_STATE = "wishContainerReducer/wish_state";
export const SET_CURRENCY = "wishContainerReducer/set_currency";
export const SET_USER_MONEY_RECEIVER =
  "wishContainerReducer/set_user_money_receiver";
export const ADD_ALLOWED_USERS = "wishContainerReducer/add_allowed_users";
export const REMOVE_ALLOWED_USERS = "wishContainerReducer/remove_allowed_users";
export const RESET = "wishContainerReducer/reset";
export const SET_RECIPIENT = "wishContainerReducer/setRecipiant";
export const SET_FLAT_DONATION = "wishConatainerReducer/setFlatDonation";
export const SET_CUSTOM_USER_NAME = "wishContainerReducer/setCustomUserName";
export const SET_ALLOWED_USERS = "wishContainerReducer/setAllowedUsers";
export const SET_ESHOP_ID = "wishContainerReducer/setEshopId";
export const SET_ESHOP_PRODUCT_ID = "wishContainerReducer/setEshopProductId";
export const SET_CREATION_SOURCE = "wishContainerReducer/setCreationSource";
