import * as types from "./actionTypes";

export const open = (item) => ({
  type: types.OPEN,
  item,
});

export const close = () => ({
  type: types.CLOSE,
});
