export const BANK_ACCOUNT = {
  czk: process.env.REACT_APP_BANK_ACCOUNT_CZK || "CZ0320100000002500993327",
  eur: process.env.REACT_APP_BANK_ACCOUNT_EUR || "CZ0320100000002500993327",
};
export const BANK_ACCOUNT_SHORT = {
  czk: process.env.REACT_APP_BANK_ACCOUNT_SHORT_CZK || "2500993327/2010",
  eur: process.env.REACT_APP_BANK_ACCOUNT_SHORT_EUR || "2500993327/8330",
};

export const ELATEME_WALLET_ID = "2MxGTePiyJhLrQimEDZAXzvFcf1FWnCxBPx";
export const WISH_DETAIL_URL = "/wish/detail/";
export const BT_ACTION_URL = "address/check/";

export const ONE_BT_VALUE = 8165;
export const ONE_DOLLAR = 25;
export const LOWER_BOUND = 0;
export const UPPER_BOUND = 99999;
