import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";
import * as actions from "components/WishControllers/actions";

import TimeRemaining from "../TimeRemaining";

class FriendWidgetComponent extends React.Component {
  render() {
    return (
      <li className="flipper__container">
        <div className="flipper__avatar">
          <Link to={`/user/${this.props.friend.id}`} className="no-link">
            <UserImage
              className="contacts__user-avatar"
              user={this.props.friend}
            />
          </Link>
        </div>
        <div className="flipper__user">
          <Link to={`/user/${this.props.friend.id}`} className="no-link">
            {this.props.friend.first_name}{" "}
            <strong>{this.props.friend.last_name}</strong>
          </Link>
        </div>
        <div className="flipper">
          <div className="flipper--front">
            <TimeRemaining date={this.props.friend.birthday} />
          </div>
          <div className="flipper--back">
            <Link
              to={`/wish/create/${this.props.friend.id}`}
              onClick={() =>
                this.props.actions.setSurpriseWish({
                  recipient: this.props.friend.id,
                })
              }
            >
              <strong>
                <FormattedMessage id="friends.createGift" />
              </strong>
            </Link>
          </div>
        </div>
      </li>
    );
  }
}
FriendWidgetComponent.propTypes = {
  friend: PropTypes.shape({
    id: PropTypes.number,
    gender: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    image: PropTypes.string,
    date_of_birth: PropTypes.string,
    birthday: PropTypes.instanceOf(Date),
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(injectIntl(FriendWidgetComponent));
