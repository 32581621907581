import { authFetchApi, fetchApi } from "services/api/";

import { setCreationSource } from "../../../../components/WishControllers/actions";

import * as types from "./actionTypes";
import * as constants from "./constants";

export const setSearchText = (item) => ({
  type: types.SET_SEARCH_TEXT,
  item,
});

export const setSuggested = (item) => ({
  type: types.SET_SUGGESTED,
  item,
});

export const setTimeout = (item) => ({
  type: types.SET_TIMEOUT,
  item,
});

export const setLoadingStatus = (item) => ({
  type: types.SET_LOADING_STATUS,
  item,
});

export const setSurpriseWish = (item) => ({
  type: types.SET_SURPRISE_WISH,
  item,
});

export const setRecipient = (item) => ({
  type: types.SET_RECIPIENT,
  item,
});

export const reset = () => ({
  type: types.RESET,
  item: null,
});

export function getSuggested(searchText, user) {
  return (dispatch) => {
    dispatch(setLoadingStatus(constants.STATUS_LOADING));

    const expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi; // eslint-disable-line
    const query_shop_expr = /shop:([\w.]+)/g;
    const regex = new RegExp(expression);
    const match = query_shop_expr.exec(searchText);
    let shop = null;

    if (match && match[1]) {
      shop = match[1];
    }
    if (!shop && searchText.match(regex)) {
      fetchApi("/wishes/webpage/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ page_url: searchText }),
      })
        .then((data) => {
          if (!data[0].image) {
            data[0].image = "/img/placeholder-gift.png";
          }
          dispatch(setCreationSource("wowee-parsed-link"));
          dispatch(setSuggested(data));
          dispatch(setLoadingStatus(constants.STATUS_LOADED));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(setLoadingStatus(constants.STATUS_LOADED));
      /*
      const url = shop ? `/wishes/suggested?query=${encodeURI(searchText)}&=shop${encodeURI(shop)}` :
        `/wishes/suggested?query=${encodeURI(searchText)}`;
      //authFetchApi(url, user.accessToken, {
      fetchApi(url, {
        method: 'GET',
      }).then((data) => {
        dispatch(setCreationSource('wowee-product-suggest'));
        dispatch(setSuggested(data));
        dispatch(setLoadingStatus(constants.STATUS_LOADED));
      });

       */
    }
  };
}
