import * as types from "./actionTypes";
import * as constants from "./constants";

const initialState = {
  isOpen: false,
  hasChange: false,
  status: constants.initial,
  saveStatus: constants.loaded,
  gdpr: {},
  handlersData: {},
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_GDPR:
      return Object.assign({}, state, {
        isOpen: true,
        hasChange: false,
        status: constants.loaded,
        saveStatus: constants.loaded,
        gdpr: payload.data,
        handlersData: payload.data,
      });
    case types.SET_FIELD:
      return Object.assign({}, state, {
        hasChange: true,
        gdpr: { ...state.gdpr, [payload.name]: payload.value },
      });
    case types.OPEN_MODAL:
      return Object.assign({}, state, { isOpen: true });
    case types.CLOSE_MODAL:
      return Object.assign({}, state, { isOpen: false });
    case types.SET_STATUS:
      return Object.assign({}, state, { status: payload.status });
    case types.SET_SAVE_STATUS:
      return Object.assign({}, state, {
        saveStatus: payload.status,
        hasChange:
          payload.status === constants.loaded ? false : state.hasChange,
      });
    default:
      return state;
  }
};
