import { authFetchApi } from "services/api";

import * as types from "./actionTypes";
import * as constants from "./constants";

export function fetchAccessRequests(user) {
  return (dispatch) => {
    dispatch({ type: types.RESET });
    authFetchApi("/wishes/access/request/", user.accessToken, {
      method: "GET",
    })
      .then((response) => {
        const data = response.map((req) => ({
          ...req,
          changeStatus: constants.INITIAL,
          isAccepting: null,
        }));
        console.log(data);
        dispatch({
          type: types.RECEIVE_RESPONSE,
          response: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.API_ERROR,
          error,
        });
      });
  };
}

export function patchAccessRequest(user, id, isAccept) {
  return (dispatch) => {
    dispatch({
      type: types.PATCH_REQUEST,
      id,
      patch: { changeStatus: constants.LOADING, isAccepting: isAccept },
    });
    authFetchApi(`/wishes/access/${id}/`, user.accessToken, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: isAccept ? constants.GRANTED : constants.REJECTED,
      }),
    })
      .then((response) => {
        const patch = { changeStatus: constants.LOADED, ...response };
        dispatch({ type: types.PATCH_REQUEST, id, patch });
      })
      .catch((error) => {
        dispatch({
          type: types.PATCH_REQUEST,
          id,
          patch: { changeStatus: constants.ERROR },
        });
      });
  };
}
