export const GET_FRIENDS = "friends/user/friends";
export const GET_FRIEND_BY_ID = "friends/user/friends/id";
export const SET_FILTER_INPUT = "friends/setFriendsFilter";
export const CHANGE_FILTER_MOD = "friends/changeFilterMode";
export const FETCH_FRIENDS = "api/user/friends/all";
export const SET_FRIEND_INFO = "friends/setFriendsInfo";
export const CLEAR_LIST = "friends/clearFriendsList";
export const SET_FEED = "/user/feed";
export const API_ERROR = "friends/API_ERROR";
export const GET_REQUESTS = "friends/user/requests";
export const GET_USER_REQUESTS = "friends/user/friendship_requests";
export const GET_LAST_FIVE_USERS = "friends/user/getLastFiveUsers";
