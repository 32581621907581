import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../../../actions";

import FriendRequestComponent from "./components/FriendRequestComponent/index";

class FriendRequest extends React.Component {
  componentDidMount() {
    this.props.actions.getFriendshipRequests(this.props.user);
  }

  forAllRequests = (request, index) => {
    console.log(request.id);

    return (
      <FriendRequestComponent
        key={request.id}
        request={request.id}
        index={index}
        friend={request.user}
        user={this.props.user}
      />
    );
  };

  render() {
    return (
      <section>
        {!this.props.request.length ? (
          <p className="friend-list__placeholder">
            <FormattedMessage id="friends.noRequests" />
          </p>
        ) : (
          <ul className="contacts contacts--large">
            {this.props.request.map(this.forAllRequests)}
          </ul>
        )}
      </section>
    );
  }
}

FriendRequest.propTypes = {};

const mapStateToProps = (state) => ({
  request: state.friends.friends.friendRequests,
  allFriends: state.friends.friends.all,
  user: state.user,
  user_request: state.friends.friends.user_request,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FriendRequest);
