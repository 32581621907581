import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi, fetchApi } from "services/api";

import * as types from "./actionTypes";
import * as constants from "./constants";

export const receiveWishes = (item) => ({
  type: types.SET_WISHES,
  item,
});

export const setWishesLoading = (item) => ({
  type: types.SET_WISHES_LOADING,
  item,
});

export const reset = () => ({
  type: types.RESET,
});

export const setLastDownloadedPage = (item) => ({
  type: types.SET_WISHES_LAST_PAGE,
  item,
});

export const setHasMorePages = (item) => ({
  type: types.SET_WISHES_HAS_MORE,
  item: item !== null,
});

export const setUserDetail = (item) => ({
  type: types.SET_USER_DETAIL,
  user: item,
});

export const setWishState = (item) => ({
  type: types.SET_WISH_STATE,
  item,
});

export function fetchUserWishesPage(user, id, page, wishState) {
  return (dispatch) => {
    const url =
      parseInt(id, 10) === user.id
        ? `/wishes/?page=${page}&page_size=${constants.USER_WISHES_PAGE_SIZE}&state=${wishState}`
        : `/wishes/user/${id}?page=${page}&page_size=${constants.USER_WISHES_PAGE_SIZE}&state=${wishState}`;
    const options = { method: "GET" };
    let request;
    if (user.loggedIn) {
      request = authFetchApi(url, user.accessToken, options);
    } else {
      request = fetchApi(url, options);
    }
    return request
      .then((data) => {
        dispatch(setHasMorePages(data.next));
        dispatch(setLastDownloadedPage(page));
        if (data.results.length) {
          dispatch(receiveWishes(data.results));
        } else {
          dispatch(reset());
        }
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
      });
  };
}

export function getUserDetail(user, is_friend, id) {
  return (dispatch) => {
    const url = is_friend ? `/account/${id}` : `/account/restricted/${id}`;
    const options = { method: "GET" };
    let request;
    if (user.loggedIn && is_friend) {
      request = authFetchApi(url, user.accessToken, options);
    } else {
      request = fetchApi(url, options);
    }
    request
      .then((data) => {
        dispatch(setUserDetail(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
