import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import NotFound from "scenes/NotFound";
import FormattedHTMLMessage from "services/localization/flex";

import EshopProductImage from "../../components/EshopProductImage";
import Spinner from "../../components/Spinner";

import * as actions from "./actions";
import * as types from "./actionTypes";

class EshopProduct extends Component {
  componentDidMount() {
    const { match, staticContext } = this.props;
    const { params } = match;
    const { id } = params;
    if (!staticContext) {
      this.props.actions.getProduct(id);
    }
  }

  showDescription() {
    const { staticContext, product: browserProduct } = this.props;
    const product =
      staticContext && staticContext.product
        ? staticContext.product
        : browserProduct;
    if (product.description === "") {
      return (
        <p>
          <FormattedMessage id="wish.detail.noDescription" />
        </p>
      );
    }
    return <p>{product.description}</p>;
  }

  parseURL(url) {
    return url.split("/")[2];
  }

  renderProductComponent() {
    const { staticContext, product: browserProduct } = this.props;
    const product =
      staticContext && staticContext.product
        ? staticContext.product
        : browserProduct;
    console.log("FROM INSIDE", product);
    console.log("--------------", staticContext);
    let price = null;
    let currency = null;
    product.variants.forEach((variant) => {
      if (price === null) {
        price = variant.price;
        currency = variant.currency;
        return;
      }
      if (variant.price && price > variant.price) {
        price = variant.price;
        currency = variant.currency;
      }
    });
    const params = {
      title: product.title,
      amount: price,
      image: product.image,
      productURL: product.url,
      description: product.description,
      visibility: "public",
      creation_source: "product-page",
      currency,
    };

    const stringified = queryString.stringify(params);
    return (
      <div className="container container--narrow">
        {this.props.children}
        <div className="content">
          <article className="wish-detail">
            <header className="wishes-item__info-wrapper">
              <figure className="wishes-item__image">
                <EshopProductImage product={product} />
              </figure>
              <div className="wishes-item__info">
                <h2 className="wishes-item__title">
                  <span className="wishes-item__wish-name">
                    {" "}
                    {product.title}{" "}
                  </span>
                </h2>
                <div className="wishes-item-eshop">
                  <div className="wishes-item-eshop__image">
                    <img src={product.eshop.image_logo} alt="" />
                  </div>
                  <div className="wishes-item-eshop__title">
                    {product.url ? (
                      <a
                        href={product.url}
                        target="_blank"
                        className="wishes-item__image-link"
                        rel="noreferrer"
                      >
                        Zobrazit v e-shopu {this.parseURL(product.url)}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </header>
            <section className="wish-detail__user-note divider divider--medium">
              {price && currency ? (
                <p>
                  Cena od: {price} {currency.toUpperCase()}
                </p>
              ) : null}
              <p>
                <a
                  className="button button--primary fill"
                  href={`/wish/create/eshop/${product.eshop.id}/${product.guid}?${stringified}`}
                >
                  <FormattedMessage id="menu.createWish" />
                </a>
              </p>
              {this.showDescription()}
              {/* TODO vymenit this.shareWish za this.shareProduct, ktery se musi naimlementovat */}
              {/*
              <p>
                <a className="button button--facebook fill" onClick={() => { this.shareWish(product, null); }}><FormattedMessage id="common.share" /></a>
              </p>
              */}
            </section>
          </article>
        </div>
      </div>
    );
  }

  render() {
    const { status, staticContext, product: browserProduct } = this.props;
    const product =
      staticContext && staticContext.product
        ? staticContext.product
        : browserProduct;

    if (
      !staticContext &&
      (status === types.STATUS_EMPTY || status === types.STATUS_LOADING)
    ) {
      return (
        <div className="container container--narrow">
          <div className="content divider">
            <Spinner />
          </div>
        </div>
      );
    }
    if (status === types.STATUS_NOT_FOUND) {
      return <NotFound />;
    }
    return (
      <DocumentTitle title={product.title}>
        <div className="app-section">{this.renderProductComponent()}</div>
      </DocumentTitle>
    );
  }
}

EshopProduct.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  staticContext: PropTypes.shape({}),
  product: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number,
    image: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    eshop: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.number,
        currency: PropTypes.string,
      })
    ),
    currency_id: PropTypes.string,
  }),
  actions: PropTypes.shape({
    getProduct: PropTypes.func,
    setStatus: PropTypes.func,
  }).isRequired,
  status: PropTypes.string.isRequired,
};

EshopProduct.defaultProps = {
  product: null,
  staticContext: null,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  product: state.product.product,
  status: state.product.status,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EshopProduct));
