import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi } from "services/api/";

import * as types from "./actionTypes";
import * as constants from "./constants";

export const receiveFeed = (item) => ({
  type: types.SET_FEED,
  item,
});

export const setFeedLoading = (item) => ({
  type: types.SET_LOADING,
  item,
});

export const setFeedPage = (item) => ({
  type: types.SET_LOADED_FEED_PAGE,
  item,
});
export const reset = () => ({
  type: types.RESET,
});
export const setFeedHasMore = (item) => ({
  type: types.SET_FEED_HAS_MORE,
  item: item !== null,
});

export function fetchFeedPage(user, pageNumber) {
  return (dispatch) => {
    authFetchApi(
      `/feed/?page=${pageNumber}&page_size=${constants.FEED_PAGE_SIZE}`,
      user.accessToken,
      {
        method: "GET",
      }
    )
      .then((data) => {
        dispatch(setFeedHasMore(data.next));
        dispatch(setFeedPage(pageNumber));
        dispatch(receiveFeed(data.results));
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: types.API_ERROR });
      });
  };
}

export function hideUserFromFeed(id, user) {
  return (dispatch) => {
    authFetchApi(`/feed/hide/user/${id}/`, user.accessToken, {
      method: "POST",
    })
      .then(() => {
        dispatch({ type: types.HIDE_USER });
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function hideWishFromFeed(id, user) {
  return (dispatch) => {
    authFetchApi(`/feed/hide/wish/${id}/`, user.accessToken, {
      method: "POST",
    })
      .then(() => {
        dispatch({ type: types.HIDE_WISH });
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
