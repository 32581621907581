import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";
import FormattedHTMLMessage from "services/localization/flex";

class Faq extends Component {
  render() {
    return (
      <Fragment>
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "general.faq" })}
        >
          <section className="static-page">
            <div className="container container--narrow">
              <h2>
                <FormattedHTMLMessage id="general.faq" />
              </h2>
              <FormattedHTMLMessage id="faq.content" />→{" "}
              <Link to="/sign-up">ZAREGISTRUJ SE</Link>
            </div>
          </section>
        </DocumentTitle>
        <Footer lightBackground {...this.props} />
      </Fragment>
    );
  }
}

Faq.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(Faq);
