import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";
import * as actions from "components/WishControllers/actions";
import FormattedHTMLMessage from "services/localization/flex";

import FriendGroupComponent from "../../../FriendGroups/components/FriendGroupComponent";
import GroupDropdown from "../GroupDropdown";

class FriendComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  changeMode() {
    this.setState({
      active: !this.state.active,
    });
  }
  forAllGroups(group, i) {
    return (
      <FriendGroupComponent
        key={i}
        index={i}
        fullView={false}
        friendGroup={group}
      />
    );
  }

  render() {
    return (
      <li
        key={this.props.index}
        className={
          this.state.active ? "contacts__item dropdown--open" : "contacts__item"
        }
      >
        <a className="contacts__item-link">
          <UserImage
            className="contacts__user-avatar"
            user={this.props.friend}
          />
          <span className="contacts__user-name">
            {this.props.friend.first_name}{" "}
            <strong>{this.props.friend.last_name}</strong>
          </span>
          <span className="contacts__note">
            {this.props.groups.map(this.forAllGroups, this)}
          </span>
        </a>

        <div className="contacts__item-menu xlink">
          <UserImage
            user={this.props.friend}
            className="contacts__user-avatar"
          />
          <span className="contacts__user-name hidden-mobile">
            {this.props.friend.first_name}
          </span>
          <ul>
            <li className="contacts__item-menu-item">
              <Link
                to={`/wish/create/${this.props.friend.id}`}
                onClick={() =>
                  this.props.actions.setSurpriseWish({
                    recipient: this.props.friend.id,
                  })
                }
              >
                <strong>
                  <FormattedMessage id="friends.createGift" />
                </strong>
              </Link>
            </li>
            <li className="contacts__item-menu-item">
              <Link
                className="hidden-mobile"
                to={`/user/${this.props.friend.id}`}
              >
                <FormattedHTMLMessage
                  id="friends.allGiftsWithName"
                  values={{ name: this.props.friend }}
                />
              </Link>
              <Link
                className="hidden-desktop hidden-tablet"
                to={`/user/${this.props.friend.id}`}
              >
                <FormattedMessage id="friends.allGifts" />
              </Link>
            </li>
          </ul>
          {/* <a> <i className="icon icon--trash" /></a> */}
          <button
            className={
              this.state.active
                ? "dropdown no-style dropdown--open"
                : "dropdown no-style"
            }
            onClick={(e) => this.changeMode(e)}
          >
            <span className="dropdown__button">
              <i className="icon icon--list" />
            </span>
          </button>
        </div>
        {this.state.active ? (
          <GroupDropdown
            friendToModify={this.props.friend}
            activeGroups={this.props.groups}
            onClickOut={(e) => this.changeMode(e)}
          />
        ) : null}
      </li>
    );
  }
}

FriendComponent.propTypes = {
  friend: PropTypes.shape({
    id: PropTypes.number,
    gender: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
    image: PropTypes.string,
    date_of_birth: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(injectIntl(FriendComponent));
