import * as types from "./actionTypes";

export const initialState = {};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.ADD_MULTISTEP:
      return Object.assign({}, state, { [payload.item.id]: payload.item.step });
    case types.REMOVE_MULTISTEP:
      let newState = Object.assign({}, state);
      delete newState[payload.item];
      return newState;
    case types.SET_STEP:
      return Object.assign({}, state, { [payload.item.id]: payload.item.step });
    case types.RESET:
      return Object.assign({}, initialState);
    default:
      return Object.assign({}, state);
  }
};
