import React from "react";

const Tab = ({ checkedWhen, onClick, IconComponent, heading, desc }) => {
  const className = `tab ${checkedWhen && "active"}`;

  return (
    <div
      className={className}
      onClick={() => {
        !checkedWhen && onClick();
      }}
    >
      {IconComponent || <i className="icon icon--user" />}
      <h4 className="tab__heading">{heading}</h4>
      <span className="tab__description">{desc}</span>
    </div>
  );
};

export default Tab;
