import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import NotificationsList from "components/NotificationsList";
import * as notificationActions from "scenes/Notifications/actions";

class NotificationDropDown extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickMore = this.handleClickMore.bind(this);
  }

  componentDidMount() {
    // document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    // document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside() {
    if (this.props.isOpen) {
      this.props.closeNoti();
    }
  }

  handleClickMore() {
    this.props.closeNoti();
    this.props.history.push("/notifications");
  }

  handleBackdropClick = () => this.props.closeNoti();

  render() {
    if (this.props.isOpen) {
      return (
        <Fragment>
          <div
            className="dropdown__backdrop"
            onClick={this.handleBackdropClick}
          />
          <div className="dropdown__menu dropdown__menu--right">
            <div className="notifications notifications--small">
              <h2>
                <FormattedMessage id="notifications.title" />
              </h2>
              <NotificationsList
                notificationsActions={this.props.notificationActions}
                onNotificationClick={this.props.closeNoti}
                isDropdown
              />
              <a className="more-link" onClick={() => this.handleClickMore()}>
                <FormattedMessage id="notifications.allNotifications" />
              </a>
            </div>
          </div>
        </Fragment>
      );
    }
    return null;
  }
}
NotificationDropDown.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
    accessToken: PropTypes.string,
  }).isRequired,
  notificationActions: PropTypes.shape({
    getNotifications: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      date_created: PropTypes.date,
      notification_type: PropTypes.string,
      wish: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        ammount_needed: PropTypes.number,
        ammount_gathered: PropTypes.number,
        donators_count: PropTypes.number,
        date_created: PropTypes.date,
        date_of_expiration: PropTypes.date,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ),
      }),
      donation: PropTypes.shape({
        id: PropTypes.number,
        donator: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
        }),
        wish: PropTypes.number,
        amount: PropTypes.number,
        date: PropTypes.date,
      }),
    })
  ).isRequired,
  openNoti: PropTypes.func.isRequired,
  closeNoti: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  notifications: state.notifications.notifications,
});
function mapDispatchToProps(dispatch) {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationDropDown)
);
