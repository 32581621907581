import React, { Component } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import query_parser from "query-string";
import { bindActionCreators } from "redux";

import * as userActions from "../../data/user/actions";
import * as appActions from "../App/actions";
import { FB_APP_ID, PAGE_URL } from "../App/constants";

class FacebookLoginLite extends Component {
  constructor(props) {
    super(props);
    this.responseFacebook = this.responseFacebook.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.autoload && nextProps.autoload) {
      // TODO: change the module?
      // this.button.click();
    }
  }

  checkLocation() {
    const location = this.props.location.pathname.split("/");
    return location[1] === "wish" || location[1] === "user";
  }

  responseFacebook(response) {
    console.log("receive facebook token", response);
    if (localStorage) {
      const mobileRedir = localStorage.getItem("mobileredir");
      if (mobileRedir) {
        this.props.appActions.addTransition(`${mobileRedir}`);
        localStorage.removeItem("mobileredir");
      }
    }

    if (response.accessToken) {
      this.props.appActions.loading();
      this.props.userActions.login(
        {
          FBaccessToken: response.accessToken,
          loggedIn: true,
          expiresIn: response.expiresIn,
        },
        this.props.addedTransition,
        this.props.transitionTo
      );
    }
  }

  handleClick(e) {
    if (localStorage) {
      // localStorage.removeItem('mobileredir');
      const values = query_parser.parse(this.props.location.search);
      if (values.return_url) {
        localStorage.setItem("mobileredir", values.return_url);
      } else {
        localStorage.setItem("mobileredir", "/");
      }
    }

    const path = "/";

    /*
    if (this.checkLocation()) {
      path = this.props.location.pathname + this.props.location.search;
    //} else if (path.length > 0) {
    //  this.props.history.push('/login');
    } else {
      path = `/${this.props.location.search}`;
      //this.props.history.push('/login');
      this.props.history.push('/');
    } */

    this.props.appActions.addTransition(path);
  }

  onFailure(e) {
    console.log("facebook failure");
  }

  render() {
    // const mobileUrl = 'http://localhost:3000';

    const mobileUrl = `${PAGE_URL}/login`;
    return (
      <FacebookLogin
        ref={(btn) => {
          this.button = btn;
        }}
        appId={FB_APP_ID}
        autoLoad={this.props.autoload}
        scope="public_profile,email,user_friends"
        callback={this.responseFacebook}
        onClick={(e) => this.handleClick(e)}
        redirectUri={mobileUrl}
        onFailure={(e) => this.onFailure(e)}
        disableMobileRedirect={true}
        render={(renderProps) => (
          <button className="button button-fb" onClick={renderProps.onClick}>
            <img
              src="img/fb.png"
              className="button-fb__img"
              alt="Facebook button"
            />
            <FormattedHTMLMessage id="general.login.facebook" />
          </button>
        )}
      />
    );
  }
}

FacebookLoginLite.propTypes = {
  transitionTo: PropTypes.string.isRequired,
  addedTransition: PropTypes.bool.isRequired,
  autoload: PropTypes.bool.isRequired,
  appActions: PropTypes.shape({
    loading: PropTypes.func,
    loaded: PropTypes.func,
  }).isRequired,
  userActions: PropTypes.shape({
    login: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    transitionTo: state.app.transitionTo,
    addedTransition: state.app.addedTransition,
    autoload: state.app.autoload,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookLoginLite);
