import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import MyWishes from "./MyWishes";
import UserView from "./UserView";

class User extends Component {
  render() {
    const { user, match } = this.props;
    if (user.loggedIn && match.params.userId === user.id.toString()) {
      return <MyWishes />;
    }
    return <UserView match={match} />;
  }
}
User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    loggedIn: PropTypes.bool,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(User);
