import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import czechStrings from "react-timeago/lib/language-strings/cs";
import englishStrings from "react-timeago/lib/language-strings/en";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as friendActions from "scenes/Friends/actions";
import * as actions from "scenes/Notifications/actions";
import * as notificationsActions from "scenes/Notifications/actionTypes";
import FormattedHTMLMessage from "services/localization/flex";

import UserImage from "../../../UserImage";

class Notification extends React.Component {
  handleDelete() {
    this.props.deleteNotification(this.props.id);
  }

  getFormatter() {
    const formatterCzech = buildFormatter(czechStrings);
    const formatterEnglish = buildFormatter(englishStrings);

    return this.props.language === "cze" ? formatterCzech : formatterEnglish;
  }

  renderMyWishCompleted() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.successCollection.title"
            values={{
              currency: this.props.wish.currency.toUpperCase(),
              amount: this.props.wish.amount_gathered,
              wishTitle: this.props.wish.title,
            }}
          />
        </h3>
        <span className="notification__text">{this.props.wish.title}</span>
      </div>
    );
  }

  renderSuccessWishParticipated() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.successCollectionParticipated.title"
            values={{
              currency: this.props.wish.currency.toUpperCase(),
              amount: this.props.wish.amount_gathered,
              wishTitle: this.props.wish.title,
              endower: this.props.wish.user_money_receiver,
            }}
          />
        </h3>
        <span className="notification__text">
          {" "}
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />{" "}
        </span>
      </div>
    );
  }

  renderMyWishDeadlined() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.myWishExpired"
            values={{ wishTitle: this.props.wish.title }}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderFriendCreatedWish() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.friendCreatedWish.title"
            values={{
              wishTitle: this.props.wish.title,
              author: this.props.wish.author,
            }}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderFriendCreatedSurprise() {
    const params = {
      wishTitle: this.props.wish.title,
      endower: this.props.wish.user_money_receiver,
      author: this.props.wish.author,
    };
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.friendCreatedSurprise.title"
            values={params}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderMyWishGotDonation() {
    const params = {
      currency: this.props.wish.currency.toUpperCase(),
      amount: this.props.donation.amount,
      wishTitle: this.props.wish.title,
    };
    let message = "notifications.someoneGotDonation";
    if (this.props.donation.donator) {
      if (this.props.donation.donator.id == this.props.user.id) {
        message = "notifications.youMadeDonation";
      } else {
        message = "notifications.gotDonation";
        params.donator = this.props.donation.donator;
      }
    }
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage id={message} values={params} />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderGotGift() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.gotGift"
            values={{
              currency: this.props.wish.currency.toUpperCase(),
              amount: this.props.wish.amount_gathered,
              wishTitle: this.props.wish.title,
            }}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderYouDonate() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.youDonate"
            values={{
              wishTitle: this.props.wish.title,
              influencer: this.props.wish.user_money_receiver,
              currency: this.props.wish.currency.toUpperCase(),
              amount: this.props.donation.amount,
            }}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderCardDonationFailed() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.card-donation-failed.text"
            values={{
              wishTitle: this.props.wish.title,
            }}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderMyDonationCompleted() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.wishIDonatedCompleted.title"
            values={{
              friend: this.props.user_influencer,
              wishTitle: this.props.wish.title,
            }}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderMyWishComment() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.myWishComment.title"
            values={{
              author: this.props.user_influencer,
              wishTitle: this.props.wish.title,
            }}
          />
        </h3>
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  handleRevealAccept = (evt, response) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.onReveal(this.props.request.id, response);
  };

  getRequestSection() {
    if (this.props.request.request_status === "rejected") {
      return (
        <p>
          <FormattedMessage id="wish.detail.accessRejected" />
        </p>
      );
    } else if (this.props.request.request_status === "granted") {
      return (
        <p>
          <FormattedMessage id="wish.detail.accessAccepted" />
        </p>
      );
    }
    return (
      <div className="buttons">
        <button
          className="button button--primary"
          onClick={(evt) => this.handleRevealAccept(evt, true)}
        >
          <FormattedMessage id="common.accept" />
        </button>
        <button
          className="button"
          onClick={(evt) => this.handleRevealAccept(evt, false)}
        >
          <FormattedMessage id="common.decline" />
        </button>
      </div>
    );
  }

  handleAcceptRequest = (evt, flag, requestID, id) => {
    if (flag) {
      this.props.actions.editFriendRequestNotification({
        id,
        state: "accepted",
      });
      this.props.friendActions.handleRequest(
        requestID,
        "accepted",
        this.props.user
      );
    } else {
      this.props.actions.editFriendRequestNotification({ id, state: "deny" });
      this.props.friendActions.handleRequest(
        requestID,
        "deny",
        this.props.user
      );
    }
  };

  /*
  friendRequestSection(requestID, id) {
    if (flag) {
      this.props.friendActions.handleRequest(requestID, 'accepted', this.props.user);
    } else {
      this.props.friendActions.handleRequest(requestID, 'deny', this.props.user);
    }
  }

   */

  friendRequestSection = (requestID, id) => {
    return (
      <div className="buttons">
        <button
          className="button button--primary"
          onClick={(evt) => this.handleAcceptRequest(evt, true, requestID, id)}
        >
          <FormattedMessage id="common.accept" />
        </button>
        <button
          className="button"
          onClick={(evt) => this.handleAcceptRequest(evt, false, requestID, id)}
        >
          <FormattedMessage id="common.decline" />
        </button>
      </div>
    );
  };

  handleRequestSection = () => {
    const request = this.props.user_influencer.friendship_request;

    if (request.me !== null) {
      return request.me.state === "accepted" ? (
        <FormattedHTMLMessage id="notifications.friendRequest.accepted" />
      ) : (
        <FormattedHTMLMessage id="notifications.friendRequest.deny" />
      );
    } else {
      const user = request.friend || request.user;
      if (user) {
        return this.friendRequestSection(user.id, this.props.id);
      }
      // TODO vrací null, jelikož přátelství bylo smazáno - upravit na text "Uživatel byl odstraněn z přátel"
    }
  };

  renderFriendRequest() {
    const name =
      this.props.user_influencer.first_name +
      " " +
      this.props.user_influencer.last_name;
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.friendRequest.title"
            values={{
              author: name,
            }}
          />
        </h3>
        {this.handleRequestSection()}
        <span className="notification__text">
          <TimeAgo
            date={this.props.date_created}
            formatter={this.getFormatter()}
          />
        </span>
      </div>
    );
  }

  renderRevealWishReq() {
    const requestSection = this.getRequestSection();
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.revealRequest.title"
            values={{
              influencer: this.props.user_influencer,
              wishTitle: this.props.wish.title,
            }}
          />
        </h3>
        {requestSection}
      </div>
    );
  }

  renderFriendGranted() {
    const state = this.props.user_influencer.friendship_request.friend.state;
    const name =
      this.props.user_influencer.first_name +
      " " +
      this.props.user_influencer.last_name;
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          {state === "accepted" ? (
            <FormattedHTMLMessage
              id="notifications.friendGranted.accepted"
              values={{ author: name }}
            />
          ) : (
            <FormattedHTMLMessage
              id="notifications.friendGranted.deny"
              values={{ author: name }}
            />
          )}
        </h3>
      </div>
    );
  }

  renderRevealGranted() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">
          <FormattedHTMLMessage
            id="notifications.revealGranted.title"
            values={{
              influencer: this.props.wish.author,
              wishTitle: this.props.wish.title,
            }}
          />
        </h3>
      </div>
    );
  }

  renderDefault() {
    return (
      <div className="notification__content">
        <h3 className="notification__title">Not implemented</h3>
        <span className="notification__text">
          This notification is not implemented yet
        </span>
      </div>
    );
  }

  renderNotificationContent() {
    if (this.props.type === notificationsActions.SUCCESS_COLLECTION) {
      // TODO share
      return this.renderMyWishCompleted();
    } else if (this.props.type === notificationsActions.MY_WISH_DEADLINED) {
      return this.renderMyWishDeadlined();
    } else if (this.props.type === notificationsActions.MY_WISH_GOT_DONATION) {
      // TODO share
      // done
      return this.renderMyWishGotDonation();
    } else if (this.props.type === notificationsActions.YOU_DONATE) {
      return this.renderYouDonate();
    } else if (this.props.type === notificationsActions.MY_WISH_COMMENT) {
      // done
      return this.renderMyWishComment();
    } else if (this.props.type === notificationsActions.GOT_GIFT) {
      // done
      return this.renderGotGift();
    } else if (this.props.type === notificationsActions.REVEAL_WISH_REQUEST) {
      // done
      return this.renderRevealWishReq();
    } else if (this.props.type === notificationsActions.REVEAL_GRANTED) {
      // done
      return this.renderRevealGranted();
    } else if (
      this.props.type === notificationsActions.SUCCESS_COLLECTION_PARTICIPATED
    ) {
      // done
      return this.renderSuccessWishParticipated();
    } else if (this.props.type === notificationsActions.FRIEND_CREATED_WISH) {
      // TODO share
      // done
      return this.renderFriendCreatedWish();
    } else if (
      this.props.type === notificationsActions.FRIEND_CREATED_SURPRISE
    ) {
      return this.renderFriendCreatedSurprise();
    } else if (this.props.type === notificationsActions.CARD_DONATION_FAILED) {
      return this.renderCardDonationFailed();
    } else if (this.props.type === notificationsActions.FRIEND_REQUEST) {
      return this.renderFriendRequest();
    } else if (this.props.type === notificationsActions.FRIEND_GRANTED) {
      return this.renderFriendGranted();
    }
    return this.renderDefault();
  }

  handleWishNotificationClick() {
    // TODO - this does not work, this is undefined - need to be binded
    // this.props.history.push(`/wish/detail/${this.props.wish.id}`);
    this.props.onNotificationClick();
  }

  renderWishNotification = () => {
    return (
      <li className="notification notifications__item">
        <a
          className="notification__link"
          onClick={this.handleWishNotificationClick}
        >
          <img
            src={
              this.props.wish.images[0].image
                ? this.props.wish.images[0].image
                : "/img/placeholder-gift.svg"
            }
            alt={this.props.wish.title}
            className="notification__image"
          />
          {this.renderNotificationContent()}
        </a>
        <button className="no-style">
          <span className="notification__close">
            <i
              className="icon icon--close"
              onClick={() => {
                this.handleDelete();
              }}
            />
          </span>
        </button>
      </li>
    );
  };

  handleUserNotificationClick = () => {
    this.props.history.push(`/user/${this.props.user_influencer.id}`);
    this.props.onNotificationClick();
  };

  renderUserNotification() {
    const user = this.props.user_influencer;
    return (
      <li className="notification notifications__item">
        <img
          src={user.custom_photo_url ? user.custom_photo_url : user.image}
          onClick={this.handleUserNotificationClick}
          className="notification__image"
          onError={(e) => {
            e.target.src =
              user.gender === "M"
                ? "/img/user-male.svg"
                : "/img/user-female.svg";
          }}
        />
        {this.renderNotificationContent()}
        <button className="no-style">
          <span className="notification__close">
            <i
              className="icon icon--close"
              onClick={() => {
                this.handleDelete();
              }}
            />
          </span>
        </button>
      </li>
    );
  }

  render() {
    return this.props.wish
      ? this.renderWishNotification()
      : this.renderUserNotification();
  }
}

Notification.propTypes = {
  date_created: PropTypes.string,
  language: PropTypes.string,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  wish: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    amount_needed: PropTypes.number,
    amount_gathered: PropTypes.number,
    donators_count: PropTypes.number,
    date_created: PropTypes.instanceOf(Date),
    date_of_expiration: PropTypes.instanceOf(Date),
    user_money_receiver: PropTypes.shape({
      first_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  donation: PropTypes.shape({
    id: PropTypes.number,
    donator: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
    wish: PropTypes.number,
    amount: PropTypes.number,
    date: PropTypes.instanceOf(Date),
  }),
  user_influencer: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
    image: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }),
  request: PropTypes.shape({
    id: PropTypes.number,
    request_status: PropTypes.string,
  }),

  deleteNotification: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func,
  onReveal: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  onNotificationClick: () => null,
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.settings.language,
  revealRequests: state.notifications.revealRequests,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    friendActions: bindActionCreators(friendActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Notification))
);
