import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import FormattedHTMLMessage from "../../../../services/localization/flex";

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

const Row = ({
  image,
  first_name,
  last_name,
  donator_name,
  amount,
  currency,
}) => (
  <>
    <img
      src={image || "/img/user-male.svg"}
      alt=""
      className="contacts__user-avatar"
    />

    <span className="contacts__user-name">
      {first_name || donator_name ? (
        <FormattedHTMLMessage
          id="wish.detail.donator"
          values={
            first_name || last_name
              ? {
                  first_name,
                  last_name,
                }
              : { first_name: capitalize(donator_name, true), last_name: "" }
          }
        />
      ) : (
        <FormattedHTMLMessage id="wish.detail.donators.anonym" />
      )}
    </span>
    <span className="contacts__note">
      {amount} {currency ? currency.toUpperCase() : null}
    </span>
  </>
);

const DonatorsComponent = ({ id, ...props }) => (
  <li className="contacts__item contacts__item--static">
    {id ? (
      <Link to={`/user/${id}`} className="contacts__item-link">
        {" "}
        <Row {...props} />
      </Link>
    ) : (
      <Row {...props} />
    )}
  </li>
);

DonatorsComponent.propTypes = {
  id: PropTypes.number.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  donator_name: PropTypes.string,
  causes_first_name: PropTypes.shape({}),
  causes_last_name: PropTypes.shape({}),
  image: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default DonatorsComponent;
