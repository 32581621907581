import React from "react";
import PropTypes from "prop-types";

import PermissionItem from "./PermissionItem";

const PermissionsList = ({ list, filter, setField }) => {
  const items = Object.entries(list)
    .filter(filter)
    .map((val) => (
      <PermissionItem
        key={val[0]}
        id={val[0]}
        value={val[1]}
        onChange={setField}
      />
    ));
  return <div>{items}</div>;
};

PermissionsList.propTypes = {
  list: PropTypes.object.isRequired, // eslint-disable-line
  filter: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
};

export default PermissionsList;
