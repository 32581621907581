import React, { Component } from "react";
import { connect } from "react-redux";
import * as usersActions from "data/users/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import Spinner from "components/Spinner";
import * as notificationActions from "scenes/Notifications/actions";
import * as types from "scenes/Notifications/actionTypes";
import * as detailActions from "scenes/Wish/scenes/Detail/actions";
import * as constants from "services/api/constants";
import newId from "services/unique_id";

import Notification from "./components/Notification";

class NotificationsList extends Component {
  componentDidMount() {
    this.props.notificationsActions.getNotifications(this.props.user);
  }
  toDisplay() {
    const len = this.props.notifications.length;
    if (this.props.isDropdown && len > constants.NOTIFICATIONS_DROPDOWN_NR) {
      return this.props.notifications.slice(
        0,
        constants.NOTIFICATIONS_DROPDOWN_NR
      );
    }
    return this.props.notifications;
  }

  hideItemFromArray(arr, itemId) {
    const notifications = arr.slice();
    const tmp = [];
    for (let i = 0; i < notifications.length; i += 1) {
      if (notifications[i].id !== itemId) {
        tmp.push(notifications[i]);
      }
    }
    const ret = {
      results: tmp,
      next: this.props.nextURL,
    };
    return ret;
  }
  deleteNotification(id) {
    this.props.notificationActions.deleteNotification(this.props.user, id);
    this.props.notificationActions.fetchNotifications(
      this.hideItemFromArray(this.props.notifications, id)
    );
  }

  removeNotificationFromList = (id, response) => {
    this.props.detailActions.setAccessRequest(this.props.user, id, response);
  };

  makeList(item) {
    const {
      id,
      notification_type,
      wish,
      donation,
      comment,
      user_influencer,
      date_created,
      request,
    } = item;

    switch (notification_type) {
      case types.SUCCESS_COLLECTION:
      case types.SUCCESS_COLLECTION_PARTICIPATED:
      case types.MY_WISH_DEADLINED:
      case types.REVEAL_WISH_REQUEST:
      case types.MY_WISH_GOT_DONATION:
      case types.YOU_DONATE:
      case types.MY_WISH_COMMENT:
        break;
      default:
        break;
    }

    if (wish && wish.images.length === 0) {
      const dumm = {
        image: null,
        id: null,
      };
      wish.images.push(dumm);
    }

    return (
      <Notification
        key={newId()}
        id={id}
        wish={wish}
        donation={donation}
        comment={comment}
        type={notification_type} // eslint-disable-line
        user_influencer={user_influencer}
        request={request}
        date_created={date_created}
        deleteNotification={this.deleteNotification.bind(this)}
        onReveal={this.removeNotificationFromList}
        onNotificationClick={() =>
          this.props.notificationActions.setDropdown(false)
        }
      />
    );
  }
  render() {
    if (this.props.loaded) {
      return (
        <ul className="notifications__list">
          {this.toDisplay().map(this.makeList, this)}
        </ul>
      );
    }

    return <Spinner />;
  }
}

NotificationsList.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
    accessToken: PropTypes.string,
  }).isRequired,
  notificationActions: PropTypes.shape({
    fetchNotifications: PropTypes.func,
    deleteNotification: PropTypes.func,
    notificationDeleted: PropTypes.func,
    setDropdown: PropTypes.func,
  }).isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      notification_type: PropTypes.string,
      date_created: PropTypes.date,
      wish: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        ammount_needed: PropTypes.number,
        ammount_gathered: PropTypes.number,
        donators_count: PropTypes.number,
        date_created: PropTypes.date,
        date_of_expiration: PropTypes.date,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ),
      }),
      donation: PropTypes.shape({
        id: PropTypes.number,
        donator: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
        }),

        wish: PropTypes.number,
        amount: PropTypes.number,
        date: PropTypes.date,
      }),
      comment: PropTypes.shape({
        id: PropTypes.number,
        author: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          gender: PropTypes.string,
          image: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
        }),
        wish: PropTypes.number,
        text: PropTypes.string,
        date_created: PropTypes.date,
      }),
    })
  ).isRequired,
  loaded: PropTypes.bool.isRequired,
  isDropdown: PropTypes.bool.isRequired,
  usersActions: PropTypes.shape({
    updateUsers: PropTypes.func,
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      id: PropTypes.number,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
  }).isRequired,
  defaultUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  nextURL: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user,
  notifications: state.notifications.notifications,
  loaded: state.notifications.loaded,
  users: state.users.all,
  defaultUser: {
    first_name: state.intl.messages["mock.user.first_name"],
    last_name: state.intl.messages["mock.user.last_name"],
  },
  nextURL: state.notifications.next,
});
function mapDispatchToProps(dispatch) {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    detailActions: bindActionCreators(detailActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
