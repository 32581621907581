import React from "react";
import PropTypes from "prop-types";

const UserImage = ({ className, user, onClick }) => {
  return (
    <img
      className={className}
      alt={`${user.first_name} ${user.last_name}`}
      src={
        user.FBaccessToken
          ? user.image
          : user.custom_photo_url
          ? user.custom_photo_url
          : user.image
      }
      onClick={onClick}
      onError={(e) => {
        e.target.src =
          user.gender === "M" ? "/img/user-male.svg" : "/img/user-female.svg";
      }}
    />
  );
};

UserImage.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
    gender: PropTypes.string,
    image: PropTypes.string,
    custom_photo_url: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

UserImage.defaultProps = {
  className: "",
};
export default UserImage;
