import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import Share from "components/FacebookLogin/share";
import FormattedHTMLMessage from "services/localization/flex";

import * as actions from "../../actions";

class Thanks extends Share {
  constructor() {
    super();

    this.handleClickShare = this.handleClickShare.bind(this);
  }

  handleClickShare() {
    this.openShareMadeDonation(this.props.wish, this.props.donationValue);
  }

  render() {
    return (
      <div className="popup popup--with-header donate-popup donate-payment-method">
        <div className="popup__inner">
          <h3 className="popup__heading">
            <FormattedMessage id="donate.thanks" />
          </h3>
          <h4 className="popup__title">
            <FormattedHTMLMessage
              id="donate.summary"
              values={{
                name: this.props.wish.author,
                amount: this.props.intl.formatNumber(this.props.donationValue),
                currency: this.props.wish.currency.toUpperCase(),
              }}
            />
          </h4>
          <div className="popup__footer">
            <button className="button" onClick={this.props.closeModal}>
              <FormattedMessage id="common.close" />
            </button>
            <button
              className="button button--primary fill"
              onClick={this.handleClickShare}
            >
              <FormattedMessage id="common.share" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Thanks.propTypes = {
  closeModal: PropTypes.func.isRequired,
  wish: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    amount_gathered: PropTypes.number,
    amount_needed: PropTypes.number,
    currency: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
  }),

  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  donationValue: state.donation.donationValue,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Thanks));
