import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";

class CommentForm extends React.Component {
  constructor(props) {
    super(props);

    this.setCommentText = this.setCommentText.bind(this);
    this.submitComment = this.submitComment.bind(this);
  }

  setCommentText(e) {
    this.props.actions.setCommentText(this.props.id, e.target.value);
  }

  submitComment(e) {
    e.preventDefault();
    if (this.props.text.length) {
      this.props.actions.uploadComment(
        this.props.user,
        this.props.id,
        this.props.text
      );
    }
  }

  render() {
    return (
      <form className="wishes-item__reply-form" onSubmit={this.submitComment}>
        <input
          className="form__field wishes-item__reply-field"
          type="text"
          placeholder={this.props.intl.formatMessage({
            id: "feed.type-comment",
          })}
          onChange={this.setCommentText}
          value={this.props.text}
        />
        <button className="wishes-item__reply-submit" type="submit">
          <i className="icon icon--submit" />
        </button>
      </form>
    );
  }
}

CommentForm.propTypes = {
  id: PropTypes.number.isRequired,

  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,

  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,

  actions: PropTypes.shape({
    setCommentText: PropTypes.func,
    uploadComment: PropTypes.func,
  }).isRequired,

  text: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    text: state.comments.comments[ownProps.id].unsubmitted,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CommentForm));
