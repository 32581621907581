import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import DescriptionBox from "./components/DescriptionBox";
import Images from "./components/Images";

class WishDescription extends Component {
  render() {
    return (
      <section className="wish-detail__section divider">
        <h3 className="wish-detail__section-heading">
          <FormattedMessage id="wish.create.giftDescription" />
        </h3>
        <form action="./" className="form">
          <div className="form__group form__group--compact">
            <div className="form__row">
              <div className="form__drop-zone">
                <Images />
              </div>
            </div>
            <div className="form__row fill">
              <DescriptionBox />
            </div>
          </div>
        </form>
      </section>
    );
  }
}

export default injectIntl(WishDescription);
