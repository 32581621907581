import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setCurrency, setTitle } from "../actions";

import AmountNeeded from "./components/AmountNeeded";

class WishHeader extends Component {
  componentDidMount() {
    const textarea = document.querySelector("textarea");
    textarea.addEventListener("keydown", autosize);

    function autosize(event) {
      const el = event.srcElement || event;
      setTimeout(() => {
        el.style.cssText = "height:auto; padding:0";
        // for box-sizing other than "content-box" use:
        el.style.cssText = "-moz-box-sizing:content-box";
        el.style.cssText = `height:${el.scrollHeight + 5}px`;
      }, 0);
    }

    autosize(textarea);
  }

  getImage() {
    if (this.props.wish.images.length !== 0) {
      return this.props.wish.images[0].image;
    }

    return "/img/placeholder-gift.svg";
  }

  selectCurrency(event) {
    const { value } = event.target;
    this.props.setCurrency(value);
  }

  render() {
    const { currency, title, product_url } = this.props.wish;
    const _currency = currency || "czk";
    return (
      <header className="wish-detail__header divider">
        <div className="wish-detail__header-content">
          <textarea
            name="form-field-name"
            className="wish-detail__heading"
            placeholder={this.props.intl.formatMessage({ id: "wish.title" })}
            rows="1"
            value={title}
            onChange={(event) => this.props.setTitle(event.target.value)}
          />

          <form method="post" className="form wish-detail__header-content">
            <div
              className="form__group form__group--always-row form__group--header"
              style={{ justifyContent: "center" }}
            >
              <div className="form__row form__row--price">
                <AmountNeeded />
              </div>
              <div className="form__row form__row--currency">
                <select
                  name="currency"
                  value={_currency}
                  className="form__field"
                  onChange={(e) => this.selectCurrency(e)}
                >
                  <option value="czk">
                    {this.props.intl.formatMessage({
                      id: "settings.currency.czk",
                    })}
                  </option>
                  <option value="eur">
                    {this.props.intl.formatMessage({
                      id: "settings.currency.eur",
                    })}
                  </option>
                  {/*
                    <option value="usd">{this.props.intl.formatMessage({ id: 'settings.currency.usd' })}</option>
                   */}
                </select>
              </div>
            </div>
          </form>
        </div>

        <div className="hidden-mobile">
          <img
            src={this.getImage()}
            alt=""
            className="wish-detail__header-image"
          />
          {!!product_url && (
            <a
              href={product_url}
              target="_blank"
              className="wish-detail__eshop-link"
              rel="noreferrer"
            >
              <FormattedMessage id="wish.view_in_eshop" />
            </a>
          )}
        </div>
      </header>
    );
  }
}

WishHeader.propTypes = {
  defaultCurrency: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  setAmountCurrency: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  wish: PropTypes.shape({
    currency: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.any),
    product_url: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

WishHeader.defaultProps = {
  defaultCurrency: "",
};

const mapStateToProps = (state) => ({
  wish: state.wishContainer.wish,
  defaultCurrency: state.settings.currency,
});

const mapDispatchToProps = {
  setCurrency,
  setTitle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WishHeader));
