import fetch from "isomorphic-fetch";

import { API_URL } from "../../components/App/constants";

import * as constants from "./constants";
import FetchError from "./FetchError";

export function rawFetchApi(url, options, prefixURL = true) {
  const { headers, ...others } = options;
  const mergedHeaders = Object.assign({}, headers, {
    Accept: "application/json",
  });
  const mergedOptions = Object.assign({}, others, {
    mode: "cors",
    credentials: "include",
    headers: mergedHeaders,
    // cache: false,
  });
  return fetch(`${prefixURL ? API_URL : ""}${url}`, mergedOptions);
}

export function fetchApi(url, options, prefixURL = true) {
  console.log("fetchApi() is going to call", url, options);
  return rawFetchApi(url, options, prefixURL).then((response) => {
    if (response.ok) {
      if (response.status === 202) {
        return response;
      }
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        return response.json();
      }
      return {};
    }
    console.log(`Throwing Fetch error on ${url}`);
    throw new FetchError(response.status, response.statusText);
  });
}

export function authFetchApi(url, token, options, prefixURL = true) {
  console.log("authFetchApi()", url, token, options);
  const { headers, ...others } = options;
  const mergedHeaders = Object.assign({}, headers, {
    Authorization: `Token  ${token}`,
  });
  const mergedOptions = Object.assign({}, others, {
    headers: mergedHeaders,
  });
  return fetchApi(url, mergedOptions, prefixURL);
}

export function fetchBitGoApi(url, headers) {
  const mergedHeaders = Object.assign({}, headers, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return fetch(`${constants.BITCOIN_API_ADDRESS}${url}`, mergedHeaders).then(
    (response) => response.json()
  );
}
