import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import Footer from "components/App/components/Footer";
import MultiStep from "components/Containers/MultiStep";
import { setStep } from "components/Containers/MultiStep/actions";
import * as actions from "components/WishControllers/actions";
import * as apiConstants from "services/api/constants";

import Selection from "./components/Selection";
import Settings from "./components/Settings";
import * as wishCreateActions from "./actions";

class WishCreate extends Component {
  componentDidMount() {
    this.props.actions.setUserMoneyReceiver(this.props.myId);
    this.props.actions.setWishState(apiConstants.WISH_STATE_OPEN);

    const id = this.props.match.params.id;
    const eshopProductId = this.props.match.params.eshopProductId;
    const eshopId = this.props.match.params.eshopId;

    if (this.props.match.path === "/create/settings") {
      this.props.setStep("wish_create", "settings");
    } else if (id || eshopProductId) {
      if (/^\+?(0|[1-9]\d*)$/.test(id)) {
        this.props.actions.setSurpriseWish({ recipient: parseInt(id, 10) });
      } else if (id === "settings" || eshopProductId) {
        this.props.setStep("wish_create", "settings");
      }
    }
    if (eshopId && eshopProductId) {
      this.props.actions.setCreationSource("eshop-widget");
      this.props.actions.setEshopId(eshopId);
      this.props.actions.setEshopProductId(eshopProductId);
    }
  }

  componentWillUnmount() {
    this.props.actions.reset();
    this.props.wishCreateActions.reset();
  }

  render() {
    return (
      <Fragment>
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "menu.createWish" })}
        >
          <MultiStep
            id="wish_create"
            steps={{
              selection: <Selection />,
              settings: <Settings />,
            }}
            default="selection"
          />
        </DocumentTitle>
        <Footer lightBackground {...this.props} />
      </Fragment>
    );
  }
}

WishCreate.propTypes = {
  myId: PropTypes.number.isRequired,

  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  myId: state.user.id,
  friends: state.friends.friends.all,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    wishCreateActions: bindActionCreators(wishCreateActions, dispatch),
    setStep: bindActionCreators(setStep, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WishCreate));
