import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class FriendsGroupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.groupName = this.props.friendGroup.name;
  }

  handleClick() {
    this.props.setActiveGroup(this.props.index);
  }

  renderActive() {
    const cnt = this.props.friendGroup.members.length;
    const code = `#${this.props.friendGroup.color}`;
    return (
      <section>
        <div
          className="friend-lists__badge"
          style={{
            backgroundColor: code,
          }}
        />
        {this.props.friendGroup.name} ({cnt})
      </section>
    );
  }

  renderFull() {
    const cnt = this.props.friendGroup.members.length;
    const code = `#${this.props.friendGroup.color}`;
    return (
      <button
        className="no-style friend-lists__button"
        onClick={() => this.handleClick()}
      >
        <div
          className="friend-lists__badge"
          style={{
            backgroundColor: code,
          }}
        />
        {this.props.friendGroup.name} ({cnt})
      </button>
    );
  }

  renderShort() {
    const code = `#${this.props.friendGroup.color}`;
    return (
      <span
        className="friend-lists__badge"
        style={{
          backgroundColor: code,
        }}
      />
    );
  }

  render() {
    if (this.props.activeView) {
      return this.renderActive();
    }
    if (this.props.fullView) {
      return this.renderFull();
    }

    return this.renderShort();
  }
}

FriendsGroupComponent.propTypes = {
  activeView: PropTypes.bool,
  fullView: PropTypes.bool,
  friendGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  setActiveGroup: PropTypes.func,
};
FriendsGroupComponent.defaultProps = {
  fullView: false,
  index: 0,
  setActiveGroup: null,
  activeView: false,
};

const mapStateToProps = (state) => ({
  groups: state.friends.groups.all,
});

export default connect(mapStateToProps, null)(FriendsGroupComponent);
