import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import ProductImage from "components/ProductImage";
import UserImage from "components/UserImage";
import FormattedHTMLMessage from "services/localization/flex";

import Share from "../../../FacebookLogin/share";

class PopupSuccessDonation extends Share {
  constructor() {
    super();

    this.handleClickShare = this.handleClickShare.bind(this);
  }
  handleClickShare() {
    this.shareSuccessDonation(this.props.popup.wish, this.props.popup.donators);
  }

  render() {
    return (
      <div className="popup popup--with-header received-gift">
        <div className="popup__inner">
          <h3 className="popup__heading">
            <FormattedMessage id="popup.success-collection.heading" />
          </h3>
          <div className="row spacing-md align-center">
            <div>
              <h4 className="popup__title">
                <FormattedHTMLMessage
                  id="popup.success-collection.subheading"
                  values={{ title: this.props.popup.wish.title }}
                />
              </h4>
            </div>
            <div className="product-image">
              <ProductImage wish={this.props.popup.wish} />
              {this.props.popup.wish.product_url && (
                <a
                  href={this.props.popup.wish.product_url}
                  target="_blank"
                  className="wishes-item__image-link"
                  rel="noreferrer"
                >
                  <span className="wishes-item-eshop__title">
                    <FormattedHTMLMessage id="wish.detail.product_url" />
                  </span>
                </a>
              )}
            </div>
          </div>
          <div className="donators">
            <h4 className="popup__title">
              <FormattedMessage id="popup.success-collection.donators" />
            </h4>
            <ul className="donators-list">
              {this.props.popup.donators.map((user) => (
                <li className="donators-list__item" key={user.id}>
                  <Link
                    to={`/user/${user.id}`}
                    className="no-link "
                    onClick={this.props.closeModal}
                  >
                    <UserImage user={user} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="popup__footer">
            <button className="button" onClick={this.props.closeModal}>
              <FormattedMessage id="common.close" />
            </button>
            <button
              className="button button--primary fill"
              onClick={this.handleClickShare}
            >
              <FormattedMessage id="common.share" />
            </button>
          </div>
        </div>
        <button
          className="ffancybox-close-small"
          onClick={this.props.closeModal}
          disabled={this.props.closingPopup}
        />
      </div>
    );
  }
}

PopupSuccessDonation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closingPopup: PropTypes.bool.isRequired,
  popup: PropTypes.shape({
    id: PropTypes.number,
    wish: PropTypes.shape({
      id: PropTypes.number,
      author: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        image: PropTypes.string,
      }),
      title: PropTypes.string,
      description: PropTypes.string,
      amount_needed: PropTypes.number,
      date_created: PropTypes.string,
      date_of_expiration: PropTypes.string,
      date_completed: PropTypes.string,
      amount_gathered: PropTypes.number,
      donators_count: PropTypes.number,
      currency: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
        })
      ),
      wish_state: PropTypes.string.isRequired,
      surprise_wish: PropTypes.shape({
        recipient: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
          gender: PropTypes.string,
          image: PropTypes.string,
        }),
      }),
    }),
    donators: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        image: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(PopupSuccessDonation);
