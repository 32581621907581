/**
 * Created by mi on 11.3.17.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";

import AuthorizedMenu from "./components/AuthorizedMenu";
import UnauthorizedMenu from "./components/UnauthorizedMenu";

class Menu extends Component {
  render() {
    const user = this.props.user;
    return user && user.loggedIn ? (
      <AuthorizedMenu {...this.props} />
    ) : (
      <UnauthorizedMenu {...this.props} />
    );
  }
}

Menu.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
Menu.defaultProps = {
  user: {
    loggedIn: false,
  },
};
export default Menu;
