import React from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ErrorBox from "components/ErrorBox";
import InfiniteLoader from "components/react-infinite-loader";
import Spinner from "components/Spinner";
import * as wishControllActions from "components/WishControllers/actions";
import WishList from "components/WishList";
import CustomWishButton from "scenes/Wish/scenes/Create/components/Selection/components/CustomWishButton";
import ProductList from "scenes/Wish/scenes/Create/components/Selection/components/ProductList";
import SearchBox from "scenes/Wish/scenes/Create/components/Selection/components/SearchBox";

import Share from "../../../../../../components/FacebookLogin/share";

import * as feedActions from "./actions";

class Feed extends Share {
  componentWillMount() {
    this.props.wishActions.setRecipient(this.props.user);

    if (localStorage) {
      const unregisteredWish = localStorage.getItem("unregisteredWish");
      localStorage.removeItem("unregisteredWish");
      if (unregisteredWish) {
        const wishToCreate = JSON.parse(unregisteredWish);

        if (!wishToCreate.currency) {
          wishToCreate.currency = this.props.user.currency;
        }

        if (wishToCreate.visibility === "me") {
          wishToCreate.allowed_users = [this.props.user.id];
        }

        wishToCreate.user_money_receiver = this.props.user.id;
        this.props.wishActions.createWish(this.props.user, wishToCreate, null);
      }
    }
  }

  componentDidMount() {
    this.props.actions.reset();
    this.props.actions.fetchFeedPage(this.props.user, 1);
    this.props.actions.setFeedLoading(true);
  }

  loadNewFeedPage() {
    if (this.props.hasMore) {
      const page = this.props.lastDownloadedPage + 1;
      this.props.actions.fetchFeedPage(this.props.user, page);
      this.props.actions.setFeedLoading(true);
    }
  }

  renderPages() {
    return this.props.feed.map((page, index) => (
      <WishList
        key={index}
        user={this.props.user}
        list={page}
        friends={this.props.friends}
        feed
      />
    ));
  }

  renderNoFriends() {
    return (
      <div>
        <SearchBox />
        <CustomWishButton />
        <ProductList />

        <div className="text-center">
          <img
            className="m-b-md m-t-xl"
            src="img/no-friends.png"
            alt="You have no friends yet"
          />
          <p className="large-text m-b-md">
            <FormattedMessage id="feed.nofriends-sidebar" />
          </p>
          <p>
            <button
              onClick={() => this.shareNoFriends()}
              className="button button--facebook"
            >
              <FormattedMessage id="feed.share.button.tellAbout" />
            </button>
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.friends.length === 0) {
      return this.renderNoFriends();
    }
    if (this.props.error) {
      return <ErrorBox onClick={() => this.componentDidMount()} />;
    }
    if (!this.props.isFetching) {
      return (
        <DocumentTitle title="Hlavní stránka">
          <div className="main-section-feed">
            <SearchBox />
            <CustomWishButton />
            <div className="divider">
              <ProductList />
            </div>
            {this.renderPages()}
            <InfiniteLoader onVisited={() => this.loadNewFeedPage()} />
          </div>
        </DocumentTitle>
      );
    }
    return (
      <DocumentTitle title="Hlavní stránka">
        <div className="main-section feed">
          <SearchBox user={this.props.user} />
          {this.renderPages()}
          <InfiniteLoader onVisited={() => this.loadNewFeedPage()} />
          <Spinner />
        </div>
      </DocumentTitle>
    );
  }
}

Feed.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    reset: PropTypes.func,
    fetchFeedPage: PropTypes.func,
    setFeedLoading: PropTypes.func,
  }).isRequired,
  feed: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        author: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          image: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
        }),
        description: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ).isRequired,
        amount_needed: PropTypes.number,
      })
    )
  ).isRequired,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  hasMore: PropTypes.bool.isRequired,
  lastDownloadedPage: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};
const mapStateToProps = (state) => ({
  feed: state.feed.feed_pages,
  hasMore: state.feed.hasMore,
  lastDownloadedPage: state.feed.lastDownloadedPage,
  user: state.user,
  friends: state.friends.friends.all,
  isFetching: state.feed.loading,
  error: state.feed.apiError,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(feedActions, dispatch),
    wishActions: bindActionCreators(wishControllActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Feed));
