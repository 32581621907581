import { fetchApi } from "services/api/";

import * as types from "./actionTypes";

export const addUser = (item) => ({
  type: types.ADD_USER,
  item,
});

export function updateUsers(user, users, id, defaultValues) {
  return (dispatch) => {
    if (users[id] === undefined) {
      const promise = fetchApi(`/account/restricted/${id}/`, {
        method: "GET",
      });
      return promise
        .then((data) => {
          const fetchedUser = data;
          fetchedUser.fetchSuccess = true;
          dispatch(addUser(fetchedUser));
        })
        .catch((error) => {
          dispatch(
            addUser({
              id,
              first_name: defaultValues.first_name,
              last_name: defaultValues.last_name,
              gender: "M",
              fetchSuccess: false,
            })
          );
        });
    }
  };
}
