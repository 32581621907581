import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import { setStep } from "components/Containers/MultiStep/actions";
import * as actions from "components/WishControllers/actions";

class ProductListItem extends Component {
  handleClick() {
    const shop = document.createElement("a");
    shop.href = this.props.url;

    const params = {
      title: this.props.title,
      amount: this.props.amountNeeded,
      image: this.props.image,
      productURL: this.props.productUrl,
      description: "",
      surprise_wish:
        this.props.surprise_wish.recipient != undefined
          ? this.props.surprise_wish.recipient
          : "",
      custom_user_name: this.props.custom_user_name
        ? this.props.custom_user_name
        : "",
      allowed_users: [],
      flat_donation: null,
      visibility: "public",
      expiration_date: null,
      user_money_receiver: null,
      creation_source: this.props.creation_source,
      currency: this.props.currency,
    };

    const stringified = queryString.stringify(params);
    if (window.location.pathname.split("/")[1] === "create") {
      this.props.push(`/create/settings?${stringified}`);
    } else if (this.props.eshop) {
      this.props.push(
        `/wish/create/eshop/${this.props.eshop}/${this.props.guid}?${stringified}`
      );
    } else {
      this.props.push(`/wish/create/settings?${stringified}`);
    }

    //this.props.setStep('wish_create', 'settings');
  }

  openProduct(e) {
    e.stopPropagation();
    //this.props.setItem(this.props.id);
    // this.props.selectionActions.stepNext();
  }

  extractHostname(url) {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("://") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    // find & remove port number
    hostname = hostname.split(":")[0];
    // find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname;
  }

  renderShopName() {
    const hostname = this.extractHostname(this.props.productUrl);
    const logos = {
      "alza.cz": "alza-logo.svg",
      "www.alza.cz": "alza-logo.svg",
    };

    if (hostname in logos) {
      const logo = `/img/${logos[hostname]}`;
      return <img src={logo} alt={hostname} className="products-list__logo" />;
    }
    return hostname;
  }

  render() {
    const { amountNeeded } = this.props;

    return (
      <li
        className={
          this.props.selected ? "list__item list__item--selected" : "list__item"
        }
      >
        <div
          className="list__item-link"
          id="productListItem"
          onClick={() => this.handleClick()}
        >
          <img
            src={
              this.props.image ? this.props.image : "/img/placeholder-gift.svg"
            }
            alt="img-product"
            className="list__item-image"
          />
          <span className="list__item-content">
            {this.props.title}
            <span className="list__item-description">
              {!!amountNeeded && (
                <FormattedMessage
                  id="wish.create.productStartingPrice"
                  values={{ price: amountNeeded }}
                />
              )}
              <a
                href={this.props.productUrl}
                target="__blank"
                onClick={this.openProduct}
                className="list__item-shop"
              >
                Zobrazit na {this.renderShopName()}
              </a>
            </span>
          </span>
        </div>
      </li>
    );
  }
}

ProductListItem.propTypes = {
  title: PropTypes.string.isRequired,
  amountNeeded: PropTypes.number.isRequired,
  currency: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  productUrl: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  setItem: PropTypes.func.isRequired,
  eshop: PropTypes.string,
  guid: PropTypes.string,
};

ProductListItem.defaultProps = {
  // image: null,
  eshop: null,
  guid: null,
  currency: "czk",
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    setStep: bindActionCreators(setStep, dispatch),
    push: bindActionCreators(push, dispatch),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(ProductListItem));
