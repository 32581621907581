import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { StickyContainer } from "react-sticky";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import ConfirmModal from "components/ConfirmModal";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import Spinner from "components/Spinner";
import * as actions from "components/WishControllers/actions";
import * as constants from "components/WishControllers/constants";
import WishDescription from "components/WishControllers/WishDescription";
import WishHeader from "components/WishControllers/WishHeader";

import WishEditSettings from "./components/WishEditSettings";
import { back } from "./actions";

class WishEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      modalTitle: "",
      type: modalTypes.DEFAULT,
      modalCallback: () => {},
    };

    this.closeModal = this.closeModal.bind(this);
    this.back = this.back.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  componentWillMount() {
    this.props.actions.getWishById(this.getIdFromURL(), this.props.user);
  }

  componentWillUnmount() {
    this.props.actions.reset();
  }

  openModal(callback, title, type) {
    this.setState({
      isOpenModal: true,
      modalTitle: title,
      modalCallback: callback,
      type,
    });
  }

  closeModal() {
    this.setState({
      isOpenModal: false,
    });
  }

  getIdFromURL() {
    return this.props.location.pathname.replace("/wish/edit/", "");
  }

  back() {
    this.props.back(this.props.wish.id);
  }

  confirm() {
    if (!this.props.wish.title.length) {
      this.openModal(
        () => {},
        this.props.intl.formatMessage({ id: "wish.create.errorNoTitle" }),
        modalTypes.ERROR
      );
      return;
    }

    if (!this.props.wish.surprise_wish) {
      this.props.wish.surprise_wish = {};
    }
    this.updateQueryDescription();
    this.updateCustomUserNameQuery();
    this.updateTitleQuery();

    if (this.props.newImage.url !== "") {
      this.props.actions.updateWish(
        this.props.user,
        this.props.wish,
        this.props.newImage.file
      );
    } else {
      this.props.actions.updateWish(this.props.user, this.props.wish, null);
    }
  }

  updateQueryDescription() {
    const loc = window.location.search.substr(1);
    const parsedParams = queryString.parse(window.location.search);
    parsedParams.description = this.props.wish.description;
    const stringified = queryString.stringify(parsedParams);

    let updatedLocation;
    if (loc.length) {
      updatedLocation = window.location.href.replace(loc, stringified);
    } else {
      updatedLocation = `${window.location.href}?${stringified}`;
    }

    window.history.pushState({ path: updatedLocation }, "", updatedLocation);
  }

  updateTitleQuery() {
    const loc = window.location.search.substr(1);
    const parsedParams = queryString.parse(window.location.search);

    if (parsedParams.title !== this.props.wish.title) {
      parsedParams.title = this.props.wish.title;
      const stringified = queryString.stringify(parsedParams);

      let updatedLocation;
      if (loc.length) {
        updatedLocation = window.location.href.replace(loc, stringified);
      } else {
        updatedLocation = `${window.location.href}?${stringified}`;
      }

      window.history.pushState({ path: updatedLocation }, "", updatedLocation);
    }
  }

  updateCustomUserNameQuery() {
    if (
      this.props.wish.custom_user_name != null &&
      this.props.wish.custom_user_name.length
    ) {
      const loc = window.location.search.substr(1);
      const parsedParams = queryString.parse(window.location.search);
      parsedParams.custom_user_name = this.props.wish.custom_user_name;
      parsedParams.surprise_wish = "";
      const stringified = queryString.stringify(parsedParams);

      let updatedLocation;
      if (loc.length) {
        updatedLocation = window.location.href.replace(loc, stringified);
      } else {
        updatedLocation = `${window.location.href}?${stringified}`;
      }
      window.history.pushState({ path: updatedLocation }, "", updatedLocation);
    }
  }

  showControllers() {
    return (
      <div className="app-section edit-wish">
        <StickyContainer className="container container--narrow">
          <div className="content">
            <article className="wish-detail">
              <WishHeader />
              <WishDescription />
              <WishEditSettings />
            </article>
          </div>
          <ConfirmModal
            title={this.state.modalTitle}
            type={this.state.type}
            isOpen={this.state.isOpenModal}
            closeModal={this.closeModal}
            confirmAction={this.state.modalCallback}
          />
        </StickyContainer>
      </div>
    );
  }

  handleStatus() {
    switch (this.props.status) {
      case constants.STATUS_DEFAULT:
        return null;
      case constants.STATUS_LOADING:
        return <Spinner />;
      case constants.STATUS_LOADED:
        return this.showControllers();
      case constants.STATUS_LOADING_FAILED:
        return <div>loading failed</div>;
      case constants.STATUS_UNAUTHORIZED:
        return <div>unauthorized</div>;
      case constants.STATUS_UPLOADING:
        return <Spinner />;
      case constants.STATUS_UPLOADING_FAILED:
        return <div>uploading failed</div>;
      case constants.STATUS_UPLOADED:
        this.back();
        break;
      default:
        break;
    }
    return null;
  }

  render() {
    return (
      <DocumentTitle
        title={this.props.intl.formatMessage({ id: "menu.editWish" })}
      >
        {this.handleStatus()}
      </DocumentTitle>
    );
  }
}

WishEdit.propTypes = {
  actions: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
    loggedIn: PropTypes.bool,
  }).isRequired,
  status: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  status: state.wishContainer.status,
  wish: state.wishContainer.wish,
  newImage: state.wishContainer.newImage,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    back: bindActionCreators(back, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WishEdit));
