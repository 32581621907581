import { authFetchApi } from "services/api/";

import * as actionTypes from "./actionTypes";

export const setLoading = (value) => ({
  type: actionTypes.SET_LOADING,
  value,
});

export const changeQuery = (value) => ({
  type: actionTypes.CHANGE_QUERY,
  value,
});

export const search = (query, user) => (dispatch) => {
  dispatch(setLoading(true));
  authFetchApi(`/search/${query}`, user.accessToken, {
    method: "GET",
  })
    .then((data) => {
      dispatch(setLoading(false));
      dispatch({
        type: actionTypes.SEARCH,
        results: data,
        error: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SEARCH,
        results: [],
        error: true,
      });
    });
};
