import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi } from "services/api/";

import * as types from "./actionTypes";

export const receiveGroups = (item) => ({
  type: types.GET_GROUPS,
  item,
});

export const setChangeMode = () => ({
  type: types.CHANGE_MODE,
  item: null,
});

export const addGroupEntry = (item) => ({
  type: types.ADD_GROUP_ENTRY,
  item,
});

export const clearNewGroupInfo = () => ({
  type: types.CLEAR_NEW_GROUP,
  item: null,
});

export const setNewGroupColor = (item) => ({
  type: types.SET_NEW_GROUP_COLOR,
  item,
});

export const setNewGroupName = (item) => ({
  type: types.SET_NEW_GROUP_NAME,
  item,
});

export const setInputOk = (item) => ({
  type: types.SET_INPUT_OK,
  item,
});

export const setActiveIndex = (item) => ({
  type: types.SET_ACTIVE_GROUP_INDEX,
  item,
});

export const updateGroup = (item) => ({
  type: types.PATCH_GROUP,
  item,
});

export function getFriendGroups(user) {
  return (dispatch) => {
    authFetchApi("/account/friends/groups/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(receiveGroups(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function postNewGroup(group, user) {
  return (dispatch) => {
    const form = new FormData();
    form.append("name", group.name);
    form.append("color", group.color);
    return authFetchApi("/account/friends/groups/", user.accessToken, {
      method: "POST",
      body: form,
    })
      .then((json) => {
        dispatch(addGroupEntry(json));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function deleteGroup(groupId, user) {
  return (dispatch) =>
    authFetchApi(`/account/friends/groups/${groupId}/`, user.accessToken, {
      method: "DELETE",
    }).then(() => dispatch(deleteGroupSuccess(groupId)));
}

export function deleteGroupSuccess(groupId) {
  return {
    type: types.DELETE_GROUP_SUCCESS,
    groupId,
  };
}

export function patchGroup(group, user) {
  return (dispatch) => {
    const form = { members: group.members };
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    return authFetchApi(
      `/account/friends/groups/${group.id}/`,
      user.accessToken,
      options
    )
      .then((data) => {
        dispatch(updateGroup(data));
      })
      .catch((e) => {
        console.log("Error: ", e);
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
