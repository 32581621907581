export const CLOSE = "modal/CLOSE";
export const OPEN = "modal/OPEN";
export const DELETE = "modal/DELETE";
export const REFUND = "modal/REFUND";
export const DEFAULT = "modal/DEFAULT";
export const ERROR = "modal/ERROR";
export const LOGIN_ERROR = "modal/LOGIN_ERROR";
export const INVALID_ADDRESS = "modal/INVALID_ADDRESS";
export const DELETE_ACCOUNT = "modal/DELETE_ACCOUNT";
export const DELETE_FRIEND_LIST = "modal/DELETE_FRIEND_LIST";
export const SHOW_DONATORS = "modal/SHOW_DONATORS";
export const REMOVE_FRIEND = "modal/REMOVE_FRIEND";
export const USER_MONEY_RECEIVER = "modal/USER_MONEY_RECEIVER";
export const INFORMATION = "modal/INFORMATION";
export const ALLOWED_USERS = "modal/ALLOWED_USERS";
export const ABOUT_WOWEE = "modal/ABOUT_WOWEE";
export const SET_RECIPIENT = "modal/SET_RECIPIENT";
export const CHANGE_PICTURE = "modal/CHANGE_PICTURE";
export const LOGED_OUT = "modal/LOGED_OUT";
export const PASSWORD_CHANGED = "modal/PASSWORD_CHANGED";
export const PASSWORD_ERROR = "modal/PASSWORD_WRONG";
export const ALLOWED_USERS_DETAIL = "modal/ALLOWED_USERS_DETAIL";
export const NOT_ACTIVATED = "modal/RESEND_REGISTRATION_EMAIL";
export const YOU_ARE_LOGGED = "modal/YOU_ARE_LOGGED";
