/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as actions from '../../actions';

class PayingCard extends React.Component {
  constructor() {
    super();

    this.stepForward = this.stepForward.bind(this);
    this.stepBack = this.stepBack.bind(this);
  }

  stepForward() {
   this.props.actions.setStep(6);
  }


  stepBack() {
   this.props.actions.setStep(2);
  }


  render() {
    return (
    <div className="popup donate-popup donate-payment-method">
      <h3 className="popup__heading"><FormattedMessage id="donate.title" /></h3>
      <div className="popup__footer">
        <button className="button" onClick={this.stepBack}><FormattedMessage id="common.back" /></button>
        <button className="button button--next fill" onClick={this.stepForward}><FormattedMessage id="common.next" /></button>
      </div>

    </div>
    );
  }
}


PayingCard.propTypes = {
  setStep: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

function mapDispatchToProps(dispatch) {
  return {
    actions : bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayingCard);

