/**
 * Created by mi on 24.3.17.
 */
import * as types from "./actionTypes";
import * as constants from "./constants";

const initialState = {
  loggedIn: false,
  friendsError: false,
  registrationSent: false,
  alreadySent: false,
  newPasswordSent: false,
  successfulRegistration: false,
  wrongCredentials: false,
  submitting: constants.STATUS_DEFAULT,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.LOGIN:
      return payload.item;
    case types.FRIENDS_API_ERROR:
      return Object.assign({}, state, { friendsError: true });
    case types.LOGOUT:
      return Object.assign({}, initialState);
    case types.FETCH_USER:
      return Object.assign({}, state, payload.item);
    case types.REGISTRATION_SENT:
      return Object.assign({}, state, {
        registrationSent: true,
        alreadySent: false,
      });
    case types.ALREADY_SENT:
      return Object.assign({}, state, {
        alreadySent: true,
        registrationSent: false,
      });
    case types.NEW_PASSWORD_SENT:
      return Object.assign({}, state, { newPasswordSent: true });
    case types.WRONG_CREDENTIALS:
      return Object.assign({}, state, { wrongCredentials: true });
    case types.SUBMITTING:
      return Object.assign({}, state, { submitting: payload.item });
    default:
      return state;
  }
};
