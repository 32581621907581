import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import FriendsList from "./components/FriendList";
import UpcomingBirthdays from "./components/UpcomingBirthdaysComponent";

class Friends extends Component {
  render() {
    if (this.props.user.loggedIn) {
      return (
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "menu.friends" })}
        >
          <section className="app-section friends">
            <FriendsList />
            <aside className="sidebar sidebar--narrow">
              {this.props.friends.length ? <UpcomingBirthdays /> : null}
            </aside>
          </section>
        </DocumentTitle>
      );
    }
    return <DocumentTitle title="Friends" />;
  }
}
Friends.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }).isRequired,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  friends: state.friends.friends.all,
});
export default connect(mapStateToProps, null)(injectIntl(Friends));
