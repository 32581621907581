import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const PermissionItem = ({ id, value, onChange }) => (
  <div id={id}>
    <h4>
      <FormattedMessage id={`gdpr.${id}.title`} />
    </h4>
    <h5>
      <FormattedMessage id={`gdpr.${id}.description`} />
    </h5>
    <div className="popup__buttons">
      <div>
        <label onClick={() => onChange(id, true)} htmlFor="gdpr_accpet">
          <FormattedMessage id="gdpr.popup.accept" />
        </label>
        <input
          type="radio"
          value="true"
          checked={value === true}
          onChange={() => onChange(id, true)}
        />
      </div>
      <div>
        <label onClick={() => onChange(id, false)} htmlFor="gdpr.decline">
          <FormattedMessage id="gdpr.popup.decline" />
        </label>
        <input
          type="radio"
          value="false"
          checked={value === false}
          onChange={() => onChange(id, false)}
        />
      </div>
    </div>
  </div>
);

PermissionItem.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isDefined, // eslint-disable-line
  onChange: PropTypes.func.isRequired,
};

export default PermissionItem;
