import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as actions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as modalActions from "components/ConfirmModal/actions";
import * as types from "components/ConfirmModal/actionTypes";

class ForgottenPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      email_status: 0,
    };
  }

  componentDidMount() {
    if (this.props.user.loggedIn) {
      this.props.modalActions.open({
        title: "",
        type: types.YOU_ARE_LOGGED,
      });
    }
  }

  componentWillUnmount() {}

  validateEmail(email) {
    const re =
      /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });

    if (this.validateEmail(e.target.value)) {
      this.setState({
        email_status: 1,
      });
    } else {
      this.setState({
        email_status: 2,
      });
    }

    if (!e.target.value.length) {
      this.setState({
        email_status: 0,
      });
    }
  };

  getEmailMessage() {
    switch (this.state.email_status) {
      case 1:
        return (
          <p className="form__description form__description--success">
            <FormattedMessage id="login.email.valid" />
          </p>
        );
      case 2:
        return (
          <p className="form__description form__description--danger">
            <FormattedMessage id="login.email.invalid" />
          </p>
        );
      default:
        return <p></p>;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.email_status === 1) {
      this.props.actions.createForgottenPasswordHash(this.state.email);
    }
  };

  render() {
    return (
      <section className="login main">
        <div className="login-promo">
          <img src="img/celebrating.png" className="login-promo__img" alt="" />
        </div>

        <div className="login-form">
          <h1 className="login-form__title">
            <FormattedMessage id="login.password.forgotten.title" />
          </h1>
          {!this.props.user.newPasswordSent ? (
            <div>
              <p>
                <FormattedMessage id="login.password.forgotten.info" />
              </p>
              <form className="form">
                <div className="form__row">
                  <label className="form__label">
                    <FormattedMessage id="common.email" />
                  </label>
                  <input
                    type="email"
                    placeholder={this.props.intl.formatMessage({
                      id: "login.forgotten_password.placeholder",
                    })}
                    className="form__field"
                    onChange={this.handleChangeEmail}
                  />
                  {this.getEmailMessage()}
                </div>

                <div className="form__row">
                  <input
                    type="submit"
                    value={this.props.intl.formatMessage({
                      id: "common.submit",
                    })}
                    className="button login-button"
                    onClick={this.handleSubmit}
                  />
                </div>
              </form>
            </div>
          ) : (
            <p>
              <FormattedMessage id="login.password.forgotten.email_sent" />
            </p>
          )}
        </div>
      </section>
    );
  }
}

ForgottenPasswordForm.propTypes = {
  return_url: PropTypes.string,

  actions: PropTypes.shape({
    //    createCommentBox: PropTypes.func,
    //    removeCommentBox: PropTypes.func,
  }).isRequired,

  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ForgottenPasswordForm));
