import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";

class About extends Component {
  render() {
    return (
      <Fragment>
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "about.title" })}
        >
          <section className="static-page">
            <div className="container container--narrow">
              <FormattedHTMLMessage id="about.content" />
            </div>
          </section>
        </DocumentTitle>
        <Footer lightBackground {...this.props} />
      </Fragment>
    );
  }
}

About.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(About);
