// import * as constants from "../../services/api/constants";
export const PAGE_URL =
  process.env.REACT_APP_PAGE_URL || "http://localhost:3000";
export const COOKIE_DOMAIN =
  process.env.REACT_APP_COOKIE_DOMAIN || ".elateme.com";

export const GA_ID = process.env.REACT_APP_GA_ID || "UA-110875388-1";
export const GA4_ID = process.env.REACT_APP_GA4_ID || "not-set";
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID || "187342141632778";
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
