import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { StickyContainer } from "react-sticky";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import ConfirmModal from "components/ConfirmModal";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { setStep } from "components/Containers/MultiStep/actions";
import * as actions from "components/WishControllers/actions";
import WishDescription from "components/WishControllers/WishDescription";
import WishHeader from "components/WishControllers/WishHeader";
import WishSettings from "components/WishControllers/WishSettings";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      modalTitle: "",
      type: modalTypes.DEFAULT,
      modalCallback: () => {},
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    const values = queryString.parse(window.location.search);

    if (values.expiration_date) {
      this.props.actions.setDateOfExpiration(values.expiration_date);
    }

    if (values.flat_donation) {
      this.props.actions.setFlatDonation(Number(values.flat_donation));
    }

    if (values.allowed_users) {
      this.props.actions.setAllowedUsers(values.allowed_users);
    }

    if (values.surprise_wish && values.surprise_wish.length) {
      this.props.actions.setSurpriseWish({
        recipient: Number(values.surprise_wish),
      });
    }

    if (values.description && values.description.length) {
      this.props.actions.setDescription(values.description);
    }

    if (values.custom_user_name && values.custom_user_name.length) {
      this.props.actions.setCustomUserName(values.custom_user_name);
    } else {
      this.props.actions.setCustomUserName(null);
    }

    if (values.image && values.image.length) {
      this.props.actions.setImages([{ image: values.image }]);
    }
    const currency = values.currency || "czk";
    this.props.actions.setCurrency(currency.toLowerCase());

    if (values.user_money_receiver) {
      this.props.actions.setUserMoneyReceiver(
        Number(values.user_money_receiver)
      );
    } else {
      this.props.actions.setUserMoneyReceiver(this.props.user.id);
    }

    if (values.visibility) {
      this.props.actions.setVisibility(values.visibility);
    } else {
      this.props.actions.setVisibility("public");
    }

    if (values.creation_source) {
      this.props.actions.setCreationSource(values.creation_source);
    }

    this.props.actions.setTitle(
      values.title && values.title.length > 100
        ? values.title.substring(0, 100)
        : values.title
    );
    this.props.actions.setAmountNeeded(Number(values.amount));
    this.props.actions.setProductUrl(values.productURL);

    if (
      window.location.pathname.split("/")[3] === "eshop" &&
      values.productURL &&
      !values.amount
    ) {
      this.props.actions.loadDataFromEshop(this.props.user, values.productURL);
    }
  }

  openModal(callback, title, type) {
    this.setState({
      isOpenModal: true,
      modalTitle: title,
      modalCallback: callback,
      type,
    });
  }

  closeModal() {
    this.setState({
      isOpenModal: false,
    });
  }

  back() {
    this.props.actions.setTitle("");
    this.props.actions.setDescription("");
    this.props.actions.setAmountNeeded(0);
    this.props.actions.setProductUrl("");
    this.props.actions.setImages([]);
    this.props.actions.setNewImage({ file: null, url: "" });

    this.props.setStep("wish_create", "selection");
  }

  confirm() {
    if (!this.props.wish.title || !this.props.wish.title.length) {
      this.openModal(
        () => {},
        this.props.intl.formatMessage({ id: "wish.create.errorNoTitle" }),
        modalTypes.ERROR
      );
      return;
    }

    this.updateQueryStrings();

    if (!this.props.user.loggedIn) {
      if (localStorage) {
        const serializedWish = JSON.stringify(this.props.wish);
        localStorage.setItem("unregisteredWish", serializedWish);
      }
      this.props.push("/login");
    } else {
      if (this.props.newImage.url !== "") {
        this.props.actions.createWish(
          this.props.user,
          this.props.wish,
          this.props.newImage.file
        );
      } else {
        this.props.actions.createWish(this.props.user, this.props.wish, null);
      }
    }
  }

  updateQueryStrings() {
    const loc = window.location.search.substr(1);
    const parsedParams = queryString.parse(window.location.search);

    if (this.props.wish.surprise_wish.recipient != undefined) {
      parsedParams.surprise_wish = this.props.wish.surprise_wish.recipient;
    }
    if (Object.keys(this.props.wish.allowed_users).length) {
      parsedParams.allowed_users = Object.keys(this.props.wish.allowed_users);
    }

    parsedParams.amount = this.props.wish.amount_needed;
    parsedParams.flat_donation = this.props.wish.flat_donation;
    parsedParams.expiration_date = this.props.wish.date_of_expiration;
    parsedParams.title = this.props.wish.title;
    parsedParams.description = this.props.wish.description;
    parsedParams.custom_user_name = this.props.wish.custom_user_name;
    parsedParams.visibility = this.props.wish.visibility;

    const stringified = queryString.stringify(parsedParams);

    let updatedLocation;
    if (loc.length) {
      updatedLocation = window.location.href.replace(loc, stringified);
    } else {
      updatedLocation = window.location.href + "?" + stringified;
    }
    window.history.pushState({ path: updatedLocation }, "", updatedLocation);
  }

  render() {
    return (
      <div className="app-section create-wish">
        <StickyContainer className="container container--narrow">
          <div className="content">
            <article className="wish-detail">
              <WishHeader />
              <WishDescription />
              <WishSettings />
            </article>
          </div>
          <ConfirmModal
            title={this.state.modalTitle}
            type={this.state.type}
            isOpen={this.state.isOpenModal}
            closeModal={this.closeModal}
            confirmAction={this.state.modalCallback}
          />
        </StickyContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wish: state.wishContainer.wish,
  user: state.user,
  allUsers: state.users.all,
  newImage: state.wishContainer.newImage,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    setStep: bindActionCreators(setStep, dispatch),
    push: bindActionCreators(push, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Settings));
