import React from "react";

const SearchIcon = () => (
  <svg className="wish-search__icon" viewBox="161 219.947 17.261 17.261">
    <g id="Group_22" opacity="0.5" transform="translate(0 -123.053)">
      <g
        id="Ellipse_1"
        fill="none"
        stroke="#96999e"
        transform="translate(161 343)"
      >
        <circle stroke="none" cx="6.559" cy="6.559" r="6.559" />
        <circle fill="none" cx="6.559" cy="6.559" r="6.059" />
      </g>
      <line
        id="Line_5"
        stroke="#96999e"
        strokeWidth="2"
        fill="none"
        x2="5.622"
        y2="5.622"
        transform="translate(171.932 353.932)"
      />
    </g>
  </svg>
);

export default SearchIcon;
