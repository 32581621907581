import App from "components/App";
import { privateRoute } from "components/Router";
import About from "scenes/About";
import AccessRequests from "scenes/AccessRequests";
import Contact from "scenes/Contact";
import EshopProduct from "scenes/EshopProduct";
import Faq from "scenes/Faq";
import ForgottenPassword from "scenes/ForgottenPassword";
import Friends from "scenes/Friends/index";
import General from "scenes/General";
import Home from "scenes/Home";
import Login from "scenes/Login";
import NotFound from "scenes/NotFound";
import Notifications from "scenes/Notifications";
import Privacy from "scenes/Privacy";
import Search from "scenes/Search";
import Settings from "scenes/Settings/index";
import SetUpNewPassword from "scenes/SetUpNewPassword";
import SetupPartnersPassword from "scenes/SetupPartnersPassword";
import ShoptetWidget from "scenes/ShoptetWidget";
import SignUp from "scenes/SignUp";
import SignUpFinish from "scenes/SignUpFinish";
import Thanks from "scenes/Thanks";
import UpcomingBirthdays from "scenes/UpcomingBirthdays";
import User from "scenes/User";
import WishCreate from "scenes/Wish/scenes/Create";
import WishDetail from "scenes/Wish/scenes/Detail";
import WishEdit from "scenes/Wish/scenes/Edit";

const routes = [
  {
    component: App,
    routes: [
      {
        path: "/",
        exact: true,
        component: Home,
      },
      {
        path: "/wish/create/eshop/:eshopId/:eshopProductId",
        component: WishCreate,
      },
      {
        path: "/wish/create/:id",
        component: privateRoute(WishCreate),
      },
      {
        path: "/create/settings",
        component: WishCreate,
      },
      {
        path: "/create",
        component: WishCreate,
      },
      {
        path: "/setup-new-password/:hashKey",
        component: SetUpNewPassword, // TODO vytvor notLoggedRoute()
      },
      {
        path: "/set-partners-password/:hashKey",
        component: SetupPartnersPassword, // TODO vytvor notLoggedRoute()
      },
      {
        path: "/wish/create",
        component: privateRoute(WishCreate),
      },
      {
        path: "/wish/detail/:id",
        component: WishDetail,
      },
      {
        path: "/wish/edit/:id",
        component: privateRoute(WishEdit),
      },
      {
        path: "/user/:userId",
        component: User,
      },
      {
        path: "/friends",
        component: privateRoute(Friends),
      },
      {
        path: "/settings",
        component: privateRoute(Settings),
      },

      {
        path: "/access-requests",
        component: privateRoute(AccessRequests),
      },
      {
        path: "/notifications",
        component: privateRoute(Notifications),
      },
      {
        path: "/conditions",
        component: General,
      },
      {
        path: "/faq",
        component: Faq,
      },
      {
        path: "/privacy",
        component: Privacy,
      },
      {
        path: "/shoptet-widget",
        component: ShoptetWidget,
      },
      {
        path: "/about",
        component: About,
      },
      {
        path: "/thanks",
        component: Thanks,
      },
      {
        path: "/contact",
        component: Contact,
      },
      {
        path: "/upcoming-birthdays",
        component: privateRoute(UpcomingBirthdays),
      },
      {
        path: "/login",
        component: Login,
      },
      {
        path: "/sign-up",
        component: SignUp,
      },
      {
        path: "/finish-sign-up/:hashKey",
        component: SignUpFinish,
      },
      {
        path: "/forgotten-password",
        component: ForgottenPassword,
      },
      {
        path: "/share/url=:url",
        component: privateRoute(WishCreate),
      },
      {
        path: "/search/:query",
        component: privateRoute(Search),
      },
      {
        path: "/eshops/:eshopName/:sid/:id",
        component: EshopProduct,
      },
      {
        path: "*",
        component: NotFound,
      },
    ],
  },
];

export default routes;
