import React, { Component } from "react";

import UserImage from "components/UserImage";

class FriendButton extends Component {
  render() {
    let className = "contacts__item list__item";

    if (this.props.checkedWhen) {
      className = "contacts__item list__item selected";
    }

    return (
      <a
        className={className}
        onClick={() => this.props.onClick(this.props.id)}
      >
        <UserImage className="contacts__user-avatar" user={this.props} />
        <span className="contacts__user-name">
          {this.props.first_name} <strong>{this.props.last_name}</strong>
        </span>
      </a>
    );
  }
}

export default FriendButton;
