import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

import ProductImage from "components/ProductImage";
import FormattedHTMLMessage from "services/localization/flex";

class CardDonationFailed extends Component {
  renderRestricted() {
    return (
      <FormattedHTMLMessage id="popup.card-donation-failed-restricted.subheading" />
    );

    /*
    return (
      <FormattedHTMLMessage id="popup.card-donation-failed-restricted.subheading" values={{
          currency: this.props.popup.wish.currency.toUpperCase(),
          amount: this.props.popup.donation.amount,
        }} />
    );
    */
  }

  renderFull() {
    return (
      <FormattedHTMLMessage
        id="popup.card-donation-failed.subheading"
        values={{
          title: this.props.popup.wish.title,
          currency: this.props.popup.wish.currency.toUpperCase(),
        }}
      />
    );
  }

  render() {
    const is_restricted = !this.props.popup.wish.title;
    return (
      <div className="popup popup--with-header received-gift">
        <div className="popup__inner">
          <h3 className="popup__heading">
            <FormattedMessage id="popup.card-donation-failed.heading" />
          </h3>
          <div className="row spacing-md align-center">
            <div>
              <h4 className="popup__title">
                {is_restricted ? this.renderRestricted() : this.renderFull()}
              </h4>
            </div>
            <div className="product-image">
              <ProductImage wish={this.props.popup.wish} />
            </div>
          </div>

          <div className="popup__footer">
            <button
              className="button fill button--primary"
              onClick={this.props.closeModal}
            >
              <FormattedMessage id="common.close" />
            </button>
          </div>
        </div>
        <button
          className="fancybox-close-small"
          onClick={this.props.closeModal}
          disabled={this.props.closingPopup}
        />
      </div>
    );
  }
}

CardDonationFailed.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closingPopup: PropTypes.bool.isRequired,
  popup: PropTypes.shape({
    id: PropTypes.number,
    wish: PropTypes.shape({
      id: PropTypes.number,
      author: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        image: PropTypes.string,
      }),
      title: PropTypes.string,
      description: PropTypes.string,
      amount_needed: PropTypes.number,
      date_created: PropTypes.string,
      date_of_expiration: PropTypes.string,
      date_completed: PropTypes.string,
      amount_gathered: PropTypes.number,
      donators_count: PropTypes.number,
      currency: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
        })
      ),
      wish_state: PropTypes.string.isRequired,
      surprise_wish: PropTypes.shape({
        recipient: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
          gender: PropTypes.string,
          image: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(CardDonationFailed);
