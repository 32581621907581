import React, { Component } from "react";
import { Link } from "react-router-dom";

import ProductImage from "components/ProductImage";

class Wish extends Component {
  render() {
    const { wish } = this.props;
    return (
      <Link
        to={`/wish/detail/${wish.id}`}
        key={this.props.index}
        className="search__people"
      >
        <div className="search__wish-img">
          <ProductImage wish={wish} />
        </div>
        <h1 className="search__people-name">
          <strong>{wish.title}</strong>
        </h1>
        <h1 className="search__people-name search__people-name--secondary">
          pro
        </h1>
        <h1 className="search__people-name search__people-name--secondary">
          {wish.user_money_receiver.first_name}
        </h1>
        <h1 className="search__people-name search__people-name--secondary">
          {wish.user_money_receiver.last_name}
        </h1>
      </Link>
    );
  }
}

export default Wish;
