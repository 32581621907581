import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import ConfirmModal from "components/ConfirmModal";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import DayPickerForm from "components/DayPickerForm";
import * as actions from "components/WishControllers/actions";
import FormattedHTMLMessage from "services/localization/flex";

import "moment/locale/cs";
import "moment/locale/en-gb";

import DoneeSelector from "../../../../../scenes/Wish/scenes/Create/components/Selection/components/SearchBox/DoneeSelector";

import CustomFriendsList from "./CustomFriendsList";
import UserMoneyReceiverSelector from "./UserMoneyReceiverSelector";

import "react-day-picker/lib/style.css";

const currentYear = new Date().getFullYear();

class Visibility extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: new Date(currentYear, new Date().getMonth()),
      selectedVisibility: this.props.visibility,
      isOpenModal: false,
      modalTitle: "",
      modalType: modalTypes.DEFAULT,
      startDate: this.props.expiration_date
        ? new Date(this.props.expiration_date)
        : null,
      value: this.props.flat_donation ? this.props.flat_donation : 0,
      flatDonation: !!this.props.flat_donation,
      closeModal: () => {},
    };

    this.closeModalVisibility = this.closeModalVisibility.bind(this);
    this.closeModalRecipient = this.closeModalRecipient.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.setAllowedUsers = this.setAllowedUsers.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  closeModalVisibility() {
    if (!Object.keys(this.props.allowedUsers).length) {
      this.props.actions.setVisibility("public");
      this.props.actions.setAllowedUsers([]);
      this.setState({ selectedVisibility: "public" });
    } else {
      this.props.actions.setVisibility("custom_users");
    }
    this.setState({
      isOpenModal: false,
    });
  }

  closeModalRecipient() {
    if (
      this.props.surprise_wish &&
      this.props.surprise_wish.recipient != undefined
    ) {
      const recipient = this.props.users[this.props.surprise_wish.recipient];
      this.setState({
        recipientPlaceholder: recipient.first_name + " " + recipient.last_name,
      });
    }

    this.setState({
      isOpenModal: false,
    });
  }

  setAllowedUsers() {
    this.setState({ isOpenModal: true });
    this.setState({ modalTitle: "Set allowed users" });
    this.setState({ modalType: modalTypes.ALLOWED_USERS });
    this.setState({ closeModal: this.closeModalVisibility });
  }

  handleChangeVisibility = (event) => {
    this.setState({ selectedVisibility: event.target.value });
    switch (event.target.value) {
      case "public":
        this.props.actions.setAllowedUsers([]);
        this.props.actions.setVisibility("public");
        break;
      case "friends":
        this.props.actions.setAllowedUsers(this.props.friends);
        this.props.actions.setVisibility("friends");
        break;
      case "me":
        this.props.actions.setAllowedUsers([this.props.user.id || "me"]);
        this.props.actions.setVisibility("me");
        break;
      case "custom_users":
        this.props.actions.setAllowedUsers([]);
        this.setAllowedUsers();
        break;
      default:
        this.props.actions.setVisibility("public");
        break;
    }
  };

  handleChangeDate = (date) => {
    this.setState({
      startDate: date,
    });
    if (date != null) {
      this.props.actions.setDateOfExpiration(date);
    }
  };

  handleChangeFlatDonation = (event) => {
    this.setState({
      value: event.target.value,
    });
    this.props.actions.setFlatDonation(event.target.value);
  };

  handleChangeValue = (flag) => {
    this.setState({
      flatDonation: flag,
    });

    if (!flag) {
      this.props.actions.setFlatDonation(null);
    } else if (flag && this.state.value) {
      this.props.actions.setFlatDonation(this.state.value);
    }
  };

  onMonthChange(month) {
    this.setState({
      month,
    });
  }

  confirm() {
    window.location.pathname.split("/").length > 2 &&
    window.location.pathname.split("/")[2] === "edit"
      ? this.confirmEdit()
      : this.confirmCreate();
  }

  confirmEdit = () => {
    if (!this.props.wish.surprise_wish) {
      this.props.actions.setSurpriseWish({});
    }

    if (this.props.newImage.url !== "") {
      this.props.actions.updateWish(
        this.props.user,
        this.props.wish,
        this.props.newImage.file
      );
    } else {
      this.props.actions.updateWish(this.props.user, this.props.wish, null);
    }
  };

  confirmCreate = () => {
    this.updateQueryStrings();

    if (!this.props.user.loggedIn) {
      if (localStorage) {
        const serializedWish = JSON.stringify(this.props.wish);
        localStorage.setItem("unregisteredWish", serializedWish);
      }
      this.props.push("/sign-up");
    } else {
      if (this.props.newImage && this.props.newImage.url !== "") {
        this.props.actions.createWish(
          this.props.user,
          this.props.wish,
          this.props.newImage.file
        );
      } else {
        this.props.actions.createWish(this.props.user, this.props.wish, null);
      }
    }
  };

  updateQueryStrings() {
    const loc = window.location.search.substr(1);
    const parsedParams = queryString.parse(window.location.search);

    if (this.props.wish.surprise_wish.recipient != undefined) {
      parsedParams.surprise_wish = this.props.wish.surprise_wish.recipient;
    }
    if (Object.keys(this.props.wish.allowed_users).length) {
      parsedParams.allowed_users = Object.keys(this.props.wish.allowed_users);
    }

    parsedParams.amount = this.props.wish.amount_needed;
    parsedParams.flat_donation = this.props.wish.flat_donation;
    parsedParams.visibility = this.props.wish.visibility;
    parsedParams.expiration_date = this.props.wish.date_of_expiration;
    parsedParams.title = this.props.wish.title;
    parsedParams.description = this.props.wish.description;
    parsedParams.custom_user_name = this.props.wish.custom_user_name;
    parsedParams.user_money_receiver = this.props.user_money_receiver;

    const stringified = queryString.stringify(parsedParams);

    let updatedLocation;
    if (loc.length) {
      updatedLocation = window.location.href.replace(loc, stringified);
    } else {
      updatedLocation = window.location.href + "?" + stringified;
    }
    window.history.pushState({ path: updatedLocation }, "", updatedLocation);
  }

  showButtonDown() {
    return (
      <a
        className="button button--confirm-down"
        onClick={() => this.confirm()}
        ref={(bt) => {
          this.buttonConfirm = bt;
        }}
      >
        <FormattedMessage id="common.confirm" />
      </a>
    );
  }

  render() {
    return (
      <div className="form-transform">
        <div className="form__group form__group--always-row">
          <div className="form__row">
            <label htmlFor="forWho" className="form__label">
              <FormattedHTMLMessage id="wish.create.surprise_wish" />
            </label>
            <DoneeSelector onConfirm={() => {}} />
          </div>
          <div className="form__row">
            <label className="form__label" htmlFor="moneyReceiver">
              <FormattedHTMLMessage id="wish.create.money_receiver.who" />
            </label>
            <UserMoneyReceiverSelector />
          </div>
        </div>
        <div className="form__group form__group--always-row">
          <div className="form__row">
            <label className="form__label" htmlFor="visibility">
              <FormattedHTMLMessage id="wish.create.visibility" />
            </label>
            <span className="form__icon">
              <select
                name="visibility"
                className="form__field"
                value={this.state.selectedVisibility || ""}
                onChange={this.handleChangeVisibility}
              >
                <option value="public">
                  {this.props.intl.formatMessage({
                    id: "wish.create.visibilityPublic",
                  })}
                </option>
                <option value="friends">
                  {this.props.intl.formatMessage({
                    id: "wish.create.visibilityFriends",
                  })}
                </option>
                <option value="me">
                  {this.props.intl.formatMessage({
                    id: "wish.create.visibilityMe",
                  })}
                </option>
                <option
                  value="custom_users"
                  disabled={!this.props.user.loggedIn}
                >
                  {this.props.intl.formatMessage({
                    id: "wish.create.visibilityCustomUsers",
                  })}
                </option>
              </select>
            </span>
          </div>
          <div className="form__row">
            <label className="form__label" htmlFor="deadline">
              <FormattedHTMLMessage id="wish.create.deadline" />
            </label>
            <div className="form__field">
              <DayPickerForm
                deadline={this.state.startDate}
                value={
                  this.props.wish.date_of_expiration
                    ? new Date(this.props.wish.date_of_expiration)
                    : null
                }
                onDayChange={this.handleChangeDate}
                placeholder={this.props.intl.formatMessage({
                  id: "wish.create.setDeadline",
                })}
                locale={this.props.locale}
                month={this.state.month}
                onChange={this.onMonthChange}
              />
            </div>
          </div>
        </div>
        <div className="form__group form__group--always-row">
          <div className="form__row">
            {this.state.selectedVisibility === "custom_users" && (
              <CustomFriendsList
                allowedUsers={Object.keys(this.props.allowedUsers)}
                allUsers={this.props.users}
                handleEdit={this.setAllowedUsers}
              />
            )}
          </div>
        </div>
        <div className="form__group form__group--always-row">
          <div className="form__row">
            <label className="form__label" htmlFor="donations">
              <FormattedHTMLMessage id="wish.create.donations" />
            </label>

            <ul className="list list--selectable">
              <li
                className={
                  !this.state.flatDonation
                    ? "list__item selected"
                    : "list__item"
                }
                onClick={() => this.handleChangeValue(0)}
              >
                <input type="radio" id="c1" name="cc" />
                <label htmlFor="c1" className="fill">
                  <FormattedMessage id="wish.create.donatorsChoice" />
                </label>
              </li>
              <li
                className={
                  this.state.flatDonation ? "list__item selected" : "list__item"
                }
                onClick={() => this.handleChangeValue(1)}
              >
                <input type="radio" id="c2" name="cc" />
                <label htmlFor="c2" className="fill">
                  <FormattedMessage id="wish.create.fixedValue" />
                  <input
                    type="number"
                    className="form__field form__field--short"
                    value={
                      this.props.flat_donation
                        ? this.props.flat_donation
                        : this.state.value
                    }
                    onChange={this.handleChangeFlatDonation}
                    max={999}
                    step={50}
                  />
                  {/*TODO this.props.currency.toUpperCase()*/}
                </label>
              </li>
            </ul>

            {/*<ul className="list list--selectable">*/}
            {/*<li className={!this.state.flatDonation ? 'list__item selected'*/}
            {/*: 'list__item' } onClick={() => this.handleChangeValue(0)}>*/}
            {/*<span className="fill">*/}
            {/*<FormattedMessage id="wish.create.donatorsChoice" />*/}
            {/*</span>*/}
            {/*</li>*/}
            {/*<li className={this.state.flatDonation ? 'list__item selected'*/}
            {/*: 'list__item' } onClick={() => this.handleChangeValue(1)}>*/}
            {/*<span className="fill">*/}
            {/*<FormattedMessage id="wish.create.fixedValue" />*/}
            {/*<input*/}
            {/*type="number"*/}
            {/*className="form__field form__field--short"*/}
            {/*value={this.props.flat_donation ? this.props.flat_donation : this.state.value}*/}
            {/*onChange={this.handleChangeFlatDonation}*/}
            {/*max={999}*/}
            {/*step={50}*/}
            {/*/>*/}
            {/*/!* TODO this.props.currency.toUpperCase()*!/*/}
            {/*<FormattedHTMLMessage id="wish.create.fixedValueInfo" />*/}
            {/*</span>*/}
            {/*</li>*/}
            {/*</ul>*/}
          </div>
        </div>
        <div className="form__group form__group--always-row form__group--button">
          {this.showButtonDown()}
        </div>

        <ConfirmModal
          title={this.state.modalTitle}
          isOpen={this.state.isOpenModal}
          type={this.state.modalType}
          closeModal={this.state.closeModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  visibility: state.wishContainer.wish.visibility,
  friends: state.friends.friends.all,
  users: state.users.all,
  user: state.user,
  allowedUsers: state.wishContainer.wish.allowed_users,
  surprise_wish: state.wishContainer.wish.surprise_wish,
  custom_user_name: state.wishContainer.wish.custom_user_name,
  flat_donation: state.wishContainer.wish.flat_donation,
  expiration_date: state.wishContainer.wish.date_of_expiration,
  user_money_receiver: state.wishContainer.wish.user_money_receiver,
  wish: state.wishContainer.wish,
  newImage: state.wishContainer.newImage,
  locale: state.intl.locale,
});

Visibility.propTypes = {
  intl: intlShape.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    push: bindActionCreators(push, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Visibility));
