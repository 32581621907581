import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "components/WishControllers/actions";

import CustomWishButton from "./components/CustomWishButton";
import ProductList from "./components/ProductList";
import SearchBox from "./components/SearchBox";

class Selection extends Component {
  render() {
    return (
      <section className="app-section create-wish">
        <div className="container container--narrow">
          <SearchBox />
          <CustomWishButton />
          <ProductList />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  suggestedStatus: state.wishCreate.suggestedStatus,
  allUsers: state.users.all,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Selection);
