import { authFetchApi } from "services/api/";

import { fetchApi } from "../../services/api";

import * as types from "./actionTypes";

export const createCommentBox = (item) => ({
  type: types.CREATE_COMMENT_BOX,
  item,
});

export const removeCommentBox = (item) => ({
  type: types.REMOVE_COMMENT_BOX,
  item,
});

export const addCommentAttachments = (item) => ({
  type: types.ADD_COMMENT_ATTACHMENTS,
  item,
});

export const addCommentPage = (wishid, comments, count) => ({
  type: types.ADD_COMMENT_PAGE,
  item: {
    id: wishid,
    comments,
    count,
  },
});

export const addSingleComment = (wishid, comment) => ({
  type: types.ADD_SINGLE_COMMENT,
  item: {
    id: wishid,
    comment,
  },
});

export const setCommentText = (wishid, text) => ({
  type: types.SET_COMMENT_TEXT,
  item: {
    id: wishid,
    text,
  },
});

export function loadCommentPage(user, wishid, page, pageSize) {
  const adress = `/wishes/${wishid}/comments/?page=${page}&page_size=${pageSize}`;
  const options = { method: "GET" };
  let request;

  if (user && user.loggedIn) {
    request = authFetchApi(adress, user.accessToken, options);
  } else {
    request = fetchApi(adress, options);
  }

  return (dispatch) => {
    request
      .then((data) => {
        if (data.results.length !== 0) {
          const comments = data.results.map((comment) =>
            extractCommentAttachments(comment)
          );
          dispatch(addCommentPage(wishid, comments, data.count));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function extractCommentAttachments(comment) {
  try {
    const attachments = JSON.parse(comment.json_object);
    return Object.assign({}, comment, { attachments });
  } catch (err) {} // eslint-disable-line no-empty
  return comment;
}

function getAttachmentData(user, url) {
  const attachmentForm = new FormData();
  attachmentForm.append("page_url", url);

  return authFetchApi("/wishes/webpage/", user.accessToken, {
    method: "POST",
    body: attachmentForm,
  });
}

export function uploadComment(user, wishid, text) {
  return async (dispatch) => {
    const urlRegex = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i; // eslint-disable-line
    const urlMatches = text.match(urlRegex);
    const form = new FormData();

    if (urlMatches) {
      const attachments = await getAttachmentData(user, urlMatches[0]);
      form.append("text", text.replace(urlRegex, ""));
      form.append("json_object", JSON.stringify(attachments));
    } else {
      form.append("text", text);
    }

    authFetchApi(`/wishes/${wishid}/comments/`, user.accessToken, {
      method: "POST",
      body: form,
    })
      .then((data) => {
        data.text = text;
        data.date_created = String(new Date());
        dispatch(addSingleComment(data.wish, extractCommentAttachments(data)));
        dispatch(setCommentText(wishid, ""));
        // loadCommentAttachments(user, data, dispatch);
      })
      .catch((err) => {
        alert("Comment upload failed!");
        console.log(err);
      });
  };
}
