import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import { setStep } from "components/Containers/MultiStep/actions";
import Visibility from "components/WishControllers/WishSettings/components/Visibility";

class WishEditSettings extends Component {
  showRecipient() {
    if (
      this.props.custom_user_name != null &&
      this.props.custom_user_name.length
    ) {
      return this.props.custom_user_name;
    } else if (
      this.props.surprise_wish === null ||
      this.props.surprise_wish.recipient === undefined
    ) {
      return <FormattedMessage id="wish.create.recipientTitleMe" />;
    } else {
      return `${
        this.props.users[this.props.surprise_wish.recipient].first_name
      } ${this.props.users[this.props.surprise_wish.recipient].last_name}`;
    }
  }

  showDeadline() {
    if (!this.props.deadline) {
      return <FormattedMessage id="wish.create.noDeadline" />;
    }

    const date = new Date(this.props.deadline);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  render() {
    return (
      <section className="wish-detail__section">
        <h3 className="wish-detail__section-heading">
          <FormattedMessage id="wish.create.giftSettings" />
        </h3>
        <Visibility />
      </section>
    );
  }
}

WishEditSettings.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    setStep: bindActionCreators(setStep, dispatch),
  };
}

const mapStateToProps = (state) => ({
  visibility: state.wishContainer.wish.visibility,
  deadline: state.wishContainer.wish.date_of_expiration,
  surprise_wish: state.wishContainer.wish.surprise_wish,
  custom_user_name: state.wishContainer.wish.custom_user_name,
  friends: state.friends.friends.all,
  users: state.users.all,
  step: state.multiStep.wish_edit_settings,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WishEditSettings));
