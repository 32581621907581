import React from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import RequestsList from "./components/RequestList";
import * as actions from "./actions";

class AccessRequests extends React.Component {
  componentDidMount() {
    this.props.actions.fetchAccessRequests(this.props.user);
  }

  render() {
    const { actions, response, user, intl } = this.props;
    return (
      <DocumentTitle
        title={intl.formatMessage({ id: "access_requests.title" })}
      >
        <div className="app-section">
          <RequestsList
            user={user}
            list={response}
            acceptRequest={(id) => actions.patchAccessRequest(user, id, true)}
            declineRequest={(id) => actions.patchAccessRequest(user, id, false)}
          />
        </div>
      </DocumentTitle>
    );
  }
}

AccessRequests.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    fetchAccessRequests: PropTypes.func,
    patchAccessRequest: PropTypes.func,
  }).isRequired,
  response: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      petitioner: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        gender: PropTypes.string,
        image: PropTypes.string,
      }),
      wish: PropTypes.shape({
        title: PropTypes.string,
        author: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
          gender: PropTypes.string,
          image: PropTypes.string,
        }),
        description: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ).isRequired,
        amount_needed: PropTypes.number,
      }),
    })
  ).isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  response: state.accessRequests.response,
  status: state.accessRequests.status,
  error: state.accessRequests.error,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AccessRequests));
