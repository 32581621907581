import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import FormattedHTMLMessage from "services/localization/flex";

class Privacy extends Component {
  render() {
    return (
      <DocumentTitle
        title={this.props.intl.formatMessage({ id: "privacy.title" })}
      >
        <section className="static-page">
          <div className="container container--narrow">
            <FormattedHTMLMessage id="privacy.content" />
          </div>
        </section>
      </DocumentTitle>
    );
  }
}

Privacy.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(Privacy);
