import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";

import * as actions from "../../../../actions";

class NoFriendsSuggestions extends React.Component {
  sentRequest(user) {
    for (let i = 0; i < this.props.friendshipsRequested.length; i++) {
      if (user.id === this.props.friendshipsRequested[i].friend) {
        return true;
      }
    }

    return false;
  }

  renderManageFriend(user) {
    let className = "button button--next";
    let id = "common.add";

    if (!this.props.user.loggedIn || user === null) {
      return null;
    }

    if (this.sentRequest(user)) {
      className = "button";
      id = "user.request_sent";
    }

    return (
      <button className={className} onClick={() => this.addFriend(user)}>
        <FormattedMessage id={id} />
      </button>
    );
  }

  addFriend(user) {
    this.props.actions
      .sendFriendshipRequest(this.props.user, user)
      .then(() => {});
  }

  render() {
    return (
      <li className="contacts__item">
        <Link
          to={`/user/${this.props.friend.id}`}
          className="contacts__item no-link"
        >
          <UserImage
            className="contacts__user-avatar"
            user={this.props.friend}
          />
          <span className="contacts__user-name">
            {this.props.friend.first_name}{" "}
            <strong>{this.props.friend.last_name}</strong>
          </span>
        </Link>
        {this.renderManageFriend(this.props.friend)}
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  friendshipsRequested: state.friends.friends.friendshipsRequested,
  user: state.user,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoFriendsSuggestions);
