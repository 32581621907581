export function userById(userId, users, messages) {
  const dummyUser = {
    first_name: messages.first_name,
    last_name: messages.last_name,
    image: "/img/placeholder-user-male.svg",
    gender: "M",
  };
  if (users[userId] === undefined) {
    return dummyUser;
  }
  return users[userId];
}
