import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";

import FormattedHTMLMessage from "../../services/localization/flex";

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "contact.title" })}
        >
          <section className="static-page">
            <div className="container container--narrow">
              <p>
                <FormattedHTMLMessage id="hp.contact.about" />
              </p>
              <h3>ElateMe s. r. o.</h3>
              <p>
                Rybná 716/24
                <br />
                Praha 1<br />
                110 00
                <br />
                <br />
                IČ: 06226426
                <br />
                <FormattedHTMLMessage id="hp.contact.person" />
              </p>
              <p>
                <FormattedHTMLMessage id="hp.contact.question" />
              </p>
            </div>
          </section>
        </DocumentTitle>
        <Footer lightBackground {...this.props} />
      </Fragment>
    );
  }
}

Contact.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(Contact);
