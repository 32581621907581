import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { routerReducer } from 'react-router-redux';
import userReducer from './data/user/reducer';
import usersReducer from './data/users/reducer';
import friendsReducer from './scenes/Friends/reducer';
import userViewReducer from './scenes/User/UserView/reducer';
import groupsReducer from './scenes/Friends/components/FriendGroups/reducer';
import colorsReducer from './scenes/Friends/components/ColorSelector/reducer';
import wishCreateReducer from './scenes/Wish/scenes/Create/reducer';
import wishDetailReducer from './scenes/Wish/scenes/Detail/reducer';
import wishContainerReducer from './components/WishControllers/reducer';
import donationReducer from './scenes/Wish/scenes/Detail/components/Donation/reducer';
import appComponentReducer from './components/App/reducer';
import modalReducer from './components/ConfirmModal/reducer';
import feedReducer from './scenes/Home/components/Wishes/components/Feed/reducer';
import settingsReducer from './scenes/Settings/reducer';
import notificationsReducer from './scenes/Notifications/reducer';
import commentsReducer from './components/CommentBox/reducer';
import myWishesPageReducer from './scenes/User/MyWishes/reducer';
import accessRequestsReducer from './scenes/AccessRequests/reducer';
import searchReducer from './scenes/Search/reducer';
import productReducer from './scenes/EshopProduct/reducer';
import gdpr from './components/GDPR/reducer';
import multiStepReducer from './components/Containers/MultiStep/reducer';

const friendsCombinedReducer = combineReducers({
  friends: friendsReducer,
  groups: groupsReducer,
  groupColors: colorsReducer,
});

export default combineReducers({
  app: appComponentReducer,
  user: userReducer,
  users: usersReducer,
  donation: donationReducer,
  modal: modalReducer,
  wishCreate: wishCreateReducer,
  wishDetail: wishDetailReducer,
  wishContainer: wishContainerReducer,
  multiStep: multiStepReducer,
  accessRequests: accessRequestsReducer,
  friends: friendsCombinedReducer,
  feed: feedReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  myWishes: myWishesPageReducer,
  userView: userViewReducer,
  comments: commentsReducer,
  intl: intlReducer,
  router: routerReducer,
  search: searchReducer,
  product: productReducer,
  gdpr,
});
