import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import Spinner from "components/Spinner";
import * as actions from "components/WishControllers/actions";
import * as wishCreateActions from "scenes/Wish/scenes/Create/actions";
import * as constants from "scenes/Wish/scenes/Create/constants";

import ProductListItem from "./components/ProductListItem";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: -1,
    };
    this.setItem = this.setItem.bind(this);
  }

  setItem(id) {
    this.setState({
      selectedItem: id,
    });
  }

  showSuggested() {
    if (this.props.suggested.length === 0) {
      return null;
      /*
      return (
        <div className="content">
          <FormattedMessage id="wish.create.noProductsFound" />
        </div>
      );
      */
    }

    return (
      <div className="content">
        <ul className="list list--large products-list">
          {this.props.suggested.map((item) => (
            <ProductListItem
              key={item.id.toString()}
              title={item.title}
              amountNeeded={item.price}
              image={item.image}
              productUrl={item.url}
              selected={item.id.toString() === this.state.selectedItem}
              setItem={this.setItem}
              surprise_wish={this.props.surprise_wish}
              description={item.description}
              custom_user_name={this.props.custom_user_name}
              creation_source={this.props.creation_source}
              currency={this.props.currency}
              eshop={item.eshop}
              guid={item.guid}
            />
          ))}
        </ul>
      </div>
    );
  }

  handleStatus() {
    switch (this.props.loadingStatus) {
      case constants.STATUS_DEFAULT:
        return null;
      case constants.STATUS_LOADING:
        return <Spinner />;
      case constants.STATUS_LOADED:
        return this.showSuggested();
      default:
        break;
    }
    return null;
  }

  render() {
    /* <div className="content divider">
      {this.handleStatus()}
    </div> */

    return <div>{this.handleStatus()}</div>;
  }
}

ProductList.propTypes = {
  loadingStatus: PropTypes.number.isRequired,
};

ProductList.defaultProps = {
  suggested: [],
};

const mapStateToProps = (state) => ({
  suggested: state.wishCreate.suggested,
  loadingStatus: state.wishCreate.loadingStatus,
  user: state.user,
  searchText: state.wishCreate.searchText,
  surprise_wish: state.wishContainer.wish.surprise_wish,
  eshop: state.wishContainer.wish.eshop,
  custom_user_name: state.wishContainer.wish.custom_user_name,
  creation_source: state.wishContainer.wish.creation_source,
  currency: state.settings.currency,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    wishCreateActions: bindActionCreators(wishCreateActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
