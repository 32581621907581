import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as friendsActions from "../../../../actions";
import * as groupActions from "../../../FriendGroups/actions";

class GroupDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.editGroup = this.editGroup.bind(this);
  }
  handleClickOutside(evt) {
    this.props.onClickOut(evt);
  }

  editGroup(group) {
    const groupsTmp = this.props.groups.slice();
    const id = this.props.friendToModify.id;
    let wasEdited = false;

    for (let i = 0; i < groupsTmp.length; i += 1) {
      const members = groupsTmp[i].members.slice();
      for (let j = 0; j < members.length; j += 1) {
        if (members[j] === id) {
          if (groupsTmp[i].id === group.id) {
            const gr = this.removeUserFromGroup(groupsTmp[i], id);
            groupsTmp[i].members = gr.slice();
            wasEdited = true;
            break;
          }
        }
      }
      if (wasEdited) {
        break;
      } else if (groupsTmp[i].id === group.id) {
        members.push(id);
        groupsTmp[i].members = members.slice();
        this.props.actions.patchGroup(groupsTmp[i], this.props.user);
        break;
      }
    }
    if (this.props.index !== -1) {
      const i = this.props.index;
      this.props.friendsActions.getFriendsById(
        groupsTmp[i].members,
        this.props.friends
      );
    }

    this.props.actions.receiveGroups(groupsTmp);
  }

  removeUserFromGroup(group, userId) {
    const old = group.members.slice();
    const members = [];
    for (let i = 0; i < old.length; i += 1) {
      if (old[i] !== userId) {
        members.push(old[i]);
      }
    }
    const toPatch = {
      id: group.id,
      members: members.slice(),
    };
    this.props.actions.patchGroup(toPatch, this.props.user);
    return members;
  }
  forAllGroups(item, i) {
    let act = false;
    const gr = this.props.activeGroups.slice();
    for (let j = 0; j < gr.length; j += 1) {
      if (gr[j].id === item.id) {
        act = true;
      }
    }
    const code = `#${item.color}`;
    let styleObj = {};
    if (act) {
      styleObj = { backgroundColor: code };
    }
    return (
      <li
        className={act ? "dropdown__menu-item active" : "dropdown__menu-item"}
        key={i}
      >
        <a href="##" onClick={() => this.editGroup(item)} style={styleObj}>
          <span
            className="friend-lists__badge"
            style={{ backgroundColor: code }}
          />
          {` ${item.name} `}
        </a>
      </li>
    );
  }
  render() {
    return (
      <ul className="dropdown__menu">
        {this.props.groups.map(this.forAllGroups, this)}
      </ul>
    );
  }
}

GroupDropdown.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string,
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      image: PropTypes.string,
    }),
  }).isRequired,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  actions: PropTypes.shape({
    getFriendGroups: PropTypes.func,
    patchGroup: PropTypes.func,
    setChangeMode: PropTypes.func,
    receiveGroups: PropTypes.func,
  }).isRequired,
  friendsActions: PropTypes.shape({
    getFriendsById: PropTypes.func,
  }).isRequired,
  friendToModify: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      name: PropTypes.name,
      members: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  activeGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onClickOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  groups: state.friends.groups.all,
  index: state.friends.groups.index,
  active: state.friends.groups.changeModeActive,
  friends: state.friends.friends.all,
  users: state.users.all,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupActions, dispatch),
    friendsActions: bindActionCreators(friendsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(GroupDropdown));
