import * as types from "./actionTypes";
import * as constants from "./constants";

export const initialState = {
  sent: false,
  apiError: false,
  step: 1,
  created: false,
  donationValue: constants.LOWER_BOUND, //value which user donate as donation
  BTValue: 0, //value in bit coins
  payingOption: 0,
  urlCardPayment: "",
  donators: null,
  flat_donation: false,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.POST_DONATION:
      return Object.assign({}, state, { sent: true });
    case types.POST_CARD_PAYMENT:
      return Object.assign({}, state, { urlCardPayment: payload.item });
    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true });
    case types.STEP_NEXT:
      return Object.assign({}, state, { step: state.step + 1 });
    case types.STEP_BACK:
      return Object.assign({}, state, { step: state.step - 1 });
    case types.SET_STEP:
      return Object.assign({}, state, { step: payload.item });
    case types.SET_DONATION_VALUE:
      return Object.assign({}, state, { donationValue: payload.item });
    case types.SET_BITCOIN_VALUE:
      return Object.assign({}, state, { BTValue: payload.item });
    case types.RESET_STEP:
      return Object.assign({}, state, { step: 1 });
    case types.RESET_DONATION_VALUE:
      return Object.assign({}, state, { donationValue: constants.LOWER_BOUND });
    case types.RESET_BITCOIN_VALUE:
      return Object.assign({}, state, { BTValue: 0 });
    case types.SET_PAYING_OPTION:
      return Object.assign({}, state, { payingOption: payload.item });
    case types.SET_DONATORS:
      return Object.assign({}, state, { donators: payload.item });
    case types.SET_FLAT_DONATION:
      return Object.assign({}, state, { flat_donation: payload.item });
    default:
      return state;
  }
};
