export default {
  "app.name": "Wowee",
  "common.no": "No",
  "common.yes": "Yes",
  "common.cancel": "Cancel",
  "common.close": "Close",
  "common.share": "Share",
  "common.confirm": "Confirm",
  "common.select": "Select",
  "common.next": "Next",
  "common.back": "Back",
  "common.save": "Save",
  "common.saved": "Saved",
  "common.loading": "Loading",
  "common.tryAgain": "Try again",
  "common.loadingError": "Damn, data was not loaded",
  "common.saving": "Saving ...",
  "common.accept": "Accept",
  "common.decline": "Decline",
  "common.error": "Error",
  "common.friends": "Friends",
  "common.users": "Users",
  "common.wishes": "Wishes",
  "gdpr.popup.title": "Personal data protection and usage",
  "gdpr.popup.description": "List of permissions",
  "gdpr.popup.decline": "Decline",
  "gdpr.popup.accept": "Accept",
  "gdpr.open": "Open GDPR details",
  "gdpr.web-analytics.title": "Tools for web analysis",
  "gdpr.web-analytics.description": "TODO",
  "gdpr.facebook-analytics.title": "TODO",
  "gdpr.facebook-analytics.description": "TODO",
  "gdpr.facebook-data-forwarding.title": "TODO",
  "gdpr.facebook-data-forwarding.description": "TODO",
  "gdpr.cookie-forwarding.title": "TODO",
  "gdpr.cookie-forwarding.description": "TODO",
  "common.submit": "Submit",
  "common.submiting": "Submitting",
  "common.email": "Mail",
  "common.or": "Or",
  "common.password": "Password",
  "common.password-again": "Password again",
  "common.copyClipboard": "Copied to clipboard",
  "common.more": "More",
  "common.less": "Less",

  "general.sign-up": "Sign up",
  "general.forgotten-password": "Forgotten password",
  "general.login": "Log In",
  "general.log_out": "Log Out",
  "general.login.facebook": "Log In with Facebook",
  "general.set-up-new-password": "Set your password",
  "general.set-up-new-password-for": "Set new password for",
  "general.error.title": "Not Found",
  "general.error.subtitle.1": "unfortunately we don't have this here ... ",
  "general.error.subtitle.2": " Do you really know what you're looking for?",
  "wish.product-search.hint": "Napište stručně, jak a komu chcete pomoci.", // 'Napište název produktu nebo vložte odkaz na eshop/web', // COVID-19

  "contact.title": "Contact",
  "thanks.title": "Thanks",
  "about.title": "About",
  "about.content": "TODO kopik",

  "hp.title": "Plníme <span>tvoje přání!</span>",
  "hp.subtitle":
    "Vytvoř sobě nebo kamarádovi sbírku na dárek. Tví přátelé se na něj složí.",
  "hp.fb.button": "",
  "hp.describer.title1": "Dostaň dárek, který chceš",
  "hp.describer.title2": "Žádné starosti s&nbsp;výběrem dárků",
  "hp.describer.info1":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta turpis massa, nec mollis turpis iaculis quis. Donec sollicitudin cursus risus in congue. Aliquam quis odio quis tortor facilisis interdum non at felis. Integer rutrum diam sit amet eleifend volutpat. Fusce ac lacus libero.",
  "hp.describer.info2":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta turpis massa, nec mollis turpis iaculis quis. Donec sollicitudin cursus risus in congue. Aliquam quis odio quis tortor facilisis interdum non at felis. Integer rutrum diam sit amet eleifend volutpat. Fusce ac lacus libero.",

  "hp.mobile.download": "Stáhni si Wowee",
  "hp.mobile.download.into": "do svého smartphonu",
  "hp.try": "Vyzkoušej Wowee",
  "hp.try.web": "ve svém prohlížeči",
  "hp.about": "About",
  "hp.partners": "Partners",
  "hp.conditions": "Conditions",
  "hp.thanks": "Thanks",
  "hp.contact": "Contact",
  "hp.loginButtonText": "<span>Přihlásit</span> přes <span>Facebook</span>",
  "hp.contact.about": "Wowee is run by:",
  "hp.contact.person": "Business contact: michal.manena@wowee.cz",
  "hp.contact.question": "Do you have question? Hit us on poradna@wowee.cz",

  "hp.header.title": "Come together with your friends for great gifts",
  "hp.header.subtitle-special":
    "Když máš dobré přátele, můžeš si přát cokoliv a dostaneš to. Naše aplikace spojuje rodinu, kamarády, známé, spolužáky i kolegy, kteří si chtějí navzájem plnit přání. Tak začni – je to rychlé a jednoduché.",
  "hp.header.subtitle":
    "If you have good friends, you can ask for anything and get it. Our app connects family, friends, acquaintances, classmates and colleagues who want to fulfill each other's wishes. So get started - it's quick and easy.",
  "hp.press.title": "Napsali o nás",
  "hp.header.link": "Create wish",
  "hp.section.h2.1": "3 steps",
  "hp.section.h2.2": "to",
  "hp.section.h2.3": "dream gift",
  "hp.bubble.1": "Create a wish for yourself or a loved one.",
  "hp.bubble.2": "Invite everyone you know to contribute too.",
  "hp.bubble.3": "Once the money is collected, you can celebrate.",
  "hp.what-it-is.title": "What is Wowee?",
  "hp.what-it-is.subtitle":
    "Wowee is an application that fills people's wishes. Small, big and the biggest. We've got a lot of useless gifts in our lives and we decided to change it. We would like to get what we really want. Wowee connects those who know what they want with those who want to treat them. ",
  "hp.what-it-is.link": "How it work's",
  "hp.cookies.message":
    "This site uses cookies to provide services. By using this site you agree with that.",
  "hp.cookies.button": "I agree",

  "mock.user.first_name": "Unknown user",
  "mock.user.last_name": "Last name",

  "menu.feed": "Wishes",
  "menu.feed.loading": "Loading content",
  "menu.birthdays": "Birthdays",
  "menu.mywishes": "My wishes",

  "menu.createWish": "Create wish",
  "menu.editWish": "Edit wish",
  "menu.friends": "Friends",
  "menu.notifications": "Notifications",
  "menu.loggedIn": "Logged in as",

  "comments.loadNext": "Load more comments",

  "notFound.title": "Page not found",
  "notifications.title": "Notifications",
  "notifications.loadMore": "Load more notifications",
  "notifications.allNotifications": "All notifications",
  "notifications.myWishExpired":
    "Dnes končí tvá sbírka <strong>{wishTitle}</strong>",
  "notifications.myWishCompleted": "Your wish was funded!",
  "notifications.myWishDonation":
    "<strong>{donator}</strong> donated to your <strong>{wishTitle}</strong>",
  "notifications.wishIDonatedCompleted.title":
    "<strong>{friend}'s</strong> wish <strong>{wishTitle}</strong> was raised",
  "notifications.wishIDonatedCompleted.text": "You donated to this wish",
  "notifications.myWishComment.title":
    "<strong>{author}</strong> commented on your wish <strong>{wishTitle}</strong>",
  "notifications.youDonate":
    "Přispěl jsi <strong>{amount} {currency}</strong> kamarádovi <strong>{influencer}</strong> na dárek <strong>{wishTitle}</strong>",
  "notifications.someoneGotDonation":
    "Kamarád ti přispěl <strong>{amount} {currency}</strong> na dárek <strong>{wishTitle}</strong>",
  "notifications.gotDonation":
    "{donator} ti přispěl{donator_nomina} <strong>{amount} {currency}</strong> na dárek <strong>{wishTitle}</strong>",
  "notifications.gotGift":
    "Tví kamarádi se ti složili na tajný dárek <strong>{wishTitle}</strong> částkou <strong>{amount} {currency}</strong>",
  "notifications.revealRequest.title":
    "Uživatel <strong>{influencer}</strong> tě žádá o zpřístupnění detailu tvého dárku <strong>{wishTitle}</strong>",
  "notifications.friendRequest.title":
    "User <strong>{author}</strong> sent you friend request",
  "notifications.friendRequest.accepted": "You accepted this friend request",
  "notifications.friendRequest.deny": "You denied this friend request",
  "notifications.friendGranted.accepted":
    "User <strong>{author}</strong> accepted your friend request",
  "notifications.friendGranted.deny":
    "User <strong>{author}</strong> denied your friend request",

  "popup.got-a-gift.heading": "You’ve got a gift!",
  "popup.got-a-gift.donators": "These friends made your wish come true:",
  "popup.got-a-gift.subheading":
    'You received from your friends {amount} {currency} for <span className="product-name">{title}</span>',
  "popup.got-a-gift.shareMessage": "I received {title} from you. Thanks",
  "popup.success-collection.heading": "Success colection",
  "popup.success-collection.subheading":
    'Collection for <span className="product-name">{title}</span> was successful',
  "popup.success-collection.donators": "These people made this wish come true:",
  "popup.success-collection.shareMessage":
    "Collection for {title} was successful",
  "popup.thanks.heading": "Thank you!",
  "popup.thanks.subheading":
    "You've donated {amount} {currency} to {name}. <br /> We are sure you made her happy!",
  "popup.thanks.share": "I've donated for {name}'s wish",

  "home.title": "Home",
  "home.welcome": "Welcome!",
  "home.how-to-create-wish":
    "Rychle a snadno. Přihlas se přes Facebook, vytvoř přání a vyber, kdo dárek dostane. Můžeš to být ty, člen rodiny, kamarád, spolužák, známý nebo kolega z práce. Urči cílovou částku a přání potvrď. Jakmile přání vytvoříš, nezapomeň ho sdílet na sociálních sítích!",

  "login.email.valid": "Email is valid",
  "login.email.invalid": "Invalid email",
  "login.email.placeholder": "Write down your email",
  "login.password.invalid": "Invalid password",
  "login.password.dont_match": "Passwords dont match",
  "login.password.match": "Passwords match",
  "login.password.valid": "Password is valid",
  "login.password.forget": "Did you forget password",
  "login.password.again": "Password again",
  "login.password.forgotten.reset_it": "Reset password!",
  "login.password.placeholder": "Write down your password",
  "login.password.placeholder_again": "Write down your password again",
  "login.error.credentials": "Wrong credentials (email, password or both)",
  "login.no_account.1": "You dont have account yet? ",
  "login.no_account.2": " Create one",
  "login.account.1": "You already have account? Great, you can ",
  "login.account.2": "login",

  "login.sign-up.about":
    "Wowee is connecting friends and all, who wants to make dreams of their closest come true. All you have to do is to register for free, create wish and share it with others, so they can help you with it.",
  "login.sign-up.title": "Great! Now just few things about you.",
  "login.sign-up.name": "Name",
  "login.sign-up.surname": "Surname",
  "login.sign-up.birthday": "Date of birth",
  "login.sign-up.gender": "Gender",
  "login.sign-up.gender.male": "Male",
  "login.sign-up.gender.female": "Female",

  "login.name": "Write down your name",
  "login.surname": "Write down your surname",
  "login.birthday": "Chose date of your birthday",

  "login.forgotten_password.placeholder": "Write down your email",
  "login.new_password.used_hash":
    "This link was already used and the password was successfully changed",
  "login.new_password.login": "Log in to your account",
  "login.new_password.error": "Invalid link for password change",
  "login.new_password.error.login": "Back to home page",
  "login.new_password.footer.1": "You know your password? Great, you can ",
  "login.new_password.footer.2": "log in",

  "login.registration.sent":
    "We sent you verification mail. You just have to click on it and registration is done.",
  "login.registration.sent.title": "Great, its almost done",
  "login.registration.error.title": "Error during registration",
  "login.registration.error.1": "This mail adress have not been activated yet.",
  "login.registration.error.2":
    "If you didnt receive mail with activation link, we can resend it by clicking on the button below.",
  "login.registration.button": "Send email again",

  "login.password.forgotten.title": "Password reset",
  "login.password.forgotten.info":
    "Fill in your mail adress. If we know it and there is registration under that mail, we will sent you there link to set new password",
  "login.password.forgotten.email_sent":
    "To mail which you wrote down, we were sent informations how to set new password.",

  "wish.title": "Wish title",
  "wish.view_in_eshop": "View in eshop",

  "wish.create.what": "What do you wish?",
  "wish.create.forSomeone": "What do you want to give?",
  "wish.create.noProductsFound": "No products found",
  "wish.create.whatForHim": "What do you want to give him?",
  "wish.create.whatForHer": "What do you want to give her?",
  "wish.create.productStartingPrice": "starting at {price} Kč",
  "wish.create.selectCustomWish": "Use <em>{text}</em> as a wish",
  "wish.create.created":
    "Wish was created. Please wait. You will be redirected.",
  "wish.create.giftDescription": "Gift description",
  "wish.create.giftSettings": "Gift settings",
  "wish.create.wishTitle": "Wish title",
  "wish.create.price": "Price",
  "wish.create.fromAndPrice":
    "from <strong>{shop}</strong> for <strong>{price} Kč</strong>",
  "wish.create.recipientTitle": "This wish is for",
  "wish.create.recipientTitleMe": "Me",
  "wish.create.visibility": "Visibility",
  "wish.create.deadline": "Deadline",
  "wish.create.deadline.info":
    "Deadline is just informative. Money transfer to your account will happen after you terminate your collection.",
  "wish.create.donations": "Donations",
  "wish.create.noDonations": "any value",
  "wish.create.noDeadline": "never",
  "wish.create.setDeadline": "Set a deadline for this wish",
  "wish.create.donatorsChoice":
    "Donators can choose how much they want to donate",
  "wish.create.choseOption": "Chose option:",
  "wish.create.fixedValue": "Fixed value of",
  "wish.create.dollars": "dollars",
  "wish.create.euros": "euros",
  "wish.create.crowns": "czech crowns",
  "wish.create.recipientMe": "This wish is for me",
  "wish.create.recipientMeDescription": "Ok, this gift will be for you.",
  "wish.create.recipientFriend": "This wish is for a friend",
  "wish.create.recipientOutside": "For friend outside of Wowee",
  "wish.create.writeNameOfFriend": "Write down your friends name: ",
  "wish.create.visibilitySelected": "Only selected friends",
  "wish.create.visibilityExpect": "All except selected",
  "wish.create.visibilityPublic": "Public",
  "wish.create.visibilityFriends": "Friends",
  "wish.create.visibilityMe": "Only me",
  "wish.create.visibility.edit": "Edit",
  "wish.create.visibilityCustomUsers": "Specific friends",
  "wish.create.visibilityPublicMessage":
    "This wish will be set to public, so anybody including strangers will be able to see it.",
  "wish.create.friendLists": "Available friend lists",
  "wish.create.descriptionPlaceholder":
    "Tell your friends why they should help you with this wish",
  "wish.create.forMe": "For me",
  "wish.create.for": "For",
  "wish.create.createForMe": "Create this wish <strong>for me</strong>",
  "wish.create.createForFriend":
    "Create this wish <strong> for {name}</strong>",
  "wish.create.errorNoTitle":
    "Before you submit the wish, you have to fill wish title.",
  "wish.edit.errorNoTitle":
    "Before you submit the wish changes, you have to fill wish title.",
  "wish.create.unlimitedValue": "unlimited",
  "wish.create.fixedValueInfo":
    "  (this amount is only indicative, it is not necessary to fill it)",
  "wish.create.whoIsIt": "Who is it?",
  "wish.create.friendsName": "Write down friends name",
  "wish.create.money_receiver.who": "Who will get collected money?",
  "wish.create.money_receiver.me": "Me",
  "wish.create.money_receiver.me.placeholder": "I will get the money",
  "wish.create.surprise_wish": "Who will get this gift?",

  "modal.error.heading": "We are sorry",
  "modal.error.title": "Try refresh page",
  "modal.loginError.title": "Change app permissions",
  "modal.user_logged.title": "Unfortunately you are logged in",
  "modal.user_logged.description":
    "To perform this action you have to be loged out",

  "wish.confirm.question": "Are you sure?",
  "wish.confirmDelete.title": "Delete wish",
  "wish.confirmDelete.question": "Are you sure you want to delete this wish?",
  "wish.confirmClose.title": "Close collection",
  "wish.confirmClose.question":
    "Are you sure you want to close collection for this wish?",
  "wish.missingAccountNumber.title": "Missing bank account",
  "wish.missingAccountNumber.description":
    "You have not filled your bank account. For a money transfers and collection closes is necesarry to fill up it. ",
  "wish.missingAccountNumber.doItNow": "Please do it now.",
  "wish.confirmRefund.title": "Refund",
  "wish.confirmRefund.question": "Are you sure you want to refund this wish?",
  "wish.confirmRefund.description":
    "All progress will be lost and collected money will be given back to donators.",
  "wish.close.succ.title": "Success!",
  "wish.close.succ.description": "Money will be transferred within 24 hours.",
  "wish.detail.collected.mine":
    "{count, plural, =0 {Nobody has donated yet.} one {<strong>{count} </strong>friend has collected <strong>{gathered} {currency}</strong>} other {<strong>{count} </strong>friends have collected <strong>{gathered} {currency}</strong>}}",
  "wish.detail.collected":
    "{count, plural, =0 {Nobody has donated, yet. Be first!} one {<strong>{count} </strong>friend has collected <strong>{gathered} {currency}</strong>} other {<strong>{count} </strong>friends have collected <strong>{gathered} {currency}</strong>}}",
  "wish.detail.remaining": "<strong>{days} days</strong> remaining",
  "wish.detail.completed": "Completed",
  "wish.detail.expired": "Expired",
  "wish.detail.title": "{name} wishes",
  "wish.detail.surpriseTitle": "{author} created wish for {recipient}",
  "wish.detail.askForAccess": "Ask {name} to reveal this wish",
  "wish.detail.accessRequested": "You requested access to this wish",
  "wish.detail.accessAccepted": "Request was accepted",
  "wish.detail.accessRejected": "Request was rejected",
  "wish.detail.mainTitle": "Wish detail",
  "wish.detail.unknown": "Unknown thing",
  "wish.detail.donateButton": "Donate",
  "wish.detail.donateButtonFlat": "Donate {value} {currency}",
  "wish.detail.donate-money-info.desktop":
    "Money is collected in Wowee accounts until the wish is terminated by its creator. The funds will then be transferred to his account. Wowee does not charge any hidden fees for carrying or storing money, or any other.",
  "wish.detail.donate-money-info.mobile":
    "Money is collected in Wowee accounts.",
  "wish.detail.comments": "TODO: Comments",
  "wish.detail.remainingMoney":
    "needs <strong>{money} {currency}</strong> in total",
  "wish.detail.remainingMoneyCollected":
    "needed <strong>{money} {currency}</strong> in total",
  "wish.detail.forRefund": "This wish has been marked for refundation.",
  "wish.detail.terminated": "This wish has been terminated.",
  "wish.detail.terminatedShort": "Closed",
  "wish.detail.deleted": "This wish has been deleted.",
  "wish.detail.noDescription": "No description.",
  "wish.detail.noDeadline": "never",
  "wish.detail.theseUsersDonated": "These users donated to this wish",
  "wish.detail.showAllDonators": "Show all donators",
  "wish.detail.userMoneyReceiver": "After wish termination money will get",
  "wish.detail.visibilityTitle": "Wish is visible",
  "wish.detail.visibility.forMe": "only for me",
  "wish.detail.visibility.public": "for everybody",
  "wish.detail.visibility.forFriends": "for all my friends",
  "wish.detail.visibility.chosenFriends": "for chosen friends",
  "wish.detail.allowed_users.title": "This users can see the wish",
  "wish.detail.product_url": "Show in e-shop",
  "wish.detail.donators.anonym": "Anonym donator",
  "wish.detail.donator": "{first_name} <strong>{last_name}</strong>",

  "wish.detail.share.title": "Your wish has been created!",
  "wish.detail.share.dismiss": "Dismiss",
  "wish.detail.share.copyUrl": "Copy",
  "wish.detail.share.copied": "Copied!",
  "wish.detail.share.facebook": "Share on Facebook",
  "wish.detail.share.twitter": "Share on Twitter",

  "feed.nofriends": "You seem to be so alone here",
  "feed.nofriends-sidebar": "Seems you have no friends here.",
  "feed.share": "Share Wowee on Facebook",
  "feed.type-comment": "Type your comment here...",
  "feed.makeSomeoneHappy":
    "What about making someone happy <br />by creating a secret gift for him/her?",
  "feed.letsDoIt": "Let's do it!",
  "feed.shareMessage":
    "I just started to use Wowee, but don'n have here any friends. Please join and we can donate our own gifts.",
  "feed.loadMoreComments": "Load more comments",
  "feed.loadLessComments": "Load less comments",

  "wish.dropdownTerminate.title": "Terminate this wish",
  "wish.dropdownTerminate.description":
    "<strong>This wish</strong> will be terminated",
  "wish.dropdownRefund.title": "Refund this wish",
  "wish.dropdownRefund.description":
    "<strong>This wish</strong> will be refunded",
  "wish.dropdownEdit.title": "Edit this wish",
  "wish.dropdownEdit.description": "<strong>This wish</strong> will be edited",
  "wish.dropdownDelete.title": "Delete this wish",
  "wish.dropdownDelete.description":
    "<strong>This wish</strong> will be deleted",
  "wish.dropdownCopy.title": "Copy",
  "wish.dropdownCopy.description": "Create as your wish or surprise",
  "wish.dropdownHideWish.title": "Hide this wish",
  "wish.dropdownHideWish.description":
    "<strong>This wish</strong> will be hidden from your feed",
  "wish.dropdownHideUser.title": "Hide this user",
  "wish.dropdownHideUser.description":
    "<strong>All {name}&rsquo;s wishes </strong> will be hidden from your feed",
  "wish.dropdownShowDonators.title": "Show donators",
  "wish.dropdownShowDonators.description":
    "Show users who donated to this wish",
  "wish.dropdownSetMoneyReceiver.title": "Set money receiver",
  "wish.dropdownSetMoneyReceiver.description":
    "Set user who will get the money from wish",
  "wish.dropdownSuccesfullyDeletedWish.description":
    "Wish was successfully deleted",
  "wish.dropdownShare.title": "Share",
  "wish.dropdownShare.description": "Share this wish.",

  "donate.title": "Donate",
  "donate.title.flat": "Donate {donation} {currency}",
  "donate.checkingPayment": "Checking payment:",
  "donate.addressIdQr": "Address id in qr:",
  "donate.addressId": "Address id:",
  "donate.bitcoins": "Your donation in BT coins:",
  "donate.howMuch": "How much would you like to donate to {name} for her wish?",
  "donate.choosePaymentMethod": "Please choose a payment method:",
  "donate.card": "Credit card",
  "donate.bitcoin": "Bitcoin",
  "donate.QRCode": "QR code",
  "donate.QRCodeMessage":
    "Scan this QR code with your phone to make the payment",
  "donate.thanks": "Thank you!",
  "donate.share": "I've donated {value} {currency} for {name}'s wish",
  "donate.donators": "{first_name} {last_name} donated {amount} {currency}",
  "donate.donators.title": "List of all donators",
  "donate.money_receiver": "Set money receiver",
  "donate.bank_account": "Bank account: ",
  "donate.variable_symbol:": "Variable symbol",

  "donate.cardRedirection": "Redirecting...",
  "donate.cardRedirectionWait":
    "Please, wait a little while until you be redirected to payment gateway",
  "donate.summary":
    "You donated <strong>$ {amount}</strong> to {name}. <br /> We are sure you made her happy!",

  "user.friendship_request.title": "This user sent you friendship request",

  "friends.all": "All",
  "friends.request": "Requests",
  "friends.newList": "New List",
  "friends.filterFriends": "Filter friends",
  "friends.createGift": "Create gift",
  "friends.allGifts": "All gifts",
  "friends.allGiftsWithName": "View all {name}&rsquo;s gifts",
  "friends.delete": "Delete friends list",
  "friends.delete.heading": "Delete friends list",
  "friends.delete.content":
    "Are you sure you want to delete list {name}? This action cannot be undone.",
  "friends.suggestions": "Available friends",
  "friends.noRequests": "You have no friendship requests",
  "friends.acceptRequest": "accept",
  "friends.declineRequest": "decline",
  "friends.noFriends.inviteNew":
    "These 5 users are new at Wowee, maybe you know somebody?",
  "friends.empty_list": "This list of friends is empty for now.",

  "upcomingBirthdays.title": "Upcoming birthdays",
  "seeAll.link": "All »",
  "upcomingBirthdays.title.link": "See all »",
  "myWishes.title": "My Wishes",
  "myWishes.label.active": "active",
  "myWishes.label.ended": "ended",
  "myWishes.label.deleted": "deleted",

  "settings.bankAccount.example": "ex: 1234567890/1234",
  "settings.title": "Settings",
  "settings.bankAccount.title": "Bank account number:",
  "settings.bankAccount.howTo":
    "To change your current bank account number, fill in the field with a new one and click Save.",
  "settings.loggedIn": "Logged in as",
  "settings.createdGifts": "Gifts created",
  "settings.createdSuprises": "Surprises created",
  "settings.finishedWishes": "Successfully finished",
  "settings.totalDonated": "Total donated ({currency})",
  "settings.selectLanguage": "Select language",
  "settings.language.english": "English",
  "settings.language.czech": "Czech",
  "settings.language.slovak": "Slovak",
  "settings.selectCurrency": "Select currency",
  "settings.currency.czk": "CZK",
  "settings.currency.usd": "USD",
  "settings.currency.eur": "EUR",
  "settings.country.label": "In which country i am",
  "settings.country.cz": "Czech Republic",
  "settings.country.svk": "Slovakia",
  "settings.delete_account": "Delete account",
  "settings.access_requests": "Requests for access to wishes",
  "settings.popup.invalidInput.title": "Error",
  "settings.popup.invalidInput.description":
    "This is not a valid bank account number or a bitcoin wallet adress.",
  "settings.confirmDeleteAccount.title": "Delete account",
  "settings.confirmDeleteAccount.description":
    "Do you really want to delete your account?",
  "settings.friendRemoved.title": "User {name} was removed from friends",
  "settings.friendRemoved.description":
    "You and {name} are no more friends in Wowee application",
  "settings.information.title": "Information",
  "settings.allowedUsers.title": "Chose friends, whose will see this wish",
  "settings.allowedUsers.close": "Chose friends",
  "settings.aboutWowee.title": "How it works?",
  "settings.selectRecipient.title": "Select wish recipient",
  "settings.userLogedOut.title": "User was loged out",
  "settings.userLogedOut.description.first":
    "In aplication appeared known error, but user was loged out from Wowee",
  "settings.userLogedOut.description.second":
    "To log in again, press Log in button",
  "settings.setNewPassword.title": "Password change",
  "settings.setNewPassword.currentPassword": "Current password",
  "settings.setNewPassword.newPassword": "New password",
  "settings.setNewPassword.newPasswordAgain": "New password again",
  "settings.setNewPassword.confirm": "Change password",
  "settings.setNewPassword.passwordChanged":
    "Password was successfully changed",
  "settings.setNewPassword.passwordError":
    "Wrong current password or new passwords are not same",
  "settings.changePicture": "Change profile picture",
  "settings.changePicture.title": "Changing profile picture",

  "access_requests.title": "Requests for access to wishes",
  "access_requests.user_asked": "{name} asked to reveal",
  "access_requests.accept": "Accept",
  "access_requests.accept.done": "Accepted",
  "access_requests.accept.error": "Accepting failed",
  "access_requests.decline": "Decline",
  "access_requests.decline.done": "Declined",
  "access_requests.decline.error": "Declining failed",

  "user.profile": "profile",
  "user.name": "{first_name} {last_name}",
  "user.noWishes": "The user has no wishes.",
  "user.add_user": "Add to friends",
  "user.remove_user": "Remove from friends",
  "user.request_sent": "Friendship request sent",
  "user.no_friend":
    "This user is not in your friend list. If you want to create gift for him, at first you have to send him friendship request and wait for his confirm.",

  "thanks.fit.title": "Faculty of Information Technology, CTU in Prague",
  "thanks.fit.subtitle":
    "Wowee was created predominantly at CTU FIT in Prague as a part of teaching and provided space for mutual meetings of the implementation team.",
  "thanks.chludil":
    "Jiří is a key teacher who has opened the door to realize not only Wowee but also other projects. Throughout the project he led the project manager Bc. Michal Manena and led the final works of the first wave of students that Wowee formed.",
  "thanks.valenta":
    "Michal, as head of the Software Engineering Department, formally backed the entire Wowee implementation at his department. Without him, Wowee would never have formed.",
  "thanks.paus":
    "Petr is the supervisor of bachelor theses that will be defended by students of the 2nd Wowee development in 2018.",
  "thanks.buchtela":
    "David is the supervisor of the bachelor theses that will be defended by students of the 2nd Wowee Wave in 2018.",
  "thanks.hoffman":
    "Honza is the author of the user interface and was the opponent of several bachelor theses.",
  "thanks.maca":
    "Jindra led a group of students within the web team who were responsible for the output quality of the application.",

  "thanks.oponents.title": "Opponents of theses",
  "thanks.oponents.subtitle":
    "The following instructors elaborated opposition assessments of students' qualifications, which were involved in the development.",
  "thanks.oponents.klan":
    "Associate Professor Klán opposed a bachelor thesis dealing with online payment methods.",
  "thanks.oponents.vitvar":
    "Associate Professor Vitvar was an opponent of the bachelor thesis dealing with the comparison of ReST and GraphQL.",
  "thanks.oponents.vynikarova":
    "Dr. Vynikarova was an opponent of the bachelor thesis dealing with the operation of the Wowee iOS application in a global environment.",
  "thanks.oponents.kucera":
    "Dr. Kučera was a strict opponent of the bachelor thesis dealing with GDPR in web services, the practical part of which focuses on Wowee.",
  "thanks.oponents.jeschke":
    "Lukáš was an opponent of two bachelor theses focused on backend.",
  "thanks.oponents.jirkovsky":
    "Engineer Jirkovský was an opponent of the bachelor thesis dealing with the quality of cross-platform applications.",
  "thanks.oponents.stepan":
    "The engineer Štěpán was the opponent of the bachelor thesis Android version Wowee.",
  "thanks.oponents.bliznicenko":
    "Engineer Blizničenko was an opponent of the bachelor thesis dealing with the Wowee backend.",

  "thanks.mobile-app.title": "Mobile version developers",
  "thanks.mobile-app.subtitle":
    "The foundation stone for the development of Wowee was laid between 2016 and 2017 by the following students, respectively. CTU FIT graduates within the Team Software Project 1 and 2 courses.",
  "thanks.bachelor-thesis": "Bachelor Thesis",
  "thanks.mobile-app.balatsko.1":
    "Maks was a team leader and co-author of API. In his ",
  "thanks.mobile-app.balatsko.2":
    " he designed and prototyped a service to recommend a suitable gift for friends.",
  "thanks.mobile-app.kuzmovych":
    "Yevhen was the main developer of the first version of the API he described in his ",
  "thanks.mobile-app.arkhipov":
    "Gleb is co-author of the first version of iOS application ElateMe, on which he wrote his ",
  "thanks.mobile-app.terokhin":
    "Yegor is the co-author of the first version of the Wowee iOS application on which he wrote his ",
  "thanks.mobile-app.solovev":
    "Georgii designed and implemented the first version of the Android Wowee application ",
  "thanks.mobile-app.laskov":
    "Boris participated in the creation of the Android client.",
  "thanks.mobile-app.liferov":
    "Ilia is the developer of the Android app, which he continued after Georgii Sovovev and immortalized his efforts in his ",
  "thanks.mobile-app.tu-do.1":
    "Tuan is an economically based FIT student who performs a search for the global operation of the app and the developer of the iOS app. In his bachelor thesis he devoted himself ",
  "thanks.mobile-app.tu-do.2": "running an iOS app in a global environment",
  "thanks.mobile-app.stanovcak":
    "Tomas continues on the development of iOS client in the academic year 2018/2019 in the course BI-SP1",
  "thanks.mobile-app.aron":
    "Lukáš continues to develop the Android client in the academic year 2018/2019 in the course BI-SP1",

  "thanks.web-app.title": "Web version developers",
  "thanks.web-app.subtitle":
    "Between years 2017 and 2018, the following students worked on the Wowee web version",
  "thanks.web-app.martinek.1":
    "Michal was a leading developer of the web version. He defended his bachelor thesis on the topic ",
  "thanks.web-app.martinek.2": "Comparison of ReST and GraphQL",
  "thanks.web-app.cerny":
    "Michal was one of the team leaders of the web version. He also worked on implementing the iOS version.",
  "thanks.web-app.mokos":
    "David was one of the team leaders in charge of web development and quality management.",
  "thanks.web-app.levinzon": "Roman was the developer of the web version.",
  "thanks.web-app.rovnak": "Ruda was the developer of the web version.",
  "thanks.web-app.hrachovina":
    "Lukáš byl a je vývojářem další verze API, na němž obhájil téma své ",
  "thanks.web-app.bergl": "Štěpán was the developer of the web version.",
  "thanks.web-app.lukasevic":
    "Luka was the developer of a web version specializing in online payment methods, which he did in his ",
  "thanks.web-app.grofek.1":
    "Tomas was an API tester who fully leveled his knowledge in his bachelor thesis ",
  "thanks.web-app.grofek.2": "QA in cross-platform applications",
  "thanks.web-app.sanda.1": "Michal was a web application tester. His ",
  "thanks.web-app.sanda.2": " then he contributed to addressing GDPR.",
  "thanks.web-app.vybiral": "Tomas was the developer of the web version.",
  "thanks.web-app.bocan": "Peter was a web version tester.",

  "thanks.partners.title": "Developers for the Partners section",
  "thanks.partners.subtitle":
    "Since February 2019, we have been developing tools that will allow e-shops to create gifts directly from their site. The implementation team consists of:",
  "thanks.partners.votocek":
    "Jirka is a seasoned professional who, on his own initiative, came up with the creation of an e-shop add-on.",
  "thanks.partners.venecek":
    "Jirka leads a team of students in the academic year 2018/2019 in the course BI-SP1. He is in charge of requirements analysis and team management.",
  "thanks.partners.safarik":
    "Honza is the architect of the currently developed section For Partners.",
  "thanks.partners.picka": "Honza co-creates the section For Partners.",
  "thanks.partners.fryc": "Dominik co-creates the section for partners.",

  "general.title": "General terms and conditions",
  "privacy.title": "Privacy policy",
  "privacy.content": "",
  "general.content": `<h2>OBCHODNÍ PODMÍNKY SPOLEČNOSTI ElateMe s. r. o.</h2>
            <p>
              Pečlivě si přečtěte tyto obchodní podmínky (dále jen „Obchodní podmínky“)
              předtím, než začnete využívat služby zprostředkované společností    <strong>ElateMe s.r.o.</strong>, IČ 06226426, se sídlem Rybná 716/24, Staré
              Město, 110 00 Praha 1, zapsané v obchodním rejstříku vedeném Městským
              soudem v Praze odd. C vložka 278456.
            </p>
            <p>
              Tyto Obchodní podmínky stanoví zásady provozu a užívání webových stránek
              ………………… (dále jen „Webové stránky“ nebo také „Portál“) pro Autory sbírek
              zveřejněných na Webových stránkách a jejich Uživatele a zásady užívání
              služeb poskytovaných společností <strong>ElateMe s.r.o. </strong>
              prostřednictvím Webových stránek (dále jen „Služby“).
            </p>
            <p>
              Vstupem, užíváním, prohlížením, přenosem nebo uložením Webových stránek,
              nebo kterékoli ze služeb společnosti <strong>ElateMe s.r.o.</strong>,
              především pak zasláním žádosti o zveřejnění Sbírky na Webových stránkách
              potvrzujete, že jste si tyto Obchodní podmínky přečetli a souhlasíte a
              zavazujete se tyto Obchodní podmínky dodržovat.
            </p>
            <p>
              Nedílnou součástí těchto Obchodních podmínek jsou Zásady ochrany osobních
              údajů, které jsou uveřejněny na …………………. Společnost    <strong>ElateMe s.r.o.</strong> si vyhrazuje právo dle svého vlastního
              uvážení změnit nebo nahradit kteroukoli z podmínek, nebo kdykoli změnit,
              pozastavit nebo ukončit Službu (zejména dostupnost jakékoli funkce nebo
              obsahu), uveřejněním těchto změn na Webových stránkách nebo v e-mailovém
              oznámení zaslaném Uživatelům.
            </p>
            <p>
              Společnost <strong>ElateMe s.r.o.</strong> může rovněž stanovit omezení
              určitých funkcí a služeb nebo omezit přístup ke kterékoli nebo veškerým
              Službám, a to bez předchozího oznámení.
            </p>
            <p>
              <h3>Definice základních pojmů</h3>
            </p>
            <p>
              <strong>ElateMe s.r.o.</strong>
              <strong>, Provozovatel</strong>
              – společnost <strong>ElateMe s.r.o.</strong>, IČ 06226426, se sídlem Rybná
              716/24, Staré Město, 110 00 Praha, zapsaná v obchodním rejstříku vedeném
              Městským soudem v Praze odd. C vložka 278456, provozující webové stránky na
              adrese …………………..
            </p>
            <p>
              <strong>Webové stránky, Portál</strong>
              – stránky provozované společnosti <strong>ElateMe s.r.o.</strong> umístěné
              na adrese …………………
            </p>
            <p>
              <strong>Sbírka</strong>
              – je návrh na dárek nebo finanční sbírku za účelem transparentně zajistit
              organizaci výběru finančních příspěvků. Výtěžek z této sbírky je určen pro
              Příjemce sbírky.
            </p>
            <p>
              <strong>Autor sbírky </strong>
              – fyzická osoba, která vytvoří Sbírku a zašle Provozovateli žádost o
              zveřejnění Sbírky.
            </p>
            <p>
              <strong>Relizovaná sbírka – </strong>
              jedná se o sbírku, jež byla ukončena Autorem sbírky. Finanční prostředky
              byly převedeny na účet Příjemce sbírky.
            </p>
            <p>
              <strong>Příjemce sbírky - </strong>
              uživatel, jež je vybrán Autorem sbírky, na jehož bankovní účet bude
              převeden výtěžek ze Sbírky.
            </p>
            <p>
              <strong>Refundovaná sbírka</strong>
              – je Sbírka, která byla zrušena Autorem sbírky. Vybrané prostředky budou
              vráceny na účty Donátorů.
            </p>
            <p>
              <strong>Uživatel</strong>
              – jakákoli fyzická osoba (Donátor, Autor sbírky, Příjemce sbírky,
              neregistrovaná osoba), která využívá služby poskytované společností    <strong>ElateMe s.r.o. </strong>na Webových stránkách.
            </p>
            <p>
              <strong>Donátor – </strong>
              osoba, která přispěla na Sbírku.
            </p>
            <p>
              <strong>Příspěvek – </strong>
              finanční prostředky přispěné Donátorem na Sbírku.
            </p>
            <p>
              <strong>Oprávnění uživatelé</strong>
            </p>
            <p>
              Služby jsou k dispozici pouze osobám ve věku minimálně 15 let. Vstupem,
              užíváním, prohlížením, přenosem nebo uložením Webových stránek či zasláním
              Sbírky a jakoukoli další aktivní účastí prohlašujete a zaručujete se, že
              fyzickou osobou starší 15 let, a že veškeré registrační údaje, které jsou
              Vámi předkládány, jsou přesné a pravdivé.
            </p>
            <p>
              Provozovatel může dle svého výlučného uvážení odmítnout nabízet Službu
              kterékoli fyzické nebo právnické osobě.
            </p>
            <p>
              <strong>Registrace</strong>
            </p>
            <p>
              Podmínkou užívání určitých Služeb ze strany Provozovatele je registrace
              Uživatele u Provozovatele prostřednictvím služby Facebook.
            </p>
            <p>
              Uživatelé jsou povinni poskytnout Provozovateli přesné a úplné registrační
              údaje a tyto údaje pravidelně aktualizovat. Uživatel nesmí použít účet
              jiného Uživatele. V případě, že dojde k neoprávněnému použití účtu
              Uživatele, je tento Uživatel povinen písemně oznámit Provozovateli takovéto
              neoprávněné použití účtu nebo jiné porušení bezpečnosti účtu, o kterém se
              dozví.
            </p>
            <p>
              Společnost <strong>ElateMe s.r.o.</strong> si vyhrazuje právo zamítnout
              registraci Uživatele nebo ji zrušit, dle svého výlučného uvážení.
            </p>
            <p>
              <h3>Autoři sbírek – získávání peněz od Donátorů</h3>
            </p>
            <p>
              ElateMe je online systém a platforma, v rámci které lze získat financování
              pro přání Příjemce sbírky a určené Autorovi či 3. osobě. Provozovatel
              povoluje Autorům sbírky, kteří zapíší své Sbírky na seznam, aby Příjemci
              sbírky získali finanční prostředky od jiných Uživatelů (Donátorů).
              Provozovatel je zprostředkovatelem vztahu mezi Autory sbírek a ostatními
              Uživateli. Provozovatel umožňuje Autorům sbírek prezentovat své Sbírky na
              Webových stránkách.
            </p>
            <p>
              Veškeré Příspěvky prochází skrze podrozvahové účty společnosti    <strong>ElateMe s.r.o., </strong>které jsou pouhým transitním místem.
              Finálním příjemcem je Příjemce sbírky. Uživatel je povinen poskytnout    <strong>ElateMe s.r.o.</strong> veškerou součinnost potřebnou k provedení
              identifikace podle zákona č. 253/2008 Sb., o některých opatřeních proti
              legalizaci výnosů z trestné činnosti a financování terorismu a prokázat
              vlastnictví bankovního účtu. Pokud <strong>ElateMe s.r.o.</strong> bude mít
              jakékoli důvodné pochybnosti o osobě skutečného majitele účtu, nebo
              podezření z využití bankovního účtu k financování terorismu nebo legalizaci
              výnosů z trestné činnosti, nebo odmítne-li se Autor sbírky podrobit
              identifikaci, neprovede <strong>ElateMe s.r.o. </strong>převod finančních
              prostředků.
            </p>
            <p>
              Nárok Příjemců sbírek na zaslání cílové částky je splatný uplynutím 5 dnů
              po uplynutí doby finanční sbírky (výběru peněžních částek Sbírek).
            </p>
            <p>
              Po ukončení finanční sbírky jsou peníze převedeny na účet Příjemce sbírky.
              Po celou dobu je též možné Sbírku zrušit a tím dojde k refundaci a vrácení
              peněz. <strong>ElateMe s.r.o. </strong>si vyhrazuje právo na účtování
              (nevyplacení, odečtení) manipulačního poplatku za refundaci peněz ve výši
              5% vracené částky.
            </p>
            <p>
              <strong>ElateMe s.r.o. </strong>
              je povinna vracet peněžní částky dle tohoto článku Smlouvy vždy výhradně na
              bankovní účet či platební kartu Donátora, z něhož byla platba Zákazníka
              přijata.
            </p>
            <p>
              Provozovatel ani Příjemce sbírky nejsou oprávněni jakkoliv nakládat s
              těmito prostředky do doby ukončení termínu Sbírky.
            </p>
            <p>
              Autoři sbírek zcela odpovídají za své Sbírky, zejména za poskytnutí
              přesných a nezavádějících informací a splnění veškerých závazků uvedených v
              zápisu jakéhokoli sbírky, který vytvoří. Provozovatel nedohlíží na kvalitu,
              plnění ani přesnost jakýchkoli Sbírek prezentovaných na Webových stránkách.
              Veškerá jednání s kterýmkoli Uživatelem nebo jakýmikoli třetími osobami
              nalezenými prostřednictvím Služby jsou vedeny výlučně mezi Autory sbírky a
              těmito Uživateli nebo třetími osobami.
            </p>
            <p>
              Provozovatel neodpovídá za žádnou škodu nebo ztrátu utrpěnou v důsledku
              interakcí mezi Uživatelem, třetími osobami a Autory sbírek, ani za aktivity
              Autorů sbírek, zejména za dodání zboží a služeb a jakékoli jiné podmínky,
              záruky nebo prohlášení související se seznamy sbírek na Webových stránkách
              Provozovatele.
            </p>
            <p>
              Provozovatel má právo kdykoliv Sbírku zrušit.
            </p>
            <p>
              <h3>Poplatky a platby společnosti <strong>ElateMe s.r.o.</strong></h3>
            </p>
            <p>
              Registrace a vedení účtu je zdarma. Některé další služby však mohou být
              zpoplatněny (např. vrácení peněz Zákazníkovi). Provozovatel si vyhrazuje
              právo dle potřeby a okolností tyto poplatky měnit nebo stanovit nové
              poplatky.
            </p>
            <p>
              <h3>Sbírka – požadavky na Sbírku</h3>
            </p>
            <p>
              Autor sbírky se zavazuje uvádět ke Sbírce zveřejněném na Webových stránkách
              a ke svým registračním údajům přesné a pravdivé informace, zejména je
              povinen uvádět a pravidelně aktualizovat své kontaktní údaje a číslo
              bankovního účtu, na který mají být poukázány finanční prostředky Příjemcům
              sbírky při úspěšném naplnění cílové částky Sbírky.
            </p>
            <p>
              Uživatelé se zavazují, že budou uvádět číslo bankovního účtu, jehož jsou
              majiteli a ke kterému má plné dispoziční právo.
            </p>
            <p>
              Autor sbírky může stanovit orientační cílovou částku Sbírky. Autor sbírky
              bere na vědomí, že tato cílová částka nesmí být v průběhu zveřejnění Sbírky
              z jeho strany měněna a zahrnuje veškeré náklady související se Sbírkou,
              bankovní poplatky, daň z přidané hodnoty ve výši dle platných právních
              předpisů ke dni zveřejnění Sbírky. Z těchto důvodů Provozovatel doporučuje,
              aby si Autor sbírky propočítal důkladně veškeré náklady za zasílání odměn a
              další náklady související s realizací Sbírky.
            </p>
            <p>
              Autor sbírky zveřejní popis Sbírky vždy v českém jazyce a dále, dle jeho
              uvážení v dalších jazycích.
            </p>
            <p>
              Autor sbírky není oprávněn dále měnit podmínky zveřejněné Sbírky, zejména
              výši cílové částky a předmět přání.
            </p>
            <p>
              Autorem sbírky zvolený název Sbírky ani popis Sbírky nesmí obsahovat návrh
              k prodeji zboží nebo služby, která není přímo předmětem Sbírky. Autor
              sbírky smí k prezentaci Sbírky připojit obrázky, fotografie, videa, hudbu,
              apod., v takovém případě se zavazuje neporušovat při prezentaci Sbírky
              žádná autorská práva, práva průmyslová, nepoužívat ochranné známky ve
              vlastnictví třetích osob bez jejich souhlasu a dodržovat zásady na ochranu
              práv duševního vlastnictví tak, jak je popsáno níže v Obchodních
              podmínkách. Autor sbírky není oprávněn při popisu Sbírky nebo odměny
              parazitovat na pověsti třetích osob, uvádět spojení Sbírky nebo odměn se
              zavedenými značkami, logy nebo ochrannými známkami třetích osob, pokud k
              tomuto nemá souhlas vlastníka značky, loga nebo ochranné známky.
            </p>
            <p>
              Autor sbírky se zavazuje nezveřejňovat v popisu Sbírky žádné nemravné,
              pohoršující, rasistické či obdobné informace či materiály. Provozovatel si
              vyhrazuje právo na zrušení Sbírky, který by jakékoli takové informace nebo
              materiály obsahoval.
            </p>
            <p>
              Autor sbírky bere na vědomí, že Zákazník má v průběhu zveřejnění Sbírky
              právo na odstoupení od smlouvy, a to až do doby uhrazení částky na účet
              vedený Provozovatelem.
            </p>
            <p>
              Autor sbírky se zavazuje informovat o jakékoli změně ve Sbírce ostatní
              Uživatele, a to zveřejněním informace do popisu příslušné Sbírky.
            </p>
            <p>
              Autor sbírky se zavazuje splnit veškeré závazky vůči Uživatelům, které
              uvedl v popisu Sbírky, v kterém došlo k naplnění cílové částky, zejména se
              zavazuje dodržet termíny realizace Sbírky.
            </p>
            <p>
              <h3>Prohlášení Provozovatele</h3>
            </p>
            <p>
              Provozovatel nenese odpovědnost za Autorem sbírky zveřejněný popis Sbírky.
              Provozovatel si vyhrazuje právo na odstranění či změnu popisu Sbírky nebo
              odměny, dozví-li se o tom, že byla zveřejněním Sbírky porušena jakákoli
              práva třetích osob týkající se zejména práva autorského. Provozovatel
              uvádí, že není platformou, která zprostředkovává příspěvky, finanční sbírky
              na plnění konkrétních přání.
            </p>
            <h3>Práva duševního vlastnictví společnosti	<strong>ElateMe s.r.o.</strong></h3>
            <p>
              Veškerá práva související s Webovými stránkami a poskytovanými Službami, a
              jakýchkoli a veškerých jiných technologiích a jakémkoli obsahu (definovaném
              níže), vytvořeném nebo odvozeném na základě výše uvedeného, jsou ve
              výlučném vlastnictví Provozovatele.
            </p>
            <p>
              Užíváním Webových stránek nebo Služeb Provozovatele souhlasí Uživatel s
              tím, že Služba obsahuje obsah zvláště poskytnutý společností    <strong>ElateMe s.r.o.</strong>, případně jejími partnery, a že tento obsah
              je ve výlučném vlastnictví společnosti <strong>ElateMe s.r.o.</strong>,
              případně jejích partnerů a je chráněn autorskými právy, ochrannými
              známkami, servisními značkami, patenty, obchodními tajemstvími nebo jinými
              zákonem chráněnými právy a právními předpisy.
            </p>
            <p>
              Obsah Služby zahrnuje zejména videa, audioklipy, písemné komentáře z fór,
              informace, data, texty, fotografie, software, skripty, grafiku a
              interaktivní funkce, jež jsou vytvořeny, poskytnuty nebo jinak zpřístupněny
              Provozovatelem, popř. jejími partnery prostřednictvím Webových stránek nebo
              Služby. Uživatel se zavazuje dodržovat veškerá oznámení, informace a
              omezení týkající se autorských práv, která jsou uvedena v jakémkoli obsahu
              přístupném prostřednictvím Webové stránky nebo Služby.
            </p>
            <p>
              Společnost <strong>ElateMe s.r.o.</strong> uděluje oprávněnému Uživateli
              Služby nebo Webových stránek nevýhradní, nesublicencovatelnou a
              nepřevoditelnou licenci k užívání, modifikaci a reprodukci Obsahu, výlučně
              pro účely jeho použití v souvislosti se Službou. Užívání, reprodukce,
              modifikace, distribuce nebo uložení jakéhokoli Obsahu pro jakýkoli jiný
              účel je výslovně zakázáno, pokud je bez předchozího písemného souhlasu
              společnosti <strong>ElateMe s.r.o.</strong> nebo držitele autorských práv,
              uvedeného v oznámení o autorských právech, jež je součástí Obsahu.
            </p>
            <p>
              Uživatel nesmí (přímo ani nepřímo) činit následující:
            </p>
            <ul>
              <li>prodávat, licencovat, půjčovat, pronajímat nebo jinak užívat nebo
                využívat jakýkoli Obsah pro komerční účely nebo jakýmkoli způsobem,
                který porušuje práva kterékoli třetí osoby;
              </li>
              <li>
                dešifrovat, dekompilovat, demontovat, znovu vytvářet nebo se jinak
                pokoušet získat jakýkoli zdrojový kód nebo hlavní myšlenky nebo
                algoritmy kterékoli části Služby;
              </li>
              <li>
                modifikovat, překládat nebo jinak vytvářet odvozená díla z
                kterékoli části Služby;
              </li>
              <li>
                kopírovat, půjčovat, pronajímat, distribuovat nebo jinak převádět
                jakákoli práva, která získá na základě těchto Obchodních podmínek.
              </li>
            </ul>
            <h3>Práva duševního vlastnictví Uživatelů</h3>
            <p>
              Služba může poskytnout Oprávněným uživatelům možnost přidávat, vytvářet,
              nahrávat (upload), předkládat, distribuovat, shromažďovat nebo uveřejňovat
              (dále rovněž „předkládat“) obsah, videa, audioklipy, písemné komentáře z
              fór, data, texty, fotografie, software, skripty, grafiku nebo jiné
              informace na Webové stránky (dále společně jen jako „Uživatelské
              příspěvky“).
            </p>
            <p>
              Předložením Uživatelských příspěvků na Webové stránky nebo jinak
              prostřednictvím Služby činí Uživatel následující:
            </p>
            <ul>
              <li>
                uveřejňuje daný Uživatelský příspěvek a souhlas, že může být
                veřejně identifikováni prostřednictvím emailové adresy, jakožto
                registračního údaje, v souvislosti s jakýmkoli takovým Uživatelským
                příspěvkem;
              </li>
              <li>
                uděluje Provozovateli celosvětovou, nevýhradní, trvalou,
                neodvolatelnou, bezplatnou, sublicencovatelnou a převoditelnou
                licenci k užívání, editování, modifikaci, reprodukci, distribuci,
                zpracování odvozených děl, propagaci, realizaci a jinému úplnému
                využívání Uživatelských příspěvků v souvislosti se Službou a
                obchodními aktivitami Provozovatele (a jeho právních nástupců),
                včetně propagace a redistribuce celých Webových stránek nebo jejich
                části (a odvozených děl z nich) nebo Služby v jakýchkoli mediálních
                formátech, prostřednictvím jakýchkoli médií (včetně webových
                stránek třetích osob);
              </li>
              <li>
                tímto uděluje každému Uživateli Webových stránek anebo Služby
                nevýhradní licenci k přístupu k Uživatelským příspěvkům
                prostřednictvím Webových stránek a Služby, a k užívání, editování,
                modifikaci, reprodukci, distribuci, zpracování odvozených děl,
                propagaci a realizaci těchto Uživatelských příspěvků výlučně pro
                osobní nekomerční účely;
              </li>
              <li>
                prohlašuje a zaručuje se, že (i) vlastní nebo jinak ovládá veškerá
                práva na veškerý obsah svých Uživatelských příspěvků, nebo že obsah
                těchto Uživatelských příspěvků je veřejně přístupný, (ii) že má
                pravomoc jednat jménem vlastníků jakýchkoli práv, vlastnických
                titulů nebo podílů na jakémkoli obsahu svých Uživatelských
                příspěvků, smí užívat tento obsah, a udělit výše uvedená licenční
                práva, (iii) že má povolení užívat jméno a podobu každé
                identifikovatelné fyzické osoby a užívat identifikační nebo osobní
                údaje této osoby, dle Obchodních podmínek; a že (iv) je oprávněn
                udělit všechna výše uvedená práva k Uživatelským příspěvkům
                Provozovateli a všem uživatelům Služby;
              </li>
              <li>
                zavazuje se, že užívání nebo jiné využívání Uživatelských příspěvků
                Provozovatelem a užívání nebo jiné využívání Webových stránek a
                Služby Uživateli, dle těchto Obchodních podmínek, nenaruší ani
                neporuší práva kterékoli třetí osoby, zejména jakákoli práva na
                ochranu soukromí, autorská práva, smluvní práva nebo jakákoli jiná
                práva duševního vlastnictví nebo zákonem chráněná práva;
              </li>
              <li>
                je srozuměn a souhlasí s tím, že Provozovatel má právo vymazat,
                editovat, modifikovat nebo překládat jakékoli materiály, obsah nebo
                informace, které Uživatel předloží; a že za veškeré informace, jež
                jsou uveřejněny nebo soukromě zaslány prostřednictvím Webových
                stránek, výlučně odpovídá osoba, od které tento obsah pochází, a že
                Provozovatel neodpovídá za jakékoli chyby nebo opomenutí v
                jakémkoli obsahu; dále že Provozovatel se nemůže zaručit za
                identitu jakýchkoli jiných Uživatelů, se kterými můžete přijít do
                kontaktu během užívání Služby;
              </li>
              <li>
                zavazuje se, že bude dodržovat veškeré platné místní, státní,
                národní i mezinárodní zákony a předpisy.
              </li>
            </ul>
            <p>
              Provozovatel neschvaluje ani žádným způsobem nekontroluje jakékoli
              Uživatelské příspěvky. Provozovatel se nemůže zaručit za hodnověrnost
              jakýchkoli údajů, které o sobě poskytnou Uživatelé.
            </p>
            <p>
              Uživatel potvrzuje, že jeho přístup k veškerým Uživatelským příspěvkům za
              použití Služby je na jeho vlastní riziko, přičemž nesete výlučnou
              odpovědnost za jakoukoli škodu nebo ztrátu, kterou tím způsobí kterékoli
              osobě. Provozovatel nezaručuje, že kterýkoli Uživatelský příspěvek bude
              uveřejněn na Webových stránkách nebo prostřednictvím Služby. Provozovatel
              není povinen monitorovat Uživatelské příspěvky.
            </p>
            <p>
              Provozovatel si vyhrazuje právo kdykoli blokovat, odstranit, editovat nebo
              modifikovat jakékoli Uživatelské příspěvky z Webových stránek nebo Služby,
              bez předchozího oznámení a zdůvodnění (zejména po obdržení reklamací nebo
              obvinění od třetích osob nebo příslušných orgánů týkajících se těchto
              Uživatelských příspěvků, nebo pokud má Provozovatel podezření, že Uživatel
              porušil Obchodní podmínky), nebo bez jakéhokoli důvodu.
            </p>
            <h3>Zakázané jednání</h3>
            <p>
              Podmínkou užívání Služby je, že se Uživatel zaváže, že nebude Službu užívat
              pro žádný účel, který tyto Obchodní podmínky zakazují. Služba (zejména
              jakýkoli Obsah nebo Uživatelský příspěvek) je poskytována pouze pro omezené
              osobní nekomerční užívání (pokud Obchodní podmínky nepovolují něco jiného).
            </p>
            <p>
              Uživatel nesmí provádět, bez omezení (přímo ani nepřímo), kteroukoli z
              následujících aktivit:
            </p>
            <p>
              a) přijmout jakékoli opatření nebo nahrávat, načítat, uveřejňovat,
              předkládat ani jinak distribuovat nebo umožnit distribuci jakéhokoli obsahu
              prostřednictvím Služby, zejména jakýchkoli Uživatelských příspěvků, které:
            </p>
            <ul>
              <li>
                jsou nesprávné, zavádějící, nepravdivé nebo nepřesné;
              </li>
              <li>
                jsou nezákonné, výhružné, obtěžující, hanlivé, klamné, podvodné,
                zasahující do soukromí jiných osob, protiprávní, obscénní, urážlivé
                nebo vulgární;
              </li>
              <li>
                porušují jakýkoli patent, ochrannou známku, obchodní tajemství,
                autorské právo, právo na publicitu nebo jiné právo kterékoli jiné
                fyzické nebo právnické osoby, nebo porušují jakýkoli zákon nebo
                smluvní povinnost;
              </li>
              <li>
                představují nevyžádaný nebo neschválený reklamní nebo propagační
                materiál nebo jakýkoli reklamní e-mail (junk mail), spam nebo
                řetězový dopis; nebo
              </li>
              <li>
                obsahují softwarové viry nebo jakékoli jiné počítačové kódy,
                soubory nebo programy, jejichž cílem je narušení, poškození,
                omezení nebo zasahování do řádného fungování jakéhokoli softwaru,
                hardwaru nebo telekomunikačního zařízení, nebo poškození nebo
                získání neoprávněného přístupu k jakémukoli systému, datům, heslu
                nebo jiným informacím společnosti <strong>ElateMe s.r.o.</strong>
                nebo kterékoli třetí osoby.
              </li>
            </ul>
            <p>
              b) přijmout jakékoli opatření, které představuje nebo může představovat
              (dle rozhodnutí na základě výlučného uvážení Provozovatele) nepřiměřené
              nebo neúměrně vysoké zatížení infrastruktury Provozovatele;
            </p>
            <p>
              c) zasahovat nebo se pokoušet zasahovat do řádného fungování Služby nebo
              jakýchkoli aktivit prováděných v rámci Služby;
            </p>
            <p>
              d) obcházet jakákoli opatření, která Provozovatel může použít za účelem
              prevence nebo omezení přístupu ke Službě (nebo jiným účtům, počítačovým
              systémům nebo sítím připojeným ke Službě);
            </p>
            <p>
              f) používat Mailist, Listserv, auto-responder v jakékoli formě, nebo spam v
              rámci Služby; ani
            </p>
            <p>
              g) používat manuál nebo automatizovaný software, zařízení nebo jiné postupy
              k nabourání se (crawl) nebo zamoření (spider) jakékoli stránky Webových
              stránek.
            </p>
            <p>
              Uživatel je plně zodpovědný za to, že převodem finančních prostředků v
              rámci Sbírky nedochází k legalizaci výnosů z jakékoliv trestné činnosti a
              prostředky ve Sbírce užité nejsou určeny k financování terorismu,
              teroristických činů nebo teroristických organizací.
            </p>
            <h3>Zdroje a webové stránky třetích osob</h3>
            <p>
              Služba umožňuje Uživatelům připojit se k jiným webovým stránkám nebo
              zdrojům na Internetu, jež nejsou pod kontrolou Provozovatele, přičemž tyto
              jiné webové stránky nebo zdroje mohou obsahovat odkazy na Webové stránky.
              Pokud Uživatel vstupuje na webové stránky třetích osob, činí tak na vlastní
              riziko. Užíváním Webových stránek a Služby Uživatel potvrzuje a souhlasí,
              že Provozovatel neodpovídá za zboží, služby, obsah, funkce, přesnost,
              zákonnost, vhodnost nebo jakýkoli jiný aspekt těchto webových stránek nebo
              zdrojů třetích osob (dále jen Zdroje třetích osob“). Zahrnutí jakéhokoli
              takového odkazu neznamená schválení ani přidružení Provozovatele k
              jakýmkoli Zdrojům třetích osob. Dále tímto Uživatel potvrzuje a souhlasí,
              že Provozovatel neodpovídá přímo ani nepřímo za jakoukoli škodu nebo ztrátu
              způsobenou nebo údajně způsobenou nebo související s užíváním jakýchkoli
              Zdrojů třetích osob Uživatelem.
            </p>
            <h3>Ukončení přístupu</h3>
            <p>
              Provozovatel může kdykoli ukončit přístup Uživatele ke Službě nebo jakékoli
              její části, s uvedením důvodu nebo bez uvedení důvodu, s oznámením nebo bez
              oznámení, s okamžitou účinností, což může vést k zabavení a zničení
              veškerých informací týkajících se Uživatelova účtu. Pokud si Uživatel přeje
              ukončit svůj účet, může tak učinit splněním pokynů uvedených na Webových
              stránkách. Jakékoli poplatky uhrazené na základě této Smlouvy jsou
              nevratné. Veškerá ustanovení Obchodních podmínek, jež ze své povahy mají
              přetrvat i po ukončení účtu, platí i po ukončení účtu, zejména ustanovení
              týkající se duševního vlastnictví a omezení odpovědnosti.
            </p>
            <h3>Omezení odpovědnosti Provozovatele</h3>
            <p>
              Uživatel tímto dále potvrzuje, že Provozovatel nemá žádnou kontrolu ani
              žádnou povinnost přijmout jakékoli opatření ohledně toho, (i) jací
              uživatelé získají přístup na Webové stránky, (ii) k jakému Obsahu nebo
              Uživatelským příspěvkům má Uživatel přístup prostřednictvím Webových
              stránek, (iii) jaké dopady na Uživatele může mít tento Obsah nebo
              Uživatelské příspěvky; (iv) jak má Uživatel vykládat nebo používat Obsah
              nebo Uživatelské příspěvky; nebo (v) jaká opatření může přijmout, bude-li
              Uživateli zpřístupněn Obsah nebo Uživatelské příspěvky.
            </p>
            <p>
              Provozovatel je zproštěn veškeré odpovědnosti za Uživatelovo získání nebo
              nezískání Obsahu nebo Uživatelských příspěvků prostřednictvím Služby.
              Webové stránky mohou obsahovat nebo odkazovat na webové stránky obsahující
              informace, které někteří lidé mohou považovat za urážlivé nebo nevhodné.
              Provozovatel nečiní žádné prohlášení ohledně jakéhokoli Obsahu nebo
              Uživatelských příspěvků obsažených na Webových stránkách nebo
              zpřístupněných prostřednictvím těchto Webových stránek. Provozovatel
              neodpovídá za přesnost, dodržování autorských práv, zákonnost ani
              decentnost materiálů obsažených nebo zpřístupněných prostřednictvím
              Webových stránek nebo Služby.
            </p>
            <p>
              Provozovatel nenese žádnou odpovědnost z hlediska zákona č. 253/2008 Sb., o
              některých opatřeních proti legalizaci výnosů z trestné činnosti a není
              povinen zjišťovat původ finančních prostředků použitých v rámci Sbírky.
              Odpovědnost za dodržování příslušných předpisů v souvislosti s legalizací
              výnosů z trestné činnosti nese výhradně Uživatel.
            </p>
            <p>
              <h3>Souhlas s elektronickým oznámením</h3>
            </p>
            <p>
              Používáním Služby vyjadřuje Uživatel svůj souhlas s přijímáním veškerých
              elektronických sdělení od společnosti <strong>ElateMe s.r.o.</strong>,
              zejména oznámení, smluv, právně požadovaných oznámení (disclosures) nebo
              jiných informací souvisejících se Službou (dále společně jen jako
              „Oznámení“). <strong>ElateMe s.r.o.</strong> může poskytnout tato
              elektronická Oznámení jejich uveřejněním na Webových stránkách. Pokud si
              Uživatel přeje zrušit svůj souhlas s elektronickým přijímáním Oznámení,
              musí tuto informaci zaslat prostřednictvím emailu Provozovateli.
            </p>
            <h3>Informace o mimosoudním řešení spotřebitelských sporů</h3>
            <p>
              V souladu s ustanovením § 14 a násl. zákona č. 634/1992 Sb. v platném
              znění, o ochraně spotřebitele, tímto Provozovatel informuje spotřebitele,
              že subjekty řešení mimosoudních sporů jsou:
            </p>
            <ul>
              <li>
                Obecným subjektem pro řešení spotřebitelských sporů je Česká
                obchodní inspekce, Štěpánská 567/15, 120 00 Praha 2,            <u><a href="http://www.coi.cz/">www.coi.cz</a></u>.
              </li>
              <li>
                Subjektem pro řešení sporů v oblasti finančních služeb je Finanční
                arbitr, Legerova 1581/69, 110 00 Praha 1,            <u><a href="http://www.finarbitr.cz/">www.finarbitr.cz</a></u>.
              </li>
              <li>
                Subjektem pro řešení sporů v oblasti elektronických komunikací a
                poštovních služeb je Český telekomunikační úřad, Sokolovská 219,
                190 00 Praha 9, <u><a href="http://www.ctu.cz/">www.ctu.cz</a></u>.
              </li>
            </ul>
            <p>
              <h3>Závěrečná ustanovení</h3>
            </p>
            <p>
              Tyto Obchodní podmínky představují úplnou dohodu mezi Uživateli (zejména
              Autory sbírek) a společností <strong>ElateMe s.r.o.</strong> týkající se
              Služeb a užívání Webových stránek, a nahrazují veškerá předchozí nebo
              současná sdělení a návrhy (ať již ústní, písemné nebo elektronické) mezi
              Uživatelem a společností <strong>ElateMe s.r.o.</strong>.
            </p>
            <p>
              Pokud je kterékoli ustanovení těchto Obchodních podmínek považováno za
              nevymahatelné nebo neplatné, toto ustanovení bude vyloučeno nebo omezeno na
              minimální nezbytný rozsah a nemá vliv na platnost nebo vymahatelnost
              ostatních oddělitelných ustanovení Obchodních podmínek.
            </p>
            <p>
              Provozovatel si vyhrazuje právo kdykoliv pozměnit nebo upravit tyto
              Obchodní podmínky, jakož i Zásady ochrany osobních údajů, a to bez udání
              důvodu. O případných změnách budou Uživatelé Webových stránek a Služeb,
              informováni prostřednictvím zveřejnění oznámení ne Webových stránkách anebo
              zasláním oznámení prostřednictvím emailu. Znění nových Obchodních podmínek
              či Zásad ochrany osobních údajů je účinné ode dne jejich uveřejnění na
              Webových stránkách Provozovatele, není-li Provozovatelem stanoveno jinak.
            </p>
            <p>
              Obchodní podmínky a veškeré právní vztahy z těchto Obchodních podmínek
              vyplývající se řídí českým právem.
            </p>`,
};
