import * as langConstants from "services/localization/constants";

import * as types from "./actionTypes";

export const initState = {
  bankNumber: {
    text: "",
    inputOk: false,
  },
  gifts: {
    count: 0,
    results: [],
  },
  loading: true,
  language: langConstants.CZECH,
  currency: "czk",
  apiError: false,
  apiSubmitting: false,
  success_update: true,
  informationsChanged: false,
};

export default (state = initState, payload) => {
  switch (payload.type) {
    case types.SET_INPUT_OK:
      return {
        ...state,
        bankNumber: {
          text: state.bankNumber.text,
          inputOk: payload.item,
        },
      };
    case types.SET_LANGUAGE:
      return { ...state, language: payload.item };
    case types.SET_CURRENCY:
      return { ...state, currency: payload.item };
    case types.SET_SUCCESS_UPDATE:
      return { ...state, success_update: payload.item };
    case types.SET_NEW_BANK_NR:
      return {
        ...state,
        bankNumber: {
          text: payload.item,
          inputOk: state.bankNumber.inputOk,
        },
      };
    case types.FETCH_USER:
      return {
        ...state,
        bankNumber: {
          text: "",
          inputOk: false,
        },
        apiSubmitting: false,
      };
    case types.API_ERROR:
      return { ...state, apiError: true, apiSubmitting: false };
    case types.FETCH_WISHES:
      return { ...state, gifts: payload.item };
    case types.DONE_LOADING:
      return { ...state, loading: false };
    case types.API_SUBMITTING:
      return { ...state, apiSubmitting: true };
    case types.INFORMATIONS_CHANGED:
      return { ...state, informationsChanged: payload.item };
    default:
      return state;
  }
};
