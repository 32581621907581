export const POST_DONATION = "donation/POST";
export const POST_CARD_PAYMENT = "donation/POST/cardPayment";
export const API_ERROR = "donation/API_ERROR";
export const CHANGE_DONATION = "donation/POST";
export const RESET_STEP = "donation/step/resetStep";
export const RESET_DONATION_VALUE = "donation/values/resetDonation";
export const RESET_BITCOIN_VALUE = "donation/values/resetBitcoins";
export const STEP_NEXT = "donation/step/next";
export const STEP_BACK = "donation/step/back";
export const SET_STEP = "donation/step/setStep";
export const SET_DONATION_VALUE = "donation/values/setDonationvalue";
export const SET_BITCOIN_VALUE = "donation/values/setBtValue";
export const SET_PAYING_OPTION = "donation/paying/option";
export const SET_DONATORS = "donation/GET";
export const SET_FLAT_DONATION = "donation/setFlatDonation";
