import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

import * as searchActions from "scenes/Search/actions";

import Helper from "./components/Helper";

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      helper: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.query === "") {
      return;
    }
    this.props.history.push(`/search/${this.state.query}`);
  }
  handleQueryChange(e) {
    this.setState({ query: e.target.value });
    if (e.target.value === "") {
      this.setState({ helper: false });
      return;
    }
    this.setState({ helper: true });
    this.props.searchActions.search(e.target.value, this.props.user);
  }
  handleInputFocus(e) {
    if (this.state.query === "") {
      return;
    }
    e.target.select();
    this.setState({ helper: true });
  }

  handleInputBlur(e) {
    this.setState({ helper: false });
  }

  render() {
    const { search } = this.props;

    return (
      <form
        onSubmit={(e) => this.handleSubmit(e)}
        className="search-box hidden-mobile"
      >
        <input
          type="text"
          value={this.state.email}
          onChange={(e) => this.handleQueryChange(e)}
          className="search-box__input"
          placeholder="Hledat"
          onFocus={(e) => this.handleInputFocus(e)}
          onBlur={(e) => this.handleInputBlur(e)}
        />
        <input type="submit" value="" className="search-box__submit" />

        {this.state.helper ? <Helper search={search} /> : null}
      </form>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      search: state.search,
      user: state.user,
    }),
    (dispatch) => ({
      searchActions: bindActionCreators(searchActions, dispatch),
    })
  )(SearchBox)
);
