import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";
import FormattedHTMLMessage from "services/localization/flex";

class ShoptetWidget extends Component {
  render() {
    return (
      <Fragment>
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "shoptetWidget.title" })}
        >
          <section className="static-page">
            <div className="container container--narrow">
              <center>
                <h2>
                  <FormattedHTMLMessage id="shoptetWidget.headline" />
                </h2>
                <a href="https://www.shoptet.cz/">
                  <img
                    src="/img/logoShoptet-300x225.png"
                    alt="shoptet"
                    width="200"
                  />
                </a>
              </center>
              <FormattedHTMLMessage id="shoptetWidget.shoptetOnly" />
              <FormattedHTMLMessage id="shoptetWidget.howItWorks" />
              <center>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/TnkOblHuCIc"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </center>
              <FormattedHTMLMessage id="shoptetWidget.content" />
              <center>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/w8i-3BAOMH0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </center>
              <FormattedHTMLMessage id="shoptetWidget.instalation" />
            </div>
          </section>
        </DocumentTitle>
        <Footer lightBackground {...this.props} />
      </Fragment>
    );
  }
}

ShoptetWidget.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(ShoptetWidget);
