/* eslint-disable no-trailing-spaces,semi */
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import Share from "components/FacebookLogin/share";

import * as friendsActions from "../../actions";
import NoFriendsSuggestions from "../FriendList/components/NoFriendsSuggestions";

import FriendSidebarComponent from "./FriendSidebarComponent";
import FriendWidgetComponent from "./FriendWidgetComponent";

class UpcomingBirthdaysComponent extends Share {
  componentDidMount() {
    if (!this.props.friends.length) {
      this.props.actions.getLastFiveUsers(this.props.user);
    }
  }

  computeBirthday(friendIndex) {
    const friend = Object.assign({}, this.props.users[friendIndex]);
    if (!friend) return null;
    const currentDate = new Date();
    const date = new Date(friend.date_of_birth);
    date.setFullYear(currentDate.getFullYear());
    if (date < currentDate) {
      date.setFullYear(date.getFullYear() + 1);
    }
    friend.birthday = date;
    return friend;
  }
  transformFriend() {
    const list = this.props.friends.map(this.computeBirthday, this);
    list.sort((a, b) => {
      if (a.birthday < b.birthday) {
        return -1;
      }
      if (a.birthday > b.birthday) {
        return 1;
      }
      return 0;
    });
    return list;
  }
  forAllFriends(friend, index) {
    if (this.props.widget || this.props.page) {
      return (
        <FriendWidgetComponent key={index} index={index} friend={friend} />
      );
    }
    return <FriendSidebarComponent key={index} index={index} friend={friend} />;
  }

  renderNoFriends() {
    return (
      <div>
        <p className="text-center p-md">
          <FormattedMessage id="feed.nofriends-sidebar" />
        </p>
        <p className="text-center p-md">
          <FormattedMessage id="friends.noFriends.inviteNew" />
        </p>
        <ul className="contacts">
          {this.props.lastFiveUsers.map(this.forAllSuggested)}
        </ul>
      </div>
    );
  }

  forAllSuggested(item, index) {
    return <NoFriendsSuggestions key={index} friend={item} />;
  }

  renderWidgetContent() {
    if (this.props.friends.length === 0) {
      return this.renderNoFriends();
    }
    return (
      <ul className="contacts">
        {this.transformFriend().slice(0, 5).map(this.forAllFriends, this)}
      </ul>
    );
  }

  renderWidget() {
    const friendsCount = this.props.friends.length;
    return (
      <section className="widget">
        {friendsCount > 0 ? (
          <h3 className="widget__heading">
            <Link className="row" to="/upcoming-birthdays">
              <span className="fill">
                <FormattedMessage id="upcomingBirthdays.title" />
              </span>
            </Link>
            <Link className="row" to="/friends">
              <span>
                <FormattedMessage id="seeAll.link" />
              </span>
            </Link>
          </h3>
        ) : null}
        <div className="widget__content">{this.renderWidgetContent()}</div>
      </section>
    );
  }

  renderSideBar() {
    return (
      <section>
        <h3 className="sidebar__heading">
          <FormattedMessage id="upcomingBirthdays.title" />
        </h3>
        {this.props.friends.length === 0 ? (
          this.renderNoFriends()
        ) : (
          <ul className="upcoming-side-list">
            {this.transformFriend().slice(0, 5).map(this.forAllFriends, this)}
          </ul>
        )}
      </section>
    );
  }

  renderPage() {
    return (
      <div className="content">
        {this.props.friends.length === 0 ? (
          this.renderNoFriends()
        ) : (
          <ul className="contacts">
            {this.transformFriend().map(this.forAllFriends, this)}
          </ul>
        )}
      </div>
    );
  }

  render() {
    if (this.props.widget) {
      return this.renderWidget();
    } else if (this.props.page) {
      return this.renderPage();
    }
    return this.renderSideBar();
  }
}

const mapStateToProps = (state) => ({
  users: state.users.all,
  user: state.user,
  friends: state.friends.friends.all,
  lastFiveUsers: state.friends.friends.lastFiveUsers,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(friendsActions, dispatch),
  };
}

UpcomingBirthdaysComponent.propTypes = {
  widget: PropTypes.bool,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      image: PropTypes.string,
      date_of_birth: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
  }).isRequired,
  page: PropTypes.bool,
};
UpcomingBirthdaysComponent.defaultProps = {
  widget: false,
  page: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UpcomingBirthdaysComponent));
