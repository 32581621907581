import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as modalActions from "components/ConfirmModal/actions";
import * as types from "components/ConfirmModal/actionTypes";
import FormattedHTMLMessage from "services/localization/flex";

import FacebookLoginLite from "../FacebookLoginLite";

import { RECAPTCHA_SITE_KEY } from "./constants";

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      email_status: 0,
      password_status: 0,
      password_again_status: 0,
      not_a_robot: process.env.REACT_APP_DISABLE_CAPTCHA ? true : false,
    };
  }

  componentDidMount() {
    if (this.props.user.loggedIn) {
      this.props.modalActions.open({
        title: "",
        type: types.YOU_ARE_LOGGED,
      });
    }
  }

  componentWillUnmount() {}

  validateEmail(email) {
    const re =
      /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });

    if (this.validateEmail(e.target.value)) {
      this.setState({
        email_status: 1,
      });
    } else {
      this.setState({
        email_status: 2,
      });
    }

    if (!e.target.value.length) {
      this.setState({
        email_status: 0,
      });
    }
  };

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });

    if (e.target.value.length >= 5) {
      this.setState({
        password_status: 1,
      });
    } else {
      this.setState({
        password_status: 2,
      });
    }

    if (!e.target.value.length) {
      this.setState({
        password_status: 0,
      });
    }
  };

  handleChangePasswordAgain = (e) => {
    if (e.target.value === this.state.password) {
      this.setState({
        password_again_status: 1,
      });
    } else {
      this.setState({
        password_again_status: 2,
      });
    }

    if (!e.target.value.length) {
      this.setState({
        password_again_status: 0,
      });
    }
  };

  getEmailMessage() {
    switch (this.state.email_status) {
      case 1:
        return (
          <p className="form__description form__description--success">
            <FormattedMessage id="login.email.valid" />
          </p>
        );
      case 2:
        return (
          <p className="form__description form__description--danger">
            <FormattedMessage id="login.email.invalid" />
          </p>
        );
      default:
        return <p></p>;
    }
  }

  getPasswordMessage() {
    switch (this.state.password_status) {
      case 1:
        return (
          <p className="form__description form__description--success">
            <FormattedMessage id="login.password.valid" />
          </p>
        );
      case 2:
        return (
          <p className="form__description form__description--danger">
            <FormattedMessage id="login.password.invalid" />
          </p>
        );
      default:
        return <p></p>;
    }
  }

  getPasswordAgainMessage() {
    switch (this.state.password_again_status) {
      case 1:
        return (
          <p className="form__description form__description--success">
            <FormattedMessage id="login.password.match" />
          </p>
        );
      case 2:
        return (
          <p className="form__description form__description--danger">
            <FormattedMessage id="login.password.dont_match" />
          </p>
        );
      default:
        return <p></p>;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.password_again_status === 1 &&
      this.state.password_status === 1 &&
      this.state.email_status === 1 &&
      this.state.not_a_robot
    ) {
      this.props.actions.createRegistrationHash(
        this.state.email,
        this.state.password
      );
    }
  };

  onChangeRecaptcha = () => {
    this.setState({
      not_a_robot: true,
    });
  };

  renderRegistrationSent() {
    return (
      <div className="success-note">
        <h1 className="hp-header__title">
          <FormattedMessage id="login.registration.sent.title" />
        </h1>
        <p>
          <FormattedMessage id="login.registration.sent" />
        </p>
        {/*
              <p>Pokud ti nic nedorazilo, klikni na tlačítko níže.</p>
              <Link to="#" className="button login-button">Odeslat znovu</Link>
              */}
      </div>
    );
  }

  sendRegistrationEmail = (e) => {
    e.preventDefault();
    if (this.state.email) {
      this.props.actions.resendRegistrationEmail(this.state.email);
    }
  };

  renderAlreadySent() {
    return (
      <div>
        <h4 className="popup__title">
          <FormattedHTMLMessage id="login.registration.resend" />
        </h4>
        <div className="text-center">
          <button
            type="button"
            className="button button--primary"
            onClick={this.sendRegistrationEmail}
          >
            <FormattedHTMLMessage id="login.registration.button" />
          </button>
        </div>
      </div>
    );
  }

  renderSignupForm() {
    return (
      <div className="login-form">
        <h1 className="login-form__title">
          <FormattedMessage id="general.sign-up" />
          ...
        </h1>
        <p>
          <FormattedMessage id="login.sign-up.about" />
        </p>

        <FacebookLoginLite {...this.props} />
        <h2 className="title-line">
          <span>
            <FormattedMessage id="common.or" />
          </span>
        </h2>
        <form className="form">
          <div className="form__row">
            <label className="form__label">
              <FormattedMessage id="common.email" />
            </label>
            <input
              type="email"
              value={this.state.email}
              placeholder={this.props.intl.formatMessage({
                id: "login.email.placeholder",
              })}
              className="form__field"
              onChange={this.handleChangeEmail}
            />
            {this.getEmailMessage()}
          </div>

          <div className="form__row">
            <label className="form__label">
              <FormattedMessage id="common.password" />
            </label>
            <input
              type="password"
              value={this.state.password}
              placeholder={this.props.intl.formatMessage({
                id: "login.password.placeholder",
              })}
              className="form__field"
              onChange={this.handleChangePassword}
            />
            {this.getPasswordMessage()}
          </div>

          <div className="form__row">
            <label className="form__label">
              <FormattedMessage id="login.password.again" />
            </label>
            <input
              type="password"
              placeholder={this.props.intl.formatMessage({
                id: "login.password.placeholder_again",
              })}
              className="form__field"
              onChange={this.handleChangePasswordAgain}
            />
            {this.getPasswordAgainMessage()}
          </div>
          {!process.env.REACT_APP_DISABLE_CAPTCHA ? (
            <div className="form__row">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={this.onChangeRecaptcha}
              />
            </div>
          ) : null}

          <div className="form__row">
            <input
              type="submit"
              value={this.props.intl.formatMessage({ id: "common.submit" })}
              className="button login-button"
              onClick={this.handleSubmit}
            />
          </div>
        </form>
        <div className="login-footer">
          <p>
            <FormattedMessage id="login.account.1" />
            <Link to={`/login`}>
              <FormattedMessage id="login.account.2" />
            </Link>
            .
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section className="login main">
        <div className="login-promo">
          <img src="/img/celebrating.png" className="login-promo__img" alt="" />
        </div>
        {this.props.user.registrationSent
          ? this.renderRegistrationSent()
          : this.props.user.alreadySent
          ? this.renderAlreadySent()
          : this.renderSignupForm()}
      </section>
    );
  }
}

SignUpForm.propTypes = {
  return_url: PropTypes.string,

  actions: PropTypes.shape({
    //    createCommentBox: PropTypes.func,
    //    removeCommentBox: PropTypes.func,
  }).isRequired,

  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SignUpForm));
