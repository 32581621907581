import * as friendTypes from "scenes/Friends/actionTypes";

import * as types from "./actionTypes";

const initialState = {
  all: {},
};

function arrayToObject(usersObj, friends) {
  for (let i = 0; i < friends.length; i += 1) {
    Object.assign(usersObj, usersObj, {
      [friends[i].id]: friends[i],
    });
  }
  return usersObj;
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case friendTypes.FETCH_FRIENDS:
      const usersObj = arrayToObject(state.all, payload.item);
      return Object.assign({}, state, {
        all: usersObj,
      });
    case types.ADD_USER:
      const users = Object.assign({}, state.all, {
        [payload.item.id]: payload.item,
      });
      return Object.assign({}, state, {
        all: users,
      });
    default:
      return state;
  }
};
