import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi } from "services/api/";

import * as types from "./actionTypes";

export const receiveColors = (item) => ({
  type: types.GET_COLORS,
  item,
});

export const changeMode = () => ({
  type: types.CHANGE_MODE,
  item: null,
});

export function getColors(user) {
  return (dispatch) => {
    authFetchApi("/account/friends/groups/colors/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(receiveColors(data));
      })
      .catch(() => {
        dispatch({ type: types.API_FAIL });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
