import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";
import * as actions from "components/WishControllers/actions";
import * as wishCreateActions from "scenes/Wish/scenes/Create/actions";
import FormattedHTMLMessage from "services/localization/flex";

import SearchIcon from "./SearchIcon";

class DoneeSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      searchText: "",
      selectedId: 0,
      highlightedId: 0,
    };
  }

  componentDidMount() {
    if (this.props.custom_user_name && this.props.custom_user_name.length) {
      this.setState({ selectedId: -1 });
    } else if (
      this.props.surprise_wish != undefined &&
      Object.keys(this.props.surprise_wish).length
    ) {
      this.setState({ selectedId: this.props.surprise_wish.recipient });
    } else {
      this.setState({ selectedId: 0 });
    }
  }

  handleSearchFocus = () => {
    this.setState(
      (prevState) => {
        const { users } = this.props;
        const filteredFriendIds = prevState.searchText
          ? Object.keys(users).filter((friendId) =>
              `${users[friendId].first_name} ${users[friendId].last_name}`.match(
                new RegExp(prevState.searchText, "i")
              )
            )
          : Object.keys(users);

        return {
          isFocused: true,
          highlightedId: filteredFriendIds[0],
        };
      },
      () => this.input.select()
    );
  };

  handleSearchBlur = (evt) => {
    setTimeout(() => {
      this.setState((prevState) => ({
        isFocused: false,
      }));
    }, 200);
  };

  handleSuggestionClick = (selectedId) => {
    const parsedId = parseInt(selectedId, 10);

    this.setState({
      selectedId,
      searchText:
        selectedId > 0
          ? `${this.props.users[selectedId].first_name} ${this.props.users[selectedId].last_name}`
          : selectedId === -1
          ? this.state.searchText
          : "",
    });
    this.props.actions.setSurpriseWish({
      ...(parsedId > 0 ? { recipient: parsedId } : {}),
    });
    this.props.actions.setRecipient(parsedId > 0 ? parsedId : null);
    this.props.actions.setCustomUserName(
      selectedId === -1 && this.state.searchText ? this.state.searchText : null
    );
    this.props.onConfirm();
  };

  handleSearchChange = (evt) => {
    const { users } = this.props;
    const { searchText } = this.state;
    const newValue = evt.target.value;
    const filteredFriendIds = searchText
      ? Object.keys(users).filter((friendId) =>
          `${users[friendId].first_name} ${users[friendId].last_name}`.match(
            new RegExp(newValue, "i")
          )
        )
      : Object.keys(users);

    this.setState((prevState) => ({
      searchText: newValue,
      highlightedId:
        newValue && filteredFriendIds.length ? filteredFriendIds[0] : 0,
    }));
  };

  handleKeyDown = (evt) => {
    const { users } = this.props;
    const { searchText } = this.state;
    const filteredFriendIds = searchText
      ? Object.keys(users).filter((friendId) =>
          `${users[friendId].first_name} ${users[friendId].last_name}`.match(
            new RegExp(this.state.searchText, "i")
          )
        )
      : Object.keys(users);
    const selectedIndex = filteredFriendIds.indexOf(this.state.highlightedId);
    const nextId =
      selectedIndex + 1 < filteredFriendIds.length
        ? filteredFriendIds[selectedIndex + 1]
        : searchText
        ? filteredFriendIds[0]
        : 0;
    const prevId =
      selectedIndex > 0
        ? filteredFriendIds[selectedIndex - 1]
        : searchText || selectedIndex < 0
        ? filteredFriendIds[filteredFriendIds.length - 1]
        : 0;

    switch (evt.keyCode) {
      case 38:
        this.setState({ highlightedId: prevId });
        evt.preventDefault();
        break;
      case 40:
        this.setState({ highlightedId: nextId });
        evt.preventDefault();
        break;
      case 13: {
        const parsedId = parseInt(this.state.highlightedId, 10);
        this.props.actions.setSurpriseWish({
          ...(parsedId > 0 ? { recipient: parsedId } : null),
        });
        this.setState((prevState) => ({
          selectedId: prevState.highlightedId,
          searchText: this.props.users[prevState.highlightedId]
            ? `${this.props.users[prevState.highlightedId].first_name} ${
                this.props.users[prevState.highlightedId].last_name
              }`
            : "",
          highlightedId: 0,
        }));
        this.input.blur();
        this.handleSearchBlur(evt);
        this.props.onConfirm();
        break;
      }
      default:
    }
  };

  getFirstNameGenitiv(user) {
    if (user.causes_first_name) {
      return user.causes_first_name.akuzativ;
    }
    return user.first_name;
  }

  getLastNameGenitiv(user) {
    if (user.causes_last_name) {
      return user.causes_last_name.akuzativ;
    }
    return user.last_name;
  }

  getPlaceHolder() {
    return this.props.intl.formatMessage({ id: "wish.create.forMe" });
  }

  render() {
    const { users } = this.props;
    const { highlightedId, searchText, isFocused } = this.state;
    let { selectedId } = this.state;
    const filteredFriendIds = searchText
      ? Object.keys(users).filter((friendId) =>
          `${users[friendId].first_name} ${users[friendId].last_name}`.match(
            new RegExp(searchText, "i")
          )
        )
      : Object.keys(users);

    if (selectedId > 0 && this.props.surprise_wish.recipient === undefined) {
      selectedId = 0;
    }

    if (this.props.custom_user_name && this.props.custom_user_name.length) {
      selectedId = -1;
    } else if (selectedId === -1) {
      selectedId = 0;
    }

    return (
      <div className={`wish-search__wrapper ${isFocused ? "focus" : ""}`}>
        <input
          type="text"
          className={`form__field wish-search__field wish-search__field--donee ${
            selectedId ? "wish-search__field--hidden" : ""
          }`}
          placeHolder={this.getPlaceHolder()}
          value={searchText}
          onChange={this.handleSearchChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleSearchFocus}
          onBlur={this.handleSearchBlur}
          ref={(input) => (this.input = input)}
        />
        <SearchIcon />
        {selectedId > 0 ? (
          <div className="wish-search__selection">
            <span>
              {this.props.intl.formatMessage({ id: "wish.create.for" })}
            </span>
            <UserImage
              className="wish-search__avatar wish-search__avatar--small"
              user={users[selectedId]}
            />

            <strong>{this.getFirstNameGenitiv(users[selectedId])}</strong>
          </div>
        ) : selectedId === 0 ? (
          <div className="wish-search__selection wish-search__selection--empty">
            {}
          </div>
        ) : (
          <div className="wish-search__selection">
            <span>
              {this.props.intl.formatMessage({ id: "wish.create.for" })}
            </span>
            <strong>
              {` ${this.props.custom_user_name}` || this.state.searchText}
            </strong>
          </div>
        )}

        {this.state.isFocused && (
          <ul className="wish-search__suggestions">
            {!searchText && (
              <li
                onClick={() => this.handleSuggestionClick(0)}
                className={`wish-search__suggestion wish-search__suggestion--no-image ${
                  highlightedId === 0 ? "wish-search__suggestion--selected" : ""
                }`}
              >
                <FormattedHTMLMessage id="wish.create.createForMe" />
              </li>
            )}
            {searchText && (
              <li
                onClick={() => this.handleSuggestionClick(-1)}
                className={`wish-search__suggestion wish-search__suggestion--no-image ${
                  highlightedId === -1
                    ? "wish-search__suggestion--selected"
                    : ""
                }`}
              >
                <FormattedHTMLMessage
                  id="wish.create.createForFriend"
                  values={{ name: searchText }}
                />
              </li>
            )}
            {filteredFriendIds.map((userId) => (
              <li
                key={userId}
                onClick={() => this.handleSuggestionClick(userId)}
                className={`wish-search__suggestion ${
                  highlightedId === userId
                    ? "wish-search__suggestion--selected"
                    : ""
                }`}
              >
                <UserImage
                  className="wish-search__avatar"
                  user={users[userId]}
                />
                {this.getFirstNameGenitiv(users[userId])}{" "}
                <strong>{this.getLastNameGenitiv(users[userId])}</strong>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

DoneeSelector.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users.all,
  surprise_wish: state.wishContainer.wish.surprise_wish,
  custom_user_name: state.wishContainer.wish.custom_user_name,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    wishCreateActions: bindActionCreators(wishCreateActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DoneeSelector));
