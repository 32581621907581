import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import * as userActions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";
import * as notificationsActions from "scenes/Notifications/actions";
import * as wishActions from "scenes/Wish/scenes/Create/actions";

import NotificationBadge from "./components/NotificationBadge";
import NotificationDropDown from "./components/NotificationDropDown";
import SearchBox from "./components/SearchBox";

class AuthorizedMenu extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleNotiButtonClick = this.handleNotiButtonClick.bind(this);
  }

  logout(e) {
    e.preventDefault();
    this.props.actions.logout(this.props.user.accessToken);
    this.props.history.push("/");
    window.location.reload();
  }

  handleClick() {
    this.props.wishActions.reset();
  }

  handleNotiButtonClick = (event) => {
    if (this.props.isDropdownOpen) {
      this.props.notificationsActions.setDropdown(false);
    } else {
      this.props.notificationsActions.setDropdown(true);
      this.props.notificationsActions.fetchNotificationsCount(0);
    }
    event.preventDefault();
  };

  render() {
    const adress = this.props.location.pathname;
    return (
      <header className="header">
        <div className="container header__container">
          <h1 className="header__logo">
            <Link className="logo" onClick={this.handleClick} to="/">
              <span>
                <FormattedMessage id="app.name" />
              </span>
            </Link>
          </h1>
          <ul className="header-menu">
            <li
              className={
                adress === "/create"
                  ? "header-menu__item active"
                  : "header-menu__item"
              }
            >
              <Link
                to="/create"
                className="header-menu__link"
                onClick={this.handleClick}
              >
                <FormattedMessage id="menu.createWish" />
              </Link>
            </li>
            <li className="header-menu__item header-menu__item--searchbox">
              <SearchBox />
            </li>

            {/*
            <li
              className={
                adress === '/' ?
                  'header-menu__item active'
                  : 'header-menu__item'
              }
            >
              <Link to="/" className="header-menu__link"><FormattedMessage id="menu.feed" /></Link>
            </li>
            {/*  mobile menu  */}
            <li
              className={
                adress === "/upcoming-birthdays"
                  ? "header-menu__item hidden-tablet hidden-desktop active"
                  : "header-menu__item hidden-tablet hidden-desktop"
              }
            >
              <Link to="/upcoming-birthdays" className="header-menu__link">
                <FormattedMessage id="menu.birthdays" />
              </Link>
            </li>

            <li
              className={
                adress === `/user/${this.props.user.id}`
                  ? "header-menu__item hidden-tablet hidden-desktop active"
                  : "header-menu__item hidden-tablet hidden-desktop"
              }
            >
              <Link
                to={`/user/${this.props.user.id}`}
                className="header-menu__link"
              >
                <FormattedMessage id="menu.mywishes" />
              </Link>
            </li>
          </ul>
          <div className="header-user">
            <Link to={`/user/${this.props.user.id}`}>
              <UserImage
                className="header-user__avatar"
                user={this.props.user}
              />
            </Link>
            <span className="header-user__name">
              <FormattedMessage id="menu.loggedIn" />
              <br />
              <strong>
                <Link to={`/user/${this.props.user.id}`}>
                  {this.props.user.first_name}
                </Link>
              </strong>
            </span>

            <ul className="header-user__menu">
              <li className="header-user__menu-item">
                <a
                  href="##"
                  onClick={this.handleNotiButtonClick}
                  className="dropdown__button"
                >
                  <i className="icon icon--notification" />
                  <NotificationBadge />
                </a>
                <NotificationDropDown
                  user={this.props.user}
                  openNoti={() =>
                    this.props.notificationsActions.setDropdown(true)
                  }
                  closeNoti={() =>
                    this.props.notificationsActions.setDropdown(false)
                  }
                  isOpen={this.props.isDropdownOpen}
                />
              </li>

              <li className="header-user__menu-item">
                <NavLink activeClassName="active" to="/friends">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2.6rem"
                    viewBox="0 0 80 80"
                  >
                    <path
                      fill="currentColor"
                      d="M54.24 52.03v-.255l-.085-.255a13.557 13.557 0 0 0-6.554-6.384 14.307 14.307 0 0 1 10.043-3.575c9.618 0 12.512 6.894 12.512 6.894v3.575zm3.4-14.725a6.179 6.179 0 0 1-6.3-6.3 6.179 6.179 0 0 1 6.3-6.3 6.179 6.179 0 0 1 6.3 6.3 6.231 6.231 0 0 1-6.292 6.304zM52.703 55.69H27.68v-3.575s2.894-6.894 12.512-6.894 12.512 6.894 12.512 6.894zM40.191 40.965a6.179 6.179 0 0 1-6.3-6.3 6.179 6.179 0 0 1 6.3-6.3 6.179 6.179 0 0 1 6.3 6.3 6.179 6.179 0 0 1-6.291 6.301zM26.24 51.52l-.085.255v.255H10.24v-3.575s2.894-6.894 12.512-6.894a14.307 14.307 0 0 1 10.043 3.575 13.2 13.2 0 0 0-6.555 6.384zm-3.49-14.214a6.179 6.179 0 0 1-6.3-6.3 6.179 6.179 0 0 1 6.3-6.3 6.179 6.179 0 0 1 6.3 6.3 6.179 6.179 0 0 1-6.298 6.303z"
                    />
                  </svg>
                </NavLink>
              </li>
              <li className="header-user__menu-item">
                <NavLink activeClassName="active" to="/settings">
                  <i className="icon icon--settings" />
                </NavLink>
              </li>
              {/* eslint-disable */}
              <li className="header-user__menu-item">
                <a onClick={this.logout}><i className="icon icon--logout"/></a>
              </li>
              {/* eslint-enable */}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

AuthorizedMenu.propTypes = {
  actions: PropTypes.shape({
    logout: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
    image: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  wishActions: PropTypes.shape({
    reset: PropTypes.func,
  }).isRequired,
  notificationsActions: PropTypes.shape({
    setDropdown: PropTypes.func,
    fetchNotificationsCount: PropTypes.func,
  }).isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    isDropdownOpen: state.notifications.isDropdownOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
    wishActions: bindActionCreators(wishActions, dispatch),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthorizedMenu)
);
