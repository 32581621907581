import React from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ErrorBox from "components/ErrorBox";
import InfiniteLoader from "components/react-infinite-loader";
import Spinner from "components/Spinner";
import WishList from "components/WishList";
import SettingsSidebar from "scenes/Settings/components/SettingsSidebar";
import CustomWishButton from "scenes/Wish/scenes/Create/components/Selection/components/CustomWishButton";
import ProductList from "scenes/Wish/scenes/Create/components/Selection/components/ProductList";

import * as actions from "./actions";

class MyWishes extends React.Component {
  componentDidMount() {
    this.props.actions.reset();
    this.props.actions.fetchWishesPage(
      this.props.user,
      1,
      this.props.wishState
    );
  }

  loadNewPage() {
    if (this.props.hasMore) {
      const page = this.props.lastDownloadedPage + 1;
      this.props.actions.fetchWishesPage(
        this.props.user,
        page,
        this.props.wishState
      );
      this.props.actions.setMyWishesLoading(true);
    }
  }

  renderPages() {
    if (this.props.wishes.length === 0 && this.props.lastDownloadedPage > 0) {
      const wish_filter = this.props.wish_filter || "default";
      const msg = `myWishes.no_wish_${wish_filter}`;
      return (
        <p>
          <FormattedMessage id={msg} />
        </p>
      );
    }
    return (
      <WishList
        user={this.props.user}
        list={this.props.wishes}
        friends={this.props.friends}
        feed
      />
    );
  }

  renderMyWishes() {
    if (this.props.isFetching) {
      return (
        <div className="main-section-feed">
          {this.renderPages()}
          <InfiniteLoader onVisited={() => this.loadNewPage()} />
          <Spinner />
        </div>
      );
    }
    return (
      <div className="main-section-feed">
        {this.renderPages()}
        <InfiniteLoader onVisited={() => this.loadNewPage()} />
      </div>
    );
  }

  render() {
    return (
      <DocumentTitle
        title={this.props.intl.formatMessage({ id: "myWishes.title" })}
      >
        <section className="app-section">
          <div className="settings wish-list container">
            <SettingsSidebar showAccessRequests />
            <div className="divider divider--vertical">
              <div className="row">
                <h2 className="divider__title">
                  <FormattedMessage id="myWishes.title" />
                </h2>
                {/*
                <WishesTabs />
                */}
              </div>
              <CustomWishButton />
              <ProductList />

              {this.props.error ? (
                <ErrorBox onClick={() => this.componentDidMount()} />
              ) : (
                this.renderMyWishes()
              )}
            </div>
          </div>
        </section>
      </DocumentTitle>
    );
  }
}

MyWishes.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    reset: PropTypes.func,
    fetchWishesPage: PropTypes.func,
    setMyWishesLoading: PropTypes.func,
  }).isRequired,
  wishes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      author: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        gender: PropTypes.string,
        image: PropTypes.string,
      }),
      description: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
        })
      ).isRequired,
      amount_needed: PropTypes.number,
    })
  ).isRequired,
  wish_filter: PropTypes.string,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  hasMore: PropTypes.bool.isRequired,
  lastDownloadedPage: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  wishState: PropTypes.string,
};

const mapStateToProps = (state) => ({
  wishes: state.myWishes.wishes,
  hasMore: state.myWishes.hasMore,
  lastDownloadedPage: state.myWishes.lastDownloadedPage,
  user: state.user,
  friends: state.friends.friends.all,
  isFetching: state.myWishes.loading,
  error: state.myWishes.apiError,
  wish_filter: state.myWishes.wish_filter,
  wishState: state.myWishes.wishState,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MyWishes));
