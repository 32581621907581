import * as types from "./actionTypes";

export const initialState = {
  feed_pages: [],
  drop_down_active: -1,
  loading: false,
  hasMore: true,
  lastDownloadedPage: 0,
  apiError: false,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_FEED_HAS_MORE:
      return Object.assign({}, state, {
        hasMore: payload.item,
      });
    case types.SET_LOADED_FEED_PAGE:
      return Object.assign({}, state, {
        lastDownloadedPage: payload.item,
      });
    case types.SET_FEED:
      const pages = state.feed_pages.slice();
      pages.push(payload.item);
      return Object.assign({}, state, {
        feed_pages: pages,
        loading: false,
      });
    case types.SET_LOADING:
      return Object.assign({}, state, {
        loading: payload.item,
      });
    case types.RESET:
      return initialState;
    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true });
    default:
      return state;
  }
};
