export default {
  "app.name": "Wowee",
  "common.create": "Vytvoriť",
  "common.no": "Nie",
  "common.yes": "Áno",
  "common.add": "Pridať",
  "common.cancel": "Zrušiť",
  "common.close": "Zavrieť",
  "common.share": "Zdieľať",
  "common.confirm": "Potvrdiť",
  "common.select": "Zvoliť",
  "common.next": "Ďalej",
  "common.back": "Späť",
  "common.save": "Uložiť",
  "common.saving": "Ukládám ...",
  "common.saved": "Uložené",
  "common.loading": "Načítanie",
  "common.accept": "Prijať",
  "common.decline": "Odmietnuť",
  "common.tryAgain": "Skúsiť znova",
  "common.selectAll": "Vybrať všetko",
  "common.deselectAll": "Odobrať všetko",
  "common.loadingError": "Ouha, dáta sa nepodarilo načítať",
  "common.submit": "Odoslať",
  "common.submitting": "Odosílám",
  "common.email": "Email",
  "common.or": "Alebo",
  "common.password": "Heslo",
  "common.password-again": "Heslo znovu",
  "common.copyClipboard": "Skopírované do schránky",
  "common.more": "Viac",
  "common.less": "Menej",
  "common.friends": "priatelia",
  "common.users": "Užívatelia",
  "common.wishes": "Zbierky",
  "wish.product-search.hint": "Napište stručně, jak a komu chcete pomoci.", // 'Napište název produktu nebo vložte odkaz na eshop/web', // COVID-19

  "gdpr.web-alalytics.title": "Nástroje na analýzu návštevnosti",
  "general.sign-up": "Zaregistruj sa",
  "general.forgotten-password": "Zabudnuté heslo",
  "general.setup-partners-password": "Nastavte si heslo",
  "general.login": "Prihlás sa",
  "general.log_out": "Odhlásiť",
  "general.login.facebook": "Prihlás sa cez Facebook",
  "general.set-up-new-password": "Nastav si nové heslo",
  "general.set-up-new-password-for": "Nastav si nové heslo pre",
  "general.error.title": "Nenájdené",
  "general.error.subtitle.1": "Bohužiaľ toto tu nemáme ... ",
  "general.error.subtitle.2": " Naozaj vieš čo hľadáš?",

  "general.share.title": "Wowee!",
  "general.share.description":
    "Keď máš dobrých priateľov, máš každý sen na dosah. Či už túžiš po čomkoľvek. Splň si svoje tajné priania vďaka Wowee!",
  "general.share.og-description":
    "Keď máš dobrých priateľov, je každý sen dosiahnuteľný. A pretože veríme v silu priateľstva, založili sme Wowee. Prepája kamarátov, ktorí si chcú navzájom plniť tajné priania. Tá veľká aj tá ešte väčšie. Tak začni a vytvor si to svoje.",
  "gdpr.web-alalytics.description": "TODO",
  "gdpr.facebook-analytics.title": "TODO",
  "gdpr.facebook-analytics.description": "TODO",
  "gdpr.facebook-data-forwarding.title": "TODO",
  "gdpr.facebook-data-forwarding.description": "TODO",
  "gdpr.cookie-forwarding.title": "TODO",
  "gdpr.cookie-forwarding.description": "TODO",
  "wish.view_in_eshop": "Zobraziť v eshope",
  "wish.notlogged.introtext":
    "S Wowee môžeš prispieť na darček svojmu kamarátovi! Toto želanie nemôžeš vidieť, pretože ho tvoj kamarát sprístupnil len niekoľkým používateľom. Svoju zbierku na darček vytvoríš po prihlásení.",
  "wish.restricted.introtext":
    "Toto želanie je neverejné a prístup k nemu majú len určití užívatelia. Aj cez to môžeš na darček prispieť alebo požiadať o sprístupnenie autora zbierky.",

  "contact.title": "Kontakt",
  "thanks.title": "Poďakovanie",
  "thanks.description":
    "Sú ľudia, bez ktorých by sa vývoj ElateMe nezaobišiel. Nižšie uvedení ľudia a inštitúcie mali zásadný zásluhu na tom, že môžeme robiť radosť ľuďom. Preto zaslúži veľké poďakovanie za celé Wowee. Ďakujeme!",
  "about.title": "O Wowee",
  "about.content":
    "<h2>Prevádzkovateľ</h2>\n" +
    "<p>Spoločnosť ElateMe s. r. o. Je startup založený v júli 2017 v Prahe. Od februára 2016 jej zakladatelia vytvárali webovú a mobilnú aplikáciu Wowee.cz. Tá poskytuje inovatívne riešenia ľudské potreby v situáciách, kedy nastane udalosť u ktorej sa očakáva príjem daru. Typicky narodeniny, Vianoce či svadby. Kombinuje funkcionalitu wish listu a crowdfunding. Používatelia môžu vytvárať svoje darčeky a finančne prispievať na darčeky svojich blízkych. \n" +
    "\n" +
    "Výrobky a služby:\n" +
    " Aplikácia ElateMe umožňuje transparentné prispievania ľubovoľných finančných čiastok na želanie blízkeho používateľa." +
    "Peniaze sú po celú dobu uložené na účtoch spoločnosti ElateMe. Po ukončení finančnej zbierky sú peniaze prevedené na účet obdarovávaného." +
    "Používatelia môžu vytvoriť vlastný prianie alebo tzv. Surprise, čo je založenie zbierky na želanie nejakého užívateľa bez jeho vedomia." +
    " Aplikácia umožňuje riešiť problém nevhodného darčeka a to ako pre obdarovávaného, ​​tak jeho blízkych, od ktorých sa dar očakáva. " +
    " ElateMe s. r. o. si nenárokuje žiadnu provízií z finančných transakcií." +
    "ElateMe s. r. o. bolo založené v júli 2017 v Prahe. Spoločnosť zakúpila autorské práva od študentov ČVUT, ktorí sa na aplikáciu podieľali.\n" +
    "Prvá myšlienka na vyvíjaný typ služby sa objavila v roku 2012. Vo februári 2016 sa vlastníci, dohodli na tvorbe vlastného projektu." +
    " Michal Maněna  prostredníctvom Ing. Jiřího Chludila z ČVUT vytvoril témy pre kurz Tímový softvérový projekt na ČVUT FIT," +
    "na ktorý sa prihlásilo 6 študentov z Ruska a Ukrajiny, ktoré začali vyvíjať mobilnú aplikáciu ElateMe pre Android a iOS. " +
    "Päť z nich sa následne rozhodlo pokračovať vo vývoji aplikácie v rámci ich bakalárskych prác. " +
    "V júni 2017 obhájili svoje práce ktorých výstupom sú klienti pre Android a iOS, API server a AdServer. " +
    "Webovú verziu začal tvoriť tím 12 študentov ďalšieho ročníka výučby. </p>" +
    "<h2>Čo je Wowee?</h2>" +
    "<p>Užívateľ po prihlásení prostredníctvom Facebooku vidí feed želania jeho kamarátov. " +
    "Prianie je definované jeho názvom, sumou a prípadným popisom, obrázkom a termínom, do ktorého je potrebné cieľovú sumu vybrať." +
    " Tvorca želanie vždy zvolí užívateľa alebo skupiny užívateľov, ktorí priania uvidia a môžu naň prispievať. " +
    "Na detaile je tiež umožnené písať komentáre k želanie. " +
    "Z množiny facebookových priateľov používateľa vidia v aplikácii užívateľ iba tých, ktorí už do aplikácie vstúpili,  " +
    "resp. dali Facebooku súhlas s opravením. Na stránke zoznamu priateľov užívateľ môže prechádzať priania jednotlivých priateľov. </p>" +
    "<p>Pri tvorbe želanie užívateľ najskôr napíše, na čo chce založiť zbierku. " +
    "Aplikácia mu podľa napísaného textu ponúka adekvátne produkty. " +
    "Užívateľ môže nejaký z nich vybrať alebo pokračovať bez udania konkrétneho produktu. P" +
    "okud zvolí z ponúkaných možností produktov, je mu zobrazená vnorená stránka s eshopom, na ktorom je možné produkt zakúpiť. " +
    "Následne užívateľ potvrdí požadovaný produkt a zvolí či je darček pre neho osobne alebo vytvára želanie pre niekoho zo svojich blízkych (surprise). " +
    "Ďalej zvolí okruh užívateľov, ktorí vytváranú zbierku uvidia a budú môcť prispieť. Výber donátorov sa líšia v type želanie - u surprise používateľ vyberá primárne z okruhu priateľov obdarovávaného a nie svojho. " +
    "Následne želanie potvrdením formulára zverejní.</p>",

  "hp.title":
    'Plníme <span class="hp-header__title--span">tajné priania!</span>',
  "hp.subtitle":
    "Keď máš dobrých priateľov, je každý sen dosiahnuteľný. A pretože veríme v silu priateľstva, založili sme Wowee. Prepája kamarátov, ktorí si chcú navzájom plniť tajné priania. Tá veľká aj tá ešte väčšie. Tak začni a vytvor si to svoje.",
  "hp.fb.button": "",
  "hp.describer.title1": "Už žiadne nechcené darčeky",
  "hp.describer.title2": "Veľká želanie sa plní po troškách",
  "hp.describer.info2":
    "Každá veľká cesta začína prvým krokom. A každé veľké želanie sa zase plní po troškách. Prihlás sa do\n" +
    "Wowee, napíš svoje priania a oslov členmi rodiny, kamarátov, kolegov a známych, aby tí s ním pomohli. každý\n" +
    "z nich môže prispieť ľubovoľnou čiastkou. Keď sa celková suma vyberie, pristane ti na účte práve včas,\n" +
    "nech si môžeš zaobstarať, po čom túžiš. Radosť tak budú mať všetci - tie, pretože dostaneš to, čo naozaj\n" +
    "chceš, aj tvoji blízki, pretože tí pomohli splniť sen.",
  "hp.describer.info1":
    "Poznáme to všetci. Občas dá skrátka fušku vymyslieť správny darček. A výsledok? Na polici sa hromadia\n" +
    "v dvoch radoch fľaše alkoholu, ktoré nikdy nevypiješ, a na stole zase knihy, ktoré nikdy neprečítaš.\n" +
    "Skoncujte s nechcenými darčekmi a vyber si, čo by ti urobilo najväčšiu radosť. Môže to byť večera\n" +
    "v michelinské reštaurácii, vstupenky na Stouny alebo Biebera, kávovar na parádny espresso, elektrická\n" +
    "gitara alebo jazykový pobyt v zahraničí. Proste čokoľvek.",
  "hp.mobile.download": "Stiahni si Wowee",
  "hp.mobile.download.into": "do svojho smartphonu",
  "hp.try": "Vyskúšaj Wowee",
  "hp.try.web": "vo svojom prehliadači",
  "hp.about": "O Wowee",
  "hp.partners": "Partneri",
  "hp.conditions": "Podmienky",
  "hp.thanks": "Poďakovanie",
  "hp.contact": "Kontakt",
  "hp.loginButtonText": "Prihlásiť cez Facebook",
  "hp.contact.about": "Wowee prevádzkuje spoločnosť:",
  "hp.contact.person": "Obchodný kontakt: michal.manena@wowee.cz",
  "hp.contact.question": "Máte otázku? Napíšte nám na poradna@wowee.cz",

  "hp.header.title": "Zlož sa s kamarátmi na skvelé darčeky",
  "hp.header.subtitle-special":
    "Když máš dobré přátele, můžeš si přát cokoliv a dostaneš to. Naše aplikace spojuje rodinu, kamarády, známé, spolužáky i kolegy, kteří si chtějí navzájem plnit přání. Tak začni – je to rychlé a jednoduché.",
  "hp.header.subtitle":
    "Keď máš dobrých priateľov, môžeš si priať čokoľvek a dostaneš to. Naša aplikácia spája rodinu, kamarátov, známych, spolužiakov aj kolegov, ktorí si chcú navzájom plniť priania. Tak začni - je to rýchle a jednoduché.",
  "hp.press.title": "Napísali o nás",
  "hp.header.link": "Vytvor priania",
  "hp.section.h2.1": "3 kroky",
  "hp.section.h2.2": "k",
  "hp.section.h2.3": "vysnívanému darčeku",
  "hp.bubble.1": "Vytvor želanie pre seba alebo niekoho blízkeho.",
  "hp.bubble.2": "Pozvi všetky, ktoré poznáš, nech taky prispejú.",
  "hp.bubble.3": "Akonáhle sa peniaze vyberú, môže sa sláviť.",
  "hp.what-it-is.title": "Čo je Wowee?",
  "hp.what-it-is.subtitle":
    "Wowee je aplikácia, ktorá plní ľuďom želania. Malá, veľká aj najväčší. Sami sme v živote dostali veľa nepoužiteľných darčekov a rozhodli sme sa to zmeniť. Radi by sme dostávali to, po čom naozaj túžime. Wowee spája tých, ktorí vedia, čo chcú, s tými, ktorí im to chcú dopriať.",
  "hp.what-it-is.link": "Ako to funguje?",
  "hp.cookies.message":
    "Tento web používa na poskytovanie služieb súbory cookie. Používaním tohto webu s tým súhlasíte.",
  "hp.cookies.button": "Rozumiem",

  "mock.user.first_name": "Neznamy užívateľ",
  "mock.user.last_name": "priezvisko",

  "menu.feed": "Prianie",
  "menu.feed.loading": "Načítanie obsahu",
  "menu.birthdays": "Narodeniny",
  "menu.mywishes": "Má želanie",
  "menu.createWish": "Vytvoriť želanie",
  "menu.editWish": "Editovať želanie",
  "menu.friends": "Priatelia",
  "menu.notifications": "Notifikácia",
  "menu.loggedIn": "Prihlásený ako",
  "profile.friend.share.title": "Darčeky {name_akuzativ}",
  "profile.friend.share.description": "Darčeky {name_akuzativ} ",
  "profile.my.share.title": "Moje dárky",
  "profile.my.share.description": "Moje darčeky",
  "home.how-it-works":
    "Bezchybne, keď máš okolo seba skvelých ľudí. A my vieme, že máš. Hromadné financovania je najľahšou cestou k parádnym darčekom. Kedysi pomohlo k zrodu Národného divadla. A pomáha dodnes. Trebárs keď sa doma skladáte na darček pre babičku, v škole pre spolužiaka a v práci pre kolegov. Stačí, keď každý prihodí aspoň trošku, aby sa celkovo vyzbieralo toľko, že je možné splniť veľká aj ešte väčší želania.",
  "home.how-to-create-wish":
    "Rýchlo a ľahko. Prihlás sa cez Facebook, vytvor priania a vyber, kto darček dostane. Môžeš to byť tie, člen rodiny, kamarát, spolužiak, známy alebo kolega z práce. Urči cieľovú sumu a priania potvrď. Akonáhle želanie vytvoríš, nezabudni ho zdieľať na sociálnych sieťach!",
  "home.cashback":
    "Dobu, ktorú potrebuješ na vybranie cieľovej sumy, určuješ ty. Určiť môžeš aj hodnotu príspevku. Máš tak v rukách všetko, čo potrebuješ, aby sa podarilo vybrať cieľovú sumu. Keď to nevyjde, nedá sa nič robiť.",
  "home.how-it-works.title": "Ako to funguje?",
  "home.how-to-create-wish.title": "Ako založiť prianie?",
  "home.cashback.description":
    "Peniaze si samozrejme nenecháme - môžeš si za ne vybrať a kúpiť niečo iné, alebo ich využiť na iné želanie.",
  "home.cashback.title": "Čo keď sa peniaze nevyberú?",
  "comments.loadNext": "Načítať viac komentárov",

  "general.og.wish.non-restricted.author-open-amount":
    "{author_nominativ} vytvoril na Wowee zbierku na {product} za {amount} Sk. Schádza už len {needed} Sk. Prispej na ňu taky a pomôž so splnením sna!", // WL1
  "general.og.wish.non-restricted.author-open-noamount":
    "{author_nominativ} vytvoril na Wowee zbierku na {product}. Prispej na ňu taky a pomôž so splnením sna!", // WL1
  "general.og.wish.non-restricted.author-closed-amount":
    "Zbierka na darček pre {author_akuzativ} na Wowee práve skončila! Vybralo sa {gathered} Sk od {donators} priateľov. Vďaka všetkým!", // WL2
  "general.og.wish.non-restricted.author-closed-noamount":
    "Zbierka na darček pre {author_akuzativ} na Wowee práve skončila! Vybralo sa {gathered} Sk od {donators} priateľov. Vďaka všetkým!", // WL2
  "general.og.wish.non-restricted.friend-open-amount":
    "{friend_nominativ} túžia po {product}. Pomôž nám toto prianie splniť. Na Wowee nám zostáva už len {needed} Sk.", // WL3
  "general.og.wish.non-restricted.friend-open-noamount":
    "{friend_nominativ} túžia po {product}. Pomôž nám toto prianie splniť.", // WL3
  "general.og.wish.non-restricted.friend-closed-amount":
    "Práve sme na Wowee vybrali {gathered} Sk na {product} pre {endower_akuzativ}! Vďaka všetkým!", // WL4
  "general.og.wish.non-restricted.friend-closed-noamount":
    "Práve sme na Wowee vybrali {gathered} Sk na {product} pre {endower_akuzativ}! Vďaka všetkým!", // WL4
  "general.og.wish.restricted.author-open-amount":
    "{author_nominativ} vytvoril na Wowee zbierku na svoje želanie. Prispej taky a pomôž mu ho splniť!", // WL5
  "general.og.wish.restricted.author-open-noamount":
    "{author_nominativ} vytvoril na Wowee zbierku na svoje želanie. Prispej taky a pomôž mu ho splniť!", // WL5
  "general.og.wish.restricted.author-closed-amount":
    "{author_nominativ} práve na Wowee úspešne ukončil zbierku na svoje tajné želanie! Vďaka všetkým!", // WL6
  "general.og.wish.restricted.author-closed-noamount":
    "{author_nominativ} práve na Wowee úspešne ukončil zbierku na svoje tajné želanie! Vďaka všetkým!", // WL6
  "general.og.wish.restricted.friend-open-amount":
    "{author_nominativ} vytvoril na Wowee zbierku na darček pre {endower_akuzativ}. Prispej taky!", // WL7
  "general.og.wish.restricted.friend-open-noamount":
    "{author_nominativ} vytvoril na Wowee zbierku na darček pre {endower_akuzativ}. Prispej taky!", // WL7
  "general.og.wish.restricted.friend-closed-amount":
    "{author_nominativ} práve na Wowee úspešne ukončil zbierku na darček pre {endower_akuzativ}. Vďaka všetkým!", // WL8
  "general.og.wish.restricted.friend-closed-noamount":
    "{author_nominativ} práve na Wowee úspešne ukončil zbierku na darček pre {endower_akuzativ}. Vďaka všetkým!", // WL8

  "general.og.my-wish.non-restricted.author-open-amount":
    "Vytvoril som na Wowee zbierku na {product} za {amount} Sk. Chýba mi už len {needed} Sk. Prispej mi taky a pomôž mi splniť môj sen!", // WL9
  "general.og.my-wish.non-restricted.author-open-noamount":
    "Vytvoril som na Wowee zbierku na {product}. Prispej mi taky a pomôž mi splniť môj sen!", // WL9
  "general.og.my-wish.non-restricted.author-closed-amount":
    "Je to paráda! Priatelia sa mi na Wowee zložili na {product} a pomohli mi splniť sen. Vďaka všetkým!", // WL10
  "general.og.my-wish.non-restricted.author-closed-noamount":
    "Je to paráda! Priatelia sa mi na Wowee zložili na {product} a pomohli mi splniť sen. Vďaka všetkým!", // WL10
  "general.og.my-wish.non-restricted.friend-open-amount":
    "{endower_nominativ} si moc praje {product}. Preto som pre neho vytvoril zbierku na Wowee, chýba už len {needed} Sk. Prispej taky! ", // WL11
  "general.og.my-wish.non-restricted.friend-open-noamount":
    "{endower_nominativ} si moc praje {product}. Preto som pre neho vytvoril zbierku na Wowee. Prispej taky! ", // WL11
  "general.og.my-wish.non-restricted.friend-closed-amount":
    "Paráda! Vďaka Wowee sme vybrali {amount_gathered} Sk na {product} pre {endower_akuzativ}! ", // WL12
  "general.og.my-wish.non-restricted.friend-closed-noamount":
    "Paráda! Vďaka Wowee sme vybrali {amount_gathered} Sk na {product} pre {endower_akuzativ}!", // WL12
  "general.og.my-wish.restricted.author-open-amount":
    "Vytvoril som na Wowee zbierku na tajný darček. Prispej mi a pomôž mi splniť sen!", // WL13
  "general.og.my-wish.restricted.author-open-noamount":
    "Vytvoril som na Wowee zbierku na tajný darček. Prispej mi a pomôž mi splniť sen!", // WL13
  "general.og.my-wish.restricted.author-closed-amount":
    "Paráda! Vďaka Wowee a priateľom som vybral peniaze na svoj tajný darček!", // WL14
  "general.og.my-wish.restricted.author-closed-noamount":
    "Paráda! Vďaka Wowee a priateľom som vybral peniaze na svoj tajný darček!", // WL14
  "general.og.my-wish.restricted.friend-open-amount":
    "Vytvoril som na Wowee zbierku na tajný darček pre priateľa. Prispej taky a pomôž mu splniť sen!", // WL15
  "general.og.my-wish.restricted.friend-open-noamount":
    "Vytvoril som na Wowee zbierku na tajný darček pre priateľa. Prispej taky a pomôž mu splniť sen!", // WL15
  "general.og.my-wish.restricted.friend-closed-amount":
    "Paráda! Na Wowee sme vybrali peniaze na tajný darček pre priateľa. Vďaka všetkým!", // WL16
  "general.og.my-wish.restricted.friend-closed-noamount":
    "Paráda! Na Wowee sme vybrali peniaze na tajný darček pre priateľa. Vďaka všetkým!", // WL16

  "general.og.for-me-wish.friend-open-amount":
    "{author_nominativ} pre mňa na Wowee vytvoril zbierku na {product}. Ešte zostáva {needed} Sk. Pomôž mi splniť sen a prispej taky!", // WL17
  "general.og.for-me-wish.friend-open-noamount":
    "{author_nominativ} pre mňa na Wowee vytvoril zbierku na {product}. Pomôž mi splniť sen a prispej taky!", // WL17
  "general.og.for-me-wish.friend-closed-amount":
    "Paráda! {author_nominativ} a ďalší priatelia vybrali na Wowee {amount} Sk na {product}. Splnili mi sen, vďaka moc!", // WL18
  "general.og.for-me-wish.friend-closed-noamount":
    "Paráda! {author_nominativ} a ďalší priatelia vybrali na Wowee {amount} Sk na {product}. Splnili mi sen, vďaka moc!", // WL18

  "notFound.title": "Stránka nenájdená",
  "notifications.title": "Notifikácia",
  "notifications.loadMore": "Načítať ďalšie notifikácia",
  "notifications.allNotifications": "Všetky notifikácia",
  "notifications.myWishExpired":
    "Dnes končí tvoja zbierka <strong> {wishTitle} </strong>",
  "notifications.myWishDonation":
    "<strong> {donator} </strong> prispel na Vaše prianie <strong> {wishTitle} </strong>",
  "notifications.wishIDonatedCompleted.title":
    "Prianie <strong> {friend_genitiv} </strong> <strong> {wishTitle} </strong> bolo úspešne ukončené",
  // 'notifications.wishIDonatedCompleted.text': 'Přispěli jste na toto přání',
  // 'notifications.card-donation-failed.title': 'Platba se nezdařila',
  "notifications.card-donation-failed.text": "Platba na {wishTitle} zlyhala.",
  "notifications.myWishComment.title":
    "<strong> {author_nominativ} </strong> komentoval vaše priania <strong> {wishTitle} </strong>.",
  "notifications.youDonate":
    "Prispel si <strong> {amount} {currency} </strong> <strong> {influencer_dativ} </strong> na darček <strong> {wishTitle} </strong>.",
  "notifications.someoneGotDonation":
    "Kamarát ti prispel <strong> {amount} {currency} </strong> na darček <strong> {wishTitle} </strong>.",
  "notifications.gotDonation":
    "{donator_nominativ} tich prispel <strong> {amount} {currency} </strong> na zbierku <strong> {wishTitle} </strong>.",
  "notifications.youMadeDonation":
    "Prispel si <strong> {amount} {currency} </strong> na zbierku <strong> {wishTitle} </strong>.",
  "notifications.gotGift":
    "Tvoji kamaráti sa ti zložili na tajný darček <strong> {wishTitle} </strong> čiastkou <strong> {amount} {currency} </strong>.",
  "notifications.revealRequest.title":
    "<strong> {influencer_nominativ} </strong> ťa žiada o sprístupnenie detaile tvojho darčeka <strong> {wishTitle} </strong>.",
  "notifications.revealGranted.title":
    "<strong> {influencer_nominativ} </strong> tí sprístupnil {influencer_posesiv} priania <strong> {wishTitle} </strong>.",
  "notifications.successCollection.title":
    "Gratulujeme! Tvoji priateľov sa ti zložili čiastkou {amount} {currency} na darček <strong> {wishTitle} </strong>.",
  "notifications.successCollectionParticipated.title":
    "Gratulujeme! <strong> Ty </strong> a tvoji priateľov sa ti zložili čiastkou {amount} {currency} na darček <strong> {wishTitle} </strong> pre kamaráta <strong> {endower} </strong>.",
  "notifications.friendCreatedWish.title":
    "<strong> {author_nominativ} </strong> vytvoril nové priania <strong> {wishTitle} </strong>, na ktoré môžeš prispieť.",
  "notifications.friendCreatedSurprise.title":
    "<strong> {author_nominativ} </strong> vytvoril zbierku <strong> {wishTitle} </strong> pre <strong> {endower_akuzativ} </strong>",
  "notifications.friendRequest":
    "Užívateľ <strong>{author}</strong> ti odoslal žiadosť o priateľstvo",
  "notifications.friendRequest.accepted": "Prijali ste žiadosť o priateľstvo",
  "notifications.friendRequest.deny":
    "Túto žiadosť o priateľstvo ste neprijali",
  "notifications.friendGranted.accepted":
    "Užívateľ <strong>{author}</strong> potvrdil tvoju žiadosť o priateľstvo",
  "notifications.friendGranted.deny":
    "Užívateľ <strong>{author}</strong> zamietol tvoju žiadosť o priateľstvo",

  "popup.got-a-gift.heading": "Dostal si darček!",
  "popup.got-a-gift.donators": "Títo priatelia ti prispeli:",
  "popup.got-a-gift.subheading":
    'Dostal si od priateľov <strong> {amount} {currency} </strong> na <span className = "product-name"> {title} </ span>',
  "popup.got-a-gift.shareMessage":
    "Tyjo, kamoši sa mi zložili na dokonalý darček {title}! Vďaka všetkým!",
  "popup.got-a-gift.shareDescription":
    "Kamaráti vybrali celkom {amount} {currency}.",
  "popup.success-collection.heading": "Úspešná zbierka",
  "popup.success-collection.subheading":
    'Zbierka na <span className = "product-name"> {title} </ span> bola úspešná. Peniaze boli odoslané na tvoj účet.',
  "popup.wish-created.heading": "A je to! Vytvoril si zbierku.",
  "popup.wish-created.subheading":
    'Teraz zdieľaj priania <span className = "product-name"> {title} </ span> medzi svojich kamarátov.',
  "popup.success-collection.donators": "Títo ľudia prispeli na darček:",
  "popup.success-collection.shareMessage": "Zbierka na {title} bola úspešná!",
  "popup.thanks.heading": "Ďakujeme!",
  "popup.thanks.subheading":
    "<strong> {name_dativ} </strong> si prispel <strong> {amount} {currency} </strong> na darček <strong> {wishTitle} </strong> <br /> Sme radi, že robíte ľuďom radosť!",
  "popup.thanks-restricted.subheading":
    "<strong> {name_dativ} </strong> si prispel <strong> {amount} {currency} </strong> na darček. <br /> Sme radi, že robíte ľuďom radosť!",
  "popup.you-donate-restricted.subheading":
    "Práve si prispel {amount} {currency} na darček <strong> {name_dativ} </strong>! Vďaka, že pomáhaš plniť sny!",

  "popup.thanks.share":
    "To je pocit! Prispel som {name_dativ} čiastkou {amount} {currency}, aby som {name_pronoun} pomohol {donator_nomina} splniť sen {wishTitle}!",
  // 'popup.thanks.share': 'To je pocit! Přispěla jsem {name_dativ} částkou {amount} {currency}, abych mu pomohl{name_posesive} splnit sen {wishTitle}!',
  "popup.card-donation-failed.heading": "Platba zlyhala",
  "popup.card-donation-failed.subheading":
    "Bohužiaľ sa nám nepodarilo overiť vašu platbu na zbierku {title}. Z vašej karty nebolo nič strhnuté. Skúste platbu opakovať.",
  //   'popup.card-donation-failed-restricted.subheading': 'Bohužel se nám nepodařilo ověřit vaši platbu {amount} {currency}. Z vaší karty nebylo nic strženo. Zkuste platbu opakovat.',
  "popup.card-donation-failed-restricted.subheading":
    "Bohužiaľ sa nám nepodarilo overiť vašu platbu. Z vašej karty nebolo nič strhnuté. Skúste platbu opakovať.",

  "home.title": "Wowee",
  "home.welcome": "Vitajte!",

  "login.email.valid": "Tento email, je v poriadku",
  "login.email.invalid": "Tento email, nie je v poriadku",
  "login.email.placeholder": "Sem zadajte Váš email",
  "login.password.invalid": "Neplatné heslo",
  "login.password.dont_match": "Heslá sa nezhodujú",
  "login.password.match": "Heslá sa zhodujú",
  "login.password.valid": "Heslo je v poriadku",
  "login.password.forget": "Zabudol si heslo?",
  "login.password.again": "Heslo znova",
  "login.password.placeholder": "Sem zadajte Vaše heslo",
  "login.password.placeholder_again": "Sem zadajte Vaše heslo znova",
  "login.name": "Sem zadajte Vaše jméno",
  "login.surname": "Sem zadajte Vaše priezvisko",
  "login.birthday": "Zadajte dátum narodenia",
  "login.forgotten_password.placeholder": "Sem zadajte Váš email",
  "login.error.credentials":
    "Chybne zadané prihlasovacie údaje (email, heslo alebo oboje)",
  "login.registration.error.title": "Chyba pri registrácii",
  "login.registration.error.1":
    "Registrácia tejto emailovej adresy nebola dokončená",
  "login.registration.error.2":
    "Ak vám neprišiel aktivačný email, vygenerujte si nový.",
  "login.registration.sent.title": "Skvele, už je skoro hotovo",
  "login.registration.sent":
    "Na tvoj email sme poslali overovací email. Stačí na neho kliknúť a registrácia je hotová.",
  "login.registration.resend":
    "Na tento email už bol zaslaný registračný email. Ak vám email nedorazil, môžete si ho nechať zaslať znova pomocou tlačidla nižšie",
  "login.registration.button": "Znovu poslať email",
  "login.new_password.used_hash":
    "Tento odkaz už bol v minulosti použitý, tým pádom došlo k úspešnej zmene hesla.",
  "login.new_password.login": "Prihlás sa do svojho účtu",
  "login.new_password.error": "Neplatný odkaz pre zmenu hesla",
  "login.new_password.error.login": "Späť na úvodnú stránku",
  "login.new_password.footer.1":
    "Poznáte svoje heslo? Výborne tak sa môžete rovno ",
  "login.new_password.footer.2": "prihlásiť",
  "login.no_account.1": "Nemáš u nás účet? Tak si ho ",
  "login.no_account.2": "rýchlo urob",
  "login.account.1": "Už u nás máš účet? Výborne, tak sa môžeš rovno ",
  "login.account.2": "prihlásiť",

  "login.sign-up.about":
    "Wowee prepája priateľov a všetkých, ktorí chcú plniť priania svojich blízkych. Stačí sa zdarma registrovať, založiť priania a potom len zdieľať s ostatnými, aby tí s tým pomohli.",
  "login.sign-up.title": "Skvele! Teraz už len niečo málo o tebe.",
  "login.sign-up.name": "Meno",
  "login.sign-up.surname": "Priezvisko",
  "login.sign-up.birthday": "Dátum narodenia",
  "login.sign-up.gender": "Pohlavie",
  "login.sign-up.gender.male": "Muž",
  "login.sign-up.gender.female": "Žena",

  "login.password.forgotten.title": "Obnova hesla",
  "login.password.forgotten.reset_it": "Obnov si ho!",
  "login.password.forgotten.info":
    "Vyplň svojou e-mailovú adresu. Ak jej poznáme a je na nej zaregistrovaný účet, pošleme ti na tvoj email údaje k obnoveniu účtu",
  "login.password.forgotten.email_sent":
    "Na Vami zadaný email boli zaslané inštrukcie pre nastavenie nového hesla.",

  "wish.title": "Názov zbierky",

  "wish.create.what": "Čo naozaj chceš?",
  "wish.create.forSomeone": "Čo si prajete darovať?",
  "wish.create.whatForHim": "Čo mu chcete dať?",
  "wish.create.whatForHer": "Čo jej chcete dať?",
  "wish.create.noProductsFound": "Nebol nájdený žiadny výsledok hľadania",
  "wish.create.productStartingPrice": "začína na {price} Sk",
  "wish.create.selectCustomWish": "Použiť <em> {text} </ em> ako prianie",
  "wish.create.created":
    "Želanie bolo vytvorené. Prosím počkajte, prebieha presmerovanie.",
  "wish.create.giftDescription": "Popis želanie",
  "wish.create.giftSettings": "Nastavenie želanie",
  "wish.create.wishTitle": "Názov želanie",
  "wish.create.price": "Cena",
  "wish.create.fromAndPrice":
    "z <strong> {shop} </strong> za <strong> {price} Sk </strong>",
  "wish.create.recipientTitle": "Pre koho?",
  "wish.create.recipientTitleMe": "Mňa",
  "wish.create.visibility": "Kto ho môže zobraziť?",
  "wish.create.deadline": "Termín",
  "wish.create.deadline.info":
    "Deadline je len informatívny. Peniaze budú prevedené na Váš účet potom čo ukončíte želania.",
  "wish.create.donations": "Príspevok",
  "wish.create.noDonations": "ľubovoľná hodnota",
  "wish.create.donatorsChoice":
    "Prispievajúce môže zvoliť ľubovoľnú čiastku pre príspevok",
  "wish.create.choseOption": "Vyber možnosť:",
  "wish.create.fixedValue": "Fixná suma",
  "wish.create.dollars": "dolárov",
  "wish.create.euros": "euro",
  "wish.create.crowns": "korun českých",
  "wish.create.noDeadline": "žiadny",
  "wish.create.setDeadline": "Termín",
  "wish.create.recipientMe": "Prianie je pre mňa",
  "wish.create.recipientMeDescription": "Prianie je pre teba.",
  "wish.create.recipientFriend": "Prianie je pre priateľa",
  "wish.create.recipientOutside": "Pre priateľa mimo Wowee",
  "wish.create.writeNameOfFriend": "Napíš meno priateľa: ",
  "wish.create.visibilitySelected": "Iba vybraní priatelia",
  "wish.create.visibilityExpect": "Všetci až na vybrané",
  "wish.create.visibilityPublicIncludingDower":
    "Ktokoľvek vrátane obdarovaného",
  "wish.create.visibilitySelectedExcludingDower":
    "Vybrania priatelia bez obdarovaného",
  "wish.create.visibilityPublicMessage":
    "Priania si môžu zobraziť všetci užívatelia Wowee vrátane neregistrovaných.",
  "wish.create.visibilitySelectedMessage":
    "Prianie uvidí nižšie vybraní priatelia",
  "wish.create.visibilitySelectedMessageExcludingEndower":
    "Prianie uvidí nižšie vybraní priatelia okrem {name_genitiv}.",
  "wish.create.visibilityPublic": "Verejné",
  "wish.create.visibilityFriends": "Priatelia",
  "wish.create.visibilityMe": "Iba ja",
  "wish.create.visibilityCustomUsers": "Konkrétne priatelia",
  "wish.create.visibility.edit": "Uprav",
  "wish.create.friendLists": "Dostupné zoznamy priateľov",
  "wish.create.descriptionPlaceholder":
    "Napíšte, prečo by vám mali vaši priatelov pomôcť s týmto prianím",
  "wish.create.forMe": "Pre mňa",
  "wish.create.for": "Pre",
  "wish.create.createForMe": "Vytvoriť toto prianie <strong> pre mňa </strong>",
  "wish.create.createForFriend":
    "Vytvoriť toto prianie <strong> pre {name} </strong>",
  "wish.create.errorNoTitle":
    "Musíte vyplniť názov želanie pred jeho uložením.",
  "wish.edit.errorNoTitle": "Musíte vyplniť názov želanie pred uložením zmien.",
  "wish.create.unlimitedValue": "Suma bez limitu",
  "wish.create.fixedValueInfo":
    "  (táto suma je len orientačné, nie je nutné ju naplniť)",
  "wish.create.whoIsIt": "Kto to je?",
  "wish.create.friendsName": "Napíš priateľovo meno",
  "wish.create.money_receiver.who": "Kto dostane vybrané peniaze?",
  "wish.create.money_receiver.me": "Ja",
  "wish.create.money_receiver.me.placeholder": "Ja dostanem peniaze",
  "wish.create.surprise_wish": "Pre koho je darček?",

  "modal.error.heading": "Je nám to ľúto",
  "modal.error.title": "Skúste obnoviť stránku",
  "modal.loginError.title": "Zmeňte oprávnenia aplikácie",
  "modal.user_logged.title": "Bohužiaľ ste prihlásený",
  "modal.user_logged.description":
    "Pre vykonanie tejto akcie musíte byť odhlásený",

  "wish.confirm.question": "Ste si istí?",
  "wish.confirmDelete.title": "Zmazať želanie",
  "wish.confirmDelete.question": "Ste si istí, že chcete zmazať toto prianie?",
  "wish.confirmClose.title": "Ukončiť prispievanie",
  "wish.confirmClose.question":
    "Ste si istí, že chcete ukončiť prispievanie pre toto prianie?",
  "wish.missingAccountNumber.title": "Chýba bankový účet.",
  "wish.missingAccountNumber.description":
    "Pre prevody vybraných peňazí a ukončenia zbierky je nutné nastaviť si svoj bankový účet.",
  "wish.missingAccountNumber.doItNow": "Urob to prosím hneď.",
  "wish.confirmRefund.title": "Vrátiť peniaze",
  "wish.confirmRefund.question":
    "Ste si istí, že chcete vrátiť peniaze prispievateľom?",
  "wish.confirmRefund.description": "Peniaze budú vrátené prispievateľom.",
  "wish.close.succ.title": "OK!",
  "wish.close.succ.description": "Peniaze vám budú pripísané do 24 hodín.",
  "wish.detail.collected.mine":
    "{count, plural, " +
    "=0 {Zatial nikto neprispel}" +
    "one {<strong> {count} </strong> priateľ prispel <strong> {gathered} {currency} </strong>}" +
    "few {<strong> {count} </strong> priatelia prispeli celkom <strong> {gathered} {currency} </strong>} " +
    "other {<strong> {count} </strong> priateľov prispelo celkom <strong> {gathered} {currency} </strong>}}",
  "wish.detail.collected":
    "{count, plural, " +
    "=0 {Zatial nikto neprispel}" +
    "one {<strong>{count}</strong> priateľ prispel <strong>{gathered} {currency}</strong>}" +
    "few {<strong>{count} </strong>priatelia prispeli celkom <strong>{gathered} {currency}</strong>} " +
    "other {<strong>{count} </strong> priateľov prispelo celkom <strong>{gathered} {currency}</strong>}}",
  "wish.detail.remaining": "<strong> {days} dní </strong> zostáva",
  "wish.detail.todayEnds": "<strong> Dnes </strong> končí",
  "wish.detail.completed": "Ukončená a vyplatená.",
  "wish.detail.expired": "Po termíne, ale stále môžeš prispievať!",
  "wish.detail.title": "{name_nominativ} si želá",
  "wish.detail.authorName": "{author_nominativ}",
  "wish.detail.baseSurpriseForNonUser":
    "{author_nominativ} vytvoril želanie pre {recipient}",
  "wish.detail.surpriseForNonUser":
    "{author_nominativ} vytvoril želanie pre {recipient}",
  "wish.detail.surpriseTitleHe":
    "{author_nominativ} vytvoril želanie pre {recipient_akuzativ}", // pouzije se v seznamu prani
  "wish.detail.surpriseTitleShe":
    "{author_nominativ} vytvorila želanie pre {recipient_akuzativ}", // pouzije se v seznamu prani
  "wish.detail.surpriseTitleLinkedHe":
    "{author} vytvoril želanie pre {recipient}", // pouzije se na detailu prani
  "wish.detail.surpriseTitleLinkedShe":
    "{author}  vytvorila želanie pre {recipient}", // pouzije se na detailu prani
  "wish.detail.askForAccess": "Požiadať {name_akuzativ} o prístup",
  "wish.detail.accessRequested": "Požiadal si o prístup k tomuto želanie",
  "wish.detail.accessAccepted": "Žiadosť bola akceptovaná",
  "wish.detail.accessRejected": "Žiadosť bola zamietnutá",
  "wish.detail.mainTitle": "Detail želanie",
  "wish.detail.unknown": "Tajný darček",
  "wish.detail.donateButton": "Prispieť",
  "wish.detail.donate-money-info.desktop":
    "Peniaze sú zhromažďované na účtoch Wowee, kým nie je želanie ukončené jeho tvorcom. Potom budú prostriedky prevedené na jeho účet. Wowee si neúčtuje žiadne skryté poplatky za prenos alebo úschovu peňazí, či akékoľvek iné.",
  "wish.detail.donate-money-info.mobile":
    "Peniaze sú zhromažďované na účtoch Wowee",
  "wish.detail.donateButtonFlat": "Prispieť {value} {currency}",
  "wish.detail.comments": "TODO: Komentáre",
  "wish.detail.moneyNeed": "potrebuje <strong> {money} {currency} </strong>",
  "wish.detail.moneyNeeded": "potreboval <strong> {money} {currency} </strong>",
  "wish.detail.moneyGathered": "vybralo <strong> {money} {currency} </strong>",
  "wish.detail.forRefund": "Zbierka bola refundovaná.",
  "wish.detail.terminated": "Zbierka bola uzavretá.",
  "wish.detail.terminatedShort": "Uzavretá.",
  "wish.detail.deleted": "Prianie bolo vymazané.",
  "wish.detail.noDescription": "Žiadny popis.",
  "wish.detail.noDeadline": "žiadny",
  "wish.detail.theseUsersDonated": "Títo kamaráti prispeli na želanie",
  "wish.detail.showAllDonators": "Ukáž všetky prispievajúce",
  "wish.detail.userMoneyReceiver": "Po ukončení želanie dostane peniaze",
  "wish.detail.visibilityTitle": "Prianie je viditeľné",
  "wish.detail.visibility.forMe": "iba pre mňa",
  "wish.detail.visibility.public": "pre všetkých",
  "wish.detail.visibility.forFriends": "pre všetky moje priateľov",
  "wish.detail.visibility.chosenFriends": "pre vybrané priateľov",
  "wish.detail.allowed_users.title": "Títo užívatelia si môžu priania zobraziť",
  "wish.detail.product_url": "Zobraziť v e-shope",
  "wish.detail.donators.anonym": "Anonymné darcu",
  "wish.detail.donator": "{first_name}  <strong>{last_name}</strong>",

  "wish.detail.share.title": "Prianie bolo vytvorené!",
  "wish.detail.share.dismiss": "Zavrieť",
  "wish.detail.share.copyUrl": "Kopírovať",
  "wish.detail.share.copied": "Skopírované!",
  "wish.detail.share.facebook": "Zdieľať na Facebooku",
  "wish.detail.share.twitter": "Zdieľať na Twitteri",

  "feed.nofriends":
    "Som na Wowee, čo ty? Pridaj sa! Čím viac kamošov, tým lepšie.",
  "feed.nofriends-sidebar":
    "Vitaj na Wowee! Môžeš rovno založiť svoje prvé želanie. Alebo pozvi svojich priateľov z Facebooku.",
  "feed.share": "Zdieľať Wowee na Facebooku",
  "feed.share.button.tellAbout": "Pozvať kamarátmi",
  "feed.type-comment": "Sem napíš svoj komentár ...",
  "feed.makeSomeoneHappy":
    "Čo tak urobiť niekoho šťastým <br /> a dať mu tajný darček?",
  "feed.letsDoIt": "Vytvoriť darček!",
  "feed.shareMessage":
    "Wowee vie vybrať peniaze na malé aj veľké želanie. Pridaj sa ku mne a mrkni, ako môžeš Wowee využiť aj tie.",
  "feed.loadMoreComments": "Načítať všetky komentáre",
  "feed.loadLessComments": "Schovať všetky komentáre",

  "wish.dropdownTerminate.title": "Ukončiť želanie",
  "wish.dropdownTerminate.description":
    "<strong> Toto želanie </strong> bude ukončené",
  "wish.dropdownRefund.title": "Vrátiť peniaze",
  "wish.dropdownRefund.description":
    "Peniaze pre toto želanie budú vrátené prispievateľom",
  "wish.dropdownEdit.title": "Upraviť toto želanie",
  "wish.dropdownEdit.description": "<strong> Úprava </strong> želania",
  "wish.dropdownDelete.title": "Zmazať želanie",
  "wish.dropdownDelete.description":
    "<strong> Toto želanie </strong> bude vymazané",
  "wish.dropdownCopy.title": "Kopírovať",
  "wish.dropdownCopy.description": "Vytvoriť ako prianie, či prekvapenie",
  "wish.dropdownHideWish.title": "Skryť toto želanie",
  "wish.dropdownHideWish.description":
    "<strong> Toto želanie </strong> bude skryté",
  "wish.dropdownHideUser.title": "Schovať tohto používateľa",
  "wish.dropdownHideUser.description":
    "<strong> Všetky priania od {name_genitiv} </strong> budú skryté",
  "wish.dropdownShowDonators.title": "Ukáž prispievajúce",
  "wish.dropdownShowDonators.description":
    "Ukáž užívateľov, ktorí prispeli na toto prianie",
  "wish.dropdownSetMoneyReceiver.title": "Nastav príjemcu peňazí",
  "wish.dropdownSetMoneyReceiver.description":
    "Nastav používateľa, ktorý získa peniaze z priania",
  "wish.dropdownSuccesfullyDeletedWish.description":
    "Želanie bolo úspešne vymazané.",
  "wish.dropdownShare.title": "Zdieľaj",
  "wish.dropdownShare.description": "Zdieľajte toto želanie.",

  "donate.title": "Prispieť",
  "donate.title.flat": "Prispieť {donation} {currency}",
  "donate.checkingPayment": "Kontrolovanie platby:",
  "donate.addressIdQr": "Bitcoin adresa v QR:",
  "donate.addressId": "Bitcoin adresa:",
  "donate.bitcoins": "Váš príspevok v BT coins:",
  "donate.howMuch": "Koľko si prajete prispieť {name_dativ}?",
  "donate.choosePaymentMethod": "Prosím vyberte platobnú metódu:",
  "donate.card": "Platobná karta",
  "donate.bitcoin": "Bitcoin",
  "donate.QRCode": "Prevodný príkaz / QR kód",
  "donate.QRCodeMessage":
    "Naskenujte tento QR kód, alebo vykonajte platobný príkaz:",
  "donate.thanks": "Ďakujeme vám!",
  "donate.share":
    "Prispel som {amount} {currency} na želanie pre {wish_title} {done}",
  "donate.share.collected": "Vybraných {amount} {currency}.",
  "donate.share.missing": "Zostáva vybrať {amount} {currency}.",
  "donate.cardRedirection": "Presmerúvajú ...",
  "donate.cardRedirectionWait":
    "Chvíľku vyčkaj, budeš presmerovaný na platobnú bránu.",
  "donate.donators": "{user} prispel {amount} {currency}",
  "donate.donators.title": "Zoznam všetkých prispievajúcich",
  "donate.money_receiver": "Zvoliť príjemcu peňazí",
  "donate.bank_account": "Číslo účtu: ",
  "donate.variable_symbol": "Variabilný symbol",

  "donate.summary":
    "Čiastka <strong> {amount} {currency} </strong> pre {name_akuzativ} bude pripísaná na zbierku hneď, ako prebehne transakcie. <br /> Ďakujeme, že ľuďom dávate radosť.",

  "user.friendship_request.title":
    "Tento užívateľ Vám poslal žiadosť o priateľstvo",

  "friends.all": "Všetci",
  "friends.request": "Žiadosti",
  "friends.newList": "Nový zoznam",
  "friends.filterFriends": "Vyhľadať priateľa",
  "friends.createGift": "Vytvoriť darček",
  "friends.allGifts": "Všetky darčeky",
  "friends.allGiftsWithName": "Všetky darčeky pre {name_genitiv}",
  "friends.delete": "Zmazať zoznam",
  "friends.delete.heading": "Zmazať zoznam priateľov",
  "friends.delete.content":
    "Naozaj chcete zmazať zoznam {name}? Táto akcia sa nedá odvolať.",
  "friends.suggestions": "Dostupní priatelia",
  "friends.noRequests": "Nemáte žiadne žiadosti o priateľstve",
  "friends.acceptRequest": "Prijať",
  "friends.declineRequest": "Odmietnuť",
  "friends.noFriends.inviteNew":
    "Týchto 5 užívateľov je na Wowee nových, možno poznáte niekoho z nich?",
  "friends.empty_list": "Tento zoznam priateľov je zatiaľ prázdny.",

  "upcomingBirthdays.title": "Nadchádzajúce narodeniny",
  "seeAll.link": "Všetci »",
  "myWishes.title": "Moje želanie",
  "myWishes.no_wish_open": "Nemáte aktívny žiadne priania.",
  "myWishes.no_wish_default": "Nemáte aktívny žiadne priania.",
  "myWishes.no_wish_ended": "Žiadna želanie ste doposiaľ neukončil.",
  "myWishes.no_wish_deleted": "Žiadne želanie ste doteraz nezmazal.",
  "myWishes.label.active": "aktívny",
  "myWishes.label.ended": "ukončené",
  "myWishes.label.deleted": "zmazané",
  "settings.bankAccount.example": "např.: 1234567890/1234",
  "settings.title": "Nastavenie",
  "settings.bankAccount.title": "Číslo bankového účtu:",
  "settings.bankAccount.howTo":
    "Pre zmenu čísla bankového účtu, vyplňte polia formulára a kliknite na Uložiť.",
  "settings.loggedIn": "Prihlásený ako",
  "settings.activeGifts": "Otvorené zbierky",
  "settings.createdGifts": "Vytvorené zbierky",
  "settings.createdSuprises": "Vytvorených prekvapenie",
  "settings.finishedWishes": "Úspešné zbierky",
  "settings.totalDonated": "Daroval {user_nomina} si ({currency})",
  "settings.totalGathered": "Dostal{user_nomina} si ({currency})",
  "settings.selectLanguage": "Vybrať jazyk",
  "settings.language.english": "Angličtina",
  "settings.language.czech": "Čeština",
  "settings.language.slovak": "Slovenština",
  "settings.selectCurrency": "Měna",
  "settings.country.label": "Krajina v ktorej sa nachádzam",
  "settings.country.cz": "Česká Republika",
  "settings.country.svk": "Slovensko",
  "settings.currency.czk": "CZK",
  "settings.currency.usd": "USD",
  "settings.currency.eur": "EUR",
  "settings.delete_account": "Smazať účet",
  "settings.access_requests": "Žiadosti o sprístupnenie želanie",
  "settings.popup.invalidInput.title": "Chyba",
  "settings.popup.invalidInput.description":
    "Nezadali ste správne číslo účtu alebo adresu Bitcoin peňaženky.",
  "settings.confirmDeleteAccount.title": "Smazať účet",
  "settings.confirmDeleteAccount.description":
    "Ste si istí, že chcete zmazať tento účet?",
  // 'settings.friendRemoved.title': '{name_nominativ} byl odstraněn z přátel',
  "settings.friendRemoved.description":
    "{name_nominativ} bol odobratý z priateľov.",
  "settings.information.title": "Informácie",
  "settings.allowedUsers.title": "Vyberte priateľov, ktorí uvidia toto želanie",
  "settings.allowedUsers.close": "Vybrať priateľov",
  "settings.aboutWowee.title": "Ako to funguje?",
  "settings.selectRecipient.title": "Vyber príjemcu želanie",
  "settings.changePicture": "Zmeniť profilový obrázok",
  "settings.changePicture.title": "Zmena profilového obrázka",
  "settings.userLogedOut.title": "Došlo k odhlásenie používateľa",
  "settings.userLogedOut.description.first":
    "V aplikácii sa vyskytla chyba, ktorá je známa, došlo ale k odhlášní používateľa z Wowee",
  "settings.userLogedOut.description.second":
    "Pre opätovné prihlásenie vykonaní predchádzajúcich krokov stlačte prihlásiť",
  "settings.setNewPassword.title": "Zmena hesla",
  "settings.setNewPassword.currentPassword": "Aktuálne heslo",
  "settings.setNewPassword.newPassword": "Nové heslo",
  "settings.setNewPassword.newPasswordAgain": "Nové heslo pre kontrolu",
  "settings.setNewPassword.confirm": "Zmeniť heslo",
  "settings.setNewPassword.passwordChanged": "Heslo bolo úspešne zmenené",
  "settings.setNewPassword.passwordError":
    "Zle zadané pôvodné heslo alebo sa nové heslá nezhodujú",

  "access_requests.empty": "Nemáte žiadne čakajúce žiadosti.",
  "access_requests.title": "Žiadosti o sprístupnenie želanie",
  "access_requests.user_asked":
    "{name_nominativ} žiada o sprístupnenie želanie",
  "access_requests.accept": "Potvrdiť",
  "access_requests.accept.done": "Potvrdené",
  "access_requests.accept.error": "Potvrdenie zlyhalo",
  "access_requests.decline": "Zamietnuť",
  "access_requests.decline.done": "Zamietnuté",
  "access_requests.decline.error": "Zamietnutú zlyhalo",

  "user.profile": "{name_nominativ}",
  "user.name": "{first_name} {last_name}",
  "user.noWishes":
    " {name_nominativ} momentálne nemá žiadnu verejnú zbierku, na ktorú by ste mohli prispieť. Registrujte sa a vytvorte ju.",
  //  'user.profile.non-logged.noWishes': '{name_nominativ} momentálně nemá žádnou veřejnou sbírku, na kterou byste mohli přispět. Registrujte se a vytvořte ji pro něj.',
  "user.profile.non-logged.hasWishes":
    " {name_dativ} môžete prispieť na nejakú {name_posesiv} zbierku.",
  // 'user.profile.non-logged.heHasWishes': ' {name_dativ} můžete přispět na nějakou jeho sbírku.',
  // 'user.profile.non-logged.sheHasWishes': ' {name_dativ} můžete přispět na nějakou jeho sbírku.',
  "user.profile.non-logged":
    "Vitajte na Wowee - služba, ktorá spája kamarátov.",
  "user.add_user": "Pridať do priateľov",
  "user.remove_user": "Odstrániť z priateľov",
  "user.request_sent": "Žiadosť o priateľstvo odoslaná",
  "user.no_friend":
    "Tento používateľ nie je v tvojich priateľoch. Ak chceš mu vytvoriť darček, najskôr si ho pridaj do priateľov a vyčkaj na jeho potvrdenie.",

  "thanks.fit.title": "Fakulta informačných technológií ČVUT v Prahe",
  "thanks.fit.subtitle":
    "Wowee bolo vytvorené prevažne na pôde ČVUT FIT v Prahe v rámci výučby a poskytlo priestor pre vzájomné stretnutia realizačného tímu.",
  "thanks.chludil":
    "Jiří je kľúčový vyučujúci, ktorý otvoril dvere k realizácii nielen Wowee, ale aj ďalších projektov. Po celú dobu podporoval vedúceho projektu Bc. Michala Maněnu a viedol záverečnej práce prvej vlny študentov, ktoré Wowee tvorili.",
  "thanks.valenta":
    "Michal, ako vedúci katedry Softvérového inžinierstva, formálne zaštítil celú realizáciu Wowee na svojej katedre. Bez neho by Wowee nikdy nevzniklo.",
  "thanks.paus":
    "Peter je vedúcim bakalárskych prác, ktoré v roku 2018 budú obhajovať študenti 2. vlny vývoja Wowee.",
  "thanks.buchtela":
    "David je vedúcim bakalárskych prác, ktoré v roku 2018 budú obhajovať študenti 2. vlny vývoja Wowee.",
  "thanks.hoffman":
    "Honza je autorom užívateľského rozhrania a bol oponentom niekoľkých bakalárskych prác.",
  "thanks.maca":
    "Jindra viedol v rámci webového tíme skupinu študentov, ktorí boli zodpovední za výstupnú kvalitu aplikácie.",

  "thanks.oponents.title": "Oponenti kvalifikačných prác",
  "thanks.oponents.subtitle":
    "Nižšie uvedení pedagógovia vypracovali vznášanie posudkami klasifikačných prác študentov, ktoré sa na vývoji podieľali.",
  "thanks.oponents.klan":
    "Docent Klán oponoval bakalársku prácu zaoberajúca sa online platobnými metódami.",
  "thanks.oponents.vitvar":
    "Docent Vitvar bol oponentom bakalárskej práce zaoberajúce sa komparáciou REST a GraphQL.",
  "thanks.oponents.vynikarova":
    "Dr. Vynikarová bola oponentom bakalárskej práce zaoberajúce sa prevádzkou iOS aplikácia Wowee v globálnom prostredí.",
  "thanks.oponents.kucera":
    "Dr. Kučera bol prísnym oponentom bakalárskej práce zaoberajúce sa GDPR vo webových službách, ktorej praktická časť sa zameriava na Wowee.",
  "thanks.oponents.jeschke":
    "Lukáš bol oponentom dvoch bakalárskych prác s orientáciou na backend.",
  "thanks.oponents.jirkovsky":
    "Inžinier Jirkovský bol oponentom bakalárskej práce zaoberajúce sa kvalitou multiplatformových aplikácií.",
  "thanks.oponents.stepan":
    "Inžinier Štefan bol oponentom bakalárskej práce Android verzií Wowee.",
  "thanks.oponents.bliznicenko":
    "Inžinier Blizničenko bol oponentom bakalárskej práce zaoberajúce sa backendom Wowee.",

  "thanks.mobile-app.title": "Vývojári mobilná verzia",
  "thanks.mobile-app.subtitle":
    "Základný kameň pre vývoj Wowee položili v rokoch 2016 až 2017 nižšie uvedení študenti, resp. absolventi ČVUT FIT v rámci kurzov Tímový softvérový projekt 1 a 2. Vytvorili prvú verziu API a mobilné verzie aplikácií, na ktorých vývoji pokračujú ďalšie ročníky študentov.",
  "thanks.bachelor-thesis": "bakalárskej práci",
  "thanks.mobile-app.balatsko.1":
    "Maks bol vedúcim tímu a spoluautor API. Vo svojej ",
  "thanks.mobile-app.balatsko.2":
    " navrhol a prototypoval službu, ktoré slúžia na doporučovania vhodného darčeka pre priateľov.",
  "thanks.mobile-app.kuzmovych":
    "Yevhen bol hlavým vývojára prvá verzia API, ktoré opísal vo svojej ",
  "thanks.mobile-app.arkhipov":
    "Gleb je spoluautorom prvá verzia iOS aplikácie ElateMe, na ktorej písal svoju ",
  "thanks.mobile-app.terokhin":
    "Yegor je spoluautorom prvá verzia iOS aplikácie Wowee, na ktorej písal svoju ",
  "thanks.mobile-app.solovev":
    "Georgii navrh a implementoval prvú verziu Android aplikácie Wowee ",
  "thanks.mobile-app.laskov":
    "Boris sa v rámci výučby podieľal na tvorbe Android klienta.",
  "thanks.mobile-app.liferov":
    "Ilia je vývojárom Android aplikácie, v ktorej pokračoval po Georgii Sovovevem a svoje snaženie zvečnil vo svojej ",
  "thanks.mobile-app.tu-do.1":
    "Tuan je ekonomicky založený študent FIT, ktoré vykonáva rešerš ku globálnemu prevádzky aplikácie a vývojár iOS aplikácie. Vo svojej bakalárskej práci sa venoval ",
  "thanks.mobile-app.tu-do.2": " prevádzky iOS aplikácie v globálnom prostredí",
  "thanks.mobile-app.stanovcak":
    "Tomáš pokračuje na vývoji iOS klienta v akademickom roku 2018/2019 v kurze BI-SP1",
  "thanks.mobile-app.aron":
    "Lukáš pokračuje na vývoji Android klienta v akademickom roku 2018/2019 v kurze BI-SP1",

  "thanks.web-app.title": "Vývojári webovej verzie",
  "thanks.web-app.subtitle":
    "V rokoch 2017 až 2018 pracovali nižšie uvedení študenti na realizáciu webovej verzie Wowee",
  "thanks.web-app.martinek.1":
    "Michal bol vedúcim vývojárom webovej verzie. Obhájil bakalársku prácu na tému ",
  "thanks.web-app.martinek.2": "Komparácia REST a GraphQL",
  "thanks.web-app.cerny":
    "Michal bol jeden z teamleaderů webovej verzie. Tiež pracoval na implementácii iOS verzie.",
  "thanks.web-app.mokos":
    "David bol jeden z teamleaderů, ktoré mal na starosti vývoj webu a manažment kvality.",
  "thanks.web-app.levinzon": "Roman bol vývojárom webovej verzie.",
  "thanks.web-app.rovnak": "Ruda bol vývojárom webovej verzie.",
  "thanks.web-app.hrachovina":
    "Lukáš bol a je vývojárom ďalšia verzia API, na ktorom obhájil tému svojej ",
  "thanks.web-app.bergl": "Štefan bol vývojárom webovej verzie.",
  "thanks.web-app.lukasevic":
    "Luka bol vývojárom webovej verzie so špecializáciou na online platobné metódy, čomu sa venoval vo svojej ",
  "thanks.web-app.grofek.1":
    "Tomáš bol testerom API, ktoré svoje znalosti plne rovninul vo svojej bakalárskej práci ",
  "thanks.web-app.grofek.2": " QA v multiplatformových aplikáciách",
  "thanks.web-app.sanda.1": "Michal bol testerom webové aplikácie. Svoju ",
  "thanks.web-app.sanda.2": " potom prispel k riešeniu problematiky GDPR.",
  "thanks.web-app.vybiral": "Tomáš bol vývojárom webovej verzie.",
  "thanks.web-app.bocan": "Peter bol testerom webovej verzie.",

  "thanks.partners.title": "Vývojári sekcie Pre partnerov",
  "thanks.partners.subtitle":
    "Od februára 2019 vyvíjame nástroje, ktorý umožní eshopům vytvárať darčeky priamo z ich stránok. Realizačný tím tvoria:",
  "thanks.partners.votocek":
    "Jirka je ostrieľaný profesionál, ktorý z vlastnej iniciatívy prišiel s vytvorením doplnku pre eshopy.",
  "thanks.partners.venecek":
    "Jirka riadi tím študentov v akademickom roku 2018/2019 v kurze BI-SP1. Má na starosť analýzu požiadaviek a riadenie tímu.",
  "thanks.partners.safarik":
    "Honza je architektom momentálne vyvíjané sekcie Pre partnerov.",
  "thanks.partners.picka": "Honza spoluvytvára sekcii Pre partnerov.",
  "thanks.partners.fryc": "Dominik spoluvytvára sekcii Pre partnerov.",

  "shoptetWidget.title": "Shoptet doplnok Wowee zbierka",
  "shoptetWidget.headline": "Shoptet doplnok Wowee zbierka",
  "shoptetWidget.content": `
    <p>Eshopům, ktoré sú prevádzkované na platforme Shoptet, umožňujeme inštaláciu <a href="https://doplnky.shoptet.cz/wowee-sbirka"> doplnku </a>, ktorý umožní vytvorenie zbierky priamo z detailu produktu.</p>
    <h3>Čo doplnok umožní?</h3>
    <ul>
    <li>Rýchlo a jednoducho založiť zbierku na vybraný tovar z e-shopu</li>
    <li>Získať peniaze na vysnívaný darček priamo od svojich priateľov (najčastejšie ide o narodeninové alebo svadobné dary)</li>
    <li>Propagovať zbierku na sociálnych sieťach i ďalšími kanálmi, a tým promovať aj vaše produkty a e-shop</li>
    </ul>
    <p>Beží Ak je váš eshop na Shoptet, <a href="https://doplnky.shoptet.cz/wowee-sbirka"> nainštalujte si doplnok </a> ešte dnes a získajte nových zákazníkov.</p>
  `,
  "shoptetWidget.shoptetOnly":
    '<p>Eshopům, které jsou provozovány na platformě Shoptet, umožňujeme instalaci <a href="https://doplnky.shoptet.cz/wowee">doplňku</a>, jenž umožní vytvoření sbírky přímo z detailu produktu.</p>',
  "shoptetWidget.howItWorks": `
    <h3>Ako Wowee funguje?</h3>
    <p>Naše video vám to vysvetlí za 1 minútu!</p>  
  `,
  "shoptetWidget.instalation": `
    <h3>Ako doplnok nainštalovať?</h3>
    <p>Po inštalácii vám príde potvrdzovací email na adresu uvedenú ako konktatní u vášho eshopu. V emaile kliknete na potvrdzovací odkaz a nastavíte si heslo pre prístup k administrácii.</p>
   `,
  "privacy.title": "Zásady ochrany osobných údajov",
  "privacy.content": `<h2>Zásad ochrany osobných údajov SPOLOČNOSTI ElateMe s.r.o.</h2>
	<p>
	Tieto zásady ochrany osobných údajov ( "Zásady") sú neoddeliteľnou súčasťou obchodných podmienok spoločnosti ElateMe s.r.o., so sídlom Praha 1 - Rybná 716/24, PSČ 110 00, zapísanej na Mestskom súde v Prahe oddiel C vložka 278456 (ďalej len "ElateMe") .
	</p>
	<p>
	Zásady popisujú, akým spôsobom spoločnosť ElateMe zhromažďuje, užíva a sprístupňuje, teda nakladá, s Vašimi osobnými údajmi vo vzťahu k webovým stránkam prevádzkovaným spoločnosťou ElateMe na adrese https://wowee.cz (ďalej len "Portál") a službám poskytovaným spoločnosťou ElateMe prostredníctvom portálu (ďalej len "Služby"). Spoločnosť ElateMe zhromažďuje len také osobné údaje, ktoré umožní spoločnosti poskytnúť Vám požadované Služby.
	</p>
	<p>
	Spoločnosť ElateMe môže meniť a dopĺňať tieto Zásady kedykoľvek pomocou umiestnenie revidovanej verzie na Portáli a takáto zmena a doplnenie nadobudne účinnosť k dátumu takéhoto umiestnenia.
	</p>

	<h3>Osobné údaje, ktoré spoločnosť ElateMe zhromažďuje</h3>
	<p>
	Na založenie Vášho účtu na využívanie Služieb poskytovaných spoločnosťou ElateMe na Portáli (ďalej len "registrácia"), vyžaduje spoločnosť ElateMe poskytovania nižšie osobných údajov: meno, priezvisko, dátum narodenia, bydlisko, emailová adresa a číslo bankového účtu.
	</p>
	<p>
	Údaje týkajúce sa Vášho bankového účtu sú určené výhradne v súvislosti s prevodom finančných prostriedkov pre Zbierku, ktorú ste založili alebo prispeli. Spoločnosť ElateMe sa zaväzuje používať tieto informácie k založeniu Vášho účtu, na správu účtu a na zabezpečenie Vášho prístupu k Službe a k jej aktualizáciám. Súhlas so spracovaním Vašich osobných údajov je vyžadovaný pri registrácii Služby. Tento súhlas môžete kedykoľvek odvolať, a to prostredníctvom emailu zaslaného na emailovú adresu spoločnosti ElateMe info@wowee.cz s uvedením Vášho mena, priezviska a adresy z dôvodu Vašej identifikácie.
	</p>
	<p>
	V prípade používania Portálu, a to aj bez registrácie, zaznamená spoločnosť ElateMe Vašu IP adresu a štandardné údaje, ako je typ Vášho prehliadača a stránky, ktoré ste si na Portáli prezerali. Tieto informácie slúžia na monitorovanie a na prevenciu podvodov, diagnostike problémov a na spracovanie štatistických údajov, ktoré sú anonymné a neuvádzajú Vaše osobné údaje.
	</p>

	<h3>Nakladanie s osobnými údajmi</h3>
	<p>
	Vami poskytnuté údaje v rámci registrácie či pri prezeraní Portálu spoločnosť ElateMe použije na účely uvedené a ďalej len na tieto účely:
	<ol>
		<li>vyhovenie Vašej žiadosti o Službu, tj. hlavne na spracovanie Vašich údajov určených k výmene medzi prispievateľov a Príjemcom sbítky a ďalej potom;

	<li>k zlepšovaniu kvality poskytovanej Služby a Portálu;

	<li>k Vášmu kontaktovanie za účelom riešenia problémov súvisiacich s využívaním Služby alebo Portálu;

	<li>k zaslanie informácie o Službe, oi. potvrdenie, oznámenia, aktualizácie a podporná a administratívne oznámenie;

	<li>k zaslaniu oznámenia o výrobkoch a službách a podujatiach organizovaných spoločnosťou ElateMe;

	<li>a na uľahčenie užívania Portálu tak, aby ste mohli uvádzať menej údajov.
	</ol>

	<p>Osobné údaje budú spoločnosťou ElateMe uchovávať po dobu platnosti Vášho účtu a ďalej po dobu viac ako 5 rokov od zrušenia účtu.
	</p>
	<p>
	Ak nie je v týchto Zásadách stanovené inak, spoločnosť ElateMe bez Vašeho výslovného súhlasu neprenajíma ani nepredáva žiadne osobne identifikovateľné údaje, zhromaždené prostredníctvom Portálu alebo Služby, ani ich nezdieľa s inými osobami alebo nespriaznenými spoločnosťami, s výnimkou týchto prípadov:
	<ul>
	<li>poskytovanie týchto údajov nezávislým dodávateľom, poradcom a iným poskytovateľom služieb, ktorí spolupracujú so spoločnosťou ElateMe a potrebujú prístup k Vašim údajom, aby mohli vykonávať túto činnosť;
	<li>dodržiavanie právnych predpisov alebo v reakcii na požiadavky právnych predpisov;
	<li>ochrana práv a majetku spoločnosti ElateMe, jej zástupcov, používateľov a iných osôb, najmä za účelom vymáhania jej zmlúv, zásad a užívateľských podmienok a v naliehavých prípadoch na ochranu vlastnej bezpečnosti spoločnosti ElateMe, používateľov jej služieb alebo akejkoľvek inej osoby;
	<li>v súvislosti s akoukoľvek fúziou, predajom majetku spoločnosti, financovaním alebo nadobudnutím všetkých alebo časti podniku spoločnosti ElateMe zo strany inej spoločnosti alebo v priebehu takéhoto procesu.
	<li>Spoločnosť ElateMe bez vášho súhlasu zo zásady nesprístupňuje osobné údaje zozbierané po internete tretími osobám na účely priameho marketingu. Pokiaľ si budete priať tento súhlas kedykoľvek po jeho udelení odvolať, povedzte to spoločnosti ElateMe na e-mailovú adresu info@wowee.cz.
	</ul>
	<h3>Spravovanie Vášho účtu, aktualizácia údajov, zmazanie účtu</h3>
	<p>
	V rámci vedenie účtu máte možnosť Vaše osobné údaje aktualizovať, opraviť, popr. Vaše konto zmazať. Pokiaľ dôjde k zmazaniu účtu, spoločnosť ElateMe si vyhradzuje právo na uloženie základných informácií ohľadom tých užívateľov Služby, ktorí porušili obchodné podmienky, či ktorým bol účet spoločností ElateMe zrušený, a to z dôvodu ich opakovaného zneužitia Služieb.
	</p>

	<h3>Bezpečnostné opatrenia a oznámenie osobných údajov</h3>
	<p>
	Účet každého užívateľa Služby je chránený nezávislým prihlásením prostredníctvom siete Facebook. Odporúčame heslo uchovávať v tajnosti a neposkytovať ho tretím osobám, a to ani prostredníctvom emailu, v ktorom bude heslo vyžadované. Spoločnosť ElateMe nezasiela užívateľom Služby emailovej správy, ktoré obsahujú požiadavku na zadanie hesla do emailu. V prípade zabudnutého hesla, dostane užívateľ, na svoju žiadosť a na emailovú adresu uvedenú v registrácii, email obsahujúci dočasné heslo k prístupu na svoj účet, kde si zvolí nové heslo.
	</p>
	<p>
	Spoločnosť ElateMe nezodpovedá za zneužitie poskytovaných údajov, ku ktorému došlo v dôsledku neoprávneného prístupu tretej osoby, či v priebehu prenosu dát v rámci komunikačných sietí. Používateľ berie na vedomie, že údaje a informácie poskytnuté na internete napr. V rámci diskusného fóra, chatu či emailu, môžu byť zneužité tretími osobami. Pri uvádzaní Vašich údajov prostredníctvom internetu buďte preto obozretní.
	</p>
	<p>
	ElateMe uchováva Vaše osobné údaje v súlade s najvyššími bezpečnostnými štandardmi, prostredníctvom širokej škály bezpečnostných opatrení, vrátane šifrovacích a overovacích nástrojov, aby zabránila neoprávnenému prístupu, pozmenenie, zverejnenie alebo zničenie Vašich osobných údajov a zachovávala ich utajenia. Vaše osobné údaje sú chránené firewally a sú prístupné iba obmedzenému počtu osôb, ktoré sú povinné uchovávať tieto údaje v tajnosti.
	</p>
	<p>
	Nehľadiac na toto úsilie zo strany spoločnosti ElateMe nemožno zaručiť 100% bezpečnosť prenosu údajov prostredníctvom verejnej siete internet. Vaše osobné údaje môžu byť napríklad ovplyvnené určitými udalosťami mimo dosahu spoločnosti ElateMe, ako je činnosť hackerov a fyzická krádež. Beriete na vedomie, že osobné údaje poskytujete na svoje vlastné nebezpečenstvo.
	</p>

	<h3>Cookies a internetové beacons</h3>
	<p>
	Aby Vám spoločnosť ElateMe mohla poskytovať služby prispôsobené Vaším potrebám, používa tzv. Cookies (session ID cookies a trvalé cookies) k uchovávanie a príležitostnému sledovanie Vašich návštev Portálu. Cookie je malý textový súbor, ktorý je uložený vo Vašom počítači na účely zaznamenávania. Spoločnosť ElateMe užíva cookies, aby Vám uľahčila navigáciu po Portálu. Po zatvorení Vášho prehliadača, platnosť session ID cookie uplynie. Trvalé cookie zostáva na Vašom pevnom disku po dlhšiu dobu. Spoločnosť ElateMe tiež ukladá rozlíšenie Vašej obrazovky a zisťuje, či je vo Vašom prehliadači povolený JavaScript na uľahčenie prezeranie Portálu. Väčšina internetových prehliadačov je nastavená tak, že automaticky akceptuje cookies. Cookies môžete obvykle odstrániť alebo odmietnuť tak, že sa riadite pokynmi v podpornom súbore svojho internetového prehliadača. Ak však odstránite cookies, nemusíte mať možnosť plne využívať všetkých aspektov Portálu alebo Služby.
	</p>
	<p>
	Spoločnosť ElateMe môže zhromažďovať údaje pri využití Portálu aj pomocou internetových beacons. Tieto navádzača sú elektronické obrázky, ktoré možno užívať na Portáli v súvislosti so Službami alebo v e-maile, ktorý zašle spoločnosť ElateMe. Tá užíva beacons k odovzdávanie cookies, k počítanie návštev a na to, aby Vám oznámila, či bol nejaký e-mail otvorený a vybavený. ElateMe môže tiež zhromažďovať informácie o Vašom počítači alebo o inom prístupovom zariadení na zmiernenie rizík a na prevenciu podvodov.
	</p>

	<h3>Kontakt</h3>

	Všetky otázky týkajúce sa ochrany osobných údajov, či upozornenie na porušenie práv, zasielajte prostredníctvom emailu spoločnosti ElateMe na adresu: info@elateme.cz

	ElateMe s.r.o. Privacy Policy v.1

	Dátum účinnosti: 20.11.2017`,
  "general.title": "Obchodné podmienky",
  "general.content": `<h2>OBCHODNÉ PODMIENKY SPOLOČNOSTI ElateMe s. R. O.</h2>
            <p>
              Starostlivo si prečítajte tieto obchodné podmienky (ďalej len "Obchodné podmienky")
              predtým, než začnete využívať služby sprostredkovanej spoločností <strong> ElateMe s.r.o.. </strong>, IČO 06226426, so sídlom Rybná 716/24, Staré
              Mesto, 110 00 Praha 1, zapísanej v obchodnom registri Okresného
              súdom v Prahe odd. C vložka 278456.
            </p>
            <p>
              Tieto Obchodné podmienky stanovujú zásady prevádzky a užívania webových stránok
              wowee.cz (ďalej len "Webové stránky" alebo tiež "Portál") pre Autormi zbierok
              zverejnených na Webovej stránke a ich Užívateľa a zásady používania
              služieb poskytovaných spoločnosťou <strong> ElateMe s.r.o. </ Strong>
              Webovej stránke (ďalej len "Služby").
            </p>
            <p>
              Vstupom, užívaním, prehliadaním, prenosom alebo uložením webovskej stránky,
              alebo ktorejkoľvek zo služieb spoločnosti <strong> ElateMe s.r.o.. </strong>,
              predovšetkým potom zaslaním žiadosti o zverejnenie Zbierky na Webových stránkach
              potvrdzujete, že ste si tieto Obchodné podmienky prečítali a súhlasíte a
              zaväzujete sa tieto Obchodné podmienky dodržiavať.
            </p>
            <p>
              Neoddeliteľnou súčasťou týchto Obchodných podmienok sú <a href="/privacy"> Zásady ochrany osobných
              údajov </a>. Spoločnosť <strong> ElateMe s.r.o.. </ Strong> si vyhradzuje právo podľa svojho vlastného
              uváženia zmeniť alebo nahradiť ktorúkoľvek z podmienok, alebo kedykoľvek zmeniť,
              pozastaviť alebo ukončiť Službu (najmä dostupnosť akúkoľvek funkciu alebo
              obsahu), uverejnením týchto zmien na Webových stránkach alebo v e-mailovom
              oznámenia zaslanom Používateľom.
            </p>
            <p>
              Spoločnosť <strong> ElateMe s.r.o.. </ Strong> môže tiež stanoviť obmedzenia
              určitých funkcií a služieb alebo obmedziť prístup ku ktorejkoľvek alebo všetkým
              Službám, a to bez predchádzajúceho oznámenia.
            </p>
            <p>
              <h3>Definícia základných pojmov</h3>
            </p>
            <p>
              <strong>ElateMe s.r.o.</strong>
              <strong>, prevádzkovateľ</strong>
              – spoločnosť <strong> ElateMe s.r.o.. </strong>, IČO 06226426, so sídlom Rybná
              716/24, Staré Mesto, 110 00 Praha, zapísaná v obchodnom registri vedenom
              Mestským súdom v Prahe odd. C vložka 278456, prevádzkujúca webové stránky na
              adrese wowee.cz
            </p>
            <p>
              <strong>Webové stránky, Portál</strong>
              – stránky prevádzkované spoločnosti <strong> ElateMe s.r.o.. </strong> umiestnené
              na adrese wowee.cz
            </p>
            <p>
              <strong>Zbierka </strong>
              – je návrh na darček alebo finančnú zbierku na účel transparentne zabezpečiť
              organizáciu výberu finančných príspevkov. Výťažok z tejto zbierky je určený pre
              Príjemca zbierky.
            </p>
            <p>
              <strong>Autor zbierky </strong>
              – fyzická osoba, ktorá vytvorí Zbierku a zašle Prevádzkovateľovi žiadosť o
              zverejnenie Zbierky.
            </p>
            <p>
              <strong>Relizovaná zbierka - </strong>
              jedná sa o zbierku, ktorá bola ukončená Autorom zbierky. finančné prostriedky
              boli prevedené na účet Príjemca zbierky.
            </p>
            <p>
              <strong>Príjemca zbierky - </strong>
              užívateľ, ktorá je vybraný Autorom zbierky, na ktorého bankový účet bude
              prevedený výťažok zo Zbierky.
            </p>
            <p>
              <strong> Používateľ </strong>
              – akákoľvek fyzická osoba (Donator, Autor zbierky, Príjemca zbierky,
              neregistrované osoba), ktorá využíva služby poskytované spoločnosťou <strong> ElateMe s.r.o. </ Strong> na Webovej stránke.
            </p>
            <p>
              <strong>Donátor – </strong>
              osoba, ktorá prispela na Zbierku.
            </p>
            <p>
              <strong>Príspevok – </strong>
              finančné prostriedky prínosu k Donator na Zbierku.
            </p>
            <p>
              <strong>Oprávnení užívatelia</strong>
            </p>
            <p>
              Služby sú k dispozícii len osobám vo veku minimálne 15 rokov. vstupom,
              užívaním, prehliadaním, prenosom alebo uložením Webových stránok či zaslaním
              Zbierky a akúkoľvek ďalšiu aktívnou účasťou vyhlasujete a ručíte, že
              fyzickou osobou staršou 15 rokov, a že všetky registračné údaje, ktoré sú
              Vami predkladané, sú presné a pravdivé.
            </p>
            <p>
              Prevádzkovateľ môže podľa svojho výlučného uváženia odmietnuť ponúkať Službu
              akejkoľvek fyzickej alebo právnickej osobe.
            </p>
            <p>
              <strong>Registrácia</strong>
            </p>
            <p>
              Podmienkou používanie určitej Služieb zo strany Prevádzkovateľa je registrácia
              Užívateľa u Prevádzkovateľa prostredníctvom služby Facebook.
            </p>
            <p>
              Užívatelia sú povinní poskytnúť Prevádzkovateľovi presné a úplné registračné
              údaje a tieto údaje pravidelne aktualizovať. Používateľ nesmie použiť účet
              iného Používateľa. V prípade, že dôjde k neoprávnenému použitiu účtu
              Užívateľa, je tento Používateľ povinný písomne ​​oznámiť Prevádzkovateľovi takejto
              neoprávnené použitie účtu alebo iné porušenie bezpečnosti účtu, o ktorom sa
              dozvie.
            </p>
            <p>
              Spoločnosť <strong> ElateMe s.r.o.. </ Strong> si vyhradzuje právo zamietnuť
              registráciu Užívateľa alebo ju zrušiť, podľa svojho výlučného uváženia.
            </p>
            <p>
              <h3>Autori zbierok - získavanie peňazí od Donator</h3>
            </p>
            <p>
              Elat je online systém a platforma, v rámci ktorej možno získať financovanie
              pre priania Príjemca zbierky a určené Autorovi či 3. osobe. prevádzkovateľ
              povoľuje Autorom zbierky, ktorí zapíšu svoje Zbierky do zoznamu, aby Príjemcovia
              zbierky získali finančné prostriedky od iných Užívateľov (Donator).
              Prevádzkovateľ je sprostredkovateľom vzťahu medzi Autormi zbierok a ostatnými
              Používateľovi. Prevádzkovateľ umožňuje Autorom zbierok prezentovať svoje Zbierky na
              Webových stránkach.
            </p>
            <p>
              Všetky Príspevky prechádza skrz podsúvahových účtoch spoločnosti <strong> ElateMe s.r.o., </strong> ktoré sú obyčajným tranzitný miestom.
              Finálnym príjemcom je Príjemca zbierky. Užívateľ je povinný poskytnúť <strong> ElateMe s.r.o.. </ Strong> všetku súčinnosť potrebnú na vykonanie
              identifikácia podľa zákona č. 253/2008 Zb. o niektorých opatreniach proti
              legalizácii príjmov z trestnej činnosti a financovaniu terorizmu a preukázať
              vlastníctvo bankového účtu. Ak <strong> ElateMe s.r.o.. </ Strong> bude mať
              akékoľvek odôvodnené pochybnosti o osobe skutočného majiteľa účtu, alebo
              podozrenie z využitia bankového účtu na financovanie terorizmu alebo legalizáciu
              príjmov z trestnej činnosti, alebo ak odmietne sa Autor zbierky podrobiť
              identifikáciu, nevykoná <strong> ElateMe s.r.o. </ Strong> prevod finančných
              prostriedkov.
            </p>
            <p>
              Nárok Príjemcov zbierok na zaslanie cieľovej sumy je splatný uplynutím 5 dní
              po uplynutí času finančné zbierky (výberu peňažných čiastok Zbierok).
            </p>
            <p>
              Po ukončení finančnej zbierky sú peniaze prevedené na účet Príjemca zbierky.
            </p>
            <p>
              Prevádzkovateľ ani Príjemca zbierky nie sú oprávnení akokoľvek nakladať s
              týmito prostriedkami do doby ukončenia Zbierky.
            </p>
            <p>
              Autori zbierok úplne zodpovedajú za svoje Zbierky, najmä za poskytnutie
              presných a Čestné informačné a splnenie všetkých záväzkov uvedených v
              zápisu akéhokoľvek zbierky, ktorý vytvorí. Prevádzkovateľ nedohliada na kvalitu,
              plnenie ani presnosť akýchkoľvek Zbierok prezentovaných na Webovej stránke.
              Všetky rokovania s ktorýmkoľvek Užívateľom alebo akýmikoľvek tretími osobami
              nájdenými prostredníctvom Služby sú vedené výlučne medzi Autormi zbierky a
              týmito Používateľovi alebo tretími osobami.
            </p>
            <p>
              Prevádzkovateľ nezodpovedá za žiadnu škodu alebo stratu utrpenú v dôsledku
              interakcií medzi Užívateľom, tretími osobami a Autormi zbierok, ani za aktivity
              Autorov zbierok, najmä za dodanie tovarov a služieb a akékoľvek iné podmienky,
              záruky alebo vyhlásenia súvisiace so zoznamami zbierok na Webovej stránke
              Prevádzkovateľa.
            </p>
            <p>
              Prevádzkovateľ má právo kedykoľvek Zbierku zrušiť.
            </p>
            <p>
              <h3>Poplatky a platby spoločnosti <strong> ElateMe s.r.o.. </ Strong></h3>
            </p>
            <p>
              Registrácia a vedenie účtu je zdarma. Prevádzkovateľ si vyhradzuje
              právo podľa potreby a okolností stanoviť nové
              poplatky.
            </p>
            <p>
              <h3>Zbierka - požiadavky na Zbierku</h3>
            </p>
            <p>
              Autor zbierky sa zaväzuje uvádzať ku Zbierke zverejnenom na Webovej stránke
              a ku svojim registračným údajom presné a pravdivé informácie, najmä je
              povinný uvádzať a pravidelne aktualizovať svoje kontaktné údaje a číslo
              bankového účtu, na ktorý majú byť poukázané finančné prostriedky Príjemcom
              zbierky pri úspešnom naplnení cieľovej sumy Zbierky.
            </p>
            <p>
              Používatelia sa zaväzujú, že budú uvádzať číslo bankového účtu, ktorého sú
              majiteľovi a ku ktorému má plné dispozičné právo.
            </p>
            <p>
              <a name="__DdeLink__818_1047398533"></a>
              Autor zbierky môže stanoviť orientačné cieľovú sumu Zbierky. Autor zbierky
              berie na vedomie, že táto cieľová suma nesmie byť v priebehu zverejnenia Zbierky
              z jeho strany menená a zahŕňa všetky náklady súvisiace so Zbierkou,
              bankové poplatky, daň z pridanej hodnoty vo výške podľa platných právnych
              predpisov ku dňu zverejnenia Zbierky. Z týchto dôvodov Prevádzkovateľ odporúča,
              aby si Autor zbierky prepočítal dôkladne všetky náklady za zasielanie odmien a
              ďalšie náklady súvisiace s realizáciou Zbierky.
            </p>
            <p>
              Autor zbierky zverejní opis Zbierky vždy v slovenskom jazyku a ďalej, podľa jeho
              uváženia v ďalších jazykoch.
            </p>
            <p>
              Autor zbierky nie je oprávnený ďalej meniť podmienky zverejnené Zbierky, najmä
              výšku cieľovej sumy a predmet priania.
            </p>
            <p>
              Autorom zbierky zvolený názov Zbierky ani popis Zbierky nesmie obsahovať návrh
              na predaj tovaru alebo služby, ktorá nie je priamo predmetom Zbierky. Autor
              zbierky smie k prezentácii Zbierky pripojiť obrázky, fotografie, videá, hudbu,
              a pod., v takom prípade sa zaväzuje neporušovať pri prezentácii Zbierky
              žiadne autorské práva, práva priemyselná, nepoužívať ochrannej známky v
              vlastníctva tretích osôb bez ich súhlasu a dodržiavať zásady na ochranu
              práv duševného vlastníctva tak, ako je popísané nižšie v Obchodných
              podmienkach. Autor zbierky nie je oprávnený pri opise Zbierky alebo odmeny
              parazitovať na povesti tretích osôb, uvádzať spojenie Zbierky alebo odmien sa
              zavedenými značkami, logami alebo ochrannými známkami tretích osôb, ak k
              tomuto nemá súhlas vlastníka značky, logá alebo ochranné známky.
            </p>
            <p>
              Autor zbierky sa zaväzuje nezverejňovať v popise Zbierky žiadnej nemravné,
              pohoršujúce, rasistické či podobné informácie či materiály. prevádzkovateľ si
              vyhradzuje právo na zrušenie Zbierky, ktorý by akékoľvek takéto informácie alebo
              materiály obsahoval.
            </p>
            <p>
              Autor zbierky berie na vedomie, že Zákazník má v priebehu zverejnenia Zbierky
              právo na odstúpenie od zmluvy, a to až do doby uhradenia sumy na účet
              vedený Prevádzkovateľom.
            </p>
            <p>
              Autor zbierky sa zaväzuje informovať o akejkoľvek zmene v Zbierke ostatné
              Užívateľa, a to zverejnením informácie do popisu príslušnej Zbierky.
            </p>
            <p>
              Autor zbierky sa zaväzuje splniť všetky záväzky voči Užívateľom, ktoré
              uviedol v popise Zbierky, v ktorom došlo k naplneniu cieľovej sumy, najmä so
              zaväzuje dodržať termíny realizácie Zbierky.
            </p>
            <p>
              <h3>vyhlásenie Prevádzkovateľa</h3>
            </p>
            <p>
              Prevádzkovateľ nenesie zodpovednosť za Autorom zbierky zverejnený opis Zbierky.
              Prevádzkovateľ si vyhradzuje právo na odstránenie alebo zmenu popisu Zbierky alebo
              odmeny, ak sa dozvie o tom, že bola zverejnením Zbierky porušená akákoľvek
              práva tretích osôb týkajúce sa najmä práva autorského. prevádzkovateľ
              uvádza, že nie je platformou, ktorá sprostredkováva príspevky, finančné zbierky
              na plnenie konkrétnych želaní.
            </p>
            <h3>Práva duševného vlastníctva spoločnosti <strong> ElateMe s.r.o.. </ Strong></h3>
            <p>
              Všetka práva súvisiace s Webovými stránkami a poskytovanými Službami, a
              akýchkoľvek a všetkých iných technológiách a akomkoľvek obsahu (definovanom
              nižšie), vytvorenom alebo odvodenom na základe vyššie uvedeného, ​​sú vo
              výlučnom vlastníctve Prevádzkovateľa.
            </p>
            <p>
              Užívaním Webových stránok alebo Služieb Prevádzkovateľa súhlasí Užívateľ s
              tým, že Služba obsahuje obsah zvlášť poskytnutý spoločnosťou <strong> ElateMe s.r.o.. </strong>, prípadne jej partnermi, a že tento obsah
              je vo výlučnom vlastníctve spoločnosti <strong> ElateMe s.r.o.. </strong>,
              prípadne jej partnerov a je chránený autorskými právami, ochrannými
              známkami, servisnými značkami, patentmi, obchodnými tajomstvami alebo inými
              zákonom chránenými právami a právnymi predpismi.
            </p>
            <p>
              Obsah Služby zahŕňa najmä videá, zvukové klipy, písomné komentáre z fór,
              informácie, dáta, texty, fotografie, softvér, skripty, grafiku a
              interaktívne funkcie, ktoré sú vytvorené, poskytnuté alebo inak sprístupnené
              Prevádzkovateľom, popr. jej partnermi prostredníctvom Webových stránok alebo
              Služby. Užívateľ sa zaväzuje dodržiavať všetky oznámenia, informácie a
              obmedzenia týkajúce sa autorských práv, ktoré sú uvedené v akomkoľvek obsahu
              prístupnom na Webové stránky alebo Služby.
            </p>
            <p>
              Spoločnosť <strong> ElateMe s.r.o.. </ Strong> udeľuje oprávnenému Používateľovi
              Služby alebo Webových stránok nevýhradnú, nesublicencovatelnou a
              neprevoditeľnú licenciu na používanie, modifikáciu a reprodukciu Obsahu, výlučne
              na účely jeho použitia v súvislosti so Službou. Užívanie, reprodukcie,
              modifikácie, distribúcia alebo uloženie akéhokoľvek Obsahu pre akýkoľvek iný
              účel je výslovne zakázané, ak je bez predchádzajúceho písomného súhlasu
              spoločnosti <strong> ElateMe s.r.o.. </strong> alebo držiteľa autorských práv,
              uvedeného v oznámení o autorských právach, ktorá je súčasťou Obsahu.
            </p>
            <p>
              Užívateľ nesmie (priamo alebo nepriamo) činiť nasledovné:
            </p>
            <ul>
              <li>predávať, licencovať, požičiavať, prenajímať alebo inak používať alebo
                využívať akýkoľvek Obsah pre komerčné účely alebo akýmkoľvek spôsobom,
                ktorý porušuje práva akejkoľvek tretej osoby;
              </li>
              <li>
                dešifrovať, dekompilovať, demontovať, znovu vytvárať alebo sa inak
                pokúšať získať akýkoľvek zdrojový kód alebo hlavné myšlienky alebo
                algoritmy ktorejkoľvek časti Služby;
              </li>
              <li>
                modifikovať, prekladať alebo inak vytvárať odvodené diela z
                ktorejkoľvek časti Služby;
              </li>
              <li>
                kopírovať, požičiavať, prenajímať, distribuovať alebo inak previesť
                akékoľvek práva, ktorá získa na základe týchto Obchodných podmienok.
              </li>
            </ul>
            <h3>Práva duševného vlastníctva Užívateľov</h3>
            <p>
              Služba môže poskytnúť Oprávneným užívateľom možnosť pridávať, vytvárať,
              nahrávať (upload), predkladať, distribuovať, zhromažďovať alebo uverejňovať
              (Ďalej tiež "predkladať") obsah, videá, zvukové klipy, písomné komentáre z
              fór, dáta, texty, fotografie, softvér, skripty, grafiku alebo iné
              informácie na Webové stránky (ďalej spoločne len ako "Užívateľské
              príspevky ").
            </p>
            <p>
              Predložením Užívateľských príspevkov na Webové stránky alebo inak
              prostredníctvom Služby činí Užívateľ nasledujúce:
            </p>
            <ul>
              <li>
                uverejňuje daný Užívateľský príspevok a súhlas, že môže byť
                verejne identifikovaní prostredníctvom emailovej adresy, ako
                registračného údaje, v súvislosti s akýmkoľvek takýmto Užívateľským
                príspevkom;
              </li>
              <li>
                udeľuje Prevádzkovateľovi celosvetovú, nevýhradnú, trvalú,
                neodvolateľnú, bezplatnú, sublicencovatelnou a prevoditeľnú
                licenciu na používanie, editovanie, modifikáciu, reprodukciu, distribúciu,
                spracovanie odvodených diel, propagáciu, realizáciu a inému úplnému
                využívanie Užívateľských príspevkov v súvislosti so Službou a
                obchodnými aktivitami Prevádzkovateľa (a jeho právnych nástupcov),
                vrátane propagácie a redistribúcia celých Webových stránok alebo ich
                časti (a odvodených diel z nich) alebo Služby v akýchkoľvek mediálnych
                formátoch, prostredníctvom akýchkoľvek médií (vrátane webových
                stránok tretích osôb);
              </li>
              <li>
                týmto udeľuje každému Používateľovi Webových stránok alebo Služby
                nevýhradnú licenciu k prístupu k Užívateľským príspevkom
                prostredníctvom Webových stránok a Služby, a na užívanie, editovanie,
                modifikáciu, reprodukciu, distribúciu, spracovanie odvodených diel,
                propagáciu a realizáciu týchto Užívateľských príspevkov výlučne pre
                osobné nekomerčné účely;
              </li>
              <li>
                prehlasuje a zaručuje sa, že (i) vlastní alebo inak ovláda všetka
                práva na všetok obsah svojich Užívateľských príspevkov, alebo že obsah
                týchto Užívateľských príspevkov je verejne prístupný, (ii) že má
                právomoc konať v mene vlastníkov akýchkoľvek práv, vlastníckych
                titulov alebo podielov na akomkoľvek obsahu svojich Užívateľských
                príspevkov, smie užívať tento obsah, a udeliť vyššie uvedené licenčné
                práva, (iii) že má povolenie používať meno a podobu každej
                identifikovateľné fyzické osoby a užívať identifikačné alebo osobné
                údaje tejto osoby, podľa Obchodných podmienok; a že (iv) je oprávnený
                udeliť všetky uvedené práva k Užívateľským príspevkom
                Prevádzkovateľovi a všetkým užívateľom Služby;
              </li>
              <li>
                zaväzuje sa, že užívanie alebo iné využívanie Užívateľských príspevkov
                Prevádzkovateľom a užívanie alebo iné využívanie Webových stránok a
                Služby Užívateľovi, podľa týchto Obchodných podmienok, nenaruší ani
                neporuší práva ktorejkoľvek tretej osoby, najmä akékoľvek práva na
                ochranu súkromia, autorské práva, zmluvné práva alebo akákoľvek iná
                práva duševného vlastníctva, alebo zákonom chránené práva;
              </li>
              <li>
                je uzrozumený a súhlasí s tým, že Prevádzkovateľ má právo vymazať,
                editovať, modifikovať alebo prekladať akékoľvek materiály, obsah alebo
                informácie, ktoré Užívateľ predloží; a že za všetky informácie, ktoré
                sú dostupné alebo súkromne zaslané prostredníctvom Webových
                stránok, výlučne zodpovedá osoba, od ktorej tento obsah pochádza, a že
                Prevádzkovateľ nezodpovedá za akékoľvek chyby alebo opomenutia v
                akomkoľvek obsahu; ďalej že Prevádzkovateľ sa nemôže zaručiť za
                identitu akýchkoľvek iných Užívateľov, s ktorými môžete prísť do
                kontaktu počas užívania Služby;
              </li>
              <li>
                zaväzuje sa, že bude dodržiavať všetky platné miestne, štátne,
                národné i medzinárodné zákony a predpisy.
              </li>
            </ul>
            <p>
              Prevádzkovateľ neschvaľuje ani žiadnym spôsobom nekontroluje akejkoľvek
              Užívateľské príspevky. Prevádzkovateľ sa nemôže zaručiť za hodnovernosť
              akýchkoľvek údajov, ktoré o sebe poskytnú Užívatelia.
            </p>
            <p>
              Užívateľ potvrdzuje, že jeho prístup k všetkým Užívateľským príspevkom za
              Služby je na jeho vlastné riziko, pričom nesiete výlučnú
              zodpovednosť za akúkoľvek škodu alebo stratu, ktorú tým spôsobí ktorejkoľvek
              osobe. Prevádzkovateľ nezaručuje, že ktorýkoľvek Užívateľský príspevok bude
              uverejnený na Webových stránkach alebo prostredníctvom Služby. prevádzkovateľ
              nie je povinný monitorovať Užívateľské príspevky.
            </p>
            <p>
              Prevádzkovateľ si vyhradzuje právo kedykoľvek blokovať, odstrániť, editovať alebo
              modifikovať akékoľvek Užívateľské príspevky z Webových stránok alebo Služby,
              bez predchádzajúceho oznámenia a zdôvodnenie (najmä po obdržaní reklamácií alebo
              obvinenia od tretích osôb alebo príslušných orgánov týkajúcich sa týchto
              Užívateľských príspevkov, alebo ak má Prevádzkovateľ podozrenie, že Užívateľ
              porušil Obchodné podmienky), alebo bez akéhokoľvek dôvodu.
            </p>
            <h3>Zakázané rokovania</h3>
            <p>
              Podmienkou užívania Služby je, že sa Užívateľ zaviaže, že nebude Službu užívať
              pre žiadny účel, ktorý tieto Obchodné podmienky zakazujú. Služba (najmä
              akýkoľvek Obsah alebo Užívateľský príspevok) sa poskytuje iba pre obmedzené
              osobné nekomerčné užívanie (pokiaľ Obchodné podmienky nepovoľujú niečo iné).
            </p>
            <p>
              Používateľ nesmie vykonávať, bez obmedzenia (priamo ani nepriamo), ktorúkoľvek z
              nasledovných aktivít:
            </p>
            <p>
              a) prijať akékoľvek opatrenia alebo nahrávať, načítať, uverejňovať,
              predkladať ani inak distribuovať alebo umožniť distribúciu akéhokoľvek obsahu
              prostredníctvom Služby, najmä akýchkoľvek Užívateľských príspevkov, ktoré:
            </p>
            <ul>
              <li>
                sú nesprávne, zavádzajúce, nepravdivé alebo nepresné;
              </li>
              <li>
                sú nezákonné, výhražné, obťažujúce, hanlivé, klamné, podvodné,
                zasahujúce do súkromia iných osôb, nezákonné, obscénne, urážlivé
                alebo vulgárne;
              </li>
              <li>
                porušujú akýkoľvek patent, ochrannú známku, obchodné tajomstvo,
                autorské právo, právo na publicitu alebo iné právo ktorejkoľvek inej
                fyzickej alebo právnickej osoby, alebo porušujú akýkoľvek zákon alebo
                zmluvnú povinnosť;
              </li>
              <li>
                predstavujú nevyžiadaný alebo neschválený reklamné alebo propagačné
                materiál alebo akýkoľvek reklamné e-mail (junk mail), spam alebo
                reťazový list; alebo
              </li>
              <li>
                obsahujú softvérové ​​vírusy alebo akékoľvek iné počítačové kódy,
                súbory alebo programy, ktorých cieľom je narušenie, poškodenie,
                obmedzenia alebo zasahovanie do riadneho fungovania akéhokoľvek softvéru,
                hardvéru alebo telekomunikačného zariadenia, alebo poškodenie alebo
                získanie neoprávneného prístupu k akémukoľvek systému, dátam, heslu
                alebo iným informáciám spoločnosti <strong> ElateMe s.r.o.. </strong>
                alebo ktorejkoľvek tretej osoby.
              </li>
            </ul>
            <p>
              b) prijať akékoľvek opatrenie, ktoré predstavuje alebo môže predstavovať
              (Podľa rozhodnutia na základe výlučného uváženia Prevádzkovateľa) neprimerané
              alebo neúmerne vysoké zaťaženie infraštruktúry Prevádzkovateľa;
            </p>
            <p>
              c) zasahovať alebo sa pokúšať zasahovať do riadneho fungovania Služby alebo
              akýchkoľvek aktivít vykonávaných v rámci Služby;
            </p>
            <p>
              d) obchádzať akékoľvek opatrenia, ktoré Prevádzkovateľ môže použiť na účel
              prevencie alebo obmedzenia prístupu k Službe (alebo iným účtom, počítačovým
              systémom alebo sieťam pripojeným k Službe);
            </p>
            <p>
              f) používať Mailist, Listserv, auto-responder v akejkoľvek forme, alebo spam v
              rámci Služby; ani
            </p>
            <p>
              g) používať manuál alebo automatizovaný softvér, zariadenia alebo iné postupy
              k nabúranie sa (crawl) alebo zamorenia (spider) akejkoľvek stránky Webových
              stránok.
            </p>
            <p>
              Užívateľ je plne zodpovedný za to, že prevody finančných prostriedkov v
              rámci Zbierky nedochádza k legalizácii výnosov z akejkoľvek trestnej činnosti a
              prostriedky v Zbierke užité nie sú určené na financovanie terorizmu,
              teroristických činov alebo teroristických organizácií.
            </p>
            <h3>Zdroje a webové stránky tretích osôb</h3>
            <p>
              Služba umožňuje Užívateľom pripojiť sa k iným webovým stránkam alebo
              zdrojom na Internete, ktoré nie sú pod kontrolou Prevádzkovateľa, pričom tieto
              iné webové stránky alebo zdroje môžu obsahovať odkazy na Webové stránky.
              Ak Užívateľ vstupuje na webové stránky tretích osôb, robí tak na vlastné
              riziko. Užívaním Webových stránok a Služby Užívateľ potvrdzuje a súhlasí,
              že Prevádzkovateľ nezodpovedá za tovar, služby, obsah, funkcie, presnosť,
              zákonnosť, vhodnosť alebo akýkoľvek iný aspekt týchto webových stránok alebo
              zdrojov tretích osôb (ďalej len Zdroje tretích osôb "). zahrnutie akéhokoľvek
              takéhoto odkazu neznamená schválenie ani pridruženie Prevádzkovateľa k
              akýmkoľvek zdrojov ďalších osôb. Ďalej týmto Užívateľ potvrdzuje a súhlasí,
              že Prevádzkovateľ nezodpovedá priamo ani nepriamo za akúkoľvek škodu alebo stratu
              spôsobenú alebo údajne spôsobenú alebo súvisiace s užívaním akýchkoľvek
              Zdrojov tretích strán Užívateľom.
            </p>
            <h3>Ukončenie prístupu</h3>
            <p>
              Prevádzkovateľ môže kedykoľvek ukončiť prístup Používateľa k Službe alebo akejkoľvek
              jej časti, s uvedením dôvodu alebo bez uvedenia dôvodu, s oznámením alebo bez
              oznámenia, s okamžitou účinnosťou, čo môže viesť k zabavenie a zničenie
              informácií o uskutočnených Redaktorove účtu. Ak si Užívateľ želá
              ukončiť svoj účet, môže tak urobiť splnením pokynov uvedených na Webovej
              stránkach. Akékoľvek poplatky zaplatené na základe tejto zmluvy sú
              nevratné. Všetky ustanovenia Obchodných podmienok, ktoré zo svojej povahy majú
              pretrvať aj po ukončení účtu, platí aj po ukončení účtu, najmä ustanovenia
              týkajúce sa duševného vlastníctva a obmedzenie zodpovednosti.
            </p>
            <h3>Obmedzenie zodpovednosti Prevádzkovateľa</h3>
            <p>
              Užívateľ týmto ďalej potvrdzuje, že Prevádzkovateľ nemá žiadnu kontrolu ani
              žiadnu povinnosť prijať akékoľvek opatrenia ohľadne toho, (i) akí
              používatelia získajú prístup na Webové stránky, (ii) k akému Obsahu alebo
              Užívateľským príspevkom má Užívateľ prístup prostredníctvom Webových
              stránok, (iii) aké dopady na Užívateľa môže mať tento Obsah alebo
              Užívateľské príspevky; (Iv) ako má Používateľ vykladať alebo používať Obsah
              alebo Používateľské príspevky; alebo (v) aké opatrenia môže prijať, ak bude
              Používateľovi sprístupnený Obsah alebo Používateľské príspevky.
            </p>
            <p>
              Prevádzkovateľ je zbavený akejkoľvek zodpovednosti za Užívateľove získanie alebo
              nezískania Obsahu alebo Užívateľských príspevkov prostredníctvom Služby.
              Webové stránky môžu obsahovať alebo odkazovať na webové stránky obsahujúce
              informácie, ktoré niektorí ľudia môžu považovať za urážlivé alebo nevhodné.
              Prevádzkovateľ neposkytuje žiadne vyhlásenie akéhokoľvek Obsahu alebo
              Užívateľských príspevkov obsiahnutých na Webových stránkach alebo
              sprístupnených prostredníctvom týchto Webových stránok. prevádzkovateľ
              nezodpovedá za presnosť, dodržiavanie autorských práv, zákonnosť ani
              decentnosť materiálov obsiahnutých alebo sprístupnených prostredníctvom
              Webových stránok alebo Služby.
            </p>
            <p>
              Prevádzkovateľ nenesie žiadnu zodpovednosť z hľadiska zákona č. 253/2008 Zb. O
              ochrane pred legalizáciou príjmov z trestnej činnosti a nie je
              povinný zisťovať pôvod finančných prostriedkov použitých v rámci Zbierky.
              Zodpovednosť za dodržiavanie príslušných predpisov v súvislosti s legalizáciou
              príjmov z trestnej činnosti nesie výhradne Užívateľ.
            </p>
            <p>
              <h3>Súhlas s elektronickým oznámením</h3>
            </p>
            <p>
              Používaním Služby vyjadruje Užívateľ svoj súhlas s prijímaním akýchkoľvek
              elektronických oznámení od spoločnosti <strong> ElateMe s.r.o.. </strong>,
              najmä oznámenia, zmlúv, právne požadovaných oznámenia (Disclosures) alebo
              iných informácií súvisiacich so Službou (ďalej spoločne len ako
              "Oznámenie"). <Strong> ElateMe s.r.o.. </ Strong> môže poskytnúť táto
              elektronická Oznámenie ich uverejnením na Webovej stránke. Ak si
              Užívateľ želá zrušiť svoj súhlas s elektronickým prijímaním Oznámenia,
              musí túto informáciu zaslať prostredníctvom emailu Prevádzkovateľovi.
            </p>
            <h3>Informácie o mimosúdnom riešení spotrebiteľských sporov</h3>
            <p>
              V súlade s ustanovením § 14 a nasl. zákona č. 634/1992 Zb. v platnom
              znenie, o ochrane spotrebiteľa, týmto Prevádzkovateľ informuje spotrebiteľa,
              že subjekty riešenia mimosúdnych sporov sú:
            </p>
            <ul>
              <li>
                 Všeobecným subjektom pre riešenie spotrebiteľských sporov je Česká
                 obchodná inšpekcia, Štěpánská 567/15, 120 00 Praha 2,          <u><a href="http://www.coi.cz/">www.coi.cz</a></u>.
              </li>
              <li>
                Subjektom pre riešenie sporov v oblasti finančných služieb je Finančné
                arbiter, Legerova 1581/69, 110 00 Praha 1,           <u><a href="http://www.finarbitr.cz/">www.finarbitr.cz</a></u>.
              </li>
              <li>
                Subjektom pre riešenie sporov v oblasti elektronických komunikácií a
                poštových služieb je Český telekomunikačný úrad, Sokolovská 219,
                190 00 Praha 9,  <u><a href="http://www.ctu.cz/">www.ctu.cz</a></u>.
              </li>
            </ul>
            <p>
              <h3>Záverečné ustanovenia</h3>
            </p>
            <p>
              Tieto Obchodné podmienky predstavujú úplnú dohodu medzi Používateľovi (najmä
              Autormi zbierok) a spoločnosťou <strong> ElateMe s.r.o.. </ Strong>, ktoré sa týkajú
              Služieb a užívania Webových stránok, a nahrádzajú všetky predchádzajúce alebo
              súčasná oznámenia a návrhy (či už ústne, písomné alebo elektronické) medzi
              Užívateľom a spoločnosťou <strong> ElateMe s.r.o.. </ Strong>.
            </p>
            <p>
              Ak je ktorékoľvek ustanovenie týchto Obchodných podmienok považované za
              nevymáhateľné alebo neplatné, toto ustanovenie bude vylúčené alebo obmedzené na
              minimálny nevyhnutný rozsah a nemá vplyv na platnosť alebo vymáhateľnosť
              ostatných oddeliteľných ustanovení Obchodných podmienok.
            </p>
            <p>
              Prevádzkovateľ si vyhradzuje právo kedykoľvek pozmeniť alebo upraviť tieto
              Obchodné podmienky, ako aj Zásady ochrany osobných údajov, a to bez udania
              dôvodu. O prípadných zmenách budú Používatelia Webových stránok a Služieb,
              informovaní prostredníctvom uverejnenia oznámenia nie Webovej stránke alebo
              zaslaním oznámenia prostredníctvom emailu. Znenie nových Obchodných podmienok
              či Zásad ochrany osobných údajov je účinné odo dňa ich uverejnenia na
              Webových stránkach Prevádzkovateľa, ak nie je Prevádzkovateľom stanovené inak.
            </p>
            <p>
              Obchodné podmienky a všetky právne vzťahy z týchto Obchodných podmienok
              vyplývajúce sa riadi slovenským právom.
            </p>`,
};
