import * as types from "./actionTypes";

export const initialState =
  typeof window !== "undefined" &&
  window.DATA &&
  window.DATA[types.PRODUCT_REDUCER]
    ? window.DATA[types.PRODUCT_REDUCER]
    : {
        title: "",
        description: "",
        price: "",
        image: "",
        eshop: {},
        status: types.STATUS_EMPTY,
        apiError: false,
      };

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_PRODUCT:
      return Object.assign({}, state, {
        product: payload.item,
        status: types.STATUS_LOADED_FULL,
      });
    case types.SET_LOADING:
      return Object.assign({}, state, {
        wishId: payload.id,
        status: types.STATUS_LOADING,
      });
    case types.SET_STATUS:
      return Object.assign({}, state, { status: payload.item });
    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true });
    default:
      return Object.assign({}, state);
  }
};
