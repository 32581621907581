import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as appActions from "components/App/actions";

export const privateRoute = function (WrappedComponent) {
  class PrivateComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        redirected: false,
      };
    }

    componentWillMount() {
      if (!this.props.user.loggedIn) {
        this.setState({
          redirected: true,
        });
        this.props.actions.addTransition(
          this.props.location.pathname + this.props.location.search
        );
        this.props.history.push("/");
      }
    }

    componentWillReceiveProps(nextProps) {
      if (!nextProps.user.loggedIn) {
        this.setState({
          redirected: true,
        });
        this.props.actions.addTransition(
          this.props.location.pathname + this.props.location.search
        );
        this.props.history.push("/");
      }
    }
    render() {
      const { actions, ...rest } = this.props;
      if (!this.state.redirected) {
        return <WrappedComponent className="test" {...rest} />;
      }
      return null;
    }
  }

  PrivateComponent.propTypes = {
    user: PropTypes.shape({
      loggedIn: PropTypes.bool,
    }).isRequired,
    actions: PropTypes.shape({
      addTransition: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
  };

  function mapStateToProps(state) {
    return {
      user: state.user,
      transitionTo: state.app.transitionTo,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(appActions, dispatch),
    };
  }

  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrivateComponent)
  );
};
