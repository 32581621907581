/**
 * Created by levinrom on 4/6/17.
 */

import * as types from "./actionTypes";

export const initState = {
  changeModeActive: false,
  newGroup: {
    name: "",
    color: "",
    inputOk: false,
  },
  all: [],
  index: -1,
  loading: true,
  apiError: false,
};

export default (state = initState, payload) => {
  switch (payload.type) {
    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true, loading: false });
    case types.GET_GROUPS:
      return Object.assign({}, state, { all: payload.item, loading: false });
    case types.ADD_GROUP_ENTRY:
      return Object.assign({}, state, { all: [...state.all, payload.item] });
    case types.CHANGE_MODE:
      return Object.assign({}, state, {
        changeModeActive: !state.changeModeActive,
      });
    case types.SET_INPUT_OK:
      return Object.assign({}, state, {
        newGroup: {
          name: state.newGroup.name,
          color: state.newGroup.color,
          inputOk: payload.item,
        },
      });
    case types.SET_ACTIVE_GROUP_INDEX:
      return Object.assign({}, state, { index: payload.item });
    case types.CLEAR_NEW_GROUP:
      return Object.assign({}, state, {
        newGroup: {
          name: "",
          color: "",
          inputOk: false,
        },
      });
    case types.PATCH_GROUP:
      return Object.assign({}, state, {
        all: state.all.map((group) =>
          group.id === payload.item.id
            ? {
                ...group,
                members: payload.item.members,
              }
            : group
        ),
      });
    case types.SET_NEW_GROUP_NAME:
      return Object.assign({}, state, {
        newGroup: {
          name: payload.item,
          color: state.newGroup.color,
          inputOk: state.newGroup.inputOk,
        },
      });
    case types.DELETE_GROUP_SUCCESS:
      return Object.assign({}, state, {
        all: state.all.filter((item) => item.id !== payload.groupId),
        index: -1,
      });
    case types.SET_NEW_GROUP_COLOR:
      return Object.assign({}, state, {
        newGroup: {
          name: state.newGroup.name,
          color: payload.item,
          inputOk: state.newGroup.inputOk,
        },
      });
    default:
      return state;
  }
};
