import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";

class Thanks extends Component {
  render() {
    const defaultImage = "img/user-male.svg";
    return (
      <Fragment>
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "thanks.title" })}
        >
          <section className="static-page">
            <div className="container container--narrow">
              <FormattedHTMLMessage id="thanks.title" tagName="h2" />
              <FormattedHTMLMessage id="thanks.description" />
              <ul className="thanks">
                <li className="thanks__item wrap-mobile">
                  <img
                    src="https://www.fit.cvut.cz/sites/default/files/PR/logo_FIT.svg"
                    width="200"
                    className="thanks__photo"
                    alt="Fakulta informačních technologií ČVUT v Praze"
                  />
                  <div className="thanks__content">
                    <h4>
                      <FormattedHTMLMessage id="thanks.fit.title" />
                    </h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.fit.subtitle" />
                    </p>
                  </div>
                </li>

                <li className="thanks__item">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Jiří Chludil"
                  />
                  <div className="thanks__content">
                    <h4>Ing. Jiří Chludil</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.chludil" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Michal Valenta, Ph.D."
                  />
                  <div className="thanks__content">
                    <h4>Ing. Michal Valenta, Ph.D.</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.valenta" />
                    </p>
                  </div>
                </li>

                <li className="thanks__item">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Petr Pauš, Ph.D."
                  />
                  <div className="thanks__content">
                    <h4>Ing. Petr Pauš, Ph.D.</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.paus" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. David Buchtela, Ph.D."
                  />
                  <div className="thanks__content">
                    <h4>Ing. David Buchtela, Ph.D.</h4>
                    <p>
                      <FormattedHTMLMessage id="title.buchtela" />
                    </p>
                  </div>
                </li>

                <li className="thanks__item">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Jan Hoffman"
                  />
                  <div className="thanks__content">
                    <h4>Ing. Jan Hoffman</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.hoffman" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Jindřich Máca"
                  />
                  <div className="thanks__content">
                    <h4>Bc. Jindřich Máca</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.maca" />
                    </p>
                  </div>
                </li>
              </ul>

              <h3>
                <FormattedHTMLMessage id="thanks.oponents.title" />
              </h3>
              <p>
                <FormattedHTMLMessage id="thanks.oponents.subtitle" />
              </p>
              <ul className="thanks">
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="doc. Ing. Mgr. Petr Klán, CSc."
                  />
                  <div className="thanks__content">
                    <h4>doc. Ing. Mgr. Petr Klán, CSc.</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.klan" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="doc. Ing. Tomáš Vitvar, Ph.D."
                  />
                  <div className="thanks__content">
                    <h4>doc. Ing. Tomáš Vitvar, Ph.D.</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.vitvar" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Dana Vynikarová, Ph.D."
                  />
                  <div className="thanks__content">
                    <h4>Ing. Dana Vynikarová, Ph.D.</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.vynikarova" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="JUDr. Zdeněk Kučera, Ph.D."
                  />
                  <div className="thanks__content">
                    <h4>JUDr. Zdeněk Kučera, Ph.D.</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.kucera" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Lukáš Jeschke"
                  />
                  <div className="thanks__content">
                    <h4>Ing. Lukáš Jeschke</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.jeschke" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Vojtěch Jirkovský"
                  />
                  <div className="thanks__content">
                    <h4>Ing. Vojtěch Jirkovský</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.jirkovsky" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Pavel Štěpán"
                  />
                  <div className="thanks__content">
                    <h4>Ing. Pavel Štěpán</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.stepan" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Ing. Jan Blizničenko"
                  />
                  <div className="thanks__content">
                    <h4>Ing. Jan Blizničenko</h4>
                    <p>
                      <FormattedHTMLMessage id="thanks.oponents.bliznicenko" />
                    </p>
                  </div>
                </li>
              </ul>

              <h3>
                <FormattedHTMLMessage id="thanks.mobile-app.title" />
              </h3>
              <p>
                <FormattedHTMLMessage id="thanks.mobile-app.subtitle" />
              </p>

              <ul className="thanks">
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Maksym Balatsko"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Maksym Balatsko</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.balatsko.1" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/69610"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      <FormattedHTMLMessage id="thanks.mobile-app.balatsko.2" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Yevhen Kuzmovych"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Yevhen Kuzmovych</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.kuzmovych" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/69626"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Gleb Arkhipov"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Gleb Arkhipov</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.arkhipov" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/69627"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Yegor Terokhin"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Yegor Terokhin</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.terokhin" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/69650"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Georgii Solovev"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Georgii Solovev</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.solovev" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/69650"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Boris Laskov"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Boris Laskov</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.laskov" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Ilia Liferov"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Ilia Liferov</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.liferov" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/76821"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Tuan Tu Do"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Tuan Tu Do</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.tu-do.1" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/76656"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.mobile-app.tu-do.2" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Tomáš Stanovčák"
                  />
                  <div className="thanks__content">
                    <h5>Tomáš Stanovčák</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.stanovcak" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Tomáš Stanovčák"
                  />
                  <div className="thanks__content">
                    <h5>Lukáš Aron</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.aron" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bich Phuong Pham"
                  />
                  <div className="thanks__content">
                    <h5>Bich Phuong Pham</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.renata" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bich Phuong Pham"
                  />
                  <div className="thanks__content">
                    <h5>Hoang Anh Ngo</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.mobile-app.tony" />
                    </p>
                  </div>
                </li>
              </ul>

              <h3>
                <FormattedHTMLMessage id="thanks.web-app.title" />
              </h3>

              <p>
                <FormattedHTMLMessage id="thanks.web-app.subtitle" />
              </p>

              <ul className="thanks">
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Michal Martínek"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Michal Martínek</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.martinek.1" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/76836"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.web-app.martinek.2" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Michal Černý"
                  />
                  <div className="thanks__content">
                    <h5>Michal Černý</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.cerny" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. David Mokoš"
                  />
                  <div className="thanks__content">
                    <h5>Bc. David Mokoš</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.mokos" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Roman Levinzon"
                  />
                  <div className="thanks__content">
                    <h5>Roman Levinzon</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.levinzon" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Rudolf Rovňák"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Rudolf Rovňák</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.rovnak" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Lukáš Hrachovina"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Lukáš Hrachovina</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.hrachovina" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/76978"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Štěpán Bergl"
                  />
                  <div className="thanks__content">
                    <h5>Štěpán Bergl</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.bergl" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Luka Lukaševič"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Luka Lukaševič</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.lukasevic" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/76811"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Tomáš Grofek"
                  />
                  <div className="thanks__content">
                    <h5>Bc. Tomáš Grofek</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.grofek.1" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/76811"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.web-app.grofek.2" />
                      </a>
                      .
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Bc. Tomáš Grofek"
                  />
                  <div className="thanks__content">
                    <h5>Ing. Michal Šanda</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.sanda.1" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/77483"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedHTMLMessage id="thanks.bachelor-thesis" />
                      </a>
                      <FormattedHTMLMessage id="thanks.web-app.sanda.2" />
                      <a
                        href="https://dspace.cvut.cz/handle/10467/87863"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Návrh heuristických algoritmů v rámci aplikace Wowee
                      </a>
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Tomáš Vybíral"
                  />
                  <div className="thanks__content">
                    <h5>Tomáš Vybíral</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.vybiral" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Peter Bočan"
                  />
                  <div className="thanks__content">
                    <h5>Peter Bočan</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.web-app.bocan" />
                    </p>
                  </div>
                </li>
              </ul>

              <h3>
                <FormattedHTMLMessage id="thanks.partners.title" />
              </h3>
              <p>
                <FormattedHTMLMessage id="thanks.partners.subtitle" />
              </p>

              <ul className="thanks">
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt=""
                  />
                  <div className="thanks__content">
                    <h5>Jiří Votoček</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.partners.votocek" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Jiří Věneček"
                  />
                  <div className="thanks__content">
                    <h5>Jiří Věneček</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.partners.venecek" />
                    </p>
                  </div>
                </li>

                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Jan Šafařík"
                  />
                  <div className="thanks__content">
                    <h5>Jan Šafařík</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.partners.safarik" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Jan Picka"
                  />
                  <div className="thanks__content">
                    <h5>Jan Picka</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.partners.picka" />
                    </p>
                  </div>
                </li>
                <li className="thanks__item col-2">
                  <img
                    src={defaultImage}
                    className="thanks__photo rounded"
                    alt="Dominik Fryč"
                  />
                  <div className="thanks__content">
                    <h5>Dominik Fryč</h5>
                    <p>
                      <FormattedHTMLMessage id="thanks.partners.fryc" />
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </DocumentTitle>
        <Footer lightBackground {...this.props} />
      </Fragment>
    );
  }
}

Thanks.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(Thanks);
