import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as usersActions from "data/users/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import CommentBox from "components/CommentBox";
import ProductImage from "components/ProductImage";
import UserImage from "components/UserImage";
import FormattedHTMLMessage from "services/localization/flex";

import ProgressItem from "../ProgressItem";
import WishDropdownMenu from "../WishDropdownMenu";

class WishComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({
      open: true,
    });
  }

  close() {
    this.setState({
      open: false,
    });
  }

  renderUserImage() {
    if (
      this.props.wish.surprise_wish &&
      this.props.wish.surprise_wish.recipient
    ) {
      const { recipient } = this.props.wish.surprise_wish;
      return (
        <UserImage className="wishes-item__user-avatar" user={recipient} />
      );
    }
    return (
      <UserImage
        className="wishes-item__user-avatar"
        user={this.props.friend}
      />
    );
  }

  renderMoneyInfo() {
    const mineWish = this.props.user.id === this.props.wish.author;
    return <ProgressItem progress={this.props.wish} mineWish={mineWish} />;

    /*
    const deadline = false;
    return (
      <div className={`wishes-item__progress ${!deadline && 'wishes-item__progress--center'}`}>
        <p className="wishes-item__progress-description">
          <FormattedHTMLMessage
            id={mineWish ? 'wish.detail.collected.mine' : 'wish.detail.collected'}
            values={{
              count: this.props.wish.donators_count,
              gathered: this.props.intl.formatNumber(this.props.wish.amount_gathered),
              currency: this.props.wish.currency.toUpperCase(),
            }}
          />
        </p>
        {!!deadline &&
          <p>
            <i className="icon icon--time" />
            <span> {deadline}</span>
          </p>
        }
      </div>
    );

     */
  }

  render() {
    const wishMessage = `wish.detail.surpriseTitle${
      this.props.friend.gender === "F" ? "She" : "He"
    }`;
    return (
      <article
        className={
          this.state.open ? "wishes-item dropdown--open" : "wishes-item"
        }
      >
        <div>
          <header className="wishes-item__info-wrapper">
            <figure className="wishes-item__image">
              <ProductImage wish={this.props.wish} />
            </figure>

            <div className="wishes-item__info">
              <div className="wishes-item__header">
                {this.renderUserImage()}
                <h2 className="wishes-item__recipient">
                  <span className="small">
                    {this.props.wish.surprise_wish &&
                    this.props.wish.surprise_wish.recipient ? (
                      <FormattedHTMLMessage
                        id={wishMessage}
                        values={{
                          author_nominativ: this.props.friend.first_name,
                          recipient: this.props.wish.surprise_wish.recipient,
                        }}
                      />
                    ) : (
                      <FormattedHTMLMessage
                        id="wish.detail.title"
                        values={{
                          name_nominativ: this.props.friend.first_name,
                        }}
                      />
                    )}
                  </span>
                </h2>
              </div>

              <h2 className="wishes-item__title">
                <span className="wishes-item__wish-name">
                  {" "}
                  {this.props.wish.title}{" "}
                </span>
              </h2>
              {this.renderMoneyInfo()}
            </div>
            <Link to={`/wish/detail/${this.props.wish.id}`} className="xlink" />
            <WishDropdownMenu
              author={this.props.friend}
              wishToModify={this.props.wish}
              openConfirmModal={this.props.openConfirmModal}
              closeConfirmModal={this.props.closeConfirmModal}
              open={this.open}
              close={this.close}
              isOpen={this.state.open}
              isFeed={this.props.isFeed}
            />
          </header>

          <CommentBox
            id={this.props.wish.id}
            last_three_comments={this.props.wish.last_three_comments}
            comments_allowed={this.props.wish.is_allowed_to_add_comment}
            detail={false}
            isFeed={this.props.isFeed}
          />
        </div>
      </article>
    );
  }
}

WishComponent.propTypes = {
  wish: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    amount_needed: PropTypes.number,
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      gender: PropTypes.string,
      image: PropTypes.string,
    }),
    date_created: PropTypes.string,
    date_of_expiration: PropTypes.string,
    date_completed: PropTypes.string,
    amount_gathered: PropTypes.number,
    donators_count: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
    wish_state: PropTypes.string,
    isDeleted: PropTypes.bool,
    surprise_wish: PropTypes.shape({
      recipient: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        gender: PropTypes.string,
        image: PropTypes.string,
      }),
    }),
  }).isRequired,
  friend: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
    gender: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  closeConfirmModal: PropTypes.func.isRequired,
  isFeed: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  usersActions: PropTypes.shape({
    updateUsers: PropTypes.func,
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      id: PropTypes.number,
    }),
  }).isRequired,
  defaultUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users.all,
  defaultUser: {
    first_name: state.intl.messages["mock.user.first_name"],
    last_name: state.intl.messages["mock.user.last_name"],
  },
});

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
  };
}

ProgressItem.propTypes = {
  intl: intlShape.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WishComponent));
