import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "../../../actions";

class AmountNeeded extends Component {
  render() {
    return (
      <input
        className="form__field wish-detail__header-description wish-detail__amount"
        type="number"
        min="0"
        style={this.props.style}
        value={this.props.amountNeeded > 0 ? this.props.amountNeeded : null}
        placeholder={
          this.props.amountNeeded > 0
            ? this.props.amountNeeded
            : this.props.intl.formatMessage({
                id: "wish.create.unlimitedValue",
              })
        }
        onChange={(e) => this.props.actions.setAmountNeeded(e.target.value)}
      />
    );
  }
}

AmountNeeded.propTypes = {
  actions: PropTypes.shape({
    setAmountNeeded: PropTypes.func,
  }).isRequired,
  amountNeeded: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

const mapStateToProps = (state) => ({
  amountNeeded: state.wishContainer.wish.amount_needed,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AmountNeeded));
