import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as colorsActions from "./actions";

class ColorSelector extends React.Component {
  componentDidMount() {
    this.props.actions.getColors(this.props.user);

    if (this.props.colors.length) {
      const availableColors = this.props.colors.filter(
        (color) => !this.props.excludeColors.includes(color.code)
      );
      const randomIndex = this.getRandomInt(availableColors.length);
      this.props.setNewGroupColor(availableColors[randomIndex]);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.autoSelect &&
      ((this.props.colors.length !== nextProps.colors.length &&
        nextProps.excludeColors !== null) ||
        (nextProps.colors.length &&
          this.props.excludeColors === null &&
          nextProps.excludeColors !== null))
    ) {
      const availableColors = nextProps.colors.filter(
        (color) => !nextProps.excludeColors.includes(color.code)
      );
      const randomIndex = this.getRandomInt(availableColors.length);
      this.props.setNewGroupColor(availableColors[randomIndex]);
    }
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  forAllColors(item, index) {
    const code = `#${item.code}`;
    return (
      <li key={index} className="dropdown__menu-item">
        <button
          className="no-style"
          onClick={() => this.props.setNewGroupColor(item)}
        >
          <span
            className="friend-lists__badge"
            style={{
              backgroundColor: code,
            }}
          />
        </button>
      </li>
    );
  }

  renderNormal() {
    const style = this.props.selectedColor
      ? {
          backgroundColor: `#${this.props.selectedColor}`,
        }
      : {};
    return (
      <button
        onClick={() => this.props.actions.changeMode()}
        className="no-style"
      >
        <span className="friend-lists__badge dropdown__button" style={style} />
      </button>
    );
  }

  renderActive() {
    return (
      <span
        onClick={() => this.props.actions.changeMode()}
        className="no-style"
      >
        <span className="friend-lists__badge dropdown__button" />
        <ul className="dropdown__menu friend-lists__colors-menu">
          {this.props.colors.map(this.forAllColors, this)}
        </ul>
      </span>
    );
  }

  render() {
    if (this.props.active) {
      return this.renderActive();
    }
    return this.renderNormal();
  }
}

ColorSelector.propTypes = {
  autoSelect: PropTypes.bool,
  setNewGroupColor: PropTypes.func.isRequired,
  selectedColor: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
    })
  ).isRequired,
  excludeColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  actions: PropTypes.shape({
    getColors: PropTypes.func,
    changeMode: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

ColorSelector.defaultProps = {
  autoSelect: false,
  excludeColors: null,
};

const mapStateToProps = (state) => ({
  user: state.user,
  colors: state.friends.groupColors.all,
  active: state.friends.groupColors.selectorActive,
  selectedColor: state.friends.groups.newGroup.color,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(colorsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorSelector);
