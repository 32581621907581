import React from "react";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import * as constants from "../../constants";

class PayingQR extends React.Component {
  constructor() {
    super();

    this.state = {
      clipboardUsed: false,
    };

    this.stepForward = this.stepForward.bind(this);
    this.stepBack = this.stepBack.bind(this);
  }

  stepForward() {
    this.props.actions.setStep(7);
  }

  stepBack() {
    this.props.actions.setStep(2);
  }

  fallbackCopyTextClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (e) {
      console.log("Error occured during copying to clipboard");
    }

    document.body.removeChild(textArea);
  }

  copyToClipBoard(text) {
    this.showMessage();
    if (!navigator.clipboard) {
      this.fallbackCopyTextClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text);
  }

  showMessage() {
    this.setState({
      clipboardUsed: true,
    });
    setTimeout(() => {
      this.setState({
        clipboardUsed: false,
      });
    }, 3000);
  }

  renderSymbolSpecific() {
    if (this.props.user.id) {
      return (
        <div>
          Specifický symbol: <strong>{this.props.user.id}</strong>
          <img
            className="popup__clipboard"
            src="/img/clipboard.svg"
            alt={this.props.intl.formatMessage({
              id: "wish.detail.share.copyUrl",
            })}
            onClick={() => this.copyToClipBoard(this.props.user.id)}
          />
        </div>
      );
    }
    return null;
  }

  getTitle() {
    let donation;

    if (this.props.flat_donation) {
      donation = this.props.flat_donation;
    } else {
      donation = this.props.value;
    }

    return (
      <FormattedMessage
        id="donate.title.flat"
        values={{
          donation,
          currency: this.props.currency.toUpperCase(),
        }}
      />
    );
  }

  render() {
    const currencyUp = this.props.currency.toUpperCase();
    const bankAccount = constants.BANK_ACCOUNT[this.props.currency];
    const bankAccountShort = constants.BANK_ACCOUNT_SHORT[this.props.currency];
    const bankAcc = bankAccountShort.slice(0, bankAccountShort.length - 5);
    let qrValue = `SPD*1.0*ACC:${bankAccount}*AM:${this.props.value}*CC:${currencyUp}*X-VS:${this.props.wishId}`;
    if (this.props.user.id) {
      qrValue = `${qrValue}*X-SS:${this.props.user.id}`;
    }
    return (
      <div className="popup donate-popup donate-payment-method">
        <h3 className="popup__heading">{this.getTitle()}</h3>
        <div className="row spacing-md align-center">
          <QRCode value={qrValue} />
          <h4 className="popup__title">
            <FormattedMessage id="donate.QRCodeMessage" />
            <br />
            <br />
            <FormattedMessage id="donate.bank_account" />{" "}
            <strong>{bankAccountShort}</strong>
            <img
              className="popup__clipboard"
              src="/img/clipboard.svg"
              onClick={() => this.copyToClipBoard(bankAcc)}
              alt={this.props.intl.formatMessage({
                id: "wish.detail.share.copyUrl",
              })}
            />
            <br />
            <FormattedMessage id="donate.variable_symbol" />{" "}
            <strong>{this.props.wishId}</strong>
            <img
              className="popup__clipboard"
              src="/img/clipboard.svg"
              onClick={() => this.copyToClipBoard(this.props.wishId)}
              alt={this.props.intl.formatMessage({
                id: "wish.detail.share.copyUrl",
              })}
            />
            {this.renderSymbolSpecific()}
            <p
              id="clipboard"
              style={{
                visibility: this.state.clipboardUsed ? "visible" : "hidden",
              }}
            >
              <FormattedMessage id="common.copyClipboard" />
            </p>
          </h4>
        </div>
        <div className="popup__footer">
          <button className="button" onClick={this.stepBack}>
            <FormattedMessage id="common.back" />
          </button>
          <button
            className="button button--next fill"
            onClick={this.stepForward}
          >
            <FormattedMessage id="common.next" />
          </button>
        </div>
      </div>
    );
  }
}

PayingQR.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  wishId: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  value: state.donation.donationValue,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PayingQR));
