import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import { setStep } from "components/Containers/MultiStep/actions";

import Tab from "./components/Tab";
import Visibility from "./components/Visibility";

class WishSettings extends Component {
  showRecipient() {
    if (
      this.props.custom_user_name != null &&
      this.props.custom_user_name.length
    ) {
      return this.props.custom_user_name;
    } else if (
      this.props.surprise_wish === null ||
      this.props.surprise_wish.recipient === undefined
    ) {
      return <FormattedMessage id="wish.create.recipientTitleMe" />;
    } else {
      return `${
        this.props.users[this.props.surprise_wish.recipient].first_name
      } ${this.props.users[this.props.surprise_wish.recipient].last_name}`;
    }
  }

  showDeadline() {
    if (!this.props.deadline) {
      return <FormattedMessage id="wish.create.noDeadline" />;
    }

    const date = new Date(this.props.deadline);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  showDonations() {
    if (!this.props.flat_donation) {
      return <FormattedMessage id="wish.create.noDonations" />;
    }

    return `${
      this.props.flat_donation
    } ${this.props.user.currency.toUpperCase()}`;
  }

  showTabs() {
    return (
      <div className="tabs divider">
        <Tab
          onClick={() => this.props.setStep("wish_settings", "recipient")}
          heading={<FormattedMessage id="wish.create.recipientTitle" />}
          desc={this.showRecipient()}
          checkedWhen={this.props.step === "recipient"}
        />
        <Tab
          onClick={() => this.props.setStep("wish_settings", "deadline")}
          heading={<FormattedMessage id="wish.create.deadline" />}
          desc={this.showDeadline()}
          checkedWhen={this.props.step === "deadline"}
          IconComponent={<i className="icon icon--deadline" />}
        />
        <Tab
          onClick={() => this.props.setStep("wish_settings", "donations")}
          heading={<FormattedMessage id="wish.create.donations" />}
          desc={this.showDonations()}
          checkedWhen={this.props.step === "donations"}
          IconComponent={<i className="icon icon--money" />}
        />
      </div>
    );
  }

  render() {
    return (
      <section className="wish-detail__section">
        <h3 className="wish-detail__section-heading">
          <FormattedMessage id="wish.create.giftSettings" />
        </h3>
        <Visibility />
        <p className="text-center small">
          Vytvořením přání potvrzujete souhlas s{" "}
          <a href="/conditions" target="_blank">
            obchodními podmínkami.
          </a>
        </p>
        {/*
        {this.showTabs()}
        <MultiStep
          id="wish_settings"
          steps={{
            recipient: <Recipient />,
            deadline: <Deadline />,
            donations: <Donations />,
          }}
          default="recipient"
        />
      */}
      </section>
    );
  }
}

WishSettings.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    setStep: bindActionCreators(setStep, dispatch),
  };
}

const mapStateToProps = (state) => ({
  deadline: state.wishContainer.wish.date_of_expiration,
  flat_donation: state.wishContainer.wish.flat_donation,
  surprise_wish: state.wishContainer.wish.surprise_wish,
  custom_user_name: state.wishContainer.wish.custom_user_name,
  friends: state.friends.friends.all,
  users: state.users.all,
  user: state.user,
  step: state.multiStep.wish_settings,
  recipient: state.wishContainer.recipient,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WishSettings));
