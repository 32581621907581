/**
 * Created by mi on 24.3.17.
 */

import * as types from "./actionTypes";

export const addTransition = (item) => ({
  type: types.TRANSITION,
  item,
});

export const loading = () => ({
  type: types.LOADING,
});
export const autoload = () => ({
  type: types.AUTOLOAD,
});

export function loaded() {
  return {
    type: types.LOADED,
  };
}
