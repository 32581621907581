import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";

import SignUpFinishForm from "../../components/SignUpFinishForm";

class SignUpFinish extends Component {
  render() {
    return (
      <Fragment>
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "general.sign-up" })}
        >
          <section className="static-page">
            <SignUpFinishForm {...this.props} />
          </section>
        </DocumentTitle>
        <Footer lightBackground {...this.props} />
      </Fragment>
    );
  }
}

SignUpFinish.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(SignUpFinish);
