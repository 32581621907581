import React, { Component } from "react";
import { Link } from "react-router-dom";

class Stranger extends Component {
  render() {
    const { stranger } = this.props;
    return (
      <Link
        to={`/user/${stranger.id}`}
        key={this.props.index}
        className="search__people"
      >
        <img
          className="contacts__user-avatar"
          alt={`${stranger.first_name} ${stranger.last_name}`}
          src={stranger.image}
          onError={(e) => {
            e.target.src =
              stranger.gender === "M"
                ? "/img/user-male.svg"
                : "/img/user-female.svg";
          }}
        />
        <span className="search__people-name">
          {stranger.first_name} <strong>{stranger.last_name}</strong>
        </span>
      </Link>
    );
  }
}

export default Stranger;
