import { WISH_DELETED } from "../../Wish/scenes/Detail/actionTypes";

import * as types from "./actionTypes";
import * as constants from "./constants";

function filterWishes(wishes, toggle) {
  switch (toggle) {
    case constants.TOGGLE_ACTIVE:
      return getActive(wishes);
    case constants.TOGGLE_DELETED:
      return getDeleted(wishes);
    case constants.TOGGLE_ENDED:
      return getEnded(wishes);
    default:
      return [];
  }
}

function getActive(wishes) {
  return wishes.filter((wish) => {
    return (
      wish.wish_state === "open" ||
      wish.wish_state === "default" ||
      wish.wish_state === undefined
    );
  });
}

function getEnded(wishes) {
  return wishes.filter((wish) => {
    return wish.wish_state === "closed" || wish.wish_state === "transfered";
  });
}

function getDeleted(wishes) {
  return wishes.filter((wish) => {
    return wish.wish_state === "deleted";
  });
}

export const initialState = {
  allWishes: [],
  wishes: [],
  activeToggle: constants.TOGGLE_ACTIVE,
  loading: false,
  hasMore: true,
  lastDownloadedPage: 0,
  apiError: false,
  wishState: constants.STATE_OPEN,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_HAS_MORE_WISHES:
      return Object.assign({}, state, {
        hasMore: payload.item,
      });
    case types.SET_WISHES_TO_DISPLAY:
      return Object.assign({}, state, {
        wishes: filterWishes(state.allWishes, payload.item),
        wish_filter: payload.item,
        activeToggle: payload.item,
      });
    case types.SET_WISHES_LAST_PAGE:
      return Object.assign({}, state, {
        lastDownloadedPage: payload.item,
      });
    case types.RECEIVE_MY_WISHES_PAGE: {
      const pages = state.allWishes.slice();
      pages.push(...payload.item);
      return Object.assign({}, state, {
        allWishes: pages,
        loading: false,
        wishes: filterWishes(pages, state.activeToggle),
      });
    }
    case types.SET_WISHES_LOADING:
      return Object.assign({}, state, {
        loading: payload.item,
      });

    case WISH_DELETED: {
      const allExceptDeleted = state.allWishes.filter(
        (wish) => wish.id !== payload.item
      );

      return Object.assign({}, state, {
        allWishes: allExceptDeleted,
        wishes: filterWishes(allExceptDeleted, state.activeToggle),
      });
    }

    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true });
    case types.RESET:
      return Object.assign({}, initialState);
    case types.SET_WISH_STATE:
      return Object.assign({}, state, { wishState: payload.item });
    default:
      return state;
  }
};
