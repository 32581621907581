import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const ErrorBox = (props) => (
  <div
    className={
      props.mainContent
        ? "flex-column align-center justify-center fill"
        : "widget__content"
    }
  >
    <div className="text-center large-text p-md">
      <p className="m-b-md">
        <FormattedMessage id="common.loadingError" />
      </p>
      <p>
        <button onClick={props.onClick} className="button button--primary">
          <FormattedMessage id="common.tryAgain" />
        </button>
      </p>
    </div>
  </div>
);

ErrorBox.propTypes = {
  mainContent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ErrorBox.defaultProps = {
  mainContent: false,
};

export default ErrorBox;
