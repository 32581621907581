import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FriendsList from "./components/FriendsList";
import SearchLoader from "./components/SearchLoader";
import StrangersList from "./components/StrangersList";
import WishesList from "./components/WishesList";
import * as searchActions from "./actions";

class Search extends Component {
  componentDidMount() {
    const query = this.props.match.params.query;
    this.props.searchActions.search(query, this.props.user);
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params !== prevProps.match.params) {
      const query = this.props.match.params.query;
      this.props.searchActions.search(query, this.props.user);
    }
  }
  render() {
    const { friends, strangers, wishes } = this.props.search.results;
    const { loading } = this.props.search;

    if (loading === true) {
      return (
        <React.Fragment>
          <SearchLoader />
        </React.Fragment>
      );
    }

    return (
      <div className="container container--narrow">
        <section className="app-section search">
          <main className="main-section friend-list search__list">
            <FriendsList friends={friends} />
            <StrangersList strangers={strangers} />
          </main>
          <main className="main-section friend-list search__list">
            <WishesList wishes={wishes} />
          </main>
        </section>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    search: state.search,
    user: state.user,
  }),
  (dispatch) => ({
    searchActions: bindActionCreators(searchActions, dispatch),
  })
)(Search);
