import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import UserImage from "components/UserImage";

import TimeRemaining from "../TimeRemaining";

class FriendSidebarComponent extends React.Component {
  render() {
    return (
      <li key={this.props.index} className="upcoming-side-list__item">
        <Link to={`/user/${this.props.friend.id}`} className="no-link">
          <UserImage
            user={this.props.friend}
            className="wishes-item__user-avatar upcoming-side-list__avatar"
          />
          <h4 className="upcoming-side-list__name">
            {this.props.friend.first_name}
          </h4>
          <h5 className="upcoming-side-list__time">
            <TimeRemaining date={this.props.friend.birthday} />
          </h5>
        </Link>
      </li>
    );
  }
}
FriendSidebarComponent.propTypes = {
  friend: PropTypes.shape({
    id: PropTypes.number,
    gender: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    image: PropTypes.string,
    date_of_birth: PropTypes.string,
    birthday: PropTypes.instanceOf(Date),
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  index: PropTypes.number.isRequired,
};
export default FriendSidebarComponent;
