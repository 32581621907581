/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { fetchBitGoApi } from 'services/api/index';
import * as actions from '../../actions';
import * as constants from '../../constants';

class CheckPaying extends React.Component {
  constructor() {
    super();

    this.state = {
      btn: 'I\'ve sent it',
      message: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.changeStep = this.changeStep.bind(this);
  }

  changeStep() {
    this.props.actions.setStep(6);
  }

  handleClick() {

    this.setState({ message: 'Loading...'});
    setTimeout(function() {
      fetchBitGoApi(constants.BT_ACTION_URL, {
        method: 'POST',
        body: JSON.stringify({
          wallet: constants.ELATEME_WALLET_ID,
          address: '',
        }),
      }).then((data) => {
        let bt = data.received / 100000000;
        bt = bt.toFixed(6);
        if (bt >= this.props.BTValue) {
          this.props.post();
          this.props.changeStep();
        } else {
          this.setState({ message: 'We have not received amount yet!' });
          this.setState({ btn: 'Check it again' });
        }
      });
    }.bind(this), 2000);
  }

  render() {
    return (
    <div className="popup donate-popup donate-payment-method">
      <h3 className="popup__heading"><FormattedMessage id="donate.title" /></h3>

      <h4 className="popup__title"><FormattedMessage id="donate.checkingPayment" /></h4>

      <p>
        <FormattedMessage id="donate.addressIdQr" /><br />
        <QRCode value={`bitcoin:${this.props.addressId}?amount=${this.props.BTValue}&label=${this.props.userName}`} />
      </p>
      <p>
        <FormattedMessage id="donate.addressId" /> {this.props.addressId}
      </p>
      <p>
        <FormattedMessage id="donate.bitcoins" /> {this.props.BTValue}
      </p>
      <p>
        {this.state.message}
      </p>
      <p>
        <button onClick={this.handleClick}>
          {this.state.btn}
        </button>
      </p>

      <div className="popup__footer">
        <button className="button" onClick={this.props.actions.stepBack}><FormattedMessage id="common.back" /></button>
        <button className="button button--next fill" onClick={this.changeStep}><FormattedMessage id="common.next" /></button>
      </div>

    </div>
    );
  }
}


CheckPaying.propTypes = {
  post: PropTypes.func.isRequired,
  addressId: PropTypes.string,
  BTValue: PropTypes.number.isRequired,
};

CheckPaying.defaultProps = {
  addressId: '',
};

const mapStateToProps = state => ({
  BTValue : state.donation.BTValue,
});

function mapDispatchToProps(dispatch) {
  return {
    actions : bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckPaying);

