import React from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as types from "../../actionTypes";

import CardRedirection from "./components/CardRedirection/index";
import Donate from "./components/Donate/index";
import Paying from "./components/Paying/index";
import PayingBitcoins from "./components/PayingBitcoins/index";
import PayingCard from "./components/PayingCard/index";
import PayingQR from "./components/PayingQR/index";
import Thanks from "./components/Thanks/index";
import * as donationActions from "./actions";

class Donation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      addressId: "address",
    };

    this.post = this.post.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.resetValues = this.resetValues.bind(this);
  }

  componentDidMount() {
    this.props.actions.setFlatDonation(false);
  }

  setAddress(addr) {
    this.setState({ addressId: addr });
  }

  post() {
    const wishId =
      this.props.status === types.STATUS_LOADED_FULL
        ? this.props.wish.id
        : this.props.restrictedWish.id;
    this.props.actions.postDonation(this.props.user, wishId, this.props.value);
  }

  resetValues() {
    this.props.actions.resetStep();
    this.props.actions.resetBitcoins();
    this.props.actions.resetDonation();
  }

  openModal() {
    this.setState({ modal: true });
  }

  closeModal() {
    this.setState({ modal: false });
    this.resetValues();
  }

  renderComponent() {
    const status = this.props.status;
    if (
      status !== types.STATUS_LOADED_FULL &&
      status !== types.STATUS_LOADED_RESTRICTED
    ) {
      return null;
    }
    let user = this.props.usr;
    if (
      this.props.wish &&
      this.props.wish.surprise_wish &&
      this.props.wish.surprise_wish.recipient
    ) {
      user = this.props.wish.surprise_wish.recipient;
    }
    switch (this.props.step) {
      case 1: {
        return (
          <Donate
            user={user}
            userWish={
              status === types.STATUS_LOADED_FULL ? this.props.wish.title : ""
            }
            maxValue={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.amount_needed -
                  this.props.wish.amount_gathered
                : 10000
            }
            collected={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.amount_gathered
                : 0
            }
            currency={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.currency
                : this.props.restrictedWish.currency
            }
          />
        );
      }

      case 2: {
        return (
          <Paying
            userName={this.props.usr.first_name}
            setAddress={this.setAddress}
            currency={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.currency
                : this.props.restrictedWish.currency
            }
            flat_donation={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.flat_donation
                : this.props.restrictedWish.flat_donation
            }
          />
        );
      }

      case 3: {
        return (
          <PayingBitcoins
            post={this.post}
            addressId={this.state.addressId}
            userName={this.props.usr.first_name}
          />
        );
      }

      case 4: {
        return (
          <PayingQR
            user={this.props.user}
            wishId={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.id
                : this.props.restrictedWish.id
            }
            currency={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.currency
                : this.props.restrictedWish.currency
            }
            flat_donation={
              status === types.STATUS_LOADED_FULL
                ? this.props.wish.flat_donation
                : this.props.restrictedWish.flat_donation
            }
          />
        );
      }

      case 5: {
        return <PayingCard />;
      }

      case 6: {
        return <CardRedirection closeModal={this.closeModal} />;
      }
      case 7: {
        const wish = this.props.wish
          ? this.props.wish
          : this.props.restrictedWish;
        return (
          <Thanks
            donationValue={this.state.value}
            wish={wish}
            closeModal={this.closeModal}
          />
        );
      }
      default:
        break;
    }
    return null;
  }
  render() {
    return (
      <ReactModal
        contentLabel="Donation"
        isOpen={this.state.modal}
        onRequestClose={this.closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        {this.renderComponent()}
        <button
          className={
            this.props.step === 7
              ? "ffancybox-close-small"
              : "fancybox-close-small"
          }
          onClick={this.closeModal}
        />
      </ReactModal>
    );
  }
}

Donation.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,
  usr: PropTypes.shape({
    gender: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    image: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }),
  wish: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    amount_gathered: PropTypes.number,
    amount_needed: PropTypes.number,
    currency: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
  }),
  restrictedWish: PropTypes.shape({
    id: PropTypes.number,
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string,
      image: PropTypes.string, // restricted wish by IMHO nemel mit image
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
    amount_gathered: PropTypes.number,
    amount_needed: PropTypes.number,
    currency: PropTypes.string,
  }),
  actions: PropTypes.shape({
    postDonation: PropTypes.func,
  }).isRequired,
  status: PropTypes.string.isRequired,
};
Donation.defaultProps = {
  usr: {},
  wish: {},
  restrictedWish: {},
};
const mapStateToProps = (state) => ({
  user: state.user,
  wish: state.wishDetail.wish,
  restrictedWish: state.wishDetail.restrictedWish,
  usr: state.wishDetail.friend,
  status: state.wishDetail.status,
  step: state.donation.step,
  value: state.donation.donationValue || 100,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(donationActions, dispatch),
  };
}

// export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Donation);
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Donation);
