import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import Spinner from "components/Spinner";

import * as actions from "../../../../../User/MyWishes/actions";

import MyWish from "./components/MyWish";

class MyWishes extends React.Component {
  componentDidMount() {
    if (this.props.page === 0) {
      this.props.actions.fetchWishesPage(this.props.user, 1);
    }
  }

  forAllWishes(wish) {
    if (wish.images.length === 0) {
      const dumm = {
        image: null,
        id: null,
      };
      wish.images.push(dumm);
    }
    return <MyWish key={wish.id} wish={wish} />;
  }

  render() {
    return (
      <section className="widget">
        <h3 className="widget__heading">
          <Link className="row" to={`/user/${this.props.user.id}`}>
            <span className="fill">
              <FormattedMessage id="myWishes.title" />
            </span>
          </Link>
          <Link className="row" to="/create">
            <span>
              <FormattedMessage id="menu.createWish" />
            </span>
          </Link>
        </h3>
        <div className="widget__content">
          {this.props.error ? (
            <div className="text-center p-md">
              <p className="m-b-sm">
                <FormattedMessage id="common.loadingError" />
              </p>
              <p>
                <a
                  onClick={() => this.componentDidMount()}
                  className="button button--primary"
                >
                  <FormattedMessage id="common.tryAgain" />
                </a>
              </p>
            </div>
          ) : this.props.loading ? (
            <Spinner />
          ) : (
            this.props.user_wishes.slice(0, 5).map(this.forAllWishes, this)
          )}
        </div>
      </section>
    );
  }
}

MyWishes.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  user_wishes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      ammount_needed: PropTypes.number,
      ammount_gathered: PropTypes.number,
      donators_count: PropTypes.number,
      date_created: PropTypes.date,
      date_of_expiration: PropTypes.date,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    fetchWishesPage: PropTypes.func,
  }).isRequired,
  page: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  user_wishes: state.myWishes.allWishes,
  loading: state.myWishes.loading,
  error: state.myWishes.apiError,
  page: state.myWishes.lastDownloadedPage,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWishes);
