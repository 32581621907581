import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import * as userActions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import DonatorsComponent from "components/DonatorsList/components/DonatorsComponent";
import * as wishControllActions from "components/WishControllers/actions";
import FriendButton from "components/WishControllers/WishSettings/components/FriendButton";
import FriendsFilter from "scenes/Friends/components/FriendsFilter";
import * as wishActions from "scenes/Wish/scenes/Detail/actions";
import FormattedHTMLMessage from "services/localization/flex";

import * as settingsActions from "../../scenes/Settings/actions";
import UserImage from "../UserImage";

import * as types from "./actionTypes";

import "react-image-crop/dist/ReactCrop.css";

function getCroppedImg(image, pixelCrop, fileName) {
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      file.name = fileName;
      resolve(file);
    }, "image/jpeg");
  });
}

const defaultCrop = {
  x: 10,
  y: 10,
  width: 80,
  height: 80,
};

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeId: props.wish ? props.wish.user_money_receiver.id : 0,
      activeListAll: props.allFriends,
      activeListAllowed: this.props.wish ? props.wish.allowed_users : [],
      activeList: props.allFriends,
      activeReceiver:
        this.props.surprise_wish &&
        this.props.surprise_wish.recipient != undefined
          ? this.props.surprise_wish.recipient
          : -1,
      src: null,
      crop: {
        x: 10,
        y: 10,
        width: 80,
        height: 80,
      },
      loadedImage: null,
      croppedImage: {
        url: null,
        file: null,
      },
      emailSent: false,
    };
  }

  componentWillMount() {
    this.props.actions.setCurrency(this.props.user.currency);
    this.checkInput(this.props.user.bank_account);
  }

  allDonators(item, index) {
    return (
      <DonatorsComponent
        key={item.id}
        first_name={item.donator && item.donator.first_name}
        last_name={item.donator && item.donator.last_name}
        id={item.donator && item.donator.id}
        image={item.donator && item.donator.image}
        amount={item.amount}
        currency={this.props.user.currency}
        donator_name={item.donator_name}
      />
    );
  }

  handleSuggestionClick(id) {
    this.setState({ activeId: id });
  }

  closeAndShowDonator(donatorId) {
    this.props.closeModal();
    this.context.router.history.push(`/user/${donatorId}`);
  }

  checkInput(text) {
    this.checkNumber(text);
  }

  selectCurrency(event) {
    const currency = event.target.value;
    this.props.actions.setCurrency(currency);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.bankNumber.inputOk) {
      const bankAccount = this.props.bankNumber.text;
      this.props.actions.submitForm(
        bankAccount,
        this.props.user,
        this.props.user.language,
        this.props.currency
      );
    } else if (
      this.props.bankNumber.text != null &&
      !this.props.bankNumber.inputOk
    ) {
      this.props.modalActions.open({
        title: "",
        type: modalTypes.INVALID_ADDRESS,
      });
    }
  }

  checkNumber(text) {
    this.props.actions.setNewBankNr(text);
    this.props.actions.setInputOk(this.validateInput(text));
  }

  handleOnChange(text) {
    this.checkNumber(text);
    this.props.actions.setSuccessUpdate(false);
  }

  validateInput(input) {
    if (!input) return true;
    const len = input.length;
    if (len > 5) {
      for (let i = len - 1; i >= 0; i--) {
        if (i === len - 5 && input[i] !== "/") {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  renderDelete() {
    return (
      <div className="delete-popup-container">
        <div className="popup delete-popup">
          <h3 className="popup__heading">
            <FormattedHTMLMessage id="wish.confirmDelete.title" />
          </h3>

          <h4 className="popup__title">
            <FormattedHTMLMessage id="wish.confirmDelete.question" />
          </h4>

          <div className="popup__footer">
            <button className="button fill" onClick={this.props.closeModal}>
              <FormattedMessage id="common.no" />
            </button>
            <button
              className="button button--delete fill"
              onClick={this.props.confirmAction}
            >
              <FormattedMessage id="common.yes" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderRefund() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="wish.confirmRefund.title" />
          </h3>

          <h4 className="popup__title">
            <FormattedMessage id="wish.confirmRefund.question" />
          </h4>
          <h4 className="popup__subtitle">
            <FormattedMessage id="wish.confirmRefund.description" />
          </h4>

          <div className="popup__footer">
            <button className="button fill" onClick={this.props.closeModal}>
              <FormattedMessage id="common.no" />
            </button>
            <button
              className="button button--delete fill"
              onClick={this.props.confirmAction}
            >
              <FormattedMessage id="common.yes" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderClose() {
    const commission = Math.ceil(this.props.wish.amount_gathered * 0.02);
    const amount = this.props.wish.amount_gathered - commission;

    if (this.props.user.bank_account) {
      return (
        <div className="close-collecion-popup-container">
          <div className="popup close-collecion-popup">
            <h3 className="popup__heading">
              <FormattedMessage id="wish.confirmClose.title" />
            </h3>
            <h4 className="popup__title">
              <FormattedMessage id="wish.confirmClose.question" />
            </h4>

            <div className="popup__footer">
              <button className="button fill" onClick={this.props.closeModal}>
                <FormattedMessage id="common.no" />
              </button>
              <button
                className="button button--next fill"
                onClick={this.props.confirmAction}
              >
                <FormattedMessage id="common.yes" />
              </button>
            </div>
          </div>
        </div>
      );
    }
    let message = "common.save";
    if (this.props.disabled) {
      message = "common.saving";
    } else {
      message = this.props.success_update ? "common.saved" : "common.save";
    }

    const className =
      message !== "common.save" ? "button fill" : "button button--next fill";
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="wish.missingAccountNumber.title" />
        </h3>
        <h4 className="popup__title">
          <FormattedMessage id="wish.missingAccountNumber.description" />
        </h4>
        <div className="form__row">
          <label className="form__label" htmlFor="bank_account">
            <FormattedMessage id="settings.bankAccount.title" />
          </label>
          <input
            type="text"
            placeholder={this.props.intl.formatMessage({
              id: "settings.bankAccount.example",
            })}
            className="form__field"
            onChange={(e) => this.handleOnChange(e.target.value)}
            value={this.props.bankNumber.text}
          />
          <p className="form__description">
            <FormattedMessage id="settings.bankAccount.howTo" />
          </p>
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="currency">
            <FormattedMessage id="settings.selectCurrency" />
          </label>
          <select
            name="currency"
            value={this.props.currency}
            className="form__field"
            onChange={(e) => this.selectCurrency(e)}
          >
            <option value="czk">
              {this.props.intl.formatMessage({ id: "settings.currency.czk" })}
            </option>
            <option value="usd">
              {this.props.intl.formatMessage({ id: "settings.currency.eur" })}
            </option>
            {/*
            <option value="eur"><FormattedMessage id="settings.currency.usd" /></option>
            */}
          </select>
        </div>
        <div className="popup__footer">
          {/*
          <button className="button" onClick={this.props.closeModal} ><FormattedMessage id="common.close" /></button>
          */}
          <button
            className={className}
            onClick={(e) => this.handleSubmit(e)}
            disabled={this.props.success_update}
          >
            <FormattedMessage id={message} />
          </button>
        </div>
      </div>
    );
  }

  renderError() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="modal.error.heading" />
        </h3>
        <h4 className="popup__title">
          {this.props.title ? (
            this.props.title
          ) : (
            <FormattedMessage id="modal.error.title" />
          )}
        </h4>
        <div className="popup__footer">
          <button
            className="button button--delete fill"
            onClick={this.props.closeModal}
          >
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </div>
    );
  }

  renderLoginError() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="modal.error.heading" />
        </h3>
        <h4 className="popup__title">
          {this.props.title ? (
            this.props.title
          ) : (
            <FormattedMessage id="modal.loginError.title" />
          )}
        </h4>
        <div className="popup__footer">
          <button
            className="button button--delete fill"
            onClick={this.props.closeModal}
          >
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </div>
    );
  }

  renderDefault() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="wish.confirm.question" />
        </h3>
        <h4 className="popup__title">{this.props.title}</h4>
        <div className="popup__footer">
          <button className="button" onClick={this.props.closeModal}>
            <FormattedMessage id="common.cancel" />
          </button>
          <button
            className="button button--next fill"
            onClick={this.props.confirmAction}
          >
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </div>
    );
  }

  renderInvalidInput() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="settings.popup.invalidInput.title" />
        </h3>
        <h4 className="popup__title">
          <FormattedMessage id="settings.popup.invalidInput.description" />
        </h4>
        <div className="popup__footer">
          <button
            className="button button--next fill"
            onClick={this.props.closeModal}
          >
            <FormattedMessage id="common.close" />
          </button>
        </div>
      </div>
    );
  }

  renderInformation() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="settings.information.title" />
        </h3>
        <h4 className="popup__title">{this.props.title}</h4>
        <div className="popup__footer">
          <button
            className="button button--next fill"
            onClick={this.props.closeModal}
          >
            <FormattedMessage id="common.close" />
          </button>
        </div>
      </div>
    );
  }

  renderDeleteAccount() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="settings.confirmDeleteAccount.title" />
        </h3>
        <h4 className="popup__title">
          <FormattedMessage id="settings.confirmDeleteAccount.description" />
        </h4>
        <div className="popup__footer">
          <button className="button fill" onClick={this.props.closeModal}>
            <FormattedMessage id="common.no" />
          </button>
          <button
            className="button button--delete"
            onClick={this.props.confirmAction}
          >
            <FormattedMessage id="common.yes" />
          </button>
        </div>
      </div>
    );
  }

  renderRemoveFriend() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedHTMLMessage
            id="settings.friendRemoved.title"
            values={{ name: this.props.friend }}
          />
        </h3>
        <h4 className="popup__title">
          <FormattedHTMLMessage
            id="settings.friendRemoved.description"
            values={{ name: this.props.friend }}
          />
        </h4>
        <div className="popup__footer">
          <button
            className="button button--next fill"
            onClick={this.props.closeModal}
          >
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </div>
    );
  }

  renderShowDonators() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="donate.donators.title" />
          </h3>
          {this.props.donators && (
            <ul>{this.props.donators.map(this.allDonators, this)}</ul>
          )}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.props.closeModal}
            >
              <FormattedMessage id="common.close" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderDeleteFriendList = () => (
    <div className="popup">
      <h3 className="popup__heading">
        <FormattedMessage id="friends.delete.heading" />
      </h3>
      <h4 className="popup__title">
        <FormattedMessage
          id="friends.delete.content"
          values={{ name: <strong>{this.props.listTitle}</strong> }}
        />
      </h4>
      <div className="popup__footer">
        <button onClick={this.props.closeModal} className="button">
          <FormattedMessage id="common.cancel" />
        </button>
        <button
          onClick={this.props.confirmAction}
          className="button button--delete fill"
        >
          <FormattedMessage id="common.confirm" />
        </button>
      </div>
    </div>
  );

  renderAboutWowee() {
    return (
      <div className="popup">
        <h3 className="popup__heading">
          <FormattedMessage id="settings.aboutWowee.title" />
        </h3>
        <p>
          <FormattedMessage id="home.how-it-works" />
        </p>
        <h4>
          <FormattedMessage id="home.how-to-create-wish.title" />
        </h4>
        <p>
          <FormattedMessage id="home.how-to-create-wish" />
        </p>
        <h4>
          <FormattedMessage id="home.cashback.title" />
        </h4>
        <p>
          <FormattedMessage id="home.cashback" />
        </p>
        <p>
          <FormattedMessage id="home.cashback.description" />
        </p>

        <div className="popup__footer">
          <button
            className="button button--next fill"
            onClick={this.props.closeModal}
          >
            <FormattedMessage id="common.close" />
          </button>
        </div>
      </div>
    );
  }

  getPossibleReceivers(user, users) {
    const activeIndex = this.state.activeId
      ? this.state.activeId
      : this.props.wish.user_money_receiver.id;
    const className =
      activeIndex == user
        ? "contacts_item list__item selected"
        : "contacts__item list__item";

    return (
      <li
        key={user}
        onClick={() => this.handleSuggestionClick(user)}
        className={className}
      >
        <UserImage user={users[user]} className="contacts__user-avatar" />
        <span className="contacts__user-name">
          {users[user].first_name} <strong>{users[user].last_name}</strong>
        </span>
      </li>
    );
  }

  setActiveList = (filteredList) => {
    this.props.wish.allowed_users.length
      ? this.setState({ activeListAllowed: filteredList })
      : this.setState({ activeListAll: filteredList });
  };

  confirmModal = () => {
    this.props.wishActions.setMoneyReceiver(
      this.props.user,
      this.props.wish,
      this.state.activeId
    );
    this.props.closeModal();
  };

  renderSetMoneyReceiver() {
    if (
      this.props.wish.allowed_users.length &&
      !this.state.activeListAllowed.length
    ) {
      this.setState({ activeListAllowed: this.props.wish.allowed_users });
    }

    const usersToFilter = this.props.wish.allowed_users.length
      ? this.state.activeListAllowed
      : this.state.activeListAll;

    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="donate.money_receiver" />
          </h3>
          <FriendsFilter
            setActiveFilter={this.setActiveList}
            friends={usersToFilter}
          />
          {!this.props.wish.allowed_users.length && (
            <ul>
              {this.state.activeListAll.map((user) =>
                this.getPossibleReceivers(user, this.props.allUsers)
              )}
            </ul>
          )}
          {this.props.wish.allowed_users && (
            <ul>
              {this.state.activeListAllowed.map((user) =>
                this.getPossibleReceivers(user, this.props.allUsers)
              )}
            </ul>
          )}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.confirmModal}
            >
              <FormattedMessage id="common.select" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleUser = (id) => {
    if (this.props.allowed_users[id]) {
      this.props.wishControllActions.removeAllowedUsers([id]);
    } else {
      this.props.wishControllActions.addAllowedUsers([id]);
    }
  };

  setFilter = (filterList) => {
    this.setState({
      activeList: filterList,
    });
  };

  showAllFriends = (item) => {
    const friend = this.props.allUsers[item];
    const isChecked = !!this.props.allowed_users[item];

    return (
      <FriendButton
        id={item}
        first_name={friend.first_name}
        last_name={friend.last_name}
        image={friend.image}
        onClick={this.handleUser}
        checkedWhen={isChecked}
      />
    );
  };

  renderAllowedUsers() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="settings.allowedUsers.title" />
          </h3>
          <FriendsFilter
            setActiveFilter={this.setFilter}
            friends={this.props.allFriends}
          />
          {this.state.activeList.map(this.showAllFriends)}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.props.closeModal}
            >
              <FormattedMessage id="settings.allowedUsers.close" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleCheck = (id) => {
    this.props.wishControllActions.setSurpriseWish({ recipient: id });
    this.setState({ activeRecipient: id });
  };

  showPossibleRecipients = (item) => {
    const friend = this.props.allUsers[item];
    const isChecked = this.state.activeRecipient === friend.id;

    return (
      <FriendButton
        id={item}
        first_name={friend.first_name}
        last_name={friend.last_name}
        image={friend.image}
        onClick={this.handleCheck}
        checkedWhen={isChecked}
      />
    );
  };

  renderSelectRecipient() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="settings.selectRecipient.title" />
          </h3>
          {this.props.allFriends.map(this.showPossibleRecipients)}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.props.closeModal}
            >
              <FormattedMessage id="common.confirm" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.setState({
            src: reader.result,
          });
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  confirmChangePicture = () => {
    if (this.state.croppedImage.file != null) {
      this.props.userActions.updateUserImage(
        this.props.user.accessToken,
        this.state.croppedImage.file
      );
      this.setState({
        crop: defaultCrop,
      });
    }
    this.props.closeModal();
  };

  onCropChange = (crop) => {
    this.setState({
      crop,
    });
  };

  getCroppedImage = (pixelCrop, image) => {
    const tmpImg = image || this.state.loadedImage;
    getCroppedImg(
      tmpImg,
      pixelCrop,
      `${this.props.user.first_name}_${this.props.user.last_name}.jpg`
    ).then((res) => {
      const blobUrl = URL.createObjectURL(res);
      const newImage = {
        url: blobUrl,
        file: res,
      };
      this.setState({
        croppedImage: newImage,
      });
    });
  };

  handleImageLoaded = (image, crop) => {
    this.getCroppedImage(crop, image);
    this.setState({
      loadedImage: image,
    });
  };

  handleOnCropComplete = (crop, pixelCrop) => {
    this.getCroppedImage(pixelCrop, null);
  };

  renderChangePicture() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="settings.changePicture.title" />
          </h3>
          <input type="file" onChange={this.onSelectFile} />
          {this.state.src && (
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              onChange={this.onCropChange}
              onImageLoaded={this.handleImageLoaded}
              onComplete={this.handleOnCropComplete}
              keepSelection
            />
          )}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.confirmChangePicture}
            >
              <FormattedMessage id="common.confirm" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  sendUserToLogin = () => {
    if (this.props.user.loggedIn) {
      this.props.userActions.logout(this.props.user.accessToken);
    }
    this.props.push("/login");
    this.props.closeModal();
  };

  renderUserLogedOut() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="settings.userLogedOut.title" />
          </h3>
          <h4 className="popup__title">
            <FormattedHTMLMessage id="settings.userLogedOut.description.first" />
          </h4>
          <h4 className="popup__title">
            <FormattedHTMLMessage id="settings.userLogedOut.description.second" />
          </h4>
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.sendUserToLogin}
            >
              <FormattedMessage id="general.login" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderAllowedUsersDetail() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="wish.detail.allowed_users.title" />
          </h3>
          {this.props.wish.allowed_users.map((item) => {
            const friend = this.props.allUsers[item];
            return (
              <li key={item} className="contacts__item list__item">
                <UserImage user={friend} className="contacts__user-avatar" />
                <span className="contacts__user-name">
                  {friend.first_name} <strong>{friend.last_name}</strong>
                </span>
              </li>
            );
          })}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.props.closeModal}
            >
              <FormattedMessage id="common.close" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderPasswordChanged(success) {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="settings.setNewPassword.title" />
          </h3>
          {success ? (
            <h4 className="popup__title">
              <FormattedHTMLMessage id="settings.setNewPassword.passwordChanged" />
            </h4>
          ) : (
            <h4 className="popup__title">
              <FormattedHTMLMessage id="settings.setNewPassword.passwordError" />
            </h4>
          )}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.props.closeModal}
            >
              <FormattedMessage id="common.close" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  sendRegistrationEmail = () => {
    if (this.props.email) {
      this.props.userActions.resendRegistrationEmail(this.props.email);
      this.setState({
        emailSent: true,
      });
      // this.props.closeModal();
    }
  };

  renderResendRegistrationEmail() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          {this.state.emailSent ? (
            <div>
              <h3 className="popup__heading">
                <FormattedMessage id="login.registration.error.title" />
              </h3>
              <h4 className="popup__title">
                <FormattedMessage id="login.registration.sent" />
              </h4>
            </div>
          ) : (
            <div>
              <h3 className="popup__heading">
                <FormattedMessage id="login.registration.error.title" />
              </h3>
              <h4 className="popup__title">
                <FormattedHTMLMessage id="login.registration.error.1" />
              </h4>
              <h4 className="popup__title">
                <FormattedHTMLMessage id="login.registration.error.2" />
              </h4>
              <div className="text-center">
                <button
                  type="button"
                  className="button button--primary"
                  onClick={this.sendRegistrationEmail}
                >
                  <FormattedHTMLMessage id="login.registration.button" />
                </button>
              </div>
            </div>
          )}
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.props.closeModal}
            >
              <FormattedMessage id="common.close" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  logUserOut = (e) => {
    e.preventDefault();
    this.props.userActions.logout();
    this.props.closeModal();
  };

  renderYouAreLogged() {
    return (
      <div className="refund-popup-container">
        <div className="popup refund-popup">
          <h3 className="popup__heading">
            <FormattedMessage id="modal.user_logged.title" />
          </h3>
          <h4 className="popup__title">
            <FormattedHTMLMessage id="modal.user_logged.description" />
          </h4>
          <button
            type="button"
            className="button login-button"
            onClick={this.logUserOut}
          >
            <FormattedMessage id="general.log_out" />
          </button>
          <div className="popup__footer">
            <button
              className="button button--next fill"
              onClick={this.props.goBack}
            >
              <FormattedMessage id="common.back" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderPopup(type) {
    if (type === types.DELETE) {
      return this.renderDelete();
    }
    if (type === types.REFUND) {
      return this.renderRefund();
    }
    if (type === types.CLOSE) {
      return this.renderClose();
    }
    if (type === types.INVALID_ADDRESS) {
      return this.renderInvalidInput();
    }
    if (type === types.ERROR) {
      return this.renderError();
    }
    if (type === types.LOGIN_ERROR) {
      return this.renderLoginError();
    }
    if (type === types.DELETE_ACCOUNT) {
      return this.renderDeleteAccount();
    }
    if (type === types.SHOW_DONATORS) {
      return this.renderShowDonators();
    }
    if (type === types.DELETE_FRIEND_LIST) {
      return this.renderDeleteFriendList();
    }
    if (type === types.REMOVE_FRIEND) {
      return this.renderRemoveFriend();
    }
    if (type === types.USER_MONEY_RECEIVER) {
      return this.renderSetMoneyReceiver();
    }
    if (type === types.INFORMATION) {
      return this.renderInformation();
    }
    if (type === types.ALLOWED_USERS) {
      return this.renderAllowedUsers();
    }
    if (type === types.ALLOWED_USERS_DETAIL) {
      return this.renderAllowedUsersDetail();
    }
    if (type === types.ABOUT_WOWEE) {
      return this.renderAboutWowee();
    }
    if (type === types.SET_RECIPIENT) {
      return this.renderSelectRecipient();
    }
    if (type === types.CHANGE_PICTURE) {
      return this.renderChangePicture();
    }
    if (type === types.LOGED_OUT) {
      return this.renderUserLogedOut();
    }
    if (type === types.PASSWORD_CHANGED) {
      return this.renderPasswordChanged(true);
    }
    if (type === types.PASSWORD_ERROR) {
      return this.renderPasswordChanged(false);
    }
    if (type === types.NOT_ACTIVATED) {
      return this.renderResendRegistrationEmail();
    } else if (type === types.YOU_ARE_LOGGED) {
      return this.renderYouAreLogged();
    }
    return this.renderDefault();
  }

  render() {
    return (
      <ReactModal
        contentLabel="Confirm"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        className={this.props.className}
        overlayClassName={this.props.overlayClassName}
        popups={this.props.popups}
      >
        {this.renderPopup(this.props.type)}
        <button
          className="fancybox-close-small"
          onClick={this.props.closeModal}
        />
      </ReactModal>
    );
  }
}
ConfirmModal.propTypes = {
  actions: PropTypes.shape({
    setCurrency: PropTypes.func,
    setInputOk: PropTypes.func,
    submitForm: PropTypes.func,
    setNewBankNr: PropTypes.func,
    setSuccessUpdate: PropTypes.func,
  }).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmAction: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  bankNumber: PropTypes.shape({}),
  currency: PropTypes.string,
  popups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      donators: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          image: PropTypes.string,
        })
      ),
    })
  ),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

ConfirmModal.defaultProps = {
  confirmAction: () => {},
  type: types.DEFAULT,
  className: "modal",
  overlayClassName: "overlay",
  bankNumber: "",
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingsActions, dispatch),
    wishActions: bindActionCreators(wishActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    wishControllActions: bindActionCreators(wishControllActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    push: bindActionCreators(push, dispatch),
    goBack: bindActionCreators(goBack, dispatch),
  };
}

const mapStateToProps = (state) => ({
  donators: state.donation.donators,
  user: state.user,
  bankNumber: state.settings.bankNumber,
  currency: state.settings.currency,
  disabled: state.settings.apiSubmitting,
  success_update: state.settings.success_update,
  wish: state.wishDetail.wish,
  allUsers: state.users.all,
  allFriends: state.friends.friends.all,
  allowed_users: state.wishContainer.wish.allowed_users,
  suprise_wish: state.wishContainer.wish.suprise_wish,
  intl: state.intl,
  email: state.modal.email,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ConfirmModal));
