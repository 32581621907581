import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import FormattedHTMLMessage from "../../services/localization/flex";

class NotFound extends Component {
  render() {
    return (
      <DocumentTitle
        title={this.props.intl.formatMessage({ id: "notFound.title" })}
      >
        <article className="container error-page">
          <div className="test-class">
            <img src="/img/error.svg" alt="Error" />
            <h2>404</h2>
            <div>
              <h3>
                <FormattedHTMLMessage id="general.error.title" />
              </h3>
              <p>
                <FormattedHTMLMessage id="general.error.subtitle.1" />
                &nbsp;
                <FormattedHTMLMessage id="general.error.subtitle.2" />
              </p>
            </div>
          </div>
        </article>
      </DocumentTitle>
    );
  }
}

NotFound.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(NotFound);
