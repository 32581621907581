import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as notificationsActions from "scenes/Notifications/actions";

class NotificationBadge extends Component {
  constructor(props) {
    super(props);
    this.getCount = this.getCount.bind(this);
  }
  componentDidMount() {
    this.getCount();
    this.summaryTimer = setInterval(this.getCount, 6000);
  }

  componentWillUnmount() {
    if (this.summaryTimer) {
      clearInterval(this.summaryTimer);
    }
  }

  getCount() {
    this.props.notificationsActions.getCount(
      this.props.user,
      this.summaryTimer
    );
  }

  render() {
    const { count } = this.props;
    if (count === 0) {
      return null;
    }
    return <span className="header-user__notification-badge">{count}</span>;
  }
}

NotificationBadge.propTypes = {
  notificationsActions: PropTypes.shape({
    getCount: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
    gender: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  count: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    count: state.notifications.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBadge);
