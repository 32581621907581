import React from "react";
import IntlMessageFormat from "intl-messageformat";
import PropTypes from "prop-types";

import { PAGE_URL } from "components/App/constants";
import locateMessages from "services/localization/cs";
import { getFlexedParams } from "services/localization/flex";

import * as constants from "../../scenes/Wish/scenes/Detail/components/Donation/constants";

// https://www.facebook.com/v2.3/dialog/share_open_graph?action_properties=%7B%22object%22%3A%7B%22og%3Aurl%22%3A%22https%3A%2F%2Fwowee.cz%2Fwish%2Fdetail%2F1864%22%2C%22og%3Atitle%22%3A%7B%22key%22%3Anull%2C%22ref%22%3Anull%2C%22props%22%3A%7B%22id%22%3A%22popup.thanks.share%22%2C%22values%22%3A%7B%22name_dativ%22%3A%22Nikol%20Palov%C3%A1%22%2C%22amount%22%3A50%2C%22currency%22%3A%22CZK%22%2C%22wishTitle%22%3A%22Miss%20Dior%20-%20Parf%C3%A9m%20%E2%80%A2%20DIOR%20%E2%89%A1%20SEPHORA%22%7D%7D%2C%22_owner%22%3Anull%7D%2C%22og%3Adescription%22%3A%22%22%2C%22og%3Aimage%3Awidth%22%3A%222560%22%2C%22og%3Aimage%3Aheight%22%3A%22960%22%2C%22og%3Aimage%22%3A%22https%3A%2F%2Fwowee.cz%2Fimg%2Fplaceholder-gift.png%22%7D%7D&action_type=og.shares&app_id=1225717134199251&channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2FafATJJjxKE6.js%3Fversion%3D43%23cb%3Df3f41962226c238%26domain%3Dwowee.cz%26origin%3Dhttps%253A%252F%252Fwowee.cz%252Ff3ad8391efcb71c%26relation%3Dopener&display=popup&e2e=%7B%7D&fallback_redirect_uri=https%3A%2F%2Fwowee.cz%2Fwish%2Fdetail%2F1864&locale=en_US&mobile_iframe=false&next=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2FafATJJjxKE6.js%3Fversion%3D43%23cb%3Dfb792c3172fc3%26domain%3Dwowee.cz%26origin%3Dhttps%253A%252F%252Fwowee.cz%252Ff3ad8391efcb71c%26relation%3Dopener%26frame%3Df3d004f8b861d5%26result%3D%2522xxRESULTTOKENxx%2522&sdk=joey&version=v2.3
export class Share extends React.Component {
  shareYouDonated(wish, donation) {
    // copywrited
    const params = getFlexedParams(this.props.intl, "popup.thanks.share", {
      name: wish.author,
      donator: donation.donator,
      currency: wish.currency.toUpperCase(),
      amount: donation.amount,
      wishTitle: wish.title,
    });
    const title = this.props.intl.formatMessage(
      { id: "popup.thanks.share" },
      params
    );
    Share.openShareWish(wish, title, wish.description);
  }

  shareNoFriends() {
    const title = this.props.intl.formatMessage({ id: "feed.shareMessage" });
    Share.openShareWindow(
      `${PAGE_URL}/`,
      title,
      "",
      `${PAGE_URL}/img/placeholder-gift.png`
    );
  }

  shareSuccessDonation(wish, donators) {
    const title = this.props.intl.formatMessage(
      { id: "popup.success-collection.shareMessage" },
      { title: wish.title }
    );
    Share.openShareWish(wish, title, wish.decription);
  }

  openShareGotAGift(wish) {
    // copywritted
    const title = this.props.intl.formatMessage(
      { id: "popup.got-a-gift.shareMessage" },
      { title: wish.title }
    );
    const description = `${this.props.intl.formatMessage(
      { id: "popup.got-a-gift.shareMessage" },
      { amount: wish.amount_gathered, currency: wish.currency.toUpperCase() }
    )}\n\n${wish.description}`;
    Share.openShareWish(wish, title, description);
  }

  openShareMadeDonation(wish, _amount) {
    // prispel jsem na darek
    const curr = wish.currency.toUpperCase();
    const title = this.props.intl.formatMessage(
      { id: "donate.share" },
      {
        amount: _amount,
        currency: curr,
        wish_title: wish.title || "Tajné přání",
        donee: wish.author.first_name,
      }
    );
    let description = "";

    if (wish.amount_gathered) {
      description = this.props.intl.formatMessage(
        { id: "donate.share.collected" },
        { amount: wish.amount_gathered, currency: curr }
      );
    }
    if (wish.amount_needed) {
      // const missing = wish.amount_needed - wish.amount_gathered;
      description += this.props.intl.formatMessage({
        id: "donate.share.missing",
        amount: wish.amount_needed,
        currency: curr,
      });
    }

    description += `\n\n${wish.description}`;
    Share.openShareWish(wish, title, description.trim());
  }

  static get_message(id, params) {
    const text = new IntlMessageFormat(locateMessages[id], "cs-CZ");
    return text.format(params);
  }

  static getOGProfile(user, loggedUser) {
    const url = `${PAGE_URL}/user/${user.id}`;
    // const url = `${PAGE_URL}`;
    let key = "friend";
    const image = user.image;

    if (loggedUser && loggedUser.id === user.id) {
      key = "my";
      // image = user.image;
    }
    const msgTitle = `profile.${key}.share.title`;
    const msgDescription = `profile.${key}.share.description`;
    let params = getFlexedParams(null, msgTitle, { name: user });

    const title = this.get_message(msgTitle, params);

    params = getFlexedParams(null, msgDescription, { name: user });
    const description = this.get_message(msgDescription, params);
    return Share.getHeaders(url, title, description, image);
  }

  openShareProfile(user, loggedUser) {
    // pokud sdilim svuj profil, tak obrazek bude wowee, jinak uzivatele
    const ogData = Share.getOGProfile(user, loggedUser);
    Share.share(ogData);
  }

  static getOGRestrictedWish(wish, friend, loggedUser) {
    // const img = wish.images.length > 0 ? wish.images[0].image : null;
    const url = `${PAGE_URL}${constants.WISH_DETAIL_URL}${wish.id}`;
    let title;

    if (wish.is_for_creator) {
      title = `Sbírka na dárek pro ${friend.first_name} ${friend.last_name}`;
    } else {
      title = `${friend.first_name} ${friend.last_name} vytvořil sbírku na dárek svému kamarádovi!`;
    }
    // metatags['og:title'] = `${Object.keys(state).join()}\n\n${Object.values(state).join()}`;
    const description = "Chceš vidět toto přání? Řekni si o přístup!";
    // metatags['og:description'] = Object.keys(state.restrictedWish);

    return Share.getHeaders(url, title, description);
    // if (wish.wish_state === 'open') {
    // TODO - upravit text dle stavu přání - např. že sbírka byla úspěšná (RESTRICTED WISH neposkytuje informaci o wish, tedy ani o stavu)
  }

  /* zde sdili prihlaseny i neprihlaseny uzivatel a muze byt restricted i non restricted */
  shareWish(wish, _friend, loggedUser) {
    let ogData = null;
    let friend = _friend;

    if (wish.visibility === "public") {
      ogData = Share.getOGWish(wish, friend, loggedUser);
    } else {
      if (!friend) {
        if (wish.surprise_wish) {
          friend = wish.surprise_wish.recipient;
          wish.is_for_creator = false;
        } else {
          friend = wish.author;
          wish.is_for_creator = true;
        }
      }
      ogData = Share.getOGRestrictedWish(wish, friend, loggedUser);
    }
    Share.share(ogData);
  }

  static getOGProduct(product) {
    return Share.getHeaders(
      product.url,
      product.title.slice(0, 60),
      product.description.slice(0, 152),
      product.image
    );
  }

  /* pouzije se pro FB sdileni, tedy tam, kde pozorovatel neni prihlasen */
  static getOGWish(wish, friend, loggedUser, isNonRestricted) {
    const img =
      wish.images && wish.images.length > 0 ? wish.images[0].image : null;
    const url = `${PAGE_URL}${constants.WISH_DETAIL_URL}${wish.id}`;
    const endower = wish.surprise_wish
      ? wish.surprise_wish.recipient
      : wish.author;
    const endower_key = wish.surprise_wish ? "friend" : "author";
    let state_key = wish.wish_state === "open" ? "open" : "closed";
    const amount_key = wish.amount_needed ? "amount" : "noamount";
    const visibility = isNonRestricted ? "non-restricted" : "restricted"; // TODO determinovat, zda je ci neni restricted
    let description = "";

    let moneyLeft = 0;
    let message_key = "";
    if (wish.amount_needed) {
      if (wish.amount_gathered < wish.amount_needed) {
        moneyLeft = wish.amount_needed - wish.amount_gathered;
      } else {
        state_key = "closed";
      }
    }

    if (loggedUser && loggedUser.id === wish.author.id) {
      message_key = `general.og.my-wish.${visibility}.${endower_key}-${state_key}-${amount_key}`;
    } else if (loggedUser && isNonRestricted && loggedUser.id === endower.id) {
      message_key = `general.og.for-me-wish.${state_key}-${amount_key}`;
    } else {
      message_key = `general.og.wish.${visibility}.${endower_key}-${state_key}-${amount_key}`;
    }

    if (isNonRestricted) {
      description = wish.description || "";
    } else {
      description = "Chceš vidět toto přání? Řekni si o přístup!";
    }
    const params = getFlexedParams(null, message_key, {
      author: wish.author,
      product: wish.title,
      amount: wish.amount_needed,
      needed: moneyLeft,
      gathered: wish.amount_gathered,
      endower: endower,
    });
    const title = this.get_message(message_key, params);
    return Share.getHeaders(url, title, description.slice(0, 152), img);
  }

  /* ----- private methods ----- */
  static openShareWish(wish, title, description) {
    const url = `${PAGE_URL}${constants.WISH_DETAIL_URL}${wish.id}`;

    let headers;
    if (wish.images && wish.images.length && wish.images[0].image) {
      headers = Share.getHeaders(url, title, description, wish.images[0].image);
    } else {
      headers = Share.getHeaders(url, title, description);
    }
    Share.share(headers);
  }

  static getHeaders(url, title, description, _image) {
    // const image = _image || `${PAGE_URL}/img/placeholder-gift.png`;
    // const image = _image || `${PAGE_URL}/img/logo-fb-post.png`;
    const image = _image || `${PAGE_URL}/img/share-image.png`;

    return {
      "og:url": url,
      "og:title": title,
      "og:description": description,
      // 'og:image:width': '2560',
      // 'og:image:height': '960',
      "og:image": image,
    };
  }

  static share(ogData, _responseCallBack) {
    const responseCallback = _responseCallBack || function (response) {};

    window.FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        display: "popup",
        mobile_iframe: false,
        action_properties: JSON.stringify({
          object: ogData,
        }),
      },
      responseCallback
    );
  }

  static openShareWindow(url, title, description, _image, _responseCallback) {
    Share.share(
      Share.getHeaders(url, title, description, _image),
      _responseCallback
    );
  }
}

Share.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default Share;

// https://wowee.cz/wish/create/settings?title=D%C4%9Btsk%C3%A1%20v%C3%BDbavi%C4%8Dka%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20&amount=0%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20&image=%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20&description=%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20&surprise_wish=
