import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import czechStrings from "react-timeago/lib/language-strings/cs";
import englishStrings from "react-timeago/lib/language-strings/en";
import PropTypes from "prop-types";

import UserImage from "components/UserImage";

import Attachment from "../CommentAttachment/index.jsx";

class CommentComponent extends PureComponent {
  getComment() {
    return this.splitString(this.props.comment, 40);
  }

  splitString(str, length) {
    const words = str.split(" ");
    for (let j = 0; j < words.length; j++) {
      const l = words[j].length;
      if (l > length) {
        let result = [],
          i = 0;
        while (i < l) {
          result.push(words[j].substr(i, length));
          i += length;
        }
        words[j] = result.join(" ");
      }
    }
    return words.join(" ");
  }

  getFormatter() {
    const formatterCzech = buildFormatter(czechStrings);
    const formatterEnglish = buildFormatter(englishStrings);

    return this.props.language === "cze" ? formatterCzech : formatterEnglish;
  }

  render() {
    return (
      <article className="comment">
        {!!this.props.author && (
          <Fragment>
            <Link to={`/user/${this.props.author.id}`} className="no-link">
              <UserImage
                className="comment__author-avatar"
                user={this.props.author}
              />
            </Link>
            <Link to={`/user/${this.props.author.id}`} className="no-link">
              <span className="comment__author">
                {this.props.author.first_name}
              </span>
            </Link>
          </Fragment>
        )}
        <span className="comment__content"> {this.getComment()} </span>

        {Array.isArray(this.props.attachments) &&
          this.props.attachments.map((attachment) => (
            <Attachment key={attachment.url} data={attachment} />
          ))}

        <span className="comment__time">
          {" "}
          <TimeAgo
            date={this.props.date}
            formatter={this.getFormatter()}
          />{" "}
        </span>
      </article>
    );
  }
}

CommentComponent.propTypes = {
  author: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
    gender: PropTypes.string,
    image: PropTypes.string,
  }),
  attachments: PropTypes.array,
  comment: PropTypes.string,
  date: PropTypes.string,
  language: PropTypes.string,
};

CommentComponent.defaultProps = {
  attachments: [],
};

const mapStateToProps = (state) => ({
  language: state.settings.language,
});

export default withRouter(connect(mapStateToProps, null)(CommentComponent));
