import * as types from "./actionTypes";

export const initialState =
  typeof window !== "undefined" &&
  window.DATA &&
  window.DATA[types.WISH_REDUCER]
    ? window.DATA[types.WISH_REDUCER]
    : {
        wishId: "",
        wish: null,
        restrictedWish: null,
        isWaiting: false,
        friend: {},
        access_requests: {},
        isRestricted: false, // can show only restricted version
        status: types.STATUS_EMPTY,
        isDeleted: false,
        apiError: false,
      };

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_WISH:
      return Object.assign({}, state, {
        wish: payload.item,
        status: types.STATUS_LOADED_FULL,
      });
    case types.SET_RESTRICTED_WISH:
      return Object.assign({}, state, {
        restrictedWish: payload.item,
        status: types.STATUS_LOADED_RESTRICTED,
      });
    case types.SET_FRIEND:
      return Object.assign({}, state, { friend: payload.item });
    case types.SET_RESTRICTION:
      return Object.assign({}, state, { isRestricted: payload.item });
    case types.SET_LOADING:
      return Object.assign({}, state, {
        wishId: payload.id,
        status: types.STATUS_LOADING,
      });
    case types.SET_STATUS:
      return Object.assign({}, state, { status: payload.item });
    case types.RESET:
      return Object.assign({}, initialState);
    case types.WISH_DELETED:
      return Object.assign({}, state, { isDeleted: true });
    case types.API_ERROR:
      return Object.assign({}, state, { apiError: true });
    case types.SET_ACCESS_REQUESTS:
      return Object.assign({}, state, { access_requests: payload.item });
    case types.IS_WAITING:
      return Object.assign({}, state, { isWaiting: true });
    default:
      return Object.assign({}, state);
  }
};
