import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import FriendsList from "./FriendsList";
import HelperLoader from "./HelperLoader";
import OthersList from "./OthersList";
import WishesList from "./WishesList";

class Helper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.search.loading,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search.loading !== this.props.search.loading) {
      this.setState({ loading: this.props.value });
    }
  }

  render() {
    const { friends, wishes, strangers } = this.props.search.results;

    const filterFirstThree = (array) => {
      return array.filter((items, idx) => idx < 3);
    };

    if (this.state.loading === true) {
      return <HelperLoader />;
    }

    return (
      <div className="search-helper">
        <div className="search-helper__wrapper">
          <h1 className="search-helper__heading">
            <FormattedMessage id="common.friends" />
          </h1>
          <FriendsList list={filterFirstThree(friends)} />
        </div>
        <div className="search-helper__wrapper">
          <h1 className="search-helper__heading">
            <FormattedMessage id="common.users" />
          </h1>
          <OthersList list={filterFirstThree(strangers)} />
        </div>
        <div className="search-helper__wrapper">
          <h1 className="search-helper__heading">
            <FormattedMessage id="common.wishes" />
          </h1>
          <WishesList list={filterFirstThree(wishes)} />
        </div>
      </div>
    );
  }
}

export default Helper;
