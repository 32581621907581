import * as types from "./actionTypes";

const initialState = {
  isOpen: false,
  title: "",
  type: types.DEFAULT,
  email: null,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.OPEN:
      return Object.assign({}, state, {
        title: payload.item.title,
        type: payload.item.type,
        isOpen: true,
        email: payload.item.email,
      });
    case types.CLOSE:
      return Object.assign({}, state, { isOpen: false });
    default:
      return state;
  }
};
