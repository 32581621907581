import React, { Component } from "react";
import {
  FormattedHTMLMessage as OriginalFormattedHTMLMessage,
  FormattedMessage as OriginalFormattedMessage,
  injectIntl,
  intlShape,
} from "react-intl";
import PropTypes from "prop-types";

import locateMessages from "services/localization/cs";

function getPlaceholders(str) {
  const regex = /\{(\w+)\}/g;
  const result = [];
  let match;
  while (match = regex.exec(str)) { // eslint-disable-line
    result.push(match[1]);
  }

  return result;
}

function getCauseSuffix(placeholder) {
  const causes = [
    "nominativ",
    "genitiv",
    "dativ",
    "akuzativ",
    "vokativ",
    "lokal",
    "instrumental",
    "posesiv",
    "pronoun",
  ];
  const parts = placeholder.split("_");
  const suffix = parts[parts.length - 1];
  if (causes.indexOf(suffix) > -1) {
    return suffix;
  }
  return null;
}

export function getNameCause(user, cause, firstNameOnly = false) {
  const firstName = user.causes_first_name
    ? user.causes_first_name[cause]
    : user.first_name;
  const lastName = user.causes_last_name
    ? user.causes_last_name[cause]
    : user.last_name;

  if (firstNameOnly) {
    return `${firstName}`;
  }
  return `${firstName} ${lastName}`;
}

export function getFlexedParams(
  intl,
  messageId,
  params,
  firstNameOnly = false
) {
  if (!params) {
    return {};
  }
  const out = {};
  const message = locateMessages[messageId];
  const placeholders = getPlaceholders(message);
  let placeholder;
  let object_key;
  for (let i = 0; i < placeholders.length; i++) {
    placeholder = placeholders[i];
    const cause = getCauseSuffix(placeholder);
    // console.log("CAUSE1", cause);
    if (cause) {
      // placeholder musime sklonit a uz vime, jaky je, proto z placeholderu odstranime suffix
      object_key = placeholder.replace(`_${cause}`, "");

      const object = params[object_key];
      if (object) {
        // console.log("CAUSE3", object, cause);
        if (cause === "posesiv" && object.gender) {
          out[placeholder] = object.gender === "M" ? "jeho" : "její";
        } else if (cause === "nomina" && object.gender) {
          out[placeholder] = object.gender === "M" ? "" : "a";
        } else if (cause === "pronoun" && object.gender) {
          out[placeholder] = object.gender === "M" ? "mu" : "jí";
        } else {
          out[placeholder] = getNameCause(object, cause, firstNameOnly);
        }
      } else {
        // console.log("CAUSE4", placeholder, params[placeholder], params);
        out[placeholder] = params[placeholder];
      }
    } else {
      // console.log("CAUSE2", placeholder, params[placeholder], params);
      out[placeholder] = params[placeholder];
    }
  }
  return out;
}

export function formattedMessage(
  intl,
  messageId,
  params,
  firstNameOnly = false
) {
  const flexedParams = getFlexedParams(intl, messageId, params, firstNameOnly);
  return <OriginalFormattedMessage id={messageId} values={flexedParams} />;
}

class FormattedHTMLMessage extends Component {
  render() {
    const flexedParams = getFlexedParams(
      this.props.intl,
      this.props.id,
      this.props.values
    );
    return (
      <OriginalFormattedHTMLMessage id={this.props.id} values={flexedParams} />
    );
  }
}

FormattedHTMLMessage.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.object,
};

FormattedHTMLMessage.defaultProps = {
  values: {},
};

export default injectIntl(FormattedHTMLMessage);
