import { fetchApi } from "services/api/";

import * as types from "./actionTypes";

export const setProduct = (item) => ({
  type: types.SET_PRODUCT,
  item,
});

export const setStatus = (item) => ({
  type: types.SET_STATUS,
  item,
});

export const setLoading = (id) => ({
  type: types.SET_LOADING,
  id,
});

export function getProduct(id) {
  return (dispatch) => {
    dispatch(setLoading(id));
    const address = `/eshops/product/${id}`;
    console.log(address);
    const options = { method: "GET" };

    const request = fetchApi(address, options);
    return request
      .then((data) => {
        dispatch(setProduct(data));
      })
      .catch((error) => {
        if (error.errorCode === 404) {
          dispatch(setStatus(types.STATUS_NOT_FOUND));
        } else {
          dispatch(setStatus(types.STATUS_ERROR_FULL));
        }
      });
  };
}
