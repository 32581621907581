import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";
import * as actions from "components/WishControllers/actions";
import FormattedHTMLMessage from "services/localization/flex";

import SearchIcon from "./SearchIcon";

class UserMoneyReceiverSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      searchText: "",
      selectedId:
        this.props.user_money_receiver === -1
          ? 0
          : this.props.user_money_receiver,
      highlightedId: 0,
    };
  }

  handleSearchFocus = () => {
    this.setState(
      (prevState) => {
        const { users } = this.props;
        const filteredFriendIds = prevState.searchText
          ? Object.keys(users).filter((friendId) =>
              `${users[friendId].first_name} ${users[friendId].last_name}`.match(
                new RegExp(prevState.searchText, "i")
              )
            )
          : Object.keys(users);

        return {
          isFocused: true,
          highlightedId: filteredFriendIds[0],
        };
      },
      () => this.input.select()
    );
  };

  handleSearchBlur = (evt) => {
    setTimeout(() => {
      this.setState((prevState) => ({
        isFocused: false,
      }));
    }, 200);
  };

  handleSuggestionClick = (selectedId) => {
    const parsedId = parseInt(selectedId, 10);

    this.setState({
      selectedId,
      searchText:
        selectedId > 0
          ? `${this.props.users[selectedId].first_name} ${this.props.users[selectedId].last_name}`
          : selectedId === -1
          ? this.state.searchText
          : "",
    });
    this.props.actions.setUserMoneyReceiver(
      parsedId > 0 ? parsedId : this.props.user.id
    );
  };

  handleSearchChange = (evt) => {
    const { users } = this.props;
    const { searchText } = this.state;
    const newValue = evt.target.value;
    const filteredFriendIds = searchText
      ? Object.keys(users).filter((friendId) =>
          `${users[friendId].first_name} ${users[friendId].last_name}`.match(
            new RegExp(newValue, "i")
          )
        )
      : Object.keys(users);

    this.setState((prevState) => ({
      searchText: newValue,
      highlightedId:
        newValue && filteredFriendIds.length ? filteredFriendIds[0] : 0,
    }));
  };

  handleKeyDown = (evt) => {
    const { users } = this.props;
    const { searchText } = this.state;
    const filteredFriendIds = searchText
      ? Object.keys(users).filter((friendId) =>
          `${users[friendId].first_name} ${users[friendId].last_name}`.match(
            new RegExp(this.state.searchText, "i")
          )
        )
      : Object.keys(users);
    const selectedIndex = filteredFriendIds.indexOf(this.state.highlightedId);
    const nextId =
      selectedIndex + 1 < filteredFriendIds.length
        ? filteredFriendIds[selectedIndex + 1]
        : searchText
        ? filteredFriendIds[0]
        : 0;
    const prevId =
      selectedIndex > 0
        ? filteredFriendIds[selectedIndex - 1]
        : searchText || selectedIndex < 0
        ? filteredFriendIds[filteredFriendIds.length - 1]
        : 0;

    switch (evt.keyCode) {
      case 38:
        this.setState({ highlightedId: prevId });
        evt.preventDefault();
        break;
      case 40:
        this.setState({ highlightedId: nextId });
        evt.preventDefault();
        break;
      case 13: {
        const parsedId = parseInt(this.state.highlightedId, 10);
        this.props.actions.setUserMoneyReceiver(
          parsedId > 0 ? parsedId : this.props.user.id
        );
        this.setState((prevState) => ({
          selectedId: prevState.highlightedId,
          searchText: this.props.users[prevState.highlightedId]
            ? `${this.props.users[prevState.highlightedId].first_name} ${
                this.props.users[prevState.highlightedId].last_name
              }`
            : "",
          highlightedId: 0,
        }));
        this.input.blur();
        this.handleSearchBlur(evt);
        this.props.onConfirm();
        break;
      }
      default:
    }
  };

  render() {
    const { users } = this.props;
    const { highlightedId, searchText, isFocused } = this.state;
    let { selectedId } = this.state;
    const filteredFriendIds = searchText
      ? Object.keys(users).filter((friendId) =>
          `${users[friendId].first_name} ${users[friendId].last_name}`.match(
            new RegExp(searchText, "i")
          )
        )
      : Object.keys(users);

    this.props.user_money_receiver &&
    this.props.user_money_receiver > 0 &&
    this.props.user_money_receiver != this.props.user.id
      ? (selectedId = this.props.user_money_receiver)
      : (selectedId = 0);

    return (
      <div className={`wish-search__wrapper ${isFocused ? "focus" : ""}`}>
        <input
          type="text"
          className={`form__field wish-search__field wish-search__field--donee ${
            selectedId ? "wish-search__field--hidden" : ""
          }`}
          value={searchText}
          onChange={this.handleSearchChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleSearchFocus}
          onBlur={this.handleSearchBlur}
          ref={(input) => (this.input = input)}
        />
        <SearchIcon />
        {selectedId > 0 ? (
          <div className="wish-search__selection">
            <img
              src={users[selectedId].image}
              className="wish-search__avatar wish-search__avatar--small"
              alt=""
            />
            <strong>
              {users[selectedId].first_name} {users[selectedId].last_name}
            </strong>
          </div>
        ) : (
          <div className="wish-search__selection wish-search__selection--empty">
            <strong>
              {!searchText &&
                this.props.intl.formatMessage({
                  id: "wish.create.money_receiver.me",
                })}
            </strong>
          </div>
        )}
        {this.state.isFocused && (
          <ul className="wish-search__suggestions">
            {!searchText && (
              <li
                onClick={() => this.handleSuggestionClick(0)}
                className={`wish-search__suggestion wish-search__suggestion--no-image ${
                  highlightedId === 0 ? "wish-search__suggestion--selected" : ""
                }`}
              >
                <FormattedHTMLMessage id="wish.create.money_receiver.me.placeholder" />
              </li>
            )}
            {filteredFriendIds.map((userId) => (
              <li
                key={userId}
                onClick={() => this.handleSuggestionClick(userId)}
                className={`wish-search__suggestion ${
                  highlightedId === userId
                    ? "wish-search__suggestion--selected"
                    : ""
                }`}
              >
                <UserImage
                  className="wish-search__avatar"
                  user={users[userId]}
                />
                {users[userId].first_name}{" "}
                <strong>{users[userId].last_name}</strong>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

UserMoneyReceiverSelector.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users.all,
  user_money_receiver: state.wishContainer.wish.user_money_receiver,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UserMoneyReceiverSelector));
