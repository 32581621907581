import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi } from "services/api/";

import * as types from "./actionTypes";

export const fetchFriends = (item) => ({
  type: types.FETCH_FRIENDS,
  item,
});

export const setActiveList = (item) => ({
  type: types.GET_FRIENDS,
  item,
});

export const setFriend = (item) => ({
  type: types.GET_FRIEND_BY_ID,
  item,
});

export const setFilter = (item) => ({
  type: types.SET_FILTER_INPUT,
  item,
});

export const changeFilterMode = (item) => ({
  type: types.CHANGE_FILTER_MOD,
  item,
});

export const setFriendsInfo = (item) => ({
  type: types.SET_FRIEND_INFO,
  item,
});

export const clearList = () => ({
  type: types.CLEAR_LIST,
  item: null,
});

export const fetchRequests = (item) => ({
  type: types.GET_REQUESTS,
  item,
});

export const setRequestedFriendships = (item) => ({
  type: types.GET_USER_REQUESTS,
  item,
});

export const setLastFiveUsers = (item) => ({
  type: types.GET_LAST_FIVE_USERS,
  item,
});

export function getUserFriends(user) {
  return (dispatch) => {
    const promise = authFetchApi("/account/friends/", user.accessToken, {
      method: "GET",
    });
    promise.then((data) => {
      const friends = [];
      let friend = null;
      for (let i = 0; i < data.length; i++) {
        friend = data[i].friend;
        friend["friendship_id"] = data[i]["id"];
        friend["is_custom"] = data[i]["is_custom"];
        friend["state"] = data[i]["state"];
        friends.push(friend);
      }
      dispatch(fetchFriends(friends));
    });
    return promise;
  };
}

export function getFriendshipRequests(user) {
  return (dispatch) => {
    authFetchApi("/account/friends/confirm/", user.accessToken, {
      method: "GET",
    }).then((data) => {
      dispatch(fetchRequests(data));
    });
  };
}

export function fetchFriendInfo(id, index, user) {
  return (dispatch) => {
    authFetchApi(`/account/${id}/`, user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        const item = {
          i: index,
          friend: data,
        };
        dispatch(setFriendsInfo(item));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
export function getFriendsById(members, friends) {
  const newList = [];
  return (dispatch) => {
    for (let j = 0; j < members.length; j += 1) {
      for (let i = 0; i < friends.length; i += 1) {
        if (friends[i] === members[j]) {
          newList.push(friends[i]);
        }
      }
    }
    dispatch(setActiveList(newList));
  };
}

export function handleRequest(request_id, state, user) {
  return (dispatch) => {
    const form = { state };
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    return authFetchApi(
      `/account/friends/confirm/${request_id}/`,
      user.accessToken,
      options
    )
      .then((json) => {
        dispatch(getFriendshipRequests(user));
        dispatch(getUserFriends(user));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function declineFriendshipRequest(user, request_id) {
  return (dispatch) => {
    return authFetchApi(`/account/friends/${request_id}/`, user.accessToken, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(getRequestedFriendships(user));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function sendFriendshipRequest(user, future_friend) {
  return (dispatch) => {
    const form = { state: "waiting" };
    form.user = user.id;
    form.friend = future_friend.id;
    form.state = "waiting";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    return authFetchApi("/account/friends/request/", user.accessToken, options)
      .then((data) => {
        dispatch(getRequestedFriendships(user));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function removeFromFriends(user, friendship_id) {
  return (dispatch) => {
    return authFetchApi(`/account/friends/${friendship_id}/`, user.accessToken, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(getUserFriends(user));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function getRequestedFriendships(user) {
  return (dispatch) => {
    authFetchApi("/account/friends/request/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(setRequestedFriendships(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function getLastFiveUsers(user) {
  return (dispatch) => {
    authFetchApi("/account/friends/suggest/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        const lastFiveUsers = data.slice(0, 5);
        dispatch(setLastFiveUsers(lastFiveUsers));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
