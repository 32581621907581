export const GET_GROUPS = "user/friends/groups/groups";
export const POST_NEW_GROUP = "user/friends/groups/NewFriendGroup";
export const DELETE_GROUP = "user/friends/groups/DeleteFriendGroup";
export const DELETE_GROUP_SUCCESS =
  "user/friends/groups/DeleteFriendGroupSuccess";
export const ADD_GROUP_ENTRY = "user/friends/groups/addNewGroupToList";
export const PATCH_GROUP = "user/friends/groups/id/";
export const CHANGE_MODE = "user/friends/groups/ChangeMode";
export const SET_ACTIVE_GROUP_INDEX = "user/friends/groups/setActiveIndex";
export const CLEAR_NEW_GROUP = "user/friends/groups/clearNewGroupInfo";
export const SET_NEW_GROUP_NAME = "user/friends/groups/setNewGroupName";
export const SET_NEW_GROUP_COLOR = "user/friends/groups/setNewGroupColor";
export const SET_INPUT_OK = "user/friends/groups/setInputOk";
export const API_ERROR = "user/friends/groups/API_ERROR";
