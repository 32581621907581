import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "scenes/Notifications/actions";

import CardDonationFailed from "./components/CardDonationFailed";
import PopupGotAGift from "./components/PopupGotAGift";
import PopupSuccessDonation from "./components/PopupSuccessDonation";
import PopupYouDonate from "./components/PopupYouDonate";

class PopupContainer extends React.Component {
  getPopup() {
    if (this.props.popups[0].notification_type === "got-a-gift") {
      return (
        <PopupGotAGift
          popup={this.props.popups[0]}
          closeModal={this.closeModal}
          closeModalAndDelay={this.closeModalAndDelay}
          closingPopup={this.props.closingPopup}
        />
      );
    } else if (this.props.popups[0].notification_type === "you-donate") {
      return (
        <PopupYouDonate
          popup={this.props.popups[0]}
          closeModal={this.closeModal}
          closeModalAndDelay={this.closeModalAndDelay}
          closingPopup={this.props.closingPopup}
        />
      );
    } else if (
      this.props.popups[0].notification_type === "card-donation-failed"
    ) {
      return (
        <CardDonationFailed
          popup={this.props.popups[0]}
          closeModal={this.closeModal}
          closeModalAndDelay={this.closeModalAndDelay}
          closingPopup={this.props.closingPopup}
        />
      );
    }
    return (
      <PopupSuccessDonation
        popup={this.props.popups[0]}
        closeModal={this.closeModal}
        closeModalAndDelay={this.closeModalAndDelay}
        closingPopup={this.props.closingPopup}
      />
    );
  }
  closeModal = () => {
    if (!this.props.closingPopup && this.props.popups.length > 0) {
      this.props.actions.closeModal(this.props.user, this.props.popups[0]);
    }
  };

  closeModalAndDelay = () => {
    localStorage.setItem("delayPopup", 10);
    this.closeModal();
  };
  renderPopup() {
    let delay = parseInt(localStorage.getItem("delayPopup"), 10) || 0;
    if (delay > 0) {
      delay--;
      localStorage.setItem("delayPopup", delay);
      return null;
    }
    const popup = this.getPopup();
    return (
      <Modal
        isOpen
        onRequestClose={this.closeModal}
        className="modal modal--main"
        overlayClassName="overlay overlay--main"
      >
        {popup}
      </Modal>
    );
  }
  render() {
    if (this.props.user.loggedIn && this.props.popups.length > 0) {
      return this.renderPopup();
    }
    return null;
  }
}

PopupContainer.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }),
  popups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      notification_type: PropTypes.string.isRequired,
      wish: PropTypes.shape({
        id: PropTypes.number,
        author: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
          image: PropTypes.string,
        }),
        title: PropTypes.string,
        description: PropTypes.string,
        amount_needed: PropTypes.number,
        date_created: PropTypes.string,
        date_of_expiration: PropTypes.string,
        date_completed: PropTypes.string,
        amount_gathered: PropTypes.number,
        donators_count: PropTypes.number,
        currency: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ),
        wish_state: PropTypes.string.isRequired,
        surprise_wish: PropTypes.shape({
          recipient: PropTypes.shape({
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            causes_first_name: PropTypes.shape({}),
            causes_last_name: PropTypes.shape({}),
            gender: PropTypes.string,
            image: PropTypes.string,
          }),
        }),
      }),
      donators: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          image: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  actions: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    closeModalAndDelay: PropTypes.func,
  }).isRequired,
  closingPopup: PropTypes.bool.isRequired,
};

PopupContainer.defaultProps = {
  user: {
    loggedIn: false,
  },
};
const mapStateToProps = (state) => ({
  popups: state.notifications.popups,
  closingPopup: state.notifications.closingPopup,
  user: state.user,
  loadDelay: state.notifications.loadDelay,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
