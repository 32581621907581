import React, { Component } from "react";

class SearchLoader extends Component {
  render() {
    return (
      <div className="container container--narrow">
        <section className="app-section search">
          <main className="main-section friend-list search__list">
            <div className="search__item">
              <h1 className="search__item-heading">Friends</h1>
              <div className="search__loader" />
              <div className="search__loader" />
              <div className="search__loader" />
            </div>
            <div className="search__item">
              <h1 className="search__item-heading">Others</h1>
              <div className="search__loader" />
              <div className="search__loader" />
              <div className="search__loader" />
            </div>
          </main>
          <main className="main-section friend-list search__list">
            <div className="search__item">
              <h1 className="search__item-heading">Wishes</h1>
              <div className="search__loader" />
              <div className="search__loader" />
              <div className="search__loader" />
            </div>
          </main>
        </section>
      </div>
    );
  }
}

export default SearchLoader;
