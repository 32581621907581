import React from "react";

export default {
  "app.name": "Wowee",
  "common.create": "Vytvořit",
  "common.no": "Ne",
  "common.yes": "Ano",
  "common.add": "Přidat",
  "common.cancel": "Zrušit",
  "common.close": "Zavřít",
  "common.share": "Sdílet",
  "common.confirm": "Potvrdit",
  "common.select": "Zvolit",
  "common.next": "Dále",
  "common.back": "Zpět",
  "common.save": "Uložit",
  "common.saving": "Ukládám ...",
  "common.saved": "Uloženo",
  "common.loading": "Načítání",
  "common.accept": "Přijmout",
  "common.decline": "Odmítnout",
  "common.tryAgain": "Zkusit znovu",
  "common.selectAll": "Vybrat vše",
  "common.deselectAll": "Odebrat vše",
  "common.loadingError": "Ouha, data se nepodařilo načíst",
  "common.error": "Nastala chyba",
  "gdpr.popup.title": "GDPR",
  "gdpr.popup.description": "Seznam oprávnění",
  "gdpr.popup.decline": "Zamítnout",
  "gdpr.popup.accept": "Přijmout",
  "gdpr.open": "Seznam oprávnění GDPR",
  "gdpr.web-analytics.title": "Nástroje k analýze návštěvnosti",
  "gdpr.web-analytics.description":
    "Abychom mohli Wowee stále zlepšovat, používáme analytické nástroje Google Analytics a Facebook, které posktují souhrné informace o používání webu.",
  "common.submit": "Odeslat",
  "common.submitting": "Odesílám",
  "common.email": "Email",
  "common.or": "Nebo",
  "common.password": "Heslo",
  "common.password-again": "Heslo znovu",
  "common.copyClipboard": "Zkopírováno do schránky",
  "common.more": "Více",
  "common.less": "Méně",
  "common.friends": "Přátelé",
  "common.users": "Uživatelé",
  "common.wishes": "Sbírky",

  "gdpr.web-alalytics.title": "Nástroje k analýze návštěvnosti",
  "general.sign-up": "Zaregistruj se",
  "general.forgotten-password": "Zapomenuté heslo",
  "general.setup-partners-password": "Nastavte si heslo",
  "general.login": "Přihlas se",
  "general.log_out": "Odhlásit",
  "general.login.facebook": "Přihlas se přes Facebook",
  "general.set-up-new-password": "Nastav si nové heslo",
  "general.set-up-new-password-for": "Nastav si nové heslo pro",
  "general.error.title": "Nenalezeno",
  "general.error.subtitle.1": "Bohužel toto tady nemáme ... ",
  "general.error.subtitle.2": " Opravdu víš co hledáš?",

  "general.share.title": "Wowee!",
  "general.share.description":
    "Když máš dobré přátele, máš každý sen na dosah. Ať už toužíš po čemkoliv. Splň si své tajné přání díky Wowee!",
  "general.share.og-description":
    "Když máš dobré přátele, je každý sen dosažitelný. A protože věříme v sílu přátelství, založili jsme Wowee. Propojuje kamarády, kteří si chtějí navzájem plnit tajná přání. Ta velká i ta ještě větší. Tak začni a vytvoř si to své.",
  "gdpr.web-alalytics.description": "TODO",
  "gdpr.facebook-analytics.title": "TODO",
  "gdpr.facebook-analytics.description": "TODO",
  "gdpr.facebook-data-forwarding.title": "TODO",
  "gdpr.facebook-data-forwarding.description": "TODO",
  "gdpr.cookie-forwarding.title": "TODO",
  "gdpr.cookie-forwarding.description": "TODO",
  "wish.view_in_eshop": "Zobrazit v eshopu",
  "wish.notlogged.introtext":
    "S Wowee můžeš přispět na dárek svému kamarádovi! Toto přání nemůžeš vidět, protože jej tvůj kamarád zpřistupnil jen několika uživatelům. Svou sbírku na dárek vytvoříš po přihlášení.",
  "wish.restricted.introtext":
    "Toto přání je neveřejné a přístup k němu mají jen určití uživatelé. I přes to můžeš na dárek přispět nebo požádat o zpřístupnení autora sbírky.",
  "wish.restricted.not-approved":
    "S Wowee můžeš přispět na dárek svému kamarádovi! Toto přání nyní je nové, proto chvilku počkej, za chvilku bude možné na něj přispět. Svou sbírku na dárek vytvoříš po přihlášení.",

  "contact.title": "Kontakt",
  "thanks.title": "Poděkování",
  "thanks.description":
    "Jsou lidé, bez kterých by se vývoj ElateMe neobešel. Níže uvedení lidé a instituce měly zásadní zásluhu na tom, že můžeme dělat radost lidem. Proto zaslouží velké poděkování za celé Wowee. Děkujeme!",
  "about.title": "O Wowee",
  "about.content":
    "<h2>Provozovatel</h2>\n" +
    "<p>Společnost ElateMe s. r. o. je startup založený v červenci 2017 v Praze. Od února 2016 její zakladatelé vytvářeli webovou a mobilní aplikaci Wowee.cz. Ta poskytuje inovativní řešení lidské potřeby v situacích, kdy nastane událost u níž se očekává příjem daru. Typicky narozeniny, Vánoce či svatby. Kombinuje funkcionalitu wish listu a crowdfundingu. Uživatelé mohou vytvářet své dárky a finančně přispívat na dárky svých blízkých. Iniciátor a hlavním realizátorem je Michal Maněna.\n" +
    "\n" +
    "Výrobky a služby:\n" +
    "Aplikace ElateMe umožňuje transparentní přispívání libovolných finančních částek na přání blízkého uživatele. " +
    "Peníze jsou po celou dobu drženy na účtech společnosti ElateMe. Po ukončení finanční sbírky jsou peníze převedeny na účet obdarovávaného." +
    "Uživatelé mohou vytvořit vlastní přání nebo tzv. surprise, což je založení sbírky na přání nějakého uživatele bez jeho vědomí." +
    " Aplikace umožňuje řešit problém nevhodného dárku a to jak pro obdarovávaného, tak jeho blízkých, od nichž se dar očekává. " +
    "ElateMe s. r. o. si nenárokuje žádnou provizí z finančních transakcí. " +
    "ElateMe s. r. o. bylo založeno v červenci 2017 v Praze. Společnost zakoupila autorská práva od studentů ČVUT, kteří se na aplikaci podíleli.\n" +
    "První myšlenka na vyvíjený typ služby se objevila v roce 2012. V únoru 2016 se vlastníci, dohodli na tvorbě vlastního projektu." +
    " Michal Maněna prostřednictvím Ing. Jiřího Chludila z ČVUT vytvořil témata pro kurz Týmový softwarový projekt na ČVUT FIT, " +
    "na nějž se přihlásilo 6 studentů z Ruska a Ukrajiny, jež začali vyvíjet mobilní aplikaci ElateMe pro Android a iOS. " +
    "Pět z nich se následně rozhodlo pokračovat ve vývoji aplikace v rámci jejich bakalářských prací. " +
    "V červnu 2017 obhájili své práce jejichž výstupem jsou klienti pro Android a iOS, API server a AdServer. " +
    "Webovou verzi začal tvořit tým 12 studentů dalšího ročníku výuky. </p>" +
    "<h2>Co je Wowee?</h2>" +
    "<p>Uživatel po přihlášení prostřednictvím Facebooku vidí feed přání jeho kamarádů. " +
    "Přání je definované jeho názvem, částkou a případným popisem, obrázkem a termínem, do kterého je nutné cílovou částku vybrat." +
    " Tvůrce přání vždy zvolí uživatele či skupiny uživatelů, kteří přání uvidí a mohou na něj přispívat. " +
    "Na detailu je též umožněno psát komentáře k přání. " +
    "Z množiny facebookových přátel uživatele vidí v aplikaci uživatel pouze ty, kteří již do aplikace vstoupili, " +
    "resp. dali Facebooku souhlas s oprávěním. Na stránce seznamu přátel uživatel může procházet přání jednotlivých přátel. </p>" +
    "<p>Při tvorbě přání uživatel nejdříve napíše, na co chce založit sbírku. " +
    "Aplikace mu dle napsaného textu nabízí adekvátní produkty. " +
    "Uživatel může nějaký z nich vybrat nebo pokračovat bez udání konkrétního produktu. P" +
    "okud zvolí z nabízených možností produktů, je mu zobrazena vnořená stránka s eshopem, na němž je možné produkt zakoupit. " +
    "Následně uživatel potvrdí požadovaný produkt a zvolí zda je dárek pro něj osobně nebo vytváří přání pro někoho ze svých blízkých (surprise). " +
    "Dále zvolí okruh uživatelů, kteří vytvářenou sbírku uvidí a budou moci přispět. Výběr donátorů se liší v typu přání - u surprise uživatel vybírá primárně z okruhu přátel obdarovávaného a nikoliv svého. " +
    "Následně přání potvrzením formuláře zveřejní.</p>",

  "hp.title": 'Plníme <span class="hp-header__title--span">tajná přání!</span>',
  "hp.subtitle":
    "Když máš dobré přátele, je každý sen dosažitelný. A protože věříme v sílu přátelství, založili jsme Wowee. Propojuje kamarády, kteří si chtějí navzájem plnit tajná přání. Ta velká i ta ještě větší. Tak začni a vytvoř si to své.",
  "hp.fb.button": "",
  "hp.describer.title1": "Už žádné nechtěné dárky",
  "hp.describer.title2": "Velká přání se plní po troškách",
  "hp.describer.info2":
    "Každá velká cesta začíná prvním krokem. A každé velké přání se zase plní po troškách. Přihlas se do\n" +
    "Wowee, napiš své přání a oslov členy rodiny, kamarády, kolegy a známé, aby ti s ním pomohli. Každý\n" +
    "z nich může přispět libovolnou částkou. Když se celková suma vybere, přistane ti na účtu právě včas,\n" +
    "ať si můžeš pořídit, po čem toužíš. Radost tak budou mít všichni – ty, protože dostaneš to, co opravdu\n" +
    "chceš, i tví blízcí, protože ti pomohli splnit sen.",
  "hp.describer.info1":
    "Známe to všichni. Občas dá zkrátka fušku vymyslet správný dárek. A výsledek? Na polici se hromadí\n" +
    "ve dvou řadách láhve alkoholu, které nikdy nevypiješ, a na stole zase knihy, které nikdy nepřečteš.\n" +
    "Skoncuj s nechtěnými dárky a vyber si, co by ti udělalo největší radost. Může to být večeře\n" +
    "v michelinské restauraci, vstupenky na Stouny nebo Biebera, kávovar na parádní espresso, elektrická\n" +
    "kytara nebo jazykový pobyt v zahraničí. Prostě cokoliv.",
  "hp.mobile.download": "Stáhni si Wowee",
  "hp.mobile.download.into": "do svého smartphonu",
  "hp.try": "Vyzkoušej Wowee",
  "hp.try.web": "ve svém prohlížeči",
  "hp.about": "O Wowee",
  "hp.partners": "Partneři",
  "hp.conditions": "Podmínky",
  "hp.thanks": "Poděkování",
  "hp.contact": "Kontakt",
  "hp.loginButtonText": "Přihlásit přes Facebook",
  "hp.contact.about": "Wowee provozuje společnost:",
  "hp.contact.person": "Obchodní kontakt: michal.manena@wowee.cz",
  "hp.contact.question": "Máte dotaz? Napište nám na poradna@wowee.cz",

  "hp.header.title": "Slož se s kamarády na skvělé dárky!",
  "hp.header.subtitle-special":
    "Když máš dobré přátele, můžeš si přát cokoliv a dostaneš to. Naše aplikace spojuje rodinu, kamarády, známé, spolužáky i kolegy, kteří si chtějí navzájem plnit přání. Tak začni – je to rychlé a jednoduché.",
  "hp.header.subtitle":
    "Když máš dobré přátele, můžeš si přát cokoliv a dostaneš to. Naše aplikace spojuje rodinu, kamarády, známé, spolužáky i kolegy, kteří si chtějí navzájem plnit přání. Tak začni – je to rychlé a jednoduché.",
  "hp.press.title": "Napsali o nás",
  "hp.header.link": "Vytvoř přání",
  "hp.section.h2.1": "3 kroky",
  "hp.section.h2.2": "k",
  "hp.section.h2.3": "vysněnému dárku",
  "hp.bubble.1": "Vytvoř přání pro sebe nebo někoho blízkého.",
  "hp.bubble.2": "Pozvi všechny, které znáš, ať taky přispějí.",
  "hp.bubble.3": "Jakmile se peníze vyberou, může se slavit.",
  "hp.what-it-is.title": "Co je Wowee?",
  "hp.what-it-is.subtitle":
    "Wowee je aplikace, která plní lidem přání. Malá, velká i největší. Sami jsme v životě dostali spoustu nepoužitelných dárků a rozhodli jsme se to změnit. Rádi bychom dostávali to, po čem opravdu toužíme. Wowee spojuje ty, kteří vědí, co chtějí, s těmi, kteří jim to chtějí dopřát.",
  "hp.what-it-is.link": "Jak to funguje?",
  "hp.cookies.message":
    "Tento web používá k poskytování služeb soubory cookie. Používáním tohoto webu s tím souhlasíte.",
  "hp.cookies.button": "Rozumím",
  "wish.product-search.hint":
    "Napište název produktu nebo vložte odkaz na eshop nebo web.",

  "mock.user.first_name": "Neznamý uživatel",
  "mock.user.last_name": "přijmeni",

  "menu.feed": "Přání",
  "menu.feed.loading": "Načítání obsahu",
  "menu.birthdays": "Narozeniny",
  "menu.mywishes": "Má přání",
  "menu.createWish": "Vytvořit přání",
  "menu.editWish": "Editovat přání",
  "menu.friends": "Přátelé",
  "menu.notifications": "Notifikace",
  "menu.loggedIn": "Přihlášen jako",
  "profile.friend.share.title": "Dárky {name_akuzativ}",
  "profile.friend.share.description": "Dárky {name_akuzativ} ",
  "profile.my.share.title": "Moje dárky",
  "profile.my.share.description": "Moje dárky",
  "home.how-it-works":
    "Bezvadně, když máš okolo sebe skvělé lidi. A my víme, že máš. Hromadné financování je nejsnazší cestou k parádním dárkům, nebo pomoci v nouzi. Kdysi pomohlo ke zrodu Národního divadla. A pomáhá dodnes. Třeba když se doma skládáte na dárek nebo ve škole pro spolužáka a v práci pro kolegy. Stačí, když každý přihodí aspoň trošku, aby se celkově vybralo tolik, že je možné splnit velká i ještě větší přání.",
  "home.how-to-create-wish":
    "Rychle a snadno. Přihlas se přes Facebook, vytvoř přání a vyber, kdo dárek dostane. Můžeš to být ty, člen rodiny, kamarád, spolužák, známý nebo kolega z práce. Urči cílovou částku a přání potvrď. Jakmile přání vytvoříš, nezapomeň ho sdílet na sociálních sítích! Jakmile jsi spokojen s vybranou částkou, přání ukončíš a peníze se ti převedou na tvůj účet.",
  "home.cashback":
    "Dobu, kterou potřebuješ na vybrání cílové částky, určuješ ty. Určit můžeš i hodnotu příspěvku. Máš tak v rukou vše, co potřebuješ, aby se povedlo vybrat cílovou částku. Když to nevyjde, nedá se nic dělat.",
  "home.how-it-works.title": "Jak to funguje?",
  "home.how-to-create-wish.title": "Jak založit přání?",
  "home.cashback.description":
    "Peníze si samozřejmě nenecháme – můžeš si za ně vybrat a koupit něco jiného.", // , nebo je využít na jiné přání
  "home.cashback.title": "Co když se peníze nevyberou?",
  "comments.loadNext": "Načíst více komentářů",
  "general.faq": "Časté dotazy",
  "faq.content": `<h3>K čemu aplikace Wowee slouží?</h3>
    <p>Wowee je jednoduchá webová aplikace, která ti pomůže založit online přání (sbírku na dárek) pro tvé přátele, rodinu, kolegy a známé. Přání pak stačí sdílet s ostatními, kteří mohou také snadno přispět. </p>
    <h3>Jak se můžu do aplikace zaregistrovat?</h3>
    <p>Snadno. Vpravo nahoře klikni na Zaregistruj se a vytvoř si bezplatně účet. Přihlásit se můžeš přes profil na Facebooku nebo tvým e-mailem.</p>
    <h3 id="commission">Na čem vyděláváte? Nejsou tu nějaké skryté poplatky?</h3>
    <p>Nejsou. Pouze při platbě kartou vám naúčtujeme poplatky, které nám strhne banka a vydavatel karty ve výši cca 1 %. Při platbě pomocí bankovního převodu (QR platba) jsou nulové poplatky. Jsme startup a ještě hledáme vhodný způsob výdělku. Pracujeme ale na propojení s eshopy a kamennými prodejnami. V budoucnu si vezmeme provizi od nich.</p>
    <h3>Jak založit přání/sbírku v aplikaci Wowee?</h3>
    <p>Snadno. Přihlaste se do aplikace. Jestli ještě nemáte účet, zaregistrujte se emailem nebo přes svůj Facebook profil. Po přihlášení klikněte vlevo nahoře na Vytvoř přání a můžete začít se svou první sbírkou.</p>
    <h3>Co se stane, když se nevybere cílová částka sbírky?</h3>
    <p>To vůbec nevadí. I peníze ze sbírky, u které se nevybere cílová částka, můžete po ukončení sbírky ihned vyplatit obdarovanému nebo použít na nákup dárku.</p>
    <h3>V čem jsou výhody přihlášení přes Facebook?</h3>
    <p>V aplikaci Wowee díky tomu uvidíte i přání svých přátel na Facebooku a další uživatele Wowee mezi vašimi přáteli na Facebooku. Můžete snadno například založit přání pro toho, kdo má v nejbližší době narozeniny.</p>
    <h3>Je přání viditelné i pro toho, pro koho je přání vytvořené?</h3>
    <p>Viditelnost přání si můžete nastavit přímo v aplikaci Wowee při zakládání přání. Přání může být skryté i viditelné, podle toho, jaké přání chcete založit. </p>
    <h3>Co se děje s penězi z ukončené sbírky?</h3>
    <p>Peníze, které vyberete, můžete okamžitě přeposlat na bankovní účet příjemci, oslavenci nebo tomu, který má být obdarován. Vše je naprosto transparentní a bez jakýchkoliv poplatků.</p>
    <!--
    <p>Také můžete vybrané peníze okamžitě použít na nákup dárku v daném e-shopu, jestliže je vaše přání propojeno s konkrétním produktem v některým z e-shopů.</p>
    -->
    <h3>Můžu cílovou částku sbírky a dobu trvání sbírky měnit?</h3>
    <p>Ano, cílovou částku sbírky i dobu trvání sbírky lze libovolně upravovat nahoru i dolů.</p>
    <h3>V jakých situacích mi Wowee pomůže?</h3>
    <p>Aplikace Wowee vám pomůže se snadno složit (i po malých částkách) na přání a dárky ostatním. Složte se s přáteli, rodinou, kolegy nebo známými na toho, na kterém vám záleží.</p>
    <p>Potřebujete vybrat peníze na narozeninový dárek, složit se babičce na lázně v široké rodině a na velkou vzdálenost nebo si chcete splnit svůj velký sen, ale potřebujete pomoc rodiny a přátel? Nebo máte třeba jen nápad, jak pomoci druhému v nouzi a chcete transparentně vybrat menší částky od kamarádů a známých? Využijte k tomu aplikaci Wowee.</p>
    `,

  "general.og.wish.non-restricted.author-open-amount":
    "{author_nominativ} vytvořil na Wowee sbírku na {product} za {amount} Kč. Schází už jen {needed} Kč. Přispěj na ni taky a pomoz se splněním snu!", // WL1
  "general.og.wish.non-restricted.author-open-noamount":
    "{author_nominativ} vytvořil na Wowee sbírku na {product}. Přispěj na ni taky a pomoz se splněním snu!", // WL1
  "general.og.wish.non-restricted.author-closed-amount":
    "Sbírka na dárek pro {author_akuzativ} na Wowee právě skončila! Vybralo se {gathered} Kč od {donators} přátel. Díky všem!", // WL2
  "general.og.wish.non-restricted.author-closed-noamount":
    "Sbírka na dárek pro {author_akuzativ} na Wowee právě skončila! Vybralo se {gathered} Kč od {donators} přátel. Díky všem!", // WL2
  "general.og.wish.non-restricted.friend-open-amount":
    "{author_nominativ} vytvořil na Wowee sbírku na dárek pro {endower_akuzativ}. Přispěj taky! Na Wowee nám zbývá už jen {needed} Kč.", // WL3
  "general.og.wish.non-restricted.friend-open-noamount":
    "{author_nominativ} vytvořil na Wowee sbírku na dárek pro {endower_akuzativ}. Přispěj taky!", // WL3
  "general.og.wish.non-restricted.friend-closed-amount":
    "Právě jsme na Wowee vybrali {gathered} Kč na {product} pro {endower_akuzativ}! Díky všem!", // WL4
  "general.og.wish.non-restricted.friend-closed-noamount":
    "Právě jsme na Wowee vybrali {gathered} Kč na {product} pro {endower_akuzativ}! Díky všem!", // WL4
  "general.og.wish.restricted.author-open-amount":
    "{author_nominativ} vytvořil na Wowee sbírku na své přání. Přispěj taky a pomoz mu ho splnit!", // WL5
  "general.og.wish.restricted.author-open-noamount":
    "{author_nominativ} vytvořil na Wowee sbírku na své přání. Přispěj taky a pomoz mu ho splnit!", // WL5
  "general.og.wish.restricted.author-closed-amount":
    "{author_nominativ} právě na Wowee úspěšně ukončil sbírku na své tajné přání! Díky všem!", // WL6
  "general.og.wish.restricted.author-closed-noamount":
    "{author_nominativ} právě na Wowee úspěšně ukončil sbírku na své tajné přání! Díky všem!", // WL6
  "general.og.wish.restricted.friend-open-amount":
    "{author_nominativ} vytvořil na Wowee sbírku na dárek pro {endower_akuzativ}. Přispěj taky!", // WL7
  "general.og.wish.restricted.friend-open-noamount":
    "{author_nominativ} vytvořil na Wowee sbírku na dárek pro {endower_akuzativ}. Přispěj taky!", // WL7
  "general.og.wish.restricted.friend-closed-amount":
    "{author_nominativ} právě na Wowee úspěšně ukončil sbírku na dárek pro {endower_akuzativ}. Díky všem!", // WL8
  "general.og.wish.restricted.friend-closed-noamount":
    "{author_nominativ} právě na Wowee úspěšně ukončil sbírku na dárek pro {endower_akuzativ}. Díky všem!", // WL8

  "general.og.my-wish.non-restricted.author-open-amount":
    "Vytvořil jsem na Wowee sbírku na {product} za {amount} Kč. Schází mi už jen {needed} Kč. Přispěj mi taky a pomoz mi splnit můj sen!", // WL9
  "general.og.my-wish.non-restricted.author-open-noamount":
    "Vytvořil jsem na Wowee sbírku na {product}. Přispěj mi taky a pomoz mi splnit můj sen!", // WL9
  "general.og.my-wish.non-restricted.author-closed-amount":
    "Je to paráda! Přátelé se mi na Wowee složili na {product} a pomohli mi splnit sen. Díky všem!", // WL10
  "general.og.my-wish.non-restricted.author-closed-noamount":
    "Je to paráda! Přátelé se mi na Wowee složili na {product} a pomohli mi splnit sen. Díky všem!", // WL10
  "general.og.my-wish.non-restricted.friend-open-amount":
    "{endower_nominativ} si moc přeje {product}. Proto jsem pro něj vytvořil sbírku na Wowee, chybí už jen {needed} Kč. Přispěj taky! ", // WL11
  "general.og.my-wish.non-restricted.friend-open-noamount":
    "{endower_nominativ} si moc přeje {product}. Proto jsem pro něj vytvořil sbírku na Wowee. Přispěj taky! ", // WL11
  "general.og.my-wish.non-restricted.friend-closed-amount":
    "Paráda! Díky Wowee jsme vybrali {amount_gathered} Kč na {product} pro {endower_akuzativ}! ", // WL12
  "general.og.my-wish.non-restricted.friend-closed-noamount":
    "Paráda! Díky Wowee jsme vybrali {amount_gathered} Kč na {product} pro {endower_akuzativ}", // WL12
  "general.og.my-wish.restricted.author-open-amount":
    "Vytvořil jsem na Wowee sbírku na tajný dárek. Přispěj mi a pomoz mi splnit sen!", // WL13
  "general.og.my-wish.restricted.author-open-noamount":
    "Vytvořil jsem na Wowee sbírku na tajný dárek. Přispěj mi a pomoz mi splnit sen!", // WL13
  "general.og.my-wish.restricted.author-closed-amount":
    "Paráda! Díky Wowee a přátelům jsem vybral peníze na svůj tajný dárek!", // WL14
  "general.og.my-wish.restricted.author-closed-noamount":
    "Paráda! Díky Wowee a přátelům jsem vybral peníze na svůj tajný dárek!", // WL14
  "general.og.my-wish.restricted.friend-open-amount":
    "Vytvořil jsem na Wowee sbírku na tajný dárek pro přítele. Přispěj taky a pomoz mu splnit sen!", // WL15
  "general.og.my-wish.restricted.friend-open-noamount":
    "Vytvořil jsem na Wowee sbírku na tajný dárek pro přítele. Přispěj taky a pomoz mu splnit sen!", // WL15
  "general.og.my-wish.restricted.friend-closed-amount":
    "Paráda! Na Wowee jsme vybrali peníze na tajný dárek pro přítele. Díky všem!", // WL16
  "general.og.my-wish.restricted.friend-closed-noamount":
    "Paráda! Na Wowee jsme vybrali peníze na tajný dárek pro přítele. Díky všem!", // WL16

  "general.og.for-me-wish.friend-open-amount":
    "{author_nominativ} pro mě na Wowee vytvořil sbírku na {product}. Ještě zbývá {needed} Kč. Pomoz mi splnit sen a přispěj taky!", // WL17
  "general.og.for-me-wish.friend-open-noamount":
    "{author_nominativ} pro mě na Wowee vytvořil sbírku na {product}. Pomoz mi splnit sen a přispěj taky!", // WL17
  "general.og.for-me-wish.friend-closed-amount":
    "Paráda! {author_nominativ} a další přátelé vybrali na Wowee {amount} Kč na {product}. Splnili mi sen, díky moc!", // WL18
  "general.og.for-me-wish.friend-closed-noamount":
    "Paráda! {author_nominativ} a další přátelé vybrali na Wowee {amount} Kč na {product}. Splnili mi sen, díky moc!", // WL18

  "notFound.title": "Stránka nenalezena",
  "notifications.title": "Notifikace",
  "notifications.loadMore": "Načítat další notifikace",
  "notifications.allNotifications": "Všechny notifikace",
  "notifications.myWishExpired":
    "Dnes končí tvá sbírka <strong>{wishTitle}</strong>",
  "notifications.myWishDonation":
    "<strong>{donator}</strong> přispěl na Vaše přání <strong>{wishTitle}</strong>",
  "notifications.wishIDonatedCompleted.title":
    "Přání <strong>{friend_genitiv}</strong> <strong>{wishTitle}</strong> bylo úspěšně ukončeno",
  // 'notifications.wishIDonatedCompleted.text': 'Přispěli jste na toto přání',
  // 'notifications.card-donation-failed.title': 'Platba se nezdařila',
  "notifications.card-donation-failed.text":
    "Platba na {wishTitle} se nezdařila.",
  "notifications.myWishComment.title":
    "<strong>{author_nominativ}</strong> komentoval vaše přání <strong>{wishTitle}</strong>.",
  "notifications.youDonate":
    "Přispěl jsi <strong>{amount} {currency}</strong> <strong>{influencer_dativ}</strong> na dárek <strong>{wishTitle}</strong>.",
  "notifications.someoneGotDonation":
    "Kamarád ti přispěl <strong>{amount} {currency}</strong> na dárek <strong>{wishTitle}</strong>.",
  "notifications.gotDonation":
    "{donator_nominativ} ti přispěl <strong>{amount} {currency}</strong> na sbírku <strong>{wishTitle}</strong>.",
  "notifications.youMadeDonation":
    "Přispěl jsi <strong>{amount} {currency}</strong> na sbírku <strong>{wishTitle}</strong>.",
  "notifications.gotGift":
    "Tví kamarádi se ti složili na tajný dárek <strong>{wishTitle}</strong> částkou <strong>{amount} {currency}</strong>.",
  "notifications.revealRequest.title":
    "<strong>{influencer_nominativ}</strong> tě žádá o zpřístupnění detailu tvého dárku <strong>{wishTitle}</strong>.",
  "notifications.revealGranted.title":
    "<strong>{influencer_nominativ}</strong> ti zpřístupnil {influencer_posesiv} přání <strong>{wishTitle}</strong>.",
  "notifications.successCollection.title":
    "Gratulujeme! Tví přátele se ti složili částkou {amount} {currency} na dárek <strong>{wishTitle}</strong>.",
  "notifications.successCollectionParticipated.title":
    "Gratulujeme! <strong>Ty</strong> a tví přátele se ti složili částkou {amount} {currency} na dárek <strong>{wishTitle}</strong> pro kamaráda <strong>{endower}</strong>.",
  "notifications.friendCreatedWish.title":
    "<strong>{author_nominativ}</strong> vytvořil nové přání <strong>{wishTitle}</strong>, na které můžeš přispět.",
  "notifications.friendCreatedSurprise.title":
    "<strong>{author_nominativ}</strong> vytvořil sbírku <strong>{wishTitle}</strong> pro <strong>{endower_akuzativ}</strong>",
  "notifications.friendRequest.title":
    "Uživatel <strong>{author}</strong> ti odeslal žádost o přátelství",
  "notifications.friendRequest.accepted": "Přijali jste žádost o přátelství",
  "notifications.friendRequest.deny": "Tuto žádost o přátelství jste nepřijali",
  "notifications.friendGranted.accepted":
    "Uživatel <strong>{author}</strong> potvrdil tvoji žádost o přátelství",
  "notifications.friendGranted.deny":
    "Uživatel <strong>{author}</strong> zamítnul tvoji žádost o přátelství",

  "popup.got-a-gift.heading": "Dostal jsi dárek!",
  "popup.got-a-gift.donators": "Tito přátelé ti přispěli:",
  "popup.got-a-gift.subheading":
    'Dostal jsi od přátel <strong>{amount} {currency}</strong> na <span className="product-name">{title}</span>',
  "popup.got-a-gift.shareMessage":
    "Tyjo, kámoši se mi složili na dokonalý dárek {title}! Díky všem!",
  "popup.got-a-gift.shareDescription":
    "Kamarádi vybrali celkem {amount} {currency}.",
  "popup.success-collection.heading": "Úspěšná sbírka",
  "popup.success-collection.subheading":
    'Sbírka na <span className="product-name">{title}</span> byla úspěšná. Peníze byly odeslány na tvůj účet.',
  "popup.wish-created.heading": "A je to! Vytvořil jsi sbírku.",
  "popup.wish-created.subheading":
    'Nyní sdílej přání <span className="product-name">{title}</span> mezi své kamarády.',
  "popup.success-collection.donators": "Tito lidé přispěli na dárek:",
  "popup.success-collection.shareMessage": "Sbírka na {title} byla úspěšná!",
  "popup.thanks.heading": "Děkujeme!",
  "popup.thanks.subheading":
    "<strong>{name_dativ}</strong> jsi přispěl <strong>{amount} {currency}</strong> na dárek <strong>{wishTitle}</strong><br /> Jsme rádi, že děláte lidem radost!",
  "popup.thanks-restricted.subheading":
    "<strong>{name_dativ}</strong> jsi přispěl <strong>{amount} {currency}</strong> na dárek.<br /> Jsme rádi, že děláte lidem radost!",
  "popup.you-donate-restricted.subheading":
    "Právě jsi přispěl {amount} {currency} na dárek <strong>{name_dativ}</strong>! Díky, že pomáháš plnit sny!",

  "popup.thanks.share":
    "To je pocit! Přispěl jsem {name_dativ} částkou {amount} {currency}, abych {name_pronoun} pomohl{donator_nomina} splnit sen {wishTitle}!",
  // 'popup.thanks.share': 'To je pocit! Přispěla jsem {name_dativ} částkou {amount} {currency}, abych mu pomohl{name_posesive} splnit sen {wishTitle}!',
  "popup.card-donation-failed.heading": "Platba se nezdařila",
  "popup.card-donation-failed.subheading":
    "Bohužel se nám nepodařilo ověřit vaši platbu na sbírku {title}. Z vaší karty nebylo nic strženo. Zkuste platbu opakovat.",
  //   'popup.card-donation-failed-restricted.subheading': 'Bohužel se nám nepodařilo ověřit vaši platbu {amount} {currency}. Z vaší karty nebylo nic strženo. Zkuste platbu opakovat.',
  "popup.card-donation-failed-restricted.subheading":
    "Bohužel se nám nepodařilo ověřit vaši platbu. Z vaší karty nebylo nic strženo. Zkuste platbu opakovat.",

  "home.title": "Wowee",
  "home.welcome": "Vítejte!",

  "login.email.valid": "Tento email, je v pořádku",
  "login.email.invalid": "Tento email, není v pořádku",
  "login.email.placeholder": "Sem zadejte Váš email",
  "login.password.invalid": "Neplatné heslo",
  "login.password.dont_match": "Hesla se neshodují",
  "login.password.match": "Hesla se shodují",
  "login.password.valid": "Heslo je v pořádku",
  "login.password.forget": "Zapomněl jsi heslo?",
  "login.password.again": "Heslo znovu",
  "login.password.placeholder": "Sem zadejte Vaše heslo",
  "login.password.placeholder_again": "Sem zadejte Vaše heslo znovu",
  "login.name": "Sem zadejte Vaše jméno",
  "login.surname": "Sem zadejte Vaše příjmení",
  "login.birthday": "Zadejte datum narození",
  "login.forgotten_password.placeholder": "Sem zadejte Váš email",
  "login.error.credentials":
    "Chybně zadané přihlašovací údaje (email, heslo nebo obojí)",
  "login.registration.error.title": "Chyba při registraci",
  "login.registration.error.1":
    "Registrace této emailové adresy nebyla dokončena",
  "login.registration.error.2":
    "Nepřišel-li vám aktivační email, vygenerujte si nový.",
  "login.registration.sent.title": "Skvěle, už je skoro hotovo",
  "login.registration.sent":
    "Na tvůj email jsme poslali ověřovací email. Stačí na něj kliknout a registrace je hotová.",
  "login.registration.resend":
    "Na tento email už byl zaslán registrační email. Pokud Vám email nedorazil, můžete si ho nechat zaslat znovu pomocí tlačítka níže",
  "login.registration.button": "Znovu odeslat email",
  "login.new_password.used_hash":
    "Tento odkaz již byl v minulosti použit a heslo bylo změněno.",
  "login.new_password.login": "Přihlas se do svého účtu",
  "login.new_password.error": "Neplatný odkaz pro změnu hesla",
  "login.new_password.error.login": "Zpět na úvodní stránku",
  "login.new_password.footer.1":
    "Znáte své heslo? Výborně tak se můžete rovnou ",
  "login.new_password.footer.2": "přihlásit",
  "login.no_account.1": "Nemáš u nás účet? Tak si ho ",
  "login.no_account.2": "rychle udělej",
  "login.account.1": "Už u nás máš účet? Výborně, tak se můžeš rovnou ",
  "login.account.2": "přihlásit",

  "login.sign-up.about":
    "Wowee propojuje přátele a všechny, kteří chtějí plnit přání svých blízkých. Stačí se zdarma registrovat, založit přání a pak jen sdílet s ostatními, aby ti s tím pomohli.",
  "login.sign-up.title": "Skvěle! Teď už jen něco málo o tobě.",
  "login.sign-up.name": "Jméno",
  "login.sign-up.surname": "Příjmení",
  "login.sign-up.birthday": "Datum narození",
  "login.sign-up.gender": "Pohlaví",
  "login.sign-up.gender.male": "Muž",
  "login.sign-up.gender.female": "Žena",

  "login.password.forgotten.title": "Obnova hesla",
  "login.password.forgotten.reset_it": "Obnov si ho!",
  "login.password.forgotten.info":
    "Vyplň svojí e-mailovou adresu. Pokud jí známe a je na ní zaregistrovaný účet, pošleme ti na tvůj email údaje k obnovení účtu",
  "login.password.forgotten.email_sent":
    "Tvoji žádost o nové heslo jsme zpracovali. Pokud je zadaný email v naší databázi, odeslali jsme na něj instrukce pro nastavení nového hesla.",

  "wish.title": "Název sbírky",

  "wish.create.what": "Co opravdu chceš?",
  "wish.create.forSomeone": "Co si přejete darovat?",
  "wish.create.whatForHim": "Co mu chcete dát?",
  "wish.create.whatForHer": "Co jí chcete dát?",
  "wish.create.noProductsFound": "Nebyl nalezen žádný výsledek hledání",
  "wish.create.productStartingPrice": "začíná na {price} Kč",
  "wish.create.selectCustomWish": "Vytvoř přání <em>{text}</em>",
  "wish.create.created":
    "Přání bylo vytvořené. Prosím počkejte, probíhá přesměrování.",
  "wish.create.giftDescription": "Popis přání",
  "wish.create.giftSettings": "Nastavení přání",
  "wish.create.wishTitle": "Název přání",
  "wish.create.price": "Cena",
  "wish.create.fromAndPrice":
    "z <strong>{shop}</strong> za <strong>{price} Kč</strong>",
  "wish.create.recipientTitle": "Pro koho?",
  "wish.create.recipientTitleMe": "Mne",
  "wish.create.visibility": "Kdo jej může zobrazit?",
  "wish.create.deadline": "Termín",
  "wish.create.deadline.info":
    "Deadline je pouze informativní. Peníze budou převedeny na Váš účet potom co ukončíte přání.",
  "wish.create.donations": "Příspěvek",
  "wish.create.noDonations": "libovolná hodnota",
  "wish.create.donatorsChoice":
    "Přispívající může zvolit libovolnou částku pro příspěvek",
  "wish.create.choseOption": "Vyber možnost:",
  "wish.create.fixedValue": "Fixní částka",
  "wish.create.dollars": "dolarů",
  "wish.create.euros": "euro",
  "wish.create.crowns": "korun českých",
  "wish.create.noDeadline": "žádný",
  "wish.create.setDeadline": "Termín",
  "wish.create.recipientMe": "Přání je pro mne",
  "wish.create.recipientMeDescription": "Přání je pro tebe.",
  "wish.create.recipientFriend": "Přání je pro přítele",
  "wish.create.recipientOutside": "Pro přítele mimo Wowee",
  "wish.create.writeNameOfFriend": "Napiš jméno přítele: ",
  "wish.create.visibilitySelected": "Pouze vybraní přátelé",
  "wish.create.visibilityExpect": "Všichni až na vybrané",
  "wish.create.visibilityPublicIncludingDower": "Kdokoliv včetně obdarovaného",
  "wish.create.visibilitySelectedExcludingDower":
    "Vybrání přátelé bez obdarovaného",
  "wish.create.visibilityPublicMessage":
    "Přání si mohou zobrazit všichni uživatelé Wowee včetně neregistrovaných.",
  "wish.create.visibilitySelectedMessage": "Přání uvidí níže vybraní přátelé",
  "wish.create.visibilitySelectedMessageExcludingEndower":
    "Přání uvidí níže vybraní přátelé kromě {name_genitiv}.",
  "wish.create.visibilityPublic": "Veřejné",
  "wish.create.visibilityFriends": "Přátelé",
  "wish.create.visibilityMe": "Pouze já",
  "wish.create.visibility.edit": "Uprav",
  "wish.create.visibilityCustomUsers": "Konkrétní přátelé",
  "wish.create.friendLists": "Dostupné seznamy přátel",
  "wish.create.descriptionPlaceholder":
    "Napište, proč by vám měli vaši prátele pomoci s tímto přáním",
  "wish.create.forMe": "Pro mě",
  "wish.create.for": "Pro",
  "wish.create.createForMe": "Vytvořit toto přání <strong>pro mě</strong>",
  "wish.create.createForFriend":
    "Vytvořit toto přání <strong> pro {name}</strong>",
  "wish.create.errorNoTitle": "Musíte vyplnit název přání před jeho uložením.",
  "wish.edit.errorNoTitle": "Musíte vyplnit název přání před uložením změn.",
  "wish.create.unlimitedValue": "Částka bez limitu",
  "wish.create.fixedValueInfo":
    "  (tato částka je pouze orientační, není nutné ji naplnit)",
  "wish.create.whoIsIt": "Kdo to je?",
  "wish.create.friendsName": "Napiš přítelovo jméno",
  "wish.create.money_receiver.who": "Kdo dostane vybrané peníze?",
  "wish.create.money_receiver.me": "Já",
  "wish.create.money_receiver.me.placeholder": "Já dostanu peníze",
  "wish.create.surprise_wish": "Pro koho je dárek?",

  "modal.error.heading": "Je nám to líto",
  "modal.error.title": "Zkuste obnovit stránku",
  "modal.loginError.title": "Změňte oprávnění aplikace",
  "modal.user_logged.title":
    "Tuto akci může provést pouze nepřihlášený uživatel",
  "modal.user_logged.description":
    "Pro provedení této akce musíte být odhlášen",

  "wish.confirm.question": "Jste si jistí?",
  "wish.confirmDelete.title": "Smazat přání",
  "wish.confirmDelete.question": "Jste si jistí, že chcete smazat toto přání?",
  "wish.confirmClose.title": "Ukončit přispívání",
  "wish.confirmClose.question":
    "Jste si jistí, že chcete ukončit přispívání pro toto přání?",
  "wish.missingAccountNumber.title": "Chybí bankovní účet.",
  "wish.missingAccountNumber.description":
    "Pro převody vybraných peněz a ukončení sbírky je nutné nastavit si svůj bankovní účet.",
  "wish.missingAccountNumber.doItNow": "Udělej to prosím hned.",
  "wish.confirmRefund.title": "Vrátit peníze",
  "wish.confirmRefund.question":
    "Jste si jistí, že chcete vrátít peníze přispěvatelům?",
  "wish.confirmRefund.description": "Peníze budou vráceny přispěvatelům.",
  "wish.close.succ.title": "OK!",
  "wish.close.succ.description": "Peníze vám budou připsány do 24 hodin.",
  "wish.detail.collected.mine":
    "{count, plural, " +
    "=0 {Zatím nikdo nepřispěl}" +
    "one {<strong>{count}</strong> přítel příspěl <strong>{gathered} {currency}</strong>}" +
    "few {<strong>{count} </strong> přátelé přispěli celkem <strong>{gathered} {currency}</strong>} " +
    "other {<strong>{count} </strong> přátel přispělo celkem <strong>{gathered} {currency}</strong>}}",
  "wish.detail.collected":
    "{count, plural, " +
    "=0 {Zatím nikdo nepřispěl}" +
    "one {<strong>{count}</strong> přítel příspěl <strong>{gathered} {currency}</strong>}" +
    "few {<strong>{count} </strong>přátelé přispěli celkem <strong>{gathered} {currency}</strong>} " +
    "other {<strong>{count} </strong>přátel přispělo celkem <strong>{gathered} {currency}</strong>}}",
  "wish.detail.remaining": "<strong>{days} dnů</strong> zbývá",
  "wish.detail.todayEnds": "<strong>Dnes</strong> končí",
  "wish.detail.completed": "Ukončená a vyplacená.",
  "wish.detail.expired": "Po termínu, ale stále můžeš přispívat!",
  "wish.detail.title": "{name_nominativ} si přeje",
  "wish.detail.authorName": "{author_nominativ}",
  "wish.detail.baseSurpriseForNonUser":
    "{author_nominativ} vytvořil přání pro {recipient}",
  "wish.detail.surpriseForNonUser":
    "{author_nominativ} vytvořil přání pro {recipient}",
  "wish.detail.surpriseTitleHe":
    "{author_nominativ} vytvořil přání pro {recipient_akuzativ}", // pouzije se v seznamu prani
  "wish.detail.surpriseTitleShe":
    "{author_nominativ}  vytvořila přání pro {recipient_akuzativ}", // pouzije se v seznamu prani
  "wish.detail.surpriseTitleLinkedHe":
    "{author} vytvořil přání pro {recipient}", // pouzije se na detailu prani
  "wish.detail.surpriseTitleLinkedShe":
    "{author}  vytvořila přání pro {recipient}", // pouzije se na detailu prani
  "wish.detail.askForAccess": "Požádat {name_akuzativ} o přístup",
  "wish.detail.accessRequested": "Požádal jsi o přístup k tomuto přání",
  "wish.detail.accessAccepted": "Žádost byla akceptována",
  "wish.detail.accessRejected": "Žádost byla zamítnuta",
  "wish.detail.mainTitle": "Detail přání",
  "wish.detail.unknown": "Tajný dárek",
  "wish.detail.donateButton": "Přispět",
  "wish.detail.donate-money-info.desktop":
    "Peníze jsou shromažďovány na účtech Wowee, dokud není přání ukončeno jeho tvůrcem. Poté budou prostředky převedeny na jeho účet. Wowee si neúčtuje žádné skryté poplatky za úschovu peněz. Pouze při platbě kartou navýší částku o poplatky banky a vydavatele karty ve vyši cca. 1 %.",
  "wish.detail.donate-money-info.mobile":
    "Peníze jsou shromažďovány na účtech Wowee.",
  "wish.detail.donateButtonFlat": "Přispět {value} {currency}",
  "wish.detail.comments": "TODO: Komentáře",
  "wish.detail.moneyNeed": "potřebuje <strong>{money} {currency}</strong>",
  "wish.detail.moneyNeeded": "potřeboval <strong>{money} {currency}</strong>",
  "wish.detail.moneyGathered": "vybráno <strong>{money} {currency}</strong>",
  "wish.detail.forRefund": "Sbírka byla refundována.",
  "wish.detail.terminated": "Sbírka byla uzavřena.",
  "wish.detail.terminatedShort": "Uzavřena.",
  "wish.detail.deleted": "Přání bylo smazáno.",
  "wish.detail.noDescription": "Žádný popis.",
  "wish.detail.noDeadline": "žádný",
  "wish.detail.theseUsersDonated": "Tito kamarádi přispěli na přání",
  "wish.detail.showAllDonators": "Ukaž všechny přispívající",
  "wish.detail.userMoneyReceiver": "Po ukončení přání dostane peníze",
  "wish.detail.visibilityTitle": "Přání je viditelné",
  "wish.detail.visibility.forMe": "pouze pro mě",
  "wish.detail.visibility.public": "pro všechny",
  "wish.detail.visibility.forFriends": "pro všechny mé přátele",
  "wish.detail.visibility.chosenFriends": "pro vybrané přátele",
  "wish.detail.allowed_users.title": "Tito uživatelé si mohou přání zobrazit",
  "wish.detail.product_url": "Zobrazit v e-shopu",
  "wish.detail.donators.anonym": "Anonymní dárce",
  "wish.detail.donator": "{first_name}  <strong>{last_name}</strong>",

  "wish.detail.share.title": "Přání bylo vytvořeno!",
  "wish.detail.share.dismiss": "Zavřít",
  "wish.detail.share.copyUrl": "Kopírovat",
  "wish.detail.share.copied": "Zkopírováno!",
  "wish.detail.share.facebook": "Sdílet na Facebooku",
  "wish.detail.share.twitter": "Sdílet na Twitteru",

  "feed.nofriends":
    "Jsem na Wowee, co ty? Přidej se! Čím více kámošů, tím líp.",
  "feed.nofriends-sidebar":
    "Vítej na Wowee! Můžeš rovnou založit své první přání. Nebo pozvi své přátele z Facebooku.",
  "feed.share": "Sdílet Wowee na Facebooku",
  "feed.share.button.tellAbout": "Pozvat kamarády",
  "feed.type-comment": "Sem napiš svůj komentář...",
  "feed.makeSomeoneHappy":
    "Co takhle udělat někoho šťastým <br />a dát mu tajný dárek?",
  "feed.letsDoIt": "Vytvořit dárek!",
  "feed.shareMessage":
    "Wowee umí vybrat peníze na malá i velká přání. Přidej se ke mně a mrkni, jak můžeš Wowee využít i ty.",
  "feed.loadMoreComments": "Načíst všechny komentáře",
  "feed.loadLessComments": "Schovat všechny komentáře",

  "wish.dropdownTerminate.title": "Ukončit přání",
  "wish.dropdownTerminate.description":
    "<strong>Toto přání</strong> bude ukončeno",
  "wish.dropdownRefund.title": "Vrátit peníze",
  "wish.dropdownRefund.description":
    "Peníze pro toto přání budou vráceny přispěvatelům",
  "wish.dropdownEdit.title": "Upravit toto přání",
  "wish.dropdownEdit.description": "<strong>Úprava</strong> přání",
  "wish.dropdownDelete.title": "Smazat přání",
  "wish.dropdownDelete.description": "<strong>Toto přání</strong> bude smazáno",
  "wish.dropdownCopy.title": "Kopírovat",
  "wish.dropdownCopy.description": "Vytvořit jako přání, či překvapení",
  "wish.dropdownHideWish.title": "Skrýt toto přání",
  "wish.dropdownHideWish.description":
    "<strong>Toto přání</strong> bude skryté",
  "wish.dropdownHideUser.title": "Schovat tohoto uživatele",
  "wish.dropdownHideUser.description":
    "<strong>Všechny přání od {name_genitiv}</strong> budou skryté",
  "wish.dropdownShowDonators.title": "Ukaž přispívající",
  "wish.dropdownShowDonators.description":
    "Ukaž uživatele, kteří přispěli na toto přání",
  "wish.dropdownSetMoneyReceiver.title": "Nastav příjemce peněz",
  "wish.dropdownSetMoneyReceiver.description":
    "Nastav uživatele, který získá peníze z přání",
  "wish.dropdownSuccesfullyDeletedWish.description":
    "Přání bylo úspěšně smazáno.",
  "wish.dropdownShare.title": "Sdílej",
  "wish.dropdownShare.description": "Sdílejte toto přání.",

  "donate.title": "Přispět",
  "donate.title.flat": "Přispět {donation} {currency}",
  "donate.checkingPayment": "Kontrolování platby:",
  "donate.addressIdQr": "Bitcoin adresa v QR:",
  "donate.addressId": "Bitcoin adresa:",
  "donate.bitcoins": "Váš příspěvek v BT coins:",
  "donate.howMuch": "Kolik si přejete přispet {name_dativ}?",
  "donate.choosePaymentMethod": "Prosím vyberte platební metodu:",
  "donate.card": "Platební karta",
  "donate.bitcoin": "Bitcoin",
  "donate.QRCode": "Převodní příkaz / QR kód",
  "donate.QRCodeMessage":
    "Naskenujte tento QR kód, nebo proveďte platební příkaz:",
  "donate.thanks": "Děkujeme vám!",
  "donate.share":
    "Přispěl jsem {amount} {currency} na přání pro {wish_title} {donee}",
  "donate.share.collected": "Vybráno {amount} {currency}.",
  "donate.share.missing": "Zbývá vybrat {amount} {currency}.",
  "donate.cardRedirection": "Přesměrovávám...",
  "donate.cardRedirectionWait":
    "Chvilku vyčkej, budeš přesměrován na platební bránu.",
  "donate.donators": "{user} přispěl {amount} {currency}",
  "donate.donators.title": "Seznam všech přispívajících",
  "donate.money_receiver": "Zvolit příjemce peněz",
  "donate.bank_account": "Číslo účtu: ",
  "donate.variable_symbol": "Variabilní symbol",

  "donate.summary":
    "Částka <strong>{amount} {currency}</strong> pro {name_akuzativ} bude připsána na sbírku hned, jak proběhne transakce. <br /> Děkujeme, že lidem dáváte radost.",

  "user.friendship_request.title":
    "Tento uživatel Vám zaslal žádost o přátelství",

  "friends.all": "Všichni",
  "friends.request": "Žádosti",
  "friends.newList": "Nový seznam",
  "friends.filterFriends": "Vyhledat přítele",
  "friends.createGift": "Vytvořit dárek",
  "friends.allGifts": "Všechny dárky",
  "friends.allGiftsWithName": "Všechny dárky pro {name_genitiv}",
  "friends.delete": "Smazat seznam",
  "friends.delete.heading": "Smazat seznam přátel",
  "friends.delete.content":
    "Opravdu chcete smazat seznam {name}? Tuto akci nelze odvolat.",
  "friends.suggestions": "Dostupní přátelé",
  "friends.noRequests": "Nemáte žádné žádosti o přátelství",
  "friends.acceptRequest": "Přijmout",
  "friends.declineRequest": "Odmítnout",
  "friends.noFriends.inviteNew":
    "Těchto 5 uživatelů je na Wowee nových, možná znáte někoho z nich?",
  "friends.empty_list": "Tento seznam přátel je zatím prázdný.",

  "upcomingBirthdays.title": "Nadcházející narozeniny",
  "seeAll.link": "Všichni »",
  "myWishes.title": "Moje přání",
  "myWishes.no_wish_open": "Nemáte aktivní žádná přání.",
  "myWishes.no_wish_default": "Nemáte aktivní žádná přání.",
  "myWishes.no_wish_ended": "Žádná přání jste doposud neukončil.",
  "myWishes.no_wish_deleted": "Žádné přání jste doposud nesmazal.",
  "myWishes.label.active": "aktivní",
  "myWishes.label.ended": "ukončené",
  "myWishes.label.deleted": "smazané",
  "settings.bankAccount.example": "např.: 1234567890/1234",
  "settings.title": "Nastavení",
  "settings.bankAccount.title": "Číslo bankovního účtu:",
  "settings.bankAccount.howTo":
    "Pro změnu čísla bankovního účtu, vyplňte pole formuláře a klikněte na Uložit.",
  "settings.loggedIn": "Přihlášen jako",
  "settings.activeGifts": "Otevřené sbírky",
  "settings.createdGifts": "Vytvořené sbírky",
  "settings.createdSuprises": "Vytvořených překvapení",
  "settings.finishedWishes": "Úspěšné sbírky",
  "settings.totalDonated": "Daroval{user_nomina} si ({currency})",
  "settings.totalGathered": "Dostal{user_nomina} si ({currency})",
  "settings.selectLanguage": "Vybrat jazyk",
  "settings.language.english": "Angličtina",
  "settings.language.czech": "Čeština",
  "settings.language.slovak": "Slovenština",
  "settings.selectCurrency": "Měna",
  "settings.currency.czk": "CZK",
  "settings.currency.usd": "USD",
  "settings.currency.eur": "EUR",
  "settings.country.label": "Země ve které se nacházím",
  "settings.country.cz": "Česká Republika",
  "settings.country.svk": "Slovensko",
  "settings.delete_account": "Smazat účet",
  "settings.access_requests": "Žádosti o zpřístupnění přání",
  "settings.popup.invalidInput.title": "Chyba",
  "settings.popup.invalidInput.description":
    "Nezadali jste správné číslo účtu nebo adresu bitcoin peněženky.",
  "settings.confirmDeleteAccount.title": "Smazat účet",
  "settings.confirmDeleteAccount.description":
    "Jste si jistí, že chcete smazat tento účet?",
  // 'settings.friendRemoved.title': '{name_nominativ} byl odstraněn z přátel',
  "settings.friendRemoved.description":
    "{name_nominativ} byl odebrán z přátel.",
  "settings.information.title": "Informace",
  "settings.allowedUsers.title": "Vyberte přátele, kteří uvidí toto přání",
  "settings.allowedUsers.close": "Vybrat přátele",
  "settings.aboutWowee.title": "Jak to funguje?",
  "settings.selectRecipient.title": "Vyber příjemce přání",
  "settings.changePicture": "Změnit profilový obrázek",
  "settings.changePicture.title": "Změna profilového obrázku",
  "settings.userLogedOut.title": "Došlo k odhlášení uživatele",
  "settings.userLogedOut.description.first":
    "Byli jsme nuceni vás odhlásit z důvodu údržby Wowee.",
  "settings.userLogedOut.description.second":
    "Pro opětovné přihlášení stistkněte tlačítko přihlásit",
  "settings.setNewPassword.title": "Změna hesla",
  "settings.setNewPassword.currentPassword": "Aktuální heslo",
  "settings.setNewPassword.newPassword": "Nové heslo",
  "settings.setNewPassword.newPasswordAgain": "Nové heslo pro kontrolu",
  "settings.setNewPassword.confirm": "Změnit heslo",
  "settings.setNewPassword.passwordChanged": "Heslo bylo úspěšně změněno",
  "settings.setNewPassword.passwordError":
    "Špatně zadané původní heslo nebo se nová hesla neshodují",

  "access_requests.empty": "Nemáte žádné čekající žádosti.",
  "access_requests.title": "Žádosti o zpřístupnění přání",
  "access_requests.user_asked": "{name_nominativ} žádá o zpřístupnění přání",
  "access_requests.accept": "Potvrdit",
  "access_requests.accept.done": "Potvrzeno",
  "access_requests.accept.error": "Potvrzení selhalo",
  "access_requests.decline": "Zamítnout",
  "access_requests.decline.done": "Zamítnuto",
  "access_requests.decline.error": "Zamitnutí selhalo",

  "user.profile": "{name_nominativ}",
  "user.name": "{first_name} {last_name}",
  "user.noWishes":
    " {name_nominativ} momentálně nemá žádnou veřejnou sbírku, na kterou byste mohli přispět. Registrujte se a vytvořte ji.",
  //  'user.profile.non-logged.noWishes': '{name_nominativ} momentálně nemá žádnou veřejnou sbírku, na kterou byste mohli přispět. Registrujte se a vytvořte ji pro něj.',
  "user.profile.non-logged.hasWishes":
    " {name_dativ} můžete přispět na nějakou {name_posesiv} sbírku.",
  // 'user.profile.non-logged.heHasWishes': ' {name_dativ} můžete přispět na nějakou jeho sbírku.',
  // 'user.profile.non-logged.sheHasWishes': ' {name_dativ} můžete přispět na nějakou jeho sbírku.',
  "user.profile.non-logged":
    "Vítejte na Wowee - služba, která spojuje kamarády.",
  "user.add_user": "Přidat do přátel",
  "user.remove_user": "Odebrat z přátel",
  "user.request_sent": "Žádost o přátelství odeslána",
  "user.no_friend":
    "Tento uživatel není ve tvých přátelích. Chceš-li mu vytvořit dárek, nejdříve si jej přidej do přátel a vyčkej na jeho potvrzení.",

  "thanks.fit.title": "Fakulta informačních technologií ČVUT v Praze",
  "thanks.fit.subtitle":
    "Wowee bylo vytvořeno převážně na půdě ČVUT FIT v Praze v rámci výuky a poskytlo prostor pro vzájemné schůzky realizačního týmu.",
  "thanks.chludil":
    "Jiří je klíčový vyučující, jenž otevřel dveře k realizaci nejenom Wowee, ale i dalších projektů. Po celou dobu podporoval vedoucího projektu Bc. Michala Maněnu a vedl záverečné práce první vlny studentů, jež Wowee tvořili.",
  "thanks.valenta":
    "Michal, jakožto vedoucí katedry Softwarového inženýrství, formálně zaštítil celou realizaci Wowee na své katedře. Bez něj by Wowee nikdy nevzniklo.",
  "thanks.paus":
    "Petr je vedoucím bakalářských prací, jež v roce 2018 budou obhajovat studenti 2. vlny vývoje Wowee.",
  "thanks.buchtela":
    "David je vedoucím bakalářských prací, jež v roce 2018 budou obhajovat studenti 2. vlny vývoje Wowee.",
  "thanks.hoffman":
    "Honza je autorem uživatelského rozhraní a byl oponentem několika bakalářských prací.",
  "thanks.maca":
    "Jindra vedl v rámci webového týmu skupinu studentů, jež byli odpovědní za výstupní kvalitu aplikace.",

  "thanks.oponents.title": "Oponenti kvalifikačních prací",
  "thanks.oponents.subtitle":
    "Níže uvedení pedagogové vypracovali oponentní posudky klasifikačních prací studentů, jež se na vývoji podíleli.",
  "thanks.oponents.klan":
    "Docent Klán oponoval bakalářskou práci zabývající se online platebními metodami.",
  "thanks.oponents.vitvar":
    "Docent Vitvar byl oponentem bakalářské práce zabývající se komparací ReST a GraphQL.",
  "thanks.oponents.vynikarova":
    "Dr. Vynikarová byla oponentem bakalářské práce zabývající se provozem iOS aplikace Wowee v globálním prostředí.",
  "thanks.oponents.kucera":
    "Dr. Kučera byl přísným oponentem bakalářské práce zabývající se GDPR ve webových službách, jejíž praktická část se zaměřuje na Wowee.",
  "thanks.oponents.jeschke":
    "Lukáš byl oponentem dvou bakalářských prací s orientací na backend.",
  "thanks.oponents.jirkovsky":
    "Inženýr Jirkovský byl oponentem bakalářské práce zabývající se kvalitou multiplatformních aplikací.",
  "thanks.oponents.stepan":
    "Inženýr Štěpán byl oponentem bakalářské práce Android verzí Wowee.",
  "thanks.oponents.bliznicenko":
    "Inženýr Blizničenko byl oponentem bakalářské práce zabývající se backendem Wowee.",

  "thanks.mobile-app.title": "Vývojáři mobilní verze",
  "thanks.mobile-app.subtitle":
    "Základní kámen pro vývoj Wowee položili v letech 2016 až 2017 níže uvedení studenti, resp. absolventi ČVUT FIT v rámci kurzů Týmový softwarový projekt 1 a 2. Vytvořili první verzi API a mobilní verze aplikací, na jejichž vývoji pokračují další ročníky studentů.",
  "thanks.bachelor-thesis": "bakalářské práci",
  "thanks.mobile-app.balatsko.1":
    "Maks byl vedoucím týmu a spoluautor API. Ve své ",
  "thanks.mobile-app.balatsko.2":
    " navrhnul a prototypoval službu, jež slouží k doporučování vhodného dárku pro přátele.",
  "thanks.mobile-app.kuzmovych":
    "Yevhen byl hlavím vývojářem první verze API, jež popsal ve své ",
  "thanks.mobile-app.arkhipov":
    "Gleb je spoluautorem první verze iOS aplikace ElateMe, na níž psal svou ",
  "thanks.mobile-app.terokhin":
    "Yegor je spoluautorem první verze iOS aplikace Wowee, na níž psal svou ",
  "thanks.mobile-app.solovev":
    "Georgii navrh a implementoval první verzi Android aplikace Wowee ",
  "thanks.mobile-app.laskov":
    "Boris se v rámci výuky podílel na tvorbě Android klienta.",
  "thanks.mobile-app.liferov":
    "Ilia je vývojářem Android aplikace, v níž pokračoval po Georgii Sovovevem a své snažení zvěčnil ve své ",
  "thanks.mobile-app.tu-do.1":
    "Tuan je ekonomicky založený student FIT, jež provádí rešerši ke globálnímu provozu aplikace a vývojář iOS aplikace. Ve své bakalářské práci se věnoval ",
  "thanks.mobile-app.tu-do.2": "provozu iOS aplikace v globálním prostředí",
  "thanks.mobile-app.stanovcak":
    "Tomáš pokračuje na vývoji iOS klienta v akademickém roce 2018/2019 v kurzu BI-SP1",
  "thanks.mobile-app.aron":
    "Lukáš pracoval na vývoji Android klienta v akademickém roce 2018/2019 v kurzu BI-SP1",
  "thanks.mobile-app.renata":
    '"Renata" pracovala na vývoji Android klienta v akademickém roce 2019/2020 v kurzu BI-SP2',
  "thanks.mobile-app.tony":
    '"Tony" pracoval na vývoji iOS klienta v akademickém roce 2019/2020 v kurzu BI-SP2',

  "thanks.web-app.title": "Vývojáři webové verze",
  "thanks.web-app.subtitle":
    "V letech 2017 až 2018 pracovali níže uvedení studenti na realizaci webové verze Wowee",
  "thanks.web-app.martinek.1":
    "Michal byl vedoucím vývojářem webové verze. Obhájil bakalářskou práci na téma ",
  "thanks.web-app.martinek.2": "Komparace ReST a GraphQL",
  "thanks.web-app.cerny":
    "Michal byl jeden z teamleaderů webové verze. Též pracoval na implementaci iOS verze.",
  "thanks.web-app.mokos":
    "David byl jeden z teamleaderů, jež měl na starosti vývoj webu a management kvality.",
  "thanks.web-app.levinzon": "Roman byl vývojářem webové verze.",
  "thanks.web-app.rovnak": "Ruda byl vývojářem webové verze.",
  "thanks.web-app.hrachovina":
    "Lukáš byl a je vývojářem další verze API, na němž obhájil téma své ",
  "thanks.web-app.bergl": "Štěpán byl vývojářem webové verze.",
  "thanks.web-app.lukasevic":
    "Luka byl vývojářem webové verze se specializací na online platební metody, čemuž se věnoval ve své ",
  "thanks.web-app.grofek.1":
    "Tomáš byl testerem API, jež své znalosti plně rovninul ve své bakalářské práci ",
  "thanks.web-app.grofek.2": "QA v multiplatformních aplikacích",
  "thanks.web-app.sanda.1": "Michal byl testerem webové aplikace. Svou ",
  "thanks.web-app.sanda.2":
    " pak přispěl k řešení problematiky GDPR. Na Wowee dále pokračoval i ve své diplomové práci ",
  "thanks.web-app.vybiral": "Tomáš byl vývojářem webové verze.",
  "thanks.web-app.bocan": "Peter byl testerem webové verze.",

  "thanks.partners.title": "Vývojáři sekce Pro partnery",
  "thanks.partners.subtitle":
    "Od února 2019 vyvíjíme nástroje, jenž umožní eshopům vytvářet dárky přímo z jejich stránek. Realizační tým tvoří:",
  "thanks.partners.votocek":
    "Jirka je ostřílený profesionál, jenž z vlastní iniciativy přišel s vytvořením doplňku pro eshopy.",
  "thanks.partners.venecek":
    "Jirka řídí tým studentů v akademickém roce 2018/2019 v kurzu BI-SP1. Má na starost analýzu požadavků a řízení týmu.",
  "thanks.partners.safarik":
    "Honza je architektem momentálně vyvíjené sekce Pro partnery.",
  "thanks.partners.picka": "Honza spoluvytváří sekci Pro partnery.",
  "thanks.partners.fryc": "Dominik spoluvytváří sekci Pro partnery.",

  "shoptetWidget.title": "Shoptet doplněk Wowee",
  "shoptetWidget.headline": "Shoptet doplněk Wowee",
  "shoptetWidget.shoptetOnly":
    '<p>Eshopům, které jsou provozovány na platformě Shoptet, umožňujeme instalaci <a href="https://doplnky.shoptet.cz/wowee">doplňku</a>, jenž umožní vytvoření sbírky přímo z detailu produktu.</p>',
  "shoptetWidget.howItWorks": `
    <h3>Jak Wowee funguje?</h3>
    <p>Naše video vám to vysvětlí za 1 minutu!</p>  
  `,
  "shoptetWidget.content": `
    <h3>Co doplněk umožní?</h3>
    <ul>
    <li>Rychle a jednoduše založit sbírku na vybrané zboží z e-shopu</li>
    <li>Získat peníze na vysněný dárek přímo od svých přátel (nejčastěji jde o narozeninové nebo svatební dary)</li>
    <li>Propagovat sbírku na sociálních sítích i dalšími kanály, a tím promovat i vaše produkty a e-shop</li>
    <li>Importuje produkty z eshopu na Wowee. Uživatelé pak v nich mohou vyhledávat a jsou jim nabízeny jako dárky.</li>
    </ul>
    <p>Běží-li váš eshop na Shoptetu, <a href="https://doplnky.shoptet.cz/wowee">nainstalujte si doplněk</a> ještě dnes a získejte nové zákazníky.</p>
  `,
  "shoptetWidget.instalation": `
    <h3>Jak doplněk nainstalovat?</h3>
    <p>Po instalaci vám přijde potvrzovací email na adresu uvedenou jako konktatní u vašeho eshopu. V emailu kliknete na potvrzovací odkaz a nastavíte si heslo pro přístup k administraci.</p>
   `,
  "privacy.title": "Zásady ochrany osobních údajů",
  "privacy.content": `<h2>ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ SPOLEČNOSTI ELATEME s.r.o.</h2>
<p>
Tyto zásady ochrany osobních údajů ("Zásady") jsou nedílnou součástí obchodních podmínek společnosti ElateMe s.r.o., se sídlem Tyršova 33, Česká Kamenice, 407 21, zapsané u Krajského soudu v Ústí nad Labem oddíl C vložka 48007 (dále jen „ElateMe“).
Společnost ElateMe dbá na ochranu Vašich osobních údajů v souladu s platnou a účinnou legislativou, kterou od 25. 5. 2018 představuje zejména Nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen „Nařízení”).
</p>
<p>
Společnost ElateMe je provozovatelem webových stránek na adrese http://www.wowee.cz (dále jen "Portál"), prostřednictvím kterých poskytuje své služby (dále jen "Služby"). Zásady se vztahují na všechny osobní údaje zpracovávané společností ElateMe na základě plnění smluvního vztahu, právní povinnosti, oprávněného zájmu nebo uděleného souhlasu, a to prostřednictvím Portálu společnosti ElateMe. Zásady popisují způsoby využívání a ochrany osobních údajů ze strany společnosti ElateMe.
</p>
<p>
	Společnost ElateMe může měnit a doplňovat tyto Zásady kdykoli pomocí umístění revidované verze na Portálu a taková změna a doplnění nabude účinnost k datu takového umístění.
	</p>
    <h3>Správce a jeho kontaktní údaje</h3>
    <p>
        Správcem osobních údajů ve smyslu čl. 4 odst. 7 Nařízení je společnost ElateMe.
    </p>
    <p>
        Veškerou komunikaci týkající se ochrany osobních údajů, či upozornění na porušení práv, zasílejte prostřednictvím emailové adresy společnosti ElateMe na adresu: info@wowee.cz nebo na poštovní adresu: Tyršova 33, Česká Kamenice, 407 21.
    </p>
    <p>
        Společnost ElateMe nejmenovala pověřence pro ochranu osobních údajů ve smyslu čl. 37 Nařízení.
    </p>    
	<h3>Osobní údaje, které společnost ElateMe shromažďuje</h3>
	<p>
Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě. Identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.	
    </p>
	<p>
	K založení Vašeho účtu k využívání Služeb poskytovaných společností ElateMe na Portálu (dále jen „registrace“), vyžaduje společnost ElateMe poskytnutí následujících osobních údajů: jméno, příjmení, datum narození, emailová adresa a číslo bankovního účtu.
	</p>
	<p>
	Údaje týkající se Vašeho bankovního účtu jsou určeny výhradně v souvislosti s převodem finančních prostředků pro Sbírku, kterou jste založili nebo přispěli. Společnost ElateMe se zavazuje užívat tyto informace k založení Vašeho účtu, ke správě účtu a k zajištění Vašeho přístupu ke Službě a k jejím aktualizacím. Souhlas se zpracováním Vašich osobních údajů je vyžadován při registraci Služby. Tento souhlas můžete kdykoli odvolat, a to prostřednictvím emailu zaslaného na emailovou adresu společnosti ElateMe info@wowee.cz s uvedením Vašeho jména, příjmení a adresy z důvodu Vaší identifikace.
	</p>
	<p>
	V případě používání Portálu, a to i bez registrace, zaznamená společnost ElateMe Vaši IP adresu a standardní údaje, jako je typ Vašeho prohlížeče a stránky, které jste si na Portálu prohlíželi. Tyto informace slouží k monitorování a k prevenci podvodů, diagnostice problémů a ke zpracování statistických údajů, které jsou anonymní a neuvádějí Vaše osobní údaje.
	</p>
	<p>
	Při poskytování Služeb pomáhají společnosti ElateMe tzv. cookies a internetové beacons. Jejich podrobnější vymezení, druhy a účely jejich užívání společností ElateMe naleznete v Zásadách níže.
    </p>

	<h3>Účely zpracování osobních údajů</h3>
	<p>
	Vámi poskytnuté údaje v rámci registrace či při prohlížení Portálu společnost ElateMe použije k účelům uvedeným a dále jen k těmto účelům:

	<ol>
		<li>vyhovění Vaší žádosti o Službu, tj. zejména ke zpracování Vašich údajů určených k výměně mezi Přispěvovatelem a Příjemcem sbítky a dále pak;
        <li>správa uživatelských účtů (tj. zejména vyhovění Vaší žádosti o Službu, založení Vašeho účtu, správa Vašeho účtu a zajištění Vašeho přístupu ke Službě a k jejím aktualizacím);
        <li>kontaktování za účelem řešení problémů souvisejících s využíváním Služby nebo Portálu;
        <li>zaslání informace o Službě (tj. potvrzení, oznámení, aktualizace a podpůrná a administrativní sdělení);
        <li>zasílání obchodních sdělení, nabídek o výrobcích a službách a akcích pořádaných společností ElateMe a činění dalších marketingových aktivit;
        <li>zobrazování reklam na základě Vašeho zájmu;
        <li>plnění zákonných povinností společnosti ElateMe;
        <li> zlepšování kvality poskytované Služby a Portálu.	
	</ol>

    <h3>Udělení souhlasu</h3>
    <p>Jedním ze zákonných důvodů zpracování osobních údajů je udělení Vašeho souhlas se zpracováním osobních údajů ve smyslu ustanovení čl. 6, odst. 1., písm. a) Nařízení. O udělení souhlasu se zpracováním Vašich osobních údajů Vás společnost ElateMe žádá zejména při:
    </p>
    <ul>
        <li>zasílání obchodních sdělení a nabídek služeb a produktů;</li>
        <li>užívání tzv. cookies pro sociální média a cookies pro reklamu</li>
    </ul>
    <p>
        Souhlas udělený společnosti ElateMe se zpracováním Vašich osobních údajů máte právo kdykoliv odvolat, a to prostřednictvím emailu zaslaného na emailovou adresu společnosti ElateMe info@wowee.cz s uvedením Vašeho jména, příjmení a adresy z důvodu Vaší identifikace. Odvoláním souhlasu není dotčena zákonnost zpracování vycházející ze souhlasu, který byl dán před jeho odvoláním.
    </p>
    
    <h3>Doba zpracování osobních údajů</h3>
    <p>Osobní údaje budou společností ElateMe zpracovávány:</p>
    <ul>
      <li>v případě zpracovávání osobních údajů na základě registrace - po dobu registrace;
      <li>v případě zpracovávání osobních údajů v rámci zveřejněného projektu - po dobu15 let od zveřejnění projektu na Portálu;
      <li>v případě zpracovávání osobních údajů na základě souhlasu - po dobu od poskytnutí souhlasu do jeho odvolání.
    </ul>
    <p>
    Po uplynutí doby uchovávání osobních údajů společnost ElateMe osobní údaje vymaže.
    </p>
    
    <h3>Poskytování osobních údajů třetím osobám</h3>
	<p>
	Není-li v těchto Zásadách stanoveno jinak, společnost ElateMe bez Vašeho výslovného souhlasu nepronajímá ani neprodává žádné osobně identifikovatelné údaje, shromážděné prostřednictvím Portálu nebo Služby, ani je nesdílí s jinými osobami nebo nespřízněnými společnostmi, s výjimkou těchto případů:
	<ul>
	<li>poskytování těchto údajů nezávislým dodavatelům, poradcům a jiným poskytovatelům služeb, kteří spolupracují se společností ElateMe a potřebují přístup k Vašim údajům, aby mohli vykonávat tuto činnost;
	<li>dodržování právních předpisů nebo v reakci na požadavky právních předpisů;
	<li>ochrana práv a majetku společnosti ElateMe, jejích zástupců, uživatelů a jiných osob, zejména za účelem vymáhání jejích smluv, zásad a uživatelských podmínek a v naléhavých případech na ochranu vlastní bezpečnosti společnosti ElateMe, uživatelů jejích služeb nebo jakékoli jiné osoby;
	<li>v souvislosti s jakoukoli fúzí, prodejem majetku společnosti, financováním nebo nabytím všech nebo části podniku společnosti ElateMe ze strany jiné společnosti nebo v průběhu takového procesu.
	<li>Společnost ElateMe bez Vašeho souhlasu ze zásady nezpřístupňuje osobní údaje shromážděné po internetu třetími osobám pro účely přímého marketingu. Pokud si budete přát tento souhlas kdykoli po jeho udělení odvolat, sdělte to společnosti ElateMe na e-mailovou adresu info@wowee.cz.
	</ul>
	
	<h3>Práva subjektu údajů</h3>
    <p>Jako subjekt údajů dle GDPR, jehož osobní údaje jsou zpracovávány, máte níže uvedená práva, které můžete kdykoliv uplatnit. Jedná se o:</p>
    <ul>
      <li>právo na přístup ke svým osobním údajům (tj. právo získat informace, zda jsou Vaše údaje zpracovávány, a pokud ano, tak máte právo k nim získat přístup);
      <li>právo na opravu osobních údajů (tj. žádat opravu pokud zjistíte, že společnost ElateMe zpracovává nepřesné nebo nepravdivé údaje);
      <li>právo požadovat vysvětlení (tj. pokud máte podezření, že zpracování osobních údajů narušuje ochranu Vašeho osobního a soukromého života nebo dochází ke zpracování v rozporu s právními předpisy);
      <li>právo na omezení zpracování osobních údajů (tj. právo požadovat dočasné omezení zpracovávání Vašich osobních údajů);
      <li>právo na výmaz osobních údajů (tj. v případě pokud Vaše údaje již nejsou potřebné pro účely, pro které byly zpracovávány);
      <li>právo vznést námitku proti zpracování osobních údajů (společnost ElateMe je povinna prokázat, že existuje závažný oprávněný důvod pro zpracování osobních údajů, který převažuje nad Vašimi zájmy nebo právy a svobodami);
      <li>právo na přenositelnost údajů (tj. právo požadovat, aby Vaše údaje dostala třetí osoba);
      <li>právo kdykoliv odvolat souhlas se zpracováním Vašich osobních údajů;
      <li>právo podat stížnost u Úřadu pro ochranu osobních údajů (tj. v případě, že se domníváte, že bylo porušeno Vaše právo na ochranu osobních údajů).	
    </ul>
    
    <h3>Bezpečnostní opatření a sdělení osobních údajů</h3>
    <p>
      Účet každého uživatele Služby je chráněn heslem, které si sami zvolíte. Doporučujeme heslo uchovávat v tajnosti a nesdělovat jej třetím osobám, a to ani prostřednictvím emailu, v kterém bude heslo vyžadováno. Společnost ElateMe nezasílá uživatelům Služby emailové zprávy, které obsahují požadavek na zadání hesla do emailu. V případě zapomenutého hesla, obdrží uživatel, na svou žádost a na emailovou adresu uvedenou v registraci, email obsahující dočasné heslo k přístupu na svůj účet, kde si zvolí nové heslo.
    </p>
    <p>
        Společnost ElateMe neodpovídá za zneužití poskytovaných údajů, ke kterému došlo v důsledku neoprávněného přístupu třetí osoby, či v průběhu přenosu dat v rámci komunikačních sítí. Uživatel bere na vědomí, že údaje a informace poskytnuté na internetu např. v rámci diskusního fóra, chatu či emailu, mohou být zneužity třetími osobami. Při uvádění Vašich údajů prostřednictvím internetu buďte proto obezřetní.
    </p>
    <p>
        Společnost ElateMe uchovává Vaše osobní údaje v souladu s nejvyššími bezpečnostními standardy, prostřednictvím široké škály bezpečnostních opatření, včetně šifrovacích a ověřovacích nástrojů, aby zabránila neoprávněnému přístupu, pozměnění, zveřejnění nebo zničení Vašich osobních údajů a zachovávala jejich utajení. Vaše osobní údaje jsou chráněny firewally a jsou přístupné pouze omezenému počtu osob, které jsou povinny uchovávat tyto údaje v tajnosti.    
    </p>

	<h3>Cookies a internetové beacons</h3>
	<p>
      Aby Vám společnost ElateMe mohla poskytovat služby přizpůsobené Vaším potřebám, používá tzv. cookies (session ID cookies a trvalé cookies) k uchovávání a příležitostnému sledování Vašich návštěv Portálu. Cookie je malý textový soubor, který je uložen ve Vašem počítači pro účely zaznamenávání. Společnost ElateMe užívá cookies, aby Vám usnadnila navigaci po Portálu. Po zavření Vašeho prohlížeče platnost session ID cookie uplyne. Trvalé cookie zůstává na Vašem pevném disku po delší dobu. Společnost ElateMe rovněž ukládá rozlišení Vaší obrazovky a zjišťuje, zda je ve Vašem prohlížeči povolen JavaScript k usnadnění prohlížení Portálu. Většina internetových prohlížečů je nastavena tak, že automaticky akceptuje cookies.
    </p>
    <p>
        Na Portálu společnosti ElateMe používáme v zásadě dvě kategorie souborů cookies:
    </p>
    <ul>
      <li>Funkční: Tyto soubory cookies jsou vyžadovány pro základní fungování stránek, a proto jsou vždy zapnuté; patří k nim cookies, které umožňují si Vás při procházení našich webových stránek zapamatovat již během jediné návštěvy. Tyto cookies dále pomáhají v zabezpečení a při plnění požadavků stanovených právními předpisy.
      <li>Sociální media a reklama: Cookies pro sociální média nabízejí možnost propojit Vás se sociálními sítěmi a sdílet na nich obsah z našich Webových stránek. Cookies pro reklamu (cookies třetích stran) shromažďují informace napomáhající lépe přizpůsobovat reklamu Vašim zájmům, a to na našich webových stránkách i mimo ně. V některých případech je součástí těchto cookies i zpracování Vašich osobních údajů. Odmítnutí těchto cookies může mít za následek zobrazení reklamy, která pro Vás nebude tolik relevantní, nebo nemožnost účinně se propojit s účty na Facebooku, Twitteru či jiných sociálních sítích, a/nebo neumožnění, abyste na sociálních médiích sdíleli obsah. Cookies pro sociální média a pro reklamu jsou společností ElateMe zpracovávány pouze na základě Vašeho souhlasu.
    </ul>
    <p>
    Cookies můžete obvykle odstranit nebo odmítnout tak, že se řídíte pokyny v podpůrném souboru svého internetového prohlížeče. Pokud však odstraníte cookies, nemusíte mít možnost plně využívat všech aspektů Portálu nebo Služby. Vaše nastavení souhlasu s cookies můžete změnit zde.
    </p>

<p>
Společnost ElateMe může shromažďovat údaje při využití Portálu také pomocí internetových beacons. Tyto naváděče jsou elektronické obrázky, které lze užívat na Portálu v souvislosti se Službami nebo v e-mailu, který zašle společnost ElateMe. Ta užívá beacons k předávání cookies, k počítání návštěv a k tomu, aby Vám sdělila, zda byl nějaký e-mail otevřen a vyřízen. Společnost ElateMe může rovněž shromažďovat informace o Vašem počítači nebo o jiném přístupovém zařízení ke zmírnění rizik a k prevenci podvodů.
</p>
ElateMe s.r.o. Privacy Policy v.1

Datum účinnosti: 29.4.2020

<h3>Souhlas se zpracováním osobních údajů a poučení subjektu údajů</h3>
<h4>Identifikace správce</h4>
<p>
    ElateMe s.r.o. <br />
IČO 06226426<br />
Sídlem Česká Kamenice, Tyršova 33, PSČ 40721<br />
Kontaktní údaje: info@wowee.cz<br />
</p>
<h4>Osobní údaje, které budou zpracovány:</h4>h4>
<ul>
  <li>jméno a příjmení
  <li>emailová adresa
</ul>

<h4>Účelem zpracování osobních údajů je:</h4>
<p>Zasílání obchodních sdělení a newsletterů Správce.</p>

<h4>
Doba zpracování osobních údajů je:
</h4>
<p>Do odvolání souhlasu.</p>

<h4>Osobní údaje mohou být poskytnuty následujícím třetím osobám:</h4>
<p>
Osobní údaje jsou poskytovány třetím osobám spolupracujícím se správcem v rámci zjišťování spokojenosti zákazníků, rozesílání e-mailů, atp. (dále jen „zpracovatelé“). Dále mohou být osobní údaje předány osobám poskytujícím právní služby a institucím či subjektům, u kterých to vyžadují obecně závazné právní předpisy. Zpracovatelé jsou povinni dodržovat zásady GDPR stanovené Nařízením. Aktuální seznam zpracovatelů bude poskytnut na vyžádání.
</p>
<h4>Poučení subjektu údajů</h4>

<p>Správce tímto v souladu s ustanovením čl. 13 Nařízení Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně osobních údajů (dále jen „Nařízení“), informuje, že:
</p>
<ul>
  <li>osobní údaje subjektu údajů budou zpracovány na základě jeho svobodného souhlasu, a to za výše uvedených podmínek;
  <li>důvodem poskytnutí osobních údajů subjektu údajů je zájem subjektu údajů o zasílání obchodních nabídek Správce, což by bez poskytnutí těchto údajů nebylo možné;
  <li>při zpracování osobních údajů subjektu údajů nebude docházet k automatizovanému rozhodování ani k profilování vyjma profilování ve smyslu informace o oboru činnosti subjektu údajů, pokud uvedený údaj se svým souhlasem subjekt údajů poskytne;
  <li>Správce nejmenoval pověřence pro ochranu osobních údajů, nepověřil zpracováním osobních údajů žádného zpracovatele ani neurčil zástupce pro plnění povinností ve smyslu Nařízení;
  <li>Správce nemá v úmyslu předat osobní údaje subjektu údajů do třetí země, mezinárodní organizaci nebo jiným, než výše uvedeným třetím osobám;
  <li>subjekt údajů má právo kdykoliv odvolat svůj souhlas se zpracováním osobních údajů, právo požadovat od Správce přístup ke svým osobním údajům, jejich opravu nebo výmaz, popřípadě omezení zpracování, a vznést námitku proti zpracování, má právo na přenositelnost těchto údajů k jinému správci, jakož i právo podat stížnost u Úřadu pro ochranu osobních údajů, má-li za to, že Správce při zpracování osobních údajů postupuje v rozporu s Nařízením.
</ul>`,
  "general.title": "Obchodní podmínky",
  "general.content": `<h2>OBCHODNÍ PODMÍNKY SPOLEČNOSTI ElateMe s. r. o.</h2>
            <p>
              Pečlivě si přečtěte tyto obchodní podmínky (dále jen „Obchodní podmínky“)
              předtím, než začnete využívat služby zprostředkované společností    <strong>ElateMe s.r.o.</strong>, IČ 06226426, se sídlem Tyršova 33, Česká Kamenice, 407 21, zapsané v obchodním rejstříku vedeném Krajským
              soudem v Ústí nad Labem odd. C vložka 48007.
            </p>
            <p>
              Tyto Obchodní podmínky stanoví zásady provozu a užívání webových stránek
              wowee.cz (dále jen „Webové stránky“ nebo také „Portál“) pro Autory sbírek
              zveřejněných na Webových stránkách a jejich Uživatele a zásady užívání
              služeb poskytovaných společností <strong>ElateMe s.r.o. </strong>
              prostřednictvím Webových stránek (dále jen „Služby“).
            </p>
            <p>
              Vstupem, užíváním, prohlížením, přenosem nebo uložením Webových stránek,
              nebo kterékoli ze služeb společnosti <strong>ElateMe s.r.o.</strong>,
              především pak zasláním žádosti o zveřejnění Sbírky na Webových stránkách
              potvrzujete, že jste si tyto Obchodní podmínky přečetli a souhlasíte a
              zavazujete se tyto Obchodní podmínky dodržovat.
            </p>
            <p>
              Nedílnou součástí těchto Obchodních podmínek jsou <a href="/privacy">Zásady ochrany osobních
              údajů</a>. Společnost    <strong>ElateMe s.r.o.</strong> si vyhrazuje právo dle svého vlastního
              uvážení změnit nebo nahradit kteroukoli z podmínek, nebo kdykoli změnit,
              pozastavit nebo ukončit Službu (zejména dostupnost jakékoli funkce nebo
              obsahu), uveřejněním těchto změn na Webových stránkách nebo v e-mailovém
              oznámení zaslaném Uživatelům.
            </p>
            <p>
              Společnost <strong>ElateMe s.r.o.</strong> může rovněž stanovit omezení
              určitých funkcí a služeb nebo omezit přístup ke kterékoli nebo veškerým
              Službám, a to bez předchozího oznámení.
            </p>
            <p>
              <h3>Definice základních pojmů</h3>
            </p>
            <p>
              <strong>ElateMe s.r.o.</strong>
              <strong>, Provozovatel</strong>
              – společnost <strong>ElateMe s.r.o.</strong>, IČ 06226426, se sídlem Tyršova 33, 407 21 Česká Kamenice, zapsaná v obchodním rejstříku vedeném
              Krajským soudem v Ústí nad Labem odd. C vložka 48007, provozující webové stránky na
              adrese wowee.cz
            </p>
            <p>
              <strong>Webové stránky, Portál</strong>
              – stránky provozované společnosti <strong>ElateMe s.r.o.</strong> umístěné
              na adrese wowee.cz
            </p>
            <p>
              <strong>Sbírka, Neveřejná sbírka</strong>
              – je návrh na dárek nebo finanční sbírku za účelem transparentně zajistit
              organizaci výběru finančních příspěvků. Výtěžek z této sbírky je určen pro
              Příjemce sbírky.
            </p>
            <p>
              <strong>Autor sbírky </strong>
              – fyzická osoba, která vytvoří Sbírku a zašle Provozovateli žádost o
              zveřejnění Sbírky.
            </p>
            <p>
              <strong>Realizovaná sbírka – </strong>
              jedná se o sbírku, jež byla ukončena Autorem sbírky. Finanční prostředky
              byly převedeny na účet Příjemce sbírky. Sbírka má charakter odměnového crowdfungu. Jako odměna
              pro Donátory postačuje poděkování od Autora sbírka, popř. Příjemce sbírky. 
            </p>
            <p>
              <strong>Veřejná sbírka sbírka – </strong>
              je sbírka, která podléhá zákonu č. 117/2001 Sb.
            </p>
            <p>
              <strong>Příjemce sbírky - </strong>
              uživatel, jež je vybrán Autorem sbírky, na jehož bankovní účet bude
              převeden výtěžek ze Sbírky.
            </p>
            <p>
              <strong>Uživatel</strong>
              – jakákoli fyzická osoba (Donátor, Autor sbírky, Příjemce sbírky,
              neregistrovaná osoba), která využívá služby poskytované společností    <strong>ElateMe s.r.o. </strong>na Webových stránkách.
            </p>
            <p>
              <strong>Donátor – </strong>
              osoba, která přispěla na Sbírku.
            </p>
            <p>
              <strong>Příspěvek – </strong>
              finanční prostředky přispěné Donátorem na Sbírku.
            </p>
            <p>
              <strong>Oprávnění uživatelé</strong>
            </p>
            <p>
              Služby jsou k dispozici pouze osobám ve věku minimálně 15 let. Vstupem,
              užíváním, prohlížením, přenosem nebo uložením Webových stránek či zasláním
              Sbírky a jakoukoli další aktivní účastí prohlašujete a zaručujete se, že
              fyzickou osobou starší 15 let, a že veškeré registrační údaje, které jsou
              Vámi předkládány, jsou přesné a pravdivé.
            </p>
            <p>
              Provozovatel může dle svého výlučného uvážení odmítnout nabízet Službu
              kterékoli fyzické nebo právnické osobě.
            </p>
            <p>
              <strong>Registrace</strong>
            </p>
            <p>
              Podmínkou užívání určitých Služeb ze strany Provozovatele je registrace
              Uživatele u Provozovatele prostřednictvím služby Facebook.
            </p>
            <p>
              Uživatelé jsou povinni poskytnout Provozovateli přesné a úplné registrační
              údaje a tyto údaje pravidelně aktualizovat. Uživatel nesmí použít účet
              jiného Uživatele. V případě, že dojde k neoprávněnému použití účtu
              Uživatele, je tento Uživatel povinen písemně oznámit Provozovateli takovéto
              neoprávněné použití účtu nebo jiné porušení bezpečnosti účtu, o kterém se
              dozví.
            </p>
            <p>
              Společnost <strong>ElateMe s.r.o.</strong> si vyhrazuje právo zamítnout
              registraci Uživatele nebo ji zrušit, dle svého výlučného uvážení.
            </p>
            <p>
              <h3>Autoři sbírek – získávání peněz od Donátorů</h3>
            </p>
            <p>
              ElateMe je online systém a platforma, v rámci které lze získat financování
              pro přání Příjemce sbírky a určené Autorovi či 3. osobě. Provozovatel
              povoluje Autorům sbírky, kteří zapíší své Sbírky na seznam, aby Příjemci
              sbírky získali finanční prostředky od jiných Uživatelů (Donátorů).
              Provozovatel je zprostředkovatelem vztahu mezi Autory sbírek a ostatními
              Uživateli. Provozovatel umožňuje Autorům sbírek prezentovat své Sbírky na
              Webových stránkách.
            </p>
            <p>
              Veškeré Příspěvky prochází skrze podrozvahové účty společnosti    <strong>ElateMe s.r.o., </strong>které jsou pouhým transitním místem.
              Finálním příjemcem je Příjemce sbírky. Uživatel je povinen poskytnout    <strong>ElateMe s.r.o.</strong> veškerou součinnost potřebnou k provedení
              identifikace podle zákona č. 253/2008 Sb., o některých opatřeních proti
              legalizaci výnosů z trestné činnosti a financování terorismu a prokázat
              vlastnictví bankovního účtu. Pokud <strong>ElateMe s.r.o.</strong> bude mít
              jakékoli důvodné pochybnosti o osobě skutečného majitele účtu, nebo
              podezření z využití bankovního účtu k financování terorismu nebo legalizaci
              výnosů z trestné činnosti, nebo odmítne-li se Autor sbírky podrobit
              identifikaci, neprovede <strong>ElateMe s.r.o. </strong>převod finančních
              prostředků.
            </p>
            <p>
              Nárok Příjemců sbírek na zaslání cílové částky je splatný uplynutím 5 dnů
              po uplynutí doby finanční sbírky (výběru peněžních částek Sbírek).
            </p>
            <p>
              Po ukončení finanční sbírky jsou peníze převedeny na účet Příjemce sbírky.
            </p>
            <p>
              Provozovatel ani Příjemce sbírky nejsou oprávněni jakkoliv nakládat s
              těmito prostředky do doby ukončení Sbírky.
            </p>
            <p>
              Autoři sbírek zcela odpovídají za své Sbírky, zejména za poskytnutí
              přesných a nezavádějících informací a splnění veškerých závazků uvedených v
              zápisu jakéhokoli sbírky, který vytvoří. Provozovatel nedohlíží na kvalitu,
              plnění ani přesnost jakýchkoli Sbírek prezentovaných na Webových stránkách.
              Veškerá jednání s kterýmkoli Uživatelem nebo jakýmikoli třetími osobami
              nalezenými prostřednictvím Služby jsou vedeny výlučně mezi Autory sbírky a
              těmito Uživateli nebo třetími osobami.
            </p>
            <p>
              Provozovatel neodpovídá za žádnou škodu nebo ztrátu utrpěnou v důsledku
              interakcí mezi Uživatelem, třetími osobami a Autory sbírek, ani za aktivity
              Autorů sbírek, zejména za dodání zboží a služeb a jakékoli jiné podmínky,
              záruky nebo prohlášení související se seznamy sbírek na Webových stránkách
              Provozovatele.
            </p>
            <p>
              Provozovatel má právo kdykoliv Sbírku zrušit.
            </p>
            <p>
                V případě, že Sbírka vytvořená Autorem sbírky podléhá zákonu č. 117/2001 Sb., 
                zejména mají-li být Donátoři z předem neurčeného okruhu přispěvovatelů pro předem stanovený 
                veřejně prospěšný účel zejména humanitární nebo charitativní, rozvoj vzdělání, tělovýchovy nebo sportu, 
                nebo ochrana kulturních památek, tradic nebo životního prostředí, Provozovatel sbírku nezveřejní do doby, 
                než budou splněny zákonné povinosti. Autor sbírky bude kontaktován Provozovatelem a požádán o součinnost,
                který dle svého uvážení nahlásí konání veřejně prospěšné sbírky na místně příslušném úřadě.. 
                Bude-li ze strany Magistrátu sbírka povolena, Provozovatel může sbírku zveřejnit a konat dle  č. 117/2001 Sb.
            </p>
            <p>
                Ministerstvo vnitra eviduje dobročinnou sbírku konanou pořadatelem pod označením 
                „pomoc lidem v těžké situaci a podpora právnických osob“ na <a href="https://aplikace.mvcr.cz/seznam-verejnych-sbirek/Detail.aspx?id=11825">https://aplikace.mvcr.cz/seznam-verejnych-sbirek/Detail.aspx?id=11825</a>.
            </p>
            <p> 
                Činností Provozovatele není organizace shromažďování finančních prostředků pro politické strany nebo politická hnutí. 
                Pořadatel rovněž nikdy neshromažďuje finanční prostředky, aby tyto byly použity k církevním a náboženským účelům. 
                Pořadatel nikdy současně nekoná více dobročinných sbírek sloužících stejnému účelu
            </p>
            <p>
                Návrhy na uspořádání dobročinné sbírky budou primárně vyřizovány prostřednictvím portálu Wowee nebo 
                prostřednictvím e-mailové adresy poradna@wowee.cz. Navrhovatel by měl v návrhu uvést své kontaktní 
                údaje a představit účel dobročinné sbírky, tedy objasnit, s jakým problémem může dobročinná sbírka pomoci.
            </p>
            <p>
            Pořadatel zpravidla koná dobročinné sbírky shromažďováním finančních prostředků na bankovním účtu 
            zřízeném pro tento účel u banky. Pořadatel si vyhrazuje právo konat dobročinnou sbírku i jiným způsobem 
            nebo kombinací více způsobů předpokládaných aplikovatelnými právními předpisy.
            </p>
            <p>
                Informace o konaných dobročinných sbírkách jsou veřejně dostupné na portálu Wowee.
            </p>
            <p>
            Pořadatel účtuje o nákladech, výnosech, aktivech a pasivech jednotlivých dobročinných sbírek tak, 
            aby mohl prokázat soulad účetních záznamů s vyúčtováním dobročinné sbírky. 
            Na úhradu nákladů spojených s konáním dobročinné sbírky pořadatel používá část hrubého výtěžku 
            dobročinné sbírky maximálně do výše 5 %.
            </p>
            <p>
              <h3>Poplatky a platby společnosti <strong>ElateMe s.r.o.</strong></h3>
            </p>
            <p>
              Registrace a vedení účtu je zdarma. Výběr peněz prostřednictvím převodu na účet Uživatele je zpoplatněn 2% z vybrané částky.
              Provozovatel si vyhrazuje právo dle potřeby stanovit nové poplatky.
            </p>
            <p>
              <h3>Veřejná sbírka – požadavky na Veřejnou sbírku</h3>
              <p>
              Dobročinná sbírka je ukončena shromážděním finančních prostředků nebo uplynutím doby, jež byla stanovena pro její konání. 
                </p>
                <p>Po ukončení dobročinné sbírky pořadatel (a) poskytne čistý výtěžek dobročinné sbírky příjemci převodem 
                na předem určený bankovní účet, nebo (b) opatří věc potřebnou pro naplnění účelu dobročinné sbírky 
                a tuto převede do vlastnictví příjemce, případně obstará a umožní příjemci čerpat službu 
                potřebnou pro naplnění účelu dobročinné sbírky.</p>
                <p>Příjemce musí použít čistý výtěžek dobročinné sbírky nebo poskytnutou věc anebo čerpanou službu výhradně 
                ke stanovenému účelu dobročinné sbírky. Plnění této povinnosti kdykoli prokáže příslušenému úřadu 
                v souladu s § 24 odst. 5 z. č. 117/2001 Sb., o veřejných sbírkách.</p>
                <p>Dokončené dobročinné sbírky jsou zveřejňovány na portálu Wowee. </p>
                <p>Pořadatel si vyhrazuje právo ukončit dobročinnou sbírku i před uplynutím doby, jež byla stanovena pro její konání.</p>
                <p>Hodnotu poskytnutého příspěvku do dobročinné sbírky si přispěvatel může při splnění podmínek příslušné daňové právní úpravy odečíst od základu daně. Pořadatel za tímto účelem na žádost poskytne přispěvateli o poskytnutém příspěvku potvrzení.</p>
                <p>V souvislosti s poskytnutím příspěvku do dobročinné sbírky bude přispěvateli umožněno v libovolné výši přispět na činnost a provoz pořadatele. Takto shromážděné finanční prostředky jsou především použity na mzdy zaměstnanců, marketingové aktivity, vývoj platformy a poplatky spojené s provozem platební brány.</p>
                <p>Pořadatel nezveřejňuje ani neposkytuje žádné údaje o neregistrovaných či do aplikace neautorizovaných přispěvatelích s výjimkou případů, kdy s tím přispěvatelé předem vyslovili souhlas a případů, kdy to je to vyžadováno právními předpisy.</p>
              <h3>Neveřejná sbírka – požadavky na Sbírku</h3>
            </p>
            <p>
              Autor sbírky se zavazuje uvádět ke Sbírce zveřejněném na Webových stránkách
              a ke svým registračním údajům přesné a pravdivé informace, zejména je
              povinen uvádět a pravidelně aktualizovat své kontaktní údaje a číslo
              bankovního účtu, na který mají být poukázány finanční prostředky Příjemcům
              sbírky při úspěšném naplnění cílové částky Sbírky.
            </p>
            <p>
              Uživatelé se zavazují, že budou uvádět číslo bankovního účtu, jehož jsou
              majiteli a ke kterému má plné dispoziční právo.
            </p>
            <p>
              Autor sbírky může stanovit orientační cílovou částku Sbírky. Autor sbírky
              bere na vědomí, že cílová částka zahrnuje veškeré náklady související se Sbírkou,
              bankovní poplatky, daň z přidané hodnoty ve výši dle platných právních
              předpisů ke dni zveřejnění Sbírky. Z těchto důvodů Provozovatel doporučuje,
              aby si Autor sbírky propočítal důkladně veškeré náklady za případné zasílání odměn a
              další náklady související s realizací Sbírky.
            </p>
            <p>
              Autor sbírky zveřejní popis Sbírky vždy v českém jazyce a dále, dle jeho
              uvážení v dalších jazycích.
            </p>
            <p>
              Autorem sbírky zvolený název Sbírky ani popis Sbírky nesmí obsahovat návrh
              k prodeji zboží nebo služby, která není přímo předmětem Sbírky. Autor
              sbírky smí k prezentaci Sbírky připojit obrázky, fotografie, videa, hudbu,
              apod., v takovém případě se zavazuje neporušovat při prezentaci Sbírky
              žádná autorská práva, práva průmyslová, nepoužívat ochranné známky ve
              vlastnictví třetích osob bez jejich souhlasu a dodržovat zásady na ochranu
              práv duševního vlastnictví tak, jak je popsáno níže v Obchodních
              podmínkách. Autor sbírky není oprávněn při popisu Sbírky nebo odměny
              parazitovat na pověsti třetích osob, uvádět spojení Sbírky nebo odměn se
              zavedenými značkami, logy nebo ochrannými známkami třetích osob, pokud k
              tomuto nemá souhlas vlastníka značky, loga nebo ochranné známky.
            </p>
            <p>
              Autor sbírky se zavazuje nezveřejňovat v popisu Sbírky žádné nemravné,
              pohoršující, rasistické či obdobné informace či materiály. Provozovatel si
              vyhrazuje právo na zrušení Sbírky, který by jakékoli takové informace nebo
              materiály obsahoval.
            </p>
            <p>
              Autor sbírky bere na vědomí, že Donátor má v průběhu zveřejnění Sbírky
              právo na odstoupení od smlouvy, a to až do doby uhrazení částky na účet
              vedený Provozovatelem.
            </p>
            <p>
              Autor sbírky se zavazuje informovat o jakékoli změně ve Sbírce ostatní
              Uživatele, a to zveřejněním informace do popisu příslušné Sbírky.
            </p>
            <p>
              Autor sbírky se zavazuje splnit veškeré závazky vůči Uživatelům, které
              uvedl v popisu Sbírky, v kterém došlo k naplnění cílové částky, zejména se
              zavazuje dodržet termíny realizace Sbírky.
            </p>
            <p>
              <h3>Prohlášení Provozovatele</h3>
            </p>
            <p>
              Provozovatel nenese odpovědnost za Autorem sbírky zveřejněný popis Sbírky.
              Provozovatel si vyhrazuje právo na odstranění či změnu popisu Sbírky nebo
              odměny, dozví-li se o tom, že byla zveřejněním Sbírky porušena jakákoli
              práva třetích osob týkající se zejména práva autorského. Provozovatel
              uvádí, že není platformou, která zprostředkovává příspěvky, finanční sbírky
              na plnění konkrétních přání.
            </p>
            <h3>Práva duševního vlastnictví společnosti	<strong>ElateMe s.r.o.</strong></h3>
            <p>
              Veškerá práva související s Webovými stránkami a poskytovanými Službami, a
              jakýchkoli a veškerých jiných technologiích a jakémkoli obsahu (definovaném
              níže), vytvořeném nebo odvozeném na základě výše uvedeného, jsou ve
              výlučném vlastnictví Provozovatele.
            </p>
            <p>
              Užíváním Webových stránek nebo Služeb Provozovatele souhlasí Uživatel s
              tím, že Služba obsahuje obsah zvláště poskytnutý společností    <strong>ElateMe s.r.o.</strong>, případně jejími partnery, a že tento obsah
              je ve výlučném vlastnictví společnosti <strong>ElateMe s.r.o.</strong>,
              případně jejích partnerů a je chráněn autorskými právy, ochrannými
              známkami, servisními značkami, patenty, obchodními tajemstvími nebo jinými
              zákonem chráněnými právy a právními předpisy.
            </p>
            <p>
              Obsah Služby zahrnuje zejména videa, audioklipy, písemné komentáře z fór,
              informace, data, texty, fotografie, software, skripty, grafiku a
              interaktivní funkce, jež jsou vytvořeny, poskytnuty nebo jinak zpřístupněny
              Provozovatelem, popř. jejími partnery prostřednictvím Webových stránek nebo
              Služby. Uživatel se zavazuje dodržovat veškerá oznámení, informace a
              omezení týkající se autorských práv, která jsou uvedena v jakémkoli obsahu
              přístupném prostřednictvím Webové stránky nebo Služby.
            </p>
            <p>
              Společnost <strong>ElateMe s.r.o.</strong> uděluje oprávněnému Uživateli
              Služby nebo Webových stránek nevýhradní, nesublicencovatelnou a
              nepřevoditelnou licenci k užívání, modifikaci a reprodukci Obsahu, výlučně
              pro účely jeho použití v souvislosti se Službou. Užívání, reprodukce,
              modifikace, distribuce nebo uložení jakéhokoli Obsahu pro jakýkoli jiný
              účel je výslovně zakázáno, pokud je bez předchozího písemného souhlasu
              společnosti <strong>ElateMe s.r.o.</strong> nebo držitele autorských práv,
              uvedeného v oznámení o autorských právech, jež je součástí Obsahu.
            </p>
            <p>
              Uživatel nesmí (přímo ani nepřímo) činit následující:
            </p>
            <ul>
              <li>prodávat, licencovat, půjčovat, pronajímat nebo jinak užívat nebo
                využívat jakýkoli Obsah pro komerční účely nebo jakýmkoli způsobem,
                který porušuje práva kterékoli třetí osoby;
              </li>
              <li>
                dešifrovat, dekompilovat, demontovat, znovu vytvářet nebo se jinak
                pokoušet získat jakýkoli zdrojový kód nebo hlavní myšlenky nebo
                algoritmy kterékoli části Služby;
              </li>
              <li>
                modifikovat, překládat nebo jinak vytvářet odvozená díla z
                kterékoli části Služby;
              </li>
              <li>
                kopírovat, půjčovat, pronajímat, distribuovat nebo jinak převádět
                jakákoli práva, která získá na základě těchto Obchodních podmínek.
              </li>
            </ul>
            <h3>Práva duševního vlastnictví Uživatelů</h3>
            <p>
              Služba může poskytnout Oprávněným uživatelům možnost přidávat, vytvářet,
              nahrávat (upload), předkládat, distribuovat, shromažďovat nebo uveřejňovat
              (dále rovněž „předkládat“) obsah, videa, audioklipy, písemné komentáře z
              fór, data, texty, fotografie, software, skripty, grafiku nebo jiné
              informace na Webové stránky (dále společně jen jako „Uživatelské
              příspěvky“).
            </p>
            <p>
              Předložením Uživatelských příspěvků na Webové stránky nebo jinak
              prostřednictvím Služby činí Uživatel následující:
            </p>
            <ul>
              <li>
                uveřejňuje daný Uživatelský příspěvek a souhlas, že může být
                veřejně identifikováni prostřednictvím emailové adresy, jakožto
                registračního údaje, v souvislosti s jakýmkoli takovým Uživatelským
                příspěvkem;
              </li>
              <li>
                uděluje Provozovateli celosvětovou, nevýhradní, trvalou,
                neodvolatelnou, bezplatnou, sublicencovatelnou a převoditelnou
                licenci k užívání, editování, modifikaci, reprodukci, distribuci,
                zpracování odvozených děl, propagaci, realizaci a jinému úplnému
                využívání Uživatelských příspěvků v souvislosti se Službou a
                obchodními aktivitami Provozovatele (a jeho právních nástupců),
                včetně propagace a redistribuce celých Webových stránek nebo jejich
                části (a odvozených děl z nich) nebo Služby v jakýchkoli mediálních
                formátech, prostřednictvím jakýchkoli médií (včetně webových
                stránek třetích osob);
              </li>
              <li>
                tímto uděluje každému Uživateli Webových stránek anebo Služby
                nevýhradní licenci k přístupu k Uživatelským příspěvkům
                prostřednictvím Webových stránek a Služby, a k užívání, editování,
                modifikaci, reprodukci, distribuci, zpracování odvozených děl,
                propagaci a realizaci těchto Uživatelských příspěvků výlučně pro
                osobní nekomerční účely;
              </li>
              <li>
                prohlašuje a zaručuje se, že (i) vlastní nebo jinak ovládá veškerá
                práva na veškerý obsah svých Uživatelských příspěvků, nebo že obsah
                těchto Uživatelských příspěvků je veřejně přístupný, (ii) že má
                pravomoc jednat jménem vlastníků jakýchkoli práv, vlastnických
                titulů nebo podílů na jakémkoli obsahu svých Uživatelských
                příspěvků, smí užívat tento obsah, a udělit výše uvedená licenční
                práva, (iii) že má povolení užívat jméno a podobu každé
                identifikovatelné fyzické osoby a užívat identifikační nebo osobní
                údaje této osoby, dle Obchodních podmínek; a že (iv) je oprávněn
                udělit všechna výše uvedená práva k Uživatelským příspěvkům
                Provozovateli a všem uživatelům Služby;
              </li>
              <li>
                zavazuje se, že užívání nebo jiné využívání Uživatelských příspěvků
                Provozovatelem a užívání nebo jiné využívání Webových stránek a
                Služby Uživateli, dle těchto Obchodních podmínek, nenaruší ani
                neporuší práva kterékoli třetí osoby, zejména jakákoli práva na
                ochranu soukromí, autorská práva, smluvní práva nebo jakákoli jiná
                práva duševního vlastnictví nebo zákonem chráněná práva;
              </li>
              <li>
                je srozuměn a souhlasí s tím, že Provozovatel má právo vymazat,
                editovat, modifikovat nebo překládat jakékoli materiály, obsah nebo
                informace, které Uživatel předloží; a že za veškeré informace, jež
                jsou uveřejněny nebo soukromě zaslány prostřednictvím Webových
                stránek, výlučně odpovídá osoba, od které tento obsah pochází, a že
                Provozovatel neodpovídá za jakékoli chyby nebo opomenutí v
                jakémkoli obsahu; dále že Provozovatel se nemůže zaručit za
                identitu jakýchkoli jiných Uživatelů, se kterými můžete přijít do
                kontaktu během užívání Služby;
              </li>
              <li>
                zavazuje se, že bude dodržovat veškeré platné místní, státní,
                národní i mezinárodní zákony a předpisy.
              </li>
            </ul>
            <p>
              Provozovatel neschvaluje ani žádným způsobem nekontroluje jakékoli
              Uživatelské příspěvky. Provozovatel se nemůže zaručit za hodnověrnost
              jakýchkoli údajů, které o sobě poskytnou Uživatelé.
            </p>
            <p>
              Uživatel potvrzuje, že jeho přístup k veškerým Uživatelským příspěvkům za
              použití Služby je na jeho vlastní riziko, přičemž nesete výlučnou
              odpovědnost za jakoukoli škodu nebo ztrátu, kterou tím způsobí kterékoli
              osobě. Provozovatel nezaručuje, že kterýkoli Uživatelský příspěvek bude
              uveřejněn na Webových stránkách nebo prostřednictvím Služby. Provozovatel
              není povinen monitorovat Uživatelské příspěvky.
            </p>
            <p>
              Provozovatel si vyhrazuje právo kdykoli blokovat, odstranit, editovat nebo
              modifikovat jakékoli Uživatelské příspěvky z Webových stránek nebo Služby,
              bez předchozího oznámení a zdůvodnění (zejména po obdržení reklamací nebo
              obvinění od třetích osob nebo příslušných orgánů týkajících se těchto
              Uživatelských příspěvků, nebo pokud má Provozovatel podezření, že Uživatel
              porušil Obchodní podmínky), nebo bez jakéhokoli důvodu.
            </p>
            <h3>Zakázané jednání</h3>
            <p>
              Podmínkou užívání Služby je, že se Uživatel zaváže, že nebude Službu užívat
              pro žádný účel, který tyto Obchodní podmínky zakazují. Služba (zejména
              jakýkoli Obsah nebo Uživatelský příspěvek) je poskytována pouze pro omezené
              osobní nekomerční užívání (pokud Obchodní podmínky nepovolují něco jiného).
            </p>
            <p>
              Uživatel nesmí provádět, bez omezení (přímo ani nepřímo), kteroukoli z
              následujících aktivit:
            </p>
            <p>
              a) přijmout jakékoli opatření nebo nahrávat, načítat, uveřejňovat,
              předkládat ani jinak distribuovat nebo umožnit distribuci jakéhokoli obsahu
              prostřednictvím Služby, zejména jakýchkoli Uživatelských příspěvků, které:
            </p>
            <ul>
              <li>
                jsou nesprávné, zavádějící, nepravdivé nebo nepřesné;
              </li>
              <li>
                jsou nezákonné, výhružné, obtěžující, hanlivé, klamné, podvodné,
                zasahující do soukromí jiných osob, protiprávní, obscénní, urážlivé
                nebo vulgární;
              </li>
              <li>
                porušují jakýkoli patent, ochrannou známku, obchodní tajemství,
                autorské právo, právo na publicitu nebo jiné právo kterékoli jiné
                fyzické nebo právnické osoby, nebo porušují jakýkoli zákon nebo
                smluvní povinnost;
              </li>
              <li>
                představují nevyžádaný nebo neschválený reklamní nebo propagační
                materiál nebo jakýkoli reklamní e-mail (junk mail), spam nebo
                řetězový dopis; nebo
              </li>
              <li>
                obsahují softwarové viry nebo jakékoli jiné počítačové kódy,
                soubory nebo programy, jejichž cílem je narušení, poškození,
                omezení nebo zasahování do řádného fungování jakéhokoli softwaru,
                hardwaru nebo telekomunikačního zařízení, nebo poškození nebo
                získání neoprávněného přístupu k jakémukoli systému, datům, heslu
                nebo jiným informacím společnosti <strong>ElateMe s.r.o.</strong>
                nebo kterékoli třetí osoby.
              </li>
            </ul>
            <p>
              b) přijmout jakékoli opatření, které představuje nebo může představovat
              (dle rozhodnutí na základě výlučného uvážení Provozovatele) nepřiměřené
              nebo neúměrně vysoké zatížení infrastruktury Provozovatele;
            </p>
            <p>
              c) zasahovat nebo se pokoušet zasahovat do řádného fungování Služby nebo
              jakýchkoli aktivit prováděných v rámci Služby;
            </p>
            <p>
              d) obcházet jakákoli opatření, která Provozovatel může použít za účelem
              prevence nebo omezení přístupu ke Službě (nebo jiným účtům, počítačovým
              systémům nebo sítím připojeným ke Službě);
            </p>
            <p>
              f) používat Mailist, Listserv, auto-responder v jakékoli formě, nebo spam v
              rámci Služby; ani
            </p>
            <p>
              g) používat manuál nebo automatizovaný software, zařízení nebo jiné postupy
              k nabourání se (crawl) nebo zamoření (spider) jakékoli stránky Webových
              stránek.
            </p>
            <p>
              Uživatel je plně zodpovědný za to, že převodem finančních prostředků v
              rámci Sbírky nedochází k legalizaci výnosů z jakékoliv trestné činnosti a
              prostředky ve Sbírce užité nejsou určeny k financování terorismu,
              teroristických činů nebo teroristických organizací.
            </p>
            <h3>Zdroje a webové stránky třetích osob</h3>
            <p>
              Služba umožňuje Uživatelům připojit se k jiným webovým stránkám nebo
              zdrojům na Internetu, jež nejsou pod kontrolou Provozovatele, přičemž tyto
              jiné webové stránky nebo zdroje mohou obsahovat odkazy na Webové stránky.
              Pokud Uživatel vstupuje na webové stránky třetích osob, činí tak na vlastní
              riziko. Užíváním Webových stránek a Služby Uživatel potvrzuje a souhlasí,
              že Provozovatel neodpovídá za zboží, služby, obsah, funkce, přesnost,
              zákonnost, vhodnost nebo jakýkoli jiný aspekt těchto webových stránek nebo
              zdrojů třetích osob (dále jen Zdroje třetích osob“). Zahrnutí jakéhokoli
              takového odkazu neznamená schválení ani přidružení Provozovatele k
              jakýmkoli Zdrojům třetích osob. Dále tímto Uživatel potvrzuje a souhlasí,
              že Provozovatel neodpovídá přímo ani nepřímo za jakoukoli škodu nebo ztrátu
              způsobenou nebo údajně způsobenou nebo související s užíváním jakýchkoli
              Zdrojů třetích osob Uživatelem.
            </p>
            <h3>Ukončení přístupu</h3>
            <p>
              Provozovatel může kdykoli ukončit přístup Uživatele ke Službě nebo jakékoli
              její části, s uvedením důvodu nebo bez uvedení důvodu, s oznámením nebo bez
              oznámení, s okamžitou účinností, což může vést k zabavení a zničení
              veškerých informací týkajících se Uživatelova účtu. Pokud si Uživatel přeje
              ukončit svůj účet, může tak učinit splněním pokynů uvedených na Webových
              stránkách. Jakékoli poplatky uhrazené na základě této Smlouvy jsou
              nevratné. Veškerá ustanovení Obchodních podmínek, jež ze své povahy mají
              přetrvat i po ukončení účtu, platí i po ukončení účtu, zejména ustanovení
              týkající se duševního vlastnictví a omezení odpovědnosti.
            </p>
            <h3>Omezení odpovědnosti Provozovatele</h3>
            <p>
              Uživatel tímto dále potvrzuje, že Provozovatel nemá žádnou kontrolu ani
              žádnou povinnost přijmout jakékoli opatření ohledně toho, (i) jací
              uživatelé získají přístup na Webové stránky, (ii) k jakému Obsahu nebo
              Uživatelským příspěvkům má Uživatel přístup prostřednictvím Webových
              stránek, (iii) jaké dopady na Uživatele může mít tento Obsah nebo
              Uživatelské příspěvky; (iv) jak má Uživatel vykládat nebo používat Obsah
              nebo Uživatelské příspěvky; nebo (v) jaká opatření může přijmout, bude-li
              Uživateli zpřístupněn Obsah nebo Uživatelské příspěvky.
            </p>
            <p>
              Provozovatel je zproštěn veškeré odpovědnosti za Uživatelovo získání nebo
              nezískání Obsahu nebo Uživatelských příspěvků prostřednictvím Služby.
              Webové stránky mohou obsahovat nebo odkazovat na webové stránky obsahující
              informace, které někteří lidé mohou považovat za urážlivé nebo nevhodné.
              Provozovatel nečiní žádné prohlášení ohledně jakéhokoli Obsahu nebo
              Uživatelských příspěvků obsažených na Webových stránkách nebo
              zpřístupněných prostřednictvím těchto Webových stránek. Provozovatel
              neodpovídá za přesnost, dodržování autorských práv, zákonnost ani
              decentnost materiálů obsažených nebo zpřístupněných prostřednictvím
              Webových stránek nebo Služby.
            </p>
            <p>
              Provozovatel nenese žádnou odpovědnost z hlediska zákona č. 253/2008 Sb., o
              některých opatřeních proti legalizaci výnosů z trestné činnosti a není
              povinen zjišťovat původ finančních prostředků použitých v rámci Sbírky.
              Odpovědnost za dodržování příslušných předpisů v souvislosti s legalizací
              výnosů z trestné činnosti nese výhradně Uživatel.
            </p>
            <p>
              <h3>Souhlas s elektronickým oznámením</h3>
            </p>
            <p>
              Používáním Služby vyjadřuje Uživatel svůj souhlas s přijímáním veškerých
              elektronických sdělení od společnosti <strong>ElateMe s.r.o.</strong>,
              zejména oznámení, smluv, právně požadovaných oznámení (disclosures) nebo
              jiných informací souvisejících se Službou (dále společně jen jako
              „Oznámení“). <strong>ElateMe s.r.o.</strong> může poskytnout tato
              elektronická Oznámení jejich uveřejněním na Webových stránkách. Pokud si
              Uživatel přeje zrušit svůj souhlas s elektronickým přijímáním Oznámení,
              musí tuto informaci zaslat prostřednictvím emailu Provozovateli.
            </p>
            <h3>Informace o mimosoudním řešení spotřebitelských sporů</h3>
            <p>
              V souladu s ustanovením § 14 a násl. zákona č. 634/1992 Sb. v platném
              znění, o ochraně spotřebitele, tímto Provozovatel informuje spotřebitele,
              že subjekty řešení mimosoudních sporů jsou:
            </p>
            <ul>
              <li>
                Obecným subjektem pro řešení spotřebitelských sporů je Česká
                obchodní inspekce, Štěpánská 567/15, 120 00 Praha 2,            <u><a href="http://www.coi.cz/">www.coi.cz</a></u>.
              </li>
              <li>
                Subjektem pro řešení sporů v oblasti finančních služeb je Finanční
                arbitr, Legerova 1581/69, 110 00 Praha 1,            <u><a href="http://www.finarbitr.cz/">www.finarbitr.cz</a></u>.
              </li>
              <li>
                Subjektem pro řešení sporů v oblasti elektronických komunikací a
                poštovních služeb je Český telekomunikační úřad, Sokolovská 219,
                190 00 Praha 9, <u><a href="http://www.ctu.cz/">www.ctu.cz</a></u>.
              </li>
            </ul>
            <p>
              <h3>Závěrečná ustanovení</h3>
            </p>
            <p>
              Tyto Obchodní podmínky představují úplnou dohodu mezi Uživateli (zejména
              Autory sbírek) a společností <strong>ElateMe s.r.o.</strong> týkající se
              Služeb a užívání Webových stránek, a nahrazují veškerá předchozí nebo
              současná sdělení a návrhy (ať již ústní, písemné nebo elektronické) mezi
              Uživatelem a společností <strong>ElateMe s.r.o.</strong>.
            </p>
            <p>
              Pokud je kterékoli ustanovení těchto Obchodních podmínek považováno za
              nevymahatelné nebo neplatné, toto ustanovení bude vyloučeno nebo omezeno na
              minimální nezbytný rozsah a nemá vliv na platnost nebo vymahatelnost
              ostatních oddělitelných ustanovení Obchodních podmínek.
            </p>
            <p>
              Provozovatel si vyhrazuje právo kdykoliv pozměnit nebo upravit tyto
              Obchodní podmínky, jakož i Zásady ochrany osobních údajů, a to bez udání
              důvodu. O případných změnách budou Uživatelé Webových stránek a Služeb,
              informováni prostřednictvím zveřejnění oznámení ne Webových stránkách anebo
              zasláním oznámení prostřednictvím emailu. Znění nových Obchodních podmínek
              či Zásad ochrany osobních údajů je účinné ode dne jejich uveřejnění na
              Webových stránkách Provozovatele, není-li Provozovatelem stanoveno jinak.
            </p>
            <p>
              Obchodní podmínky a veškeré právní vztahy z těchto Obchodních podmínek
              vyplývající se řídí českým právem.
            </p>`,
};
