export const SET_PRODUCT = "productReducer/setProduct";
export const STATUS_NOT_FOUND = "productReducer/status_not_found";
export const STATUS_EMPTY = "productReducer/status_empty";
export const STATUS_LOADING = "productReducer/status_loading";
export const STATUS_ERROR_FULL = "productReducer/status_error_full";
export const STATUS_LOADED_FULL = "productReducer/status_loaded_full";
export const SET_LOADING = "productReducer/setLoading";
export const SET_STATUS = "productReducer/setStatus";
export const PRODUCT_REDUCER = "productReducer";
export const API_ERROR = "productReducer/api_error";
