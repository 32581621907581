import React, { Component } from "react";
import { Link } from "react-router-dom";

class Friend extends Component {
  render() {
    return (
      <Link
        to={`/user/${this.props.friend.id}`}
        key={this.props.index}
        className="search__people"
      >
        <img
          className="contacts__user-avatar"
          alt={`${this.props.friend.first_name} ${this.props.friend.last_name}`}
          src={this.props.friend.image}
          onError={(e) => {
            e.target.src =
              this.props.friend.gender === "M"
                ? "/img/user-male.svg"
                : "/img/user-female.svg";
          }}
        />
        <span className="search__people-name">
          {this.props.friend.first_name}{" "}
          <strong>{this.props.friend.last_name}</strong>
        </span>
      </Link>
    );
  }
}

export default Friend;
