import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";
import * as actions from "scenes/Wish/scenes/Detail/actions";
import FormattedHTMLMessage from "services/localization/flex";

class RestrictedDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
    };
  }

  componentDidMount() {
    // this.props.actions.getAccessRequest(this.props.user);

    this.setState({
      status: this.getWishStatus(),
    });
  }

  getProgress() {
    console.log("GETPROGESS", this.props.wish);
    const need = this.props.wish.amount_needed;
    const got = this.props.wish.amount_gathered;
    if (got >= need) {
      return "100%";
    }
    return `${(got * 100) / need}%`;
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.actions
      .requestForWishReveal(this.props.user, this.props.wish)
      .then(() => {});
    this.setState({ status: "waiting" });
  };

  getWishStatus() {
    const waitingWishes = this.props.access_requests;

    for (let i = 0; i < waitingWishes.length; i++) {
      if (waitingWishes[i].wish === this.props.wish.id) {
        return waitingWishes[i].status;
      }
    }

    return this.props.wish.status;
  }

  showDonateButton() {
    console.log("RESTRICRTED WISH", this.props.wish);
    if (this.props.wish.wish_state !== "open") {
      return false;
    }
    return true;
  }

  getDonateButtonTitle() {
    return this.props.wish.flat_donation ? (
      <FormattedMessage
        id="wish.detail.donateButtonFlat"
        values={{
          value: this.props.wish.flat_donation,
          currency: this.props.wish.currency.toUpperCase(),
        }}
      />
    ) : (
      <FormattedMessage id="wish.detail.donateButton" />
    );
  }

  render() {
    return (
      <div className="container container--narrow">
        <div className="content">
          <div className="restricted-warning">
            {!this.props.wish.is_following_condititions ? (
              <FormattedHTMLMessage id="wish.restricted.not-approved" />
            ) : this.props.user.loggedIn ? (
              <FormattedHTMLMessage id="wish.restricted.introtext" />
            ) : (
              <FormattedHTMLMessage id="wish.notlogged.introtext" />
            )}
            {this.props.user.loggedIn ? (
              <p>
                {this.props.wish.status === "none" && !this.props.isWaiting ? (
                  <button
                    href="#"
                    className="button"
                    onClick={this.handleClick}
                  >
                    <FormattedHTMLMessage
                      id="wish.detail.askForAccess"
                      values={{ name: this.props.friend }}
                    />
                  </button>
                ) : (
                  <FormattedHTMLMessage id="wish.detail.accessRequested" />
                )}
              </p>
            ) : null}
          </div>
          <article className="wish-detail">
            <header className="wish-detail__header wishes-item__info-wrapper divider">
              <div className="wishes-item__info">
                <Link to={`/user/${this.props.friend.id}`} className="no-link">
                  <UserImage
                    className="wishes-item__user-avatar"
                    user={this.props.friend}
                  />
                </Link>
                <h2 className="wishes-item__title">
                  <span className="small">
                    <FormattedHTMLMessage
                      id="wish.detail.title"
                      values={{ name: this.props.friend }}
                    />
                  </span>
                  <span className="wishes-item__wish-name">
                    <FormattedHTMLMessage id="wish.detail.unknown" />
                  </span>
                </h2>
                {this.showDonateButton() ? (
                  <a
                    onClick={this.props.openDonationModal}
                    className="button button--donate"
                  >
                    {this.getDonateButtonTitle()}
                  </a>
                ) : null}
              </div>
              <figure className="wishes-item__image">
                <img alt="Unknown wish" src="/img/placeholder-gift.svg" />
              </figure>
            </header>

            <section className="wish-detail__stats">
              <table>
                <tbody>
                  <tr>
                    <td className="wish-detail__progress">
                      <span className="wishes-item__progress-bar">
                        <span
                          className="wishes-item__progress-bar-inner"
                          style={{ width: this.getProgress() }}
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </article>
        </div>
      </div>
    );
  }
}

RestrictedDetail.propTypes = {
  wish: PropTypes.shape({
    id: PropTypes.number,
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string,
      image: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
    is_following_condititions: PropTypes.bool,
    currency: PropTypes.string,
  }).isRequired,
  friend: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    gender: PropTypes.string,
    last_name: PropTypes.string,
    image: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    requestForWishReveal: PropTypes.func,
    getAccessRequest: PropTypes.func,
  }).isRequired,
  openDonationModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  access_requests: state.wishDetail.access_requests,
  wish: state.wishDetail.restrictedWish,
  isWaiting: state.wishDetail.isWaiting,
});

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedDetail);
