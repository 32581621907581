import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ColorSelector from "../ColorSelector";
import * as groupActions from "../FriendGroups/actions";

class CreateGroupButton extends React.Component {
  constructor(props) {
    super(props);
    this.setGroupColor = this.setGroupColor.bind(this);
  }

  setGroupColor(color) {
    this.props.actions.setNewGroupColor(color.code);
    const text = this.props.newGroup.name;
    if (text.length !== 0) {
      this.props.actions.setInputOk(true);
    } else {
      this.props.actions.setInputOk(false);
    }
  }

  checkUserInput(text) {
    this.props.actions.setNewGroupName(text);
    if (text.length !== 0 && this.props.newGroup.color) {
      this.props.actions.setInputOk(true);
    } else {
      this.props.actions.setInputOk(false);
    }
  }

  handleSave = (evt) => {
    if (!this.props.newGroup.inputOk) {
      return;
    }

    const group = {
      name: this.props.newGroup.name,
      color: this.props.newGroup.color,
      members: [],
    };

    this.props.addNewGroup(group);
    this.props.actions.clearNewGroupInfo();
    evt.preventDefault();
  };

  render() {
    const { groups, groupsLoading, newGroup } = this.props;

    return (
      <Fragment>
        <ColorSelector
          setNewGroupColor={this.setGroupColor}
          excludeColors={
            groupsLoading ? null : groups.map((group) => group.color)
          }
          autoSelect
        />
        <form className="fill flex" onSubmit={this.handleSave}>
          <input
            className="form__field friend-lists__add-list-field"
            type="text"
            placeholder={this.props.intl.messages["friends.newList"]}
            onChange={(e) => this.checkUserInput(e.target.value)}
            value={newGroup.name}
          />
          <button
            onClick={this.handleSave}
            disabled={!newGroup.inputOk}
            className={
              newGroup.inputOk
                ? "friend-lists__add-list-submit"
                : "friend-lists__add-list-submit hidden"
            }
          >
            <i className="icon icon--tick" />
          </button>
        </form>
      </Fragment>
    );
  }
}
CreateGroupButton.propTypes = {
  newGroup: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    inputOk: PropTypes.bool,
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupsLoading: PropTypes.bool.isRequired,
  addNewGroup: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape({
      "friends.newList": PropTypes.string,
    }),
  }).isRequired,
  actions: PropTypes.shape({
    clearNewGroupInfo: PropTypes.func,
    setNewGroupColor: PropTypes.func,
    setNewGroupName: PropTypes.func,
    setInputOk: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  newGroup: state.friends.groups.newGroup,
  groups: state.friends.groups.all,
  groupsLoading: state.friends.groups.loading,
  intl: state.intl,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupButton);
