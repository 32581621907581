import * as types from "./actionTypes";
import * as constants from "./constants";

export const initialState = {
  pageSize: constants.DEFAULT_COMMENT_PAGE_SIZE,
  comments: {},
};

function createCommentBox() {
  const newCommentBox = {
    unsubmitted: "",
    comment_added: false,
    comments: [],
    page: 0,
    visible: 0,
    count: 0,
  };

  return newCommentBox;
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.CREATE_COMMENT_BOX:
      return Object.assign({}, state, {
        comments: {
          ...state.comments,
          [payload.item]: createCommentBox(),
        },
      });

    case types.ADD_COMMENT_PAGE:
      return Object.assign({}, state, {
        comments: {
          ...state.comments,
          [payload.item.id]: {
            ...state.comments[payload.item.id],
            comments: state.comments[payload.item.id].comments.concat(
              payload.item.comments
            ),
            page: state.comments[payload.item.id].page + 1,
            visible:
              state.comments[payload.item.id].visible +
              payload.item.comments.length,
            count: payload.item.count,
          },
        },
      });

    case types.ADD_SINGLE_COMMENT:
      return Object.assign({}, state, {
        comments: {
          ...state.comments,
          [payload.item.id]: {
            ...state.comments[payload.item.id],
            comments: Array(payload.item.comment).concat(
              state.comments[payload.item.id].comments
            ),
            comment_added: true,
          },
        },
      });

    case types.ADD_COMMENT_ATTACHMENTS:
      return Object.assign({}, state, {
        comments: Object.assign({}, state.comments, {
          [payload.item.comment.wish]: Object.assign(
            {},
            state.comments[payload.item.comment.wish],
            {
              // eslint-disable-next-line no-confusing-arrow
              comments: state.comments[payload.item.comment.wish].comments
                .slice()
                .map((comment) =>
                  comment.id === payload.item.comment.id
                    ? Object.assign({}, comment, {
                        text: payload.item.comment.text,
                        attachments: payload.item.attachments,
                      })
                    : comment
                ),
            }
          ),
        }),
      });

    case types.SET_COMMENT_TEXT:
      return Object.assign({}, state, {
        comments: {
          ...state.comments,
          [payload.item.id]: {
            ...state.comments[payload.item.id],
            unsubmitted: payload.item.text,
          },
        },
      });

    case types.REMOVE_COMMENT_BOX:
      return Object.assign({}, state, {
        comments: {
          ...state.comments,
          [payload.item]: undefined,
        },
      });

    default:
      return state;
  }
};
