import React from "react";
import { injectIntl } from "react-intl";

const TimeRemaining = injectIntl(({ date, intl }) => {
  if (date === 0) {
    return <span>{intl.formatRelative(date, { units: "day" })}</span>;
  }
  return <span>{intl.formatRelative(date)}</span>;
});
export default TimeRemaining;
