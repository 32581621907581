import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import UserImage from "../../../../../../../../../../UserImage";

class OthersList extends Component {
  handleLinkToItem(url) {
    this.props.history.push(url);
  }

  render() {
    const { list } = this.props;

    const Rows = list.map((item, i) => {
      return (
        <div
          onMouseDown={() => this.handleLinkToItem(`/user/${item.id}`)}
          className="search-helper__item"
          key={i}
        >
          {" "}
          <div className="search-helper__item-img">
            <UserImage user={item} />
          </div>
          <h1 className="search-helper__item-name">{item.first_name}</h1>
          <h1 className="search-helper__item-name search-helper__item-name--bold">
            {item.last_name}
          </h1>
        </div>
      );
    });

    return <React.Fragment>{Rows}</React.Fragment>;
  }
}

export default withRouter(OthersList);
