import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import FormattedHTMLMessage from "../../../../services/localization/flex";
import * as commentsActions from "../../actions";

import CommentComponent from "./components/CommentComponent";

class CommentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      full: false,
    };
  }
  componentDidMount() {
    if (!this.props.isFeed) {
      this.props.actions.loadCommentPage(
        this.props.user,
        this.props.id,
        1,
        this.props.pageSize
      );
    }
  }

  getCommentAuthor(id) {
    if (id === this.props.user.id) {
      return this.props.user;
    }

    if (this.props.users[id]) {
      return this.props.users[id];
    }

    return null;
  }

  showComment(comment) {
    return (
      <CommentComponent
        key={comment.id}
        id={comment.id}
        author={this.getCommentAuthor(
          comment.author.id ? comment.author.id : comment.author
        )}
        comment={comment.text}
        attachments={comment.attachments}
        date={comment.date_created}
      />
    );
  }

  showAllComments() {
    return this.props.comments.map(this.showComment, this);
  }

  showThreeComments() {
    return this.props.last_three_comments.map(this.showComment, this);
  }

  handleCommentsLoading = () => {
    if (!this.state.full) {
      this.props.actions.loadCommentPage(
        this.props.user,
        this.props.id,
        1,
        this.props.pageSize
      );
    } else {
      this.props.actions.createCommentBox(this.props.id);
    }
    this.setState({
      full: !this.state.full,
    });
  };

  checkComments = () => {
    return (
      this.props.last_three_comments &&
      this.props.last_three_comments.length === 3 &&
      !this.props.comment_added
    );
  };

  render() {
    return (
      !!this.props.comments && (
        <div>
          {!this.state.full &&
          this.props.last_three_comments &&
          !this.props.comment_added
            ? this.showThreeComments()
            : this.showAllComments()}
          {!this.state.full && this.checkComments() ? (
            <a
              className="wishes-item__load-comments"
              onClick={this.handleCommentsLoading}
            >
              <FormattedHTMLMessage id="feed.loadMoreComments" />
            </a>
          ) : this.state.full ? (
            <a
              className="wishes-item__load-comments"
              onClick={this.handleCommentsLoading}
            >
              <FormattedHTMLMessage id="feed.loadLessComments" />
            </a>
          ) : null}
        </div>
      )
    );
  }
}

CommentList.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,

  actions: PropTypes.shape({
    loadCommentPage: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    comments: state.comments.comments[ownProps.id].comments,
    comment_added: state.comments.comments[ownProps.id].comment_added,
    pageSize: state.comments.pageSize,
    users: state.users.all,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(commentsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
