import React from "react";
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl,
  intlShape,
} from "react-intl";
import PropTypes from "prop-types";

import FormattedHTMLMessage from "services/localization/flex";

class ProgressItem extends React.Component {
  getPercentage() {
    const need = this.props.progress.amount_needed;
    const got = this.props.progress.amount_gathered;
    if (got >= need) {
      return "100%";
    }
    return `${(got * 100) / need}%`;
  }

  getDateDiff(currentDate, date) {
    const t2 = currentDate.getTime();
    const t1 = date.getTime();
    const days = (t2 - t1) / (24 * 3600 * 1000);
    const weeks = (t2 - t1) / (24 * 3600 * 1000 * 7);
    const d1Y = date.getYear();
    const d2Y = currentDate.getYear();
    const d1M = date.getMonth();
    const d2M = currentDate.getMonth();
    const months = d2M + 12 * d2Y - (d1M + 12 * d1Y);
    const DateDiff = {
      inDays: Math.abs(Math.floor(days)),
      inWeeks: Math.abs(Math.floor(weeks)),
      inMonths: Math.abs(Math.floor(months)),
    };
    return DateDiff;
  }

  getDaysRemaining() {
    const actualDate = new Date();
    const dateOfExpiration = new Date(this.props.progress.date_of_expiration);

    const remaining = Math.floor((dateOfExpiration - actualDate) / 86400000);

    if (remaining <= 0) {
      return 0;
    }

    return remaining;
  }

  getDeadline() {
    let message;

    const currentDate = new Date();
    const dateExpired = new Date(this.props.progress.date_of_expiration);
    const diff = this.getDateDiff(dateExpired, currentDate);
    const wishState = this.props.progress.wish_state;
    if (wishState === "closed" || wishState === "transfered") {
      message = <FormattedMessage id="wish.detail.terminatedShort" />;
    } else if (
      this.props.progress.date_of_expiration &&
      (currentDate > dateExpired || diff === 0)
    ) {
      message = <FormattedHTMLMessage id="wish.detail.expired" />;
    } else if (this.props.progress.date_completed) {
      message = <FormattedHTMLMessage id="wish.detail.completed" />;
    } else if (this.props.progress.date_of_expiration) {
      const days = this.getDaysRemaining();
      if (days === 0) {
        message = <FormattedHTMLMessage id="wish.detail.todayEnds" />;
      } else {
        message = (
          <FormattedHTMLMessage
            id="wish.detail.remaining"
            values={{
              days: this.getDaysRemaining(),
            }}
          />
        );
      }
    }
    return message;
  }

  renderNoNeeded() {
    const mineWish = this.props.progress.id === this.props.progress.author;
    const deadline = this.getDeadline();
    return (
      <div
        className={`wishes-item__progress ${
          !deadline && "wishes-item__progress--center"
        }`}
      >
        <p className="wishes-item__progress-description">
          <FormattedHTMLMessage
            id={
              mineWish ? "wish.detail.collected.mine" : "wish.detail.collected"
            }
            values={{
              count: this.props.progress.donators_count,
              gathered: this.props.intl.formatNumber(
                this.props.progress.amount_gathered
              ),
              currency: this.props.progress.currency.toUpperCase(),
            }}
          />
        </p>
        {!!deadline && (
          <p className="text-center">
            <i className="icon icon--time" />
            <span> {deadline}</span>
          </p>
        )}
      </div>
    );
  }

  render() {
    // const deadline = this.getDeadline();
    if (this.props.progress.amount_needed > 0) {
      return this.renderNeededMoney();
    }
    return this.renderNoNeeded();
  }

  renderNeededMoney() {
    const deadline = this.getDeadline();
    return (
      <div
        className={`wishes-item__progress ${
          !deadline && "wishes-item__progress--center"
        }`}
      >
        <p className="wishes-item__progress-description">
          <FormattedHTMLMessage
            id={
              this.props.mineWish
                ? "wish.detail.collected.mine"
                : "wish.detail.collected"
            }
            values={{
              count: this.props.progress.donators_count,
              gathered: this.props.intl.formatNumber(
                this.props.progress.amount_gathered
              ),
              currency: this.props.progress.currency.toUpperCase(),
            }}
          />
        </p>
        <span className="wishes-item__progress-bar">
          <span
            className="wishes-item__progress-bar-inner"
            style={{ width: this.getPercentage() }}
          />
        </span>

        <p>
          <i className="icon icon--money" />
          <strong>
            {" "}
            <FormattedNumber value={this.props.progress.amount_needed} />
            {` ${this.props.progress.currency.toUpperCase()}`}
          </strong>
        </p>

        {!!deadline && (
          <p className="wishes-item__progress-status">
            {this.props.progress.wish_state && (
              <i className="icon icon--close" />
            )}
            <span> {deadline}</span>
          </p>
        )}
      </div>
    );
  }
}
export default injectIntl(ProgressItem);

ProgressItem.propTypes = {
  mineWish: PropTypes.bool.isRequired,
  progress: PropTypes.shape({
    isDeleted: PropTypes.bool,
    date_of_expiration: PropTypes.instanceOf(Date),
    amount_needed: PropTypes.number,
    date_completed: PropTypes.string,
    currency: PropTypes.string,
    amount_gathered: PropTypes.number,
    donators_count: PropTypes.number,
    wish_state: PropTypes.string,
  }).isRequired,
  intl: intlShape.isRequired,
};
