import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "data/user/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as appActions from "components/App/actions";
import * as modalActions from "components/ConfirmModal/actions";
import * as types from "components/ConfirmModal/actionTypes";
import DayPickerForm from "components/DayPickerForm";
import Spinner from "components/Spinner";

const currentYear = new Date().getFullYear();

class SignUpFinishForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date_of_birth: null,
      name: null,
      surname: null,
      gender: null,
      nameFlag: 1,
      surnameFlag: 1,
      genderFlag: 1,
      hash_id: window.location.pathname.replace("/finish-sign-up/", ""),
      month: new Date(currentYear - 20, 0),
      disabled: false,
    };

    this.onMonthChange = this.onMonthChange.bind(this);
    this.handleChangeBirthday = this.handleChangeBirthday.bind(this);
  }

  componentDidMount() {
    if (this.props.user.loggedIn) {
      this.props.modalActions.open({
        title: "",
        type: types.YOU_ARE_LOGGED,
      });
    }
  }

  handleChangeName = (e) => {
    if (e.target.value && e.target.value.length) {
      this.setState({
        name: e.target.value,
        nameFlag: 1,
      });
    }

    if (this.state.name && !e.target.value.length) {
      this.setState({
        name: null,
      });
    }
  };

  handleChangeSurname = (e) => {
    if (e.target.value && e.target.value.length) {
      this.setState({
        surname: e.target.value,
        surnameFlag: 1,
      });
    }

    if (this.state.surname && !e.target.value.length) {
      this.setState({
        surname: null,
      });
    }
  };

  handleChangeBirthday = (date) => {
    if (date) {
      this.setState({
        date_of_birth: date,
      });
    }
  };

  handleChangeGender = (e) => {
    this.setState({
      gender: e.target.value,
    });
  };

  getNameMessage() {
    if (this.state.nameFlag) {
      return <p></p>;
    } else {
      return (
        <p className="form__description form__description--danger">
          Jméno je povinné
        </p>
      );
    }
  }

  getSurnameMessage() {
    if (this.state.surnameFlag) {
      return <p></p>;
    } else {
      return (
        <p className="form__description form__description--danger">
          Příjmení je povinné
        </p>
      );
    }
  }

  getGenderMessage() {
    if (this.state.genderFlag) {
      return <p></p>;
    } else {
      return (
        <p className="form__description form__description--danger">
          Pohlaví je povinné
        </p>
      );
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      disabled: true,
    });

    if (
      this.state.name &&
      this.state.surname &&
      this.state.gender &&
      this.state.hash_id
    ) {
      const form = new FormData();
      if (this.state.date_of_birth) {
        const date = this.state.date_of_birth;
        const dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        form.append("date_of_birth", dateString);
      }
      form.append("first_name", this.state.name);
      form.append("last_name", this.state.surname);
      form.append("gender", this.state.gender);
      this.props.appActions.addTransition("/");
      this.props.actions.finishRegistration(this.state.hash_id, form);
    }

    if (!this.state.name || !this.state.name.length) {
      this.setState({
        nameFlag: 0,
      });
    }

    if (!this.state.surname || !this.state.surname.length) {
      this.setState({
        surnameFlag: 0,
      });
    }

    if (!this.state.gender) {
      this.setState({
        genderFlag: 0,
      });
    }
  };

  onMonthChange(month) {
    this.setState({
      month,
    });
  }

  render() {
    const buttonValue =
      this.props.user.submitting === 1
        ? this.props.intl.formatMessage({ id: "common.submitting" })
        : this.props.intl.formatMessage({ id: "common.submit" });
    return (
      <section className="login main">
        <div className="login-promo">
          <img src="/img/celebrating.png" className="login-promo__img" alt="" />
        </div>
        {this.props.user.submitting === 1 && <Spinner />}
        <div className="login-form">
          <h1 className="login-form__title">
            <FormattedMessage id="login.sign-up.title" />
          </h1>
          <form className="form">
            <div className="form__row">
              <label className="form__label">
                <FormattedMessage id="login.sign-up.name" />
              </label>
              <input
                type="text"
                onChange={this.handleChangeName}
                placeholder={this.props.intl.formatMessage({
                  id: "login.name",
                })}
                className="form__field"
                name="first_name"
              />
              {this.getNameMessage()}
            </div>
            <div className="form__row">
              <label className="form__label">
                <FormattedMessage id="login.sign-up.surname" />
              </label>
              <input
                type="text"
                onChange={this.handleChangeSurname}
                placeholder={this.props.intl.formatMessage({
                  id: "login.surname",
                })}
                className="form__field"
                name="surname"
              />
              {this.getSurnameMessage()}
            </div>
            <div className="form__row">
              <label className="form__label">
                <FormattedMessage id="login.sign-up.birthday" />
              </label>
              <DayPickerForm
                date_of_birth={this.state.date_of_birth}
                onDayChange={this.handleChangeBirthday}
                placeholder={this.props.intl.formatMessage({
                  id: "login.birthday",
                })}
                locale={this.props.locale}
                month={this.state.month}
                onChange={this.onMonthChange}
                name="birthday"
              />
            </div>

            <div className="form__row form__row--gender">
              <label className="form__label">
                <FormattedMessage id="login.sign-up.gender" />
              </label>
              <div className="form__radio">
                <div className="radio-option">
                  <input
                    type="radio"
                    name="gender"
                    value="M"
                    id="muz"
                    onChange={this.handleChangeGender}
                  />
                  <label htmlFor="muz">
                    <FormattedMessage id="login.sign-up.gender.male" />
                  </label>
                </div>
                <div className="radio-option">
                  <input
                    type="radio"
                    name="gender"
                    value="F"
                    id="zena"
                    onChange={this.handleChangeGender}
                  />
                  <label htmlFor="zena">
                    <FormattedMessage id="login.sign-up.gender.female" />
                  </label>
                </div>
              </div>
            </div>
            {this.getGenderMessage()}
            <div className="form__row">
              <input
                type="submit"
                value={buttonValue}
                className="button login-button"
                onClick={this.handleSubmit}
                disabled={this.state.disabled}
              />
            </div>
          </form>
        </div>
      </section>
    );
  }
}

SignUpFinishForm.propTypes = {
  return_url: PropTypes.string,

  actions: PropTypes.shape({
    //    createCommentBox: PropTypes.func,
    //    removeCommentBox: PropTypes.func,
  }).isRequired,

  user: PropTypes.shape({
    id: PropTypes.number,
    accessToken: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    locale: state.intl.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    push: bindActionCreators(push, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SignUpFinishForm));
