import { authFetchApi } from "services/api";

import * as types from "./actionTypes";
import * as constants from "./constants";

export const receiveMyWishes = (item) => ({
  type: types.RECEIVE_MY_WISHES_PAGE,
  item,
});

export const setMyWishesLoading = (item) => ({
  type: types.SET_WISHES_LOADING,
  item,
});
export const reset = () => ({
  type: types.RESET,
});
export const toggleWishesToDisplay = (item) => ({
  type: types.SET_WISHES_TO_DISPLAY,
  item,
});

export const setLastPage = (item) => ({
  type: types.SET_WISHES_LAST_PAGE,
  item,
});

export const setHasMoreWishes = (item) => ({
  type: types.SET_HAS_MORE_WISHES,
  item: item !== null,
});

export const setWishState = (item) => ({
  type: types.SET_WISH_STATE,
  item,
});

export function fetchWishesPage(user, pageNumber, wishState) {
  return (dispatch) => {
    authFetchApi(
      `/wishes/?page=${pageNumber}&page_size=${constants.WISHES_PAGE_SIZE}&state=${wishState}`,
      user.accessToken,
      {
        method: "GET",
      }
    )
      .then((data) => {
        dispatch(setHasMoreWishes(data.next));
        dispatch(setLastPage(pageNumber));
        dispatch(receiveMyWishes(data.results));
      })
      .catch((e) => {
        dispatch({ type: types.API_ERROR });
      });
  };
}
