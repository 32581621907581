import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as settingsActions from "../../actions";

const initialState = {
  currentPassword: "",
  newPassword: "",
  newPasswordAgain: "",
  currentPswdFlag: true,
  newPswdFlag: true,
  newPswdAgainFlag: true,
  areTheSame: true,
  disabled: true,
};

class SetNewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.resetState = this.resetState.bind(this);
  }

  resetState() {
    this.setState(initialState);
  }

  getFlagMessage(flag) {
    if (!flag) {
      return (
        <p className="form__description form__description--danger">
          <FormattedMessage id="login.password.invalid" />
        </p>
      );
    }
    return null;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (
      this.state.currentPswdFlag &&
      this.state.newPswdFlag &&
      this.state.newPswdAgainFlag &&
      this.state.areTheSame
    ) {
      this.props.actions.changePassword(
        this.props.user,
        this.state.currentPassword,
        this.state.newPassword,
        this.resetState
      );
    }
  }

  onChangeCurrentPswd(e) {
    this.setState({
      currentPassword: e.target.value,
      currentPswdFlag: e.target.value.length >= 5,
    });
  }

  onChangeNewPswd(e) {
    this.setState({
      newPassword: e.target.value,
      newPswdFlag: e.target.value.length >= 5,
    });
  }

  onChangeNewPswdAgain(e) {
    this.setState({
      newPasswordAgain: e.target.value,
      newPswdAgainFlag: e.target.value.length >= 5,
      areTheSame: e.target.value === this.state.newPassword,
    });

    if (
      e.target.value.length >= 5 &&
      e.target.value === this.state.newPassword &&
      this.state.currentPswdFlag &&
      this.state.newPswdFlag
    ) {
      this.setState({
        disabled: false,
      });
    }
  }

  render() {
    return (
      <form className="form" onSubmit={(e) => this.handleSubmit(e)}>
        <div className="form__row">
          <h2>
            <FormattedMessage id="settings.setNewPassword.title" />
          </h2>
          {!this.props.success_update ? (
            <p className="form__description form__description--danger">
              <FormattedMessage id="settings.setNewPassword.passwordError" />
            </p>
          ) : null}
        </div>
        <div className="form__row">
          <input
            className="form__field"
            placeholder={this.props.intl.formatMessage({
              id: "settings.setNewPassword.currentPassword",
            })}
            type="password"
            value={this.state.currentPassword}
            onChange={(e) => this.onChangeCurrentPswd(e)}
          />
          {this.getFlagMessage(this.state.currentPswdFlag)}
        </div>
        <div className="form__row">
          <input
            className="form__field"
            placeholder={this.props.intl.formatMessage({
              id: "settings.setNewPassword.newPassword",
            })}
            type="password"
            value={this.state.newPassword}
            onChange={(e) => this.onChangeNewPswd(e)}
          />
          {this.getFlagMessage(this.state.newPswdFlag)}
        </div>
        <div className="form__row">
          <input
            className="form__field"
            placeholder={this.props.intl.formatMessage({
              id: "settings.setNewPassword.newPasswordAgain",
            })}
            type="password"
            value={this.state.newPasswordAgain}
            onChange={(e) => this.onChangeNewPswdAgain(e)}
          />
          {this.getFlagMessage(this.state.newPswdAgainFlag)}
          {!this.state.areTheSame ? (
            <p className="form__description form__description--danger">
              <FormattedMessage id="login.password.dont_match" />
            </p>
          ) : null}
        </div>
        <div className="form__row text-right">
          <input
            type="submit"
            disabled={this.state.disabled}
            value={this.props.intl.formatMessage({
              id: "settings.setNewPassword.confirm",
            })}
            className={this.state.disabled ? "button" : "button button--next"}
          />
        </div>
      </form>
    );
  }
}

/*SetNewPassword.propTypes = {
  actions: PropTypes.shape({
  }).isRequired(),
}*/

function mapStateToProps(state) {
  return {
    user: state.user,
    success_update: state.settings.success_update,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SetNewPassword));
