import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as usersActions from "data/users/actions";
import { userById } from "data/users/helper";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import { setStep } from "components/Containers/MultiStep/actions";
import * as actions from "components/WishControllers/actions";
import * as friendActions from "scenes/Friends/actions";
import * as wishCreateActions from "scenes/Wish/scenes/Create/actions";
import * as constants from "scenes/Wish/scenes/Create/constants";
import FormattedHTMLMessage from "services/localization/flex";

import DoneeSelector from "./DoneeSelector";

class SearchBox extends Component {
  state = { isFocused: false, isHidden: false };

  onKeyPress = (e) => {
    if (e.which === 13 && !this.props.searchText) {
      e.preventDefault();
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter" && this.props.searchText) {
      const expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi; // eslint-disable-line
      const regex = new RegExp(expression);
      const query_shop_expr = /shop:([\w.]+)/g;
      const match = query_shop_expr.exec(this.props.searchText);

      if (
        this.props.searchText.match(regex) &&
        this.props.suggested &&
        this.props.suggested[0]
      ) {
        this.callWishSettings(this.props.suggested[0]);
      } else if (!match) {
        this.callWishSettings(null);
      }
    }
  };

  callWishSettings(item) {
    const shop = document.createElement("a");
    shop.href = this.props.url;

    const urlParams = item
      ? {
          title: item.title,
          amount: item.amountNeeded,
          image: item.image,
          productURL: item.productUrl,
        }
      : {
          title: this.props.searchText,
          amount: null,
          image: null,
          productURL: null,
        };

    const otherParams = {
      description: "",
      surprise_wish:
        this.props.surprise_wish.recipient != undefined
          ? this.props.surprise_wish.recipient
          : "",
      custom_user_name: this.props.custom_user_name
        ? this.props.custom_user_name
        : "",
      allowed_users: [],
      flat_donation: null,
      visibility: "public",
      expiration_date: null,
      user_money_receiver: null,
    };

    const params = Object.assign({}, urlParams, otherParams);

    const stringified = queryString.stringify(params);

    if (window.location.pathname.split("/")[1] === "create") {
      this.props.push(`/create/settings?${stringified}`);
    } else {
      this.props.push(`/wish/create/settings?${stringified}`);
    }

    this.props.setStep("wish_create", "settings");
  }

  getAuthorName(id) {
    if (id === this.props.user.id) {
      return this.props.user.first_name;
    }
    this.props.usersActions.updateUsers(
      this.props.user,
      this.props.users,
      id,
      this.props.defaultUser
    );
    return userById(id, this.props.users, this.props.defaultUser).first_name;
  }

  setSearchText = (e) => {
    this.props.wishCreateActions.setSearchText(e.target.value);
    if (this.props.loadingStatus !== constants.STATUS_DEFAULT) {
      this.props.wishCreateActions.setLoadingStatus(constants.STATUS_DEFAULT);
    }

    if (this.props.suggested) {
      this.props.wishCreateActions.setSuggested([]);
    }

    clearTimeout(this.props.timeout);
    const timeout = setTimeout(() => {
      if (this.props.searchText.replace(/\s/g, "")) {
        this.props.wishCreateActions.getSuggested(
          this.props.searchText,
          this.props.user
        );
      }
    }, 1000);

    this.props.wishCreateActions.setTimeout(timeout);
  };

  isForMe() {
    return (
      (this.props.surprise_wish === null ||
        this.props.surprise_wish.recipient === undefined) &&
      !this.props.custom_user_name
    );
  }

  getMessageByGender() {
    return this.props.users[this.props.surprise_wish.recipient].gender === "M"
      ? this.props.intl.formatMessage({ id: "wish.create.whatForHim" })
      : this.props.intl.formatMessage({ id: "wish.create.whatForHer" });
  }

  getPlaceHolder() {
    if (this.isForMe()) {
      return this.props.intl.formatMessage({ id: "wish.create.what" });
    } else if (this.props.custom_user_name) {
      return this.props.intl.formatMessage({ id: "wish.create.whatForHim" });
    } else {
      return this.getMessageByGender();
    }
  }

  handleSearchFocus = () => this.setState({ isFocused: true });
  handleSearchBlur = () => this.setState({ isFocused: false });

  handleDoneeConfirm = () => this.searchField.focus();

  showBubble() {
    //  console.log(this.props.searchText!=0) //priprava na podmienku. ak value!=0, nezobrazi sa bublina
    this.setState({ isHidden: true });
  }
  hideBubble() {
    this.setState({ isHidden: false });
  }

  render() {
    return (
      <Fragment>
        {/* this.state.isFocused && <div className="wish-search__backdrop" /> */}
        <form action="./" className="wish-search divider">
          <DoneeSelector onConfirm={this.handleDoneeConfirm} />
          <div className="wish-search__field-wrapper">
            <input
              type="text"
              className="form__field wish-search__field wish-search__field--gift"
              value={this.props.searchText}
              onChange={this.setSearchText}
              onKeyDown={this.onKeyPress}
              onFocus={this.handleSearchFocus}
              onBlur={this.handleSearchBlur}
              ref={(input) => (this.searchField = input)}
              placeholder={this.getPlaceHolder()}
              onClick={() => this.showBubble()}
              onKeyUp={() => this.hideBubble()}
              onKeyPress={this.handleKeyPress}
            />
          </div>
          {this.state.isHidden && (
            <div className="wish-search__bubble">
              {" "}
              <FormattedHTMLMessage id="wish.product-search.hint" />
            </div>
          )}
        </form>
      </Fragment>
    );
  }
}

SearchBox.propTypes = {
  searchText: PropTypes.string,
  timeout: PropTypes.number.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    first_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
    id: PropTypes.number,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      id: PropTypes.number,
    }),
  }).isRequired,
  choice: PropTypes.bool,
  forSomeone: PropTypes.bool,
  defaultUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  usersActions: PropTypes.shape({
    updateUsers: PropTypes.func,
  }).isRequired,
};

SearchBox.defaultProps = {
  searchText: "",
};

const mapStateToProps = (state) => ({
  searchText: state.wishCreate.searchText,
  loadingStatus: state.wishCreate.loadingStatus,
  user: state.user,
  timeout: state.wishCreate.timeout,
  users: state.users.all,
  defaultUser: {
    first_name: state.intl.messages["mock.user.first_name"],
    last_name: state.intl.messages["mock.user.last_name"],
  },
  surprise_wish: state.wishContainer.wish.surprise_wish,
  custom_user_name: state.wishContainer.wish.custom_user_name,
  suggested: state.wishCreate.suggested,
});

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    actions: bindActionCreators(actions, dispatch),
    wishCreateActions: bindActionCreators(wishCreateActions, dispatch),
    friendActions: bindActionCreators(friendActions, dispatch),
    setStep: bindActionCreators(setStep, dispatch),
    push: bindActionCreators(push, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SearchBox));
