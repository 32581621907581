import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";

import * as actions from "../../../../../../actions";

class FriendRequestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  changeMode() {
    this.setState({
      active: !this.state.active,
    });
  }

  handleClickAccept(e) {
    e.preventDefault();
    this.props.actions.handleRequest(
      this.props.request,
      "accepted",
      this.props.user
    );
  }

  handleClickDecline(e) {
    e.preventDefault();
    this.props.actions.handleRequest(
      this.props.request,
      "deny",
      this.props.user
    );
  }

  render() {
    return (
      <li
        key={this.props.index}
        className={
          this.state.active ? "contacts__item dropdown--open" : "contacts__item"
        }
      >
        <a className="contacts__item-link">
          <UserImage
            className="contacts__user-avatar"
            user={this.props.friend}
          />
          <span className="contacts__user-name">
            {this.props.friend.first_name}{" "}
            <strong>{this.props.friend.last_name}</strong>
          </span>
        </a>

        <div className="contacts__item-menu xlink">
          <UserImage
            className="contacts__user-avatar"
            user={this.props.friend}
          />
          <span className="contacts__user-name hidden-mobile">
            {this.props.friend.first_name}
          </span>
          <ul>
            <li className="contacts__item-menu-item">
              <a onClick={(e) => this.handleClickAccept(e)}>
                <strong>
                  <FormattedMessage id="friends.acceptRequest" />
                </strong>
              </a>
            </li>
            <li className="contacts__item-menu-item">
              <a onClick={(e) => this.handleClickDecline(e)}>
                <strong>
                  <FormattedMessage id="friends.declineRequest" />
                </strong>
              </a>
            </li>
          </ul>
        </div>
      </li>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(FriendRequestComponent);
