import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ConfirmDeleteDialog from "components/ConfirmModal";
import { close, open } from "components/ConfirmModal/actions";
import { DELETE_FRIEND_LIST } from "components/ConfirmModal/actionTypes";
import Share from "components/FacebookLogin/share";

import * as friendsActions from "../../actions";
import FriendGroups from "../FriendGroups";
import * as groupActions from "../FriendGroups/actions";
import FriendsFilter from "../FriendsFilter";

import FriendComponent from "./components/FriendComponent";
import FriendRequest from "./components/FriendRequest";
import FriendSuggestions from "./components/FriendSuggestions";
import NoFriendsSuggestions from "./components/NoFriendsSuggestions/index";

class FriendList extends Share {
  state = { showDeleteModal: false };

  componentDidMount() {
    this.props.actions.getUserFriends(this.props.user);
    this.props.groupActions.getFriendGroups(this.props.user);
    this.props.actions.getFriendshipRequests(this.props.user);

    if (!this.props.allFriends.length) {
      this.props.actions.getLastFiveUsers(this.props.user);
    }
  }

  setNewActiveGroup = (group) => {
    if (group === null) {
      this.fetchFriends();
    } else if (group === -2) {
      this.fetchRequests();
    } else {
      const { users } = this.props;
      const friends = this.props.allFriends.slice();
      const newList = [];
      const members = group.members.slice();
      for (let j = 0; j < members.length; j += 1) {
        for (let i = 0; i < friends.length; i += 1) {
          if (users[friends[i]].id === members[j]) {
            newList.push(friends[i]);
          }
        }
      }
      this.props.actions.setActiveList(newList);
    }
  };
  // dummy filter
  setActiveFilter = (filtered) => {
    this.props.actions.setActiveList(filtered);
  };

  fetchFriends() {
    this.props.actions.setActiveList(this.props.allFriends);
  }

  fetchRequests() {
    this.props.actions.setActiveList(this.props.requests);
  }

  renderNoFriends() {
    return (
      <div>
        <p className="text-center p-md">
          <FormattedMessage id="feed.nofriends-sidebar" />
        </p>
        <p className="text-center p-md">
          <FormattedMessage id="friends.noFriends.inviteNew" />
        </p>
        <ul className="contacts">
          {this.props.lastFiveUsers.map(this.forAllSuggested)}
        </ul>
      </div>
    );
  }

  forAllSuggested(item, index) {
    return <NoFriendsSuggestions key={index} friend={item} />;
  }

  getDefaultInformation() {
    if (this.props.friends.length === 0) {
      if (this.props.activeListIndex === -1 && !this.props.filter.active) {
        return this.renderNoFriends();
      } else if (this.props.activeListIndex === -2) {
        return null;
      }
      return (
        <p className="friend-list__placeholder">
          <FormattedMessage id="friends.empty_list" />
        </p>
      );
    }

    /*if (this.props.activeListIndex === -1) {
      this.props.actions.setActiveList(this.props.allFriends);
    }*/

    if (this.props.activeListIndex === -2) {
      return null;
    }

    return (
      <ul className="contacts contacts--large">
        {this.props.friends.map(this.forAllFriends)}
      </ul>
    );
  }

  forAllFriends = (friendID, index) => {
    const friend = this.props.users[friendID];
    if (!friend) {
      return;
    }
    const groups = [];
    const all = this.props.groups.slice();
    for (let i = 0; i < all.length; i += 1) {
      const members = all[i].members.slice();
      for (let j = 0; j < members.length; j += 1) {
        if (members[j] === friend.id) {
          groups.push(all[i]);
        }
      }
    }

    return (
      <FriendComponent
        key={friend.id}
        index={index}
        friend={friend}
        groups={groups}
      />
    );
  };

  handleDeleteClick = () => this.setState({ showDeleteModal: true });
  handleDeleteCloseClick = () => this.setState({ showDeleteModal: false });
  handleDeleteConfirm = () => {
    const groupId = this.props.groups[this.props.activeListIndex].id;
    this.props.groupActions.deleteGroup(groupId, this.props.user);
    this.setState({ showDeleteModal: false });
  };

  render() {
    return (
      <section className="app-section friends">
        <FriendGroups setActiveGroup={this.setNewActiveGroup} />
        <main className="main-section friend-list">
          {this.props.activeListIndex > -2 && (
            <FriendsFilter
              setActiveFilter={this.setActiveFilter}
              friends={this.props.friends}
            />
          )}

          {this.getDefaultInformation()}

          {this.props.activeListIndex > -1 && <FriendSuggestions />}

          {this.props.activeListIndex > -1 && (
            <Fragment>
              <p className="friend-list__actions">
                <button
                  onClick={this.handleDeleteClick}
                  className="no-style color-destructive"
                >
                  <FormattedMessage id="friends.delete" />
                </button>
              </p>
            </Fragment>
          )}

          {this.props.activeListIndex === -2 && <FriendRequest />}

          <ConfirmDeleteDialog
            closeModal={this.handleDeleteCloseClick}
            confirmAction={this.handleDeleteConfirm}
            isOpen={this.state.showDeleteModal}
            listTitle={
              this.props.groups[this.props.activeListIndex]
                ? this.props.groups[this.props.activeListIndex].name
                : ""
            }
            title={<FormattedMessage id="friends.delete" />}
            type={DELETE_FRIEND_LIST}
          />
        </main>
      </section>
    );
  }
}

FriendList.propTypes = {
  activeListIndex: PropTypes.number.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      gender: PropTypes.string,
      image: PropTypes.string,
    }),
  }).isRequired,
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  allFriends: PropTypes.arrayOf(PropTypes.number).isRequired,
  requests: PropTypes.arrayOf(PropTypes.number).isRequired,
  actions: PropTypes.shape({
    getUserFriends: PropTypes.func,
    setActiveList: PropTypes.func,
    getFriendsById: PropTypes.func,
    clearList: PropTypes.func,
    getFriendshipRequests: PropTypes.func,
  }).isRequired,
  groupActions: PropTypes.shape({
    deleteGroup: PropTypes.func,
    setActiveIndex: PropTypes.func,
    getFriendGroups: PropTypes.func,
  }).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  friends: state.friends.friends.activeList,
  activeListIndex: state.friends.groups.index,
  users: state.users.all,
  groups: state.friends.groups.all,
  allFriends: state.friends.friends.all,
  user: state.user,
  requests: state.friends.friends.friendRequests,
  lastFiveUsers: state.friends.friends.lastFiveUsers,
  filter: state.friends.friends.filter,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(friendsActions, dispatch),
    modalActions: bindActionCreators({ open, close }, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FriendList);
