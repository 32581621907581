import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi } from "services/api";

import * as types from "./actionTypes";

export const fetchNotifications = (item) => ({
  type: types.FETCH_NOTIFICATIONS,
  item,
});
export const fetchNotificationsCount = (count) => ({
  type: types.FETCH_NOTIFICATIONS_COUNT,
  count,
});

export const popupSuccessCollection = (popups) => ({
  type: types.POPUP_SUCCESS_COLLECTION,
  popups,
});

export const appendNotifications = (item) => ({
  type: types.APPEND_NOTIFICATIONS,
  item,
});
export const closingPopup = (item) => ({
  type: types.CLOSING_POPUP,
  item,
});
export const setDropdown = (value) => ({
  type: types.SET_DROPDOWN,
  value,
});

export const notificationDeleted = (id) => ({
  type: types.NOTIFICATION_DELETED,
  id,
});

export const setRequestsForWishReveal = (data) => ({
  type: types.SET_REQUESTS,
  data,
});

export const editFriendRequestNotification = (data) => ({
  type: types.EDIT_FRIEND_REQUEST,
  data,
});

export function getNotifications(user) {
  return (dispatch) => {
    authFetchApi("/notifications/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(fetchNotifications(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
export function getCount(user, intervalId) {
  return (dispatch) => {
    authFetchApi("/notifications/summary/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(fetchNotificationsCount(data.count));

        dispatch(popupSuccessCollection(data.popups));
        // dispatch(modalActions.open({ title: 'Popupy', type: modalTypes.SUCCESS_COLLECTION, popups: data.popups }));
      })
      .catch((error) => {
        if (error.errorCode === 401) {
          clearInterval(intervalId);
          dispatch(
            modalActions.open({ title: "", type: modalTypes.LOGED_OUT })
          );
        } else {
          clearInterval(intervalId);
          dispatch({ type: types.API_ERROR });
        }
        // dispatch(modalActions.open({ title: '', type: modalTypes.ERROR }));
      });
  };
}
export function closeModal(user, popup) {
  return (dispatch) => {
    dispatch(closingPopup(true));
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ has_seen: true }),
    };
    authFetchApi(`/notifications/${popup.id}/`, user.accessToken, options)
      .then((data) => {
        dispatch({ type: types.POPUP_CLOSE, popupId: popup.id });
        dispatch(closingPopup(false));
      })
      .catch(() => {
        // TODO:
        dispatch(closingPopup(false));
      });
  };
}

export function getNextPage(user, pageUrl) {
  return (dispatch) => {
    const { pathname } = new URL(pageUrl);
    authFetchApi(pathname, user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(appendNotifications(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
export function deleteNotification(user, id) {
  return (dispatch) => {
    authFetchApi(`/notifications/${id}/`, user.accessToken, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(notificationDeleted(id));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function getRequestForWishReveal(user) {
  return (dispatch) => {
    return authFetchApi("/wishes/access/request/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(setRequestsForWishReveal(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
