import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";

import FormattedHTMLMessage from "../../../../../../services/localization/flex";

class CustomFriendsList extends React.Component {
  showAllowedUsers = (item) => {
    const user = this.props.allUsers[item];
    return (
      <li className="contacts__item contacts__item--static">
        <Link to={`/user/${item}`} className="contacts__item-link">
          <UserImage className="contacts__user-avatar" user={user} />
        </Link>
      </li>
    );
  };

  render() {
    if (this.props.allowedUsers.length) {
      return (
        <div className="custom-friends">
          <ul className="contacts__wrap">
            {this.props.allowedUsers.map(this.showAllowedUsers)}
          </ul>
          <a onClick={() => this.props.handleEdit()}>
            <FormattedHTMLMessage id="wish.create.visibility.edit" />
          </a>
        </div>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    push: bindActionCreators(push, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CustomFriendsList);
