export const DONE_LOADING = "settings/DONE_LOADING";
export const SET_NEW_BANK_NR = "settings/SET_NEW_BANK_NR";
export const SET_SUCCESS_UPDATE = "settings/SET_SUCCESS_UPDATE";

export const SET_INPUT_OK = "settings/SET_INPUT_OK";
export const FETCH_USER = "settings/FETCH_USER";
export const FETCH_WISHES = "settings/FETCH_WISHES";
export const API_ERROR = "settings/API_ERROR";
export const SET_LANGUAGE = "settings/SET_LANGUAGE";
export const SET_CURRENCY = "settings/SET_CURRENCY";

export const API_SUBMITTING = "settings/API_SUBMITTING";
export const INFORMATIONS_CHANGED = "settings/INFORMATIONS_CHANGED";
