import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "components/WishControllers/actions";

class DescriptionBox extends Component {
  changeDescription(e) {
    this.props.actions.setDescription(e.target.value);
  }

  render() {
    return (
      <textarea
        placeholder={this.props.intl.formatMessage({
          id: "wish.create.descriptionPlaceholder",
        })}
        cols="30"
        rows="2"
        className="form__field"
        onChange={(e) => this.changeDescription(e)}
        value={this.props.description}
      />
    );
  }
}

DescriptionBox.propTypes = {
  actions: PropTypes.shape({
    setDescription: PropTypes.func,
  }).isRequired,

  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  description: state.wishContainer.wish.description,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DescriptionBox));
