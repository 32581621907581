import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "scenes/Wish/scenes/Detail/components/Donation/actions";

import FormattedHTMLMessage from "../../services/localization/flex";

import DonatorsComponent from "./components/DonatorsComponent";

class DonatorsList extends React.Component {
  componentDidMount() {
    this.props.actions.getWishDonators(this.props.user, this.props.id);
  }

  forAllDonators(item) {
    return (
      <DonatorsComponent
        key={item.id}
        first_name={item.donator && item.donator.first_name}
        last_name={item.donator && item.donator.last_name}
        id={item.donator && item.donator.id}
        image={item.donator && item.donator.image}
        amount={item.amount}
        currency={this.props.wish.currency}
        donator_name={item.donator_name}
      />
    );
  }

  getDonators(numberOfDonators) {
    let shorterArrOfDonators = [];
    if (numberOfDonators > 5) {
      for (let i = 0; i < 5; i++) {
        shorterArrOfDonators.push(this.props.donators[i]);
      }
    } else {
      shorterArrOfDonators = this.props.donators;
    }

    if (!numberOfDonators) {
      return null;
    }
    return shorterArrOfDonators.map((item) => this.forAllDonators(item));
  }

  render() {
    const numberOfDonators = this.props.donators
      ? this.props.donators.length
      : 0;

    return (
      <div>
        {numberOfDonators ? (
          <section className="wish-detail__contacts">
            {numberOfDonators ? (
              <h3>
                <FormattedHTMLMessage id="wish.detail.theseUsersDonated" />
              </h3>
            ) : null}
            <ul className="contacts__wrap">
              {this.getDonators(numberOfDonators)}
            </ul>
            {numberOfDonators > 5 && (
              <a className="contacts__more" onClick={this.props.openModal}>
                <svg
                  className="contacts__arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                </svg>
                <FormattedHTMLMessage id="wish.detail.showAllDonators" />
              </a>
            )}
          </section>
        ) : null}
      </div>
    );
  }
}

DonatorsList.propTypes = {
  actions: PropTypes.shape({
    getWishDonators: PropTypes.func,
  }).isRequired,
  id: PropTypes.number.isRequired,
  user: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  donators: state.donation.donators,
  user: state.user,
  wish: state.wishDetail.wish,
});

function mapDispatchToprops(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToprops)(DonatorsList);
