import React, { Fragment } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as usersActions from "data/users/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import CommentBox from "components/CommentBox";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import DonatorsList from "components/DonatorsList";
import ProductImage from "components/ProductImage";
import UserImage from "components/UserImage";
import WishDropdownMenu from "components/WishList/components/WishDropdownMenu";
import * as donationActions from "scenes/Wish/scenes/Detail/components/Donation/actions";
import { getNameCause } from "services/localization/flex";
import FormattedHTMLMessage from "services/localization/flex";

import Share from "../../../../../../components/FacebookLogin/share";
import PopupSuccessDonation from "../../../../../../components/PopupContainer/components/PopupSuccessDonation";
import * as WishActions from "../../actions";

class FullDetail extends Share {
  constructor(props) {
    super(props);

    this.state = {
      isOpenSuccessModal: false,
    };
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
  }

  closeSuccessModal() {
    this.setState({
      isOpenSuccessModal: false,
    });
  }

  getProgress() {
    const need = this.props.wish.amount_needed;
    const got = this.props.wish.amount_gathered;
    if (got >= need) {
      return "100%";
    }
    return `${(got * 100) / need}%`;
  }

  getDateDiff(currentDate, date) {
    const t2 = currentDate.getTime();
    const t1 = date.getTime();
    const days = (t2 - t1) / (24 * 3600 * 1000);
    const weeks = (t2 - t1) / (24 * 3600 * 1000 * 7);
    const d1Y = date.getYear();
    const d2Y = currentDate.getYear();
    const d1M = date.getMonth();
    const d2M = currentDate.getMonth();
    const months = d2M + 12 * d2Y - (d1M + 12 * d1Y);
    const DateDiff = {
      inDays: Math.abs(Math.floor(days)),
      inWeeks: Math.abs(Math.floor(weeks)),
      inMonths: Math.abs(Math.floor(months)),
    };
    return DateDiff;
  }

  getDaysRemaining() {
    const actualDate = new Date();
    const dateOfExpiration = new Date(this.props.wish.date_of_expiration);

    const remaining = Math.floor((dateOfExpiration - actualDate) / 86400000);

    if (remaining <= 0) {
      return 0;
    }

    return remaining;
  }

  renderUserImage() {
    if (
      this.props.wish.surprise_wish &&
      this.props.wish.surprise_wish.recipient
    ) {
      // const recipient = this.getWishRecipientInfo(this.props.wish.surprise_wish.recipient);
      const { recipient } = this.props.wish.surprise_wish;
      return (
        <Link to={`/user/${recipient.id}`} className="no-link">
          <UserImage className="wishes-item__user-avatar" user={recipient} />
        </Link>
      );
    }
    return (
      <Link to={`/user/${this.props.friend.id}`} className="no-link">
        <UserImage
          className="wishes-item__user-avatar"
          user={this.props.friend}
        />
      </Link>
    );
  }

  renderWishImage() {
    return <ProductImage wish={this.props.wish} />;
  }

  showDescription() {
    if (this.props.wish.description === "") {
      return (
        <p>
          <FormattedMessage id="wish.detail.noDescription" />
        </p>
      );
    }
    return <p>{this.props.wish.description}</p>;
  }

  showDonateButton() {
    /*
    if (!this.props.wish.surprise_wish && this.props.wish.user_money_receiver.id === this.props.user.id) {
      // pokud je prani pro me, tak si sam sobe zakazu donatovat - nema to smysl,
      // ja sam si muzu prispet jak chci, resp. mam svoje penize na ucte
      return false;
    }
    */
    if (this.props.wish.wish_state !== "open") {
      return false;
    }
    return true;
    /*
    return this.props.wish.wish_state === 'open' &&
      (this.props.wish.surprise_wish ||
        this.props.wish.user_money_receiver.id !== this.props.user.id ||
        (this.props.wish.surprise_wish && this.props.wish.surprise_wish.id === this.props.user.id)
      );
      */
  }

  showDeadline() {
    let message;

    const currentDate = new Date();
    const dateExpired = new Date(this.props.wish.date_of_expiration);
    const wishState = this.props.wish.wish_state;
    if (this.props.isDeleted) {
      message = <FormattedMessage id="wish.detail.deleted" />;
    } else if (
      wishState === "closed" ||
      wishState === "transfered" ||
      wishState === "transfered"
    ) {
      message = <FormattedMessage id="wish.detail.terminated" />;
    } else if (this.props.wish.date_of_expiration === null) {
      return;
    } else if (currentDate > dateExpired) {
      message = <FormattedHTMLMessage id="wish.detail.expired" />;
    } else if (this.props.wish.date_completed) {
      message = <FormattedHTMLMessage id="wish.detail.completed" />;
    } else {
      // const diff = this.getDateDiff(dateExpired, currentDate);
      const days = this.getDaysRemaining();
      if (days === 0) {
        message = <FormattedHTMLMessage id="wish.detail.todayEnds" />;
      } else {
        message = (
          <FormattedHTMLMessage
            id="wish.detail.remaining"
            values={{
              days: this.getDaysRemaining(),
            }}
          />
        );
      }
    }
    return (
      <div>
        <i className="icon icon--time" />
        <span>{message}</span>
      </div>
    );
  }

  getDonateButtonTitle() {
    console.log("Wish", this.props.wish);
    return this.props.wish.flat_donation ? (
      <FormattedMessage
        id="wish.detail.donateButtonFlat"
        values={{
          value: this.props.wish.flat_donation,
          currency: this.props.wish.currency.toUpperCase(),
        }}
      />
    ) : (
      <FormattedMessage id="wish.detail.donateButton" />
    );
  }

  handleClose(evt) {
    const wishId = this.props.wish.id;
    const token = this.props.user.accessToken;
    this.props.wishActions.closeWish(wishId, token).then(() => {
      this.props.closeModal();
    });
    this.props.donationActions
      .getWishDonators(this.props.user, this.props.wish.id)
      .then(() => {
        this.setState({ isOpenSuccessModal: true });
      });
    evt.preventDefault();
  }

  handleShowDonators = (evt) => {
    this.props.donationActions
      .getWishDonators(this.props.user, this.props.wish.id)
      .then(() => {
        this.props.openModal(() => {}, "", modalTypes.SHOW_DONATORS);
      });
    evt.preventDefault();
  };

  openDonatorsModal = (evt) => {
    this.props.openModal(() => {}, "", modalTypes.SHOW_DONATORS);
    evt.preventDefault();
  };

  removeDuplicites(donators) {
    if (!donators) {
      return [];
    }

    const dict = {};
    const arr = [];
    for (let i = 0; i < donators.length; i++) {
      if (donators[i].donator && !dict[donators[i].donator.id]) {
        dict[donators[i].donator.id] = true;
        arr.push(donators[i].donator);
      }
    }
    return arr;
  }

  renderPopupSuccessDonation() {
    const popup = {
      wish: this.props.wish,
      donation: 10,
      donators: this.removeDuplicites(this.props.donators),
    };

    return (
      <PopupSuccessDonation
        popup={popup}
        closeModal={this.closeSuccessModal}
        closingPopup={this.props.closingPopup}
      />
    );
  }

  renderAmountGathered() {
    return (
      <Fragment>
        <i className="icon icon--tick icon--positive" />{" "}
        <FormattedHTMLMessage
          id="wish.detail.moneyGathered"
          values={{
            money: this.props.intl.formatNumber(
              this.props.wish.amount_gathered
            ),
            currency: this.props.wish.currency.toUpperCase(),
          }}
        />
      </Fragment>
    );
  }

  renderAmountNeeded() {
    return (
      <Fragment>
        <i className="icon icon--tick icon--positive" />{" "}
        <FormattedHTMLMessage
          id="wish.detail.moneyNeeded"
          values={{
            money: this.props.intl.formatNumber(this.props.wish.amount_needed),
            currency: this.props.wish.currency.toUpperCase(),
          }}
        />
      </Fragment>
    );
  }

  renderAmountNeed() {
    return (
      <Fragment>
        <i className="icon icon--tick icon--positive" />{" "}
        <FormattedHTMLMessage
          id="wish.detail.moneyNeed"
          values={{
            money: this.props.intl.formatNumber(this.props.wish.amount_needed),
            currency: this.props.wish.currency.toUpperCase(),
          }}
        />
      </Fragment>
    );
  }

  renderMoneyInfo() {
    if (this.props.wish.amount_needed > 0) {
      // prani ma orientacni castku
      if (this.props.wish.amount_needed < this.props.wish.amount_gathered) {
        // vybralo se potrebne mnozstvi
        return this.renderAmountNeeded();
      }
      if (this.props.wish.amount_gathered > 0) {
        // nekdo uz prispel
        return this.renderAmountNeed();
      }
      return this.renderAmountNeed();
    }
    return null;
    /*
    if (this.props.wish.wish_state !== 'open') {
      return this.renderAmountNeeded();
    }

    // prani nema orientacni castku
    if (this.props.wish.amount_gathered > 0) {
      // nekdo uz prispel
      return null;
    }
    // nic prispeno
    return null;
    */
  }

  renderMessageFor() {
    if (this.props.wish.custom_user_name) {
      // custom name
      const link = this.getCreatorLink(this.props.friend);
      const authorName = (
        <FormattedHTMLMessage id="wish.detail.authorName" values={{}} />
      );

      return (
        <FormattedMessage
          id="wish.detail.surpriseForNonUser"
          values={{
            author_nominativ: this.getCreatorLink(this.props.friend),
            recipient: this.props.wish.custom_user_name, // getNameCause(this.props.wish.custom_user_name, 'akuzativ'),
          }}
        />
      );
    }
    const wishMessage = `wish.detail.surpriseTitleLinked${
      this.props.friend.gender === "F" ? "She" : "He"
    }`;

    if (
      this.props.wish.surprise_wish &&
      this.props.wish.surprise_wish.recipient
    ) {
      // surprise wish
      return (
        <FormattedMessage
          id={wishMessage}
          values={{
            author: this.getCreatorLink(this.props.friend),
            recipient: getNameCause(
              this.props.wish.surprise_wish.recipient,
              "akuzativ"
            ),
          }}
        />
      );
    }
    // wish for creator
    return (
      <FormattedMessage
        id="wish.detail.title"
        values={{ name_nominativ: this.props.friend.first_name }}
      />
    );
  }

  getCreatorLink(user) {
    return (
      <Link to={`/user/${user.id}`} className="no-link">
        <UserImage className="wishes-item__creator-image" user={user} />
        {user.first_name}
      </Link>
    );
  }

  getWishVisibility() {
    switch (this.props.wish.visibility) {
      case "public":
        return (
          <span>
            <strong>
              <FormattedMessage id="wish.detail.visibility.public" />
            </strong>
          </span>
        );
      case "me":
        return (
          <span>
            <strong>
              <FormattedMessage id="wish.detail.visibility.forMe" />
            </strong>
          </span>
        );
      case "friends":
        return (
          <span>
            <strong>
              <FormattedMessage id="wish.detail.visibility.forFriends" />
            </strong>
          </span>
        );
      case "custom_users":
        return (
          <a
            onClick={() =>
              this.props.openModal(
                () => {},
                "",
                modalTypes.ALLOWED_USERS_DETAIL
              )
            }
          >
            <strong>
              <FormattedMessage id="wish.detail.visibility.chosenFriends" />
            </strong>
          </a>
        );
      default:
        return (
          <span>
            <strong>
              <FormattedMessage id="wish.detail.visibility.public" />
            </strong>
          </span>
        );
    }
  }

  parseURL(url) {
    return url.split("/")[2];
  }

  render() {
    const moneyInfo = this.renderMoneyInfo();
    const deadlineInfo = this.showDeadline();

    return (
      <div className="container container--narrow">
        {this.props.children}
        <div className="content">
          <article className="wish-detail">
            <header className="wishes-item__info-wrapper">
              <WishDropdownMenu
                author={this.props.friend}
                wishToModify={this.props.wish}
                openConfirmModal={this.props.openModal}
                closeConfirmModal={this.props.closeModal}
                open={this.props.openDropDown}
                close={this.props.closeDropDown}
                isOpen={this.props.dropDownOpen}
                isFeed={false}
              />

              <figure className="wishes-item__image">
                <ProductImage wish={this.props.wish} />
              </figure>

              <div className="wishes-item__info">
                <div className="wishes-item__header">
                  {this.renderUserImage()}
                  <h2 className="wishes-item__recipient">
                    <span className="small">{this.renderMessageFor()}</span>
                  </h2>
                </div>

                <h2 className="wishes-item__title">
                  <span className="wishes-item__wish-name">
                    {" "}
                    {this.props.wish.title}{" "}
                  </span>
                </h2>

                {/* this.props.wish.wish_state === 'closed' ?
                  (<p>
                    <FormattedMessage id="wish.detail.terminated" />
                  </p>) : null
                */}
                {this.props.wish.wish_state === "for-refundation" ? (
                  <p>
                    <FormattedMessage id="wish.detail.forRefund" />
                  </p>
                ) : null}
                {/* this.props.isDeleted ?
                  (<p>
                    <FormattedMessage id="wish.detail.deleted" />
                  </p>) : null
                */}
                {this.showDonateButton() ? (
                  <a
                    onClick={this.props.openDonationModal}
                    className="button button--donate"
                  >
                    {this.getDonateButtonTitle()}
                  </a>
                ) : null}

                <div className="wishes-item-eshop">
                  {this.props.wish.eshop && (
                    <div className="wishes-item-eshop__image">
                      <img src={this.props.wish.eshop.image_logo} alt="" />
                    </div>
                  )}
                  <div className="wishes-item-eshop__title">
                    {this.props.wish.author.id !== this.props.user.id &&
                    !this.props.wish.is_audited ? (
                      <p>
                        Vydržte pár minut, přání kontrolují administátoři.
                        Přispějte jen pokud důvěřujete osobě, která vám odkaz na
                        sbírku zaslala. Tým Wowee.
                      </p>
                    ) : null}
                    {this.props.wish.product_url ? (
                      <a
                        href={this.props.wish.product_url}
                        target="_blank"
                        className="wishes-item__image-link"
                        rel="noreferrer"
                      >
                        Zobrazit v e-shopu{" "}
                        {this.parseURL(this.props.wish.product_url)}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </header>
            {/*
<figure className="wishes-item__image">
                            this.renderWishImage()
                          </figure>
*/}
            <section className="wish-detail__user-note divider divider--medium">
              {this.showDescription()}
              <p>
                <a
                  className="button button--facebook fill"
                  onClick={() => {
                    this.shareWish(this.props.wish, null, this.props.user);
                  }}
                >
                  <FormattedMessage id="common.share" />
                </a>
              </p>
            </section>

            <section className="wish-detail__stats divider divider--medium">
              <table>
                <tbody>
                  <tr>
                    <td className="wish-detail__collected">
                      <a
                        href="##"
                        className="no-link"
                        onClick={this.handleShowDonators.bind(this)}
                      >
                        <FormattedHTMLMessage
                          id={
                            this.props.wish.author === this.props.user.id
                              ? "wish.detail.collected.mine"
                              : "wish.detail.collected"
                          }
                          values={{
                            currency: this.props.wish.currency.toUpperCase(),
                            count: this.props.wish.donators_count,
                            gathered: this.props.intl.formatNumber(
                              this.props.wish.amount_gathered
                            ),
                          }}
                        />
                      </a>
                    </td>
                    <td className="wish-detail__progress">
                      <span
                        className={`wishes-item__progress-bar${
                          this.props.wish.amount_gathered &&
                          this.props.wish.amount_needed
                            ? ""
                            : " invisible"
                        }`}
                      >
                        <span
                          className="wishes-item__progress-bar-inner"
                          style={{ width: this.getProgress() }}
                        />
                      </span>
                    </td>
                  </tr>
                  {moneyInfo || deadlineInfo ? (
                    <tr>
                      <td colSpan="2">
                        <div className="divider divider--medium divider--fill" />
                      </td>
                    </tr>
                  ) : null}
                  {moneyInfo || deadlineInfo ? (
                    <tr>
                      <td className="wish-detail__collected">{moneyInfo}</td>
                      <td className="wish-detail__progress">{deadlineInfo}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </section>
            {this.props.wish.author.id === this.props.user.id && (
              <section className="wish-detail__stats divider divider--medium">
                <div className="form-transform">
                  <div className="form__group form__group--always-row">
                    <div className="form__row">
                      <label className="form__label" htmlFor="fullDetail">
                        <FormattedHTMLMessage id="wish.detail.userMoneyReceiver" />
                      </label>
                      <Link
                        to={`/user/${this.props.user_money_receiver.id}`}
                        className="contacts__item list__item"
                      >
                        <UserImage
                          className="contacts__user-avatar"
                          user={this.props.user_money_receiver}
                        />
                        <span className="contacts__user-name">
                          {this.props.user_money_receiver.first_name}{" "}
                          <strong>
                            {this.props.user_money_receiver.last_name}
                          </strong>
                        </span>
                      </Link>
                    </div>
                    <div className="form__row">
                      <label className="form__label" htmlFor="fullDetail">
                        <FormattedHTMLMessage id="wish.detail.visibilityTitle" />
                      </label>
                      {this.getWishVisibility()}
                    </div>
                  </div>
                </div>
              </section>
            )}
            <DonatorsList
              id={this.props.wish.id}
              openModal={this.openDonatorsModal}
            />
            {this.props.wish.amount_gathered > 0 &&
            this.props.wish.wish_state === "open" &&
            this.props.user.id === this.props.wish.author.id ? (
              <div className="text-center">
                <a
                  onClick={() =>
                    this.props.openModal(
                      this.handleClose.bind(this),
                      "Do you really want to terminate this wish?",
                      modalTypes.CLOSE
                    )
                  }
                  className="button button--primary"
                >
                  <FormattedMessage id="wish.dropdownTerminate.title" />
                </a>
              </div>
            ) : null}
          </article>
        </div>
        <Modal
          isOpen={this.state.isOpenSuccessModal}
          className="modal modal--main"
          overlayClassName="overlay overlay--main"
          onRequestClose={this.closeSuccessModal}
        >
          {this.renderPopupSuccessDonation()}
        </Modal>

        <CommentBox
          id={this.props.wish.id}
          comments_allowed={this.props.wish.is_allowed_to_add_comment}
          detail
        />
      </div>
    );
  }
}

FullDetail.propTypes = {
  wish: PropTypes.shape({
    id: PropTypes.number,
    custom_user_name: PropTypes.string,
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string,
      image: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
    is_audited: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    amount_needed: PropTypes.number,
    date_created: PropTypes.string,
    date_of_expiration: PropTypes.string,
    date_completed: PropTypes.string,
    amount_gathered: PropTypes.number,
    donators_count: PropTypes.number,
    currency: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
    wish_state: PropTypes.string.isRequired,
    surprise_wish: PropTypes.shape({
      recipient: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        gender: PropTypes.string,
        image: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
      }),
    }),
  }).isRequired,
  friend: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    gender: PropTypes.string,
    last_name: PropTypes.string,
    image: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openDonationModal: PropTypes.func.isRequired,
  openDropDown: PropTypes.func.isRequired,
  closeDropDown: PropTypes.func.isRequired,
  dropDownOpen: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  donationActions: PropTypes.shape({
    getWishDonators: PropTypes.func,
  }),
  wishActions: PropTypes.shape({
    deleteWish: PropTypes.func,
    closeWish: PropTypes.func,
    refundWish: PropTypes.func,
  }).isRequired,
  isDeleted: PropTypes.bool.isRequired,
  usersActions: PropTypes.shape({
    updateUsers: PropTypes.func,
  }).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      id: PropTypes.number,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
  }).isRequired,
  defaultUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  wish: state.wishDetail.wish,
  friend: state.wishDetail.friend,
  user: state.user,
  isDeleted: state.wishDetail.isDeleted,
  users: state.users.all,
  defaultUser: {
    first_name: state.intl.messages["mock.user.first_name"],
    last_name: state.intl.messages["mock.user.last_name"],
  },
  donators: state.donation.donators,
  user_money_receiver: state.wishDetail.wish.user_money_receiver,
});

function mapDispatchToProps(dispatch) {
  return {
    wishActions: bindActionCreators(WishActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    donationActions: bindActionCreators(donationActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FullDetail));
