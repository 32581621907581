import React, { Component } from "react";
import { connect } from "react-redux";
import * as usersActions from "data/users/actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ConfirmModal from "components/ConfirmModal";
import * as modalTypes from "components/ConfirmModal/actionTypes";

import WishComponent from "./components/WishComponent";

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      modalTitle: "",
      type: modalTypes.DEFAULT,
      modalCallback: () => {},
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(callback, title, type) {
    this.setState({
      isOpenModal: true,
      modalTitle: title,
      modalCallback: callback,
      type,
    });
  }

  closeModal() {
    this.setState({
      isOpenModal: false,
    });
  }

  forAllWishes(wish) {
    if (wish.images.length === 0) {
      const dumm = {
        image: null,
        id: null,
      };
      wish.images.push(dumm);
    }
    return (
      <WishComponent
        key={wish.id}
        wish={wish}
        friend={wish.author}
        openConfirmModal={this.openModal}
        closeConfirmModal={this.closeModal}
        isFeed={this.props.feed}
      />
    );
  }

  render() {
    return (
      <section>
        <ConfirmModal
          title={this.state.modalTitle}
          type={this.state.type}
          isOpen={this.state.isOpenModal}
          closeModal={this.closeModal}
          confirmAction={this.state.modalCallback}
        />
        {this.props.list.map(this.forAllWishes, this)}
      </section>
    );
  }
}

WishList.propTypes = {
  usersActions: PropTypes.shape({
    updateUsers: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      author: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        gender: PropTypes.string,
        image: PropTypes.string,
      }),
      description: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
        })
      ).isRequired,
      amount_needed: PropTypes.number,
    })
  ).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
      id: PropTypes.number,
    }),
  }).isRequired,
  defaultUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }).isRequired,

  feed: PropTypes.bool,
  loadingFeed: PropTypes.bool.isRequired,
  loadingWishes: PropTypes.bool.isRequired,
};
WishList.defaultProps = {
  feed: false,
};

const mapStateToProps = (state) => ({
  user: state.user,
  loadingFeed: state.feed.loading,
  users: state.users.all,
  loadingWishes: state.userView.loading,
  defaultUser: {
    first_name: state.intl.messages["mock.user.first_name"],
    last_name: state.intl.messages["mock.user.last_name"],
  },
});

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WishList);
