import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi, fetchApi } from "services/api/";

import * as types from "./actionTypes";

export const stepNext = () => ({
  type: types.STEP_NEXT,
  item: null,
});

export const stepBack = () => ({
  type: types.STEP_BACK,
  item: null,
});

export const setStep = (step) => ({
  type: types.SET_STEP,
  item: step,
});

export const setDonationValue = (donationValue) => ({
  type: types.SET_DONATION_VALUE,
  item: donationValue,
});

export const setBitcoinValue = (BTValue) => ({
  type: types.SET_BITCOIN_VALUE,
  item: BTValue,
});

export const resetStep = () => ({
  type: types.RESET_STEP,
  item: null,
});

export const resetBitcoins = () => ({
  type: types.RESET_BITCOIN_VALUE,
  item: null,
});

export const resetDonation = () => ({
  type: types.RESET_DONATION_VALUE,
  item: null,
});

export const setPayingOption = (option) => ({
  type: types.SET_PAYING_OPTION,
  item: option,
});

export const setDonators = (data) => ({
  type: types.SET_DONATORS,
  item: data,
});

export const setFlatDonation = (item) => ({
  type: types.SET_FLAT_DONATION,
  item,
});

export function chaningStore() {
  // TODO
  return { type: types.CHANGE_DONATION };
}

export function getWishDonators(user, wishId) {
  return (dispatch) => {
    let request;
    if (user.loggedIn) {
      request = authFetchApi(`/donations/wish/${wishId}/`, user.accessToken, {
        method: "GET",
      });
    } else {
      request = fetchApi(`/donations/wish/${wishId}`, {
        method: "GET",
      });
    }
    return request
      .then((data) => {
        dispatch(setDonators(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function postDonation(user, wishId, value) {
  return (dispatch) => {
    const time = new Date();
    const form = new FormData();
    form.append("donator", user.id);
    form.append("wish", wishId);
    form.append("amount", value);
    form.append("date", time.toISOString());
    return authFetchApi(`/donations/wish/${wishId}`, user.accessToken, {
      method: "POST",
      body: form,
    })
      .then(() => {
        dispatch({ type: types.POST_DONATION });
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function callCardPayment(user, wishId, value, window) {
  return (dispatch) => {
    const form = new FormData();
    form.append("amount", value);
    form.append("app_type", "web");
    form.append("wish", wishId);

    let fnc = null;
    let token = null;
    let options = {
      method: "POST",
      body: form,
    };

    if (user.loggedIn) {
      fnc = authFetchApi;
      token = user.accessToken;
    } else {
      fnc = fetchApi;
      token = options;
      options = true;
    }

    return fnc("/donations/cardpayment/", token, options)
      .then((data) => {
        window.location.assign(data.url);
        dispatch({
          type: types.POST_CARD_PAYMENT,
          item: data.url,
        });
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}
