import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import BankAccountNr from "./components/BankAccountNr";
import SettingsSidebar from "./components/SettingsSidebar";

class Settings extends Component {
  render() {
    if (this.props.user.loggedIn) {
      return (
        <DocumentTitle
          title={this.props.intl.formatMessage({ id: "settings.title" })}
        >
          <div className="app-section">
            <div className="container container--narrow">
              <article className="settings">
                <SettingsSidebar showDelete showGdpr />
                <BankAccountNr />
              </article>
            </div>
          </div>
        </DocumentTitle>
      );
    }
    return <DocumentTitle title="Settings" />;
  }
}
Settings.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(injectIntl(Settings));
