import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import WishComponent from "../WishComponent";

class RequestsList extends React.Component {
  renderNoRequest() {
    return (
      <p>
        <FormattedMessage id="access_requests.empty" />
      </p>
    );
  }

  renderRequests() {
    const { list, ...rest } = this.props;
    return list.map((request) => (
      <WishComponent data={request} key={request.id} {...rest} />
    ));
  }

  render() {
    return (
      <section className="access-request__container">
        <h2 className="fill">
          <FormattedMessage id="access_requests.title" />
        </h2>
        {this.props.list.length === 0
          ? this.renderNoRequest()
          : this.renderRequests()}
      </section>
    );
  }
}

/*
[
   {
      "id":1989,
      "wish":{
         "id":2664,
         "author":{
            "id":6,
            "first_name":"Michal",
            "last_name":"Martinek",
            "image":"https://graph.facebook.com/v2.10/10206480961101361/picture?type=large",
            "gender":"M"
         },
         "gathered":0.0,
         "currency":"czk",
         "status":"none"
      },
      "author":{
         "id":6,
         "first_name":"Michal",
         "last_name":"Martinek",
         "image":"https://graph.facebook.com/v2.10/10206480961101361/picture?type=large",
         "gender":"M"
      },
      "petitioner":{
         "id":14,
         "first_name":"Michal",
         "last_name":"Maněna",
         "image":"https://graph.facebook.com/v2.10/10206789029757773/picture?type=large",
         "gender":"M"
      },
      "status":"waiting"
   }
]
*/

RequestsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      petitioner: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        causes_first_name: PropTypes.shape({}),
        causes_last_name: PropTypes.shape({}),
        gender: PropTypes.string,
        image: PropTypes.string,
      }),
      wish: PropTypes.shape({
        title: PropTypes.string,
        author: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          causes_first_name: PropTypes.shape({}),
          causes_last_name: PropTypes.shape({}),
          gender: PropTypes.string,
          image: PropTypes.string,
        }),
        description: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
          })
        ).isRequired,
        amount_needed: PropTypes.number,
      }),
    })
  ).isRequired,
  acceptRequest: PropTypes.func.isRequired,
  declineRequest: PropTypes.func.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default RequestsList;
