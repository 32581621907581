import * as types from "./actionTypes";

export const initState = {
  all: [],
  selectorActive: false,
  apiFail: false,
};

export default (state = initState, payload) => {
  switch (payload.type) {
    case types.GET_COLORS:
      return Object.assign({}, state, { all: payload.item });
    case types.CHANGE_MODE:
      return Object.assign({}, state, {
        selectorActive: !state.selectorActive,
      });
    case types.API_FAIL:
      return Object.assign({}, state, { apiFail: true });
    default:
      return state;
  }
};
