import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as friendsActions from "../../actions";

class FriendsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = null;
    this.friendsToFilter = this.props.friends;
  }

  componentDidUpdate(prevProps) {
    const users = this.props.users;

    if (!prevProps.filter.active) {
      this.friendsToFilter = prevProps.friends;
    } else if (
      this.props.filter.active &&
      this.props.filter.input !== prevProps.filter.input
    ) {
      const filtered = [];
      const friends = this.friendsToFilter;
      for (let i = 0; i < friends.length; i += 1) {
        if (
          this.matches(users[friends[i]], this.inputField.value.toLowerCase())
        ) {
          filtered.push(friends[i]);
        }
      }
      this.props.setActiveFilter(filtered);
    }
  }

  matches(friend, filter) {
    const firstName = friend.first_name.toLowerCase();
    const lastName = friend.last_name.toLowerCase();
    if (firstName.substr(0, filter.length) === filter) {
      return true;
    } else if (lastName.substr(0, filter.length) === filter) {
      return true;
    }
    return false;
  }

  changeMode() {
    const prevProp = this.props.filter.active;
    this.props.actions.changeFilterMode(!prevProp);
  }

  renderActive() {
    return (
      <form
        className="friend-list__filter-form"
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={(e) => {
            this.inputField = e;
          }}
          className="form__field friend-list__filter-field"
          onChange={(e) => this.props.actions.setFilter(e.target.value)}
          onBlur={() => this.changeMode()}
        />
      </form>
    );
  }

  renderDefault() {
    return (
      <form
        className="friend-list__filter-form"
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          onClick={() => this.changeMode()}
          className="form__field friend-list__filter-field"
          placeholder={this.props.intl.messages["friends.filterFriends"]}
        />
      </form>
    );
  }

  render() {
    if (this.props.filter.active) {
      return this.renderActive();
    }
    return this.renderDefault();
  }
}
FriendsFilter.propTypes = {
  friends: PropTypes.arrayOf(PropTypes.number).isRequired,
  users: PropTypes.shape({
    id: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      causes_first_name: PropTypes.shape({}),
      causes_last_name: PropTypes.shape({}),
    }),
  }).isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    active: PropTypes.bool,
    input: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    changeFilterMode: PropTypes.func,
    setFilter: PropTypes.func,
  }).isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape({
      "friends.filterFriends": PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users.all,
  filter: state.friends.friends.filter,
  intl: state.intl,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(friendsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FriendsFilter);
