/**
 * Created by mi on 11.3.17.
 */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class Footer extends Component {
  render() {
    return (
      <footer
        className={`footer ${this.props.lightBackground && "footer--light"}`}
      >
        <ul className="footer-menu">
          <li className="footer-menu__item">
            <a href="/about" className="footer-menu__link">
              <FormattedMessage id="hp.about" />
            </a>
          </li>
          <li className="footer-menu__item hidden-mobile">
            <a href="https://partner.wowee.cz" className="footer-menu__link">
              <FormattedMessage id="hp.partners" />
            </a>
          </li>
          <li className="footer-menu__item">
            <a href="/conditions" className="footer-menu__link">
              <FormattedMessage id="hp.conditions" />
            </a>
          </li>
          <li className="footer-menu__item hidden-mobile">
            <a href="/thanks" className="footer-menu__link">
              <FormattedMessage id="hp.thanks" />
            </a>
          </li>
          <li className="footer-menu__item">
            <a href="/contact" className="footer-menu__link">
              <FormattedMessage id="hp.contact" />
            </a>
          </li>
        </ul>
      </footer>
    );
  }
}
Footer.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }),
  lightBackground: PropTypes.bool,
};
Footer.defaultProps = {
  user: {
    loggedIn: false,
  },
  lightBackground: false,
};
export default Footer;
