import React, { Component } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import { setStep } from "components/Containers/MultiStep/actions";
import * as actions from "components/WishControllers/actions";
import * as constants from "scenes/Wish/scenes/Create/constants";

class CustomWishButton extends Component {
  componentDidMount() {
    //document.addEventListener('keypress', this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleClick();
    }
  };

  handleClick() {
    const params = {
      title: this.props.searchText,
      amount: this.props.amountNeeded,
      image: this.props.image,
      productURL: this.props.productUrl,
      description: "",
      surprise_wish:
        this.props.surprise_wish.recipient != undefined
          ? this.props.surprise_wish.recipient
          : "",
      custom_user_name: this.props.custom_user_name
        ? this.props.custom_user_name
        : "",
      allowed_users: [],
      flat_donation: null,
      visibility: "public",
      expiration_date: null,
      user_money_receiver: null,
      creation_source: "wowee-product-suggest",
      currency: this.props.currency,
    };

    const stringified = queryString.stringify(params);

    if (window.location.pathname.split("/")[1] === "create") {
      this.props.push(`/create/settings?${stringified}`);
    } else {
      this.props.push(`/wish/create/settings?${stringified}`);
    }

    // this.props.setStep("wish_create", "settings");
  }

  isUrl(text) {
    var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi; // eslint-disable-line
    var regex = new RegExp(expression);

    return text.match(regex);
  }

  render() {
    if (
      this.props.loadingStatus === constants.STATUS_DEFAULT ||
      this.isUrl(this.props.searchText)
    ) {
      return null;
    }

    return (
      <div className="content divider divider--mobile-condensed">
        <ul className="list">
          <li className="list__item">
            <a
              onClick={() => this.handleClick()}
              className="list__item-link"
              id="customWishButton"
            >
              <FormattedHTMLMessage
                id="wish.create.selectCustomWish"
                values={{ text: this.props.searchText }}
              />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

CustomWishButton.propTypes = {
  searchText: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

CustomWishButton.defaultProps = {
  image: null,
};

const mapStateToProps = (state) => ({
  searchText: state.wishCreate.searchText,
  loadingStatus: state.wishCreate.loadingStatus,
  recipient: state.wishCreate.recipient,
  surprise_wish: state.wishContainer.wish.surprise_wish,
  custom_user_name: state.wishContainer.wish.custom_user_name,
  currency: state.settings.currency,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    setStep: bindActionCreators(setStep, dispatch),
    push: bindActionCreators(push, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomWishButton)
);
