import React, { Component, Fragment } from "react";
import DocumentTitle from "react-document-title";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Footer from "components/App/components/Footer";
import UpcomingBirthdays from "scenes/Friends/components/UpcomingBirthdaysComponent";

import Feed from "./components/Feed";
import MyWishes from "./components/MyWishesWidget";

class Wishes extends Component {
  render() {
    if (this.props.user.loggedIn) {
      return (
        <Fragment>
          <DocumentTitle
            title={this.props.intl.formatMessage({ id: "menu.feed" })}
          >
            <section className="app-section dashboard">
              <Feed />
              <aside className="sidebar hidden-mobile">
                <UpcomingBirthdays widget={!false} />
                <MyWishes />
              </aside>
            </section>
          </DocumentTitle>
          <Footer lightBackground {...this.props} />
        </Fragment>
      );
    }
    return (
      <DocumentTitle title={this.props.intl.formatMessage({ id: "menu.feed" })}>
        <h1>
          <FormattedMessage id="menu.feed.loading" />
        </h1>
      </DocumentTitle>
    );
  }
}
Wishes.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, null)(injectIntl(Wishes));
