import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class HelperLoader extends Component {
  render() {
    return (
      <div className="search-helper">
        <div className="search-helper__wrapper">
          <h1 className="search-helper__heading">
            <FormattedMessage id="common.friends" />
          </h1>
          <div className="search-helper__loading" />
        </div>
        <div className="search-helper__wrapper">
          <h1 className="search-helper__heading">
            <FormattedMessage id="common.users" />
          </h1>
          <div className="search-helper__loading" />
        </div>
        <div className="search-helper__wrapper">
          <h1 className="search-helper__heading">
            <FormattedMessage id="common.wishes" />
          </h1>
          <div className="search-helper__loading" />
        </div>
      </div>
    );
  }
}

export default HelperLoader;
