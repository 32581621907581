import React from "react";
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";

class CardRedirection extends React.Component {
  render() {
    return (
      <div className="popup popup--with-header donate-popup donate-payment-method">
        <div className="popup__inner">
          <h3 className="popup__heading">
            <FormattedMessage id="donate.cardRedirection" />
          </h3>
          <h4 className="popup__title">
            <FormattedHTMLMessage id="donate.cardRedirectionWait" />
          </h4>
        </div>
      </div>
    );
  }
}

CardRedirection.propTypes = {
  closeModal: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => ({
  donationValue: state.donation.donationValue,
  currency: state.donation.currency,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CardRedirection));
