import { updateIntl } from "react-intl-redux";

import * as modalActions from "components/ConfirmModal/actions";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import { authFetchApi } from "services/api";
import * as langConstants from "services/localization/constants";
import csMessages from "services/localization/cs";
import enMessages from "services/localization/en";
import svkMessages from "services/localization/svk";

import * as userActions from "../../data/user/actions";

import * as types from "./actionTypes";

export const setInputOk = (item) => ({
  type: types.SET_INPUT_OK,
  item,
});
export const setLanguage = (item) => ({
  type: types.SET_LANGUAGE,
  item,
});

export const setCurrency = (item) => ({
  type: types.SET_CURRENCY,
  item,
});

export const setNewBankNr = (item) => ({
  type: types.SET_NEW_BANK_NR,
  item,
});

export const setSuccessUpdate = (item) => ({
  type: types.SET_SUCCESS_UPDATE,
  item,
});

export const fetchUser = (item) => ({
  type: types.FETCH_USER,
  item,
});

export const apiSubmitting = (item) => ({
  type: types.API_SUBMITTING,
  item,
});

export const fetchWishes = (item) => ({
  type: types.FETCH_WISHES,
  item,
});

export const doneLoading = (item) => ({
  type: types.DONE_LOADING,
  item,
});

export const informationsChanged = (item) => ({
  type: types.INFORMATIONS_CHANGED,
  item,
});

export function switchLanguage(language) {
  let messages;
  let locale;

  switch (language) {
    case langConstants.CZECH || langConstants.CZECH_ID:
      messages = csMessages;
      locale = langConstants.CZECH_ID;
      break;
    case langConstants.SLOVAK || langConstants.SLOVAK_ID:
      messages = svkMessages;
      locale = langConstants.SLOVAK_ID;
      break;
    case langConstants.ENGLISH || langConstants.ENGLISH_ID:
      messages = enMessages;
      locale = langConstants.ENGLISH_ID;
      break;
    default:
      messages = csMessages;
      locale = langConstants.CZECH_ID;
      break;
  }

  localStorage.setItem("language", language);
  return updateIntl({
    locale,
    messages,
  });
}

export function changeInformations(user, dataForm) {
  return (dispatch) => {
    return authFetchApi("/account/", user.accessToken, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataForm),
    })
      .then((json) => {
        dispatch(userActions.fetchUser(json));
        dispatch(informationsChanged(false));
      })
      .catch((e) => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function submitForm(bankNr, user, language, currency) {
  return (dispatch) => {
    const form = { language };
    form.bank_account = bankNr;
    form.currency = currency;

    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    dispatch(apiSubmitting());
    return authFetchApi("/account/", user.accessToken, options)
      .then((json) => {
        dispatch(fetchUser(json));
        dispatch(userActions.fetchUser(json));
        // dispatch(userActions.receiveLogin(json)); It's probably redunant, but who knows xD
        dispatch(switchLanguage(json.language));
        dispatch(setLanguage(json.language));
        dispatch(setCurrency(json.currency));

        dispatch(setInputOk(true));
        dispatch(setNewBankNr(json.bank_account));
        dispatch(setSuccessUpdate(true));
      })
      .catch((e) => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function submitLanguage(user, language) {
  return (dispatch) => {
    const form = { language };
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    dispatch(apiSubmitting());
    return authFetchApi("/account/", user.accessToken, options)
      .then((json) => {
        dispatch(fetchUser(json));
        dispatch(switchLanguage(json.language));
        dispatch(setLanguage(json.language));
        dispatch(setCurrency(json.currency));

        dispatch(setSuccessUpdate(true));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function deleteAccount(user) {
  return (dispatch) => {
    const form = { state: "deactivated" };
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    };
    return authFetchApi("/account/", user.accessToken, options)
      .then(() => {})
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function getWishes(user) {
  return (dispatch) => {
    authFetchApi("/wishes/", user.accessToken, {
      method: "GET",
    })
      .then((data) => {
        dispatch(fetchWishes(data));
        dispatch(doneLoading(data));
      })
      .catch(() => {
        dispatch({ type: types.API_ERROR });
        dispatch(modalActions.open({ title: "", type: modalTypes.ERROR }));
      });
  };
}

export function changePassword(user, password, newPassword, callback) {
  return (dispatch) => {
    const form = new FormData();
    form.append("email", user.email);
    form.append("password", password);
    form.append("new-password", newPassword);
    authFetchApi("/account/password-change/", user.accessToken, {
      method: "PATCH",
      body: form,
    })
      .then((data) => {
        dispatch(setSuccessUpdate(true));
        dispatch(
          modalActions.open({ title: "", type: modalTypes.PASSWORD_CHANGED })
        );
        callback();
      })
      .catch(() => {
        dispatch(setSuccessUpdate(false));
        dispatch(
          modalActions.open({ title: "", type: modalTypes.PASSWORD_ERROR })
        );
      });
  };
}
