import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import locateMessages from "./services/localization/cs";
import appReducer from "./reducers";

export const history = createBrowserHistory();

const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

/* eslint-disable no-underscore-dangle */
if (process.env.NODE_ENV === "development") {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
/* eslint-enable */

const initialState = {
  intl: {
    defaultLocale: "cs",
    locale: "cs",
    messages: locateMessages,
  },
  // ...other initialState
};
const store = createStore(appReducer, initialState, composedEnhancers);

export default store;
