import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class UnauthorizedMenu extends Component {
  render() {
    let search = "";
    if (
      this.props.location.pathname.startsWith("/user") ||
      this.props.location.pathname.startsWith("/wish/detail")
    ) {
      search = `?return_url=${this.props.location.pathname}`;
    }
    return (
      <header className="header">
        <div className="container header__container">
          <h1 className="header__logo">
            <a className="logo" href="/">
              <span>
                <FormattedMessage id="app.name" />
              </span>
            </a>
          </h1>

          <div className="header__body">
            <ul className="header__items">
              <li>
                <Link
                  to={{ pathname: "/login", search }}
                  className="header-menu__link"
                >
                  <FormattedMessage id="general.login" />
                </Link>
              </li>
              <li>
                <Link to="/sign-up" className="header-menu__link">
                  <FormattedMessage id="general.sign-up" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

UnauthorizedMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default UnauthorizedMenu;
