import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import UserImage from "components/UserImage";
import FormattedHTMLMessage from "services/localization/flex";

import * as actions from "../../actions";

class Donate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moreInfo: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.callPaying = this.callPaying.bind(this);
    this.changedDefault = false;
  }

  componentDidMount() {
    if (this.props.flat_donation) {
      this.props.actions.setStep(2);
      this.props.actions.setFlatDonation(true);
    } else {
      this.props.actions.setDonationValue(
        this.props.currency === "czk" ? 100 : 2
      );
    }
  }

  onChangeHandle(event) {
    this.changedDefault = true;
    this.props.actions.setDonationValue(event);
  }
  callPaying() {
    if (this.getValue()) {
      this.props.actions.stepNext();
    }
  }

  getValue() {
    if (!this.changedDefault) {
      return this.props.currency === "czk" ? 100 : 2;
    }
    return this.props.value;
  }

  handleChange(e) {
    this.changedDefault = true;
    if (e.target.value) {
      this.props.actions.setDonationValue(e.target.value);
    } else {
      this.props.actions.setDonationValue("");
    }
  }

  getDonateInfoMobile() {
    if (this.state.moreInfo) {
      return (
        <p className="hidden-desktop hidden-tablet">
          <FormattedMessage id="wish.detail.donate-money-info.desktop" />
          <br />{" "}
          <a onClick={() => this.handleLoadInfo()}>
            <FormattedMessage id="common.less" />
          </a>
        </p>
      );
    }
    return (
      <p className="hidden-desktop hidden-tablet">
        <FormattedMessage id="wish.detail.donate-money-info.mobile" />
        <br />{" "}
        <a onClick={() => this.handleLoadInfo()}>
          <FormattedMessage id="common.more" />
        </a>
      </p>
    );
  }

  handleLoadInfo() {
    this.setState({
      moreInfo: !this.state.moreInfo,
    });
  }

  render() {
    let cls = "button fill";

    if (this.getValue()) {
      cls += " button--next";
    }
    return (
      <div className="popup donate-popup donate-amount">
        <h3 className="popup__heading">
          <FormattedMessage id="donate.title" />
        </h3>
        <div className="row">
          <UserImage className="donate-amount__avatar" user={this.props.user} />
          <div>
            <h4 className="popup__title">
              <FormattedHTMLMessage
                id="donate.howMuch"
                values={{ name: this.props.user }}
              />
            </h4>
            <h5 className="popup__subtitle">{this.props.userWish}</h5>

            {/*
            <Slider
              type="range"
              min={this.props.currency === 'czk' ? 50 : 2}
              max={this.props.currency === 'czk' ? 3000 : 120}
              step={this.props.currency === 'czk' ? 50 : 1}
              className="donate-amount__slider"
              value={parseInt(this.props.value, 10)}
              onChange={this.onChangeHandle}
            />
            */}
            <p className="donate-amount__value">
              <FormattedMessage id="donate.title" />
              <input
                className="donate-amount__value-field"
                type="number"
                value={this.getValue()}
                onChange={this.handleChange}
                step={50}
              />
              {this.props.currency.toUpperCase()}
            </p>
            <p className="hidden-mobile">
              <FormattedMessage id="wish.detail.donate-money-info.desktop" />
            </p>
            {this.getDonateInfoMobile()}
          </div>
        </div>
        <div className="popup__footer">
          <button className={cls} onClick={this.callPaying}>
            <FormattedMessage id="common.next" />
          </button>
        </div>
      </div>
    );
  }
}

Donate.propTypes = {
  userWish: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  collected: PropTypes.number.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  causes_first_name: PropTypes.shape({}),
  causes_last_name: PropTypes.shape({}),
  currency: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  value: state.donation.donationValue,
  flat_donation: state.wishDetail.wish
    ? state.wishDetail.wish.flat_donation
    : null,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Donate);
