export const FETCH_NOTIFICATIONS = "notifications/FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_COUNT =
  "notifications/FETCH_NOTIFICATIONS_COUNT";
export const POPUP_SUCCESS_COLLECTION =
  "notifications/POPUP_SUCCESS_COLLECTION";
export const POPUP_CLOSE = "notifications/POPUP_CLOSE";
export const CLOSING_POPUP = "notifications/CLOSING_POPUP";
export const RESET_NOTIFICATIONS = "notifications/RESET_NOTIFICATIONS";
export const APPEND_NOTIFICATIONS = "notifications/APPEND_NOTIFICATIONS";
export const API_ERROR = "notifications/API_ERROR";
export const SET_DROPDOWN = "notifications/SET_DROPDOWN";
export const SUCCESS_COLLECTION = "success-collection";
export const SUCCESS_COLLECTION_PARTICIPATED =
  "success-collection-participated";
export const MY_WISH_DEADLINED = "collection-deadlined";
export const MY_WISH_GOT_DONATION = "got-donation";
export const YOU_DONATE = "you-donate";
export const MY_WISH_COMMENT = "comment";
export const GOT_GIFT = "got-a-gift";
export const FRIEND_REQUEST = "friend-request";
export const FRIEND_GRANTED = "friend-granted";
export const FRIEND_CREATED_WISH = "friend-created-wish";
export const FRIEND_CREATED_SURPRISE = "friend-created-surprise";
export const REVEAL_WISH_REQUEST = "reveal-request";
export const REVEAL_GRANTED = "reveal-granted";
export const NOTIFICATION_DELETED = "notifications/NOTIFICATION/DELETED";
export const SET_REQUESTS = "wishDetail/requestReveal";
export const CARD_DONATION_FAILED = "card-donation-failed";
export const EDIT_FRIEND_REQUEST = "notifications/EDIT_FRIEND_REQUEST";
