import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import CreateGroupButton from "../CreateGroupButton";

import FriendGroupComponent from "./components/FriendGroupComponent";
import * as groupActions from "./actions";

class FriendGroups extends React.Component {
  constructor(props) {
    super(props);
    this.displayType = true;
    this.addNewGroup = this.addNewGroup.bind(this);
    this.setActiveGroup = this.setActiveGroup.bind(this);
    this.state = {
      resp: false,
    };
  }

  setActiveGroup(index) {
    if (index === -1) {
      this.props.actions.setActiveIndex(index);
      this.props.setActiveGroup(null);
    } else if (index === -2) {
      this.props.actions.setActiveIndex(index);
      this.props.setActiveGroup(index);
    } else {
      const group = this.props.groups[index];
      this.props.setActiveGroup(group);
      if (this.props.mainMode) {
        this.props.actions.setActiveIndex(index);
      }
    }
  }

  toggle() {
    this.setState({
      resp: !this.state.resp,
    });
  }

  forAllGroups(item, i) {
    return (
      <li
        className={
          this.props.index === i
            ? "friend-lists__item active"
            : "friend-lists__item"
        }
        key={i}
      >
        <FriendGroupComponent
          fullView={this.displayType}
          activeView={i === this.props.index}
          key={i}
          index={i}
          friendGroup={item}
          setActiveGroup={this.setActiveGroup}
        />
      </li>
    );
  }

  addNewGroup(group) {
    this.props.actions.postNewGroup(group, this.props.user);
  }

  render() {
    const requestsCnt = this.props.requests.length;
    return (
      <section>
        <ul className="friend-lists">
          <li
            key={-1}
            className={
              this.props.index === -1
                ? "friend-lists__item active"
                : "friend-lists__item"
            }
          >
            <button
              className="no-style friend-lists__button"
              onClick={() => this.setActiveGroup(-1)}
            >
              <FormattedMessage id="friends.all" />
            </button>
          </li>
          {this.props.groups.map(this.forAllGroups, this)}
          <li className="friend-lists__item">
            <CreateGroupButton
              key={this.props.groups.length}
              addNewGroup={this.addNewGroup}
            />
          </li>
          <li
            key={-2}
            className={
              this.props.index === -2
                ? "friend-lists__item active"
                : "friend-lists__item"
            }
          >
            <button
              className="no-style friend-lists__button"
              onClick={() => this.setActiveGroup(-2)}
            >
              <div>
                <FormattedMessage id="friends.request" />
                {requestsCnt ? (
                  <span className="header-user__notification-badge">
                    {requestsCnt}
                  </span>
                ) : null}
              </div>
            </button>
          </li>
        </ul>
      </section>
    );
  }
}

FriendGroups.propTypes = {
  mainMode: PropTypes.bool,
  setActiveGroup: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
      members: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
  actions: PropTypes.shape({
    getFriendGroups: PropTypes.func,
    postNewGroup: PropTypes.func,
    setActiveIndex: PropTypes.func,
    addGroupEntry: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

FriendGroups.defaultProps = {
  setGroupToDisplay: null,
  editGroup: null,
  mainMode: true,
};

const mapStateToProps = (state) => ({
  user: state.user,
  groups: state.friends.groups.all,
  index: state.friends.groups.index,
  requests: state.friends.friends.friendRequests,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FriendGroups);
