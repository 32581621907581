import { authFetchApi } from "services/api/";

import * as types from "./actionTypes";
import * as constants from "./constants";

export const setField = (name, value) => ({
  type: types.SET_FIELD,
  name,
  value,
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
export const openModal = () => ({
  type: types.OPEN_MODAL,
});

export const setStatus = (status) => ({
  type: types.SET_STATUS,
  status,
});
export const setSaveStatus = (status) => ({
  type: types.SET_SAVE_STATUS,
  status,
});

export const setGdpr = (data) => ({
  type: types.SET_GDPR,
  data,
});

export function fetchGdpr(user) {
  return (dispatch) => {
    dispatch(setStatus(constants.pending));
    dispatch(openModal());
    return authFetchApi("/account/auth/gdpr/", user.accessToken, {
      method: "GET",
    })
      .then((json) => {
        dispatch(setGdpr(json));
      })
      .catch((e) => {
        dispatch(setStatus(constants.error));
      });
  };
}

export function save() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setSaveStatus(constants.pending));
    return authFetchApi("/account/auth/gdpr/", state.user.accessToken, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ gdpr: state.gdpr.gdpr }),
    })
      .then((json) => {
        dispatch(setGdpr(state.gdpr.gdpr));
        dispatch(closeModal());
      })
      .catch((e) => {
        dispatch(setSaveStatus(constants.error));
      });
  };
}
