import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { injectIntl } from "react-intl";
import Modal from "react-modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";

import { PAGE_URL } from "components/App/constants";
import ConfirmModal from "components/ConfirmModal";
import * as modalTypes from "components/ConfirmModal/actionTypes";
import NotFound from "scenes/NotFound";

import CardDonationFailed from "../../../../components/PopupContainer/components/CardDonationFailed";
import PopupWishCreated from "../../../../components/PopupContainer/components/PopupWishCreated";
import PopupYouDonate from "../../../../components/PopupContainer/components/PopupYouDonate";
import Spinner from "../../../../components/Spinner";

import Donation from "./components/Donation";
import FullDetail from "./components/FullDetail";
import RestrictedDetail from "./components/RestrictedDetail";
import * as actions from "./actions";
import * as types from "./actionTypes";

class WishDetail extends Component {
  constructor(props) {
    super(props);

    const params = queryString.parse(this.props.location.search);
    const queryStatus = params.status ? params.status : null;

    this.state = {
      isOpenModal: false,
      isOpenPaymentModal: !!queryStatus,
      modalTitle: "",
      modalType: modalTypes.DEFAULT,
      modalCallback: () => {},
      dropDownOpen: false,
    };
    this.donationModal = React.createRef();
    this.openModal = this.openModal.bind(this);
    this.openDonationModal = this.openDonationModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closePaymentModal = this.closePaymentModal.bind(this);
    this.closeWishCreatedModal = this.closeWishCreatedModal.bind(this);
    this.openDropDown = this.openDropDown.bind(this);
    this.closeDropDown = this.closeDropDown.bind(this);
  }
  componentDidMount() {
    this.gettingData();
    this.props.actions.setRestriction(false);
    this.interval = setInterval(() => {
      this.props.actions.checkWishStatus(
        this.props.match.params.id,
        this.props.user,
        this.props.wish,
        window.location
      );
    }, 1000 * 60);
  }

  componentDidUpdate() {
    this.gettingData();
  }
  componentWillUnmount() {
    this.props.actions.reset();
    clearInterval(this.interval);
  }

  gettingData() {
    const { id } = this.props.match.params;
    const { status, user, wishId, isRestricted } = this.props;
    if (
      status === types.STATUS_EMPTY ||
      ([types.STATUS_LOADED_RESTRICTED, types.STATUS_ERROR_FULL].includes(
        status
      ) &&
        user.loggedIn &&
        !isRestricted) ||
      wishId !== id
    ) {
      this.props.actions.getWish(id, user);
    }
  }

  openModal(callback, title, type) {
    this.setState({
      isOpenModal: true,
      modalTitle: title,
      modalCallback: callback,
      modalType: type,
    });
  }
  closeModal() {
    this.setState({
      isOpenModal: false,
    });
  }
  closePaymentModal() {
    this.setState({
      isOpenPaymentModal: false,
    });
  }

  closeWishCreatedModal() {
    this.setState({
      isOpenWishCreatedModal: false,
    });
    this.props.location.state.isOpenWishCreatedModal = false;

    this.props.history.replace({
      pathname: this.props.location.pathname,
      state: { created: false, isOpenWishCreatedModal: false },
    });
  }

  openDonationModal() {
    console.log(this.donationModal);
    this.donationModal.current.openModal();
  }
  openDropDown() {
    this.setState({
      dropDownOpen: true,
    });
  }
  closeDropDown() {
    this.setState({
      dropDownOpen: false,
    });
  }
  renderWishComponent() {
    const { status } = this.props;
    if (status === types.STATUS_EMPTY || status === types.STATUS_LOADING) {
      return (
        <div className="container container--narrow">
          <div className="content divider">
            <Spinner />
          </div>
        </div>
      );
    } else if (status === types.STATUS_LOADED_FULL) {
      return (
        <FullDetail
          wish={this.props.wish}
          friend={this.props.friend}
          user={this.props.user}
          openModal={this.openModal}
          closeModal={this.closeModal}
          openDonationModal={this.openDonationModal}
          openDropDown={this.openDropDown}
          closeDropDown={this.closeDropDown}
          dropDownOpen={this.state.dropDownOpen}
        ></FullDetail>
      );
    } else if (status === types.STATUS_LOADED_RESTRICTED) {
      return (
        <RestrictedDetail
          friend={this.props.friend}
          openDonationModal={this.openDonationModal}
          user={this.props.user}
        />
      );
    } else if (status === types.STATUS_NOT_FOUND) {
      return <NotFound />;
    }
    return <div>Error</div>;
  }

  showWishImage() {
    const def = `${PAGE_URL}/img/placeholder-gift.png`;
    if (!this.props.wish) {
      return def;
    }
    if (this.props.wish.images.length === 0) {
      return def;
    }
    return this.props.wish.images[0].image;
  }

  renderPayment() {
    const params = queryString.parse(this.props.location.search);
    const queryStatus = params.status ? params.status : null;
    let wish;

    const donation = {
      amount: params.amount,
    };

    if (this.props.status === types.STATUS_LOADED_FULL) {
      wish = this.props.wish;
    } else if (this.props.status === types.STATUS_LOADED_RESTRICTED) {
      wish = this.props.restrictedWish;
    }

    const popup = {
      wish,
      donation,
    };
    if (!wish) {
      return;
    }

    if (queryStatus === "ok") {
      return (
        <PopupYouDonate
          popup={popup}
          closeModal={this.closePaymentModal}
          closingPopup={this.props.closingPopup}
        />
      );
    } else if (queryStatus === "failed") {
      return (
        <CardDonationFailed
          popup={popup}
          closeModal={this.closePaymentModal}
          closingPopup={this.props.closingPopup}
        />
      );
    }
  }

  render() {
    const title = this.props.wish
      ? this.props.wish.title
      : this.props.intl.formatMessage({ id: 'wish.detail.mainTitle' }); // eslint-disable-line
    return (
      <DocumentTitle title={title}>
        <div className="app-section">
          <Donation ref={this.donationModal} />
          <ConfirmModal
            title={this.state.modalTitle}
            isOpen={this.state.isOpenModal}
            type={this.state.modalType}
            closeModal={this.closeModal}
            confirmAction={this.state.modalCallback}
          />

          {this.renderWishComponent()}
          <Modal
            isOpen={this.state.isOpenPaymentModal}
            className="modal modal--main"
            overlayClassName="overlay overlay--main"
            onRequestClose={this.closePaymentModal}
          >
            {this.renderPayment()}
          </Modal>
          <Modal
            isOpen={
              this.props.wish &&
              this.props.location.state &&
              this.props.location.state.isOpenWishCreatedModal
            }
            className="modal modal--main"
            overlayClassName="overlay overlay--main"
            onRequestClose={this.closeWishCreatedModal}
          >
            <PopupWishCreated
              wish={this.props.wish}
              closeModal={this.closeWishCreatedModal}
              closingPopup={this.props.closingPopup}
            />
          </Modal>
        </div>
      </DocumentTitle>
    );
  }
}

WishDetail.propTypes = {
  wish: PropTypes.shape({
    title: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
      })
    ),
  }),
  restrictedWish: PropTypes.shape({}), // eslint-disable-line
  wishId: PropTypes.string.isRequired, // eslint-disable-line
  isRestricted: PropTypes.bool.isRequired, // eslint-disable-line
  friend: PropTypes.shape({
    first_name: PropTypes.string,
    causes_first_name: PropTypes.shape({}),
    causes_last_name: PropTypes.shape({}),
  }), // eslint-disable-line
  actions: PropTypes.shape({
    getWishById: PropTypes.func,
    getWish: PropTypes.func,
    reset: PropTypes.func,
    getRestrictedWishById: PropTypes.func,
    setStatus: PropTypes.func,
    setFriend: PropTypes.func,
    setRestrictedWish: PropTypes.func,
    setRestriction: PropTypes.func,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    id: PropTypes.number,
    loggedIn: PropTypes.bool,
  }).isRequired,
  status: PropTypes.string.isRequired,
};

WishDetail.defaultProps = {
  wish: null,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  wish: state.wishDetail.wish,
  restrictedWish: state.wishDetail.restrictedWish,
  friend: state.wishDetail.friend,
  isRestricted: state.wishDetail.isRestricted,
  status: state.wishDetail.status,
  wishId: state.wishDetail.wishId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WishDetail));
