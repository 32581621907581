import React, { Component } from "react";

import Wish from "./Wish";

class WishesList extends Component {
  render() {
    console.log(this.props);
    const wishesList = this.props.wishes.map((wish, i) => {
      return <Wish wish={wish} key={i} index={i} />;
    });

    return (
      <div className="search__item">
        <h1 className="search__item-heading">Wishes</h1>
        {wishesList}
      </div>
    );
  }
}

export default WishesList;
