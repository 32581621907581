import * as types from "./actionTypes";

export const initState = {
  results: { friends: [], strangers: [], wishes: [] },
  error: false,
  loading: false,
  query: "",
};

export default (state = initState, payload) => {
  switch (payload.type) {
    case types.RESET:
      return initState;
    case types.SEARCH:
      return Object.assign({}, state, {
        results: payload.results,
        error: payload.error,
      });
    case types.CHANGE_QUERY:
      return Object.assign({}, state, {
        query: payload.value,
      });
    case types.SET_LOADING:
      return Object.assign({}, state, { loading: payload.value });
    default:
      return state;
  }
};
