import * as constants from "./constants";

import * as types from "./actionTypes";

export const initialState = {
  surpriseWish: false,
  searchText: "",
  suggested: [],
  timeout: 0,
  loadingStatus: constants.STATUS_DEFAULT,
  recipient: null,
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case types.SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: payload.item });
    case types.SET_SUGGESTED:
      return Object.assign({}, state, { suggested: payload.item });
    case types.SET_TIMEOUT:
      return Object.assign({}, state, { timeout: payload.item });
    case types.SET_LOADING_STATUS:
      return Object.assign({}, state, { loadingStatus: payload.item });
    case types.SET_RECIPIENT:
      return Object.assign({}, state, { recipient: payload.item });
    case types.RESET:
      return Object.assign({}, initialState);
    default:
      return Object.assign({}, state);
  }
};
